import { Route, Routes } from "react-router-dom"
import { DynamicFormContainer } from "../../../components/DynamicForm/DynamicFormContainer"
import { TipoConvocatoriaContainer } from "../../../components/outlet/tipoConvocatoria/TipoConvocatoriaContainer"
import Convocatorias from "./Convocatorias"
import Fases from "./Fases"
import { BASE_URL } from "../../../services/api/config"
import EstudiantesConvenio from "./EstudiantesConvenio"

function Convocatoria () {
  return (
    <Routes>
      <Route  path="convenio" element = {<DynamicFormContainer idForm="34" 
        exportar={`${BASE_URL}/convenio/exportarConvenios`}
      />}></Route>
      <Route  path="estudiantes_por_convenio" element = {<EstudiantesConvenio/>}></Route>
      <Route  path="empresas" element = {<DynamicFormContainer idForm="71"/>}></Route>
      <Route  path="prorroga_convenio" element = {<DynamicFormContainer idForm="144" endPointSubmit="prorroga"/>}></Route>
      {/* <Route  path="convocatoria" element={<DynamicFormContainer idForm="31"/>} /> */}
      <Route  path="convocatoria" element={<Convocatorias/>} />
      <Route  path="convocatoria/fases" element={<Fases/>} />
      <Route  path="tipo_convocatoria" element={<TipoConvocatoriaContainer />} />
      <Route  path="*" element={<DynamicFormContainer />} />    
    </Routes>
  )
}
export { Convocatoria }