import React, { useState } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { DynamicTable } from '../../../components/DynamicForm/dynamicTable/DynamicTable'
import { BASE_URL } from '../../../services/api/config'

export default function EstudiantesConvenio() {
    const urlExportar = `${BASE_URL}/exportar/convenios`
    const urlEstudiantes = `convenio/estudiantesConvenio`
    const [enpoint, setEnpoint] = useState(urlEstudiantes)
  return (
    <>
        <Card>
            <Row>
                <Col>
                    <Card.Title>Estudiantes por convenio</Card.Title>
                </Col>
                <Col 
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}
                >   
                    <Button 
                        variant="outline-primary"
                        onClick={() => {
                            window.open(urlExportar)
                        }}
                    >
                        Exportar
                    </Button>
                </Col>
            </Row>
        </Card>

        <DynamicTable
            showIndexColumn={false}
            titleColumn={["Nombre","Convenio","Actividad o Programa", ]}
            attributes={["Nombre", "Convenio", "Actividad"]} 
            endPoint={enpoint}
            onActions={false}
        />
    </>
  )
}
