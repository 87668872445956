import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useFetchGet } from "../../../hooks/useFetchGet";
import user from '../../../assets/img/avatar.jpg';
import  './CursosActivos.css'
import { useState } from "react";
import { FormService } from "../../../services/api/formService";
import { optionsDate } from "../../../shared/util/date";

import { TextArea } from "../../../components/componentsForm/textarea/textarea";
import { alerta, alertaSinBoton } from '../../../helpers/alertas'
import { Badge, Button, Card, Col, Dropdown, DropdownButton, ListGroup, Modal, Row, Table } from "react-bootstrap";
import { useEffect } from "react";
import { BASE_SERVIDOR, BASE_URL} from "../../../services/api/config";
import { Input } from "../../../components/input/input";
import DowloadFile from "../../../components/DowloadFile/DowloadFile";
import { DynamicForm } from "../../../components/DynamicForm/DynamicForm";
import { DynamicTable } from "../../../components/DynamicForm/dynamicTable/DynamicTable";
import { LocalStorage } from "../../../services";
import { Alert } from "react-bootstrap";


function PlaneacionAcademica () {
  
  const api = new FormService()
  const location = useLocation()
  const localStorage = new LocalStorage()
  const user = localStorage.getAsJsonItem('usuario')
  const ID = user?.usuario?.IdUsuario
  const [dataAsignatura, setDataAsignatura] = useState({})
  const [error, setError] = useState(null)
  const [ready, setReady] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalPlaneacion, setModalPlaneacion] = useState()
  const [modalListaPlaneacion, setModalListaPlaneacion] = useState()
  const [modalEvaluacion, setModalEvaluacion] = useState()
  const [modalListaEvaluacion, setModalListaEvaluacion] = useState()
  const [modalRecurso, setModalRecurso] = useState()
  const [modalListaRecurso, setModalListaRecurso] = useState()
  const [recurso, setRecurso] = useState()
  const {
    IdPlanAsignaturaSyllabus,
    JustificacionAsignatura, 
    AporteAsignaturaalPlan, 
    RelacionAsignaturas,
    PropositoAsignatura,
    CompetenciaAsignatura,
    ResultadoAprendizaje,
    Aprobado,
    ObservacionAprobado
  } = dataAsignatura

  const fecthDataAsignatura = async () => {
    try {
        const userDataFetched = await api.getAll(`planAsignaturaSyllabus/plan?IdAsignatura=${location.state.item.IdAsignatura}&IdUsuario=${ID}`)
        setDataAsignatura(userDataFetched)
    } catch (err) {
        setError(err)
    }
  }

  const handleChange = (value, name) => {
    setReady(false)
    setError(null)
    setDataAsignatura({
        ...dataAsignatura,
        [name]: value
    })
  }

  const handleSudmit = async (e) => {
    e.preventDefault()
    
    if(IdPlanAsignaturaSyllabus) {
      try {
          setError(null)
          setReady(false)
          setLoading(true)
          const respuesta = await api.update("planAsignaturaSyllabus", 
                                              {
                                                "JustificacionAsignatura":dataAsignatura.JustificacionAsignatura,
                                                "AporteAsignaturaalPlan":dataAsignatura.AporteAsignaturaalPlan,
                                                "RelacionAsignaturas":dataAsignatura.RelacionAsignaturas,
                                                "PropositoAsignatura":dataAsignatura.PropositoAsignatura,
                                                "CompetenciaAsignatura":dataAsignatura.CompetenciaAsignatura,
                                                "ResultadoAprendizaje":dataAsignatura.ResultadoAprendizaje
                                              }, 
                                              IdPlanAsignaturaSyllabus)
          if(respuesta.respuesta=="exito"){
            alerta("Éxito", "success")
          } else {
            alerta("Fallo desconocido")
          }
          console.log(respuesta)
      } catch (err) {
        setError(err)
        setLoading(false)
      }
    } else {

      try {
          setError(null)
          setReady(false)
          setLoading(true)
          const respuesta = await api.create("planAsignaturaSyllabus", {...dataAsignatura, IdAsignatura: location.state.item.IdAsignatura, IdUsuario: ID}, 0)
          if(respuesta.respuesta=="exito"){
            alerta("Éxito", "success")
          } else {
            alerta("Fallo desconocido")
          }
        } catch (err) {
          setError(err)
          setLoading(false)
        }
    }
  }

    useEffect(()=>{
      fecthDataAsignatura()
    },[])



  return (
    <>
    {console.log(dataAsignatura)}
    <Card>
      <h3>Planeación Académica Syllabus</h3>
      <h6>{location.state.item.Descripcion}</h6>
    </Card>
    <Card>
        {Aprobado == 0 &&
          <Alert bsStyle="warning">
            <Button variant="danger"></Button>
            <strong>Estado:</strong> Rechazado
            <br />
            <strong>{ObservacionAprobado}</strong>
          </Alert>
        }
        {Aprobado == 1 &&
          <Alert bsStyle="success">
            <Button variant="success"></Button>
            <strong>Estado:</strong> Aprobado
            <br />
            <strong>{ObservacionAprobado}</strong>
          </Alert>
        }
        <form 
          onSubmit={ handleSudmit }
          className='user-profile__form'
        >
          <Col>
            <Row>
              <TextArea
                id={'JustificacionAsignatura'}
                name={'JustificacionAsignatura'}
                label={"Justificación de la Asignatura"}
                value={JustificacionAsignatura || ''}
                onChange={handleChange}
              />
              <TextArea
                id={'AporteAsignaturaalPlan'}
                name={'AporteAsignaturaalPlan'}
                label={"Aportes de la Asignatura al Plan de Estudios"}
                value={AporteAsignaturaalPlan || ''}
                onChange={handleChange}
              />
              <TextArea
                id={'RelacionAsignaturas'}
                name={'RelacionAsignaturas'}
                label={"Relación con otras Asignaturas"}
                value={RelacionAsignaturas || ''}
                onChange={handleChange}
              />
              <TextArea
                id={'PropositoAsignatura'}
                name={'PropositoAsignatura'}
                label={"Propósito General de la Asignatura"}
                value={PropositoAsignatura || ''}
                onChange={handleChange}
              />
              <TextArea
                id={'CompetenciaAsignatura'}
                name={'CompetenciaAsignatura'}
                label={"Competencia General de la Asignatura"}
                value={CompetenciaAsignatura || ''}
                onChange={handleChange}
              />
              <TextArea
                id={'ResultadoAprendizaje'}
                name={'ResultadoAprendizaje'}
                label={"Resultado de Aprendizaje General de la Asignatura"}
                value={ResultadoAprendizaje || ''}
                onChange={handleChange}
              />

              <Card>
                <Row>
                  <label htmlFor=""><b> Planeación Académica </b></label>
                  <Col> 
                    <Button
                      variant="secondary"
                      onClick={() => setModalPlaneacion(true)}
                    >
                      Agregar
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      variant="secondary"
                      onClick={() => setModalListaPlaneacion(true)}
                    >
                      Listar
                    </Button>
                  </Col>
                </Row>
              </Card>

              <Card>
                <Row>
                  <label htmlFor=""><b> Evaluación </b> </label>
                  <Col> 
                    <Button
                      variant="secondary"
                      onClick={() => setModalEvaluacion(true)}
                    >
                      Agregar
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      variant="secondary"
                      onClick={() => setModalListaEvaluacion(true)}
                    >
                      Listar
                    </Button>
                  </Col>
                </Row>
              </Card>

              <Card>
                <Row>
                  <label htmlFor=""><b> Recursos Bibliográficos </b> </label>
                  <Col> 
                    <Button
                      variant="secondary"
                      onClick={() => setModalRecurso(true)}
                    >
                      Agregar
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      variant="secondary"
                      onClick={() => setModalListaRecurso(true)}
                    >
                      Listar
                    </Button>
                  </Col>
                </Row>
              </Card>
              
              {IdPlanAsignaturaSyllabus ?
              <div style={{textAlign: "right", marginBottom:"15px", marginTop: "12px"}}>
                <Button 
                  type="send"
                >
                  Actualizar
                </Button>
              </div>
              :
              <div style={{textAlign: "right", marginBottom:"15px", marginTop: "12px"}}>
                <Button 
                  type="send"
                >
                  Guardar
                </Button>
              </div>
              }
            </Row>
          </Col>
        </form>
        </Card>

        <Modal
          show={modalPlaneacion}
          onHide={()=>setModalPlaneacion(false)}
        >
            <DynamicForm 
              title={"Planeación Académica"}
              justDynamicForm = {{ flag: true, idForm: "136" }}
              isCreate={true}
              endPointSubmit={"planeacionacademicasyllabus"}
              extraData={
                  {
                    IdUsuario: ID,
                    IdAsignatura: location.state.item.IdAsignatura
                  }
              }
              accionMysql={() => setModalPlaneacion(false)}
            />
        </Modal>

        <Modal
          show={modalListaPlaneacion}
          onHide={()=>setModalListaPlaneacion(false)}
          size="lg"
        >
          <DynamicTable
            titleColumn={["No", "Nombre del Eje Temático", "Tema", "TD", "TI", "Actividad Académica", "Semana (s)"]}
            attributes={[
                          "NombreEjeTematico", "Tema", "TD", "TI", "ActividadAcademica", "Semana"
                        ]}
            endPoint={`planeacionacademicasyllabus?IdAsignatura=${location.state.item.IdAsignatura}&IdUsuario=${ID}`}
            onActions={false}
          />
        </Modal>
        
        <Modal
          show={modalEvaluacion}
          onHide={()=>setModalEvaluacion(false)}
        >
            <DynamicForm 
              title={"Evaluación"}
              justDynamicForm = {{ flag: true, idForm: "137" }}
              isCreate={true}
              endPointSubmit={"evaluacionesSyllabus"}
              extraData={
                  {
                    IdUsuario: ID,
                    IdAsignatura: location.state.item.IdAsignatura
                  }
              }
              isContentTypeFormData={'1'}
              accionMysql={() => setModalEvaluacion(false)}
            />
        </Modal>

        <Modal
          show={modalListaEvaluacion}
          onHide={()=>setModalListaEvaluacion(false)}
          size="lg"
        >
          <DynamicTable
            titleColumn={["No", "Corte", "Actividad Específica de Evaluación", "Evidencia", "Ponderación Actividad"]}
            attributes={[
                          "Corte", "ActividadEspecifica", "Evidencia", "PonderacionActividad"
                        ]}
            endPoint={`evaluacionesSyllabus?IdAsignatura=${location.state.item.IdAsignatura}&IdUsuario=${ID}`}
            onActions={false}
          />
        </Modal>
        
        <Modal
          show={modalRecurso}
          onHide={()=>setModalRecurso(false)}
        >
            <DynamicForm 
              title={"Recursos Bibliográficos"}
              justDynamicForm = {{ flag: true, idForm: "138" }}
              isCreate={true}
              endPointSubmit={"recursoBibliograficoSyllabus"}
              extraData={
                  {
                    IdUsuario: ID,
                    IdAsignatura: location.state.item.IdAsignatura
                  }
              }
              accionMysql={() => setModalRecurso(false)}
            />
        </Modal>

        <Modal
          show={modalListaRecurso}
          onHide={()=>setModalListaRecurso(false)}
          size="lg"
        >
          <DynamicTable
            titleColumn={["No", "Lectura Obligatoria", "Lectura de Referencia"]}
            attributes={["LecturaObligatoria", "LecturaReferencia"]}
            endPoint={`recursoBibliograficoSyllabus?IdAsignatura=${location.state.item.IdAsignatura}&IdUsuario=${ID}`}
            onActions={false}
          />
        </Modal> 
    </>
  )
}

export  { PlaneacionAcademica };


