import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { FormService } from '../../../services/api/formService'
import { Card } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { Arrow } from '../../arrow/Arrow'
import { ProfileHeader } from '../../ProfileHeader/ProfileHeader'
import { Button } from '../../button/button'
import { Tag } from '../../Tag/Tag'
import { NoData } from '../../NoData/NoData'
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner'
import { Message } from '../../Message/Message'

import './Student.css'

const Student = () => {

    const [courses, setCourses] = useState([])
    const { idCourse, idStudent } = useParams()
    const  [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)
    const { state: { student } } = useLocation()
    const ENDPOINT = `estudiante/notas?IdOferta=${idCourse}&IdEstudiante=${idStudent}`

    const fetchDataCourse = async () => {
        setError(null)
        try {
            setLoading(true)
            const formService = new FormService()
            const data = await formService.getAll(ENDPOINT)
            setCourses(data)
            setLoading(false)
        } catch (err) {
            setError(err)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchDataCourse()
        }
        fetchData()
    }, [])
    console.log(student)
    return (
        <>
            <header>
                <Arrow />
            </header>
            <main className='profile-header__main'>
                <ProfileHeader 
                    profile={ student }
                    isStudent={ true }
                />
                <Card>
                    <div className='profile-header__list-course'>
                        {
                            loading ? <LoadingSpinner /> :
                            Array.isArray(courses) && (
                                courses.length <= 0 ? <NoData /> :
                                courses.map((course, key) => (
                                    <div 
                                        className='profile-header__card-course'
                                        key={ key }
                                    >
                                        <h4>{ course.asignatura }</h4>
                                        <div className='profile-header__course-content'>
                                            <Tag 
                                                label='Nota'
                                                value={ course.nota || '--'}
                                            />
                                            <Button
                                                isLink={ true }
                                                type='circle'
                                                to='asistencia'
                                                state={{
                                                    oferta: course,
                                                    programa: student,
                                                }}
                                            >
                                                <i className="bi bi-card-checklist"></i>
                                            </Button>
                                        </div>
                                    </div>
                                ))
                            )
                        }
                        {
                            error && (
                                <Message type='error'>
                                    { error.message }
                                </Message>
                            )
                        }
                    </div>
                </Card>
            </main>
        </>
    )
}

export { Student }