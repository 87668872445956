import React from 'react'
import { Button, Card, Col, Row, Table } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { DynamicFormContainer } from '../../../../components/DynamicForm/DynamicFormContainer'
import { BASE_URL } from '../../../../services/api/config'
import DowloadFile from '../../../../components/DowloadFile/DowloadFile'

export default function GestionConvocatoria() {

    const {state:{oferta}}=useLocation();


    const exportarFormatoConvocatoria=(e)=>{
        window.open(`${BASE_URL}/ofertas/exportar?IdOferta=${oferta.IdOferta}`,"_blank",)
    }
    const exportarFormatoNota=(e)=>{
        const IdNotaAclaratoria=e.IdNotaAclaratoria
        window.open(`${BASE_URL}/notasAclaratoria/descagarNotaAclaratoria?IdNotaAclaratoria=${IdNotaAclaratoria}`,"_blank",)
    }

  return (
    <>
        <Card>
            <Row>
                <Col>
                    <Card.Title>Gestionar convocatoria</Card.Title>
                </Col>
                <Col style={{textAlign:"right"}}>

                    <div style={{fontSize: '30px'}}>
                        <DowloadFile
                            name={'Exportar Formato'}
                            nameFile={'Convocatoria Nacional.pdf'}
                            endPoint={`${BASE_URL}/ofertas/exportar?IdOferta=${oferta.IdOferta}`}
                        />
                    </div>
                </Col>
            </Row>
            
        </Card>
        <Card>
            <Card.Title>Notas aclaratorias</Card.Title>
            <DynamicFormContainer 
                idForm="108"
                extraData={{IdOferta:oferta.IdOferta,IdConvocatoria:oferta.IdConvocatoria}}
                labelOptionClick="Exportar formato"
                handleOptionClick={(e)=>exportarFormatoNota(e)}
                
            />
        </Card>
    </>
  )
}
