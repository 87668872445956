import { Route, Routes } from "react-router-dom"
import { ActiveOffers } from "../../components/outlet/ActiveOffers/ActiveOffers"
import { Applicant } from "../../components/outlet/Applicant/Applicant"
import { ApplicantListContainer } from "../../components/outlet/ApplicantList/ApplicantListContainer"
import { EstudianteHistorial } from "../componentesC/estudianteHistorial/EstudianteHistorial"
import { PerfilEstudiante } from "../componentesC/estudianteHistorial/PerfilEstudiante"
import { EstudianteDetalleCurso } from "../estudiante/cursosFinalizados/EstudianteDetalleCurso"
import { AsignaturaEstudiantes } from "../registro/CursosActivos/AsignaturaEstudiantes"
import { EstudianteAsistencias } from "../componentesC/EstudianteAsistencias/EstudianteAsistencias" 
import { AsignaturasCurso } from "../componentesC/AsignaturasCurso/AsignaturasCurso"
import { MomentosEvaluativos } from "../componentesC/momentosEvaluativos/MomentosEvaluativos"
import { ActividadesFinalizadas } from "./actividadesFinalizadas/ActividadesFinalizadas"
import { EstudianteNotas } from "../componentesC/EstudianteNotas/EstudianteNotas"
const ApoyoGestion=()=>{

    return(
        <Routes>
            <Route path="actividades_finalizadas" element={<ActividadesFinalizadas/>}/>
            <Route
                path="actividades_finalizadas/:idoferta/asignaturas"
                element={<AsignaturasCurso editable={false} gestionable={false} />}
            ></Route>
            <Route
                path="actividades_finalizadas/:idoferta/asignaturas/:idmalla/asignatura_estudiantes"
                element={<AsignaturaEstudiantes />}
            ></Route>
            <Route  path="actividades_finalizadas/:idoferta/asignaturas/:idusario/detalle_programa" 
                    element = {<EstudianteNotas gestionable={false} />}>
            </Route> 
            <Route
                path="actividades_finalizadas/:idoferta/asignaturas/:idmalla/asignatura_estudiantes/asistencias"
                element={<EstudianteAsistencias/>}
            ></Route>
            <Route
                path="actividades_finalizadas/:idoferta/asignaturas/:idmalla/momentos_evaluativos"
                element={<MomentosEvaluativos editable={false} />}
            ></Route>
            <Route
                path="actividades_finalizadas/:idoferta/asignaturas/:idmalla/asignatura_estudiantes/notas"
                element={<EstudianteNotas/>}
            ></Route>

            <Route path="inscripciones" element={<ActiveOffers full={false}/>} />
            <Route path="inscripciones/lista-aspirante/:idApplicantList" element={<ApplicantListContainer />} />
            <Route path="inscripciones/lista-aspirante/:idApplicantList/estado-aspirante/:idApplicant" element={<Applicant editable={false} />} />
            
            <Route path="historial_de_estudiante" element={<EstudianteHistorial/>}/>
            <Route path="historial_de_estudiante/perfil_estudiante" element={<PerfilEstudiante/>}/>
            <Route path="historial_de_estudiante/perfil_estudiante/asignaturas" element={<EstudianteDetalleCurso/>}/>

        </Routes>
    )
}

export {ApoyoGestion}