import { useLocation } from "react-router-dom"
import { Card } from "../../../components/card/Card"
import { DynamicForm } from "../../../components/DynamicForm/DynamicForm"
import { ID_FORM } from "../../../constants/idForm"


function AutoEvaluacion (props) {
  const {state:{evaluacion}} = useLocation()

  // const {state:{itemParent,item}} = useLocation()
  // const [asistencias, loading, error] = useFetchGet(`estudiante/asistencia?IdOferta=${item.IdOferta}&IdEstudiante=${item.IdEstudiante}`)
  // const [asistencias, loading, error] = useFetchGet(`estudiante/asistencia?IdOferta=${itemParent.IdOferta}&IdEstudiante=${item.IdEstudiante}`)
  return (
    <>
    {/* {JSON.stringify(evaluacion)} */}
    <Card>

   <DynamicForm
    parentField = { null}
    ventanaFormName={evaluacion.nombre }
    // title = {''}
    extraData={{IdGestionInstrumento:evaluacion.IdGestionInstrumento}}
    endPointSubmit={'instrumento'}
    // keySubmit={'k'}
    isContentTypeFormData={'0'}
    isCreate={true}
    justDynamicForm={{flag:true,idForm:evaluacion?.IdForm}}
   ></DynamicForm>

    </Card>
    </>
  )
}

export { AutoEvaluacion }