import React, { useState, useEffect,useRef } from 'react'

import { FormService } from '../../../services/api/formService'
import { useSubmitCreate } from '../../DynamicForm/hooks/useSubmitCreate'
import { useFetchGet } from '../../../hooks/useFetchGet'
import { BASE_URL } from '../../../services/api/config'
import { DocumentUpload } from './DocumentUpload'

const ESTADO_DOCUMENTO = {
    NO_SE_HA_CARGADO_NADA:'0',
    APROBADO:'1',
    RECHAZADO:'2'
}

const DocumentUploadContainer = ({ item, offer,}) => {

    const URL = `requisitosAspirante?IdOferta=${item.IdOferta}&IdAspirante=`
    const [loading, setLoading] = useState(false)
    const [loadingFull, setLoadingFull] = useState(false)
    const [error, setError] = useState(null)
    const [documents, setDocuments] = useState([])
    const [registerPay, loadingRegister, errorRegister] = useFetchGet(item?.PagoInscripcion!=0?`pagos/${item?.PagoInscripcion}`:'')

    const [fetchDataCreate, statusCreate] = useSubmitCreate(
        'requisitosAspirante', 
        null, 
        null,
        '1'
    )
    const [urlBill,_] = useState(
        `${BASE_URL}/estudiante/descargarRecibo?IdOFerta=${item?.IdOferta}&IdAspirante=${item?.IdAspirante}&Concepto=${'inscripcion'}`
    )
    const [isloadingPay, setIsLoadingPay] = useState(false)
    const fetchDataDocuments = async () => {
        setLoadingFull(true)
        try {
            const formService = new FormService()
            const response = await formService.getAll(`${URL}${item?.IdAspirante}&IdTipoRequisito=1`)
            setLoadingFull(false)
            setDocuments(response)
        } catch (error) {
            setLoadingFull(false)
            setError(error)
        }
    }

    function filtrar(obj) {
        if (obj.RequisitoMatricula=='0') {
          return true;
        } else {
          return false;
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchDataDocuments()
        }
        fetchData()
    }, [])


    const created = async (formData, document) => {
        formData.set('IdAspirante', item?.IdAspirante)
        formData.set('IdRequisito', document?.IdRequisito)
        await fetchDataCreate(formData)
    }

    const update = async (formData, document) => {
        const formService = new FormService()
        formData.set('EstadoRequisito', "0")
        let dataRaw = Object.fromEntries(formData.entries());
        const id = document?.requisito?.IdRequisitoAspirante
        await formService.createWithFormData(
            `requisitosAspirante/${id}?_method=PUT`, 
            dataRaw
        )
    }

    const onPay = async () => {
    } 

    const onHandleSubmit = async (e, document) => {
        e.preventDefault()
        try {
            setLoading(true)
            const formData = new FormData(e.currentTarget)
            if (
                document?.requisito?.EstadoRequisito == 0 ||
                document?.requisito?.EstadoRequisito == 2
            ) await update(formData, document)
            else await created(formData, document)
            fetchDataDocuments()
            setLoading(false)
        } catch(err) {
            setError(err)
        }
    }

    return (
        <DocumentUpload 
            registerPay={ registerPay }
            isloadingPay={ isloadingPay }
            loadingFull={ loadingFull }
            onPay={ onPay }
            urlBill={ urlBill }
            documents={ documents }
            onHandleSubmit={ onHandleSubmit }
            error={ error }
            loading={ loading }
            oferta={item}
        />
    )
}

export { DocumentUploadContainer }