import { useState } from 'react'
import { Button, Card, Col, Modal, Row } from 'react-bootstrap'
import { useDispatch,useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { DynamicForm } from '../../../../components/DynamicForm/DynamicForm'
import { DynamicTable } from '../../../../components/DynamicForm/dynamicTable/DynamicTable'
import { alerta } from '../../../../helpers/alertas'
import { FormService } from '../../../../services/api/formService'

import { setItemSelected, setForm } from '../../../../components/DynamicForm/FormSlice'

function MomentosEvaluativos() {
    const api=new FormService();
    const location = useLocation()


    const[openModal, isOpenModal]=useState(false)
    const[reload,isReload]=useState(false)
    const oferta=location.state.curso
    const idUnidadAsignatura=location.state.asignatura.IdUnidadAsignatura
    const urlAsignaturas = `notasAsignatura?IdUnidadAsignatura=${idUnidadAsignatura}&IdOferta=${oferta.IdOferta}`
    const[endPoint,setEndpoint]=useState(urlAsignaturas)
    const[modalEliminar,setModalEliminar]=useState();
    const[modalEditar,setModalEditar]=useState();
    const[seleccionado,setSeleccionado]=useState();
    const[Nombre,setNombre]=useState();
    const[Porcentaje,setPocentaje]=useState();
    const dispatch=useDispatch();
    
    const actualizar=()=>{
        
        isReload(true)
        setTimeout(() => {
            isOpenModal()
            setModalEditar()
            setEndpoint(`${urlAsignaturas}?${Math.random()}`)
        }, 3000);
    }

    const eliminarRegistro=async()=>{
        var data={
            "Estado":"0"
        }
        let respuesta=await api.delete("notasAsignatura",data,seleccionado.IdNotaAsignatura)
        if(respuesta.respuesta=="exito"){
            alerta("Registro eliminado")
            setModalEliminar();
            setEndpoint(`${urlAsignaturas}?a=${Math.random()}`)
        }else{
            alerta("Fallo al eliminar registro")
        }
    }

    const onSeleccionar=(e)=>{
        console.log(e)
        setSeleccionado(e)
        setNombre(e.Nombre);
        setPocentaje(e.Porcentaje)
    }

    return (
        <>
            <Card>
                <Card.Title>Momentos Evaluativos</Card.Title>
                <Row>
                    <Col><p>{oferta.Nombre}</p></Col>
                    <Col>
                        <div style={{textAlign:'right'}}>
                            <Button onClick={()=>isOpenModal(true)}>Crear</Button>
                        </div>
                    </Col>
                </Row>
            </Card>
            <p style={{padding:10}}>Es necesario que la totalidad de los momentos evaluativos sumen el 100%, para que se puedan calificar las notas.</p>
            <Card>
                {oferta.IdDimension==1&&
                    <DynamicTable
                        titleColumn={['N°','Corte','Nombre', 'Acción']}
                        attributes={['Corte','Nombre']}
                        endPoint={endPoint}
                        reaload={reload}
                        onDelete={true}
                        onUpdate={true}
                        classNameOptionIcon={`bi bi-view-list`}
                        elimimarExterno={(e)=>{
                            setModalEliminar(true)
                            onSeleccionar(e)
                        }}
                        actualizarExterno={(e)=>{
                            setModalEditar(true)
                            dispatch(setItemSelected(e))
                            dispatch(setForm({"llave_primaria":"IdNotaAsignatura","form_data":"0"}))
                        }}
                    />
                }
                {oferta.IdDimension!=1&&
                    <DynamicTable
                        titleColumn={['N°','Tipo','Nombre', 'Porcentaje', 'Acción']}
                        attributes={['Corte','Nombre', 'Porcentaje']}
                        endPoint={endPoint}
                        reaload={reload}
                        onDelete={true}
                        onUpdate={true}
                        classNameOptionIcon={`bi bi-view-list`}
                        elimimarExterno={(e)=>{
                            setModalEliminar(true)
                            onSeleccionar(e)
                        }}
                        actualizarExterno={(e)=>{
                            setModalEditar(true)
                            dispatch(setItemSelected(e))
                            dispatch(setForm({"llave_primaria":"IdNotaAsignatura","form_data":"0"}))
                        }}
                    />
                }

                

            </Card> 
            <Modal
                show={openModal}
                onHide={()=>isOpenModal()}
            >

            {oferta.IdDimension==1&&
                <DynamicForm 
                    title={`Crear Momento Evaluativo`}
                    onClickCancelBtn={()=>isOpenModal(false)} 
                    accionMysql={actualizar}
                    isCreate={true} 
                    justDynamicForm={{flag:true,idForm:"62"}}
                    endPointSubmit="notasAsignatura"
                    extraData={{
                        IdOferta:oferta.IdOferta,
                        IdMalla:location.state.asignatura.IdMalla,
                        IdUnidadAsignatura:location.state.asignatura.IdUnidadAsignatura,
                        Porcentaje:100
                    }}
                />
            }

            {oferta.IdDimension!=1&&
                <DynamicForm 
                    title={`Crear Momento Evaluativo`}
                    onClickCancelBtn={()=>isOpenModal(false)} 
                    accionMysql={actualizar}
                    isCreate={true} 
                    justDynamicForm={{flag:true,idForm:"117"}}
                    endPointSubmit="notasAsignatura"
                    extraData={{
                        IdOferta:oferta.IdOferta,
                        IdMalla:location.state.asignatura.IdMalla,
                        IdUnidadAsignatura:location.state.asignatura.IdUnidadAsignatura
                    }}
                />
            }
               

            </Modal>
            <Modal
                show={modalEliminar}
                onHide={()=>setModalEliminar()}
            >
                <Modal.Body>
                    <b>Deseas eliminar este registro?</b>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={()=>setModalEliminar()}
                    >Cancelar</Button>

                    <Button
                    variant="danger"
                    onClick={()=>eliminarRegistro()}
                    >
                        Elimnar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* modal para actualizar*/}
            {oferta.IdDimension==1&&
                <Modal
                    show={modalEditar}
                    onHide={()=>setModalEditar()}
                >
                    <DynamicForm 
                        title={`Actualizar Momento Evaluativo`}
                        onClickCancelBtn={()=>setModalEditar(false)} 
                        accionMysql={actualizar}
                        isCreate={false} 
                        extraData={{
                        IdOferta:oferta.IdOferta,
                        IdMalla:location.state.asignatura.IdMalla,
                        IdUnidadAsignatura:location.state.asignatura.IdUnidadAsignatura
                    }}
                        justDynamicForm={{flag:true,idForm:"62"}}
                        endPointSubmit="notasAsignatura"
                    />
                </Modal>
            }

            {oferta.IdDimension!=1&&
            <Modal
                show={modalEditar}
                onHide={()=>setModalEditar()}
            >
                <DynamicForm 
                    title={`Actualizar Momento Evaluativo`}
                    onClickCancelBtn={()=>setModalEditar(false)} 
                    accionMysql={actualizar}
                    isCreate={false} 

                    justDynamicForm={{flag:true,idForm:"117"}}
                    endPointSubmit="notasAsignatura"
                />
            </Modal>

            }

            
        </>
    )
}

export { MomentosEvaluativos }
