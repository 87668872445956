import { DynamicForm } from "../../../../components/DynamicForm/DynamicForm";
import { DynamicFormContainer } from "../../../../components/DynamicForm/DynamicFormContainer";
import { DynamicTable } from "../../../../components/DynamicForm/dynamicTable/DynamicTable"

function Coordinadores () {
  return (
    <>
    {/* <DynamicTable
    endPoint={'coordinadores'}
    titleColumn={['N°','Nombre','Apellido', 'N° Documento' ,'Email', 'Telefono', ]}
    attributes={['PrimerNombres', 'PrimerApellidos','NumeroDocumento','email', 'TelefonoContacto']}
    onDelete={false}
    onUpdate={false}
    onActions={false}
    ></DynamicTable> */}

    <DynamicFormContainer idForm={'87'}  />
    </>
  )
}

export { Coordinadores };