import React from 'react'
import { Button } from 'react-bootstrap'

export default function FormulariosDinamicos() {
  return (
    <>
    <div
        style={{
            textAlign: 'center',
            marginTop: '2rem',
        }}
    >
        <h1>Formularios Dinámicos</h1>
        <Button 
          href="https://formularios-cea.netlify.app"
          target='_blank'
        >
          Gestionar Formularioss
        </Button>
    </div>
        
    </>
  )
}
