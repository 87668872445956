import { useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import { useEffect } from "react";
import { useFetchGet } from "../../../hooks/useFetchGet";
import { BASE_URL,} from "../../../services/api/config";
import { DynamicForm} from '../../../components/DynamicForm/DynamicForm';
import { FormService } from "../../../services/api/formService";
import './EstudianteDetalleCurso.css'
import LocalStorage from "../../../services/localStorage/LocalStorage";
import { Badge, Button, Card, Dropdown, DropdownButton, ListGroup, Modal, Table } from "react-bootstrap";
import { BsXLg } from "react-icons/bs";
import { TextArea } from "../../../components/componentsForm/textarea/textarea";
import { alerta } from "../../../helpers/alertas";
import { Select } from '../../../components/select/Select';
import DowloadFile from '../../../components/DowloadFile/DowloadFile';

function EstudianteDetalleCursoActivo () {
  const local=new LocalStorage();
  const usuario=JSON.parse(local.getItem("usuario")) 

  const {state:{programa}} = useLocation()
  const [key, setKey] = useState('notas');
  const [DataestudianteDiploma,loadingDiploma,errorDiploma] = useFetchGet(`diplomaEstudiante?IdOferta=${programa.IdOferta}&IdEstudiante=${programa.IdEstudiante}`)
  const [showDetallesEstudiante, setShowDetallesEstudiante] = useState(false);
  const [estudianteSelected, setEstudianteSelected] = useState({notas:[],asignatura:''});
  const [urlDownloadDiploma, setUrlDownloadDiploma] = useState({notas:[],asignatura:''});
  const [showBtnDiploma, setShowBtnDiploma] = useState(false);
  const api = new FormService()
  const navigate = useNavigate()
  const urlAsignaturas=`estudiante/notas?IdOferta=${programa.IdOferta}&IdEstudiante=${programa.IdEstudiante}`
  const [notas,setNotas]=useState([]);
  const [average,setAverage] = useState(0)
  const [respondio,setRespondio]=useState();
  const [malla,setMalla]=useState();
  const [modalHorario,setModalHorario]=useState();
  const [horario, setHorario] = useState();
  const [mensajeCancelacion,setMensajeCancelacion]=useState(null);
  const [modalActaMatricula,setModalActaMatricula]=useState(false);
  const[modalCancelar,setModalCancelar]=useState();
  // const urlActa=`${BASE_URL}/estudiante/descargarActaMatricula?IdEstudiante=${programa.IdEstudiante}`
  const urlHorario=`${BASE_URL}/formatosIES/exportar/horarioResumido?IdOferta=6907`
  
  const onVerPlandeTrabajo = (row) => {
    navigate(`${row.IdMalla}/plandeTrabajo`,{state:{oferta:row,programa}})
  }
  const onSeeAsistencia = (row) => {
    navigate(`${row.IdMalla}/asistencia`,{state:{oferta:row,programa}})
  }
  const onEvaluarPrograma = () => {
    navigate(`${programa.IdOferta}/evaluaciones`,{state:{programa}})
  }

  const onVerDetalles=(estudiante)=>{
    setEstudianteSelected(estudiante)
    setShowDetallesEstudiante(true)
  }
  const onSolicitarDiploma = async () => {
    // /estudiante/diploma?IdOferta=4&IdEstudiante=1
  }

  const consultarAsignaturas=async()=>{
    const asignaturas=await api.getAll(urlAsignaturas)
    setNotas(asignaturas)
  }


  const calcularNotaFinal = (notasArray) => {
    if(notasArray.length===0){return '-'}
    let final = notasArray.reduce(
      (prev, curr)=>{
        let currentNota = curr?.Nota || null
       if(curr.Nota===null)currentNota='0'
        const porcentaje = (parseFloat(curr.Porcentaje)/100).toFixed(3)
        const nota = parseFloat(currentNota).toFixed(3)
        return prev + (nota*porcentaje)
      },0)
    return final
  }

  useEffect(() => {
      if(DataestudianteDiploma?.respuesta==='true' && DataestudianteDiploma.diploma?.IdDiploma) {
        const url = `${BASE_URL}/estudiante/diploma?IdDiploma=${DataestudianteDiploma.diploma.IdDiploma}`
        setUrlDownloadDiploma(url)
        setShowBtnDiploma(true)

      }
      if(notas.length>0){
        const avg = notas.reduce(
              (acc,cur) => {
               return (parseFloat(acc) + parseFloat(cur?.nota || '0')/notas.length )
              } 
            ,0) 
        setAverage(avg.toFixed(2))
      }    
      const fetchData=async()=>{
        consultarRespondio()
      }
      fetchData()

  },[notas,DataestudianteDiploma])

  useEffect(()=>{
    const fetchData=async()=>{
      consultarAsignaturas();
    }
    fetchData()
  },[])

  const consultarRespondio=async()=>{
      const respuesta =await api.getAll(`estudiante/respondio?IdOferta=${programa.IdOferta}&IdEstudiante=${programa.IdEstudiante}&IdUsuario=${usuario.usuario.IdUsuario}`)
      if(respuesta.respuesta=="exito"){
        setRespondio(false)
      }else{
        setRespondio(true)
      }
  }
  const actualizar=()=>{
    setTimeout(() => {
      consultarRespondio()
    }, 3000);
  }
  const goSolicitarAsignaturas=()=>{
    navigate("solicitar_asignaturas",{state:{IdOferta:programa.IdOferta,IdEstudiante:programa.IdEstudiante,IdUsuario:usuario.usuario.IdUsuario}})
  }

  const cancelarAsignatura=async()=>{
    if(mensajeCancelacion==null){
      alerta("Escribe el motivo")
      return
    }
    const data={
      "IdOferta":programa.IdOferta,
      "IdMallaAspirante":malla.IdMallaAspirante,
      "MensajeCancelacion":mensajeCancelacion
    }

    const respuesta=await api.create("educacionIES/cancelarMiAsignatura",data)
    if(respuesta.respuesta=="exito"){
      consultarAsignaturas()
      alerta(respuesta.mensaje)
      setMensajeCancelacion()
      setModalCancelar()
    }else if(respuesta.respuesta=="error"){
      alerta(respuesta.mensaje)
    }else{
      alerta("Fallo desconocido")
    }


  }
 
  return ( 
 <>
 {/* {respondio ?
  <Card>
      <DynamicForm 
      justDynamicForm = {{ flag: true, idForm: 99 }}
      isCreate={true}
      endPointSubmit={"bienestarFormularios"}
      extraData={{IdOferta:programa.IdOferta,IdEstudiante:programa.IdEstudiante,IdUsuario:usuario.usuario.IdUsuario}}
      accionMysql={actualizar}
    />
  </Card>
  : */}
  <>
  <Card>
  <Card.Title> {programa.Nombre} </Card.Title>
    <div style={{textAlign:"right",paddingBottom:10}}>
        {/* Boton para abir el modal de acta de matricula */}
        {/* <Button
          variant="light"
          onClick={()=>{setModalActaMatricula(true)}}
        >
          <i class="bi bi-file-earmark-text"></i>  Acta de Matrícula
        </Button>
        {' '} */}
        {/* boton para descargar horario */}
        <Button
          variant="light"
          onClick={()=>setModalHorario(true)}
        >
          Descargar Horario
        </Button>
        {' '}
        <Button
          variant="light"
          onClick={()=>{goSolicitarAsignaturas()}}
          style={{marginRight:10}}
        >
          Solicitar Asignaturas
        </Button>

        {programa.verevaluaciones==1 &&
            <Button
              onClick={()=>{onEvaluarPrograma()}}
            >
            <i class="bi bi-pencil"></i> Instrumentos de Proceso Autoevaluación
            </Button> 
        }
        {showBtnDiploma && 
          <a 
          target={'_blank'}
          rel="noreferrer"
          style={{color:'white'}}
          onClick={()=>onSolicitarDiploma} 
          href={urlDownloadDiploma} 
          // href={`${BASE_URL}/diploma/estudiante?IdOferta=${programa.IdOferta}&IdEstudiante=${programa.IdEstudiante}`} 
          download
          ><i class="bi bi-file-earmark-text"></i>  Solicitar Diploma</a>
        }
      </div>

      
  <Table striped bordered hover>
    <thead>
      <tr>
        <th>N°</th>
        <th>Asignatura</th>
        <th>Docente</th>
        <th>Tipo</th>
        <th>Creditos</th>
        <th>Horas</th> 
        {/* <th>Estado</th> */}
        <th>Acciones</th>
      </tr>
    </thead>
  <tbody>
          {!!notas && notas.map((element,index)=>{
            return(
              <tr key={index}>
                <td>{index+1}</td>
                <td>{element.asignatura}</td>
                <td>{element?.Docente}</td>
                <td>{element?.TipoAsignatura}</td>
                <td>{element?.Creditos}</td>
                <td>{element?.horas}</td>
                {/* <td>{element.estado}</td> */}
                <td>
                  <DropdownButton>
                    {element?.IdDimension == 1 && 
                      <>
                      <Dropdown.Item
                        onClick={()=>onVerPlandeTrabajo(element)}
                      >
                        <i class="bi bi-activity"/> Plan de Trabajo 
                      </Dropdown.Item>
                      </>
                    }
                    <Dropdown.Item
                      onClick={()=>onSeeAsistencia(element)}
                    >
                      <i class="bi bi-calendar3"/> Asistencias 
                      {/* {calcularNotaFinal(element.notas)}  */}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={()=>onVerDetalles(element)}
                    >
                    <i class="bi bi-eye"/> Notas
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={()=>{
                        setMalla(element)
                        setModalCancelar(true)
                      }}
                    >
                      <BsXLg/> Cancelar
                    </Dropdown.Item>
                  </DropdownButton>
                </td>
              </tr>
            )
          })}
    </tbody>
  </Table>
  </Card>
    
  <Modal
    show={showDetallesEstudiante}
    onHide={()=>setShowDetallesEstudiante(false)}
  >
    <Modal.Header closeButton>
      <Modal.Title>
      {estudianteSelected && estudianteSelected?.asignatura}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <ListGroup>
          {estudianteSelected && estudianteSelected?.notas.map((nota,index)=>{
            return(
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-start"
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">{nota?.Nombre}</div>
                  {nota?.Nota || '-'}
                </div>
                <Badge bg="primary" pill>
                {nota?.Porcentaje}% 
                </Badge>
              </ListGroup.Item>
            )
          })}
        </ListGroup>
        {<ul className="nota-definitiva"><b>Definitiva </b>: {estudianteSelected?.definitiva}</ul>}
      </Modal.Body>
    </Modal>
    <Modal
      show={modalCancelar}
      onHide={()=>setModalCancelar()}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          ¿ Desea cancelar la asignatura {malla?.asignatura}?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TextArea 
          label="Describe el motivo"
          value={mensajeCancelacion}
          onChange={setMensajeCancelacion}
        />

      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={()=>setModalCancelar()}
        >
          Cancelar
        </Button>
        <Button 
          onClick={()=>cancelarAsignatura()}
        >
          Aceptar
        </Button>
      </Modal.Footer>
    </Modal>

    {/* filtra descarga del horario por fecha inicial y fecha fina en un modal    */}
            <Modal
                show={modalHorario}
                onHide={()=>setModalHorario(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Descarga Horario de Clases</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                      <Select
                        id="Horarios"
                        name="Horarios"
                        onChange={ (e) => setHorario(e) }
                        value={ horario || null }
                        label="Seleccionar Horario"
                        required={true}
                        optionValue="IdHorario"
                        optionName="Nombre"
                        urlOptionsService={`cursos/horarios?IdOferta=${programa.IdOferta}`}
                      />
                </Modal.Body>
                <Modal.Footer>
                      <DowloadFile
                          nameFile={"Horario.pdf"}
                          name={"Descargar Horario"}
                          endPoint={`${BASE_URL}/formatosIES/exportar/horarioResumido?IdOferta=${programa.IdOferta}&IdHorario=${horario}`}
                      />
                </Modal.Footer>
            </Modal>

    {/* Modal para el acta de matricula */}
    {/* <Modal
      show={modalActaMatricula}
      onHide={()=>setModalActaMatricula(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Acta de Matrícula
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <a href={`${urlActa}`}>Descargar Acta de Matrícula</a>
           
      </Modal.Body>
      </Modal> */}


    </>
  </>
    
  )
}

export { EstudianteDetalleCursoActivo }