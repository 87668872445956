import { Route, Routes } from 'react-router-dom'
import { DynamicFormContainer } from '../../components/DynamicForm/DynamicFormContainer'
import { AsignaturaEstudiantes } from './CursosActivos/AsignaturaEstudiantes'
import { ProgramasADictar } from './CursosActivos/ProgramasADictar'
import { AutoEvaluacion } from './AutoEvaluacion/AutoEvaluacion'
import { CalendarioAsignatura } from './calendario/CalendarioAsignaturas'
import { CalendarioDocente } from './calendario/CalendarioDocente'
import { ProgramasFinalizadosList } from './CursosFinalizados/ProgramasFinalizadosList'
import { AsignaturasCurso } from '../componentesC/AsignaturasCurso/AsignaturasCurso' 
import { ListadoEvaluaciones } from './AutoEvaluacion/ListadoEvaluaciones'
import { MomentosEvaluativos } from '../componentesC/momentosEvaluativos/MomentosEvaluativos' 
import { Docentes } from './docentes/Docentes'
import { Notificaciones } from "./Notificaciones"
import { EstudianteNotas } from '../componentesC/EstudianteNotas/EstudianteNotas'
import { ActiveOffers } from "../../components/outlet/ActiveOffers/ActiveOffers"
import { EstudianteHistorial } from '../componentesC/estudianteHistorial/EstudianteHistorial'
import { PerfilEstudiante } from '../componentesC/estudianteHistorial/PerfilEstudiante'
import { Applicant } from '../../components/outlet/Applicant/Applicant'
import { ApplicantListContainer } from '../../components/outlet/ApplicantList/ApplicantListContainer'
import {EstudianteAsistencias} from '../componentesC/EstudianteAsistencias/EstudianteAsistencias' 
import { HistorialCurso } from '../componentesC/historialCurso/HistorialCurso'
// import SolicitudGrado from '../AutoservicioGrado/CursosAprobados'
import AsignaturasSolicitadas from '../componentesC/AsginaturasSolicitadas/AsignaturasSolicitadas'
import { UnidadesAsignatura } from '../componentesC/UnidadesAsignatura/UnidadesAsignatura'
import EducacionSuperior from './EducacionSuperior/EducacionSuperior'
import PeridosInscritos from './EducacionSuperior/PeridosInscritos'
import PeriodosActivos from './EducacionSuperior/PeriodosActivos'
import PeriodosFinalizados from './EducacionSuperior/PeriodosFinalizados'
import Cohortes from './EducacionSuperior/Cohortes'
import Estudiantes from './EducacionSuperior/Estudiantes'
import CohortesMatriculados from './EducacionSuperior/CohortesMatriculados'

import TrazabilidadAdmision from './TrazabilidadAdmision/TrazabilidadAdmision'
import { AsignaturasEstudiantes } from '../componentesC/AsignaturasEstudiante/AsignaturasEstudiantes'
import { CalificarNotas } from '../componentesC/CalificarNotas/CalificarNotas'
import { CalificarAsistencias } from '../componentesC/CalificarAsistencias/CalificarAsistencias'
import Semaforizacion from './EducacionSuperior/Semaforizacion'
import { HorariosLight } from '../coordinadorAcademico/HorariosLight/HorariosLight'
import HorarioLight from '../coordinadorAcademico/HorariosLight/HorarioLight'


function Registro(props) {
    return (
        <>
            <Routes>
                <Route
                    path="calendario_docente"
                    element={<CalendarioAsignatura />}
                ></Route>
                <Route
                    path="calendario_docente/:id/detalle_calendario"
                    element={<CalendarioDocente />}
                ></Route>
                {/* Cursos activos */}
                <Route
                    path="cursos_activos"
                    element={<ProgramasADictar />}
                ></Route>
                <Route path="cursos_activos/:idoferta/asignaturas" element={<AsignaturasCurso editable={true}/>} ></Route>
                <Route path="cursos_activos/:idoferta/asignaturas/:idmalla/asignatura_estudiantes" element={<AsignaturaEstudiantes />} ></Route>
                <Route path="cursos_activos/:idoferta/asignaturas/:idmalla/momentos_evaluativos" element={<MomentosEvaluativos editable={true} />} ></Route>
                <Route path="cursos_activos/:idoferta/asignaturas/:idmalla/unidades_asignatura" element={<UnidadesAsignatura editable={true} />} ></Route>
                <Route  path="cursos_activos/:idoferta/asignaturas/:idusario/detalle_programa" element = {<EstudianteNotas />}> </Route>
                <Route  path="cursos_activos/:idoferta/asignaturas/:idusario/detalle_programa/trazabilidad" element = {<TrazabilidadAdmision />}> </Route>
                <Route  path="cursos_activos/:idoferta/asignaturas/:idusario/detalle_programa/asignaturas_solicitadas" element = {<AsignaturasSolicitadas />}> </Route> 
                <Route  path="cursos_activos/:idoferta/asignaturas/:idusario/detalle_programa/:id/asistencia" element = {<EstudianteAsistencias/>}> </Route> 
                <Route path="cursos_activos/:idoferta/asignaturas/:idmalla/asignatura_estudiantes/notas" element={<EstudianteNotas/>} ></Route>

                {/*  */}
                
                <Route path="cursos_finalizados" element={<ProgramasFinalizadosList />} ></Route>
                <Route path="cursos_finalizados/:idoferta/asignaturas" element={<AsignaturasCurso editable={false}/>} ></Route>
                <Route path="cursos_finalizados/:idoferta/asignaturas/:idmalla/asignatura_estudiantes" element={<AsignaturaEstudiantes />} ></Route>
                <Route path="cursos_finalizados/:idoferta/asignaturas/:idmalla/unidades_asignatura" element={<UnidadesAsignatura />} ></Route>
                <Route  path="cursos_finalizados/:idoferta/asignaturas/:idusario/detalle_programa" element = {<EstudianteNotas />}> </Route> 
                <Route  path="cursos_finalizados/:idoferta/asignaturas/:idusario/detalle_programa/trazabilidad" element = {<TrazabilidadAdmision />}> </Route> 
                <Route  path="cursos_finalizados/:idoferta/asignaturas/:idusario/detalle_programa/:id/asistencia" element = {<EstudianteAsistencias/>}> </Route>                 
                <Route path="cursos_finalizados/:idoferta/asignaturas/:idmalla/momentos_evaluativos" element={<MomentosEvaluativos editable={false} />} ></Route>
                <Route path="cursos_finalizados/:idoferta/asignaturas/:idmalla/asignatura_estudiantes/notas" element={<EstudianteNotas/>} ></Route>
                {/*  */}        
                <Route
                    path="auto_evaluaciones"
                    element={<ListadoEvaluaciones />}
                ></Route>
                <Route
                    path="auto_evaluaciones/:IdAutoEvaluacion"
                    element={<AutoEvaluacion />}
                ></Route>
                <Route path='docentes' element={<Docentes/>}></Route>
                <Route  path="notificaciones" element = {<Notificaciones />}></Route>

                <Route path="inscripciones" element={<ActiveOffers full={true} />} />
                <Route path="inscripciones/lista-aspirante/:idApplicantList" element={<ApplicantListContainer />} />
                <Route path="inscripciones/lista-aspirante/:idApplicantList/estado-aspirante/:idApplicant" element={<Applicant  full={true} />} />
                
                <Route path="historial_de_estudiante" element={<EstudianteHistorial/>}/>
                <Route path="historial_de_estudiante/perfil_estudiante" element={<PerfilEstudiante/>}/>
                <Route path="historial_de_estudiante/perfil_estudiante/asignaturas" element={<EstudianteNotas/>}/>
                <Route path="historial_de_estudiante/perfil_estudiante/asignaturas/:id/asistencia" element = {<EstudianteAsistencias/>}></Route> 
                
                <Route path="historial_de_curso" element={<HistorialCurso/>}/>
                <Route
                    path="historial_de_curso/:idoferta/asignaturas"
                    element={<AsignaturasCurso editable={false}/>}
                ></Route>
                <Route path='educacion_superior' element={<EducacionSuperior/>} />
                <Route path='educacion_superior/peridos_inscritos' element={<PeridosInscritos/>} />
                <Route path="educacion_superior/peridos_inscritos/lista-aspirante/:idApplicantList" element={<ApplicantListContainer />} />
                <Route path="educacion_superior/peridos_inscritos/lista-aspirante/:idApplicantList/estado-aspirante/:idApplicant" element={<Applicant  full={true} />} />

                <Route path='educacion_superior/peridos_activos' element={<PeriodosActivos/>} />
                <Route path="educacion_superior/peridos_activos/:idoferta/asignaturas" element={<AsignaturasCurso editable={true}/>} ></Route>
                <Route path="educacion_superior/peridos_activos/:idoferta/asignaturas/horarios" element={<HorariosLight/>} ></Route>
                <Route path="educacion_superior/peridos_activos/:idoferta/asignaturas/horarios/horario" element={<HorarioLight />} ></Route>
                <Route path="educacion_superior/peridos_activos/:idoferta/asignaturas/:idmalla/asignatura_estudiantes" element={<AsignaturaEstudiantes />} ></Route>
                <Route path="educacion_superior/peridos_activos/:idoferta/asignaturas/:idmalla/momentos_evaluativos" element={<MomentosEvaluativos editable={true} />} ></Route>
                <Route path="educacion_superior/peridos_activos/:idoferta/asignaturas/:idmalla/unidades_asignatura" element={<UnidadesAsignatura editable={true} />} ></Route>
                <Route path="educacion_superior/peridos_activos/:idoferta/asignaturas/:idmalla/unidades_asignatura/notas_y_asistencias" element={<AsignaturasEstudiantes editable={true} />} ></Route>
                <Route path="educacion_superior/peridos_activos/:idoferta/asignaturas/:idmalla/unidades_asignatura/notas_y_asistencias/notas" element={<CalificarNotas editable={true} />} ></Route>
                <Route path="educacion_superior/peridos_activos/:idoferta/asignaturas/:idmalla/unidades_asignatura/notas_y_asistencias/asistencias" element={<CalificarAsistencias editable={true} />} ></Route>
                <Route path="educacion_superior/peridos_activos/:idoferta/asignaturas/:idusario/detalle_programa" element = {<EstudianteNotas />}> </Route> 
                <Route path="educacion_superior/peridos_activos/:idoferta/asignaturas/:idusario/detalle_programa/trazabilidad" element = {<TrazabilidadAdmision />}> </Route> 
                <Route path="educacion_superior/peridos_activos/:idoferta/asignaturas/:idusario/detalle_programa/asignaturas_solicitadas" element = {<AsignaturasSolicitadas />}> </Route> 
                <Route path="educacion_superior/peridos_activos/:idoferta/asignaturas/:idusario/detalle_programa/:id/asistencia" element = {<EstudianteAsistencias/>}> </Route> 
                <Route path="educacion_superior/peridos_activos/:idoferta/asignaturas/:idmalla/asignatura_estudiantes/notas" element={<EstudianteNotas/>} ></Route>

                <Route path='educacion_superior/peridos_finalizados' element={<PeriodosFinalizados/>} />
                <Route path="educacion_superior/peridos_finalizados/:idoferta/asignaturas" element={<AsignaturasCurso editable={false}/>} ></Route>
                <Route path="educacion_superior/peridos_finalizados/:idoferta/asignaturas/:idmalla/asignatura_estudiantes" element={<AsignaturaEstudiantes />} ></Route>
                <Route path="educacion_superior/peridos_finalizados/:idoferta/asignaturas/:idmalla/unidades_asignatura" element={<UnidadesAsignatura />} ></Route>
                <Route  path="educacion_superior/peridos_finalizados/:idoferta/asignaturas/:idusario/detalle_programa" element = {<EstudianteNotas />}> </Route> 
                <Route  path="educacion_superior/peridos_finalizados/:idoferta/asignaturas/:idusario/detalle_programa/trazabilidad" element = {<TrazabilidadAdmision />}> </Route> 

                <Route  path="educacion_superior/peridos_finalizados/:idoferta/asignaturas/:idusario/detalle_programa/:id/asistencia" element = {<EstudianteAsistencias/>}> </Route>                 
                <Route path="educacion_superior/peridos_finalizados/:idoferta/asignaturas/:idmalla/momentos_evaluativos" element={<MomentosEvaluativos editable={false} />} ></Route>
                <Route path="educacion_superior/peridos_finalizados/:idoferta/asignaturas/:idmalla/asignatura_estudiantes/notas" element={<EstudianteNotas/>} ></Route>

                <Route path='educacion_superior/cohortes' element={<Cohortes/>} />
                <Route path='educacion_superior/cohortes/matriculados' element={<CohortesMatriculados/>} />
            
                <Route path='educacion_superior/estudiantes' element={<Estudiantes/>} />
                <Route path='educacion_superior/estudiantes/semaforizacion' element={<Semaforizacion/>} />

                <Route path="*" element={<DynamicFormContainer />}></Route>
            </Routes>
        </>
    )
}

export { Registro }
