import React, { useState } from 'react';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import TablePaginate from '../../../components/TablePaginate/TablePaginate';
import { alerta } from '../../../helpers/alertas';
import { SelectDataList } from '../../../components/SelectDataList/SelectDataList';
import { FormService } from '../../../services/api/formService';
import { BASE_URL } from '../../../services/api/config';
import DowloadFileText from '../../../components/DowloadFileText/DowloadFileText';
import { BsArrowReturnRight, BsFillFilePdfFill, BsFillFileExcelFill } from 'react-icons/bs';
import DowloadFile from '../../../components/DowloadFile/DowloadFile';


export default function ExamenesSuficiencia() {
  const api = new FormService();
  const usuario = JSON.parse(localStorage.getItem('usuario')).usuario;
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    IdMalla: '',
    IdCurso: '',
    Fecha: '',
    conceptoDocente: '',
    conceptoJurado: '',
    PocentajeEscritoDocente: 0,
    PocentajeOralDocente: 0,
    PocentajeOralJurado: 0,
    NotaEscritoDocente: 0,
    NotaOralDocente: 0,
    NotaOralJurado: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const totalCalificacion =
      parseInt(formData.PocentajeEscritoDocente) +
      parseInt(formData.PocentajeOralDocente) +
      parseInt(formData.PocentajeOralJurado);
    if (totalCalificacion !== 100) {
      alerta('La suma de los porcentajes debe ser 100%', 'error');
      return;
    }
    const data = {
      ...formData,
      IdDocente: usuario.IdUsuario,
      IdCurso: 100,
    };
    const response = await api.create('examenes', data);
    if (response.success) {
      alerta('Examen creado correctamente', 'success');
      setShow(false);
    } else {
      alerta('Error al crear el examen', 'error');
    }
  };

  const descargarActa = async (IdExamen) => {
    const response = await api.getAll(`formatosIES/examenes/acta?IdExamen=${IdExamen}`);
    const exportarActa = `${BASE_URL}formatosIES/examenes/acta?IdExamen=${IdExamen}`;
    if (response.success) {
      const url = `${BASE_URL}/storage/${response.data}`;
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      link.download = `Acta_Examen_${IdExamen}.pdf`;
  
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('Error al descargar el acta del examen.');
    }
  };

  return (
    <>
      <Card>
        <Row>
          <Col>
            <Card.Title>Examenes de suficiencia</Card.Title>
          </Col>
          <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="primary" onClick={() => setShow(true)}>
              Crear
            </Button>
          </Col>
        </Row>
      </Card>

      <TablePaginate
        apiUrl={'examenes'}
        tableHeaders={[
          { title: 'Nombre', attribute: 'Usuario' },
          { title: 'Programa', attribute: 'Curso' },
          { title: 'Fecha', attribute: 'Fecha' },
        ]}
        rowActions={[
          ({ rowData }) => (
            <DowloadFile
                nameFile={"Acta.pdf"}
                name={"Exportar Acta"}
                endPoint={`${BASE_URL}/formatosIES/examenes/acta?IdExamen=${rowData.IdExamen}`}
            />
          ),
        ]}
      />

      <Modal show={show} onHide={() => setShow(false)}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Crear examen de suficiencia</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form.Label>Usuario - Estudiante</Form.Label>
            <SelectDataList
              endPoinLastUrl={'usuarios/completos'}
              optionName={'Nombre'}
              optionValue={'IdUsuario'}
              onChangeHandler={(d) => handleChange({ target: { name: 'IdEstudiante', value: d } })}
            />

            <Form.Group controlId="nombreAsignatura">
              <Form.Label>Asignatura</Form.Label>
              <SelectDataList
                endPoinLastUrl={'asignaturasPrograma?IdOferta=6907'}
                optionName={'Descripcion'}
                optionValue={'IdMalla'}
                onChangeHandler={(d) => handleChange({ target: { name: 'IdMalla', value: d } })}
              />
            </Form.Group>
            <Form.Group controlId="Fecha">
              <Form.Label>Fecha</Form.Label>
              <Form.Control
                type="date"
                name="Fecha"
                value={formData.Fecha}
                onChange={handleChange}
              />
            </Form.Group>
            <Row
              style={{
                textAlign: 'center',
                padding: '10px',
              }}
            >
              <h5>Calificación del docente</h5>
            </Row>
            <Row>
              <Col>EXAMEN ESCRITO</Col>
              <Col>
                <Form.Group controlId="PocentajeEscritoDocente">
                  <Form.Label>Porcentaje</Form.Label>
                  <Form.Control
                    type="number"
                    name="PocentajeEscritoDocente"
                    value={formData.PocentajeEscritoDocente}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="NotaEscritoDocente">
                  <Form.Label>Nota</Form.Label>
                  <Form.Control
                    type="number"
                    name="NotaEscritoDocente"
                    value={formData.NotaEscritoDocente}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>EXAMEN ORAL</Col>
              <Col>
                <Form.Group controlId="PocentajeOralDocente">
                  <Form.Label>Porcentaje</Form.Label>
                  <Form.Control
                    type="number"
                    name="PocentajeOralDocente"
                    value={formData.PocentajeOralDocente}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="NotaOralDocente">
                  <Form.Label>Nota</Form.Label>
                  <Form.Control
                    type="number"
                    name="NotaOralDocente"
                    value={formData.NotaOralDocente}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group controlId="conceptoDocente">
              <Form.Label>Concepto</Form.Label>
              <Form.Control
                type="text"
                name="conceptoDocente"
                value={formData.conceptoDocente}
                onChange={handleChange}
              />
            </Form.Group>
            <Row
              style={{
                textAlign: 'center',
                padding: '10px',
              }}
            >
              <h5>Calificación Jurado</h5>
            </Row>
            <Row>
              <Col sm={4}>Jurado</Col>
              <Col>
                <SelectDataList
                  endPoinLastUrl={'usuarios/completos'}
                  optionName={'Nombre'}
                  optionValue={'IdUsuario'}
                  onChangeHandler={(d) => handleChange({ target: { name: 'IdJurado', value: d } })}
                />
              </Col>
            </Row>
            <Row>
              <Col>EXAMEN ORAL</Col>
              <Col>
                <Form.Group controlId="PocentajeOralJurado">
                  <Form.Label>Porcentaje</Form.Label>
                  <Form.Control
                    type="number"
                    name="PocentajeOralJurado"
                    value={formData.PocentajeOralJurado}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="NotaOralJurado">
                  <Form.Label>Nota</Form.Label>
                  <Form.Control
                    type="number"
                    name="NotaOralJurado"
                    value={formData.NotaOralJurado}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group controlId="conceptoJurado">
              <Form.Label>Concepto</Form.Label>
              <Form.Control
                type="text"
                name="conceptoJurado"
                value={formData.conceptoJurado}
                onChange={handleChange}
              />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
              Cerrar
            </Button>
            <Button variant="primary" type="submit">
              Enviar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
