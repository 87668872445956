import React from 'react'
import { Button } from 'react-bootstrap'
import { BsFillBookFill, BsFillPersonFill, BsGeoAltFill, BsPencilFill, BsPlus, BsTrashFill } from 'react-icons/bs'

export default function EventosBloque({
    bloque,
    dia,
    seleccionarDia,
    editarEvento,
    eliminarEvento
}){
    const eventos=bloque.eventos.filter(evento=>evento.Dia==dia)
  return (
    <>
        {eventos.map((evento,index)=>(
            <div key={index}>
                <Button
                    onClick={()=>editarEvento(evento)} 
                    size='sm'
                >   
                    <BsPencilFill/>
                </Button>
                {' '}
                <Button
                    onClick={()=>eliminarEvento(evento)} 
                    size='sm'
                >   
                    <BsTrashFill/>
                </Button>
                <div>
                    <BsGeoAltFill/>{evento.Aula}    
                </div>
                <div>
                <BsFillBookFill/> {evento.UnidadAsignatura}    
                </div>
                {evento.docentes.map((docente,index)=>(
                    <div key={index}>
                        <BsFillPersonFill/> {docente.Nombre}
                    </div>
                ))}
            </div>
        ))}
        {eventos.length==0&&
            <Button
                onClick={()=>{
                    seleccionarDia(bloque.IdBloqueHorario,dia)
                }}
                size="sm"
            >
            <BsPlus/>
            Agregar
            </Button>
        }
    </>
  )
}
