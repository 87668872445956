import { useEffect, useState } from "react"

export const useToggleList = (items = [], keyToFilter, idCourse) => {

    const [switchSelect, setSwitchSelect] = useState(true)
    const [checked, setChecked] = useState({})
    const [selectedNextStop, setSelectedNextStop] = useState([])
    const [numberIsSelected, setNumberIsSelected] = useState(0)

    const filterSelections = () => {
        const selected = []
        const pivotMatrix = []
        for (const id in checked) {
            if (checked[id]) {
                selected.push({
                    'IdOferta': idCourse,
                    [keyToFilter]: id 
                })
            }
        }
        items.map(item => {
            const index = selected.findIndex(check => item[keyToFilter] === check[keyToFilter])
            if (index >= 0) {
                pivotMatrix.push({
                    ...selected[index],
                    IdUsuario: item['IdUsuario']
                })
            }
        })
        setSelectedNextStop(pivotMatrix)
    }

    const countSelected = () => {
        let count = 0
        for (const id in checked) {
            if (checked[id]) {
                count++
            }
        }
        setNumberIsSelected(count)
    }

    const countNumberOfItems = () => {
        const numberOfItems = items.length
        return numberOfItems
    }

    const changeSwitchSelect = () => {
        if (numberIsSelected >= countNumberOfItems() && countNumberOfItems() > 0) {
            setSwitchSelect(false)
        }
    }

    const onReset = () => {
        setSwitchSelect(true)
        setChecked({})
        setSelectedNextStop([])
        setNumberIsSelected(0)
    }

    useEffect(() => {
        filterSelections()
        countSelected()
    }, [checked])

    useEffect(() => {
        changeSwitchSelect()
    }, [items, numberIsSelected])

    const handleChangeCheckbox = (value, name) => { 
        setChecked({ 
            ...checked,
            [name]: value
        })
    }

    const toggled = () => {
        let newChecked = {}
        setSwitchSelect(!switchSelect)
        items.map(item => {
            for (const snap in item) {
                if (snap === keyToFilter) {
                    newChecked = {
                        ...newChecked,
                        [item[snap]]: switchSelect
                    }
                }
            }
        })
        setChecked(newChecked)
    }

    return {
        selectedNextStop, //Items selected
        toggled, //Select and deselect function
        checked,
        handleChangeCheckbox,
        switchSelect,
        numberIsSelected,
        onReset,
    }
}