import React, { useState } from 'react'
import { DynamicTable } from '../../components/DynamicForm/dynamicTable/DynamicTable'
import { Button, Card, Form, Modal } from 'react-bootstrap'
import { DynamicSearch } from '../../components/DynamicForm/DynamicSearch/DynamicSearch'
import { useFetchGet } from '../../hooks/useFetchGet'
import { useNavigate } from 'react-router-dom'
import { FormService } from '../../services/api/formService'
import { alerta } from '../../helpers/alertas'
export default function ItemsMensajes() {
    // consultar items
    const api=new FormService();
    const[items,isLoading]=useFetchGet('xcampos?id_formulario=163')
    const navigate = useNavigate()
    const [endPoint, setEndPoint] = useState('itemsEstabilizacion/mensajes')
    const [busqueda, setBusqueda] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [estado, setEstado] = useState()
    const [itemActual, setItemActual] = useState()


    const onSearch = (data) => {
        setEndPoint(`itemsEstabilizacion/mensajes${data}`)
        setBusqueda(data)
    }
    // cambiar estado
    const cambiarEstado = async() => {
        if(!estado){
            return alerta('Seleccione un estado')
        }
        const data={
            EstadoItem:estado
        }
        const respuesta= await api.update(`itemsEstabilizacion`,data,itemActual.IdItemEstabilizacion)
        // if(respuesta.status===200){
            setShowModal(false)
            setEndPoint(`itemsEstabilizacion/mensajes?i${Math.random()}`)
        // }
    }

  return (
    <>
        <Card>
            <Card.Title>Items con Mensajes</Card.Title>
            {!isLoading &&
                <DynamicSearch camposForm={items} onSearch={onSearch} />
            }
        </Card>
        <DynamicTable 
            endPoint={endPoint}
            busqueda={busqueda}
            titleColumn={['Id','Estado','Grupo', 'Item','Acciones']}
            attributes={["EstadoItem","Grupo", "Item"]}
            onDelete={false}
            onUpdate={false}
            handleOptionClick={(e)=>navigate(`${e.IdItemEstabilizacion}`,{state:{Item:e}})}
            labelOptionClick={'Ver más'}
            labelOptionClick2={'Cambiar estado'}
            handleOptionClick2={(e)=>{
                setShowModal(true)
                setItemActual(e)
            }}
        />
        {/* modal para cammbiar el estado del items */}
         <Modal
            show={showModal} 
            onHide={()=>setShowModal(false)}
         >
            <Modal.Header closeButton>
                <Modal.Title>Cambiar estado</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="exampleForm.SelectCustom">
                        <Form.Label>Estado</Form.Label>
                        <Form.Control 
                            as="select" custom
                            onChange={(e)=>setEstado(e.target.value)}
                        >
                            <option>Ejecución</option>
                            <option>Cerrado</option>
                        </Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={()=>setShowModal(false)}>  
                    Cancelar
                </Button>
                <Button variant="primary" onClick={()=>cambiarEstado()}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    </>
  )
}
