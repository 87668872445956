import { useLocation } from "react-router-dom"
import { DynamicForm } from "../../../components/DynamicForm/DynamicForm"
import { ID_FORM } from "../../../constants/idForm"
import { Card } from "react-bootstrap"


function AutoEvaluacion (props) {
  const {state:{evaluacion}} = useLocation()

  const retroceder=()=>{
    setTimeout(() => { 
      window.history.go(-1)
    }, 3000);
    
  }

  // const {state:{itemParent,item}} = useLocation()
  // const [asistencias, loading, error] = useFetchGet(`estudiante/asistencia?IdOferta=${item.IdOferta}&IdEstudiante=${item.IdEstudiante}`)
  // const [asistencias, loading, error] = useFetchGet(`estudiante/asistencia?IdOferta=${itemParent.IdOferta}&IdEstudiante=${item.IdEstudiante}`)
  return (
    <>
  
      <Card>
        <Card.Title>Docente: {evaluacion?.Nombre}</Card.Title>
      </Card>

      <DynamicForm
        parentField = { null}
        ventanaFormName={evaluacion.nombre }
        titleForm={evaluacion.nombre }
        extraData={{IdGestionInstrumento:evaluacion.IdGestionInstrumento}}
        endPointSubmit={'instrumento'}
        // keySubmit={'k'}
        isContentTypeFormData={'0'}
        isCreate={true}
        justDynamicForm={{flag:true,idForm:evaluacion?.IdForm}}
        accionMysql={()=>retroceder()}
        retroceder={true}
      ></DynamicForm>


    </>
  )
}

export { AutoEvaluacion }