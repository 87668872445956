import React from 'react'

import { DeleteDialog } from '../../componentsForm/deleteDialog/DeleteDialog'
import { Table } from '../../componentsForm/table/Table'
import { Modal } from '../../modal/Modal'
import { Form } from './Form'
import { AddButton } from '../../componentsForm/addButton/addButton'
import { FormContainer } from '../../componentsForm/formContainer/FormContainer'
import { RolUsuarioContainer } from '../rolUsuario/RolUsuarioContainer'

const Usuarios = ({
    title,
    usuarios,
    usuario,
    attributes,
    onCreate,
    onDelete,
    onUpdate,
    onWindows,
    isOpenForm,
    isOpenDelete,
    isOpenWindows,
    handleSubmit,
    handleChange,
    loading,
    handleClickAdd,
    isCreate,
    handleClickDelete,
    handleClickWindows,
    deleteTuple,
}) => {

    return (
        <>
            <AddButton 
                buttonTitle='Crear usuario' 
                createModal={ onCreate }
            />
            <FormContainer style={{ width: '60%' }}>
                <Table
                    titleColumn={title}
                    data={usuarios}
                    attributes={attributes}
                    onDelete={onDelete}
                    onUpdate={onUpdate}
                    isLoading={loading}
                    titleDetails='Rol usuario'
                    onDetails={onWindows}
                />
                <Modal isOpen={isOpenForm}>
                    <Form
                        handleSubmit={handleSubmit}
                        handleChange={handleChange}
                        loading={loading}
                        formData={usuario}
                        close={handleClickAdd}
                        isCreate={isCreate}
                    />
                </Modal>
                <Modal isOpen={isOpenDelete} close={handleClickDelete}>
                    <DeleteDialog
                        deleteTuple={deleteTuple}
                        handleClickDelete={handleClickDelete}
                        element={'usuario.Nombre'}
                        loading={loading}
                    />
                </Modal>
                <Modal isOpen={ isOpenWindows } close={ handleClickWindows }>
                    <RolUsuarioContainer dependence={ usuario } />
                </Modal>
            </FormContainer>
        </>
    );
}

export { Usuarios }