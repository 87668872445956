import { Route, Routes } from "react-router-dom"
import { MovilidadDocentes } from "./MovilidadDocentes/MovilidadDocentes"
import { MovilidadEstudiantes } from "./MovilidadEstudiantes/MovilidadEstudiantes"
import { MovilidadAdministrativos } from "./MovilidadAdministrativos/MovilidadAdministrativos" 

const Internacionalizacion=()=>{

    return(
        <Routes>
            <Route path="movilidad_docentes" element={<MovilidadDocentes/>}/>
            <Route path="movilidad_estudiantes" element={<MovilidadEstudiantes/>}/>
            <Route path="movilidad_administrativos" element={<MovilidadAdministrativos/>}/>
        </Routes>
    )

}

export {Internacionalizacion}