import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"
import { useEffect } from "react";
import Table from 'react-bootstrap/Table';
import { useFetchGet } from "../../../hooks/useFetchGet"; 
import { BASE_SERVIDOR, BASE_URL } from "../../../services/api/config"; 
import {TextArea} from "../../../components/componentsForm/textarea/textarea" 
import { FormService } from "../../../services/api/formService"; 
import { alerta } from "../../../helpers/alertas";
import './EstudianteNotas.css'
import { Input } from "../../../components/input/input"; 
import { BsArrowReturnRight,BsFillFilePdfFill,BsFillFileExcelFill } from 'react-icons/bs';
import { LocalStorage } from "../../../services";
import {Dropdown, DropdownButton, Modal, Card, Button, Row, Col, Alert, ListGroup, Badge} from "react-bootstrap";
import { Select } from "../../../components/select/Select";
import { InputFile } from "../../../components/inputFile/inputFile";
import FormatosEstudianteContinuda from "../Formatos/FormatosEstudianteContinuda";
import FormatosEstudianteIES from "../Formatos/FormatoEstudiantesIES";
import DowloadFile from "../../../components/DowloadFile/DowloadFile";
import DowloadFileText from "../../../components/DowloadFileText/DowloadFileText";

function EstudianteNotas ({
    gestionable=true
}) {

  const [tipoRetiro, setTipoRetiro] = useState()
  const [numActoAdministrativo, setNumActoAdministrativo] = useState()
  const [fechaActoAdministrativo, setFechaActoAdministrativo] = useState()
  const ls = new LocalStorage()
  const {state:{estudiante,programa}} = useLocation()
  const [key, setKey] = useState('notas');
  const [DataestudianteDiploma,loadingDiploma,errorDiploma] = useFetchGet(`diplomaEstudiante?IdOferta=${programa.IdOferta}&IdEstudiante=${estudiante.IdEstudiante}`)
  const [showModalPrograma, setShowModalPrograma] = useState(false);
  const [showDetallesEstudiante, setShowDetallesEstudiante] = useState(false);
  const [estudianteSelected, setEstudianteSelected] = useState({notas:[],asignatura:''});
  const [showBtnDiploma, setShowBtnDiploma] = useState(false);
  const api = new FormService()
  const navigate = useNavigate()
  const[notas,setNotas]=useState([]);

  const [modalActaMatricula,setModalActaMatricula]=useState(false);
  const [average,setAverage] = useState(0)
  const [modalHablitar,setModalHabilitar]=useState(false)
  const [asignaturaActual,setAsignaturaActual]=useState('')
  const [Nota,setNota]=useState(null)
  const [estadoCurso,setEstadoCurso]=useState('...')
  
  const onSeeAsistencia = (row) => {
    navigate(`${row.IdMalla}/asistencia`,{state:{oferta:row,programa,estudiante}})
  }
  

  const onVerDetalles=(estudiante)=>{
    setEstudianteSelected(estudiante)
    setShowDetallesEstudiante(true)
  }

  
  const IdUsuario = ls.getAsJsonItem('usuario').usuario?.IdUsuario
 
  const urlNotas=`estudiante/notas?IdOferta=${programa.IdOferta}&IdEstudiante=${estudiante.IdEstudiante}`
  const exportarMatriculaAsignatura = `${BASE_URL}/exportar/formatoInscripcionMatriculaAsignatura?IdEstudiante=${estudiante.IdEstudiante}`
  const [estadoAprobado,setEstadoAprobado]=useState(estudiante.aprobado);
  const [modalCancelar,setModalCancelar]=useState();
  const [modalAplazar,setModalAplazar]=useState();
  const [modalNotasExternas,setModalNotasExternas]=useState();
  const [documento,setDocumento]=useState();
  const [observaciones,setOservaciones]=useState();
  const [idMalla,setIdMalla]=useState(null)
  const [tipoNota,setTipoNota]=useState(null);
  const urlDocumentos=`${BASE_SERVIDOR}/pdf`
  const urlActa=`${BASE_URL}/estudiante/descargarActaMatricula?IdEstudiante=${estudiante.IdEstudiante}`
  const [ActaMatricula,setActaMatricula]=useState();
  const [documentoActaMatricula,setDocumentoActaMAtricula]=useState(null);


  const calcularNotaFinal = (notasArray) => {
    if(notasArray.length===0){return '-'}
    let final = notasArray.reduce(
      (prev, curr)=>{
        let currentNota = curr?.Nota || null
       if(curr.Nota===null)currentNota='0'
        const porcentaje = (parseFloat(curr.Porcentaje)/100).toFixed(3)
        const nota = parseFloat(currentNota).toFixed(3)
        return prev + (nota*porcentaje)
      },0)
    return final.toFixed(2)
  }
    useEffect(() => {
  
      if(DataestudianteDiploma?.respuesta==='true' && DataestudianteDiploma.diploma?.IdDiploma) {
        const url = `${BASE_URL}/estudiante/diploma?IdDiploma=${DataestudianteDiploma.diploma.IdDiploma}`
        setShowBtnDiploma(true)
      }
      
      if(notas.length>0){
        const avg = notas.reduce(
              (acc,cur) => {
               return (parseFloat(acc) + parseFloat(cur?.nota || '0')/notas.length )
              } 
            ,0) 
        setAverage(avg.toFixed(2))
      }    
    },[notas,DataestudianteDiploma,])
    useEffect(()=>{
      console.log(programa)
      consultarNotas()
      consultarActaMatricula();
    },[])

    const openHabilitar=(item)=>{
        setAsignaturaActual(item)
        setModalHabilitar(true)
        console.log(item)
    }

    const enviarHabilitacion=async()=>{
      const data={
        "IdEstudiante":estudiante.IdEstudiante,
        "IdOferta":programa.IdOferta,
        "IdMalla":asignaturaActual.IdMalla,
        "Nota":Nota,
        "Observaciones":observaciones,
        "Documento":documento
      }

      const respuesta= await api.createWithFormData("estudiante/habilitar",data)
      console.log(respuesta)
      if(respuesta.respuesta=="exito"){
          alerta(respuesta.mensaje,"succes")
          setModalHabilitar(false)
          setNota('')
          consultarNotas()
      }else{
        alerta(respuesta.mensaje,"error")
      }
    }
    const consultarNotas=async()=>{
      const notas= await api.getAll(`${urlNotas}`)
      setNotas(notas);
      let perdidas=0
      for (let i = 0; i < notas.length; i++) {
        const element = notas[i];
        if (element.estado=="Perdio"){
          perdidas++
        }
      }
      if(perdidas>0){
        setEstadoCurso('No Aprobo')
      }else{
        setEstadoCurso('Aprobó')
      }
    }

    const habilitacion=(e)=>{
      const nH=e?.habilitacion

      if(nH){
        let nota=parseInt(nH).toFixed(2);
        let tipo="";
        if(e.TipoNotaNoRegular==1){
            tipo="Ha"
        }
        if(e.TipoNotaNoRegular==2){
          tipo="Ho"
        }
        if(e.TipoNotaNoRegular==3){
          tipo="Ex"
        }
        if(e.TipoNotaNoRegular==4){
          tipo="In"
        }
        return `(${tipo})${nota}`;
      }
    }

    const goAsginaturas=()=>{
      navigate("asignaturas_solicitadas",{state:{IdOferta:programa.IdOferta,IdEstudiante:estudiante.IdEstudiante}})
    }

    const aprobarCurso=async(Estado)=>{
      const respuesta=await api.create(`educacionIES/aprobarEstudiante?IdEstudiante=${estudiante.IdEstudiante}&Estado=${Estado}`)
      if(respuesta.estado=="0"){
        alerta("Se ha guardado el estudiante como reprobado")
        setEstadoAprobado(respuesta.estado)
      }else if (respuesta.estado=="1"){
        alerta("Se ha guardado el estudiante como aprobado")
        setEstadoAprobado(respuesta.estado)
      }else {
        setEstadoAprobado(null)
        alerta("Fallo desconocido")
      }
    }

    const guardarCancelacion=async()=>{
      const data={
        "IdOferta":programa.IdOferta,
        "IdEstudiante":estudiante.IdEstudiante,
        "Observaciones":observaciones,
        "TipoRetiro": tipoRetiro,
        "NumeroActoAdministrativo": numActoAdministrativo,
        "FechaActoAdministrativo": fechaActoAdministrativo,
        "Documento":documento
      }
      const respuesta=await api.createWithFormData("educacionIES/cancelarMatricula",data)
      if(respuesta.respuesta=="exito"){
        alerta(respuesta.mensaje)
        setModalAplazar()
        consultarNotas()
      }else{
        alerta("Fallo desconocido")
      }
    }

    const guardarAplazamiento=async()=>{
      const data={
        "IdEstudiante":estudiante.IdEstudiante,
        "Observaciones":observaciones,
        "Documento":documento
      }
      const respuesta=await api.createWithFormData("educacionIES/aplazarPrograma",data)
      if(respuesta.respuesta=="exito"){
        alerta(respuesta.mensaje)
        setModalAplazar()
        consultarNotas()
      }else{
        alerta("Fallo desconocido")
      }
    }

    const guardarNotaExterna=async()=>{
      const data={
        "IdEstudiante":estudiante.IdEstudiante,
        "IdMalla":idMalla,
        "IdOferta":programa.IdOferta,
        "Nota":Nota,
        "TipoNota":tipoNota,
        "Observaciones":observaciones,
        "Documento":documento
      }
      if(idMalla==null||tipoNota==null||Nota==null){
        alerta("Completa la información del fomrulario")
        return
      }
      const respuesta=await api.createWithFormData("educacionIES/notaExterna",data)
      if(respuesta.respuesta=="exito"){
        alerta(respuesta.mensaje)
        setModalNotasExternas()
        consultarNotas()
        
      }else if(respuesta.respuesta=="error"){
        alerta(respuesta.mensaje)
      }else{
        alerta("Fallo desconocido")
      }
    }
    // guardar acta de matricula
    const guardarActaMatricula=async()=>{
      if(ActaMatricula==null){
        alerta("Selecciona un archivo");
        return
      }
      const data={
        "ActaMatricula":ActaMatricula,
        "IdEstudiante":estudiante.IdEstudiante
      }

      const respuesta=await api.createWithFormData("estudiante/guardarActaMatricula",data)
        if(respuesta.respuesta=="exito"){
            setModalActaMatricula()
            consultarActaMatricula();
            alerta(respuesta.mensaje)
        }else{
            alerta(respuesta.mensaje)
        }
    }
    // consultar acta de matricula
    const consultarActaMatricula=async()=>{
      const respuesta=await api.getAll(`estudiante/documentoActaMatricula?IdEstudiante=${estudiante.IdEstudiante}`)
        setDocumentoActaMAtricula(respuesta)
    }
    const goTrasabilidad=()=>{
      navigate("trazabilidad",{state:{estudiante,programa}})
    }

  return (
  <>
  <Card>
    <Row>
      <Col>
        <Card.Title>
          {estudiante.Nombre}
        </Card.Title>
        <p>{programa.Nombre}</p>
      </Col>
      <Col style={{textAlign:"right"}}>
        {/* {programa?.IdDimension == 1 && programa?.IdEstadoOferta<3 &&
          <Button
            variant="light"
            onClick={()=>{setModalActaMatricula(true)}}
          >
            <i class="bi bi-file-earmark-text"></i>  Acta de Matrícula
          </Button>
        } */}

        {programa?.IdDimension != 1 &&
          <>
          <b>Estado: </b></>}
          {programa?.IdDimension != 1 && estadoAprobado==null  ?
            <>Sin Validar</>
            :
            <></>
          }  
          {programa?.IdDimension != 1 && estadoAprobado==1 ?
            <>Aprobó</>
            :
            <></> 
          }
          {programa?.IdDimension != 1 && estadoAprobado==0 ?
            <>No Aprobó</>
            :
            <></>
        }
      </Col>
    </Row>
    {(estudiante.EstadoMatricula==1 ||estudiante.EstadoMatricula==2) &&
      <Alert variant="warning">
        Al estudiante se le {estudiante.EstadoMatricula==1?'cancelo':'aplazo'} la matricula el día {estudiante.FechaEstadoMatricula}<br/>
        <b>Observaciones:</b> {estudiante.ObserEstadoMatricula}<br/>
        {estudiante.DocumentoEstadoMatricula &&
          <> 
          <b>Documento: </b>
          <a target="_blank" href={`${urlDocumentos}/${estudiante.DocumentoEstadoMatricula}`}>Ver documento</a>
          </>
        }
      </Alert>
  } 
  <div className='d-flex justify-content-end'>
    {gestionable&&
      <>
        {estadoAprobado==null && programa.IdDimension!=1 &&
          <>
            <Button
            style={{marginRight:10}} 
              onClick={()=>aprobarCurso(1)}
            >Aprobar </Button>
            <Button 
              variant="danger"
              onClick={()=>aprobarCurso(0)}
            >
              Reprobar
            </Button>
          </>
        }
        {/* {programa.IdDimension==1?
          <FormatosEstudianteIES
            IdOferta={programa.IdOferta}
            IdUsuario={IdUsuario}
            oferta={programa}
            IdEstudiante={estudiante.IdEstudiante}
          />
          : */}
          <FormatosEstudianteContinuda
            IdOferta={programa.IdOferta}
            IdUsuario={IdUsuario}
            estudiante={estudiante}
            oferta={programa}
            IdEstudiante={estudiante.IdEstudiante}
          />
        {/* } */}
        
        <DropdownButton title="Acciones">
          <Dropdown.Item
            onClick={()=>goTrasabilidad(true)}
          >
            Trazabilidad Admisión
          </Dropdown.Item>
            <Dropdown.Item 
              onClick={()=>goAsginaturas()}
            >
              Asignaturas solicitadas
            </Dropdown.Item>
            <Dropdown.Item 
              onClick={()=>setModalNotasExternas(true)}
            >
              Notas no regulares
            </Dropdown.Item>
            <Dropdown.Item 
              onClick={()=>setModalAplazar(true)}
            >
              Aplazar programa
            </Dropdown.Item>
            <Dropdown.Item 
              onClick={()=>setModalCancelar(true)}
            >
              Cancelar matricula
            </Dropdown.Item>
        </DropdownButton>
        </>
      }
    </div>
  </Card>
  <div className="cont-notas">
 <Card>

  <Table striped bordered hover>
  <thead>
    <tr>
      <th>N°</th>
      <th>Asignatura</th>
      <th>Asistencias</th>
      <th>Nota</th>
      <th>No regulares</th> 
      {gestionable&&
        <th>Habilitar</th>
      }
      
    </tr>
  </thead>
  <tbody>
          {!!notas && notas.map((element,index)=>{
            return(
              <tr key={index} className={element.estado=="Perdio"&&"perdio"} >
                <td>{index+1}</td>
                <td>{element.asignatura}</td>
                <td className="boton-tabla">
                  <Button
                  variant="light"
                  onClick={()=>onSeeAsistencia(element)}
                  ><i title={'ver asistencia :'+(index+1)} class="bi bi-calendar3"></i></Button>
                </td>
                <td className="boton-tabla">
                  <Button
                  variant="light"
                  onClick={()=>onVerDetalles(element)}
                  >
                    {/* {calcularNotaFinal(element.notas)}{' '} */}
                    {element.definitiva}
                    <i title={'ver detalles :'+(index+1)} class="bi bi-eye"></i>
                    </Button>
                </td>
                <td className="boton-tabla">
                  <Button 
                    variant="light"
                  >
                    {habilitacion(element)}
                  </Button>
                </td>
                  {gestionable&&
                    <td className="boton-tabla">
                        <Button
                          variant="light"
                          onClick={()=>openHabilitar(element)}
                          ><i class="bi bi-pencil"></i>
                        </Button>
                    </td>
                  }                
              </tr>
            )
          })}
    </tbody>
  </Table>

    

  </Card>
  </div>
  {/* modal para ver notas */}
    <Modal
      show={showDetallesEstudiante}
      onHide={()=>setShowDetallesEstudiante(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>
        {estudianteSelected && estudianteSelected?.asignatura}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <ListGroup>
          {estudianteSelected && estudianteSelected?.notas.map((nota,index)=>{
            return(/* 
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-start"
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">{nota?.Nombre}</div>
                  {nota?.Nota || '-'}
                </div>
                <Badge bg="primary" pill>
                {nota?.Porcentaje}% 
                </Badge>
              </ListGroup.Item> */
              
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-start"
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">{nota?.Nombre}</div>
                  {nota?.Corte || '-'}
                </div>
                <Badge bg="primary" pill>
                {nota?.Nota}
                </Badge>
              </ListGroup.Item>
            )
          })}
        </ListGroup>
        {<ul className="nota-definitiva"><b>Definitiva </b>: {estudianteSelected?.definitiva} {/* {calcularNotaFinal((estudianteSelected && estudianteSelected.notas)|| [])} */}</ul>}
      </Modal.Body>
      
    </Modal>
    {/* modal para habilitar nota */}
      <Modal 
        show={modalHablitar}
        onHide={()=>setModalHabilitar(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Formulario de habilitación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div><b>Asignatura: </b>{asignaturaActual?.asignatura}</div> 
          <div><b>Estudiante: </b>{estudiante.Nombre}</div>
          <Input
            label={'Nota de habilitación'}
            type='number'
            min={0}
            max={programa.IdDimension==1?5:100}
            value={Nota}
            onChange={(e)=>setNota(e)}
          />
          <TextArea 
            label="Observación"
            value={observaciones}
            onChange={setOservaciones}
          />
          <InputFile
            label={"Adjuntar archivo si lo requiere"}
            onChange={(e)=>setDocumento(e[0])}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={()=>setModalHabilitar()}
          >
            Cancelar
          </Button>
          <Button 
            className={'send'}
            onClick={()=>enviarHabilitacion()}
          >
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>


      {/* Modal para cancelar programa */}
      <Modal 
        show={modalCancelar}
        onHide={()=>setModalCancelar()}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Cancelar Matrícula
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Select
            label={"Tipo de Retiro"}
            value={tipoRetiro}
            onChange={(e) => setTipoRetiro(e)}
            optionValue={"Nombre"}
            optionName={"Nombre"}
            options={[
              {"Id": 1, "Nombre": "Retiro Disciplinario"},
              {"Id": 2, "Nombre": "Retiro Reglamento Disciplinario"}
            ]}
          />
          {tipoRetiro == "Retiro Disciplinario" &&
          <Input
            label={"Número de Acto Administrativo"}
            type={"number"}
            value={numActoAdministrativo}
            onChange={(e) => setNumActoAdministrativo(e)}
          />
          }
          {tipoRetiro == "Retiro Disciplinario" &&
          <Input
            label={"Fecha de Acto Administrativo"}
            type={"date"}
            value={fechaActoAdministrativo}
            onChange={(e) => setFechaActoAdministrativo(e)}
          />
          }
          <TextArea
            label="Observaciones"
            value={observaciones}
            onChange={(e)=>setOservaciones(e)}
          />
          <label><b>Adjunte archivo si lo require(Adjunte Acto Administrativo)</b></label>
          <input 
            type="file"
            onChange={(e)=>setDocumento(e.target.files[0])}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button 
            variant="secondary"
            onClick={()=>setModalCancelar()}
          >
            Cerrar
          </Button>
          <Button
            onClick={()=>guardarCancelacion()}
          >
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal para aplazar programa */}
      <Modal 
        show={modalAplazar}
        onHide={()=>setModalAplazar()}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Aplazar programa
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <TextArea
            label="Observaciones"
            value={observaciones}
            onChange={(e)=>setOservaciones(e)}
          />
          <label><b>Adjunte archivo si lo require</b></label>
          <input 
            type="file"
            onChange={(e)=>setDocumento(e.target.files[0])}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button 
            variant="secondary"
            onClick={()=>setModalAplazar()}
          >
            Cerrar
          </Button>
          <Button
            onClick={()=>guardarAplazamiento()}
          >
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal agregar creditos */}
      <Modal 
        show={modalNotasExternas}
        onHide={()=>setModalNotasExternas()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Agregar nota no regular </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Select
            label="Tipo de nota"
            options={[
              {"Id":"2","Nombre":"Homologacion"},
              {"Id":"3","Nombre":"Suficiencia"},
              {"Id":"4","Nombre":"Intercambio"},
              {"Id":"5","Nombre":"Supletorio"},
            ]}
            optionName="Nombre"
            value={tipoNota}
            onChange={(e)=>setTipoNota(e)}
            optionValue="Id"
          />
          <Select
            label="Seleccione la asigantura"
            value={idMalla}
            optionName="Descripcion"
            optionValue="IdMalla"
            urlOptionsService={`educacionIES/asignaturasPeriodos?IdOferta=${programa.IdOferta}`}
            onChange={(e)=>setIdMalla(e)}
          />
          <Input
            label="Nota"
            type="number"
            min={0}
            max={programa.IdDimension==1?5:100}
            value={Nota}
            onChange={(e)=>setNota(e)}
          />
          <TextArea 
            label="Observaciones"
            value={observaciones}
            onChange={(e)=>setOservaciones(e)}
          />
          <label><b>Ajunte archivo si lo requiere</b></label>
          <input
            type="file"
            onChange={(e)=>setDocumento(e.target.files[0])}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={()=>setModalNotasExternas()}
          >
            Cancelar
          </Button>
          <Button
            onClick={()=>guardarNotaExterna()}
          >
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
    
    <Modal
      show={modalActaMatricula}
      onHide={()=>setModalActaMatricula(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Acta de Matrícula
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {documentoActaMatricula?
          <>
            <p><b>Acta de Matricula Cargada</b></p>
            <a target="_blank" href={`${BASE_SERVIDOR}/pdf/${documentoActaMatricula}`}>Ver Acta</a>
          </>
          :
          <>
            <p><b>Descarga el acta de matricula del estudiante para firmarla,</b>
              <DowloadFileText
                  nameFile={"Acta de Matricula.pdf"}
                  name={"Descargar Acta"}
                  endPoint={`${urlActa}`}
              />
              {/* <a target="_blank" href={`${urlActa}`}> Descargar Acta</a> */}
            </p>
            <div>Adjunta el acta de matricula firmada</div>
            <InputFile
              label={"Adjuntar Acta de Matricula"}
              onChange={(e)=>setActaMatricula(e[0])}
            />
          </>
        
        }
        </Modal.Body>
        <Modal.Footer>

             <Button 
              onClick={()=>setModalActaMatricula()}
              variant="secondary"
            >
              Cancelar
            </Button>
          {!documentoActaMatricula&&
             <Button
              onClick={()=>guardarActaMatricula()}
            >
              Guardar
            </Button>
          }
         
        </Modal.Footer>
      </Modal>
  </>
  )
}

export { EstudianteNotas }