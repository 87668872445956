import {  Navigate, useLocation } from "react-router-dom";
import { LocalStorage } from "../../services";


export default  function RequireAuth({ children }) {
  const lStorage = new LocalStorage()
  const token = lStorage.getItem('access_token')
  const usuario = lStorage.getAsJsonItem('usuario')
  const location = useLocation();

  if (token==='' || token===null || usuario===null) {
    return <Navigate to="/inicio"  state={{ from: location }}  replace/>;
  }
    return children;
  }
