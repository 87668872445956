import React from 'react';
import { Button } from '../../button/button';
import { BASE_SERVIDOR, plantilla } from '../../../services/api/config';

import './Dropdown.css';
import manual from '../../../Luisa/Manual.pdf';

const Dropdown = ({ usuario, handleChangeModal, onLogout }) => {
    const img=usuario?.Foto?`${BASE_SERVIDOR}/imgUser/${usuario?.Foto}`:"/img/TempProfile.jpg"
    
    return (
        <div className="dropdown">
            <Button
                className={`dropdown-toggle m-0 text-white ${plantilla=="seguridad"&&'color'}}`}
                id="dropdownMenuButton1"
                dataBsToggle="dropdown"
                ariaExpanded="false"
            >
                {/* {console.log(usuario?.Foto)} */}
                <img
                    src={img}
                    alt="profile halcon"
                    className="navbar-dashboard__profile-img"
                />
            </Button>
            <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
                style={{
                    boxShadow: 'rgba(0, 0, 0, 0.3) 3px 3px 5px 2px',
                }}
            >
                <span className="d-flex m-3 text_user_gray">
                    Hola,{' '}
                    <span style={{ fontWeight: 600, marginLeft: '4px' }}>
                        {
                            usuario?.PrimerNombres
                            // + ' ' +
                            // usuario?.PrimerApellidos
                        }
                    </span>
                </span>
                {plantilla=="CEA"&&
                <>
                <li>
                    <div
                        className="dropdown-item btn-custom"
                        onClick={handleChangeModal}
                    >
                        <a  href={manual} target="_blank" rel="noreferrer" className="dropdown__button">
                            Manual de usuario
                        </a>
                    </div>
                </li>
                <li>
                    <div
                        className="dropdown-item btn-custom"
                        onClick={handleChangeModal}
                    >
                        Cambiar Rol
                    </div>
                </li>
                <li>
                    <div
                        className="dropdown-item btn-custom"
                        onClick={handleChangeModal}
                    >
                        <Button
                            isLink={true}
                            to="/perfil"
                            className="dropdown__button"
                        >
                            Mi perfil
                        </Button>
                    </div>
                </li>
                <li>
                    <div
                        className="dropdown-item btn-custom"
                        onClick={handleChangeModal}
                    >
                        <Button isLink={true} to="/oferta" className="m-0 p-0">
                            Portafolio
                        </Button>
                    </div>
                </li>
                </>
            }
                <li>
                    <div
                        className="dropdown-item btn-custom"
                        onClick={() => onLogout()}
                    >
                        Cerrar sesión
                    </div>
                </li>
            </ul>
        </div>
    );
};

export { Dropdown };
