import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Consultoria } from './Consultoria/Consultoria' 
import { Proyectos } from './Proyectos/Proyectos'
import { Servicios } from './Servicios/Servicios'
import { DynamicFormContainer } from '../../components/DynamicForm/DynamicFormContainer'

export default function Extension() {
  return (
    <Routes>
        <Route path="centros_consultoria" element = {<DynamicFormContainer idForm={'169'}/>}></Route>
        <Route path="proyecto_extension" element = {<DynamicFormContainer idForm={'170'}/>}></Route>
        <Route path='servicio_extension' element={<DynamicFormContainer idForm={'171'}/>}></Route>
    </Routes>
  ) 
}