import React from 'react'
import { LocalStorage } from '../../../services'
import Header from './Componentes/Header'
import Menu from './Componentes/Menu'

export default function Inicio() {
  const storage=new LocalStorage()
  const usuario_full=JSON.parse(storage.getItem("usuario"))
  const usuario=usuario_full.usuario

  return (
    <>
    <div style={{maxWidth:1000,margin:"0 auto",marginTop:40}}>
      <Header usuario={usuario}/>
      <Menu usuario={usuario}/>
    </div>
    </>
  )
}
