import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom";

import { AdmissionContainer } from "../../../Student/Admission/AdmissionContainer";
import { DocumentUploadContainer } from "../../../Student/DocumentUpload/DocumentUploadContainer";
import { MatriculaAspiranteContainer } from "../../../Student/MatriculaAspirante/MatriculaAspiranteContainer";
import { FormService } from '../../../../services/api/formService';
import { EnrollmentProcess } from "../../estudiante/EnrollmentProcess/EnrollmentProcess";
import ActualizarDatosAspirante from './ActualizarDatosAspirante';
import { DynamicForm } from '../../../DynamicForm/DynamicForm';


function AspiranteProcesoInscripcion () {

  const [respondio, setRespondio] = useState(false);
  const [respondioBienestar, setRespondioBienestar] = useState(true);
  const location = useLocation();
  const { state: { offer,applicant } } = location;
  const api = new FormService();
  const navigate = useNavigate();

  // const consultarRespondio=async()=>{
  //   const respuesta = await api.getAll(`aspirante/respondio?IdAspirante=${offer.IdAspirante}&IdUsuario=${offer.IdUsuario}`)
  //   if(respuesta.respuesta=="error"){
  //     setRespondio(false)
  //   }else{
  //     setRespondio(true)
  //   }
  // }

  const consultaRespondioBienestar=async()=>{
    const api = new FormService()
    const respuesta =await api.getAll(`estudiante/respondio?IdOferta=${offer.IdOferta}&IdAspirante=${applicant.IdAspirante}&IdUsuario=${applicant.IdUsuario}`)
    if(respuesta.respuesta==="exito"){
      setRespondioBienestar(false)
    }else{
      setRespondioBienestar(true)
    }
  }

  useEffect(() => {
    console.log(applicant);
    if(applicant.IdRespuestaFormulario!=null){
      setRespondio(true);
    }
    // consultarRespondio();
    // consultaRespondioBienestar();
  });

  return (
    <>
      {applicant?.IdFormulario!=null && respondio==false
      ?
        <>
          <h4 style={{textAlign:"center",marginTop:"20px"}}>Completa el formulario para continuar</h4>
            <DynamicForm 
              parentField = { null}
              justDynamicForm={{ flag: true, idForm: applicant.IdFormulario }}
              isCreate={true}
              endPointSubmit='aspirantes/guardarFormulario'
              isContentTypeFormData={'0'}
              extraData={{
                IdAspirante: applicant.IdAspirante,
                IdFormulario: applicant.IdFormulario
              }}
              accionMysql={()=>{
                setTimeout(() => {
                  setRespondio(true);
                },3000)
              }}
              onClickCancelBtn={() => {
                navigate(-1)
              }}
            />
        </>
      :
      <EnrollmentProcess
        isApplicant={ true } 
        aspirante={applicant}
        item={offer}
        respondioBienestar={respondioBienestar}
        consultaRespondioBienestar={consultaRespondioBienestar}
      >
        <DocumentUploadContainer 
          offer={ offer.IdOferta }
          item={ offer }
        />
        <AdmissionContainer
          item={ offer }
        />

        <MatriculaAspiranteContainer 
          offer={ offer.IdOferta }
          item={ offer }
          aspirante={applicant}
        />
      </EnrollmentProcess>
      }
    </>
  )
}
export { AspiranteProcesoInscripcion }