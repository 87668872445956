import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Row, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import {useNavigate } from 'react-router-dom'
import { setItemSelected } from '../../../components/DynamicForm/FormSlice';
import { useFetchGet } from '../../../hooks/useFetchGet';
import { BASE_SERVIDOR } from '../../../services/api/config';


export default function EducacionSuperior() {
    const navigate=useNavigate();
    const dispatch=useDispatch()

    const curso = useSelector((state) => state.form.itemSelected)
    const [cursos,]=useFetchGet("educacionIES/listaCursosIES")

    useEffect(() => {
        
        dispatch(setItemSelected({IdCurso:642,Nombre:"TECNOLOGÍA EN GESTIÓN DE TRÁNSITO AÉREO"}))
    }, [])


  return (
    <>
    {!curso &&
    <>
        <Card>
            <Card.Title>Seleccione el Programa a Gestionar</Card.Title>
        </Card>
        <Table>
            <thead>
                <tr>
                    <th>Nombre</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                {cursos.map((curso,index)=>
                    <tr key={index}>
                        <td>{curso.Nombre}</td>
                        <td>
                            <Button 
                                onClick={()=>{
                                    dispatch(setItemSelected(curso))
                                }}
                            >
                                Gestionar
                            </Button>
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
    </> 
    }
    {curso &&
        <>
        <div style={{textAlign:"center",padding:"50px 0"}}>
            <Card.Title>{curso.Nombre}</Card.Title>
        </div>
        <div style={{maxWidth:800,margin:"0 auto",textAlign:"center"}}>
        <Row>
            <Col>
                <Card 
                    style={{padding:"30px 20px",cursor:"pointer"}}
                    onClick={()=>navigate("peridos_inscritos",{state:{IdCurso:curso?.IdCurso}})}
                >
                    Periodos Inscritos
                </Card>
            </Col>
            <Col>
                <Card 
                    style={{padding:"30px 20px",cursor:"pointer"}}
                    onClick={()=>navigate("peridos_activos",{state:{IdCurso:curso?.IdCurso}})}
                >
                    Periodos Activos
                </Card>
            </Col>
            <Col>
                <Card 
                    style={{padding:"30px 20px",cursor:"pointer"}}
                    onClick={()=>navigate("peridos_finalizados",{state:{IdCurso:curso?.IdCurso}})}
                >
                    Periodos Finalizados
                </Card>
            </Col>
        </Row>
        <Row>
            <Col>
                <Card 
                    style={{padding:"30px 20px",cursor:"pointer"}}
                    onClick={()=>navigate("cohortes",{state:{IdCurso:curso?.IdCurso}})}
                >
                    Cohortes 
                </Card>
            </Col>
            <Col>
                <Card 
                    style={{padding:"30px 20px",cursor:"pointer"}}
                    onClick={()=>navigate("estudiantes",{state:{IdCurso:curso?.IdCurso}})}
                >
                    Estudiantes
                </Card>
            </Col>
            <Col>
                <Card 
                    style={{padding:"30px 20px",cursor:"pointer"}}
                    // decargar la malla del programa
                    onClick={()=>{
                        window.open(`${BASE_SERVIDOR}/pdf/${curso.ArchivoMalla}`)
                    }}
                >
                    Malla del programa
                </Card>
            </Col>
        </Row>
            {/* <Button
                variant='outline-primary' 
                onClick={()=>{
                    dispatch(setItemSelected(null))
                }}
            >Gestionar otro curso
            </Button> */}
        </div>
        </>    
    }
    </>
  )
}
