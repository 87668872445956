import { Route, Routes } from 'react-router-dom'
import { DynamicFormContainer } from '../../components/DynamicForm/DynamicFormContainer'
import { ProgramasADictar } from './CursosActivos/ProgramasADictar'
import { Asistencias } from './shared/components/Asistencias'
import { AutoEvaluacion } from './AutoEvaluacion/AutoEvaluacion'
import { CalendarioAsignatura } from './calendario/CalendarioAsignaturas'
import { CalendarioDocente } from './calendario/CalendarioDocente'
import { ProgramasFinalizadosList } from './CursosFinalizados/ProgramasFinalizadosList'

import { AsignaturasEstudiantes } from '../componentesC/AsignaturasEstudiante/AsignaturasEstudiantes'
import { ListadoEvaluaciones } from './AutoEvaluacion/ListadoEvaluaciones'
import { MomentosEvaluativos } from './shared/components/MomentosEvaluativos'
import { Docentes } from './docentes/Docentes'

import { EstudianteAsistencias } from '../componentesC/EstudianteAsistencias/EstudianteAsistencias' 

import { Evaluacion } from "./evaluacionesDocentes/Evaluacion"
import { EvaluacionesDocentes } from "./evaluacionesDocentes/EvaluacionesDocentes"

import { Notificaciones } from "./Notificaciones"
import { AsignaturasCurso } from '../componentesC/AsignaturasCurso/AsignaturasCurso'
import { EstudianteNotas } from '../componentesC/EstudianteNotas/EstudianteNotas'
import { UnidadesAsignatura } from '../componentesC/UnidadesAsignatura/UnidadesAsignatura'

function Coordinador(props) {


    return (
        <>
            <Routes>
                <Route
                    path="calendario_docente"
                    element={<CalendarioAsignatura />}
                ></Route>
                <Route
                    path="calendario_docente/:id/detalle_calendario"
                    element={<CalendarioDocente />}
                ></Route>
                {/* Cursos activos */}
                <Route
                    path="cursos_activos"
                    element={<ProgramasADictar />}
                ></Route>
                <Route
                    path="cursos_activos/:idoferta/asignaturas"
                    element={<AsignaturasCurso editable={false}/>}
                ></Route>
                 <Route
                    path="cursos_activos/:idoferta/asignaturas/:idMalla/unidades_asignatura"
                    element={<UnidadesAsignatura/>}
                ></Route>
                <Route
                    path="cursos_activos/:idoferta/asignaturas/:idmalla/asignatura_estudiantes"
                    element={<AsignaturasEstudiantes />}
                ></Route>
                <Route
                    path="cursos_activos/:idoferta/asignaturas/:idmalla/momentos_evaluativos"
                    element={<MomentosEvaluativos editable={true} />}
                ></Route>
                <Route  path="cursos_activos/:idoferta/asignaturas/:idusario/detalle_programa" 
                        element = {<EstudianteNotas gestionable={false} />}>
                </Route> 
                <Route  path="cursos_activos/:idoferta/asignaturas/:idusario/detalle_programa/:id/asistencia" 
                        element = {<EstudianteAsistencias/>}>
                </Route> 
                <Route
                    path="cursos_activos/:idoferta/asignaturas/:idmalla/asignatura_estudiantes/asistencias"
                    element={<Asistencias canEdit={true} />}
                ></Route>
                <Route
                    path="cursos_activos/:idoferta/asignaturas/:idmalla/asignatura_estudiantes/notas"
                    element={<EstudianteNotas canEdit={true} />}
                ></Route>

                {/* Cursos finalizados */}
                <Route
                    path="cursos_finalizados"
                    element={<ProgramasFinalizadosList />}
                ></Route>
                <Route
                    path="cursos_finalizados/:idoferta/asignaturas"
                    element={<AsignaturasCurso editable={false}/>}
                ></Route>
                <Route
                    path="cursos_finalizados/:idoferta/asignaturas/:idmalla/asignatura_estudiantes"
                    element={<AsignaturasEstudiantes />}
                ></Route>
                <Route
                    path="cursos_finalizados/:idoferta/asignaturas/:idmalla/asignatura_estudiantes/asistencias"
                    element={<Asistencias canEdit={false} />}
                ></Route>
                <Route
                    path="cursos_finalizados/:idoferta/asignaturas/:idmalla/momentos_evaluativos"
                    element={<MomentosEvaluativos editable={false} />}
                ></Route>
                <Route
                    path="cursos_finalizados/:idoferta/asignaturas/:idmalla/asignatura_estudiantes/notas"
                    element={<EstudianteNotas canEdit={false} />}
                ></Route>

                <Route
                    path="auto_evaluaciones"
                    element={<ListadoEvaluaciones />}
                ></Route>
                <Route
                    path="auto_evaluaciones/:IdAutoEvaluacion"
                    element={<AutoEvaluacion />}
                ></Route>
                <Route  path="evaluaciones_docentes" element={<EvaluacionesDocentes/>} /> 
                <Route  path="evaluaciones_docentes/:IdUsuario" element={<Evaluacion/>} />

                <Route path='docentes' element={<Docentes/>}></Route>

                <Route  path="notificaciones" element = {<Notificaciones />}></Route>
                 <Route path="*" element={<DynamicFormContainer />}></Route>
            </Routes>
        </>
    )
}

export { Coordinador }
