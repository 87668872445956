import { DynamicTable } from '../../../../components/DynamicForm/dynamicTable/DynamicTable'

function UsuarioAspirantes() {
    return (
        <>
            <DynamicTable
                endPoint={'aspirantes'}
                titleColumn={[
                    'N°',
                    'Nombre',
                    'Apellido',
                    'N° Documento',
                    'Email',
                    'Telefono',
                ]}
                attributes={[
                    'PrimerNombres',
                    'PrimerApellidos',
                    'NumeroDocumento',
                    'email',
                    'TelefonoContacto',
                ]}
                onDelete={false}
                onUpdate={false}
                onActions={false}
            ></DynamicTable>
        </>
    )
}

export { UsuarioAspirantes }
