import React from 'react'
import { Card } from 'react-bootstrap'
import { DynamicTable } from '../../components/DynamicForm/dynamicTable/DynamicTable'
import { useLocation, useNavigate } from 'react-router-dom'

export default function EstudiantesAntiguos() {
    const navigate = useNavigate()
    const { offer } = useLocation().state
    const urlEstudiantesAntiguos = `educacionIES/estudiantesAntiguos?IdOferta=${offer.IdOferta}&IdCurso=${offer.IdCurso}`
  return (
    <>
        <Card>
            <Card.Title>Estudiantes Antiguos</Card.Title>
        </Card>

        {/* tabla para mostar los estudiantes antiguos */}

        <DynamicTable
            titleColumn={ ['N°', 'Nombre', 'Email', 'Estado', 'Acción'] }
            attributes={ ['Nombre', 'email', 'Estado'] }
            endPoint={urlEstudiantesAntiguos}
            onDelete={ false }
            onUpdate={ false }
            labelOptionClick={ 'Ver más' }
            handleOptionClick={ (e) => {
                navigate('asignaturas', { state: { oferta:offer,aspirante:e } })
            } }
        />
    </>
  )
}
