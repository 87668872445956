import React, { useState } from 'react'
import { useEffect } from 'react';
import { Button, Card, Col, ListGroup, Modal, Row, Table } from 'react-bootstrap'
import { useAsync } from 'react-bootstrap-typeahead';
import { TextArea } from '../../components/componentsForm/textarea/textarea';
import { alerta } from '../../helpers/alertas';
import { BASE_SERVIDOR } from '../../services/api/config';
import { FormService } from '../../services/api/formService';
import { BASE_URL } from '../../services/api/config';

export default function Solicitudes() {
    const api=new FormService();
    const urlDocumentos=`${BASE_SERVIDOR}/pdf`
    const [solicitudes,setSolictudes]=useState([]);
    const [solicitud,setSolicitud]=useState();
    const [modalRecibir,setModalRecibir]=useState();
    const [modalRechazar,setModalRechzar]=useState();
    const [modalContestar,setModalContestar]=useState();
    const [modalSeguimiento,setModalSeguimiento]=useState();
    const [observaciones,setObservaciones]=useState();
    const [documentoRespuesta,setDocumentoRespuesta]=useState();
    const [motivoRechazo,setMotivoRechazo]=useState(null);
    const urlExportar = `${BASE_URL}/exportarSolicitudes`
    

    useEffect(()=>{
        (async()=>{
            consultarSolicitudes();
        })()

    },[])

    const consultarSolicitudes=async()=>{
        const solicitudes=await api.getAll("solicitudes")
        setSolictudes(solicitudes)
    }
    const recibirSolicitud=async()=>{
        const data={
            "EstadoSolicitud":"2"
        }
        const respuesta=await api.update("solicitudes",data,solicitud.IdSolicitud)
        if(respuesta.respuesta="exito"){
            alerta(respuesta.mensaje)
            setModalSeguimiento();
            consultarSolicitudes();
            setModalRecibir();
        }else{
            alerta("Fallo desconocido")
        }

    }

    const rechazarSolicitud=async()=>{
        if(motivoRechazo==null){
            alerta("Escribe el motivo del rechazo")
            return
        }
        const data={
            "EstadoSolicitud":"0",
            "MotivoRechazo":motivoRechazo
        }
        const respuesta=await api.update("solicitudes",data,solicitud.IdSolicitud)
        if(respuesta.respuesta="exito"){
            alerta(respuesta.mensaje)
            setModalSeguimiento();
            consultarSolicitudes();
            setModalRechzar();
        }else{
            alerta("Fallo desconocido")
        }

    }

    const contestarSolicitud=async()=>{

        const data={
            "IdSolicitud":solicitud.IdSolicitud,
            "ObservacionesRespuesta":observaciones,
            "DocumentoRespuesta":documentoRespuesta
        }
        const respuesta=await api.createWithFormData("solicitudes/responderSolicitud",data);
        if(respuesta.respuesta=="exito"){
            alerta(respuesta.mensaje);
            setModalSeguimiento();
            consultarSolicitudes();
            setModalContestar();
        }else{
            alerta("Fallo desconocido")
        }
    }

  return (
    <>
        <Card>
            <Card.Title>Solicitudes</Card.Title>
            <div style={{textAlign: 'right'}}>
                <Button href={urlExportar}>
                    Exportar
                </Button>
            </div>
        </Card>
        <Card>
            <Table>
            <thead>
                <tr>
                    <th>Solicitante</th>
                    <th>Solicitud</th>
                    <th>Estado</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                {solicitudes.map((solicitud,key)=>
                    <tr key={key}>
                        <td>{solicitud.Solicitante}</td>
                        <td>{solicitud.Solicitud}</td>
                        <td>
                        {solicitud.EstadoSolicitud==0&&
                            <>Rechazada</>
                        }
                        {solicitud.EstadoSolicitud==1&&
                            <>Aprobada</>
                        }
                         {solicitud.EstadoSolicitud==3&&
                            <>Solicitada</>
                         }
                         {solicitud.EstadoSolicitud==2&&
                            <>
                            {solicitud.EstadoPago ==null&&
                                <>Pago por realizar</>
                            }
                            {solicitud.EstadoPago==0&&
                                <>Validando pago</>
                            }
                            {solicitud.EstadoPago==1&&
                                <>Pago aprobado</>
                            }
                            {solicitud.EstadoPago==2&&
                                <>Pago rechazado</>
                            }
                            </>
                         } 
                        </td>
                        <td>
                            <Button
                                onClick={()=>{
                                    setModalSeguimiento(true)
                                    setSolicitud(solicitud)
                                }}
                            >
                                Ver más
                            </Button>
                        </td>
                    </tr>
                )}
            </tbody>

            </Table>
        </Card>

        <Modal
            size='lg'
            show={modalSeguimiento}
            onHide={()=>setModalSeguimiento()} 
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Solicitud
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <ListGroup variant="flush">
            <ListGroup.Item>
                    <Row>
                        <Col xs={4}><b>Solicitante:</b></Col><Col>{solicitud?.Solicitante}</Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Row>
                        <Col xs={4}><b>Programa:</b></Col><Col>{solicitud?.Nombre}</Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Row>
                        <Col xs={4}><b>Solicitud:</b></Col><Col>{solicitud?.Solicitud}</Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Row>
                        <Col xs={4}><b>Fecha de solicitud:</b></Col><Col>{solicitud?.FechaCreacion}</Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    {solicitud?.DocumentoSolicitud&&
                    <Row>
                        <Col xs={4}><b>Documento Adjuntado:</b></Col>
                        <Col>
                            <a target="_blank" href={`${urlDocumentos}/${solicitud?.DocumentoSolicitud}`}>Ver documento</a>
                        </Col>
                    </Row>
                    }
                </ListGroup.Item>
                <ListGroup.Item>
                    <Row>
                        <Col xs={4}><b>Observaciones:</b></Col><Col>{solicitud?.Observaciones}</Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                <Row>
                    <Col xs={4}><b>Estado:</b></Col>
                    <Col>
                        {solicitud?.EstadoSolicitud==0&&
                            <>Rechazada</>
                        }
                        {solicitud?.EstadoSolicitud==1&&
                            <>Aprobada</>
                        }
                         {solicitud?.EstadoSolicitud==3&&
                            <>Solicitada</>
                         }
                         {solicitud?.EstadoSolicitud==2 &&
                            <>
                            {solicitud.EstadoPago ==null&&
                                <>Pago por realizar</>
                            }
                            {solicitud.EstadoPago==0&&
                                <>Validando pago</>
                            }
                            {solicitud.EstadoPago==1&&
                                <>Pago aprobado</>
                            }
                            {solicitud.EstadoPago==2&&
                                <>Pago rechazado</>
                            }
                            </>
                         }
                    </Col>
                </Row>
                </ListGroup.Item>
                { solicitud?.EstadoSolicitud==2&&solicitud?.EstadoPago==1 &&
                    <Button
                        onClick={()=>setModalContestar(true)}
                    >
                        Responder solicitud
                    </Button>
                }
                {solicitud?.EstadoSolicitud==2&&solicitud?.IdPago==null &&
                    <Button
                        onClick={()=>setModalContestar(true)}
                    >
                        Responder solicitud
                    </Button>
                }              
                {solicitud?.EstadoSolicitud==3&& solicitud.IdPago!=null &&
                    <ListGroup.Item>
                        <>
                        <Button
                            variant='danger'
                            onClick={()=>setModalRechzar(true)}
                        >
                            Rechazar
                        </Button>
                        <Button
                            onClick={()=>setModalRecibir(true)}
                        >
                            Recibir
                        </Button>
                        </>
                    </ListGroup.Item>
                }
                
                {solicitud?.MotivoPagoRechazo&&
                <ListGroup.Item>
                <Row>
                    <Col xs={4}><b>Motivo  de rechazado del pago:</b></Col>
                    <Col>
                        {solicitud?.MotivoPagoRechazo}
                    </Col>
                </Row>
                </ListGroup.Item>
                }
                {solicitud?.ObservacionesRespuesta&&
                <ListGroup.Item>
                <Row>
                    <Col xs={4}><b>Respuesta:</b></Col>
                    <Col>
                        {solicitud?.ObservacionesRespuesta}
                    </Col>
                </Row>
                </ListGroup.Item>
                }
                {solicitud?.DocumentoRespuesta&&
                <ListGroup.Item>
                <Row>
                    <Col xs={4}><b>Documento de respuesta:</b></Col>
                    <Col>
                        <a target="_blank" href={`${urlDocumentos}/${solicitud?.DocumentoRespuesta}`}>Ver documento</a>
                    </Col>
                </Row>
                </ListGroup.Item>
                }
            </ListGroup>
            </Modal.Body>
        </Modal>
        <Modal
            show={modalContestar}
            onHide={()=>setModalContestar()}
        >
            <Modal.Header closeButton>
                <Modal.Title>Responder solicitud</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <TextArea 
                    label="Observaciones"
                    value={observaciones}
                    onChange={(e)=>setObservaciones(e)}
                />
                
                <label style={{marginTop:30}}>Adjuntar documento si lo requiere</label>
                <input
                    type="file"
                    // value={comprobante}
                    onChange={(e) => setDocumentoRespuesta(e.target.files[0])}
                />
            </Modal.Body>
             
            <Modal.Footer>
                <Button
                variant="secondary"
                onClick={()=>setModalContestar()}
                >
                    Cancelar
                </Button>
                <Button
                onClick={()=>contestarSolicitud()}
                >
                    Aceptar
                </Button>
            </Modal.Footer>
        </Modal>
        {/* modal rechazar */}
        <Modal 
            show={modalRechazar}
            onHide={()=>setModalRechzar()}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Rechazar solicitud
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TextArea 
                    label="Motivo de rechazao"
                    vale={motivoRechazo}
                    onChange={(e)=>setMotivoRechazo(e)}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant='secondary'
                    onClick={()=>setModalRechzar()}
                >
                    Cancelar
                </Button>
                <Button
                    variant='danger'
                    onClick={()=>rechazarSolicitud()}
                >
                    Rechzar
                </Button>
            </Modal.Footer>
        </Modal>

        {/* modal recibir solicitud */}

        <Modal 
            show={modalRecibir}
            onHide={()=>setModalRecibir()}
        >
            <Modal.Body>
                <b>¿Desea recibir esta solicitud ?</b>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant='secondary'
                    onClick={()=>setModalRecibir()}
                >
                    Cancelar
                </Button>
                <Button
                    onClick={()=>recibirSolicitud()}
                >
                    Aceptar
                </Button>
            </Modal.Footer>
        </Modal>
    </>
  )
}
