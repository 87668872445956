import React from 'react'
import { DynamicFormContainer } from '../../components/DynamicForm/DynamicFormContainer'
import { useNavigate } from 'react-router-dom'

export default function Funciones() {
    const navigate = useNavigate()
  return (
    <DynamicFormContainer 
        idForm="142"
        labelOptionClick={'Cursos requeridos'}
        handleOptionClick={(e)=>navigate(`${e.IdFuncionSaa}/cursos_requeridos`,{state:{funcion:e}})}
    />
  )
}
