import { Route, Routes } from "react-router-dom"
import { DynamicFormContainer } from "../../../components/DynamicForm/DynamicFormContainer"
import { UsuariosContainer } from "../../../components/outlet/usuarios/UsuariosContainer"



function Seguridad () {

  return (
    <Routes>
      <Route  path="modulos_y_ventanas" element = {<DynamicFormContainer idForm="66"/>}></Route>
      <Route  path="usuarios" element = {<UsuariosContainer />} />
      <Route  path="roles_y_ventanas" element = {<DynamicFormContainer idForm="65"/>}></Route>
      <Route  path="usuarios_y_roles" element = {<DynamicFormContainer idForm="69"/>}></Route>
      <Route  path="*" element = {<DynamicFormContainer />}></Route>

    </Routes>
  )
}
export { Seguridad }