import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, Col, Form, Modal, Row, Table } from 'react-bootstrap'
import { alerta } from '../../../helpers/alertas';
import { AsignarSemestre } from '../../../modules/componentesC/AsginarSemestre/AsignarSemestre';
import { BASE_URL } from '../../../services/api/config';
import { FormService } from '../../../services/api/formService';
import { Select } from '../../select/Select';

export default function SeleccionarAsignaturas({
    aspirante,
    IdOferta,
    admision=null,
    asignaturasAprobadas,
    setAsignaturasAprobadas,
    cambiarEstado,
}) {
    console.log(asignaturasAprobadas)
    const [asignaturas,setAsignaturas]=useState([]);
    const api=new FormService()
    const [modal,setModal]=useState(false)
    const [IdMalla,setIdMalla]=useState(false)
    const [cargando,setCargando]=useState(null);
    const [asignaturasPagas,setAsignaturasPagas]=useState(aspirante.AsignaturasPagas)
    const [semestre,setSemestre]=useState(aspirante.IdSemestre);
    const [miPrimerPerido,setMiprimerPeriodo]=useState(null);
    const [totalCreditos, setTotalCreditos] = useState(0);
    const [modalRechazar,setModalRechazar]=useState(false)
    const [motivoRechazo,setMotivoRechazo]=useState("")
    const [IdAsginatura,setIdAsignatura]=useState(null)
    const [modalMotivoRechazo,setModalMotivoRechazo]=useState(false)

    const urlInstructivo=`${BASE_URL}/educacionIES/descargarReciboAsignaturas?IdAspirante=${aspirante.IdAspirante}`
    const urlAsignaturas=`educacionIES/asignaturasPeriodos?IdOferta=${IdOferta}&IdUsuario=${aspirante.IdUsuario}&IdAspirante=${aspirante.IdAspirante}`

    useEffect(()=>{
        setAsignaturasAprobadas(aspirante.AsignaturasAprobadas)
        const fecthData=async()=>{
            consultarAspirante();
            consultarAsignaturas();
            consultarMiPrimerPerido();
            
        }
        fecthData();
    },[])



    useEffect(() => {
        const total = asignaturas.reduce((acumulador, asignatura) => acumulador + Number(asignatura.Creditos), 0);
        setTotalCreditos(total);
    }, [asignaturas]);

    const consultarAsignaturas=async()=>{
        const asignaturas= await api.getAll(`educacionIES/asignaturasPeriodo?IdAspirante=${aspirante.IdAspirante}`)
        setAsignaturas(asignaturas)
    }
    const agregarAsignatura=async()=>{
        const data={
            "IdMalla":IdMalla,
            "IdAspirante":aspirante.IdAspirante,
            "IdUsuario":aspirante.IdUsuario
        }
        const respuesta= await api.create("educacionIES/asignarAsignatura",data)
        if(respuesta.respuesta=="exito"){
            alerta(respuesta.mensaje)
            setModal(false)
            consultarAsignaturas()
        }else{
            alerta(respuesta.mensaje)
        }
    }
// ------
    const eliminarAsignatura=async(Id)=>{
        const data={
            "IdMallaAspirante":Id
        }
        const respuesta=await api.create("estudiante/eliminarAsignatura",data)
        if(respuesta.respuesta=="exito"){
            alerta(respuesta.mensaje)
            consultarAsignaturas()
            setModalMotivoRechazo(false)
        }else{
            alerta(respuesta.mensaje)
        }
    }
// ----
    const solicitarAprobacion=async()=>{
        const data={
            "IdAspirante":aspirante.IdAspirante
        }
        const respuesta=await api.create("educacionIES/solicitarAprobacionAsignaturas",data)
        if(respuesta.respuesta=="exito"){
            alerta(respuesta.mensaje)
            consultarAspirante()
        }else{
            alerta(respuesta.mensaje)
        }
    }
//--- admision
    const aprobarAsignaturas=async()=>{
        const data={
            "IdAspirante":aspirante.IdAspirante
        }
        const respuesta=await api.create("estudiante/aprobarAsignaturas",data)
        if(respuesta.respuesta=="exito"){
            consultarAspirante()
            alerta(respuesta.mensaje)
        }else{
            alerta(respuesta.mensaje)
        }
    }


//---- admision
    const rechazarAsignatura=async()=>{
        const data={
            "IdMallaAspirante":IdAsginatura,
            "MotivoRechazo":motivoRechazo
        }
        const respuesta=await api.create("estudiante/rechazarAsignatura",data)
        if(respuesta.respuesta=="exito"){
            alerta(respuesta.mensaje)
            consultarAsignaturas()
            setModalRechazar(false)
        }else{
            alerta(respuesta.mensaje)
        }
    }

    const consultarMiPrimerPerido=async()=>{
        const respuesta= await api.getAll(`educacionIES/miPrimerPerido?IdUsuario=${aspirante.IdUsuario}&IdOferta=${aspirante.IdOferta}`)
        if(respuesta.respuesta=="error"){
            setMiprimerPeriodo(true)
            setCargando(true)
        }else{
            setCargando(true)
        }
      }
    
    const consultarAspirante=async()=>{
        const respuesta= await api.getById(`aspirantes`,aspirante?.IdAspirante)
        setAsignaturasAprobadas(respuesta.AsignaturasAprobadas)
    }

    // enviar para correccion
    const enviarCorreccion=async()=>{
        const data={
            "IdAspirante":aspirante.IdAspirante
        }
        const respuesta=await api.create("educacionIES/enviarCorreccionAsignaturas",data)
        if(respuesta.respuesta=="exito"){
            alerta(respuesta.mensaje)
            consultarAspirante()
        }else{
            alerta(respuesta.mensaje)
        }
    }

  return (
    <>
        {aspirante.IdDimension==1 && miPrimerPerido?
            <>
                <Card>
                    <Row>
                        <Col>
                            <Card.Title>
                                Asignaturas
                                {asignaturasAprobadas=="1"?
                                    <> aprobadas </>
                                    :
                                    <> seleccionadas </>
                                }
                                para el período
                            </Card.Title>
                        </Col>
                        {!admision&&asignaturasAprobadas==0&&
                            <Col>
                                <div style={{textAlign:"right",paddingBottom:15}}>
                                    <Button 
                                        onClick={()=>setModal(true)}
                                    >
                                        Agregar asignatura
                                    </Button>
                                </div>
                            </Col>
                        }
                        
                    </Row>
                    <Table>
                        <thead>
                            <tr>
                                <th>Asignatura</th>
                                <th>Creditos</th>
                                {/* <th>Peso</th> */}
                                <th>Estado</th>
                                {!admision&&
                                <>
                                    {asignaturasAprobadas==0&&
                                        <ht>Acciones</ht>
                                    }
                                </>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {asignaturas.map((asignatura,key)=>
                                <tr keey={key}>
                                    <td>{asignatura.Descripcion}</td>
                                    <td>{asignatura.Creditos}</td>
                                    {/* <td>{asignatura.Peso}</td> */}
                                    <td>
                                    {admision &&
                                        <>
                                            {asignatura.Aprobada==0&&
                                                <>
                                                    Rechazada
                                                </>
                                            }
                                            {asignatura.Aprobada==1&&
                                                <>Seleccionada</>
                                            }
                                            {asignatura.Aprobada==2&&
                                                <>Seleccionada</>
                                            }
                                        </>
                                    }
                                    {!admision&&
                                        <>
                                        {asignatura.Aprobada==0&&
                                            <>
                                            Rechazada
                                                 {/* boton para ver motivo del rechazo */}
                                                 <Button 
                                                    size='sm'
                                                    onClick={()=>{
                                                        setModalMotivoRechazo(true)
                                                        setMotivoRechazo(asignatura.MotivoRechazo)
                                                        setIdAsignatura(asignatura.IdMallaAspirante)
                                                    }}
                                                 >
                                                    Ver motivio
                                                 </Button>
                                            </>
                                        }
                                        {asignatura.Aprobada==1&&
                                            <>Seleccionada</>
                                        }
                                        {asignatura.Aprobada==2&&
                                            <>Seleccionada</>
                                        }
                                        </>
                                    }
                                    </td>
                                    <td>
                                        {admision&&asignaturasAprobadas==2&&
                                        <>
                                        {
                                            asignatura.Aprobada!=0?
                                            <Button variant='danger'
                                                onClick={()=>{
                                                    setModalRechazar(true)
                                                    setIdAsignatura(asignatura.IdMallaAspirante)
                                                }
                                            }
                                            >
                                                Rechazar
                                            </Button>
                                            :
                                                <Button variant='danger'
                                                    disabled
                                                >
                                                    Rechazar
                                                </Button>
                            
                                        }
                                        </>
                                            
                                        }
                                        {!admision&&asignaturasAprobadas==0&&
                                            <Button variant='danger'
                                                onClick={()=>eliminarAsignatura(asignatura.IdMallaAspirante)}
                                            >Eliminar</Button>                                
                                        }
                                    </td>                                    
                                </tr>
                            )}
                             <tr>
                                <td><strong>Total de créditos</strong></td>
                                <td><strong>{totalCreditos}</strong></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </Table>
                    {!admision&&asignaturasAprobadas==0&&
                        <Button
                            onClick={solicitarAprobacion}
                        >Solicitar aprobación</Button>
                    }

                    {admision&&asignaturasAprobadas==2&&
                    
                    // verificar si hay una asignatura rechazada para no aprobarlas si no enviar para corregir
                    <>
                    {
                        asignaturas.filter(asignatura=>asignatura.Aprobada==0).length==0?
                        <Button
                            onClick={aprobarAsignaturas}
                        >
                            Aprobar Asignaturas
                        </Button>
                        :
                        <Button
                            onClick={enviarCorreccion}
                        >
                            Enviar para corrección
                        </Button>
                    }                        
                    </>
                    
                    }
                </Card>
                {!admision&&
                    <>
                        {asignaturasAprobadas=="1" && asignaturasPagas=="0" &&
                            <div style={{textAlign:"center"}}>
                                <a target="blank" href={urlInstructivo}>Descargar instructivo de pago</a>    
                            </div> 
                        }
                        {asignaturasAprobadas=="2" && asignaturasPagas=="0" &&
                            <Alert>Esperando aprobación de asignaturas</Alert>
                        }                         
                    </>
                }       
            </>
            :
            <>
            {aspirante.IdDimension==1&& cargando ?
                <>
                <Alert>
                    Esperando asignación de las asginaturas del primer semestre
                </Alert>
                {admision&&
                    <AsignarSemestre
                        consultarAsignaturas={consultarAsignaturas}
                        semestre={semestre}
                        miPrimerPerido={consultarMiPrimerPerido}
                        consultarMiPrimerPerido={consultarMiPrimerPerido}
                        setSemestre={setSemestre} 
                        usuario={aspirante} 
                        IdOferta={aspirante.IdOferta}
                    />
                }            
                </>
                :aspirante.IdDimension==1 &&
                    <Alert> Cargando... </Alert>
            } 
            </>
        }



        <Modal 
            show={modal}
            onHide={()=>setModal(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>Agregar asignatura</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Select
                    label="Seleccione la asignatura"
                    optionValue={"IdMalla"}
                    value={IdMalla}
                    optionName={"Descripcion"}
                    onChange={(e)=>setIdMalla(e)}
                    urlOptionsService={urlAsignaturas}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={(e)=>agregarAsignatura(e)}
                >Agregar asignatura</Button>
            </Modal.Footer>
        </Modal>

        {/* modal para rechzar la asignatua que tenga un MotivoRechazo */}

        <Modal
            show={modalRechazar}
            onHide={()=>setModalRechazar(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>Rechazar asignatura</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Motivo de rechazo</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={motivoRechazo}
                            onChange={(e)=>setMotivoRechazo(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={()=>rechazarAsignatura()}
                >Rechazar</Button>
            </Modal.Footer>
        </Modal>
        {/* modal para ver motivo del rechazo */}
        <Modal
            show={modalMotivoRechazo}
            onHide={()=>setModalMotivoRechazo(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>Motivo de rechazo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {motivoRechazo}
                </p>
                {/* alerta boostrap*/}
                <Alert variant="warning">
                    <Alert.Heading>Atención</Alert.Heading>
                    <p>
                    Si desea volver a agregar una asignatura en vez de esta, debe eliminarla y agregar nuevamente la asignatura que desee.
                    </p>
                </Alert>
                <Button
                    variant="danger"
                    onClick={()=>eliminarAsignatura(IdAsginatura)}
                >Eliminar Asginatura</Button>

            </Modal.Body>
            <Modal.Footer>
                <Button 
                    onClick={()=>setModalMotivoRechazo(false)}
                >Cerrar</Button>
            </Modal.Footer>
        </Modal>
       

    </>
    
  )
}
