import React from 'react'

import { Button } from '../button/button'
import { Card } from '../card/Card'

import './JobOfferCard.css'
import { Col, Row } from 'react-bootstrap'
import { BASE_URL_REPOSITORIO } from '../../services/api/config'

const JobOfferCard = ({
    nombre="Titulo",
    descripcion="Descripción...",
    lugar,
    empresa,
    telefono,
    email,
    imagen
}) => {
    return (
        <Card>
            <h3 className='job-offer-card__title'>{nombre}</h3>
            <Row>
                {/* 6 espacios */}
                <Col sm="6" >
                    <div 
                        style={{
                            background:"#fff",
                            textAlign:"center"
                        }}
                    >
                    {imagen
                        ? <img 
                            style={{
                                maxWidth:"300px",
                                maxHeight:"300px"
                            }}
                        src={`${BASE_URL_REPOSITORIO}/${imagen}`}/>
                        : <>Sin imagen</>
                    }
                    </div>
                </Col>
                <Col>
                    <p className='job-offer-card__text'>{descripcion}</p>
                    <div className='job-offer-card__description'>
                        {/* <p>COP $8.000.000 / mes</p>
                        <p>Tiempo completo</p>
                        <p>Presencial</p> */}
                        <p><span className='n-job'>Lugar:</span>  {lugar}</p>
                        <p><span className='n-job'>Empresa:</span>  {empresa} </p>
                        <p><span className='n-job'>Telefóno de contacto: </span> {telefono} </p>
                        <p><span className='n-job'>Email de contacto:</span> {email}</p>
                    </div>
                    {/* <Button type='send'>
                        Ver
                    </Button> */}
                </Col>
            </Row>
            
        </Card>
    )
}

export { JobOfferCard }