import { useNavigate } from "react-router-dom"
import { Card } from "../../../components/card/Card";
import { DynamicTable } from "../../../components/DynamicForm/dynamicTable/DynamicTable"
import { LocalStorage } from "../../../services"
import { BASE_URL } from "../../../services/api/config";
import { Title } from "../../../shared/components/title/Title"

function ActividadesFinalizadas () {

  const api = new LocalStorage();
  const idUsuario = api.getAsJsonItem("usuario")?.usuario?.IdUsuario || 'localstorage_empty'
 
  const urlProgramas = `cursos/finalizadosRegistro`;
  const urlExportar=`${BASE_URL}/exportar/fomatoOFA?IdPeriodo=1823`
  const navigate = useNavigate();

  const onNavigate = (programa) => {
    navigate(`${programa.IdOferta}/asignaturas`,{state:{programa,idUsuario}});
  }
  return (
    <>
    <Card>
     <Title>Actividades Finalizados</Title>
     <div style={{textAlign:"right"}}>
      <a className="button" href={urlExportar}>Exportar listado</a>
     </div>
    </Card>
        <DynamicTable
          titleColumn={['N°','Codigo grupo','Nombre','Fecha Inicio','Fecha Fin','Acción']}
          attributes={['codigo_grupo','Nombre','FechaInicio','FechaFin']}
          endPoint={`${urlProgramas}?IdPeriodo=53`}
          onDelete={false}
          onUpdate={false}
          labelOptionClick={`Ver más`}
          handleOptionClick={onNavigate}
          classNameOptionIcon={`bi bi-view-list`}
        >
        </DynamicTable>

    </>
  )
}
export { ActividadesFinalizadas }