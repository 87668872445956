import React from 'react'
import { Button, Card, Col, Dropdown, Row } from 'react-bootstrap'
import { BASE_SERVIDOR } from '../../services/api/config';
import {IoMdSettings} from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { FormService } from '../../services/api/formService';
import { alerta } from '../../helpers/alertas';
import Swal from 'sweetalert2';

export default function GeneradoContenido({seccion,setModalAR,setSeccionActual,setDataAR}) {
  const navigate = useNavigate()
  const api =new FormService()
    const codigoVideo = (url) => {
        if (url.includes('youtu.be')) {
            return url.split('youtu.be/')[1];
        }
        if (url.includes('youtube.com')) {
            return url.split('youtube.com/watch?v=')[1];
        }
    }
  // eliminar actividad
  const eliminar = (actividad) => {
      // confirmacion una alerta
   const result = Swal.fire({
      title: '¿Seguro que quieres eliminar esta actividad?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar',
    })
    if (result.isConfirmed) {
      const respose=api.delete(`actividadesCursosLMS/${actividad.id}`)
      if(respose.success){
        alerta('Actividad eliminada correctamente')
      }else{
        alerta('No se pudo eliminar la actividad','error')
      }
    }
  }
  return (
    <>
         <Card>
              <Row>
                <Col sm={9}>
                  <Card.Body>
                  <Card.Title>{seccion?.nombre}</Card.Title>
                  <p>{seccion?.descripcion}</p>
                </Card.Body>
                </Col>
                <Col
                  style={{textAlign:'right'}}
                >
                  <Button
                    onClick={() =>{
                      setModalAR(true)
                      setSeccionActual(seccion)
                    }}
                  >
                      Agregar
                  </Button>
                </Col>
              </Row>  
                {/* Actividades o recuros */}
                {seccion.actividades.map((actividad)=>{
                  return(
                    <Card>
                      <Row>
                        <Col>
                          <Card.Body>
                          <Card.Title>{actividad?.nombre}</Card.Title>
                          <p>{actividad?.descripcion}</p>
                        </Card.Body>
                        </Col>
                        <Col
                          style={{textAlign:'right'}}
                        >
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="light"
                                id="dropdown-basic"
                              >
                                <IoMdSettings />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={()=>{
                                    setModalAR(true)
                                    setDataAR({...actividad})
                                  }}
                                >Editar</Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => { eliminar(actividad) }}
                                >Eliminar</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>

                        </Col>
                      </Row>
                      {actividad.id_tipo==1&&
                        <iframe width="100%" height="480" 
                            src={`https://www.youtube.com/embed/${codigoVideo(actividad.url)}`}
                            title="BIENVENIDA CURSO"
                            frameborder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                            allowfullscreen>
                        </iframe>                        
                      }
                      {actividad.id_tipo==2&&
                        <a href={actividad.url} target="_blank" rel="noopener noreferrer">
                          {actividad.url}
                        </a>
                      }
                      {actividad.id_tipo==3&&
                        <a href={`${BASE_SERVIDOR}/archivos_lms/${actividad.archivo}`} target="_blank" rel="noopener noreferrer">
                          {actividad.archivo}
                        </a>
                      }
                      {actividad.id_tipo==4&&
                        //   motrar el contenido en html guardado
                        <div dangerouslySetInnerHTML={{ __html: actividad.contenido }}>
                        </div>

                      }
                      { actividad.id_tipo==6&&
                          <Button
                            onClick={() => {
                              navigate(`${actividad.id_actividad}/cuestionario`,{state:{id_cuestionario:actividad.id_actividad}})
                            }}
                          >
                              Ver
                          </Button>
                      }
                    </Card>
                  )
                })}
            </Card>
    </>
  )
}
