import { Route, Routes } from "react-router-dom"
import { OfertasEmpleo } from "./OfertasEmpleo"
import {EstudianteCursosContainer} from "./cursosFinalizados/EstudianteCursosContainer"
import HistorialContinuada from "../componentesC/PerfilUsuario/HistorialContinuada"
import HistorialIES from "../componentesC/PerfilUsuario/HistorialIES"
import { Evaluaciones } from "./Evaluaciones"
import { Evaluacion } from "./Evaluacion"

function Egresado () {

  return (
    <Routes>
      <Route path="mis_cursos_finalizados" element={<EstudianteCursosContainer/>} />
      <Route path="ofertas_de_empleo"  element={<OfertasEmpleo/>}/>
      <Route path="actividades"  element={<HistorialContinuada perfil={false}/>}/>
      <Route path="programas"  element={<HistorialIES perfil={false}/>}/>
      <Route path="evaluaciones_egresados" element={<Evaluaciones/>} />
      <Route path="evaluaciones_egresados/:id" element={<Evaluacion/>}/>
    </Routes>
  )
}
export { Egresado }