import { useEffect } from 'react'
import { useState } from 'react'
import { Button, Card, Table } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { BASE_SERVIDOR } from '../../../services/api/config'
import { FormService } from '../../../services/api/formService'

function UnidadesAsignatura() {
    const location = useLocation()
    const api=new FormService();

    const [unidades,setUnidades]=useState([]);

    const navigate=useNavigate();
    const urlAsignaturas = `malla/unidadesOferta?IdOferta=${location.state.programa.IdOferta}&IdMalla=${location.state.asignatura.IdMalla}`
     useEffect(()=>{
        (async()=>{
            consultarUnidades();
        })()
     },[])

    const consultarUnidades=async()=>{

        const respuesta=await api.getAll(urlAsignaturas)
        setUnidades(respuesta)

    }
    
    return (
        <>
            <Card>
                <Card.Title>Unidades de Asignatura</Card.Title>
                <p>{location.state.asignatura.Descripcion}</p>
            </Card>
            <Table>
                <thead>
                    <th>Nombre</th>
                    <th>Porcentaje</th>
                    <th>Plan de Asignatura</th>
                    <th>Ver más</th>
                </thead>
                <tbody>
                    {unidades.map((unidad, key)=>
                        <tr key={key}>
                            <td>{unidad.Asignatura}</td>
                            <td>{unidad.Porcentaje}</td>
                            <td>
                                {unidad.plan&&
                                    <a target="_blank" href={`${BASE_SERVIDOR}/pdf/${unidad.plan.Documento}`}>Plan de Asignatura</a>
                                }
                            </td>
                            <td>
                                <Button
                                    onClick={()=>{
                                        navigate('notas_y_asistencias',{
                                            state:{
                                                unidad,asignatura:location.state.asignatura,programa:location.state.programa,IdUsuario:location.state.IdUsuario}
                                            })
                                    }
                                    }
                                >Ver más</Button>
                            </td>
                        </tr>
                    )
                    }
                </tbody>
            </Table>
        </>
    )
}

export { UnidadesAsignatura }
