import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useFetchGet } from "../../../hooks/useFetchGet";
import user from '../../../assets/img/avatar.jpg';
import  './CursosActivos.css'
import { useState } from "react";
import { FormService } from "../../../services/api/formService";
import { optionsDate } from "../../../shared/util/date";

import { TextArea } from "../../../components/componentsForm/textarea/textarea";
import { alerta, alertaSinBoton } from '../../../helpers/alertas'
import { Badge, Button, Card, Col, Dropdown, DropdownButton, ListGroup, Modal, Row, Table } from "react-bootstrap";
import { useEffect } from "react";
import { BASE_SERVIDOR, BASE_URL} from "../../../services/api/config";
import { Input } from "../../../components/input/input";
import DowloadFile from "../../../components/DowloadFile/DowloadFile";
import { DynamicForm } from "../../../components/DynamicForm/DynamicForm";
import { DynamicTable } from "../../../components/DynamicForm/dynamicTable/DynamicTable";
import { LocalStorage } from "../../../services";
import { Alert } from "react-bootstrap";


function PlaneacionAcademicaMicro () {
  
  const api = new FormService()
  const location = useLocation()
  const localStorage = new LocalStorage()
  const user = localStorage.getAsJsonItem('usuario')
  const ID = user?.usuario?.IdUsuario
  const [dataAsignatura, setDataAsignatura] = useState({})
  const [error, setError] = useState(null)
  const [ready, setReady] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalEje, setModalEje] = useState()
  const [modalListaEje, setModalListaEje] = useState()
  const [modalEvaluacion, setModalEvaluacion] = useState()
  const [modalListaEvaluacion, setModalListaEvaluacion] = useState()
  const [modalRecurso, setModalRecurso] = useState()
  const [modalListaRecurso, setModalListaRecurso] = useState()
  const {
    IdPlanAsignaturaMicrocurriculo,
    RelacionEjeTematico, 
    RelacionAsignaturaConOtras, 
    Justificacion,
    CompetenciaGeneralAsignatura,
    ResultadoGeneralAsignatura,
    NombreEjeTematico1,
    CompetenciaEspecificaEje1,
    ResultadoCompetenciaEspecificaEje1,
    NombreEjeTematico2,
    CompetenciaEspecificaEje2,
    ResultadoCompetenciaEspecificaEje2,
    NombreEjeTematico3,
    CompetenciaEspecificaEje3,
    ResultadoCompetenciaEspecificaEje3,
    NombreEjeTematico4,
    CompetenciaEspecificaEje4,
    ResultadoCompetenciaEspecificaEje4,
    DeacripcionEvaluacion,
    AmbientesFisicos,
    AmbientesVirtuales,
    OtrosAmbientes,
    RequisitosNormativos,
    Aprobado,
    ObservacionAprobado
  } = dataAsignatura

  const fecthDataAsignatura = async () => {
    try {
        const userDataFetched = await api.getAll(`planAsignaturaMicro/plan?IdAsignatura=${location.state.item.IdAsignatura}&IdUsuario=${ID}`)
        setDataAsignatura(userDataFetched)
    } catch (err) {
        setError(err)
    }
  }

  const handleChange = (value, name) => {
    setReady(false)
    setError(null)
    setDataAsignatura({
        ...dataAsignatura,
        [name]: value
    })
  }

  const handleSudmit = async (e) => {
    e.preventDefault()
    
    if(IdPlanAsignaturaMicrocurriculo) {
      try {
        setError(null)
        setReady(false)
        setLoading(true)
        const respuesta = await api.update("planAcademicoMicrocurriculo", 
                                            { ...dataAsignatura }, 
                                            IdPlanAsignaturaMicrocurriculo
                                          )
        if(respuesta.respuesta=="exito"){
          alerta("Éxito", "success")
        } else {
          alerta("Fallo desconocido")
        }
      } catch (err) {
        setError(err)
        setLoading(false)
      }
    } else {

      try {
          setError(null)
          setReady(false)
          setLoading(true)
          const respuesta = await api.create("planAcademicoMicrocurriculo", {...dataAsignatura, IdAsignatura: location.state.item.IdAsignatura, IdUsuario: ID}, 0)
          if(respuesta.respuesta=="exito"){
            alerta("Éxito", "success")
          } else {
            alerta("Fallo desconocido")
          }
        } catch (err) {
          setError(err)
          setLoading(false)
        }
    }
  }

    useEffect(()=>{
      fecthDataAsignatura()
    },[])



  return (
    <>
    <Card>
      <h3>Planeación Académica Microcurriculo</h3>
      <h6>{location.state.item.Descripcion}</h6>
    </Card>
    <Card>
        {Aprobado == 0 &&
          <Alert bsStyle="warning">
            <Button variant="danger"></Button>
            <strong>Estado:</strong> Rechazado
            <br />
            <strong>{ObservacionAprobado}</strong>
          </Alert>
          
        }
        {Aprobado == 1 &&
          <Alert bsStyle="success">
            <Button variant="success"></Button>
            <strong>Estado:</strong> Aprobado
            <br />
            <strong>{ObservacionAprobado}</strong>
          </Alert>
        }
        <form 
          onSubmit={ handleSudmit }
          className='user-profile__form'
        >
          <Col>
            <Row>
              <TextArea
                id={'RelacionEjeTematico'}
                name={'RelacionEjeTematico'}
                label={"Relación de los Ejes Temáticos a Desarrollar"}
                value={RelacionEjeTematico || ''}
                onChange={handleChange}
              />
              <TextArea
                id={'RelacionAsignaturaConOtras'}
                name={'RelacionAsignaturaConOtras'}
                label={"Relación de la Asignatura con otras Asignaturas"}
                value={RelacionAsignaturaConOtras || ''}
                onChange={handleChange}
              />
              <TextArea
                id={'Justificacion'}
                name={'Justificacion'}
                label={"Justificación"}
                value={Justificacion || ''}
                onChange={handleChange}
              />
              <TextArea
                id={'CompetenciaGeneralAsignatura'}
                name={'CompetenciaGeneralAsignatura'}
                label={"Competencia General de la Asignatura"}
                value={CompetenciaGeneralAsignatura || ''}
                onChange={handleChange}
              />
              <TextArea
                id={'ResultadoGeneralAsignatura'}
                name={'ResultadoGeneralAsignatura'}
                label={"Resultado de Aprendizaje General de la Asignatura (Rac)"}
                value={ResultadoGeneralAsignatura || ''}
                onChange={handleChange}
              />
              <TextArea
                id={'NombreEjeTematico1'}
                name={'NombreEjeTematico1'}
                label={"Nombre del Eje Temático 1"}
                value={NombreEjeTematico1 || ''}
                onChange={handleChange}
              />
              <TextArea
                id={'CompetenciaEspecificaEje1'}
                name={'CompetenciaEspecificaEje1'}
                label={"Competencias Específicas Eje Temático 1"}
                value={CompetenciaEspecificaEje1 || ''}
                onChange={handleChange}
              />
              <TextArea
                id={'ResultadoCompetenciaEspecificaEje1'}
                name={'ResultadoCompetenciaEspecificaEje1'}
                label={"Competencias Específicas Eje Temático 1 - Resultados de Aprendizaje (RA)"}
                value={ResultadoCompetenciaEspecificaEje1 || ''}
                onChange={handleChange}
              />
              <TextArea
                id={'NombreEjeTematico2'}
                name={'NombreEjeTematico2'}
                label={"Nombre del Eje Temático 2"}
                value={NombreEjeTematico2 || ''}
                onChange={handleChange}
              />
              <TextArea
                id={'CompetenciaEspecificaEje2'}
                name={'CompetenciaEspecificaEje2'}
                label={"Competencias Específicas Eje Temático 2"}
                value={CompetenciaEspecificaEje2 || ''}
                onChange={handleChange}
              />
              <TextArea
                id={'ResultadoCompetenciaEspecificaEje2'}
                name={'ResultadoCompetenciaEspecificaEje2'}
                label={"Competencias Específicas Eje Temático 2 - Resultados de Aprendizaje (RA)"}
                value={ResultadoCompetenciaEspecificaEje2 || ''}
                onChange={handleChange}
              />
              <TextArea
                id={'NombreEjeTematico3'}
                name={'NombreEjeTematico3'}
                label={"Nombre del Eje Temático 3"}
                value={NombreEjeTematico3 || ''}
                onChange={handleChange}
              />
              <TextArea
                id={'CompetenciaEspecificaEje3'}
                name={'CompetenciaEspecificaEje3'}
                label={"Competencias Específicas Eje Temático 3"}
                value={CompetenciaEspecificaEje3 || ''}
                onChange={handleChange}
              />
              <TextArea
                id={'ResultadoCompetenciaEspecificaEje3'}
                name={'ResultadoCompetenciaEspecificaEje3'}
                label={"Competencias Específicas Eje Temático 3 - Resultados de Aprendizaje (RA)"}
                value={ResultadoCompetenciaEspecificaEje3 || ''}
                onChange={handleChange}
              />
              <TextArea
                id={'NombreEjeTematico4'}
                name={'NombreEjeTematico4'}
                label={"Nombre del Eje Temático 4"}
                value={NombreEjeTematico4 || ''}
                onChange={handleChange}
              />
              <TextArea
                id={'CompetenciaEspecificaEje4'}
                name={'CompetenciaEspecificaEje4'}
                label={"Competencias Específicas Eje Temático 4"}
                value={CompetenciaEspecificaEje4 || ''}
                onChange={handleChange}
              />
              <TextArea
                id={'ResultadoCompetenciaEspecificaEje4'}
                name={'ResultadoCompetenciaEspecificaEje4'}
                label={"Competencias Específicas Eje Temático 4 - Resultados de Aprendizaje (RA)"}
                value={ResultadoCompetenciaEspecificaEje4 || ''}
                onChange={handleChange}
              />
              <Card>
                <Row>
                  <label htmlFor=""><b> Ejes Temáticos </b></label>
                  <Col> 
                    <Button
                      variant="secondary"
                      onClick={() => setModalEje(true)}
                    >
                      Agregar
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      variant="secondary"
                      onClick={() => setModalListaEje(true)}
                    >
                      Listar
                    </Button>
                  </Col>
                </Row>
              </Card>
              <TextArea
                id={'DeacripcionEvaluacion'}
                name={'DeacripcionEvaluacion'}
                label={"Descripción de Evaluación"}
                value={DeacripcionEvaluacion || ''}
                onChange={handleChange}
              />
              <TextArea
                id={'AmbientesFisicos'}
                name={'AmbientesFisicos'}
                label={"Ambientes Físicos"}
                value={AmbientesFisicos || ''}
                onChange={handleChange}
              />
              <TextArea
                id={'AmbientesVirtuales'}
                name={'AmbientesVirtuales'}
                label={"Ambientes Virtuales"}
                value={AmbientesVirtuales || ''}
                onChange={handleChange}
              />
              <TextArea
                id={'OtrosAmbientes'}
                name={'OtrosAmbientes'}
                label={"Otros Ambientes"}
                value={OtrosAmbientes || ''}
                onChange={handleChange}
              />
              <TextArea
                id={'RequisitosNormativos'}
                name={'RequisitosNormativos'}
                label={"Requisitos Normativos"}
                value={RequisitosNormativos || ''}
                onChange={handleChange}
              />
              <Card>
                <Row>
                  <label htmlFor=""><b> Recursos Bibliográficos </b> </label>
                  <Col> 
                    <Button
                      variant="secondary"
                      onClick={() => setModalRecurso(true)}
                    >
                      Agregar
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      variant="secondary"
                      onClick={() => setModalListaRecurso(true)}
                    >
                      Listar
                    </Button>
                  </Col>
                </Row>
              </Card>
              {IdPlanAsignaturaMicrocurriculo ?
              <div style={{textAlign: "right", marginBottom:"15px", marginTop: "12px"}}>
                <Button 
                  type="send"
                >
                  Actualizar
                </Button>
              </div>
              :
              <div style={{textAlign: "right", marginBottom:"15px", marginTop: "12px"}}>
                <Button 
                  type="send"
                >
                  Guardar
                </Button>
              </div>
              }
            </Row>
          </Col>
        </form>
        </Card>

        <Modal
          show={modalEje}
          onHide={()=>setModalEje(false)}
        >
            <DynamicForm 
              title={"Planeación Académica"}
              justDynamicForm = {{ flag: true, idForm: "141" }}
              isCreate={true}
              endPointSubmit={"planeacionacademicasyllabus"}
              extraData={
                  {
                    IdUsuario: ID,
                    IdAsignatura: location.state.item.IdAsignatura
                  }
              }
              accionMysql={() => setModalEje(false)}
            />
        </Modal>

        <Modal
          show={modalListaEje}
          onHide={()=>setModalListaEje(false)}
          size="lg"
        >
          <DynamicTable
            titleColumn={["No", "Eje Temático", "Tema", "Estrategia Pedagógica", "Activida de Aprendizaje", "Actividad de Evaluación"]}
            attributes={[
                          "EjeTematico", "Tema", "EstrategiaPedagogica", "ActividadAprendizaje", "ActividadEvaluacion"
                        ]}
            endPoint={`ejeTematico?IdAsignatura=${location.state.item.IdAsignatura}&IdUsuario=${ID}`}
            onActions={false}
          />
        </Modal>
        
        <Modal
          show={modalRecurso}
          onHide={()=>setModalRecurso(false)}
        >
            <DynamicForm 
              title={"Recursos Bibliográficos"}
              justDynamicForm = {{ flag: true, idForm: "138" }}
              isCreate={true}
              endPointSubmit={"recursoBibliograficoSyllabus"}
              extraData={
                  {
                    IdUsuario: ID,
                    IdAsignatura: location.state.item.IdAsignatura
                  }
              }
              accionMysql={() => setModalRecurso(false)}
            />
        </Modal>

        <Modal
          show={modalListaRecurso}
          onHide={()=>setModalListaRecurso(false)}
          size="lg"
        >
          <DynamicTable
            titleColumn={["No", "Lectura Obligatoria", "Lectura de Referencia"]}
            attributes={["LecturaObligatoria", "LecturaReferencia"]}
            endPoint={`recursoBibliograficoSyllabus?IdAsignatura=${location.state.item.IdAsignatura}&IdUsuario=${ID}`}
            onActions={false}
          /> 
        </Modal>
    </>
  )
}

export  { PlaneacionAcademicaMicro };


