
import { Card } from "../../components/card/Card"
import { Title } from "../../shared/components/title/Title"
import { useLocation } from "react-router-dom"
import { Button, Modal, Table } from "react-bootstrap";
import { DynamicForm } from "../../components/DynamicForm/DynamicForm";
import { useEffect, useState } from "react";
import { LocalStorage } from "../../services";
import { FormService } from "../../services/api/formService";
import { BASE_SERVIDOR } from "../../services/api/config";
import { alerta as alert } from "../../helpers/alertas";
import './bienestar.css'
import { alertaDobleBtn } from "../../helpers/alertas";

const SeguiminetoAlerta=({responder=true})=>{
    const ls = new LocalStorage()
    const Location=useLocation();
    const alerta=Location.state.alerta
    const dimension = ls.getAsJsonItem('usuario').dimension
    const[modal,setModal]=useState()
    const [respuestas,setRespuestas]=useState([]);
    const IdUsuario = ls.getAsJsonItem('usuario').usuario?.IdUsuario
    const api=new FormService()

    useEffect(()=>{
        const fetchData=async()=>{
            consultarRespuestas();
        };

        fetchData();

    },[])

    const actualizar=()=>{
        setTimeout(() => {
            setModal(false)
            consultarRespuestas();
        }, 3000);
    }
    const consultarRespuestas=async()=>{
        const respuestas= await api.getAll(`respuestasAlertas?IdAlerta=${alerta?.IdAlerta}`)
        setRespuestas(respuestas)
    }
    // cerrar alerta
    const cerrarAlerta=async()=>{
        const data={
            EstadoAlerta:"Cerrada",
        }
        const respuesta= await api.update("alertas",data,alerta.IdAlerta)
        if(respuesta.success){
            alert("Alerta cerrada")
            // retroceder
            window.history.back()
        }else{
            alert("Fallo al cerrar alerta")
        }
    }

    return(
        <>
            <Card>
                <Title>Seguimiento de la Alerta</Title>
                {alerta?.IdTipo==1?
                <>
                <div><span className="N">Usuario:</span> {alerta.Estudiante}</div>
                <div><span className="N">N° Documento:</span> {alerta.Documento}</div>
                <div><span className="N">Motivo:</span> {alerta.Motivo}</div>
                <div><span className="N">Fecha:</span> {alerta.FechaCreacion}</div>
                <div><span className="N">Curso:</span> {alerta.curso}</div>
                </>
                :                 
                <>
                <div><span className="N">Estudiante:</span> {alerta.Estudiante}</div>
                <div><span className="N">N° Documento:</span> {alerta.Documento}</div>
                <div><span className="N">{dimension ? 'Programa:' : 'Curso:'}</span> {alerta.Curso}</div>
                {dimension == "true" && <div><span className="N">Semestre:</span> {alerta.Semestre} </div>}
                {alerta?.FechaClase?.fechaEvento ? <div><span className="N">Fecha de Clase:</span> {alerta?.FechaClase?.fechaEvento}</div> : <div><span className="N">Fecha de Clase:</span> {alerta?.FechaClase}</div>}
                <div><span className="N">Asignatura:</span> {alerta.Asignatura}</div>
                <div><span className="N">Docente:</span> {alerta.Docente}</div>                
                <div><span className="N">Observación:</span> {alerta?.Observacion}</div>
                <div><span className="N">Motivo:</span> {alerta.Motivo}</div>
                <div><span className="N">Fecha:</span> {alerta.FechaCreacion}</div>
                </>    
                }
                {responder&&
                    <div style={{textAlign:'right'}}>
                        {/* cerrar alerta */}
                        <Button
                            onClick={()=>cerrarAlerta()}
                        >
                            Cerrar Alerta
                        </Button>
                        {' '}
                        <Button 
                            onClick={()=>setModal(true)}
                        >
                            Responder
                        </Button>
                    </div>
                }
                
            </Card>
            <Card>
                <Table>
                    <thead>
                        <tr>
                            <th>Usuario</th>
                            <th>Mensaje</th>
                            <th>Ver documento</th>
                        </tr>
                    </thead>
                        <tbody>
                            {respuestas.map((respuesta)=>
                                <tr>
                                    <td>{respuesta.Usuario}</td>
                                    <td>{respuesta.Mensaje}</td>
                                    <td>
                                    {respuesta.Archivo&&
                                        <a target="blank" href={`${BASE_SERVIDOR}/pdf/${respuesta.Archivo}`}>Ver archivo</a>
                                    }
                                    </td>
                                </tr>
                            )
                            }
                        </tbody>
                    
                </Table>
            </Card>
            <Modal
              show={modal}
              onHide={()=>setModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Responde a esta alerta</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DynamicForm 
                        justDynamicForm={{idForm:98,flag:true}}
                        isCreate={true}
                        onClickCancelBtn={()=>setModal(false)}
                        extraData={{IdAlerta:alerta?.IdAlerta,IdUsuario:IdUsuario}}
                        endPointSubmit={'respuestasAlertas'}
                        accionMysql={()=>actualizar()}
                        isContentTypeFormData="1"
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}

export {SeguiminetoAlerta}