import React from 'react'
import { DynamicFormContainer } from '../../components/DynamicForm/DynamicFormContainer'
import { BASE_URL } from '../../services/api/config'

export default function TiposSolicitudes() {

  const urlExportar = `${BASE_URL}/exportarTipoSolicitudes`
  return (
    <DynamicFormContainer idForm="109" exportar={urlExportar}/>
  )
}
