import React from 'react'
import FullCalendar from '@fullcalendar/react' 
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

import esLocale from '@fullcalendar/core/locales/es'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useEffect } from 'react'
import './Calendar.css'
import { FormService } from '../../../../services/api/formService'
import { alerta, alertaSinBoton, alertaTimer } from '../../../../helpers/alertas'
import { Button, Col, Modal, Row, Table } from 'react-bootstrap' 
import { FormAsignarDocenteAula } from './FormAsignarDocenteAula'
import { SelectDataList } from '../../../../components/SelectDataList/SelectDataList'
import { optionsDate } from '../../../../shared/util/date'

export default function Calendar({
    editable=null,
    cordinador=null
}) {
    const { state } = useLocation()
    const api = new FormService()
    const [isOpenModalAsignacion, setIsOpenModalAsignacion] = useState()
    const [isOpenModalDelete, setIsOpenModalDelete] = useState()
    const [itemEventToDelete, setItemEventToDelete] = useState()
    const [itemSelected, setItemSelected] = useState()
    const [dateStrSelected, setDateStrSelected] = useState()
    const [originalEvents, setOriginalEvents] = useState([])
    const [loading, setLoading] = useState(false)
    const [eventos, setEventos]=useState([]);
    const [exitos, setExitos]=useState();
    const [fallos, setFallos]=useState();
    const [evento,setEvento]=useState();
    const [IdDocente,setIdDocente]=useState(null)
    const [eventoActual,setEventoActual]=useState();
    const [modalEditar,setModalEditar]=useState();
    const [isOpenModalRespuesta, setIsOpenModalRespuesta] = useState()
    const [html,setHtml]=useState();
    const [isUpdate,setsUIpdate]=useState(null)

    const [formData, setFormData] = useState({
        horaInicial:null,
        horaFinal:null,
        IdPlandeEstudio: '',
        IdUsuario: '',
        IdOferta:0,
        fechaInicial: dateStrSelected,
        fechaFinal: dateStrSelected,
        Observaciones: '',
        eventoPersonalizado:'',
        fechas: [],
        IdMalla: '',
    })

    useEffect(() => {
        (async () => {
            const originalEv = await api.getAll(
                `eventosAula?IdAula=${state.item.IdAula}`
            )
            if (originalEv?.data) {
                setOriginalEvents(originalEv.data.eventos)
            } else {
                setOriginalEvents(originalEv)
                console.log({ originalEv })
            }
        })()
    }, [isOpenModalDelete, isOpenModalAsignacion])

    const eventDataTransform = (event) => {
        return {
            id: event.IdEventoAula,
            title: event.Asignatura + ', ' + event.Docente,
            start: event.FechaInicio.replace(' ', 'T'),
            end: event.FechaFin.replace(' ', 'T'),
            allDay: false,
            classNames: ['calendar__event'],
        }
    }

    const handleDateClick = (arg) => {
        setsUIpdate(false)
        setFormData([])
        setItemSelected({
            date: arg.date,
            title: state?.item?.Edificio + ', aula ' + state?.item?.numero,
            item: state.item,
        })
        setDateStrSelected(arg.dateStr)
        setIsOpenModalAsignacion(true)

    }

    const onAddEvent = async (event, formData) => {
        setExitos(0)
        setFallos(0)
        setEventos([])
        try {
            setLoading(true)
            const data={
                "IdOferta":formData.IdOferta
            }
            const matriculados= await api.getById(`eventosAula/consultaMatriculados?IdOferta=${formData.IdOferta}`)
            if (parseInt(matriculados) > parseInt(state.item?.capacidad)) {
                alertaTimer(
                    `El aula tiene capacidad para ( ${state.item?.capacidad} ) estudiantes y hay ( ${matriculados} ) matriculados`,
                    'info',
                    4000
                )
                setLoading(false)
                return false
            }
            let getEventos=[]
            if(!isUpdate){
                getEventos = await api.create('eventosAula', event)
                console.log("creando¡¡¡¡")
            }else{
                getEventos = await api.update('eventosAula', event,eventoActual.IdEventoAula)
            }
            
            setEventos(getEventos);
            let fallos=0;
            let exitos=0;
            let html="";
            console.log(getEventos)
            for (let i = 0; i < getEventos.length; i++) {
                let element = getEventos[i];
                console.log(element)
                if (element.respuesta=='libre'){
                    exitos++;
                }else{
                    fallos++;
                }

            }
            setHtml(html)
            setExitos(exitos)
            setFallos(fallos)

            if (fallos>0) {
                setIsOpenModalRespuesta(true)
                setLoading(false)
                return false
            }else{
                setIsOpenModalAsignacion(false)
                alertaSinBoton(`Se registraron ${exitos} eventos`, 'success', 'bottom-end', 1900)
                setLoading(false)
                return true
            }
        } catch (error) {
            console.log('error addevento aula:', error)
            setLoading(false)
        }
    }

    const onEventClick = async (info) => {
        setEvento(false)
        setItemEventToDelete(info.event)
        const evento= await api.getAll(`eventosAula/evento?IdEventoAula=${info.event.id}`)
        setEventoActual(evento)
        setModalEditar(true)
        consultarDocentesEvento(info.event.id)
    }

    const onDeleteEvent = async () => {
        try {
            const respuesta= await api.delete('eventosAula', { Estado: '0' }, itemEventToDelete.id)
            if(respuesta.respuesta=="exito"){
                alerta(respuesta.mensaje)
            }else{
                alerta(respuesta.mensaje)
            }

            setIsOpenModalDelete(false)
            setModalEditar(false)
        } catch (error) {
            console.log('Error to delete', itemEventToDelete.id)
        }
    }

    const onCloseModal = async () => {
        setIsOpenModalDelete(false)
        setIsOpenModalAsignacion(false)
        setIsOpenModalRespuesta(false)
    }
    const consultarDocentesEvento=async(IdEventoAula)=>{

        const evento= await api.getAll(`eventosAula/evento?IdEventoAula=${IdEventoAula}`)
        setEvento(evento)
    }
    const agregarDocente=async()=>{
        if(IdDocente){
            const data ={
                "IdEventoAula":eventoActual.IdEventoAula,
                "IdDocente":IdDocente
            }
            const respuesta= await api.create("eventosAula/agregarDocenteEvento",data)
            if(respuesta.respuesta=="exito"){
                consultarDocentesEvento(eventoActual.IdEventoAula)
            }else if(respuesta.respuesta=="error"){
                alerta(respuesta.mensaje,"error")
            }else{
                alerta("Fallo desconocido","error")
            }
        }else{
            alerta("Busca y selecciona un docente")
        }
    }
    
    const eliminarDocenteEvento=async(IdEventoAulaDocente,IdEventoAula)=>{
        const data={"IdEventoAulaDocente":IdEventoAulaDocente}
        const respuesta=await api.create("eventosAula/eliminarDocenteEvento",data)

        if(respuesta.respuesta=="exito"){
            const evento= await api.getAll(`eventosAula/evento?IdEventoAula=${IdEventoAula}`)
            setEvento(evento)
        }
    }

    const onEditar=()=>{
        setIsOpenModalAsignacion(true)
        setsUIpdate(true)
        const fechaInicial= new Date(eventoActual.FechaInicio)
        const horaInicial=fechaInicial.toLocaleTimeString('en-GB').toString()

        const FechaFin= new Date(eventoActual.FechaFin)
        const horaFin=FechaFin.toLocaleTimeString('en-GB').toString()

        setFormData({...eventoActual, "horaFinal":horaFin.slice(0, -3),"horaInicial": horaInicial.toString().slice(0, -3)})

        console.log(FechaFin.toLocaleTimeString('it-IT'))
        setDateStrSelected(eventoActual.FechaInicio.toString().slice(0, -9))
        console.log(formData)
    }


    return (
        <>
            <section
                className="calendar-container"
                style={{ marginLeft: '40px', marginTop: '30px' }}
            >
                <h1 className="calendar-container__title">
                    {' '}
                    Aula {state?.item.numero}, {state?.item?.Edificio}
                </h1>
                <p
                    style={{ color: 'gray', marginBottom: '70px' }}
                    className="calendar-container__text"
                >
                    Bienvenido, asigna las actividades o programas a la fecha y
                    hora, junto al responsable.
                </p>
                <div
                    className="calendar-container__calendar"
                    style={{ width: '98%', justifyContent: 'center' }}
                >
                    <FullCalendar

                        plugins={[
                            dayGridPlugin,
                            timeGridPlugin,
                            interactionPlugin,
                        ]}
                        dateClick={handleDateClick}
                        initialView="dayGridMonth"
                        locale={esLocale}
                        eventClick={onEventClick}
                        displayEventEnd={true}
                        eventTimeFormat={{
                            hour: '2-digit',
                            minute: '2-digit',
                            meridiem: true,
                            hour12: true,
                        }}
                        themeSystem={'bootstrap5'}
                        headerToolbar={{
                            right: 'prev,next today',
                            left: 'title',
                            center: 'dayGridMonth,timeGridWeek,timeGridDay',
                        }}
                        events={originalEvents}
                        eventDataTransform={eventDataTransform}
                    />
                </div>
            </section>

            {/* Modal para gestionar evento */}
            <Modal 
                show={modalEditar}
                onHide={()=>setModalEditar(false)}
                size="lg"
            >
                <Modal.Header closeButton>                
                        <Modal.Title>
                            Gestionar Evento
                        </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>Programa: {eventoActual?.NombrePlanEstudio}</div>
                    <div>Asignatura: {eventoActual?.Asignatura}</div>
                    <div>Docente: {eventoActual?.Nombre}</div>
                    <div>
                        <Button 
                            variant='danger'
                            onClick={()=>setIsOpenModalDelete(true)}
                        >
                        Eliminar
                        </Button>
                        {' '}
                        <Button                            
                            onClick={()=>onEditar()}
                        >
                        Editar
                        </Button>
                    </div>
                    <div style={{marginTop:20}}>
                        <h5>Otros Docentes</h5>
                        <Row>
                            <Col>
                                <SelectDataList
                                    optionValue={"IdDocente"}
                                    optionName={"Nombre"}
                                    onChangeHandler={(e)=>setIdDocente(e)}
                                    name={"IdDocente"}
                                    endPoinLastUrl={"docente/full"}
                                    value={""}
                                    placeholder="Docente"
                                /> 
                            </Col>
                            <Col><Button onClick={()=>agregarDocente(evento?.IdEventoAula)}>Agregar</Button> </Col>
                        </Row> 
                        <div style={{marginTop:20}}>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Docente</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {evento && evento?.docentes.map((docente)=>
                                    <tr>
                                        <td>{docente.Nombre}</td>
                                        <td><Button 
                                            onClick={()=>eliminarDocenteEvento(docente.IdEventoAulaDocente,evento.IdEventoAula)}
                                        variant='danger'>Eliminar</Button></td>
                                    </tr>
                                    )
                                    }
                                </tbody>
                            </Table>

                        </div>
                         
                    </div>                        
                </Modal.Body>
            </Modal>

            {/* Modal para eliminar evento */}
            <Modal
                show={isOpenModalDelete}
                onHide={onCloseModal}
            >
                <Modal.Header>
                    <Modal.Title>
                        ¿ Deseas eliminar el evento asignado a "{' '}
                        {itemEventToDelete?.title} " ?     
                    </Modal.Title>                                                                           
                </Modal.Header>
                <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={()=>onCloseModal()}>
                            Cancelar
                        </Button>
                        <Button
                        variant="danger"
                        onClick={onDeleteEvent}>
                            Eliminar
                        </Button>
                    </Modal.Footer>
            </Modal>


            {/* Modal para asignar evento */}
            <Modal
                show={isOpenModalAsignacion}
                onHide={()=>setIsOpenModalAsignacion(false)}    
            >
            <Modal.Header closeButton>
                <Modal.Title>
                {itemSelected?.date.toLocaleDateString(
                    'es-co',
                    optionsDate
                    )
                }
                </Modal.Title>
            </Modal.Header>
                    <FormAsignarDocenteAula
                        isUpdate={isUpdate}
                        formData={formData}
                        setFormData={setFormData}
                        itemSelected={itemSelected}
                        dateStrSelected={dateStrSelected}
                        loading={loading}
                        onCloseModal={onCloseModal}
                        onAddEvent={onAddEvent}
                        cordinador={cordinador}
                    ></FormAsignarDocenteAula>
            </Modal>

            {/* Modal para mostrar respuesta al agregar evento */}
            <Modal
                show={isOpenModalRespuesta}
                onHide={()=>setIsOpenModalRespuesta(false)}
            >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <h3>Se registraron {exitos} eventos y fallaron {fallos} por los siguientes motivos</h3>
                    {eventos.map((evento, key)=>(
                        evento.respuesta=='ocupado'&&
                        <li className='lista-respuesta' key={key}> El aula se encuentra ocupada en la siguiente fecha {evento.fecha_inicio} a {evento.fecha_fin}</li>
                        ||
                        evento.respuesta=='rango'&&
                        <li className='lista-respuesta' key={key}> La asginación del siguente evento {evento.fecha_inicio} a {evento.fecha_fin} esta fuera del rango la actividad académica</li>
                        ||
                        evento.respuesta=='docente_ocupado'&&
                        <li className='lista-respuesta' key={key}>El docente o responsable se encuentra ocupado en la sigueinte fecha {evento.fecha_inicio} a {evento.fecha_fin} </li>                        
                        ||
                        evento.respuesta=="horas"&&
                        <li className='lista-respuesta'>La intensida horaría de la asignatura se ha superado no se puede programar la siguiente fecha {evento.fecha_inicio} a {evento.fecha_fin} </li>
                        ))}          
            </Modal.Body>
            </Modal>
        
        </>
    )
}


