import { Card } from "react-bootstrap"
import { DynamicForm } from "../../components/DynamicForm/DynamicForm"

const FormularioPsicosocial=()=>{

    return(
        <>
            <Card>
                <DynamicForm 
                    justDynamicForm = {{ flag: true, idForm: 99 }}
                    isCreate={true}
                    endPointSubmit={"bienestarFormularios"}
                />
            </Card>
        </>
    )
}

export {FormularioPsicosocial}