 import { useLocation } from "react-router-dom"
import { Card } from "../../../../components/card/Card";
import { DynamicForm } from "../../../../components/DynamicForm/DynamicForm";


function Instrumento (props) {

  const {state:{id,title}} = useLocation();
  // const [asistencias, loading, error] = useFetchGet(`estudiante/asistencia?IdOferta=${item.IdOferta}&IdEstudiante=${item.IdEstudiante}`)
  // const [asistencias, loading, error] = useFetchGet(`estudiante/asistencia?IdOferta=${itemParent.IdOferta}&IdEstudiante=${item.IdEstudiante}`)
  return (
    <Card className='p-5'>
   <DynamicForm
      title={null}
      parentField = { null}
      ventanaFormName={title}
      endPointSubmit={'instrumento'}
      keySubmit={'k'}
      isContentTypeFormData={'0'}
      isCreate={true}
      justDynamicForm={{flag:true,idForm:id}}
   >
    </DynamicForm> 

    </Card>
  )
}

export { Instrumento }