import React from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction' // needed for dayClick

import esLocale from '@fullcalendar/core/locales/es'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useEffect } from 'react'
import './Calendar.css'
import { FormService } from '../../../../services/api/formService'
import { alertaSinBoton, alertaTimer } from '../../../../helpers/alertas'
import { Modal } from '../../../../components/modal/Modal'
import { FormAsignarDocenteAula } from './FormAsignarDocenteAula'
import { Button } from '../../../../components/button/button'

export default function Calendar() {
    const { state } = useLocation()
    const api = new FormService()
    const [isOpenModalAsignacion, setIsOpenModalAsignacion] = useState()
    const [isOpenModalDelete, setIsOpenModalDelete] = useState()
    const [itemEventToDelete, setItemEventToDelete] = useState()
    const [itemSelected, setItemSelected] = useState()
    const [dateStrSelected, setDateStrSelected] = useState()
    const [originalEvents, setOriginalEvents] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        ;(async () => {
            const originalEv = await api.getAll(
                `eventosAula?IdAula=${state.item.IdAula}`
            )
            console.log(state.item)
            if (originalEv?.data) {
                setOriginalEvents(originalEv.data.eventos)
            } else {
                setOriginalEvents(originalEv)
                console.log({ originalEv })
            }
        })()
        // console.table([...eventsArray])

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpenModalDelete, isOpenModalAsignacion])

    const eventDataTransform = (event) => {
        return {
            id: event.IdEventoAula,
            title: event.Asignatura + ', ' + event.Docente,
            start: event.FechaInicio.replace(' ', 'T'),
            end: event.FechaFin.replace(' ', 'T'),
            allDay: false,
            classNames: ['calendar__event'],
        }
    }

    const handleDateClick = (arg) => {
        // bind with an arrow function
        // console.log('click calendar', arg)
        // // alert(arg.dateStr)
        // setItemSelected({
        //     date: arg.date,
        //     title: state?.item?.Edificio + ', aula ' + state?.item?.numero,
        //     item: state.item,
        // })
        // setDateStrSelected(arg.dateStr)
        // setIsOpenModalAsignacion(true)
    }

    const onAddEvent = async (event, formData) => {
        //fetcheventos aulas post and get
        // setEventsArrayy(response)
        try {
            setLoading(true)
            const ofertas = await api.getAll('ofertas')
            const ofertaSelected = await ofertas.find(
                (item) => item.IdPlanEstudio === formData.IdPlanEstudio
            )
            console.log(formData, 'planes:', ofertas, ofertaSelected)

            if (
                parseInt(ofertaSelected?.Cupos) >
                parseInt(state.item?.capacidad)
            ) {
                alertaTimer(
                    `El aula no tiene capacidad( ${state.item?.capacidad} ) para la oferta actual( ${ofertaSelected?.Cupos} ), selecciona otra oferta `,
                    'info',
                    4000
                )
                setLoading(false)
                return false
            }

            const eventos = await api.create('eventosAula', event)
            if (eventos?.respuesta === 'ocupado') {
                alertaTimer(
                    `El aula ${state.item.numero} del ${state.item.Edificio}  se encuentra ocupado `,
                    'info',
                    3000
                )
                setLoading(false)
                return false
            }
            if (eventos?.respuesta === 'docente_ocupado') {
                alertaTimer(
                    `El docente o responsable se encuentra ocupado en este horario`,
                    'info',
                    3000
                )
                return false
            }
            // console.log(eventos)
            setIsOpenModalAsignacion(false)
            alertaSinBoton('Compleado!', 'success', 'bottom-end', 1900)
            setLoading(false)
            return true
        } catch (error) {
            console.log('error addevento aula:', error)
            setLoading(false)
        }
    }
    const onEventClick = async (info) => {
        // eventClickInfo
        // console.log(info)
        setItemEventToDelete({ id: info.event.id, title: info.event.title })
        setIsOpenModalDelete(true)
    }
    const onDeleteEvent = async () => {
        // console.log({itemEventToDelete},{originalEvents})
        // const dataToDelete = originalEvents.find(item=>item.IdEventoAula===itemEventToDelete.id)
        // console.log("datato delete",dataToDelete,originalEvents)
        try {
            api.delete('eventosAula', { Estado: '0' }, itemEventToDelete.id)
            // api.delete('eventosAula',{...dataToDelete,Estado:'0'},itemEventToDelete.id)
            setIsOpenModalDelete(false)
        } catch (error) {
            console.log('Error to delete', itemEventToDelete.id)
        }
    }
    const onCloseModal = async () => {
        setIsOpenModalDelete(false)
        setIsOpenModalAsignacion(false)
    }

    return (
        <>
            <section
                className="calendar-container"
                style={{ marginLeft: '40px', marginTop: '30px' }}
            >
                <h1 className="calendar-container__title">
                    {' '}
                    Aula {state?.item.numero}, {state?.item?.Edificio}
                </h1>
                <p
                    style={{ color: 'gray', marginBottom: '70px' }}
                    className="calendar-container__text"
                >
                    Bienvenido, asigna las actividades o programas a la fecha y
                    hora, junto al responsable.
                </p>
                <div
                    className="calendar-container__calendar"
                    style={{ width: '98%', justifyContent: 'center' }}
                >
                    <FullCalendar
                        plugins={[
                            dayGridPlugin,
                            timeGridPlugin,
                            interactionPlugin,
                        ]}
                        dateClick={handleDateClick}
                        initialView="dayGridMonth"
                        locale={esLocale}
                        eventClick={onEventClick}
                        displayEventEnd={true}
                        eventTimeFormat={{
                            hour: '2-digit',
                            minute: '2-digit',
                            meridiem: true,
                            hour12: true,
                        }}
                        themeSystem={'bootstrap5'}
                        headerToolbar={{
                            right: 'prev,next today',
                            left: 'title',
                            center: 'dayGridMonth,timeGridWeek,timeGridDay',
                        }}
                        events={originalEvents}
                        eventDataTransform={eventDataTransform}
                    />
                </div>
            </section>

            <Modal
                isOpen={isOpenModalDelete}
                close={onCloseModal}
                hasButton={true}
            >
                <div>
                    {' '}
                    ¿ Deseas eliminar el evento asignado a "{' '}
                    {itemEventToDelete?.title} " ?
                </div>
                <div className="d-flex  justify-content-end">
                    <Button type={'danger'} onClick={onDeleteEvent}>
                        {' '}
                        Eliminar{' '}
                    </Button>
                </div>
            </Modal>

            <Modal
                isOpen={isOpenModalAsignacion}
                hasButton={true}
                // close={true}
                close={onCloseModal}
            >
                <div>
                    <FormAsignarDocenteAula
                        itemSelected={itemSelected}
                        dateStrSelected={dateStrSelected}
                        loading={loading}
                        onCloseModal={onCloseModal}
                        onAddEvent={onAddEvent}
                    ></FormAsignarDocenteAula>
                </div>
            </Modal>
        </>
    )
}


