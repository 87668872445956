import React, { useEffect } from 'react'
import { useState } from 'react'
import { Button, Card, Modal,Alert} from 'react-bootstrap'
import { useLocation } from 'react-router-dom';
import { CardPay } from '../../components/cardPay/CardPay';
import { DynamicForm } from '../../components/DynamicForm/DynamicForm';
import { useFetchGet } from '../../hooks/useFetchGet';
import { BASE_URL } from '../../services/api/config';
import { FormService } from '../../services/api/formService';
import RequisitosGrado from './RequisitosGrado';
import Header from '../componentesC/PerfilUsuario/Componentes/Header';
import { alerta } from '../../helpers/alertas';

export default function Incripcion() {
    const[modal,setModal]=useState();
    const Location=useLocation();
    const curso=Location.state.curso
    const usuario=Location.state.usuario
    const [inscripcion, setInscripcion] = useState(false)

    useEffect(()=>{
        setInscripcion(curso?.Inscripcion)
    },[])

    const api= new FormService();
    const [registerPay, loadingRegister, errorRegister] = useFetchGet(curso?.IdPago!=0?`pagos/${curso?.IdPago}`:'')
    const [urlBill,_] = useState(
        `${BASE_URL}/estudiante/descargarRecibo?IdOFerta=${curso?.IdOferta}&IdAspirante=${curso?.IdAspirante}&Concepto=${'inscripcion'}`
    )
    
    // incribirme
    const incripcionConvocatoria=async()=>{
        const data={
            IdEstudiante:curso.IdEstudiante,
            IdCursoGrado:curso.IdCursoGrado
        }
        const response=await api.create('convocatoriaGrados/inscripcion',data)
        if(response.success){
            setModal(false)
            setInscripcion(response.data)
        }else{
            alerta('No se pudo realizar la inscripción')
        }
    }
    
    return (
    <>
    <div style={{maxWidth:1000,margin:"0 auto",marginTop:40}}>
        <Header usuario={usuario}/>
        <Card>
        <Card.Title>
            Proceso de Solicitud de Grado
        </Card.Title>
        </Card>    
        <Card>
            {inscripcion==false&&
                <>
                <Card>
                    <Card.Title>
                        Información de la convocatoria
                    </Card.Title>
                    <Card.Body>
                        <div className=''>
                            <div>
                                <p>Programa: {curso?.Curso}</p>
                                <p>Nombre: {curso?.Nombre}</p>
                                <p>Descripción: {curso?.Descripcion}</p>
                                <p>Fecha: {curso?.Fecha}</p>
                                <p>Lugar: {curso?.Lugar}</p>
                                <p>Valor: {curso?.ValorPago}</p>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <Button
                    onClick={()=>setModal(true)}
                >Inscribirme</Button>
                </>
            }
            {inscripcion!=false&&
                <>
                {inscripcion.EstadoSolicitud==0&&
                    <Alert varia>
                        Usted esta inscrito en la convocatoria, sigue las siguiente indicaciones para completar su proceso.
                    </Alert> 
                }
                {inscripcion.EstadoSolicitud==1&&
                    <>
                        <Alert variant='success'>
                            Se ha aprobo su solicitud de inscripción al grado.
                        </Alert>
                        <Card>
                            <Card.Title>
                                Información de la convocatoria
                            </Card.Title>
                            <Card.Body>
                                <div className=''>
                                    <div>
                                        <p>Programa: {curso?.Curso}</p>
                                        <p>Nombre: {curso?.Nombre}</p>
                                        <p>Descripción: {curso?.Descripcion}</p>
                                        <p>Fecha: {curso?.Fecha}</p>
                                        <p>Hora: {curso?.Hora}</p>
                                        <p>Lugar: {curso?.Lugar}</p>                                        
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </>
                }
                {inscripcion.EstadoSolicitud==2&&
                    <Alert variant='danger'>
                        <p>Se ha rechazado su solicitud de inscripción.</p>
                        <p>Observación: {inscripcion.RespuestaSolicitud}</p>
                    </Alert>
                } 
                {inscripcion?.EstadoSolicitud==0&&
                <>
                    <div className='d-flex justify-content-around'> 
                    {                   
                        <CardPay
                            title={'Derechos de Grado'}
                            price={curso.ValorPago}
                            description={curso.NombrePago}
                            isDownload
                        >
                            <Button
                                className={'p-3'}
                                type={'button send'}
                            >
                                <a 
                                    style={{color:'white'}} 
                                    href={ urlBill } 
                                    download
                                >
                                    Descargar Instructivo 
                                </a> 
                            </Button>
                        </CardPay>                        
                    }
                    </div>
                    <RequisitosGrado IdAspirante={curso.IdAspirante} IdOferta={curso.IdOferta} IdUsuario={usuario.IdUsuario}/>
                </>
                }
                

            </>
            }
        </Card>

        <Modal 
            show={modal}
            onHide={()=>setModal(false)}
        >
            <Modal.Header 
                closeButton
            >
                <Modal.Title>Inscribirme a la Convocatoria</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    ¿Esta seguro de inscribirse a la convocatoria de grado?
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={()=>setModal(false)}
                >
                    Cancelar
                </Button>
                <Button
                    onClick={()=>incripcionConvocatoria()}
                >
                    Aceptar
                </Button>
            </Modal.Footer>
        </Modal>
    </div>
    </>
  )
}
