import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { EstudianteHistorial } from '../componentesC/estudianteHistorial/EstudianteHistorial'
import { PerfilEstudiante } from '../componentesC/estudianteHistorial/PerfilEstudiante'
import ActividadesAcademicas from './ActividadesAcademicas'
import ActividadesTercerizadas from './ActividadesTercerizadas'


export default function ConsultaAcademica() {
  return (
    <Routes>
        <Route path="consulta" element={<EstudianteHistorial/>} />
        <Route path="consulta/perfil_estudiante" element={<PerfilEstudiante full={false} />} />
        <Route path="actividades_academicas" element={<ActividadesAcademicas/>} />
        <Route path="actividades_tercerizadas" element={<ActividadesTercerizadas/>} />  
    </Routes>
  )
}
