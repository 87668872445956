import { useNavigate } from "react-router-dom";
import { LocalStorage } from "../../../services";
import { DynamicTable } from "../../../components/DynamicForm/dynamicTable/DynamicTable";
import { Modal } from "../../../components/modal/Modal";
import { useEffect, useState } from "react";
import { Button } from "../../../components/button/button";

import { BASE_URL} from "../../../services/api/config";
import { Card } from "react-bootstrap";
import { DynamicSearch } from "../../../components/DynamicForm/DynamicSearch/DynamicSearch";
import { FormService } from "../../../services/api/formService";
function Docentes() {

  const urlDocentes = `docente`;
  const navigate = useNavigate();
  const [modalOpen,isModalOpen]=useState(false)
  const [titulo,isTitulo]=useState('')
  const [urlDownload,isUrlDowload]=useState('')
  const [FechaInicio,setFechaInicio]=useState(null)
  const [FechaFin, setFechaFin]=useState(null)
  const [camposForm,setCamposForm]=useState();
  const[docente, setDocente]=useState([])
  const[endpoint,setEndpoint]=useState(urlDocentes);
  const api=new FormService();

  const api2 = new LocalStorage();
  const IdUsuario = api2.getAsJsonItem("usuario")?.usuario?.IdUsuario || 'localstorage_empty'

  useEffect(()=>{

   const fetchData=async()=>{
      consultarCampos();
    }
    fetchData()

  },[])

  const onNavigate = (docente) => {
    console.log(docente)

    let titulo="Exportar el certificado de horas de: "+docente.Nombre
    isTitulo(titulo)
    isModalOpen(true)
    setDocente(docente)
    const url = `${BASE_URL}/docente/horasDocentesH?NumeroDocumento=${docente.NumeroDocumento}&IdUsuario=${IdUsuario}`
    isUrlDowload(url)
    // const url = `${BASE_URL}/docente/certificadoHoras?IdDocente=${docente.IdDocente}&FechaInicio=`
  }

  const input=(e,fecha)=>{
    if(fecha==1){
        setFechaInicio(e)
    }
    if(fecha==2) {
        setFechaFin(e)
        if (FechaInicio) {
            const url = `${BASE_URL}/docente/certificadoHoras?IdDocente=${docente.IdDocente}&FechaInicio=${FechaInicio}&FechaFin=${e}`
            isUrlDowload(url)
        }
    }
  } 
  
  const consultarCampos=async()=>{
    const campos=await api.getAll("xcampos?id_formulario=60") 
    setCamposForm(campos)
  }


  const buscarDocentes=(e)=>{
    console.log(e)
    const nuevoEnpoint=`${urlDocentes}${e}`
    setEndpoint(nuevoEnpoint)
    console.log(endpoint)
  }

  return (
    <>
    <Card>
      <Card.Title>Docentes</Card.Title>
    </Card>
    {camposForm&&
      <DynamicSearch  onSearch={buscarDocentes} camposForm={camposForm} ></DynamicSearch>
    }
      <DynamicTable
        titleColumn={['N°','Nombre','Documento','Acción']}
        attributes={['Nombre','NumeroDocumento']}
        endPoint={endpoint}
        onDelete={false}
        onUpdate={false}
        labelOptionClick={`Certificado de horas`}
        handleOptionClick={onNavigate}
        classNameOptionIcon={`bi bi-view-list`}
      />

    <Modal 
        title={titulo}
        isOpen={modalOpen} 
        close={()=>isModalOpen(false)}       
    >
        {/* <Input
            label={'Fecha Inicial'}
            type={'date'}
            onChange={(e)=>input(e,1)}
        />
        <Input
            label={'Fecha Final'}
            type={'date'}
            onChange={(e)=>input(e,2)}
        /> */}
    {urlDownload &&
      <Button
      type={'send'}
      >
        <a 
        target={'_blank'}
        rel="noreferrer"
        style={{color:'white'}}
        href={urlDownload} 
        download
        ><i class="bi bi-file-earmark-text"></i>  Solicitar Certificado</a>
      </Button>
      }

    </Modal>
    </>
  )
}

export  { Docentes };