import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useFetchGet } from "../../../hooks/useFetchGet";
import { Modal } from "../../../components/modal/Modal";
import user from '../../../assets/img/avatar.jpg';
import  './ProgramasADictar.css'
import { useState } from "react";
import { Title } from "../../../shared/components/title/Title";
import { SubTitle } from "../../../shared/components/subtitle/SubTitle";
import { Button } from "../../../components/button/button";
import { FormService } from "../../../services/api/formService";
import { Card } from "../../../components/card/Card";

function AsignaturaEstudiantes () {
  
  const location = useLocation();
  const navigate = useNavigate()
  const api = new FormService()
  const { programa,asignatura,IdUsuario } = location.state;
  const [estudiantes,isLoadingEstudiantes,error] = useFetchGet(`docente/estudiantes?IdOferta=${programa.IdOferta}`);
  const [showModalDetails, setShowModalDetails] = useState(false);
  const [showModalAsistencias, setShowModalAsistencias] = useState(false);
  const [showModalNotas, setShowModalNotas] = useState(false);
  const [loadingEventAsistencia, setLoadingEventAsistencia] = useState(false);
  const [loadingEventoNota, setLoadingEventoNota] = useState(false);
  const [key, setKey] = useState('notas');
  const [estudianteSelected, setEstudianteSelected] = useState({});
  
  const [eventoAsignatura,loadingAsistencias,errorAsistencias] = useFetchGet(`docente/eventosAsignatura?IdUsuario=${IdUsuario}&IdMalla=${asignatura.IdMalla}`)
  const [eventoNotas,loadingNotas,errorNotas] = useFetchGet(`docente/notasAsignatura?IdOferta=${programa.IdOferta}&IdMalla=${asignatura.IdMalla}`)
  const handleModalOpenDetails = (estudiante) => {
    setEstudianteSelected(estudiante);
    setShowModalDetails(true);

  }

  
  const onSelectEventoAsistencia=(evento,index)=>{
    console.log({index})
    setLoadingEventAsistencia(true)
    api.getAll('docente/planillaAsistencia?IdEventoAula='+evento.IdEventoAula).then(res=>{
      setLoadingEventAsistencia(false)
      console.log({...evento,index})
      navigate('asistencias',{
        state:{
          evento:{...evento,index},asignatura,programa,IdUsuario,estudiantes:res}
        })
        
    }).catch(err=>{console.log("error getting event asis")})

  }
  const onSelectEventoNota=(evento,index)=>{
    setLoadingEventoNota(true)
    api.getAll('docente/planillaNota?IdNotaAsignatura='+evento.IdNotaAsignatura).then(res=>{
      setLoadingEventoNota(false)
      navigate('notas',{
        state:{
          evento:{...evento,index},asignatura,programa,IdUsuario,estudiantes:res}
        })
        
    }).catch(err=>{console.log("error getting event asis")})

  }

  return (
    <>
    <Card>
    <Title>{programa.Nombre}</Title>
    <p>{asignatura.Descripcion}</p>
    </Card>
    
    
    <div className="d-flex justify-content-center">
      <Button
      className='btn btn-outline-primary pt-2 m-2'
      hasButtonCss={false}
      type={"send"}
      label={"Notas"}
      onClick={()=>setShowModalNotas(true)}
      >Calificar Notas</Button>

    <Button
    hasButtonCss={false}
    type={"send"}
    className='btn btn-outline-primary pt-2 m-2'
    onClick={()=>setShowModalAsistencias(true)}
    label={"Asistencias"}
    >Llamar Asistencias</Button>

    </div>
  

<Modal
isOpen={showModalAsistencias}
hasCloseButton={true}
close={()=>setShowModalAsistencias(false)}
>
  <SubTitle>Asistencias </SubTitle>
  <div className="evento-container">
  {!!eventoAsignatura  && eventoAsignatura.map((evento,index)=>{
    return(
      <>
        <div onClick={()=>onSelectEventoAsistencia(evento,index)} className="evento-date-container">
        <div className="evento-date-container__evento-header">Fecha</div>
        <div className="evento-date-container__evento-asistencias">
        <div >{new Date(evento.FechaInicio).toLocaleDateString('es-co')}</div><br/>
        <div>{new Date(evento.FechaInicio).toLocaleTimeString('es-CO')} {new Date(evento.FechaFin).toLocaleTimeString('es-CO')}</div>
          {loadingEventAsistencia && <div className="spinner-border spinner-border-sm" role="status">
          <span className="visually-hidden">cargando...</span>
        </div>}
      </div>
      </div>
      </>
    )
  })}
  
</div>
</Modal>
{/* MODAL NOTAS */}
<Modal
isOpen={showModalNotas}
hasCloseButton={true}
close={()=>setShowModalNotas(false)}
>
  <SubTitle>Notas </SubTitle>
  <div className="notas-container">
  {!!eventoNotas  && eventoNotas.map((evento,index)=>{
    return(
      <>
      <div onClick={()=>onSelectEventoNota(evento,index+1)}  className="nota-item">
          <div><b>Nota {index+1} </b></div>
          {loadingEventoNota && <div className="spinner-border spinner-border-sm" role="status">
          <span className="visually-hidden">cargando...</span>
        </div>}
          
          <div className="porcentaje-nota"> {evento?.Porcentaje}% </div>
          </div>
          

      </>
    )
  })}
  </div>
  
</Modal>

<Modal
      isOpen={showModalDetails}
      hasCloseButton={true}
      close={()=>setShowModalDetails(false)}
      >
          <div className="content-student">

                      <img 
                          src={ estudianteSelected?.Foto || user }
                          alt={`foto estudiante ${estudianteSelected.PrimerNombres} ${estudianteSelected.PrimerApellidos}`}
                          className='applicant-list__img-user student'
                          />
                      <span>{estudianteSelected.PrimerNombres} {estudianteSelected.PrimerApellidos} {estudianteSelected.SegundoApellidos}</span>                    
                      <div>{estudianteSelected.email}</div>
                    </div>
                    <div className="d-flex  justify-content-around">

                    <div>Teléfono: {estudianteSelected.TelefonoContacto}</div>
                    <div>N° Documento: {estudianteSelected.NumeroDocumento}</div>
                    </div>
      </Modal>
    </>
  )
}

export  { AsignaturaEstudiantes };


