import React, { useState } from 'react';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { FormService } from '../../../../services/api/formService';
import { alerta } from '../../../../helpers/alertas';
import TablePaginate from '../../../../components/TablePaginate/TablePaginate';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchFields } from '../../../../shared/store/table/tableSlice';

export default function NuevoRegistro() {
  const api = new FormService();
  const [modalShow, setModalShow] = useState(false);
  const [update, setUpdate] = useState(false);
  const dispatch = useDispatch();
  // importar del store searchFields
  const { searchFields } = useSelector((state) => state.table);

  const initialFormData = {
    year: '',
    s1_estudiante_docente: '',
    s1_autoevaluacion: '',
    s1_heteroevaluacion: '',
    s1_coevaluacion: '',
    s2_estudiante_docente: '',
    s2_autoevaluacion: '',
    s2_heteroevaluacion: '',
    s2_coevaluacion: '',
    s3_estudiante_docente: '',
    s3_autoevaluacion: '',
    s3_heteroevaluacion: '',
    s3_coevaluacion: '',
    s4_estudiante_docente: '',
    s4_autoevaluacion: '',
    s4_heteroevaluacion: '',
    s4_coevaluacion: '',
    visible:false
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    // Maneja los valores según el tipo de campo
    const fieldValue = type === 'checkbox' ? checked : value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: fieldValue,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // verificar que la suma del s1 sea igual a 100
    if( parseInt(formData.s1_estudiante_docente) + parseInt(formData.s1_autoevaluacion) + parseInt(formData.s1_heteroevaluacion) + parseInt(formData.s1_coevaluacion) != 100) {
      alerta('La suma del semestre 1 debe ser igual a 100', 'warning');
      return
    }
    // verificar que la suma del s2 sea igual a 100
    if(parseInt(formData.s2_estudiante_docente) + parseInt(formData.s2_autoevaluacion) + parseInt(formData.s2_heteroevaluacion) + parseInt(formData.s2_coevaluacion) != 100) {
      alerta('La suma del semestre 2 debe ser igual a 100', 'warning');
      return
    }

    // verificar que la suma del s3 sea igual a 100
    if(parseInt(formData.s3_estudiante_docente) + parseInt(formData.s3_autoevaluacion) + parseInt(formData.s3_heteroevaluacion) + parseInt(formData.s3_coevaluacion) != 100) {
      alerta('La suma del semestre 3 debe ser igual a 100', 'warning');
      return;
    }

    // verificar que la suma del s4 sea igual a 100
    if(parseInt(formData.s4_estudiante_docente) + parseInt(formData.s4_autoevaluacion) + parseInt(formData.s4_heteroevaluacion) + parseInt(formData.s4_coevaluacion) != 100) {
      alerta('La suma del semestre 4 debe ser igual a 100', 'warning');
      return;
    }

      // actualizar registro
      if (update) {
        const response = await api.update('porcentajes-indicador', formData, formData.id);
        if (response.success) {
          alerta('Registro actualizado con éxito', 'success');
          setFormData(initialFormData);
          setModalShow(false);
          dispatch(setSearchFields({...searchFields,"i":Math.random()}))
        } else {
          alerta(response.message, 'warning');
        }
        return
      }
      // crear registro
      const response = await api.create('porcentajes-indicador', formData);
      if (response.success) {
        alerta('Registro guardado con éxito', 'success');
        setFormData(initialFormData); 
        setModalShow(false);
        dispatch(setSearchFields({...searchFields,"i":Math.random()}))
      } else {
        alerta(response.message, 'warning');
      }

  };

  return (
    <>
    {/* tablas */}
    <Card>
      <Row>
        <Col>
          <Card.Title>
            Indicadores Docente
          </Card.Title>
        </Col>
        <Col
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button variant="primary" onClick={() => {
            setModalShow(true)
            setUpdate(false)
            setFormData(initialFormData)
          }}>Agregar</Button>
        </Col>
      </Row>
      
    </Card>
    <TablePaginate
      apiUrl={`porcentajes-indicador`}
      tableHeaders={[
        {"title":"Año Parametrizado", "attribute":"year"},
      ]}
      rowActions={[
        ({rowData})=>{  
          return (
            <Button
              variant="primary"
              onClick={() =>{
                console.log(rowData)
                setFormData(rowData);
                setModalShow(true);
                setUpdate(true);
              }}
            >
              Editar
            </Button>
          )
        }
      ]}
    />
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="lg"
    >
      <Form onSubmit={handleSubmit}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Formulario de porcentajes
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        
            {/* Agregar los campos de entrada aquí */}
            <Row>
              <Col sm="6">                
                <Form.Group>
                  <Form.Label>Año </Form.Label>
                  <Form.Control
                    name="year"
                    type="number"
                    placeholder="Año"
                    value={formData.year}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col sm="6">
                <Form.Group>
                  <Form.Label>Visible en reportes
                  <Form.Check
                    type="checkbox"
                    size={"sm"}
                    name="visible"
                    checked={formData.visible}
                    value={formData.visible}
                    onChange={handleChange}
                  />
                  </Form.Label>
                </Form.Group>
              </Col>
            </Row>
          
          {/* primer semestre */}
          <Row>
            <Col sm="6">
            <h4 style={{textAlign: "center",marginTop:"15px"}}>Trimestre 1</h4>
          <Form.Group>
            <Form.Label>Estudiante - docente</Form.Label>
            <Form.Control
              name="s1_estudiante_docente"
              type="number"
              placeholder="Porcentaje"
              value={formData.s1_estudiante_docente}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Autoevaluación</Form.Label>
            <Form.Control
              name="s1_autoevaluacion"
              type="number"
              placeholder="Porcentaje"
              value={formData.s1_autoevaluacion}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Heteroevaluación</Form.Label>
            <Form.Control
              name="s1_heteroevaluacion"
              type="number"
              placeholder="Porcentaje"
              value={formData.s1_heteroevaluacion}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Co-evaluación</Form.Label>
            <Form.Control
              name="s1_coevaluacion"
              type="number"
              placeholder="Porcentaje"
              value={formData.s1_coevaluacion}
              onChange={handleChange}
            />
          </Form.Group>
            </Col>
          {/* segundo semestre */}
          <Col sm="6">
          <h4 style={{textAlign: "center",marginTop:"15px"}}>Trimestre 2</h4>
          <Form.Group>
            <Form.Label>Estudiante - docente</Form.Label>
            <Form.Control
              name="s2_estudiante_docente"
              type="number"
              placeholder="Porcentaje"
              value={formData.s2_estudiante_docente}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Autoevaluación</Form.Label>
            <Form.Control
              name="s2_autoevaluacion"
              type="number"
              placeholder="Porcentaje"
              value={formData.s2_autoevaluacion}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Heteroevaluación</Form.Label>
            <Form.Control
              name="s2_heteroevaluacion"
              type="number"
              placeholder="Porcentaje"
              value={formData.s2_heteroevaluacion}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Co-evaluación</Form.Label>
            <Form.Control
              name="s2_coevaluacion"
              type="number"
              placeholder="Porcentaje"
              value={formData.s2_coevaluacion}
              onChange={handleChange}
            />
          </Form.Group>
           </Col>
           <Col>
           <h4 style={{textAlign: "center",marginTop:"15px"}}>Trimestre 3</h4>
           <Form.Group>
            <Form.Label>Estudiante - docente</Form.Label>
            <Form.Control
              name="s3_estudiante_docente"
              type="number"
              placeholder="Porcentaje"
              value={formData.s3_estudiante_docente}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Autoevaluación</Form.Label>
            <Form.Control
              name="s3_autoevaluacion"
              type="number"
              placeholder="Porcentaje"
              value={formData.s3_autoevaluacion}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Heteroevaluación</Form.Label>
            <Form.Control
              name="s3_heteroevaluacion"
              type="number"
              placeholder="Porcentaje"
              value={formData.s3_heteroevaluacion}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Co-evaluación</Form.Label>
            <Form.Control
              name="s3_coevaluacion"
              type="number"
              placeholder="Porcentaje"
              value={formData.s3_coevaluacion}
              onChange={handleChange}
            />
          </Form.Group>
          </Col>
          <Col>
          <h4 style={{textAlign: "center",marginTop:"15px"}}>Trimestre 4</h4>
          <Form.Group>
            <Form.Label>Estudiante - docente</Form.Label>
            <Form.Control
              name="s4_estudiante_docente"
              type="number"
              placeholder="Porcentaje"
              value={formData.s4_estudiante_docente}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Autoevaluación</Form.Label>
            <Form.Control
              name="s4_autoevaluacion"
              type="number"
              placeholder="Porcentaje"
              value={formData.s4_autoevaluacion}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Heteroevaluación</Form.Label>
            <Form.Control
              name="s4_heteroevaluacion"
              type="number"
              placeholder="Porcentaje"
              value={formData.s4_heteroevaluacion}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Co-evaluación</Form.Label>
            <Form.Control
              name="s4_coevaluacion"
              type="number"
              placeholder="Porcentaje"
              value={formData.s4_coevaluacion}
              onChange={handleChange}
            />
          </Form.Group>
          </Col>
          </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setModalShow(false)}>
          Cerrar
        </Button>
        <Button variant="primary" type="submit">
          {update ? 'Actualizar' : 'Guardar'}
        </Button>
      </Modal.Footer>
      </Form>
    </Modal>
    </>
  );
}

// }
