import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useFetchGet } from "../../hooks/useFetchGet"
import { Card, Table } from "react-bootstrap"
import { Button } from "../../components/button/button"
import { LocalStorage } from "../../services"

function Evaluaciones () {

const storage= new LocalStorage()

  const IdUsuario = storage.getAsJsonItem('usuario').usuario.IdUsuario
  const [evaluaciones,isLoading,error] = useFetchGet(`gestionInstrumentos/evaluacionesParaEgresados?IdUsuario=${IdUsuario}`)

  const [isOpenModalEvaluacion,setIsOpenModalEvaluacion] = useState(false)
  const [ itemSelected,setItemSelected] = useState({})
  const [isOpenModalAsignarEvaluacion,setIsOpenModalAsignarEvaluacion] = useState(false)

  const handleOptionClick = (item) => {
    setItemSelected(item)
    setIsOpenModalEvaluacion(true)
  }

  return(
    <>
    <Card>
      <Card.Title>Evaluaciones</Card.Title>
    </Card>
    <Card>

    <Table striped bordered hover>
  <thead>
    <tr>
      <th>N°</th>
      <th>Instrumento</th>
      <th>Promgrama - Actividada</th>
      <th>Plazo (A/M/D)</th>
      <th>Realizar evaluación</th>
    </tr>
  </thead>
  <tbody>
    {!!evaluaciones && evaluaciones.map((item,index)=>{
      return(
        <tr key={index} onClick={()=>handleOptionClick(item)}>
          <td>{index+1}</td>
          <td>{item.nombre}</td>
          <td>{item.NombrePlanEstudio} - {item.codigo_grupo}</td>
          <td> {item.FechaActivo} / {item.FechaInactivo}</td>
          <td>
          <Button
            hasButtonCss={false}
            className="btn btn-outline-primary"
            isLink={true}
            to={`${item.id}`}
            state={{evaluacion:item}}
          >Evaluar</Button></td>
        </tr>
      )
    }
    
    )}
  </tbody>
</Table>
    </Card>
    </>
  )
}

export { Evaluaciones }