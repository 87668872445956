import { DynamicFormContainer } from "../../../components/DynamicForm/DynamicFormContainer"
import { BASE_URL } from "../../../services/api/config"



const Entidades=()=>{

    const urlExportar = `${BASE_URL}/exportarEntidades`

    return(
        <>
            <DynamicFormContainer idForm="94" exportar={urlExportar}/>
        </>
    )
}

export {Entidades}