import React from 'react';

import { Button } from '../../components/button/button';
import { Arrow } from '../../components/arrow/Arrow';
import { Card } from '../../components/card/Card';
import { Input } from '../../components/input/input';
import { Select } from '../../components/select/Select';

import 'bootstrap/dist/css/bootstrap.min.css';
import './Login.css';
import { Modal } from '../../components/modal/Modal';
import { BASE_URL_MOODLE } from '../../services/api/config';

const Login = ({
    handleSubmit,
    onHandleChangeCredentials,
    formData,
    emailRecover,
    onRestorePassword,
    restorePassword,
    /* roles, */
    isLoading
}) => {

    const [isOpenRecover, setIsOpenRecover] = React.useState(false);
    const { 
        IdRolUsuario,
        email,
        password,
        Dimension
    } = formData

    const recoverPassword = () => {
        setIsOpenRecover(!isOpenRecover)
    }

    return (
        <>
            <main className='login__main'>
                <Card className='m-0'>
                    <h4 className='text-center mt-4'>Iniciar Sesión</h4>
                    <form 
                        onSubmit={ handleSubmit }
                        className='login__form'
                    >
                        <Input 
                            label='Correo'
                            type='email'
                            name='email'
                            placeholder='Ingresa tu correo'
                            value={ email || '' }
                            onChange={ onHandleChangeCredentials }
                        />
                        <Input 
                            label='Contraseña'
                            type='password'
                            name='password'
                            placeholder='Ingresa tu contraseña'
                            value={ password || '' }
                            onChange={ onHandleChangeCredentials }
                        />
                        {/* <Select
                            id="Dimension"
                            name="Dimension"
                            onChange={ onHandleChangeCredentials }
                            label="Tipo Ingreso"
                            value={ Dimension }
                            optionValue="IdDimension"
                            optionName="Nombre"
                            required={true}
                            options={[
                                {"IdDimension": true, "Nombre": "Institución de Educación Superior"},
                                {"IdDimension": false, "Nombre": "Educación Especializada - Continuada"}
                            ]}
                        /> */}
                        <Button 
                            type='send'
                            className='w-100 ms-0 mt-4 mb-0'
                            loading={ isLoading }
                        >
                            Ingresar
                        </Button>
                    </form>
                    <Button 
                        className='login__reset-password mt-4'
                        onClick={ recoverPassword }
                    >
                        Recuperar Contraseña
                    </Button>

                </Card>
            </main>
            <Modal
                isOpen={ isOpenRecover }
                close={ recoverPassword }
            >
                <h5 className='mb-4'>
                Escriba su Correo Para Recuperar la Contraseña
                </h5>
                <Input
                    placeholder='Escriba su correo'
                    value={ emailRecover }
                    onChange={ onRestorePassword }
                />
                <section className='login__change-password'>
                    <Button 
                        type='send'
                        className='mt-2'
                        onClick={ restorePassword }
                        loading={ isLoading }
                    >
                        Recuperar Contraseña
                    </Button>
                </section>
            </Modal>
        {/* </div> */}
        </>
    );
};

export { Login }