import React, { useState } from 'react'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import { SelectDataList } from '../../components/SelectDataList/SelectDataList'
import TablePaginate from '../../components/TablePaginate/TablePaginate'
import { useFetchGet } from '../../hooks/useFetchGet'
import { FormService } from '../../services/api/formService'
import { alerta } from '../../helpers/alertas'
import { useDispatch, useSelector } from 'react-redux'
import { setSearchFields } from '../../shared/store/table/tableSlice'
import { useNavigate } from 'react-router-dom'

import {MdDelete} from 'react-icons/md'
import {AiFillEye} from 'react-icons/ai'
import Swal from 'sweetalert2'

export default function GenerarDocumentos() {
  const [modal, setModal] = useState(false)
  const [IdOferta, setIdOferta] = useState(null)
  const [IdDocumento, setIdDocumento] = useState(null)
  const [IdEstudiante, setIdEstudiante] = useState(null)
  const [documentos]=useFetchGet("documentos?Todos=0")
  const api=new FormService();
  const dispatch=useDispatch();
  const navigate=useNavigate();
  const searchFields=useSelector(state=>state.table.searchFields)

  // generar documento
  const GenerarDocumento = async(e) => {
    e.preventDefault()
    const data={
      "IdOferta":IdOferta,
      "IdDocumento":IdDocumento,
      "IdEstudiante":IdEstudiante
    }
    const respuesta=await api.create("documentosGenerados",data)
    if(respuesta.success){
      alerta(respuesta.message)
      setModal(false)
      dispatch(setSearchFields({...searchFields,"i":Math.random()}));
    }else{
      alerta(respuesta.message)
    }
  }
  // funcion para eliminar
  const eliminar = async (rowData) => {
    // alertar para eliminar
    const confirm = await Swal.fire({
      title: '¿Estás seguro?',
      text: 'No podrás revertir esto.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar'
    });
  
    if (confirm.isConfirmed) {
      console.log('Eliminando ...');
      // eliminar
      const data={
        Estado:0
      }
      const respuesta=await api.delete("documentosGenerados",data,rowData.IdDocumentoGenerado)
      if(respuesta.success){
        alerta(respuesta.message)
        dispatch(setSearchFields({...searchFields,"i":Math.random()}));
      }else{
        alerta(respuesta.message)
      }
    }
  };
  
  return (
    <>
      <Card>
        <Row>
          <Col>
            <Card.Title>Generar Documentos</Card.Title>
          </Col>
          <Col
            style={{
              textAlign: 'right'
            }}
          >
            <Button variant="primary"
              onClick={() => setModal(true)}
            >
              Generar
            </Button>
          </Col>
        </Row>
      </Card>
      {/* tabla */}
      <TablePaginate
        apiUrl={'documentosGenerados'}
        tableHeaders={[
          {
            title: 'Curso',
            attribute: 'Curso'
          },
          {
            title: 'Documento',
            attribute: 'NombreDocumento'
          },
          {
            title: 'Estudiante',
            attribute: 'Estudiante'
          }
        ]}
         rowActions={[
          ({rowData})=>{
            return(
              <div
                style={{
                  display:'flex',
                  flexDirection:'row',
                }}
              >
                <Button variant="danger" onClick={()=>eliminar(rowData)}>
                  {/* icono de eliminar */}
                  <MdDelete/>  
                </Button>
                <Button variant="primary" onClick={()=>navigate(
                  `${rowData.IdDocumentoGenerado}`,{state:{rowData}}
                )}>
                  <AiFillEye/>
                </Button>
              </div>
            )
          }
         ]}
      />

      {/* modal para generar los documentos */}
      <Modal show={modal} onHide={() => setModal(false)}>
        <Form
          onSubmit={GenerarDocumento}
        >

        <Modal.Header closeButton>
          <Modal.Title>Generar Documento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form.Group>
            <Form.Label>Documento</Form.Label>
            <Form.Control
              as="select"
              placeholder="Documento"
              onChange={(e)=>setIdDocumento(e.target.value)}
            >
              <option value="">Seleccione</option>
                {documentos.map((item, index) => (
                  <option key={index} value={item.IdDocumento}>{item.Nombre}</option>
                ))}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Oferta</Form.Label>
            <SelectDataList 
              endPoinLastUrl={'documentosGenerados/ofertasDisponibles'}
              optionValue={'IdOferta'}
              optionName={'Curso'}

              onChangeHandler={(e)=>setIdOferta(e)}
            />         
          </Form.Group>

          {/* verificar si el docmento seleccionado tiene i en en campo Fraccionado */}
          {IdDocumento && documentos.find((item)=>item.IdDocumento==IdDocumento)?.Fraccionado==1 &&
            <Form.Group>
              <Form.Label>Estudiante</Form.Label>
              <SelectDataList
                endPoinLastUrl={`documentosGenerados/estudiantesOferta?IdOferta=${IdOferta}`}
                optionValue={'IdEstudiante'}
                optionName='NombreCompleto'
                onChangeHandler={(e)=>setIdEstudiante(e)}
              />         
            </Form.Group>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModal(false)}>
            Cerrar
          </Button>
          <Button variant="primary" type="submit">
            Generar
          </Button>
        </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}
