import React, { useState } from 'react'
import { Button, Card, Form, Modal } from 'react-bootstrap'
import { DynamicTable } from '../../components/DynamicForm/dynamicTable/DynamicTable'
import { Input } from '../../components/input/input';
import { Select } from '../../components/select/Select';
import { alerta } from '../../helpers/alertas';
import { FormService } from '../../services/api/formService'

export default function ActividadesAcademicas() {

    const api=new FormService();
    const [modalActualizar,setModalActualizar]=useState(false)
    const [curso,setCurso]=useState({})
    const [endPoint,setEndPoint]=useState("curso")
    const [datos,setDatos]=useState({})

    const handleChange=(value,name)=>{
        setDatos({
            ...datos,
            [name]:value
        })
    }

    const actualizarCurso=async()=>{
        const data={
            ...datos
        }
        const respuesta=await api.update("curso",data,curso.IdCurso);
        if(respuesta){
            setModalActualizar(false)
            setEndPoint(`curso?i=${Math.random()}`)

        }else{
            alerta("No se pudo actualizar el curso","error")
        }
    }

   
  return (
    <>
        <Card>
            <Card.Title>Actividades Academicas</Card.Title>
        </Card>

        <DynamicTable
            endPoint={endPoint}
            attributes={["Nombre","CodigoPieso"]}
            titleColumn={["Nombre","Código Pieso","Acciones"]}
            onDelete={false}
            onUpdate={false}
            labelOptionClick="Actualizar"
            handleOptionClick={(e)=>{
                setModalActualizar(true)
                setDatos({
                    CodigoPieso:e.CodigoPieso,
                    IdSaaDireccion:e.IdSaaDireccion,
                    IdSaaGrupo:e.IdSaaGrupo,
                    IdSaaTipoCapacitacion:e.IdSaaTipoCapacitacion
                })
                setCurso(e)
            }}
            indice={false}
        />

        {/* Modal para actualizar */}
        <Modal
            show={modalActualizar}
            onHide={(e)=>setModalActualizar(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>Actualizar Actividad</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {/* CodigoPieso, IdSaaDireccion,IdSaaGrupo, IdSaaTipoCapacitacion */}
                    <Input
                        label="Codigo Pieso"
                        type="text"
                        name="CodigoPieso"  
                        value={datos.CodigoPieso}
                        onChange={handleChange}
                    />
                    <Select
                        label="Direccion"
                        name="IdSaaDireccion"
                        value={datos.IdSaaDireccion}
                        onChange={handleChange}
                        urlOptionsService="saaDirecciones"
                        optionName={"Nombre"}
                        optionValue={"IdSaaDireccion"}
                    />
                    <Select
                        label="Grupo"
                        name="IdSaaGrupo"
                        value={datos.IdSaaGrupo}
                        onChange={handleChange}
                        urlOptionsService={`saaGrupos?IdSaaDireccion=${datos.IdSaaDireccion}`}
                        optionName={"Nombre"}
                        optionValue={"IdSaaGrupo"}
                    />
                    <Select
                        label="Tipo de Capacitacion"
                        name="IdSaaTipoCapacitacion"
                        value={datos.IdSaaTipoCapacitacion}
                        onChange={handleChange}
                        urlOptionsService="saaTiposCapacitaciones"
                        optionName={"Nombre"}
                        optionValue={"IdSaaTipoCapacitacion"}
                    />

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={()=>setModalActualizar(false)}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={()=>actualizarCurso(false)}>
                    Actualizar
                </Button>
            </Modal.Footer>
        </Modal>

    </>
  )
}
