import React, { useState, useEffect, useImperativeHandle } from 'react';
import { Table, Button, Spinner, Alert } from 'react-bootstrap';
import { FormService } from '../../services/api/formService';
import { AiOutlineFrown } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { setTableData, incrementPage, decrementPage, setTotalPages } from '../../shared/store/table/tableSlice';
import { BASE_URL } from '../../services/api/config';

const TablePaginate = ({ apiUrl, tableHeaders, rowActions,}) => {
  const api = new FormService();
  const dispatch = useDispatch();
  const currentPage = useSelector(state => state.table.currentPage);
  const totalPages = useSelector(state => state.table.totalPages);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const tablaData = useSelector(state => state.table.tableData);
  const searchFields = useSelector(state => state.table.searchFields);

  useEffect(() => {
    fetchData();
  }, [apiUrl, currentPage, searchFields]);

  const generateQueryString = () => {
    const queryString = Object.entries(searchFields)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');

    return queryString;
  };


  const fetchData = async () => {
    setIsLoading(true);
    setIsError(false);
    const queryString = generateQueryString();
    try {
      const response = await api.getAll(`${apiUrl}?page=${currentPage}&${queryString}`);
      if (response.data) {
        setIsLoading(false);
        dispatch(setTableData(response.data));
        dispatch(setTotalPages(response.last_page));
      } else {
        console.log(response);
        setIsLoading(false);
      }

    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setIsError(true);
    }
  };

  const handlePreviousPage = () => {
    dispatch(decrementPage());
  };

  const handleNextPage = () => {
    dispatch(incrementPage());
  };


  
  return (
    <div
      style={{
        padding: '10px'
      }}
    >
      <Table striped bordered>
        <thead>
          <tr>
            {tableHeaders.map(header => (
              <th key={header.title}>{header.title}</th>
            ))}
            {rowActions && <th>Acciones</th>}
          </tr>
        </thead>
        <tbody>
          {tablaData.map(result => (
            <tr key={result.id}>
              {tableHeaders.map(header => (
                <td key={header.attribute}>{result[header.attribute]}</td>
              ))}             
              {rowActions &&
                <td>
                  {rowActions.map((Action, index) => (
                    <Action key={index} rowData={result} />
                  ))}
                  </td> 
              }              
            </tr>
          ))}
        </tbody>
      </Table>
      {/* Se muestra si se están cargando los datos */}
      {isLoading && (
        <div className="text-center mt-3">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Cargadno...</span>
          </Spinner>
        </div> 
      )}
      {/* Se muestra si hay un error */}
      {isError && (
        <Alert variant="ligth" 
          style={{
            textAlign: 'center',
          }}
        >
          <AiOutlineFrown size={100} className="mr-2" /><br></br>
          <span>No se pudieron cargar los datos. Por favor, inténtelo nuevamente.</span>
        </Alert>
      )}

      {!isError && !isLoading &&
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '1rem',
            gap: '1rem'
          }}
        >
          <Button onClick={handlePreviousPage} disabled={currentPage === 1} variant="primary">
            {`<`}
          </Button>
          <span>
            Página {currentPage} de {totalPages}
          </span>
          <Button onClick={handleNextPage} disabled={currentPage === totalPages} variant="primary">
            {`>`}
          </Button>
        </div>
      }
    </div>
  );
};

export default TablePaginate;
