import { Route, Routes } from "react-router-dom"
import { DynamicFormContainer } from "../../components/DynamicForm/DynamicFormContainer"
import { SeguimientoPIC } from "./seguimientoPIC/SeguimientoPIC"
import { ParametrizacionPIC } from "./parametrizacionPIC/ParametrizacionPIC"
import { FuncionariosPIC } from "./funcionariosPIC/FuncionariosPIC"
import { DirectivosPIC } from "./directivosPIC/DirectivosPIC"
import { Grupos } from "./grupos/Grupos"
import { tipoAreasPIC } from "./tipoAreasPIC/tipoAreasPIC"

const PIC=()=>{

    return(
        <Routes>
            <Route path="tipos_conocimientos" element={<DynamicFormContainer idForm={146}/>}/>
            <Route path="seguimiento_PIC" element={<SeguimientoPIC/>}/>
            <Route path="parametrizacion" element={<ParametrizacionPIC/>}/>
            <Route path="parametrizacion/:IdParametrizacionPic/funcionarios" element={<FuncionariosPIC/>}/>
            <Route path="tipo_areas" element={<tipoAreasPIC/>}/>
            <Route path="directivos" element={<DirectivosPIC/>}/>
            <Route path="grupos" element={<Grupos/>}/>
        </Routes>
    )

}

export {PIC}