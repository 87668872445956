import { useState } from 'react'
import { Button } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { Card } from '../../../components/card/Card'
import { AddButton } from '../../../components/componentsForm/addButton/addButton'
import { DynamicForm } from '../../../components/DynamicForm/DynamicForm'
import { DynamicTable } from '../../../components/DynamicForm/dynamicTable/DynamicTable'
import { Modal } from '../../../components/modal/Modal'
import { SubTitle } from '../../../shared/components/subtitle/SubTitle'
import { Title } from '../../../shared/components/title/Title'
import { TopHeadTable } from '../../../shared/components/topHeadTable/TopHeadTable'

function MomentosEvaluativos(
    {editable}
    ) {
    const location = useLocation()
    const navigate = useNavigate()

    const[openModal, isOpenModal]=useState(false)
    const[reload,isReload]=useState(false)

    console.log(location)

    const urlAsignaturas = `notasAsignatura?IdOferta=${location.state.programa.IdOferta}&IdMalla=${location.state.asignatura.IdMalla}`

    const close=()=>{
        isOpenModal(false)
    }

    const actualizar=()=>{
        isOpenModal(false)
        isReload(true)
        // isReload(false)

    }
    return (
        <>
            {/* <AddButton></AddButton> */}
            <Card>
                <Title>Momentos evaluativos</Title>
                <p>{location.state.programa.Nombre}</p>
            </Card>
            {editable &&
                <Button onClick={()=>isOpenModal(true)}>Crear</Button>
            }
            
            <DynamicTable
                // topHeadTitle={location.state.programa.Nombre}
                titleColumn={['N°', 'Nombre', 'Porcentaje', 'Acción']}
                attributes={['Nombre', 'Porcentaje']}
                endPoint={urlAsignaturas}
                reaload={reload}
                onDelete={editable?true:false}
                onUpdate={editable?true:false}
                classNameOptionIcon={`bi bi-view-list`}
            ></DynamicTable>
            <Modal
                isOpen={openModal}
                close={close}
                title={'Crear momento evaluativo'}
            >
               <DynamicForm 
                    onClickCancelBtn={()=>isOpenModal(false)} 
                    accionMysql={actualizar}
                    isCreate={true} 
                    justDynamicForm={{flag:true,idForm:"62"}}
                    endPointSubmit="notasAsignatura"
                    extraData={{IdOFerta:location.state.programa.IdOferta,IdMalla:location.state.asignatura.IdMalla}}
                />
            </Modal>
        </>
    )
}

export { MomentosEvaluativos }
