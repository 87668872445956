import React, { useState } from 'react'
import { Button, Card, Col, Modal, Row } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../services/api/config';
import { InputFile } from '../../components/inputFile/inputFile';
import { FormService } from '../../services/api/formService';
import { alerta } from '../../helpers/alertas';
import DowloadFileText from '../../components/DowloadFileText/DowloadFileText'

export default function PlanTrabajo() {
    const navigate = useNavigate();
    const api=new FormService();
    const location = useLocation();
    const curso=location.state.curso;
    const asigantura=location.state.asignatura;
    const IdUsuario=location.state.IdUsuario;
    // const {curso,asigantura,IdUsuario}=useLocation().state
    const urlArchivo=`${BASE_URL}/descargar/planAcademico`
    const urlArchivoSyllabus=`${BASE_URL}/exportar/formatosIES/syllabus?IdAsignatura=${asigantura?.IdAsignatura}&IdOferta=${curso.IdOferta}&IdUsuario=${IdUsuario}`
    const urlArchivoMicrocurriculo=`${BASE_URL}/exportar/formatosIES/formatoMicrocurriculo?IdAsignatura=${asigantura?.IdAsignatura}&IdOferta=${curso.IdOferta}&IdUsuario=${IdUsuario}`
    const urlArchivoPlandeTrabajo=`${BASE_URL}/descargar/plandeTrabajoDocente`
    const urlArchivoGuia=`${BASE_URL}/descargar/planAcademicoGuia`
    const [modal,setModal]=useState();
    const [modalPlanTrabajo, setModalPlanTrabajo]=useState();
    // const [asigantura,setAsignatura]=useState();
    const [documento,setDocumento]=useState(null)
    const [documentoPlanTrabajo, setDocumentoPlanTrabajo]= useState(null)
    const [documentoSyllabus, setDocumentoSyllabus]= useState(null)
    const [documentoMicrocurriculo, setDocumentoMicrocurriculo]= useState(null)
    const nameFile = "FormatoSyllabus.pdf"
    const nameFileM = "Microcurriculo.pdf"


    const planeacionAcademica = (asignatura) => {
        navigate(`${asignatura.IdMalla}/plan_academico`, {
            state: {
                programa: curso,
                asignatura,
                IdUsuario: IdUsuario,
            },
        }) 
    }
    const guardarPlan = async()=>{
        const data={
            "Documento":documento,
            "IdUsuario":asigantura.IdUsuario,
            "IdMalla":asigantura.IdMalla,
            "IdUnidadAsignatura":asigantura.IdUnidadAsignatura,
            "IdOferta":curso.IdOferta
        }
        const respuesta=await api.createWithFormData("docente/guardarPlanAsignatura",data)

        if(respuesta.respuesta=="exito"){
            alerta(respuesta.mensaje)
            setModal()
        }else{
            alerta("Fallo desconocido")
        }
    }
    
    const guardarPlanTrabajo = async()=>{
        const data={
            "DocumentoPlanTrabajo": documentoPlanTrabajo,
            "DocumentoSyllabus": documentoSyllabus,
            "DocumentoMicrocurriculo": documentoMicrocurriculo,
            "IdUsuario":asigantura.IdUsuario,
            "IdMalla":asigantura.IdMalla,
            "IdUnidadAsignatura":asigantura.IdUnidadAsignatura,
            "IdOferta":curso.IdOferta
        }
        const respuesta=await api.createWithFormData("docente/guardarPlandeTrabajo", data)

        if(respuesta.respuesta=="exito"){
            alerta(respuesta.mensaje)
            setModal()
        }else{
            alerta("Fallo desconocido")
        }
    }

  return (
    <>
        <Card>
            <Card.Title>Plan de Trabajo</Card.Title>
        </Card>

        {/* menu de tres botones */}
        <Row>
            {curso.IdDimension==1&&
                <>
                    <Col>
                        <Card
                            onClick={() => navigate('plan_academico_syllabus', {state: {item: asigantura}} )}
                            style={{cursor: 'pointer'}}
                        >
                            <h6>Syllabus</h6>
                        </Card>
                    </Col>
                    <Col>
                        <Card
                            onClick={() => navigate('plan_academico_microcurriculo', {state: {item: asigantura}} )}
                            style={{cursor: 'pointer'}}
                        >
                            <h6>Microcurrículo</h6>
                        </Card>
                    </Col>
                </>
            }
            {curso.IdDimension!=1&&
            <Col>
                <Card
                    onClick={() => navigate('plan_asignatura', {state: {item: asigantura}} )}
                    style={{cursor: 'pointer'}}
                >
                    <h6>Asignatura</h6>
                </Card>
            </Col>
            }
            {/* {curso.IdDimension!=1&&
            <Col>
                <Card 
                    onClick={()=>{
                        setModal(true)
                    }}
                >
                    <h6>Plan de Trabajo</h6>
                </Card>
            </Col>
            } */}
        </Row>

    {/* modales */}
        <Modal 
            show={modal}
            onHide={()=>setModal()}
        >
            <Modal.Header closeButton>
                <Modal.Title>Plan de Asignatura</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>1. <a target="_blank" href={urlArchivo}>Descargar la plantilla </a>del plan de Asignatura</p>
                <p>2. <a target="_blank" href={urlArchivoGuia}>Descargar Guia </a>de plan de Asignatura</p>
                <p>3. Diligenciar los datos requeridos</p>
                <p>4. Adjuntar el documento plan de asignatura diligenciado a la plataforma mediante la siguiente opción.</p>
                <InputFile 
                    label={`Adjuntar documento`}
                    onChange={(e)=>setDocumento(e[0])}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant='secondary'
                    onClick={()=>setModal()}
                >Cancelar</Button>
                <Button
                    onClick={()=>guardarPlan()}
                >
                    Aceptar
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal 
            show={modalPlanTrabajo}
            onHide={()=>setModalPlanTrabajo()}
        >
            <Modal.Header closeButton>
                <Modal.Title>Plan de Trabajo Docente</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {console.log(asigantura)}
                {/* <p>1. <a target="_blank" href={urlArchivoPlandeTrabajo}>Descargar la plantilla </a>del Plan de Trabajo Docente</p> */}
                <p>1. 
                    <DowloadFileText
                        nameFile={nameFile}
                        name={" Descargar formato Syllabus"}
                        endPoint={urlArchivoSyllabus}
                    />
                </p>
                <p>2. 
                    <DowloadFileText
                        nameFile={nameFileM}
                        name={" Descargar formato Microcurriculo"}
                        endPoint={urlArchivoMicrocurriculo}
                    />
                </p>
                <p>3. Diligenciar los datos requeridos</p>

                <p>4. Adjuntar el documento formato syllabus diligenciado a la plataforma mediante la siguiente opción.</p>
                <InputFile 
                    label={`Adjuntar documento`}
                    onChange={(e)=>setDocumentoSyllabus(e[0])}
                />
                
                <br />
                <p>5. Adjuntar el documento formato microcurrículo diligenciado a la plataforma mediante la siguiente opción.</p>
                <InputFile 
                    label={`Adjuntar documento`}
                    onChange={(e)=>setDocumentoMicrocurriculo(e[0])}
                />
                <br />
                <p>6. Adjuntar Otro documento a la plataforma mediante la siguiente opción.</p>
                <InputFile 
                    label={`Adjuntar documento`}
                    onChange={(e)=>setDocumentoPlanTrabajo(e[0])}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant='secondary'
                    onClick={()=>setModal()}
                >Cancelar</Button>
                <Button
                    onClick={()=>guardarPlanTrabajo()}
                >
                    Aceptar
                </Button>
            </Modal.Footer>
        </Modal>
    </>
  )
}