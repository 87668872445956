import { Route, Routes } from "react-router-dom"
import { ConsultaInvestigacionAccidentes } from "./ConsultaInvestigacionAccidentes"
import { ConsultaProgramaSeguridadAccidentes } from "./ConsultaProgramaSeguridadAccidentes"
import { NotificarSeguridadAccidentes } from "./NotificarSeguridadAccidentes"
import { DynamicFormContainer } from "../../../components/DynamicForm/DynamicFormContainer"

const InvestigacionAccidentes = () => {
    return (
      <Routes>
          <Route path="seguridad_aerea" element={<DynamicFormContainer onActions={false} idForm="103"/>}  />
          <Route path="consulta" element={<DynamicFormContainer onActions={false}  idForm="113"/>} />  
          {/* <Route path="consulta/programaseguridad/accidentes_notificacion_seguridad" element={<NotificarSeguridadAccidentes/>} /> */}
      </Routes>
    )
  }
  
  
  export  {InvestigacionAccidentes}


