import React from 'react'
import './noticiaCard.css'

import Logo from '../../../../assets/dron.png'

const NoticiaCard = () => {
  return (
      <>
    <div className='CardContainer'>
        <div className='d-flex justify-content-center cardContainer_1'>
            <div className='d-flex flex-column align-items-center'>
                <p className='titulo_noticia'>OPERACIÓN DE SISTEMAS DE AERONAVES NO TRIPULADAS – UAS</p>
                <img 
                    src={Logo}
                    className='noticiaCard__img'
                    alt="Alt"
                />
                <img className='img-fluid img_noticia' src="./dron" alt="" /><br />
                <div className='contenedor_texto_noticia'>
                    <p className='texto_noticia ' style={{textAlign: 'justify'}}>En la actualidad se ha incrementado el desarrollo de los sistemas de aeronaves no tripuladas (UAS) y la gestión del tránsito de sistemas de aeronaves no tripuladas (UTM) por lo que las autoridades aeronáuticas de los gobiernos latinoamericanos junto con los explotadores se han visto obligados en aunar esfuerzos humanos, financieros, administrativos y técnicos para integrar retos competitivos en aspecto de vigilancia autoridad, control y seguimiento teniendo como desafío en un espacio aéreo finito que cumpla con las condiciones de seguridad y la próxima evolución que van a experimentar las aeronaves, tanto tripuladas como no tripuladas.
                    Teniendo en cuenta lo anterior, Colombia se ha adaptado a esta innovación tecnológica por medio de la Resolución No. 4201 del 28 de diciembre de 2018 de la Aerocivil en la operación de drones (UAS) y se rige por el Apéndice 13 del RAC 91 “operación de sistemas de aeronaves no tripuladas – UAS”.
                    
                    <span className='mt-3 d-block '><b> Osvaldo Vera Zárate</b></span>
                    <span className='d-block'><i> Tcnel DCEM – Fuerza Aérea Paraguaya.</i></span></p>
                </div>
            </div>
        </div>
    </div>

    {/* BORRAR DESPUES DE PRESENTACION VIERNES 10 ***************************/}
    <div className='CardContainer'>
        <div className='d-flex justify-content-center cardContainer_1'>
            <div className='d-flex flex-column align-items-center'>
                <p className='titulo_noticia'>Ceremonia de graduación Curso Supervisor Radar</p>
                <img className='img-fluid img_noticia' src="./img/noticias/02.png" alt="" /><br />
                <div className='contenedor_texto_noticia'>
                    <p className='texto_noticia'>Con éxito culminó el <strong><em>Curso Supervisor Radar</em></strong>, donde se certificaron 25 servidores públicos que se desempeñan en el área de control de tránsito aéreo en la operación a nivel nacional, quienes con esfuerzo y responsabilidad adquirieron herramientas para el fortalecimiento de sus competencias para desarrollar de manera efectiva las tareas asignadas y así garantizar la seguridad operacional.</p>
                </div>
            </div>
        </div>
    </div>
    {/* BORRAR DESPUES DE PRESENTACION VIERNES 10 ***************************/}

    </>
  )
}

export default NoticiaCard
