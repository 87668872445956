import React from 'react'

import './Message.css'

const Message = ({ 
    type,
    className,
    children,
}) => {

    const classMessage = `message ${type} ${className}`

    return (
        <div className={ classMessage }>
            <h6 className='fw-bold m-0'>
                { children }
            </h6>
        </div>
    )
}

export { Message }