import { Route, Routes } from "react-router-dom"
import { Activiades } from "./actividades/Actividades"
import { Entidades } from "./entidades/Entidades"
import { OfertasExternas } from "./ofertasExternas/OfertasExternas"
import { EstudianteOfertaExterna } from "./estudiantesOfertaExterna/EstudiantesOfertaExterna"



const ActividadesTercerizadas=()=>{

    return(
        <Routes>
            <Route path="entidades" element={<Entidades/>}/>
            <Route path="actividades" element={<Activiades/>} />
            <Route path="ofertas_actividadexterna" element={<OfertasExternas/>} />
            <Route path="ofertas_actividadexterna/estudiantesOfertaExterna/:id" element={<EstudianteOfertaExterna/>} />
        </Routes>
    )

}

export {ActividadesTercerizadas}