import React, { useEffect, useState } from 'react'

import { ShowResult } from '../ShowResult/ShowResult'
import { FormService } from '../../services/api/formService'
import { Button } from '../button/button'
import { Input } from '../input/input'
import { alerta } from '../../helpers/alertas'

const AddUserAs = ({
    SearchEndpoint,
    isApplicant,
    setError,
    idCourse,
    isAdmitted,
    fetchData = async () => {},
    handleOpenModalAddApplicant,
    empresa
}) => {

    const [loading, setLoading] = useState(false)
    // const [idTime, setIdTime] = useState(null)
    const [readyAddStudent, setReadyAddStudent] = useState(false)
    const [cancel, setCancel] = useState(false)
    const [idUser, setIdUser] = useState('')
    const [user, setUser] = useState({})
    const [searchActive, setSearchActive] = useState(false)
    const api = new FormService()
    const ENDPOINT = isApplicant ? 
    isAdmitted ? 'aspirantes/admitirDirectamente' : 'aspirantes' 
    : 'estudiante'

    useEffect(() => {
        setIdUser('')
        setUser({})
        setSearchActive(false)
    }, [])

    const searchUsers = async () => {
        setLoading(true)
        setSearchActive(true)
        try {
            const formService = new FormService()
            const data = await formService.getAll(`${SearchEndpoint}${idUser}`)
            setUser(data)
            setLoading(false)
        } catch (err) {
            setError(err)
        }
    }

    const onSearchUser = (value) => {
        setIdUser(value)
    }

    const handleAddStudent = async () => {
        let data;
        if(empresa?.IdEmpresa){
             data = {
                "IdUsuario": user.IdUsuario,
                "IdOferta": idCourse,
                "IdEmpresa":empresa?.IdEmpresa
            }
        }else{
             data = {
                "IdUsuario": user.IdUsuario,
                "IdOferta": idCourse,
            }
        }
        
        let respuesta=await api.create(ENDPOINT, { ...data });
        if(respuesta.respuesta=="exito"){
            alerta(respuesta.mensaje)
            await fetchData()
            handleOpenModalAddApplicant()
        }else{
            alerta(respuesta.mensaje,"error")
        }
    }

    return (
        <>
            <section className='student-list__search'>
                <Input 
                    placeholder='Ingrese el número de cédula del usuario'
                    type='text'
                    value={ idUser }
                    onChange={ onSearchUser }
                />
                <Button 
                    type='link-primary'
                    onClick={ searchUsers }
                    loading={ loading }
                >
                    <i className="bi bi-search"></i>
                </Button>
            </section>
            {
                searchActive && (
                    <ShowResult 
                        resultData={ user }
                        loading={ loading }
                        cancel={ cancel }
                        readyAddStudent={ readyAddStudent }
                        // handleAddStudentCancel={ handleAddStudentCancel }
                        handleAddStudent={ handleAddStudent }
                        isApplicant={ isApplicant }
                        isAdmitted={ isAdmitted }
                    />
                )
            }
        </>
    )
}

export { AddUserAs }