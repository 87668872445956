import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Button, } from "../../components/button/button"
import { Card } from "../../components/card/Card"
import { FormService } from "../../services/api/formService"
import { LocalStorage } from "../../services"
import { DynamicSearch } from "../../components/DynamicForm/DynamicSearch/DynamicSearch"
import { DynamicTable } from "../../components/DynamicForm/dynamicTable/DynamicTable"
import Form from 'react-bootstrap/Form';

import { BASE_URL } from "../../services/api/config"

const url_pic=`${BASE_URL}/exportar/fomatoPIC`
const url_picofa=`${BASE_URL}/exportar/fomatoPICOFA`
const url_ofa=`${BASE_URL}/exportar/fomatoOFA`

const Exportar=()=>{

    const Storage = new LocalStorage();
    const idUsuario = Storage.getAsJsonItem("usuario")?.usuario?.IdUsuario || 'localstorage_empty'
    const urlCursos = `cursos/finalizadosRegistro`;
    const urlExportar=`${BASE_URL}/exportar/ofertasPorTipos`
    const[campos,setCampos]=useState(null);
    const[endPoint,setEndPoint]=useState(urlCursos);
    const[exportar,setExportar]=useState(urlExportar);
    const api = new FormService();
    const navigate = useNavigate();


    const radios = document.getElementsByName('group1');

    const onNavigate = (programa) => {
      navigate(`${programa.IdOferta}/formatoCurso`,{state:{programa,idUsuario}});
    }

    const buscarCursos = (e) => {
        const nuevoEndPoint=`cursos/Registro${e}`
        setEndPoint(nuevoEndPoint)
        
        const nuevoExportar=`${BASE_URL}/exportar/ofertasPorTipo${e}`
        setExportar(nuevoExportar)
    }

    useEffect(() => {
        const fecthData = async () => {
            fetchCamposFormForFilter();
        };
          fecthData();
      }, []);
    
      const fetchCamposFormForFilter = ()=>{   
        api.getAll(`xcampos?id_formulario=115`)
        .then((res)=>{
            setCampos(res)
        })
      }

    return(
        <>
        
        <Card>
             <h3>Exportar Actividades del Portafolio Académico</h3>
            {/*<a className="button" href={url_ofa}>Exportar formato OFA</a>
            <a className="button" href={url_pic}>Exportar formato PIC</a>
            <a className="button" href={url_picofa}>Exportar formato PIC / OFA</a> */}
            {/* <Form>
                {['radio'].map((type) => (
                    <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                        value="1"
                        label="Exportar formato PIC"
                        name="group1"
                        type={type}
                        id={`inline-${type}-1`}
                    />
                    <Form.Check
                        value="2"
                        label="Exportar formato PIC / OFA"
                        name="group1"
                        type={type}
                        id={`inline-${type}-2`}
                    />
                    <Form.Check
                        value="3"
                        name="group1"
                        label="Exportar formato OFA"
                        type={type}
                        id={`inline-${type}-3`}
                    />
                    </div>
                ))}
            </Form>*/}
        </Card> 
        {campos&&
            <DynamicSearch  onSearch={buscarCursos} camposForm={campos} ></DynamicSearch>
        }
        <DynamicTable
            titleColumn={['N°','Nombre','Fecha Inicio','Fecha Fin', 'Estado', 'Acción']}
            attributes={[,'Nombre','FechaInicioCurso','FechaFinCurso', 'Estado']}
            endPoint={endPoint}
            onDelete={false}
            onUpdate={false}
            labelOptionClick={`Ver más`}
            handleOptionClick={onNavigate}
            classNameOptionIcon={`bi bi-view-list`}
            exportar={exportar}
            formato={radios}
        ></DynamicTable>
        
        </>
    )
}

export {Exportar}