import React, { useState,useEffect } from 'react'
import { Card, Col, ListGroup, Modal, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { FormService } from '../../../services/api/formService' 
/* import { alertaSinBoton } from '../../../helpers/alertas' */
import { BASE_URL } from '../../../services/api/config' 
import DowloadFile from '../../../components/DowloadFile/DowloadFile'
import FormatoCursoContinuada from '../Formatos/FormatoCursoContinuada'
import FormatosCursoIES from '../Formatos/FormatosCursoIES'

function FormatoCurso({
    editable,
    gestionable=true
}) {
    const api= new FormService();
    const location = useLocation()


    const urlAsignaturas = `cursos/AsignaturasCursoRegistro?IdOferta=${location.state.programa?.IdOferta}&IdUsuario=${location.state.idUsuario}`
    const urlEstudiantes=`docente/estudiantesIes?IdOferta=${location.state.programa.IdOferta}`

    const [error, setError] = useState(false)
    const [estudiantes,setEstudiantes]=useState([]);
    const [modalDescargar,setModalDescargar]=useState();
    const IdOferta=location.state.programa.IdOferta
    const URL_EXPORT = `${BASE_URL}/exportar/`
    const [fasesLista, setFasesLista] = useState([]);
    const oferta=location.state.programa

    useEffect(()=>{
        const fetData=async()=>{
            if(estudiantes.length==0){
                getEstudiantes()
                if(oferta.IdTipoMecanismo==="4" || oferta.IdTipoMecanismo==="7" ){
                    fases(oferta.IdConvocatoria)
                }
            }
        }
       fetData();
    },[])

    const getEstudiantes=async()=>{
        const getEstudiantes= await api.getAll(urlEstudiantes);
        /* console.log(estudiantes) */
        setEstudiantes(getEstudiantes);
    }

    const fases = async (IdConvocatoria) => {
        /* console.log(IdConvocatoria) */
        try {
            // setLoadingFull(true)
            const formService = new FormService()
            const data = await formService.getAll(`convocatoriaFase/fasesLista?IdConvocatoria=${IdConvocatoria}`)
            /* console.log(data) */
            setFasesLista(data)
        } catch (err) {
            setError(err)
        }
    }

    return (
        <>
         {oferta.IdDimension==1?
            <FormatosCursoIES
                IdOferta={IdOferta}
                oferta={oferta}
                fasesLista={fasesLista}
            />
            :
            <FormatoCursoContinuada
                IdOferta={IdOferta}
                oferta={oferta}
                fasesLista={fasesLista}
            />
        }
            {/* <Card>
                <Card.Title>{location.state.programa.Nombre} - {location.state.programa.codigo_grupo}</Card.Title>
            </Card>            
            <Card>
                <Row>
                    <Col>
                        <Card>
                            <DowloadFile
                                nameFile={"Asistencias.pdf"}
                                name={"Asistencias"}
                                endPoint={`${BASE_URL}/exportar/cursoFinalizado/asistencias?IdOferta=${IdOferta}`}
                            />
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <DowloadFile
                                nameFile={"Calificaciones.pdf"}
                                name={"Calificaciones"}
                                endPoint={`${BASE_URL}/exportar/cursoFinalizado/calificaciones?IdOferta=${IdOferta}`}
                            />
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <DowloadFile
                                nameFile={"Inscritos.pdf"}
                                name={"Inscritos"}
                                endPoint={`${BASE_URL}/exportar/cursoFinalizado/inscritos?IdOferta=${IdOferta}`}
                            />
                         </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <Card>
                        <DowloadFile
                            nameFile={"Horario del Curso.pdf"}
                            name={"Horario del Curso  "}
                            endPoint={`${BASE_URL}/cursos/exportarHorario?IdOferta=${IdOferta}`}
                        />
                    </Card>
                    </Col>
                    <Col>
                    <Card>
                        <DowloadFile
                            nameFile={"Inscritos Eliminados.xlsx"}
                            name={"Inscritos Eliminados"}
                            endPoint={`${BASE_URL}/exportar/cursoFinalizado/inscritosEliminados?IdOferta=${IdOferta}`}
                        />
                    </Card>
                    </Col>
                    <Col>
                        <Card>
                        <DowloadFile
                            nameFile={"Estudiantes.xlsx"}
                            name={"Estudiantes"}
                            endPoint={`${BASE_URL}/exportar/cursoFinalizado/estudiantes?IdOferta=${IdOferta}`}
                        />
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <Card>
                        <DowloadFile
                            nameFile={"Informacion General del Curso.pdf"}
                            name={"Informacion General del Curso"}
                            endPoint={`${BASE_URL}/docente/sia?IdOferta=${IdOferta}`}
                        />
                    </Card>
                    </Col>
                    <Col>
                        <Card>
                        <DowloadFile
                            nameFile={"Promedios del Curso.pdf"}
                            name={"Promedios del Curso"}
                            endPoint={`${BASE_URL}/cursos/promedioCurso?IdOferta=${IdOferta}`}
                        />
                        </Card>
                    </Col>
                    <Col>
                    <Card>
                        <Button onClick={()=>setModalDescargar(true)}>
                            Otros Formatos
                        </Button>
                    </Card>
                    </Col>
                </Row>
            </Card>

            <Modal 
                show={modalDescargar} 
                onHide={()=>setModalDescargar(false)}
            >
            <Modal.Header closeButton>
                <Modal.Title>Exportables y Formatos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ul className="list-group">
                    <li className="list-group-item d-flex justify-content-between align-items-center">                        
                        <DowloadFile 
                            nameFile={"Listado de Apirantes.xlsx"}
                            name={"Listado de Apirantes"}
                            endPoint={`${URL_EXPORT}aspirantes?IdOferta=${IdOferta}`}
                        />
                        Listado de Aspirantes
                        <a target="blank" href={`${URL_EXPORT}aspirantes?IdOferta=${IdOferta}`}>
                        <i className="bi bi-file-arrow-down"></i>
                        </a>
                    </li>
                    <br></br>
                    <ListGroup>
                        <ListGroup.Item>
                            <DowloadFile 
                                nameFile={"Listado de Apirantes.xlsx"}
                                name={"Listado de Apirantes"}
                                endPoint={`${URL_EXPORT}aspirantes?IdOferta=${IdOferta}`}
                            />
                        </ListGroup.Item>
                        <br></br>
                        <ListGroup.Item>
                            <DowloadFile 
                                nameFile={"Listado de Inscritos.pdf"}
                                name={"Inscritos"}
                                endPoint={`${BASE_URL}/cursos/exportarAspirantesInscritos?IdOferta=${IdOferta}`}
                            />
                        </ListGroup.Item>
                        {oferta.IdConvocatoria&&
                            <ListGroup.Item>
                                <DowloadFile 
                                    nameFile={"Admitidos a pruebas.pdf"}
                                    name={"Admitidos a pruebas"}
                                    endPoint={`${BASE_URL}/cursos/exportarAdmitidosPrueba?IdOferta=${IdOferta}`}
                                />
                            </ListGroup.Item>
                        }
                        <ListGroup.Item>
                            <DowloadFile 
                                nameFile={"Admitidos.pdf"}
                                name={"Admitidos"}
                                endPoint={`${BASE_URL}/cursos/exportarAspirantesAdmitidos?IdOferta=${IdOferta}`}
                            />
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <DowloadFile 
                                nameFile={"Matriculados.pdf"}
                                name={"Matriculados"}
                                endPoint={`${BASE_URL}/cursos/exportarAspirantesMatriculados?IdOferta=${IdOferta}`}
                            />
                        </ListGroup.Item>
                    </ListGroup>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        Pre inscritos
                        <a href={`${URL_EXPORT}preInscritos?IdOferta=${IdOferta}`}>
                            <i class="bi bi-file-arrow-down"></i>
                        </a>
                        </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        Inscritos
                        <a href={`${URL_EXPORT}inscritos?IdOferta=${IdOferta}`}>
                            <i class="bi bi-file-arrow-down"></i>
                        </a>
                        </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        Admitidos
                        <a href={`${URL_EXPORT}admitidos?IdOferta=${IdOferta}`}>
                            <i class="bi bi-file-arrow-down"></i>
                        </a>                       
                        </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        Estudiantes
                        <a href={`${URL_EXPORT}matriculados?IdOferta=${IdOferta}`}>
                        <i class="bi bi-file-arrow-down"></i>
                        </a>
                    </li>
                </ul>
                <h5 className='text-center p-2'>Exporta Admitidos a Fases</h5>
                <ul className="list-group">
                {fasesLista.map((fase,key)=>
                    <ListGroup>
                    <ListGroup.Item>
                    <DowloadFile 
                                nameFile={`${fase.Nombre}.pdf`}
                                name={fase.Nombre}
                                endPoint={`${URL_EXPORT}admitidosFase?IdConvocatoriaFase=${fase.IdConvocatoriaFase}&IdOferta=${IdOferta}`}
                                />
                    </ListGroup.Item>
                    </ListGroup>
                    <li key={key} className="list-group-item d-flex justify-content-between align-items-center">
                        {fase.Nombre}
                        <a target="_blank" href={`${URL_EXPORT}admitidosFase?IdConvocatoriaFase=${fase.IdConvocatoriaFase}&IdOferta=${IdOferta}`}>
                        <i className="bi bi-file-arrow-down"></i>
                        </a>
                    </li>  
                )
                }
                </ul>                 
            </Modal.Body>
            </Modal> */}
        </>
    )
}

export { FormatoCurso }
