import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { FormService } from '../../services/api/formService';
import { BASE_URL } from '../../services/api/config';

const getItems = (count, offset = 0) =>
  Array.from({ length: count }, (v, k) => k).map((k) => ({
    id: `item-${k + offset}`,
    content: `item ${k + offset}`,
  }));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const move = (
  source,
  destination,
  droppableSource,
  droppableDestination
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  padding: grid * 1,
  margin: `0 0 ${grid}px 0`,
  background: isDragging ? 'lightgreen' : 'grey',
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
  width: 250,
});

const App = () => {
    const api= new FormService();
    const [items, setItems] = useState(getItems(1));
    const [selected, setSelected] = useState(getItems(0));
    const [tablas, setTablas] = useState([]);
    const [tabla, setTabla] = useState(null);
    const[modelo, setModelo] = useState(null);

    const id2List = {
        droppable: 'items',
        droppable2: 'selected',
    };

  useEffect(() => {
    const fetchData=async()=>{
        const tablas = await api.getAll(`listarTablas`);
        setTablas(tablas);
    }
    fetchData()
  },[])

  useEffect(() => {
    const fetchData=async()=>{
        // recetear items
        setItems([]);
        setSelected([]);
        const campos = await api.getAll(`listarCampos/${tabla}`);
        let items = [];
        for (let i = 0; i < campos.length; i++) {
            items.push({
                id: `${campos[i]}}`,
                content: `${campos[i]}`,
            });
        }
        setItems(items);
    }
    fetchData()
  },[tabla])

  const getList = (id) => (id === 'droppable' ? items : selected);


  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const updatedList = reorder(
        getList(source.droppableId),
        source.index,
        destination.index
      );

      if (source.droppableId === 'droppable2') {
        setSelected(updatedList);
      } else {
        setItems(updatedList);
      }
    } else {
      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );

      setItems(result.droppable);
      setSelected(result.droppable2);
    }
  };
//   enviarlos los campos seleccionados

const exportar=()=>{

    const campos = selected.map((item) => item.content);
    const url = `${BASE_URL}/exportarTablaDinamica?tabla=${tabla}&campos=${campos}`
    window.open(url, '_blank');


}

  return (
    <div>
        <Card>
            <Row>
                <Col>
                    <h5>Modelo a exportar</h5>
                </Col>
                <Col>
                    {/* selescionar */}
                    <Form.Select
                        aria-label="Default select example"
                        onChange={(e) => {
                            setTabla(e.target.value);
                        }}
                    >
                        <option>Selecciona la tabla</option>
                        {tablas.map((item) => (
                            <option value={item}>{item}</option>
                        ))}
                    </Form.Select>
                </Col>
                <Col>
                    <Button onClick={exportar}>Exportar</Button>
                </Col>
            </Row>
        </Card>
        <div style={{ display: 'flex' }}>
      {/* Columnas Disponibles */}
      <DragDropContext onDragEnd={onDragEnd}>
      <div style={{ marginRight: '20px' }}>
        <h5>Atributos Disponibles</h5>
        
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {items.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {item.content}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        {/* </DragDropContext> */}
      </div>

      {/* Columnas Seleccionadas */}
      <div>
        <h5>Atributos Seleccionadas</h5>
        {/* <DragDropContext onDragEnd={onDragEnd}> */}
          <Droppable droppableId="droppable2">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {selected.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {item.content}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
      </div>
      </DragDropContext>
      </div>
    </div>
  );
};

export default App;


// import React, { useState, useEffect } from 'react';
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// import { Card, Col, Form, Row } from 'react-bootstrap';
// import axios from 'axios';
// import { FormService } from '../../services/api/formService';

// const grid = 8;

// const getItemStyle = (isDragging, draggableStyle) => ({
//   userSelect: 'none',
//   padding: grid * 1,
//   margin: `0 0 ${grid}px 0`,
//   background: isDragging ? 'lightgreen' : 'grey',
//   ...draggableStyle,
// });

// const getListStyle = (isDraggingOver) => ({
//   background: isDraggingOver ? 'lightblue' : 'lightgrey',
//   padding: grid,
//   width: 250,
// });

// const App = () => {
//   const api = new FormService();
//   const [tables, setTables] = useState([]);
//   const [selectedTable, setSelectedTable] = useState('');
//   const [fields, setFields] = useState([]);
//   const [selected, setSelected] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const tablas = await api.getAll('listarTablas');
//         setTables(tablas);
//       } catch (error) {
//         console.error('Error al obtener tablas:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const campos = await api.getAll(`listarCampos/${selectedTable}`);
//         setFields(campos);
//       } catch (error) {
//         console.error('Error al obtener campos:', error);
//       }
//     };

//     fetchData();
//   }, [selectedTable]);

//   const onDragEnd = (result) => {
//     const { source, destination } = result;

//     if (!destination) {
//       return;
//     }

//     if (source.droppableId === destination.droppableId) {
//       const updatedList = reorder(
//         getList(source.droppableId),
//         source.index,
//         destination.index
//       );

//       if (source.droppableId === 'droppable2') {
//         setSelected(updatedList);
//       } else {
//         setFields(updatedList);
//       }
//     } else {
//       const result = move(
//         getList(source.droppableId),
//         getList(destination.droppableId),
//         source,
//         destination
//       );

//       setFields(result.droppable);
//       setSelected(result.droppable2);
//     }
//   };

//   const getList = (id) => (id === 'droppable' ? fields : selected);

//   const reorder = (list, startIndex, endIndex) => {
//     const result = Array.from(list);
//     const [removed] = result.splice(startIndex, 1);
//     result.splice(endIndex, 0, removed);
//     return result;
//   };

//   const move = (source, destination, droppableSource, droppableDestination) => {
//     const sourceClone = Array.from(source);
//     const destClone = Array.from(destination);
//     const [removed] = sourceClone.splice(droppableSource.index, 1);

//     destClone.splice(droppableDestination.index, 0, removed);

//     const result = {};
//     result[droppableSource.droppableId] = sourceClone;
//     result[droppableDestination.droppableId] = destClone;

//     return result;
//   };

//   return (
//     <div>
//       <Card>
//         <Row>
//           <Col>
//             <h5>Modelo a exportar</h5>
//           </Col>
//           <Col>
//             {/* Seleccionar tabla */}
//             <Form.Select
//               aria-label="Default select example"
//               onChange={(e) => {
//                 setSelectedTable(e.target.value);
//               }}
//             >
//               <option value="">Seleccione una tabla</option>
//               {tables.map((table) => (
//                 <option key={table} value={table}>
//                   {table}
//                 </option>
//               ))}
//             </Form.Select>
//           </Col>
//         </Row>
//       </Card>
//       <div style={{ display: 'flex' }}>
//         <DragDropContext onDragEnd={onDragEnd}>
//           <div style={{ marginRight: '20px' }}>
//             <h5>Atributos Disponibles</h5>
//             <Droppable droppableId="droppable">
//               {(provided, snapshot) => (
//                 <div
//                   ref={provided.innerRef}
//                   style={getListStyle(snapshot.isDraggingOver)}
//                 >
//                   {fields.map((field, index) => (
//                     <Draggable
//                       key={field.id}
//                       draggableId={field.id}
//                       index={index}
//                     >
//                       {(provided, snapshot) => (
//                         <div
//                           ref={provided.innerRef}
//                           {...provided.draggableProps}
//                           {...provided.dragHandleProps}
//                           style={getItemStyle(
//                             snapshot.isDragging,
//                             provided.draggableProps.style
//                           )}
//                         >
//                           {field.content}
//                         </div>
//                       )}
//                     </Draggable>
//                   ))}
//                   {provided.placeholder}
//                 </div>
//               )}
//             </Droppable>
//           </div>
//           <div>
//             <h5>Atributos Seleccionadas</h5>
//             <Droppable droppableId="droppable2">
//               {(provided, snapshot) => (
//                 <div
//                   ref={provided.innerRef}
//                   style={getListStyle(snapshot.isDraggingOver)}
//                 >
//                   {selected.map((item, index) => (
//                     <Draggable
//                       key={item.id}
//                       draggableId={item.id}
//                       index={index}
//                     >
//                       {(provided, snapshot) => (
//                         <div
//                           ref={provided.innerRef}
//                           {...provided.draggableProps}
//                           {...provided.dragHandleProps}
//                           style={getItemStyle(
//                             snapshot.isDragging,
//                             provided.draggableProps.style
//                           )}
//                         >
//                           {item.content}
//                         </div>
//                       )}
//                     </Draggable>
//                   ))}
//                   {provided.placeholder}
//                 </div>
//               )}
//             </Droppable>
//           </div>
//         </DragDropContext>
//       </div>
//     </div>
//   );
// };

// export default App;

