import React from 'react'
import DowloadFile from '../../../components/DowloadFile/DowloadFile'
import { BASE_URL } from '../../../services/api/config'
import { Button } from 'react-bootstrap'
import { FormService } from '../../../services/api/formService'
import { alerta } from '../../../helpers/alertas'

export default function FormatosEstudianteContinuda({
  IdOferta,
  IdEstudiante,
  IdUsuario,
  estudiante,
  oferta
}) {
  const api= new FormService();

  const urlCertificadoNotas=`${BASE_URL}/estudiante/certificadoNotas?IdOferta=${IdOferta}&IdEstudiante=${IdEstudiante}&IdUsuario=${IdUsuario}`
  const urlCertificadoAsistencia=`${BASE_URL}/estudiante/constancia?IdOferta=${IdOferta}&IdEstudiante=${IdEstudiante}&IdUsuario=${IdUsuario}`
  const urlDiploma=`${BASE_URL}/estudiante/certificadoDiploma?IdOferta=${IdOferta}&IdEstudiante=${IdEstudiante}`
  const urlDiplomaPre=`${BASE_URL}/estudiante/certificadoDiplomapre?IdOferta=${IdOferta}&IdEstudiante=${IdEstudiante}`
  const urlDecargardDiploma=`${BASE_URL}/estudiante/descargarDiploma?IdDiplomaGenerado=${estudiante?.IdDiplomaGenerado}`

  const generarDiploma = async() => {
    const res= await api.getAll(`estudiante/certificadoDiploma?IdOferta=${IdOferta}&IdEstudiante=${IdEstudiante}`)
    if(res.success){
      alerta(res.message, "success")
      window.history.back()
    }else{
      alerta(res.message, "error")
    }
  }

  return (
    <> 
    {/* validar si el currso esta finalizaod */}
    {oferta.IdEstadoOferta==3&&
    <>
    {/* validar si el estudiante aprobo el curso para generar el diploma */}
        {estudiante.aprobado==1&&
        <>
        {/* boron para generar diploma */}
            {estudiante.Diploma
              ?
              <>
                <DowloadFile nameFile="Diploma.pdf" name="Descargar Diploma" endPoint={urlDecargardDiploma} />
                <DowloadFile nameFile="DiplomaPreImpreso.pdf" name="Diploma PreImpreso" endPoint={urlDiplomaPre} />
              </>
              :
              <>
                <Button variant="success" onClick={generarDiploma}>Generar Diploma</Button>
              </>
            }
          </>
        }  
    </>
  }

      {/* <a
          className="button"
          target={'_blank'}
          rel="noreferrer"
          href={urlDiploma}  
        >
            <i class="bi bi-file-earmark-text"/>
            Diploma
        </a> */}
      {/* <a 
        className="button"
        target={'_blank'}
        rel="noreferrer"
        href={urlCertificadoNotas} 
        >
          <i class="bi bi-file-earmark-text"/>
          Certificado de Notas
      </a> */}
      <DowloadFile 
          nameFile={"Certificado de Notas.pdf"}
          name={"Certificado de Notas"}
          endPoint={urlCertificadoNotas}
      />
      {/* <a 
        className="button"
        target={'_blank'}
        rel="noreferrer"
        href={urlCertificadoAsistencia} 
        ><i class="bi bi-file-earmark-text"/>
        Certificado de constancia
      </a> */}
      <DowloadFile 
          nameFile={"Certificado de Estudios.pdf"}
          name={"Certificado de Estudios"}
          endPoint={urlCertificadoAsistencia}
      />
      
    </>
  )
}
