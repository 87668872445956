import React from 'react'

const RightButtonContainer = ({ children }) => {
    return (
        <div className="d-flex w-25">
            <div className="text-right w-100">
                { children }
            </div>
        </div>
    )
}

export { RightButtonContainer }