import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom"
import { DynamicTable } from "../../../components/DynamicForm/dynamicTable/DynamicTable"
import { LocalStorage } from "../../../services"

function ProgramasFinalizadosList () {

  const api = new LocalStorage();
  const idUsuario = api.getAsJsonItem("usuario")?.usuario?.IdUsuario || 'localstorage_empty'
 
  const urlProgramas = `coordinadores/cursosFinalizados?IdUsuario=${idUsuario}`;
  const navigate = useNavigate();

  const onNavigate = (programa) => {
    navigate(`${programa.IdOferta}/asignaturas`,{state:{programa,idUsuario}})
  }
  return (
    <>
    <Card>
     <Card.Title>Programas Finalizados</Card.Title>
    </Card>
    <DynamicTable
      titleColumn={['N°','Nombre','Descripción', 'Acción']}
      attributes={['Nombre','Descripcion']}
      endPoint={urlProgramas}
      onDelete={false}
      onUpdate={false}
      labelOptionClick={`Ver más`}
      handleOptionClick={onNavigate}
      classNameOptionIcon={`bi bi-view-list`}
    ></DynamicTable>
    </>
  )
}
export { ProgramasFinalizadosList }