import React, { useEffect } from 'react';
import { FooterHome } from '../../../src/components/Inicio/FooterHome/FooterHome';
import { HeaderHome } from '../../components/headerHome/HeaderHome';
import './Inicio.css';
import { Button } from '../../components/button/button';

import { LoginContainer } from '../Login/LoginContainer';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { BASE_URL_MOODLE, plantilla, servidorActual, servidorEnlace } from '../../services/api/config';
import { Accordion, Card, Col, Modal, Row } from 'react-bootstrap';
import { LocalStorage } from '../../services';
import PreguntasFrecuentes from './PreguntasFrecuentes';



const Inicio = () => {
    const {redireccion}=useParams();
    const navigate = useNavigate();
    const ls = new LocalStorage();
    useEffect(() => {
        if (ls.getItem('usuario') !== null || ls.getItem('usuario')){
            navigate('/dashboard/inicio')
        }
    }, [])

    
    return (
        <>
          {redireccion!="true" &&
            <Modal
                show={true}
                centered
                size="lg"
             >
                <Modal.Body>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ marginRight: '10px' }}>
                        <img src="/img/logo_cea.jpg" alt="logo" className="inicio__logo" width="200" />
                    </div>
                    <div>
                        <img src="/img/logo_aeronautica.jpg" alt="logo" className="inicio__logo" width="200" />
                    </div>
                </div>


                    <div
                        style={{
                            textAlign: 'center'
                        }}
                    >
                        <p
                            style={{
                                marginBottom: '0px',
                                paddingBottom: '0px',
                            }}
                        >Bienvenidos al Sistema de Información Académico</p>
                        <p
                            style={{
                                marginTop: '0px',
                                paddingTop: '0px',
                            }}
                        >Selecciona el tipo de ingreso</p>
                    </div>
                    <Row>
                        <Col>                            
                            <a href={`${servidorEnlace}/inicio/true`}>
                            <Card
                                style={{
                                    minHeight: '100px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center'
                                }}
                            >
                                <Card.Title>
                                    Institución de Educación Superior
                                </Card.Title>
                            </Card>
                            </a>
                        </Col>
                        <Col>
                            <a href={`http://${servidorActual}/inicio/true`}>
                            <Card
                                style={{
                                    minHeight: '100px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center'
                                }}
                            >
                                <Card.Title>
                                Educación Especializada - Continuada
                                </Card.Title>
                            </Card>
                            </a>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>    
        }
            {plantilla=="CEA" &&
                <header 
                    className="inicio__header"
                    style={{
                        position:'inherit'
                    }}
                >
                    <HeaderHome />
                </header>
            }
            
            <main className="inicio__container">
                {plantilla==="CEA" && 
                    <>
                    <section className="inicio__welcome">
                        <section className="inicio__column-description">
                            <h1 className="inicio__title">
                                Sistema de Información Académico del CEA
                            </h1>
                            <h3 className="inicio__description">
                                El CEA cuenta con un Sistema de Información Académico (SIA) llamado HALCÓN ERP especializado en el Sector Aeronáutico en modalidad SAAS.
                            </h3>
                            <h3 className="inicio__description">
                            Estimado aspirante, bienvenido al CEA. Si ya está registrado favor iniciar sesión, si es nuevo pulse en el botón “Registrarse” y diligencie el formulario de registro.
                            </h3>
                            <Link to="/sign-up">
                                <button
                                    type="button"
                                    className="btn btn-outline-primary button_registrarse"
                                >
                                    Registrarse
                                </button>
                            </Link>
                        </section>
                        <section className="inicio__column-notice">
                            <LoginContainer />

                        </section>
                    </section>
                    </>     
                }
                <PreguntasFrecuentes />

                {/* <h3>Videos tutoriales</h3>
                <div>
                <iframe width="550" height="275" src="https://www.youtube.com/embed/3Yp2sevHyCU?list=PLN_Z9BPwmOvO8Gi6Q_6HE5kqdxXs_QQPl" title="Inicio sesión y recuperación contraseña" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div> */}
                {plantilla==="seguridad" &&
                    <div style={{maxWidth:500,margin:'0 auto',padding:'50px 0'}}>
                        <Row style={{marginBottom:30}}>
                            <Col style={{padding:10}}>
                                <img width={'100%'} src="img/logo_aeronautica.png" alt="Logo aeronautica" />
                            </Col>
                            <Col style={{padding:10}}>
                                <img width={'100%'} src="img/logo_ministerio.jpg" alt="Logo Ministerio" />
                            </Col>
                        </Row>
                        <LoginContainer />
                    </div>
                }
                

                <div 
                    style={{
                        textAlign:"right",
                        fontSize:"15px",
                        color:"#ccc",
                        position:"fixed",
                        bottom:"20px",
                        right:"20px"
                    }}
                >
                    Vigilada Mineducación
                </div>
            </main>
            <footer className="inicio__footer">
                <FooterHome />
            </footer>
        </>
    );
};

export { Inicio };
