import React, {useState} from 'react'
import { Card, Col, Image, Row, Button } from 'react-bootstrap'
import { BASE_SERVIDOR } from '../../../../services/api/config'
import axios from 'axios'
import { BASE_URL } from '../../../../services/api/config'
import { alerta } from '../../../../helpers/alertas'
import { LocalStorage } from '../../../../services'
import { clear } from '../../../../pages/Login/UserSlice'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export default function Header({
    usuario
}) {
    const img=usuario?.Foto?`${BASE_SERVIDOR}/imgUser/${usuario?.Foto}`:"/img/TempProfile.jpg"
    const ls = new LocalStorage()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const onLogout = () => {
        ls.removeItem('access_token')
        ls.removeItem('usuario')
        ls.removeItem('idRol')
        ls.removeItem('roles')
        ls.removeItem('menu')
        ls.removeItem('dimensiones')
        ls.removeItem('currentRol')
        dispatch(clear())
        setTimeout(() => {
          navigate(
            "/inicio",
            {
              replace:true
            }
          )
        }, 300);
        setTimeout(() => {
            window.location.reload()
        }, 500)
      }

    const subirImg = () => {
        let campo = document.getElementById("Archivo");
        campo.click();
    }

    const cargarImg = async (e) => {
        const formData = new FormData()
        formData.append('Archivo', e.target.files[0])
        const carga = await axios.post(`${BASE_URL}/cargarImg?IdUsuario=${usuario.IdUsuario}`, formData)

        if(carga.data.respuesta === "exito") {
            alerta(carga.data.mensaje, "success")
            setTimeout(() => {
                onLogout()
            }, 3500)            
            
        } else if(carga.data.respuesta === "error") {
            alerta(carga.data.mensaje, "warning")
        } else {
            alerta("Algo salio mal", "warning")
        }
    }

  return (
    <Card>
            <Row>
                <Col sm="3">
                    <Image
                        width={100}
                        height={100}
                        alt='Imagen de perfil'
                        roundedCircle={true}
                        src={img}
                        style={{marginLeft : "19px", border: "4px solid #0D6EFD"}}
                    />
                    <div>
                            <Button
                                style={{marginTop: "5px"}}
                                onClick={subirImg}
                            >
                                Cambiar Foto
                            </Button>
                            <input
                                type={"file"}
                                id={"Archivo"}
                                name={"Archivo"}
                                onChange={ (e) => cargarImg(e) }
                                style={{display: "none"}}
                                required={true}
                            />
                    </div>
                </Col>
                <Col style={{textAlign:"left"}}>
                    <b style={{fontSize:30}}>
                        {usuario.PrimerNombres}{' '}
                        {usuario?.SegundoNombres}{' '}
                        {usuario?.PrimerApellidos}{' '}
                        {usuario.SegundoApellidos}{' '}
                        </b>
                    <p>{usuario.NumeroDocumento}</p>
                </Col>
            </Row>
        </Card>
  )
}
