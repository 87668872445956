import React, { useState } from 'react'
import { useEffect } from 'react';
import { Button } from '../../components/button/button';
import { useSubmitCreate } from '../../components/DynamicForm/hooks/useSubmitCreate';
import { InputFile } from '../../components/inputFile/inputFile';
import { Message } from '../../components/Message/Message';

import { FormService } from '../../services/api/formService';

export default function RequisitosGrado({
    IdOferta,
    IdUsuario,
    IdAspirante
}) {
    const [requisitos,setRequcitos]=useState([]);
    const [loadingFull, setLoadingFull] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [documents, setDocuments] = useState([])
    const [fetchDataCreate, statusCreate] = useSubmitCreate(
        'requisitosAspirante', 
        null, 
        null,
        '1'
    )
    const api=new FormService()

    useEffect(()=>{
        const fecthData=async()=>{
            consultarRequisitos()
        }
        fecthData();
    },[])

    const fetchDataDocuments = async () => {
        setLoadingFull(true)
        try {
            const formService = new FormService()
            const response = await formService.getAll(`${URL}${IdAspirante}&matricula=0`)
            setLoadingFull(false)
            setDocuments(response)
        } catch (error) {
            setLoadingFull(false)
            setError(error)
        }
    }

    const created = async (formData, document) => {
        formData.set('IdAspirante', IdAspirante)
        formData.set('IdRequisito', document?.IdRequisito)
        await fetchDataCreate(formData)
    }

    const update = async (formData, document) => {
        const formService = new FormService()
        formData.set('EstadoRequisito', "0")
        let dataRaw = Object.fromEntries(formData.entries());
        const id = document?.requisito?.IdRequisitoAspirante
        await formService.createWithFormData(
            `requisitosAspirante/${id}?_method=PUT`, 
            dataRaw
        )
    }


    const consultarRequisitos=async()=>{
        const requisitos=await api.getAll(`solicitudGrados/requisitosGrado?IdOferta=${IdOferta}&IdAspirante=${IdAspirante}`);
        console.log(requisitos)
        setRequcitos(requisitos);
    }

    const onHandleSubmit = async (e, document) => {
        e.preventDefault()
        try {
            setLoading(true)
            const formData = new FormData(e.currentTarget)
            if (
                document?.requisito?.EstadoRequisito === 0 ||
                document?.requisito?.EstadoRequisito === 2
            ) await update(formData, document)
            else await created(formData, document)
            fetchDataDocuments()
            consultarRequisitos()
            setLoading(false)
        } catch(err) {
            setError(err)
        }
    }

  return (
    <>
    {requisitos.map((document, key)=>
        <form 
            onSubmit={ e => onHandleSubmit(e, document) } 
            className='estudiante-documento'
            key={ key }
        >
        <div className='estudiante-documento__document'>
            <h6 className='estudiante-documento__document-title'>
                { document.Nombre }
            </h6>
            <p>Adjunta un documento PDF de maximo 10 Mb</p>
            { document?.requisito?.EstadoRequisito === undefined && 
                    <section className='estudiante-documento__update-document'>
                        <InputFile 
                            label={ document.Descripcion }
                            name='Archivo'
                            accept='image/*,.pdf'
                            // onChange={ handleChange }
                        />
                        <Button 
                            type='send'
                            loading={ loading }
                        >
                            Subir
                        </Button>
                    </section>                                
            }
            {document?.requisito?.EstadoRequisito&&
                    <>
                    <div className="alert alert-warning" role="alert">
                        Tu documento está en proceso de revisión.
                    </div>
                        <section 
                            className='estudiante-documento__update-document'
                        >
                            <InputFile 
                                label={ document.Descripcion }
                                name='Archivo'
                                accept='.pdf'
                                // onChange={ handleChange }
                            />
                            <Button 
                                type='send'
                                loading={ loading }
                            >
                                Actualizar documento
                            </Button>
                        </section>
                    </>
            }
            {document?.requisito?.EstadoRequisito ==1 && 
                    <>
                        <Message type='ok'>
                            Tu documento fue aprobado.
                        </Message>
                    </> 
            }
            { document?.requisito?.EstadoRequisito === 2 &&
                    <>
                        <Message type='error'>
                            Tu documento fue rechazado.
                        </Message>
                        <h6>Sucedio por el siguiente motivo:</h6>
                        <p>
                            { document.requisito.Observacion }
                        </p>
                        <section className='estudiante-documento__update-document'>
                            <InputFile 
                                label={ document.Descripcion }
                                name='Archivo'
                                accept='.pdf'
                                // onChange={ handleChange }
                            />
                            <Button 
                                type='send'
                                loading={ loading }
                            >
                                Actualizar documento
                            </Button>
                        </section>
                    </>
            }
        </div>
    </form>
    )
    }
    </>
  )
}
