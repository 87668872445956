import React from 'react'

import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner'
import { Badge } from '../Badge/Badge'
import { Button } from '../button/button'
import { Message } from '../Message/Message'

import './ShowResult.css'

const ShowResult = ({
    resultData,
    loading,
    cancel,
    readyAddStudent,
    handleAddStudentCancel,
    handleAddStudent,
    isApplicant,
    isAdmitted,
}) => (
    <section className="show-result__content-result">
        <section className="show-result__result">
            {
                loading && (
                    <div className="p-5">
                        <LoadingSpinner />
                    </div>
                )
            }
            {
                (resultData.length === 0 && !loading) && (
                    <>
                        <div className="show-result__warning show-result__warning--not-found">
                            <span>Usuario no encontrado</span>
                        </div>
                        <h3 className="text-center p-4 text-muted fw-bold">
                            Lo sentimos, no encontramos resultados.
                        </h3>
                    </>
                )
            }
            {
                (resultData && !loading) && (
                    <>
                        <div 
                            className="show-result__warning show-result__warning--found"
                        >
                            <span>Usuario encontrado</span>
                        </div>
                        <Badge 
                            to=''
                            state=''
                            img={ resultData?.Foto }
                            alt='Foto de perfil de estudiante'
                            user={ resultData }
                            key={ resultData.IdUsuario }
                        />
                        {
                            (cancel && !readyAddStudent) && (
                                <Button 
                                    type='loading-progress'
                                    onClick={ handleAddStudentCancel }
                                >
                                    Cancelar
                                </Button>
                            )
                        }
                        {
                            !cancel && (
                                <section className='show-result__button-add-student'>
                                    <Button 
                                        type='send'
                                        onClick={ handleAddStudent }
                                    >
                                        {
                                            `Agregar como ${
                                                isApplicant 
                                                ? isAdmitted 
                                                ? 'admitido' : 'aspirante' 
                                                : 'estudiante'}`
                                        }
                                    </Button>
                                </section>
                            )
                        }
                        {/* {
                            readyAddStudent && (
                                <Message type='ok'>
                                    {
                                        `Se ha agregado el usuario como 
                                        ${  
                                            isApplicant 
                                            ? isAdmitted 
                                            ? 'admitido' : 'aspirante' 
                                            : 'estudiante'
                                        }`
                                    }
                                </Message>
                            )
                        } */}
                    </>
                )
            }
        </section>
    </section>
)

export { ShowResult }