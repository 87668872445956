
import React from 'react';
import { BASE_SERVIDOR } from '../../services/api/config';
import { AiFillFileText } from 'react-icons/ai';
import { Tab, Tabs } from 'react-bootstrap';

const InputFile = ({
    id,
    label,
    name,
    accept,
    required = true,
    estilo = null,
    onChange = () => {},
    completo = false,
    archivoActual
}) => {
    if (!archivoActual) {
        return (
            <div className="form-group m-1">
                {label && (
                    <label
                        htmlFor={id}
                        className=" mt-3 fw-bold"
                    >
                        {label}
                    </label>
                )}
                <input
                    id={id}
                    type='file'
                    accept={accept}
                    name={name}
                    onChange={e => completo ? onChange(e.target.files[0], e.target.name) : onChange(e.target.files)}
                    className="form-control"
                    required={required ? 'required' : false}
                    style={estilo}
                />
            </div>
        );
    }

    return (
        <div className="form-group m-1"
            style={{
                border: '1px solid #ced4da',
                borderRadius: '5px',
                padding: '10px',
                marginTop: '10px',
            }}
        >
            {label && (
                <label
                    htmlFor={id}
                    className=" fw-bold"
                >
                    {label}
                </label>
            )}
            <Tabs
                id="input-file-tabs"
                defaultActiveKey="verArchivo"
                style={{
                    marginTop: '10px',
                }}
            >
                <Tab eventKey="verArchivo" title="Archivo">
                    <div
                        style={{
                            padding: '10px',
                            paddingTop: '20px',
                        }}
                    >
                        <a
                            href={`${BASE_SERVIDOR}/${archivoActual}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            // color gris
                            style={{
                                color: '#6c757d',
                                textDecoration: 'underline',
                            }}
                        >
                            <AiFillFileText size={20} />
                            Ver Archivo
                        </a>
                    </div>
                </Tab>
                <Tab eventKey="seleccionarArchivo" title="Seleccionar Archivo">
                    <div
                        style={{
                            padding: '8px',
                        }}
                    > 
                        <input
                            id={id}
                            type='file'
                            accept={accept}
                            name={name}
                            onChange={e => completo ? onChange(e.target.files[0], e.target.name) : onChange(e.target.files)}
                            className="form-control"
                            required={required ? 'required' : false}
                            style={estilo}
                        />
                    </div>
                </Tab>
            </Tabs>
        </div>
    );
}

export { InputFile };
