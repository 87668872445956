import React from 'react'
import { Button } from '../button/button'
import { useNavigate } from 'react-router-dom'

import './Arrow.css'

const Arrow = ({
    sticky,
    color,
    path,
    texto
}) => {

    const navigate = useNavigate()
    const goBack = () => {
        navigate(path || -1)
    }
    
    const classArrow = sticky ? `arrow ${color}` : `arrow ${color}`

    return (
        <div className={ classArrow }>
            <Button
                onClick={ goBack }
                className='arrow__button'
            >
                <i className="bi bi-arrow-left"></i> <span style={{fontSize:"15px",margin:"12px",position:"absolute"}}>{texto}</span>
            </Button>
        </div>
    )
}

export { Arrow }