
import { useEffect } from 'react';
import { useState } from 'react';
import ApiDashboard from '../../../../../services/api/dashboard';
import { AddButton } from '../../../../componentsForm/addButton/addButton';
import { FormContainer } from '../../../../componentsForm/formContainer/FormContainer';
import { Bar } from 'react-chartjs-2';
import faker from 'faker';


import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    /*title: {
      display: true,
       text: 'Chart.js Bar Chart', 
    },*/
  },
};
const labels = ['Abril', 'May', 'June'];

export const data = {
  labels,
  datasets: [
    {
      label: 'Cursos',
      data: !!labels && labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: '#fff',
    },
    {
      label: 'Asignaturas',
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: '#006eb7',
    },
    {
      label: 'Aspirantes',
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: '#00a3cc',
    }
  ],
};

function AdminDashboardHome () {

    const [ID, setID] = useState('');
    const [info, setInfo] = useState('');
  
    const title = [
      'Cantidad',
      'Descripción',
  ]
  
    const attributes = [
      'Descripcion',
      'CodigoE'
    ]
  
  useEffect(() => {
    const fecthDataLoad = async () => {
        await fetchData();
    };
    fecthDataLoad();
  }, []);
  
  const fetchData = async () => {
    try {
        const api = new ApiDashboard();
  
        const data = await api.getData(`metricas?IdDimension`);
        setInfo(data);
  
    } catch (e) {
        console.log('#Error fetching dataTable');
    }
  };

  return(
    <>
    <FormContainer >
      
      <AddButton />
      <div className='row col-12 p-5'>
        
        
        <div className='card  col-md-5 col-sm-12 mt-3 mx-1 '>
        <div className="-title">
          Asignaturas: {info.asignaturas}
        </div>
        <div className="col-md-10 col-sm-9"><Bar options={options} data={data} /></div>
      </div>
      <div className='card  col-md-5 col-sm-12 mt-3 mx-1 '>
        <div className="-title">
          Cursos: {info.cursos}
        </div>
        <div className="col-md-10 col-sm-9"><Bar options={options} data={data} /></div>
      </div>
      <div className='card  col-md-5 col-sm-12 mt-3 mx-1 '>
        <div className="-title">
          Aspirantes: {info.aspirantes}
        </div>
        <div className="col-md-10 col-sm-9"><Bar options={options} data={data} /></div>
      </div>
      </div>
    </FormContainer>
    </>
  )
}

export { AdminDashboardHome }