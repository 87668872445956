import React from "react";

import { Link } from 'react-router-dom'
import { BsFillHouseDoorFill } from "react-icons/bs";
import { LoadingSpinner} from '../../LoadingSpinner/LoadingSpinner'

import './MenuList.css'
import { BASE_SERVIDOR } from "../../../services/api/config";

const MenuList = ({
  menu,
  handleChangeSidebar
}) => {

  const unCheckItems = () => {
    var itemsMenu = document.getElementsByClassName("menu-list__collapse-item");
    for(var i = 0; i < itemsMenu.length; i++) {
      itemsMenu[i].classList.remove('menu-list__collapse-item--active');
    }
  }

  const onFocusItem = (e) => {
    if (!e.target.className.includes('menu-list__collapse-item--active')) {
      unCheckItems()
      e.target.classList.add('menu-list__collapse-item--active')
    }
  }
  const Icono="/img/icono.png"

  return (
    <div>
      <div 
        className="menu-list__container-start"
        onClick={ handleChangeSidebar }
      >
        <Link
          to={"/dashboard/inicio"} 
          className="menu-list__start-button"
        >
          <>
            <BsFillHouseDoorFill />
            <span className='p-2 m-0'>Inicio</span>
          </>
        </Link>
      </div>
      {
        !menu.length 
        ? <LoadingSpinner/> 
        : menu.map((item, index) => (
          <div 
            key={ index } 
            className="accordion" 
            id={ "accordionExample" + index }
          >
            <div 
              className="accordion-item menu-side"
            >
              <h2 
                className="accordion-header title-sidebar-menu"
                id={"headingThree" + index}
              >
                <button 
                  className="menu-list__button-modulo accordion-button collapsed menu-sidebar-button mb-1"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={ "#collapseThree" + index }
                  aria-expanded="false"
                  aria-controls={"collapseThree" + index}>
                    <div className="icono">
                      {item.Icono?
                        <img src={`${BASE_SERVIDOR}/img/iconos/${item.Icono}`} alt="icono" />
                        :
                        <img src={Icono} alt="icono" />
                      }
                      
                      </div>
                    <span className="menu-list__text-modulo">
                      { item.modulo }
                    </span>
                </button>
              </h2>
              <div
                id={ "collapseThree" + index } 
                className="accordion-collapse collapse" 
                aria-labelledby={"headingThree" + index}
                data-bs-parent={"#accordionExample" + index}>
                <div className="menu-list__collapse-body">
                  {
                    !!item.ventanas && item.ventanas.map((itemWindow, index) => (
                      <Link
                        key={ index }
                        className="menu-list__collapse-item"
                        to={item.path_modulo+'/'+itemWindow.path_ventana}
                        onClick={ handleChangeSidebar }
                        onFocusCapture={ onFocusItem }
                      >
                        { itemWindow.descripcion }
                      </Link>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        )) 
      }
    </div>
  )
}

export { MenuList }