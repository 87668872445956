import React, { useState, useEffect } from 'react'

import { Usuarios } from './Usuarios'
import { useForm } from '../../../hooks/useForm'
import { getData } from '../../../helpers/LocalStorage'

const UsuariosContainer = () => {

    const [isOpenWindows, setisOpenWindows] = useState(false)
    const dimension = getData('dimensionActual')
    const {
        onCreate,
        onDelete,
        onUpdate,
        allData,
        data,
        isOpenForm,
        isOpenDelete,
        isCreate,
        handleSubmit,
        handleChange,
        handleClickDelete,
        handleClickAdd,
        deleteTuple,
        loading,
        setData,
        setLoading,
        loadAllData,
    } = useForm(
        'usuario',
        'IdUsuario',
        `usuario?IdDimension=${dimension.IdDimension}`,
    )

    const handleClickWindows = () => {
        setisOpenWindows(!isOpenWindows)
    }

    const onWindows = async tuple => {
        handleClickWindows()
        setData(tuple)
        setLoading(true)
        await loadAllData()
        setLoading(false)
    }

    const title = [
        'ID',
        'Nombre de usuario',
        'Estado',
        'Acciones'
    ]

    const attributes = [
        'NombreUsuario',
        'Estado',
    ]

    return (
        <Usuarios
            title={ title }
            usuarios={ allData }
            usuario={ data }
            attributes={ attributes }
            onCreate={ onCreate }
            onDelete={ onDelete }
            onUpdate={ onUpdate }
            isCreate={ isCreate }
            isOpenForm={ isOpenForm }
            isOpenDelete={ isOpenDelete }
            isOpenWindows={ isOpenWindows}
            handleSubmit={ handleSubmit }
            handleChange={ handleChange }
            loading={ loading }
            handleClickAdd={ handleClickAdd }
            handleClickWindows={ handleClickWindows }
            handleClickDelete={ handleClickDelete }
            deleteTuple={ deleteTuple }
            onWindows={ onWindows }
        />
    )
}

export { UsuariosContainer }