import React, { useState } from 'react'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import { Select } from '../../components/select/Select';
import { SelectDataList } from '../../components/SelectDataList/SelectDataList';
import TablePaginate from '../../components/TablePaginate/TablePaginate';
import { FormService } from '../../services/api/formService';
import { alerta } from '../../helpers/alertas';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchFields } from '../../shared/store/table/tableSlice';
import { BASE_URL } from '../../services/api/config';

export default function CarnetEstudiantil() {
    const api= new FormService();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [IdUsuario, setIdUsuario] = useState(null);
    const[FechaExpedicion, setFechaExpedicion] = useState(null);
    const [FechaVencimiento, setFechaVencimiento] = useState(null);
    const searchFields=useSelector(state=>state.table.searchFields);
    const [IdOferta, setIdOferta] = useState(null);
    const [searchFields2, setSearchFields2] = useState({...searchFields});

    // expedir carnet
    const expedirCarnet = async () => {
        const data={
            "IdUsuario":IdUsuario,
            "FechaExpedicion":FechaExpedicion,
            "FechaVencimiento":FechaVencimiento,
            "IdOferta":IdOferta
        }
        const result = await api.create("carnetsEstudiantil",data)
        if(result.success){
            setShowModal(false)
            alerta(result.message)
            dispatch(setSearchFields({...searchFields,i:Math.random()}));    
        }else{
            alerta(result.message)
        }
    }
  return (
    <>
        <Card>
            <Row>
                <Col>
                    <Card.Title>Carnet Estudiantil</Card.Title>
                </Col>
                <Col
                    style={{textAlign:'right'}}
                >
                    <Button 
                        variant="primary"
                        onClick={() => setShowModal(true)}
                    >
                        Expedir Carnet
                    </Button>
                </Col>
            </Row>
        </Card>
        <Row
            style={{padding:"10px"}}
        >
            <Col>
            <Form.Group className="mb-3">
                {/* <Form.Label>Numero de Documento</Form.Label> */}
                <Form.Control
                    type="text"
                    placeholder="Numero de Documento"
                    name="NumeroDocumento"
                    value={searchFields2.NumeroDocumento}
                    onChange={e => setSearchFields2({...searchFields2,NumeroDocumento:e.target.value})}
                />
            </Form.Group>
            </Col>
            <Col>
                {/* boton para buscar */}
                <Button
                    variant="primary"
                    onClick={() => {
                        dispatch(setSearchFields({...searchFields,...searchFields2}));
                    }}
                >
                    Buscar
                </Button>
            </Col>
        </Row>
        
        {/* motrar la tabla */}
        <TablePaginate 
            apiUrl={'carnetsEstudiantil'} 
            tableHeaders={[
                {"title":"Nombre","attribute":"Nombre"},
                {"title":"Fecha de Expedición","attribute":"FechaExpedicion"},
                {"title":"Fecha de Vencimiento","attribute":"FechaVencimiento"},
            ]}
            rowActions={[
                ({rowData}) => {
                    return (
                        <Button 
                            variant="primary"
                            href={`${BASE_URL}/carnetsEstudiantil/ExportarCarnet?IdCarnetEstudiantil=${rowData.IdCarnetEstudiantil}`}
                            target="_blank"
                        >
                            Exportar
                        </Button>
                    )
                }
            ]}
        />
        <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>Carnet Estudiantil</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SelectDataList 
                    endPoinLastUrl={'usuarios/completos'}
                    optionName='Nombre'
                    optionValue='IdUsuario'
                    onChangeHandler={setIdUsuario}                    
                />
                {IdUsuario && 
                <>
                    <Select 
                        urlOptionsService={`carnetsEstudiantil/consultarcursos?IdUsuario=${IdUsuario}`}
                        optionName='Nombre'
                        optionValue='IdOferta'
                        onChange={setIdOferta}
                        value={IdOferta}
                    />

                    <Form.Group className="mb-3">
                        <Form.Label>Fecha de Expedición</Form.Label>
                        <Form.Control
                            type="date"
                            value={FechaExpedicion}
                            onChange={e => setFechaExpedicion(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Fecha de Vencimiento</Form.Label>
                        <Form.Control
                            type="date"
                            value={FechaVencimiento}
                            onChange={e => setFechaVencimiento(e.target.value)}
                        />
                    </Form.Group>
                </>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={() => expedirCarnet()}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    </>
  )
}
