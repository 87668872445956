import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Dropdown, Form, Modal, Row, Table } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom';
import { FormService } from '../../services/api/formService';
import { BASE_URL } from '../../services/api/config';
import DowloadFileText from '../../components/DowloadFileText/DowloadFileText';

export default function MomentosAsistencia() {
  const api= new FormService()
  const navigate=useNavigate();
  const [momentosAsistencia, setMomentosAsistencia] = useState([]);
  const [show, setShow] = useState(false);
  const [showEliminar, setShowEliminar] = useState(false);
  const [dataForm, setDataForm] = useState({});
  const {state:{IdOferta, asignatura, IdUsuario, IdMalla,IdUnidadAsignatura,IdAula}} = useLocation();
  const [redirigiendo, setRedirigiendo] = useState(false);

  useEffect(() => {
    consultarMomentosAsistencia();
    console.log(dataForm)
  }, [])

  const guardarMomento = async() => {
    // copro
    const data={
      ...dataForm,
      IdOferta,
      IdUsuario,
      IdMalla,
      IdAula,
      IdUnidadAsignatura
    }
    if(dataForm.actualizar){
      const respuesta=await api.update('educacionIES/actualizarMomento',data, dataForm.IdMomento);
      if(respuesta.respuesta=="exito"){
        consultarMomentosAsistencia();
        setShow(false);
      }else{
        alert('No se pudo actualizar el momento de asistencia');
      }
    }else{
      const respuesta=await api.create('educacionIES/crearMomento',data);
      if(respuesta.respuesta=="exito"){
        consultarMomentosAsistencia();
        setShow(false);
      }else{
        alert('No se pudo crear el momento de asistencia');
      }
    }
  }
  const eliminarMomento = async() => {
    const respuesta=await api.delete(`educacionIES/eliminarMomento`,{Estado:0}, dataForm.IdMomento);
    if(respuesta.respuesta=="exito"){
      consultarMomentosAsistencia();
      setShowEliminar(false);
    }else{
      alert('No se pudo eliminar el momento de asistencia');
    }
  }

  // consultar momentos de asistencias
  const consultarMomentosAsistencia = async() => {
    const momentos= await api.getAll(`educacionIES/consultarMomentosAsistencia?IdOferta=${IdOferta}&IdUnidadAsignatura=${IdUnidadAsignatura}&IdMalla=${IdMalla}`);
    setMomentosAsistencia(momentos);
  }
  // navegar a asistencia
  const navegarAsistencia=(evento,index)=>{

    setRedirigiendo(true)
    api.getAll('docente/planillaAsistencia?IdEventoAula='+evento.IdEventoAula).then(res=>{
      setRedirigiendo(false)
      navigate(`${evento.IdEventoAula}`,{
        state:{
          evento:{...evento,index},asignatura,IdUsuario,estudiantes:res}
        })
        
    }).catch(err=>{console.log("error getting event asis")})

  }

  return (
    <>
    <Card>
      <Row>
        <Col>
          <Card.Title>Momentos de Asistencia</Card.Title>
        </Col>
        <Col 
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px',
          }}
        >
          {/* exportar formato de asistencia */}
          <Button
                variant='secondary'
            >
                <DowloadFileText
                    nameFile={"Asistencia.pdf"}
                    name={"Exportar Asistencias"}
                    colortext={"#ffffff"}
                    endPoint={`${BASE_URL}/formatosIES/exportar/cursoFinalizado/asistencias?IdOferta=${IdOferta}&IdMalla=${IdMalla}`}
                />
          </Button>
          <Button
            onClick={() => {
              setShow(true)
              setDataForm({})
            }}
          >Crear Momento</Button>
        </Col>
      </Row>
    </Card>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          {/* <th>Nombre</th> */}
          <th>Fecha</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        {momentosAsistencia.map((momento, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            {/* <td>{momento.Nombre}</td> */}
            <td>{momento.FechaInicio} - {momento.FechaFin}</td>
            <td>
              <Dropdown>
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                  Acciones
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item 
                    onClick={ ()=>{ navegarAsistencia(momento,index) }}
                  >Llamar Asistencia</Dropdown.Item>
                  <Dropdown.Item 
                    onClick={() => {
                      setShow(true);
                      setDataForm({
                        Fecha: momento.FechaInicio,
                        HoraFin: momento.FechaFin.split(' ')[1],
                        HoraInicio: momento.FechaInicio.split(' ')[1],
                        Fecha: momento.FechaInicio.split(' ')[0],
                        IdMomento: momento.IdEventoAula,
                        actualizar: true,
                      })
                    }}
                  >Editar</Dropdown.Item>
                  <Dropdown.Item 
                    onClick={() => {
                      setShowEliminar(true);
                      setDataForm({
                        ...dataForm,
                        IdMomento: momento.IdEventoAula,
                      })
                    }}
                  >Eliminar</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
    {/* modal para crear momento de aistencia  */}
    <Modal show={show} onHide={()=>setShow()}>
      <Form onSubmit={(e) => { e.preventDefault(); guardarMomento(); }} >
      <Modal.Header closeButton>
        <Modal.Title>Crear Momento de Asistencia</Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Fecha</Form.Label>
            <Form.Control type="date" placeholder="Fecha" 
              value={dataForm.Fecha}
              required
              onChange={(e) => setDataForm({
                ...dataForm,
                Fecha: e.target.value,
              })}
            />
          </Form.Group>
          {/* Hora de inicio */}
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Hora de inicio</Form.Label>
            <Form.Control type="time" placeholder="Hora de inicio"
              value={dataForm.HoraInicio}
              required
              onChange={(e) => setDataForm({
                ...dataForm,
                HoraInicio: e.target.value,
              })}
            />
          </Form.Group>
          {/* Hora de fin */}
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Hora de fin</Form.Label>
            <Form.Control type="time" placeholder="Hora de fin"
              value={dataForm.HoraFin}
              required
              onChange={(e) => setDataForm({
                ...dataForm,
                HoraFin: e.target.value,
              })}
            />
          </Form.Group>
        
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={()=>setShow()}>
          Cerrar
        </Button>
        {/* enviar formulario */}
        <Button variant="primary" type="submit">
          {dataForm.actualizar ? 'Actualizar' : 'Guardar' }
        </Button>
      </Modal.Footer>
      </Form>
    </Modal>
    {/* modal para liminar */}
    <Modal show={showEliminar} onHide={()=>setShowEliminar()}>
      <Modal.Header closeButton>
        <Modal.Title>Eliminar Momento de Asistencia</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>¿Está seguro que desea eliminar el momento de asistencia?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={()=>setShowEliminar()}>
          Cerrar
        </Button>
        <Button variant="primary" onClick={()=>eliminarMomento()}>
          Eliminar
        </Button>
      </Modal.Footer>
    </Modal>
    {/* modal para actulizar momento */}
    </>
  )
}
