import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { Input } from '../../../components/input/input';
import { alerta } from '../../../helpers/alertas';
import { FormService } from '../../../services/api/formService';
import Header from './Componentes/Header';

export default function CambiarClave() {
    const {state:{usuario}}=useLocation()
    const api= new FormService();
    const[passActual,setPassActual]=useState('')
    const[passNuevo,setPassNuevo]=useState('')
    const[passRepetir,setPassRepetir]=useState('')

    const cambiarPass=async()=>{
        if(passNuevo!=''){
            if(passNuevo==passRepetir){
                const data={
                    passActual:passActual,
                    passNuevo:passNuevo,
                    IdUsuario:usuario.IdUsuario
                }
                const respuesta=await api.create("usuario/cambiarPass",data)
                console.log(respuesta)
                if(respuesta.respuesta=="exito"){
                    alerta(respuesta.mensaje,"succes")
                }else{
                    alerta(respuesta.mensaje,"error")
                }
            }else{
                alerta("Contraseñas diferentes","error")
            }
        }else{
            alerta("Escribe una contraseña","error")
        }
    }
  return (
    <>
    <div style={{maxWidth:1000,margin:"0 auto",marginTop:40}}>
        <Header usuario={usuario} />
        <div style={{maxWidth:600,margin:"0 auto"}}>
            <Input 
                label='Contraseña Actual'
                type='password'
                name='PrimerNombres'
                value={ passActual || '' }
                onChange={(e)=>setPassActual(e) }
            />
            <Input 
                label='Contraseña Nueva'
                type='password'
                name='SegundoNombre'
                value={ passNuevo || '' }
                onChange={(e)=>setPassNuevo(e) }
            />
            <Input 
                label='Repetir Contraseña'
                type='password'
                name='SegundoNombre'
                value={ passRepetir || '' }
                onChange={(e)=>setPassRepetir(e) }
            />
            <div style={{textAlign:"right",marginTop:20}}>
                <Button
                    onClick={()=>cambiarPass()}
                    className='send'
                >
                    Cambiar Contraseña
                </Button>
            </div>
        </div>
        
        </div>
    </>
  )
}
