import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { LocalStorage } from '../../../services'
import { clear } from '../../../pages/Login/UserSlice'
import { Button } from '../../button/button'
import { Dropdown } from '../Dropdown/Dropdown'

import './NavbarHeader.css'
import { plantilla } from '../../../services/api/config'

const NavbarHeader = ({
  handleChangeSidebar,
  handleChangeModal,
}) => {

  const [username, setUsername] = useState('---')
  const [usuario, setUsuario] = useState()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const ls = new LocalStorage()

  useEffect(() => {
    const usuario = ls.getAsJsonItem('usuario')
    if (usuario) {
      setUsername(usuario.usuario?.PrimerNombres + ' ' + usuario.usuario?.PrimerApellidos)
      setUsuario(usuario.usuario)
    }
  }, [])
  
  const onLogout = () => {
    ls.removeItem('access_token')
    ls.removeItem('usuario')
    ls.removeItem('idRol')
    ls.removeItem('dimension')
    ls.removeItem('roles')
    ls.removeItem('menu')
    ls.removeItem('dimensiones')
    ls.removeItem('currentRol')
    dispatch(clear())
    setTimeout(() => {
      navigate(
        "/inicio",
        {
          replace:true
        }
      )
    }, 300);
  }
  return (
    <>
      <div className={plantilla=="CEA"?'navbar-dashboard':'navbar-dashboard_2'}>
        <section className='navbar-dashboard__left'>
          {plantilla=="CEA"&&
            <>
              <figure style={{float:'left'}} className='navbar-dashboard__img-container' >
              <img 
                src="/img/logo_blanco.png" 
                alt="logo-halcon" 
                width={90}
              />                                 
            </figure>
              <div id='logo-header-incio' style={{float:'left',paddingLeft:15,paddingTop:5, fontSize:13,textTransform:'uppercase'}}>
                {/* centrar texto vertical */}
                <div>Centro de Estudios Aeronáuticos</div>
                {ls.getItem('dimension')=='"true"'&&
                  <div>Institución Universitaria </div>
                }

              </div> 
            </>
          }
          {plantilla=="seguridad"&&
            <>
              <figure style={{float:'left'}} className='navbar-dashboard__img-container' >
              <img 
                src="/img/logo_aeronautica_blanco.png" 
                alt="logo-halcon" 
                width={50}
              />                                 
            </figure>
              <div id='logo-header-incio' style={{float:'left',paddingLeft:15,paddingTop:5, fontSize:13,textTransform:'uppercase'}}>
                <div style={{marginTop:0,color:"#002860"}}> Aeronáutica Civil</div>
                <div style={{color:"#002860"}}> Unidad Administrativa Especial</div>
              </div> 
                <figure style={{float:'left',marginLeft:50}} className='navbar-dashboard__img-container' >
                  <img 
                    src="/img/logo_ministerio.jpg" 
                    alt="logo-halcon" 
                    width={220}
                  />                                 
                </figure>
            </>

          }

        </section>
        <section className='navbar-dashboard__right'>
          <Button
            className={plantilla=="CEA"?'navbar-dashboard__user':'navbar-dashboard_2__user'}
            onClick={ handleChangeModal }
          >
            <div id="nombre_usuario">
              <h5 title='Click para ver o cambiar de rol' className='navbar-dashboard__username'>
                { username }
              </h5>
            {plantilla=="CEA"&&
              <p title='Click para ver o cambiar de rol' className='navbar-dashboard__rol'>
                { ls.getAsJsonItem('currentRol')?.Descripcion }
              </p>
            }
            
            </div>
          </Button>
          <Dropdown 
            usuario={ usuario }
            onLogout={ onLogout }
            handleChangeModal={handleChangeModal}
          />
          <Button
            onClick={ handleChangeSidebar }
            className='navbar-dashboard__toggle m-0 ps-0 text-white fs-1'
          >
            <i className="bi bi-list"></i>
          </Button>
        </section>
      </div>
    </>
  )
}

export { NavbarHeader }