import { Card } from "../../../components/card/Card"
import { useNavigate } from "react-router-dom"
import { DynamicTable } from "../../../components/DynamicForm/dynamicTable/DynamicTable"
import { useState, useEffect } from "react"
import { FormService } from "../../../services/api/formService"
import { DynamicSearch } from "../../../components/DynamicForm/DynamicSearch/DynamicSearch"
import { BASE_URL } from "../../../services/api/config"

const OfertasTerceros=()=>{
    
    const navigate = useNavigate()
    const api = new FormService()
    const [endPoint, setEndpoint] = useState("ofertasExternas")
    const [camposForm, setCamposForm] = useState()
    const [urlExportar, setUrlExportar] = useState(`${BASE_URL}/exportar/actividadesExternas`)
    const [pagination, setPagination] = useState(
        {
            current_page:1,
            prev_page_url:null,
            next_page_url:null,
            per_page:0
        }
    )
    const onNavigateDetails = (e) => {
        navigate(`estudiantesOfertaExterna/${e.IdOfertaExterna}`)
    }

    const fetchCamposFormForFilter = ()=>{
        
        api.getAll(`xcampos?id_formulario=128`)
        .then((res)=>{
            setCamposForm(res)
        })
      
    }

    const onSearch = (urlParams) => {
        setEndpoint(endPoint+urlParams)
        setUrlExportar(`${BASE_URL}/exportar/actividadesExternas${urlParams}`)
    }

    useEffect(() => {
        
        const fecthData = async () => {
            fetchCamposFormForFilter(endPoint);
        };
        fecthData();
        
    }, []);

    return(
        <>
            <Card>
                <h2>Ofertas - Actividades Tercerizadas</h2>
            </Card>
            {camposForm &&
                <Card>
                    <DynamicSearch onSearch={onSearch} camposForm={camposForm}></DynamicSearch>
                </Card>
            }
            <Card>
                <DynamicTable
                    titleColumn={["N°", "Nombre Oferta - Actividad Tercerizada", "Fecha Inicio", "Fecha Fin", "Acción"]}
                    attributes={["NOMBRE_CURSO", "FechaInicio", "FechaFinal"]}
                    endPoint={endPoint}
                    onUpdate={false}
                    onDelete={false}
                    labelOptionClick={"Listar Estudiantes"}
                    handleOptionClick={onNavigateDetails}
                    pagination={pagination}
                    exportar={urlExportar}
                />
            </Card>
        </>
    )
}

export {OfertasTerceros}