
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom"
import { DynamicSearch } from "../../../components/DynamicForm/DynamicSearch/DynamicSearch";
import { DynamicTable } from "../../../components/DynamicForm/dynamicTable/DynamicTable"
import { LocalStorage } from "../../../services"
import { BASE_URL } from "../../../services/api/config";
import { FormService } from "../../../services/api/formService";


function ProgramasFinalizadosList () {

  const Storage = new LocalStorage();
  const idUsuario = Storage.getAsJsonItem("usuario")?.usuario?.IdUsuario || 'localstorage_empty'
  const urlCursos = `cursos/finalizadosRegistro`;
  const urlExportar=`${BASE_URL}/exportar/fomatoOFA?IdPeriodo=1823`
  const navigate = useNavigate();
  const onNavigate = (programa) => {
    navigate(`${programa.IdOferta}/asignaturas`,{state:{programa,idUsuario}});
  }
  const[campos,setCampos]=useState(null);
  const[endPoint,setEndPoint]=useState(urlCursos);
  const api = new FormService();
  const [busqueda,setBusqueda]=useState();
  const [urlExportarActividad, setUrlExportarActividad] = useState(`${BASE_URL}/exportarOfertasFinalizadas`);

  useEffect(() => {
    const fecthData = async () => {
        fetchCamposFormForFilter();
    };
      fecthData();
  }, []);

  const fetchCamposFormForFilter = ()=>{   
    api.getAll(`xcampos?id_formulario=97`)
    .then((res)=>{
        setCampos(res)
    })
  }
  const buscarCursos=(e)=>{
    const nuevoEndPoint=`${urlCursos}${e}`
    setEndPoint(nuevoEndPoint)
    setBusqueda(e)
    setUrlExportarActividad(`${BASE_URL}/exportarOfertasFinalizadas${e}`)
  }


  return (
    <>
    <Card>
     <Card.Title>Actividades Académicas Finalizadas</Card.Title>
    </Card>

    {campos&&
      <DynamicSearch  onSearch={buscarCursos} camposForm={campos} ></DynamicSearch>
    }
    
      <DynamicTable
        titleColumn={['N°','Nombre','Fecha Inicio','Fecha Fin','Acción']}
        attributes={[,'Nombre','FechaInicioCurso','FechaFinCurso']}
        endPoint={endPoint}
        onDelete={false}
        onUpdate={false}
        busqueda={busqueda}
        labelOptionClick={`Ver más`}
        handleOptionClick={onNavigate}
        classNameOptionIcon={`bi bi-view-list`}
        exportar={urlExportarActividad}
      ></DynamicTable>
    </>
  )
}
export { ProgramasFinalizadosList }