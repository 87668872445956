const DataCalendario=  [
    [
    "titulo",
     "PROMOCIÓN Y DIVULGACIÓN DEL PROGRAMA TECNOLOGÍA EN GESTIÓN DE TRÁNSITO ÁEREO",
    ],
    [
    "subtitulo",
     "N°",
     "Actividad",
      "Fecha",
      ""
    ],
    [
    "encabezado",
     1,
     "Inicio de campaña publicitaria del programa.",
      "date",
    ],
    [
    "titulo",
     "LANZAMIENTO DEL PROGRAMA",
    ],
    [
    "encabezado",
     2,
     "Acto Protocolarío de lanzamiento del programa.",
      "date",
    ],
    [
    "titulo",
     "INSCRIPCIONES, SELECCIÓN Y ADMISIÓN",
    ],
    [
    "subtitulo",
    "N°",
     "Actividad",
      "Período 1",
      "Período 2"
    ],
    [
    "actividad",
     3,
     "Publicación: Convocatoria del Programa Tecnología en Gestión de Tránsito Aéreo",
      "date",
      "date",
    ],
    [
    "actividad",
     4,
     "Pago de derechos de inscripción,entrega y verificación de documentación",
      "date",
      "date",
    ],
    [
    "actividad",
     5,
     "Publicación de listado de los aspirantes admitidos a la etapa 1.",
      "date",
      "date",
    ],
    [
    "actividad",
     6,
     "Etapa 1. Presentar prueba psicológica",
      "date",
      "date",
    ],
    [
    "actividad",
     7,
     "Publicación de listado de los aspirantes admitidos a la etapa 2.",
      "date",
      "date",
    ],
    [
    "actividad",
     8,
     "Etapa 2. Prueba de admisión académica interna (Prueba de habilidades y destrezas)",
      "date",
      "date",
    ],
    [
    "actividad",
     9,
     "Publicación de listado de los aspirantes admitidos a la etapa 3.",
      "date",
      "date",
    ],
    [
    "actividad",
     10,
     "Etapa 3. Entrevista con coordinación de Programa",
      "date",
      "date",
    ],
    [
    "actividad",
     11,
     "Publicación de listado de los aspirantes admitidos a la etapa 4.",
      "date",
      "date",
    ],
    [
    "actividad",
     12,
     "Etapa 4. Entrega de examen médico general",
      "date",
      "date",
    ],
    [
    "actividad",
     13,
     "Publicaciónes de resultados de admitidos al Programa Tecnología en Gestión de Transito Áereo",
      "date",
      "date",
    ],
    [
    "actividad",
     14,
     "Proceso de transferencia externa",
      "date",
      "date",
    ],
    [
    "actividad",
     15,
     "Examenes de suficiencia y homologación",
      "date",
      "date",
    ],
    [
    "titulo",
     "MATRÍCULAS Y PAGOS",
    ],
    [
    "subtitulo",
    "N°",
     "Actividad",
      "Período 1",
      "Período 2"
    ],
    [
    "actividad",
     16,
     "Pago de derechos de inscripción",
      "date",
      "date",
    ],
    [
    "actividad",
     17,
     "Liquidación de recibos de pago admitidos",
      "date",
      "date",
    ],
    [
    "actividad",
     18,
     "Pago matrícula ordinaria",
      "date",
      "date",
    ],
    [
    "actividad",
     19,
     "Pago matrícula extemporánea",
      "date",
      "date",
    ],
    [
    "actividad",
     20,
     "Matrícula e Inscripción de asignaturas en el sistema.",
      "date",
      "date",
    ],
    [
        "titulo",
        "PROGRAMACIÓN ACADÉMICA - ADMINISTRATIVA",
    ],
    [
    "subtitulo",
    "N°",
     "Actividad",
      "Período 1",
      "Período 2"
    ],
    [
    "actividad",
     21,
     "Programación calendario académico",
      "date",
      "date",
    ],
    [
    "actividad",
     22,
     "Aprobación calendario académico",
      "date",
      "date",
    ],
    [
    "actividad",
     23,
     "Ingreso en el sistema de la programación académica y estudiantes para período",
      "date",
      "date",
    ],
    [
    "actividad",
     24,
     "Fecha limite para aplazamiento de cupo académico",
      "date",
      "date",
    ],
    [
    "actividad",
     25,
     "Retiro voluntario con reembolso del pago de matrícula",
      "date",
      "date",
    ],
    [
    "actividad",
     26,
     "Plazo máximo cancelación de asignaturas o módulos",
      "date",
      "date",
    ],
    [
    "actividad",
     27,
     "Solicitud de reintegros",
      "date",
      "date",
    ],
    [
    "actividad",
     28,
     "Proceso de evaluación docente",
      "date",
      "date",
    ],
    [
    "titulo",
     "DESARROLLO PERIODO ACADÉMICO",
    ],
    [
    "subtitulo",
    "N°",
     "Actividad",
      "Período 1",
      "Período 2"
    ],
    [
    "actividad",
     29,
     "Inducción estudiantes y nivelación académica",
      "date",
      "date",
    ],
    [
    "actividad",
     30,
     "Iniciación de clases",
      "date",
      "date",
    ],
    [
    "actividad",
     31,
     "Semana universitaria",
      "date",
      "date",
    ],
    [
    "actividad",
     32,
     "Día del Tecnólogo",
      "date",
      "date",
    ],
    [
    "actividad",
     33,
     "Encuentro de Investigación",
      "date",
      "date",
    ],
    [
    "actividad",
     34,
     "Celebración dia Institucional del CEA",
      "date",
      "date",
    ],
    [
    "actividad",
     35,
     "Finalización de clases",
      "date",
      "date",
    ],
    [
    "titulo",
     "ACTIVIDADES DOCENTES",
    ],
    [
    "subtitulo",
    "N°",
     "Actividad",
      "Período 1",
      "Período 2"
    ],
    [
    "actividad",
     36,
     "Primera reunión de docentes",
      "date",
      "date",
    ],
    [
    "actividad",
     37,
     "Inducción / reinducción a docentes",
      "date",
      "date",
    ],
    [
    "actividad",
     38,
     "Celebracion dia del Docente",
      "date",
      "date",
    ],
    [
    "actividad",
     39,
     "Segunda reunión de Docentes",
      "date",
      "date",
    ],
    [
    "actividad",
     40,
     "Reunión final del periodo académico",
      "date",
      "date",
    ],
    [
    "titulo",
     "RECESO ACADÉMICO",
    ],
    [
    "subtitulo",
    "N°",
     "Actividad",
      "Período 1",
      "Período 2"
    ],
    [
    "actividad",
     41,
     "Semana Santa",
      "date",
      "date",
    ],
    [
    "actividad",
     42,
     "Período de receso estudiantil",
      "date",
      "date",
    ],
    [
    "titulo",
     "ELECCIÓN Y REUNIONES DE REPRESENTANTES DE COHORTE",
    ],
    [
    "subtitulo",
    "N°",
     "Actividad",
      "Período 1",
      "Período 2"
    ],
    [
    "actividad",
     43,
     "Campaña y elección de representante por cohorte",
      "date",
      "date",
    ],
    [
    "actividad",
     44,
     "Reunión con representante por cohorte",
      "date",
      "date",
    ],
    [
    "titulo",
    "EVALUACIONES Y REGISTRO DE CALIFICACIONES EN EL SISTEMA",
    ],
    [
    "subtitulo",
    "N°",
     "Actividad",
      "Período 1",
      "Período 2"
    ],
    [
    "actividad",
     45,
     "Evaluación Primer Módulo",
      "date",
      "date",
    ],
    [
    "actividad",
     46,
     "Socialización de notas",
      "date",
      "date",
    ],
    [
    "actividad",
     47,
     "Fecha limite de solicitud de revisión segundo calificador",
      "date",
      "date",
    ],
    [
    "actividad",
     48,
     "Respuesta de la coordinación a la solicitud de segundo calificador",
      "date",
      "date",
    ],
    [
    "actividad",
     49,
     "Evaluación del segundo calificador",
      "date",
      "date",
    ],
    [
    "actividad",
     50,
     "Supletorios del primer módulo",
      "date",
      "date",
    ],
    [
    "actividad",
     51,
     "Registro en el sistema de notas del primer módulo  (Docentes)",
      "date",
      "date",
    ],
    [
    "actividad",
     52,
     "Evaluación Segundo Módulo",
      "date",
      "date",
    ],
    [
    "actividad",
     53,
     "Socialización de notas",
      "date",
      "date",
    ],
    [
    "actividad",
     54,
     "Fecha limite de solicitud de revisión segundo calificador",
      "date",
      "date",
    ],
    [
    "actividad",
     55,
     "Respuesta de la coordinación a la solicitud de segundo calificador",
      "date",
      "date",
    ],
    [
    "actividad",
     56,
     "Evaluación del segundo calificador",
      "date",
      "date",
    ],
    [
    "actividad",
     57,
     "Supletorios del segundo módulo",
      "date",
      "date",
    ],
    [
    "actividad",
     58,
     "Registro en el sistema de notas del segundo módulo  (Docentes)",
      "date",
      "date",
    ],
    [
    "actividad",
     59,
     "Evaluación Tercer  Módulo",
      "date",
      "date",
    ],
    [
    "actividad",
     60,
     "Socialización de notas",
      "date",
      "date",
    ],
    [
    "actividad",
     61,
     "Fecha limite de solicitud de revisión segundo calificador",
      "date",
      "date",
    ],
    [
    "actividad",
     62,
     "Respuesta de la coordinación a la solicitud de segundo calificador",
      "date",
      "date",
    ],
    [
    "actividad",
     63,
     "Evaluación del segundo calificador",
      "date",
      "date",
    ],
    [
    "actividad",
     64,
     "Supletorios del tercero módulo",
      "date",
      "date",
    ],
    [
    "actividad",
     65,
     "Registro en el sistema de notas del tercer módulo  (Docentes)",
      "date",
      "date",
    ],
    [
    "actividad",
     66,
     "Evaluación Cuarto Módulo",
      "date",
      "date",
    ],
    [
    "actividad",
     67,
     "Socialización de notas",
      "date",
      "date",
    ],
    [
    "actividad",
     68,
     "Fecha limite de solicitud de revisión segundo calificador",
      "date",
      "date",
    ],
    [
    "actividad",
     69,
     "Respuesta de la coordinación a la solicitud de segundo calificador",
      "date",
      "date",
    ],
    [
    "actividad",
     70,
     "Evaluación del segundo calificador",
      "date",
      "date",
    ],
    [
    "actividad",
     71,
     "Supletorios del cuarto módulo",
      "date",
      "date",
    ],
    [
    "actividad",
     72,
     "Registro en el sistema de notas del cuarto módulo  (Docentes)",
      "date",
      "date",
    ],
    [
    "actividad",
     73,
     "Habilitaciones",
      "date",
      "date",
    ],
    [
    "actividad",
     74,
     "Fecha limite solicitud de revisión segundo calificador habilitaciones",
      "date",
      "date",
    ],
    [
    "actividad",
     75,
     "Respuesta de la coordinación a la solicitud de segundo calificador habilitaciones",
      "date",
      "date",
    ],
    [
    "actividad",
     76,
     "Evaluación del segundo calificador habilitaciones",
      "date",
      "date",
    ],
    [
    "actividad",
     77,
     "Entrega y registro de notas habilitaciones (Docentes)",
      "date",
      "date",
    ],
    [
    "actividad",
     78,
     "Cierre del período académico",
      "date",
      "date",
    ],
    [
    "titulo",
     "PROGRAMACIÓN CURSOS INTERSEMESTRALES / VACACIONALES",
    ],
    [
    "subtitulo",
    "N°",
     "Actividad",
      "Período 1",
      "Período 2"
    ],
    [
    "actividad",
     79,
     "Inscripciones cursos intersemestralesl / vacacional",
      "date",
      "date",
    ],
    [
    "actividad",
     80,
     "Plazo de pago intersemestral / vacacional",
      "date",
      "date",
    ],
    [
    "actividad",
     81,
     "Registro de asignaturas intersemestrales / vacacional",
      "date",
      "date",
    ],
    [
    "actividad",
     82,
     "Inicio clases intersemestral / vacacional",
      "date",
      "date",
    ],
    [
    "actividad",
     83,
     "Plazo máximo para solicitud abono de pago del intersemestral / vacacional",
      "date",
      "date",
    ],
    [
    "actividad",
     84,
     "Finalización de clases intersemestral / vacacional",
      "date",
      "date",
    ],
    [
    "actividad",
     85,
     "Plazo máximo para el reporte de notas del período intersemestral / vacacional",
      "date",
      "date",
    ],
    [
    "actividad",
     86,
     "Entrega y registro de calificaciones intersemestral / vacacional en secretaría académica (Docentes)",
      "date",
      "date",
    ],
    [
    "actividad",
     87,
     "Cierre académico del período intersemestral / vacacional",
      "date",
      "date",
  ]
]

export default DataCalendario;