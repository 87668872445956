
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import { OfferHeader } from "../../../../components/OfferHeader/OfferHeader"

import { useState } from "react"
import { FormService } from "../../../../services/api/formService"
import { alertaSinBoton } from "../../../../helpers/alertas"
import { Card, Modal } from "react-bootstrap"
import { DynamicTable } from "../../../../components/DynamicForm/dynamicTable/DynamicTable"
import { Button } from "../../../../components/button/button"
import { DynamicForm } from "../../../../components/DynamicForm/DynamicForm"

function Curso160 (){
  const navigate=useNavigate(); 
  
  const { state:{programa} } = useLocation()
  const [modal,setModal] = useState(false)
  console.log(programa.IdDimension)
  
  
  const [loading,setLoading] = useState(false)
  const urlAsignaturas=`cursos/AsignaturasCursoRegistro?IdOferta=${programa.IdOferta}`
  // const [asignaturas,loading2,error2]=useFetchGet()

  const enviarNotificaciones = () => {
    setLoading(true)
    const api = new FormService()
    api.getAll(`gestionInstrumentos/notificarEstudiantes?IdOferta=${programa.IdOferta}`).then(resp=>{
      alertaSinBoton("Se han enviado con exito!",'success','bottom-end',1500)
      setLoading(false)
    }).catch(err=>{
      
      alertaSinBoton("ERROR!",'warning','bottom-end',1500)
      setLoading(false)
    })
  }

  const cerrarModal=()=>{ 
    setTimeout(() => { setModal(false) }, 2000); 
  }
 
  return(
    <>


    <Card>
      <OfferHeader
        offerName={programa.Nombre}
        img={programa.Repositorio}
        oferta={programa}
        IdOferta={programa.IdOferta}
        horas={programa.horas}
        isCursoFinalizado={true}
        offerDescription={programa.Observaciones}
        isFinally={true}
        />
    </Card>

    <Card>
          <Button
            hasButtonCss={false}
            className={'btn btn-outline-primary mr-1'}
            onClick={()=>setModal(true)}
          >
            Asignar Evaluación
          </Button>

    </Card>
    <Card.Title style={{textAlign:"center"}}>Asignaturas </Card.Title>
    {programa.IdDimension==1?
       <DynamicTable
          titleColumn={['N°','Semestre','Descripción', 'Acción']}
          attributes={['IdSemestre','Descripcion']}
          endPoint={urlAsignaturas}
          onDelete={false}
          onUpdate={false}
          labelOptionClick={`Estudiantes`}
          handleOptionClick={(e)=>navigate("estudiantes",{state:{
            IdOferta:programa.IdOferta,
            programa:programa,
            IdMalla:e.IdMalla,
            asignatura:e.Descripcion,
            docentes:e.docentes,
            IdDimension:programa.IdDimension
          }})}
          classNameOptionIcon={`bi bi-view-list`}
      ></DynamicTable> 
    :
    <DynamicTable
        titleColumn={['N°',  'Descripción', 'Acción']}
        attributes={[ 'Descripcion']}
        endPoint={urlAsignaturas}
        onDelete={false}
        onUpdate={false}
        labelOptionClick={`Estudiantes`}
        handleOptionClick={(e)=>navigate("estudiantes",{state:{
          IdOferta:programa.IdOferta,
          programa:programa,
          IdMalla:e.IdMalla,
          asignatura:e.Descripcion,
          docentes:e.docentes,
          IdDimension:programa.IdDimension
        }})}
        classNameOptionIcon={`bi bi-view-list`}
    ></DynamicTable>
  
    }
    
      <Modal show={modal} onHide={()=>setModal(false)}>
        <DynamicForm
            title={"Asignar Evaluaciones"}
            endPointSubmit={'gestionInstrumentos/asignarEvaluacionOferta'}
            isCreate={true}
            extraData={{IdOferta:programa.IdOferta,Reporte:programa.IdDimension==1?"2":"1"}}
            ventanaFormName={'Gestión instrumentos'}
            justDynamicForm={{ flag:true, idForm:programa.IdDimension==1?"168":"121" }}
            onClickCancelBtn={()=>setModal(false)}
            accionMysql={()=>cerrarModal()}
          ></DynamicForm>
      </Modal>
        
    </>
  )
}

export { Curso160 }

