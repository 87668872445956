import { Route, Routes,Navigate} from 'react-router-dom'
import { AspiranteInscripciones } from '../../components/outlet/aspiranteModulo/Inscripciones/AspiranteInscripciones'
import { AspiranteProcesoInscripcion } from '../../components/outlet/aspiranteModulo/Inscripciones/AspiranteProcesoInscripcion'
import { PortafolioAcademico } from '../../components/outlet/aspiranteModulo/portafolioAcademico/PortafolioAcademico'
import { Registration } from '../../components/outlet/estudiante/Registration'
import SeleccionarAsignaturas from '../../components/Student/SeleccionarAsignaturas/SeleccionarAsignaturas'

function Aspirante() {
    return (
        <>
            <Routes>
                <Route
                    path="inscripciones"
                    element={<AspiranteInscripciones />}
                ></Route>
                <Route
                    path="inscripciones/:id"
                    element={<AspiranteProcesoInscripcion />}
                ></Route>
                <Route
                    path="inscripciones/:id/seleccionar_asignaturas"
                    element={<SeleccionarAsignaturas />}
                ></Route>

                <Route
                    path="portafolio_academico"
                    // element={<PortafolioAcademico />}
                    element={<Navigate to="/oferta/1/cursos" replace />}
                >
                </Route>
                <Route
                    path="inscripciones"
                    element={<Registration />}
                ></Route>
            </Routes>
        </>
    )
}
export { Aspirante }
