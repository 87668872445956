import axios from "axios";
import LocalStorage from "../localStorage/LocalStorage";
import authAxios  from "./AuthInterceptor";
import authAxiosFormData  from "./AuthFormDataInterceptor";
import { BASE_URL } from "./config";

import { ACCESS_TOKEN } from "./config";

class FormService  {
    
    async  getAll(endPoint) {
        return authAxios.get(`${BASE_URL}/${endPoint}`, { 
            headers: {
            'Content-Type': 'application/json',
        },})
        .then( response => response.data)        
    }


    async  getById(endPoint, id) {
        return authAxios.get(`${BASE_URL}/${endPoint}/${id}`, { 
            headers: {
            'Content-Type': 'application/json',
        },})
        .then( response => response.data)    
    }

    async create(endPoint, data, isContentTypeFormData='0') {
        console.log('Data enviada: ---->', data)
        
        if(isContentTypeFormData=='0'){
            try{
                const respuesta=await authAxios.post(`${BASE_URL}/${endPoint}`,{ ...data })
                .then( response => response.data) 
                return respuesta
            }catch(error){
                return error.response.request.response
            }
             
            
        }else{
            try{
                const respuesta=await authAxiosFormData.post(`${BASE_URL}/${endPoint}`,{data})
                .then( response => response.data)
                return  respuesta
            }catch (error){
                return error.response.request.response
            }
            
        }  
    };

    async createWithFormData(endPoint, data) {
        const formdata = new FormData()
            try {
             for(let key in data){
                 formdata.append([key],(data[key]))
             }
            const response = await axios({
                method: "post",
                url: `${BASE_URL}/${endPoint}`,
                data: formdata,
                headers: { "Content-Type": "multipart/form-data" },
            });
            return response.data
        } catch(error) {
            console.log(error)
        }
    };
    
    async update(endPoint, data, id,isContentTypeFormData='0'){
        return authAxios.put(`${BASE_URL}/${endPoint}/${id}`, data,{ 
            headers: {
                'Content-Type': isContentTypeFormData=='1'?'multipart/form-data':'application/json',
            },})
            .then( response => response.data)   
    };
        
    async updateWithFormData(endPoint, data,id) {

        const accessToken = localStorage.getItem(ACCESS_TOKEN);

        const formdata = new FormData()
        for(let key in data){
            formdata.append([key],data[key])
        }
            try {
            return axios({
                method: "post",
                url: `${BASE_URL}/${endPoint}/${id}?_method=PUT`,
                data: formdata,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${accessToken}`, // Agregar el token al encabezado
                },
            }).then( response => response.data);
        } catch(error) {
            console.log(error)
        }
    };

    async delete(endPoint, data, id,isContentTypeFormData='0'){
        return authAxios.put(`${BASE_URL}/${endPoint}/${id}`, data,{ 
            headers: {
            'Content-Type': 'application/json',
        },})
        .then( response => response.data) 
    }; 
}

export { FormService }