import React, { useState } from 'react'
import { useEffect } from 'react'
import { Button, Card, Modal, Table } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { TextArea } from '../../../components/componentsForm/textarea/textarea'
import { alerta } from '../../../helpers/alertas'
import { FormService } from '../../../services/api/formService'

export default function AsignaturasSolicitadas() {

    const api=new FormService()
    const {state:{IdOferta,IdEstudiante}}=useLocation()
    const urlAsignaturas=`educacionIES/asignaturasSolicitadas?IdEstudiante=${IdEstudiante}`
    const [asignaturas,setAsignaturas]=useState([]);
    const [modalAceptar,setModalAceptar]=useState();
    const [modalVincular,setModalVincular]=useState();
    const [modalRechazar,setModalRechazar]=useState();
    const [motivoRechazo,setMotivoRechazo]=useState(null);
    const [malla,setMalla]=useState();

    const consultarAsignaturas=async()=>{
        const asignaturas= await api.getAll(urlAsignaturas)
        setAsignaturas(asignaturas)
    }

    useEffect(()=>{
        const fetchData=async()=>{
            consultarAsignaturas()
        }

        fetchData()

    },[])


    const vincularAsignatura=async()=>{
        const data={
            "IdMallaAspirante":malla.IdMallaAspirante
        }
        const respuesta=await api.create("educacionIES/vincularAsginatura",data);
        if(respuesta.respuesta="exito"){
            alerta(respuesta.mensaje)
            consultarAsignaturas()
            setModalVincular()
        }else{
            alerta("Fallo desconocido")
        }

    }

    const aceptarAsignatura=async()=>{
        const data={
            "IdMallaAspirante":malla.IdMallaAspirante
        }
        const respuesta=await api.create("educacionIES/aprobarAsignatura",data);
        if(respuesta.respuesta="exito"){
            alerta(respuesta.mensaje)
            consultarAsignaturas()
            setModalAceptar()
        }else{
            alerta("Fallo desconocido")
        }
    }

    const rechazarAsignatura=async()=>{

        if(motivoRechazo==null){
            alerta("Escribe el motivo del rechazo")
        }
        const data={
            "IdMallaAspirante":malla.IdMallaAspirante,
            "motivoRechazo":motivoRechazo
        }
        const respuesta=await api.create("educacionIES/rechazarAsignatura",data);
        if(respuesta.respuesta="exito"){
            alerta(respuesta.mensaje)
            consultarAsignaturas()
            setModalRechazar()
        }else{
            alerta("Fallo desconocido")
        }
    }

  return (
    <>
        <Card>
            <Card.Title>
                Asignaturas solicitadas
            </Card.Title>
        </Card>

        <Card>
        <Table>
                <thead>
                    <tr>
                        <th>Asignatura</th>
                        <th>Creditos</th>
                        <th>Peso</th>
                        <th>Estado</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                {asignaturas.map((asignatura,key)=>
                    <tr key={key}>
                        <td>{asignatura.Descripcion}</td>
                        <td>{asignatura.Creditos}</td>
                        <td>{asignatura.Peso}</td>
                        <td>
                            {asignatura.Aprobada==0&&
                                <>
                                    Rechazada
                                </>
                            }
                            {asignatura.Aprobada==3&&
                                <>
                                Solicitada
                                </>
                            }
                            {asignatura.Aprobada==1&&
                                <>
                                    Vinculada
                                </>
                            }
                            {asignatura.Aprobada==2&&
                                <>
                                {asignatura.EstadoPago==null&&
                                    <>
                                    Pendiente de pago 
                                    </>
                                }
                                {asignatura.EstadoPago==0&&
                                    <>Pago en revisión</>
                                }
                                {asignatura.EstadoPago==1&&
                                    <>
                                    Pago aceptado
                                    </>
                                }
                                {asignatura.EstadoPago==2&&
                                    <>
                                        Pago rechazado
                                    </>
                                }
                                </>
                            }
                        </td>
                        <td>
                            {asignatura.Aprobada==3&&
                                <>
                                    <Button
                                        style={{marginRight:10}}
                                        variant='danger'
                                        onClick={()=>{
                                            setModalRechazar(true)
                                            setMalla(asignatura)
                                        }}
                                    >
                                        Rechazar
                                    </Button>
                                    <Button
                                        onClick={()=>{
                                            setModalAceptar(true)
                                            setMalla(asignatura)
                                        }}
                                    >
                                        Aprobar 
                                    </Button>
                                </>
                            }
                            {asignatura.Aprobada==2&&
                                <>
                                {asignatura.EstadoPago==1&&
                                    <>
                                    <Button 
                                        onClick={()=>{
                                            setModalVincular(true)
                                            setMalla(asignatura)
                                        }}
                                    >
                                        Vincular
                                    </Button>
                                    
                                    </>
                                }

                                </>
                            }
                        </td>
                    </tr>
                )}
                </tbody>
            </Table>
        </Card>
        <Modal
            show={modalVincular}
            onHide={()=>setModalVincular()}
        >
            <Modal.Body>
              ¿ Desea vincular esta asignatura a las asignatura del estudiante ?
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant='secondary'
                    onClick={()=>setModalVincular()}
                >
                    Cancelar
                </Button>
                <Button
                    onClick={()=>vincularAsignatura()}
                >
                    Aceptar
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal
            show={modalAceptar}
            onHide={()=>setModalAceptar()}
        >
            <Modal.Body>
                ¿ Desea aceptar esta asignatura para que el estudiante puede realizar el pago ?
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant='secondary'
                    onClick={()=>setModalAceptar()}
                >
                    Cancelar
                </Button>
                <Button
                    onClick={()=>aceptarAsignatura()}
                >
                    Aceptar
                </Button>
            </Modal.Footer>
        </Modal>
        
        <Modal
            show={modalRechazar}
            onHide={()=>setModalRechazar()}
        >
            <Modal.Body>
                ¿ Desea rechazar esta asignatura ?
                <TextArea 
                    label="Motivo del rechazo"
                    value={motivoRechazo}
                    onChange={()=>setMotivoRechazo()}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant='secondary'
                    onClick={()=>setModalRechazar()}
                >
                    Cancelar
                </Button>
                <Button
                variant='danger'
                    onClick={()=>rechazarAsignatura()}
                >
                    Aceptar
                </Button>
            </Modal.Footer>
        </Modal>
    </>
  )
}
