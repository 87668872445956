import React, { useEffect, useState } from 'react';

import { TextArea } from '../../../../components/componentsForm/textarea/textarea';
import { Button } from '../../../../components/button/button';
import { Input } from '../../../../components/input/input';
import { ButtonsContainer } from '../../../../components/componentsForm/buttonsContainer/buttonsContainer';
import { LeftButtonContainer } from '../../../../components/componentsForm/leftButtonContainer/leftButtonContainer';
import { RightButtonContainer } from '../../../../components/componentsForm/rightButtonContainer/rightButtonContainer';
import { FormContainer } from '../../../../components/componentsForm/formContainer/FormContainer';
import { AddButton } from '../../../../components/componentsForm/addButton/addButton';

import ApiDashboard from '../../../../services/api/dashboard';
import { alertaSinBoton, alertaTimer } from '../../../../helpers/alertas';
import { Modal } from '../../../../components/modal/Modal';
import { Table } from '../../../../components/componentsForm/table/Table';
import { DynamicForm } from '../../../../components/DynamicForm/DynamicForm';
import { onReset } from '../../../../components/DynamicForm/FormSlice';
import { useNavigate } from 'react-router-dom';


// modal type : create-delete-update

const MenuInstrumentos = () => {
    const [nombre, setNombre] = useState('');
    const [fecha, setFecha] = useState('');
    const [endPoint, setEndPoint] = useState('');
    const [llavePrimaria, setLlavePrimaria] = useState('');
    const [formdata, setFormdata] = useState('');
    const [nombreForm, setNombreForm] = useState('');
    const [permisos, setPermisos] = useState('');

    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [isOpenModalCreate, setIsOpenModalCreate] = useState(false);
    const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
    const [isOpenModalUpdate, setIsOpenModalUpdate] = useState(false);
    const [isOpenModalView, setIsOpenModalView] = useState(false);

    const [itemSelected, setItemSelected] = useState({});
    const [dataTable, setDataTable] = useState([]);
    const [modalType, setModalType] = useState('');
    const endPointLastUrl = 'selects/formularios'; /// url for service

    const navigate = useNavigate();

    //! LOAD_DATA_API */
    useEffect(() => {
        const fecthData = async () => {
            setLoading(true);
            await fetchDataTable();
            setLoading(false);
        };
        fecthData();
    }, []);

    //! SUBMIT CREATE OR UPDATE
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if (modalType === '/create') {
            createService();
        }
        if (modalType === '/update') {
            updateService();
        }

        setLoading(false);

        // setItemSelected({})
    };

    //! VALIDACION DE TOKEN
    const fetchDataTable = async () => {
        try {
            const api = new ApiDashboard();
            const data = await api.getData(endPointLastUrl);
            if (!!data.length) setDataTable(data);
            if (data?.status === 'Token is Expired') {
                alertaTimer('La sesion ha expirado', 'info', '4000');
                console.log('data exp', data);
            }
            console.log('DATA ---->', data);
        } catch (e) {
            console.log('#Error fetching dataTable');
            //  setDataTable([])
        }
    };

    //! LIMPIAR FORMULARIO
    const cleanFormValues = () => {
        setNombre('');
    };

    //! CLICK EVENTS
    const onCreate = () => {
        cleanFormValues();
        setIsOpenModalCreate(true);
        setModalType('/create');
    };

    const onDelete = (item) => {
        setItemSelected(item);
        console.log(item, 'on delete selected:');
        setIsOpenModalDelete(true);
        setModalType('/delete');
    };
    const onUpdate = (item) => {
        setItemSelected(item);
        console.log('update item->', item);
        //setDescription(item.Descripcion);
        setModalType('/update');
        setIsOpenModalUpdate(true);
    };

    const onView = (item) => {

        const ampliar = () => {
            navigate(`${item.id}`, { state: { id: item.id, title: item.nombre } });
        };
        ampliar();
/* 
        setIsOpenModalView(true);
        setItemSelected(item);
        console.log('view item->', item.id);
        setModalType('/view'); */

        
    };

    //!CLOSE ALL MODALES
    const handleCloseModal = () => {
        setIsOpenModalCreate(false);
        setIsOpenModalUpdate(false);
        setIsOpenModalDelete(false);
        setIsOpenModalView(false);
    };

    //! SERVICE CREATE
    const createService = async () => {
        try {
            const api = new ApiDashboard();
            const data = await api.createData(
                {
                    /* Descripcion: description,
                    Numero: numero, */
                    Estado: 1,
                },
                endPointLastUrl
            );
            console.log('data create', data);
            if (!!data.length) await setDataTable(data);
            if (data?.status === 'Token is Expired') {
                alertaTimer('La sesión ha expirado', 'info', '4000');
                console.log('data exp', data);
            }
            setIsOpenModalCreate(false);
            alertaSinBoton(
                'Se ha creado con éxito',
                'success',
                'center',
                '2000'
            );
            fetchDataTable();
            cleanFormValues();
        } catch (e) {}
    };

    //! SERVICE DELETE
    const deleteService = async () => {
        try {
            console.log('datain delete serivce exp', itemSelected);
            const api = new ApiDashboard();
            const data = await api.deleteData(
                {
                    Estado: 0,
                },
                itemSelected.IdEstracto,
                endPointLastUrl
            );
            // console.log("data response delete",data,itemSelected.IdPaises)
            if (!!data.length) setDataTable(data);
            if (data?.status === 'Token is Expired') {
                alertaTimer('La sesion ha expirado', 'info', '4000');
                // console.log("data exp",data)
            }
            setIsOpenModalCreate(false);
            handleCloseModal();
            alertaSinBoton(
                'Se ha eliminado con Exito',
                'success',
                'center',
                '2000'
            );

            fetchDataTable();
            cleanFormValues();
            setItemSelected({});
        } catch (e) {
            console.log('error delete');
        }
    };

    //! SERVICE UPDATE
    const updateService = async () => {
        try {
            const api = new ApiDashboard();
            const data = await api.updateData(
                {
                    /* Descripcion: description,
                    Numero: numero, */
                },
                itemSelected.IdEstracto,
                endPointLastUrl
            );
            if (!!data.length) setDataTable(data);
            if (data?.status === 'Token is Expired') {
                alertaTimer('La sesion ha expirado', 'info', '4000');
                console.log('data exp', data);
            }
            setIsOpenModalUpdate(false);
            alertaSinBoton(
                'Se ha actualizado con Exito',
                'success',
                'center',
                '2000'
            );
            fetchDataTable();
            setItemSelected({});
        } catch (error) {}
    };

    //!FORM
    const Form = () => {
        return (
            <>
                {/* ***FORM CREATE AND UPDATE**** */}
                <form onSubmit={handleSubmit}>
                    <div className="container">
                        <div className="row">
                            <Input
                                id="floatingTextarea"
                                label="Descripción"
                                value={nombre}
                                onChange={setNombre}
                                type="text"
                                required={true}
                            />

                            {/*                             <Input
                                label="Fecha"
                                type="date"
                                onChange={setFecha}
                                value={fecha}
                                required={false}
                            /> */}

                            <Input
                                label="Endpoint"
                                type="text"
                                onChange={setEndPoint}
                                value={endPoint}
                                required={true}
                            />

                            <Input
                                label="Llave Primaria"
                                type="text"
                                onChange={setLlavePrimaria}
                                value={llavePrimaria || ''}
                                required={false}
                            />

                            <Input
                                label="Form Data"
                                type="number"
                                onChange={setFormdata}
                                value={formdata || ''}
                                required={false}
                            />

                            <Input
                                label="Nombre Form"
                                type="text"
                                onChange={setNombreForm}
                                value={nombreForm || ''}
                                required={false}
                            />

                            <Input
                                label="Permisos"
                                type="number"
                                onChange={setPermisos}
                                value={permisos || ''}
                                required={false}
                            />
                        </div>
                    </div>
                    <ButtonsContainer>
                        <LeftButtonContainer>
                            <Button type="back" onClick={handleCloseModal}>
                                Cerrar
                            </Button>
                        </LeftButtonContainer>
                        <RightButtonContainer>
                            {modalType === '/update' && (
                                <Button type="send" loading={loading}>
                                    Actualizar
                                </Button>
                            )}

                            {modalType === '/create' && (
                                <Button type="send" loading={loading}>
                                    Crear
                                </Button>
                            )}
                        </RightButtonContainer>
                    </ButtonsContainer>
                </form>
            </>
        );
    };

    const FormView = () => {
        console.log('item selected', itemSelected);

        

        return (
            <>
                {/* <DynamicForm
                    parentField={null}
                    ventanaFormName={''}
                    title={itemSelected.nombre}
                    endPointSubmit={'instrumento'}
                    keySubmit={'k'}
                    isContentTypeFormData={'0'}
                    isCreate={true}
                    justDynamicForm={{ flag: false, idForm: itemSelected.id }}
                ></DynamicForm> */}

                <ButtonsContainer>
                    <LeftButtonContainer>
                        <Button type="back" onClick={handleCloseModal}>
                            Cerrar
                        </Button>
                    </LeftButtonContainer>
                    {/* <RightButtonContainer>
                        {modalType === '/update' && (
                            <Button type="send" loading={loading}>
                                Actualizar
                            </Button>
                        )}

                        {modalType === '/create' && (
                            <Button type="send" loading={loading}>
                                Crear
                            </Button>
                        )}
                    </RightButtonContainer> */}
                </ButtonsContainer>
            </>
        );
    };

    return (
        // MAIN CONTAINER
        
        <FormContainer progress="45" style={{ width: '60%' }}>
            {/* ***CREATE BUTTON**** */}
            {/*  <AddButton
                buttonTitle='Agregar Instrumento de evaluación'
                createModal={onCreate}
                // formTitleValue='Grupo'
                /> */}
            <h1 className='text-center mt-3 mb-3'>Instrumentos de Evaluación</h1>
            {/* TABLE */}
            <Table
                titleColumn={['Id', 'Nombre', 'Acciones']}
                data={dataTable}
                attributes={['nombre']} //codigo
                //onDelete={onDelete}
               // onUpdate={onUpdate}
                isDetails={false}
                titleThirdBtn={'Ver Detalles'}
                onThirdBtn={onView}
                iconTitleThirdBtn="bi-file-earmark-spreadsheet"
            ></Table>

            {/* ****** MODALES ******  */}

            <Modal isOpen={isOpenModalCreate}>{Form()}</Modal>
            <Modal isOpen={isOpenModalUpdate}>{Form()}</Modal>
            <Modal isOpen={isOpenModalView}>{FormView()}</Modal>

            <Modal isOpen={isOpenModalDelete}>
                <h1 style={{ marginBottom: '4rem' }}>
                    Estas seguro que deseas eliminar "
                    {itemSelected.Descripcion || ''}" ?{' '}
                </h1>
                <button onClick={deleteService} className="btn btn-danger">
                    Si
                </button>
                <button
                    style={{ marginLeft: '2rem' }}
                    onClick={handleCloseModal}
                    className="btn btn-secondary"
                >
                    No
                </button>
            </Modal>
        </FormContainer>
       
    );
};

export { MenuInstrumentos };
