import React from 'react'
import { Card } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { LocalStorage } from '../../../services'
import { DynamicTable } from '../../DynamicForm/dynamicTable/DynamicTable'
import { useState } from 'react'
import { BASE_URL } from '../../../services/api/config'
import { DynamicSearch } from '../../DynamicForm/DynamicSearch/DynamicSearch'
import { FormService } from '../../../services/api/formService'
import { useEffect } from 'react'

const ActiveOffers = ({full}) => {
    
    const ls = new LocalStorage()
    const IdUsuario = ls.getAsJsonItem('usuario').usuario.IdUsuario
    const dimension = ls.getItem('dimension')
    const[campos,setCampos] = useState();
    const [endPoint, setEndPoint] = useState(full?`cursos/activosFull?IdUsuario${IdUsuario}`:`cursos/inscripciones?IdUsuario=${IdUsuario}`)
    const [urlExportar, setUrlExportar] = useState(`${BASE_URL}/exportarInscripciones`)
    const api = new FormService()

    const buscarCursos=(e)=>{
        const nuevoEndPoint = full?`cursos/activosFull${e}IdUsuario${IdUsuario}`:`cursos/inscripciones${e}IdUsuario=${IdUsuario}`
        setEndPoint(nuevoEndPoint)
        /* setBusqueda(e) */
        setUrlExportar(`${BASE_URL}/exportarInscripciones${e}`)
    }
  
    const fetchCamposFormForFilter = async ()=>{  
        const camp = await api.getAll(`xcampos?id_formulario=118`)
        .then((res)=>{
            setCampos(res)
        })
    }
    
    const navigate = useNavigate()

    const titleColumnsTable = [
        'N°',
        'Actividad',
        'Coordinador de Actividad',
        'Email coordinador',
        'Cupos',
        'Acción'
    ]
    // titulos para ies
    if (dimension === '"true"') {
        titleColumnsTable[1] = 'Programa'
        titleColumnsTable[2] = 'Coordinador de Programa'
    }

    const attributes = [
        'Nombre',
        'Cordinador',
        'email',
        'cupos',
    ]

    const onNavigateDetails = (item) => {
        navigate(
            `lista-aspirante/${item.IdOferta}`, 
            { state: { offer: item } }
        ) //to ApplicantList.jsx
    }
    
    useEffect(() => {
        fetchCamposFormForFilter()
    }, [])

    return (
        <>
            <Card>
                <Card.Title>Inscripciones</Card.Title>
            </Card>
            {campos&&
                <DynamicSearch  onSearch={buscarCursos} camposForm={campos} ></DynamicSearch>
            }
            <DynamicTable
                exportar={urlExportar}
                titleColumn={ titleColumnsTable }
                attributes={ attributes }
                endPoint={endPoint}
                onDelete={ false }
                onUpdate={ false }
                labelOptionClick={ 'Lista de aspirantes' }
                handleOptionClick={ onNavigateDetails }
                classNameOptionIcon='bi bi-view-list'
            />
        </>
    )
}

export { ActiveOffers }