import axios from "axios";

async function ApiPasarela(price,description,email){
  
  const url_apify = 'https://apify.epayco.co'
  let jwt_token='empty'
  // let jwt_token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJhcGlmeWVQYXljb0pXVCIsInN1YiI6NDk5NjA0LCJpYXQiOjE2NTY1MDUzMDUsImV4cCI6MTY1NjUwODkwNSwicmFuZCI6IjA4NTk3NThkMzNkNzdjYTJjZGMwMmI2ODQ2MTNmZDUxOTQxOSIsInJlcyI6ZmFsc2UsImluYSI6ZmFsc2UsImd1aSI6bnVsbH0.MMX9z273merUh64m2RO800A49uRjGjstCWF69NNhFr8'
  const data = await axios.post('https://apify.epayco.co/login',{
    Username:window.btoa('9c4e7500d0c44f1acf14cd573af25255').toString('base64'),
    Password:window.btoa('6d00dee4b43a02f49aa47249551e3aa9').toString('base64'),
    public_key:window.btoa('9c4e7500d0c44f1acf14cd573af25255').toString('base64'),
  },{
    headers:{'Authorization':'Basic '+ window.btoa('9c4e7500d0c44f1acf14cd573af25255:6d00dee4b43a02f49aa47249551e3aa9').toString('base64')}
  }
  ).then(res=>res.data)
    
  // console.log("dataaa",data)
    
  jwt_token = data?.token
  // console.log(data) 
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${jwt_token}`);

var raw = JSON.stringify({
  "quantity": 1,
  "onePayment": true,
  "amount": price,
  "currency": "COP",
  "id": 0,
  "base": "0",
  "description": description,
  "title": `${description.toUpperCase()} `,
  "typeSell": "1",
  "tax": "0",
  "email": email
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

 return fetch(`${url_apify}/collection/link/create`, requestOptions).then(res => res.json())
//  t
    // .then(response => response.json()).catch((error) => {}).finally(() => {});
  

 
}

export { ApiPasarela }