import React, { useState } from 'react'
import { Button, Card, Col, Modal, Row, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../services/api/config';
import { InputFile } from '../../components/inputFile/inputFile';
import { FormService } from '../../services/api/formService';
import { alerta } from '../../helpers/alertas';
import DowloadFileText from '../../components/DowloadFileText/DowloadFileText'
import { Select } from '../../components/select/Select';
import { TextArea } from '../../components/componentsForm/textarea/textarea';
import { AiOutlineDownload } from 'react-icons/ai';


export default function PlanTrabajo() {
    const navigate = useNavigate();
    const api=new FormService();
    const location = useLocation();
    const curso=location.state.curso;
    const asigantura=location.state.asignatura;
    const IdUsuario=location.state.IdUsuario;
    const [malla, setMalla] = useState()
    const [apruebaSyllabus, setApruebaSyllabus] = useState()
    const [apruebaMicro, setApruebaMicro] = useState()
    const [apruebaAsignatura, setApruebaAsignatura] = useState()
    const [observacionSyllabus, setObservacionSyllabus] = useState()
    const [observacionMicro, setObservacionMicro] = useState()
    const [observacionAsignatura, setObservacionAsignatura] = useState()
    const [modalSyllabus, setModalSyllabus] = useState()
    const [modalMicrocurriculo, setModalMicrocurriculo] = useState()
    const [modalAsignatura, setModalAsignatura] = useState()
    const [usuarioDocente, setUsuarioDocente] = useState()
    
    const urlArchivo=`${BASE_URL}/descargar/planAcademico`
    const urlArchivoSyllabus=`${BASE_URL}/exportar/formatosIES/syllabus?IdAsignatura=${malla?.IdAsignatura}&IdOferta=${curso.IdOferta}&IdUsuario=${usuarioDocente}`
    const urlArchivoAsignatura=`${BASE_URL}/planAsignatura?IdAsignatura=${malla?.IdAsignatura}&IdOferta=${curso.IdOferta}&IdUsuario=${usuarioDocente}`

    const urlArchivoMicrocurriculo=`${BASE_URL}/exportar/formatosIES/formatoMicrocurriculo?IdAsignatura=${malla?.IdAsignatura}&IdOferta=${curso.IdOferta}&IdUsuario=${usuarioDocente}`
    
    const urlArchivoPlandeTrabajo=`${BASE_URL}/descargar/plandeTrabajoDocente`
    const urlArchivoGuia=`${BASE_URL}/descargar/planAcademicoGuia`
    const [modal,setModal]=useState();
    const [modalPlanTrabajo, setModalPlanTrabajo]=useState();
    const [documento,setDocumento]=useState(null)
    const [documentoPlanTrabajo, setDocumentoPlanTrabajo]= useState(null)
    const [documentoSyllabus, setDocumentoSyllabus]= useState(null)
    const [documentoMicrocurriculo, setDocumentoMicrocurriculo]= useState(null)
    const nameFile = "FormatoSyllabus.pdf"
    const nameFileM = "Microcurriculo.pdf"
    const nameFileA = "Plan Académico de Asignatura.pdf"

    const getMalla = async () => {
        const asignatura = await api.getById("malla", asigantura.IdMalla)
        setMalla(asignatura)
    }
    
    const addEstadoPlanSyllabus = async () => {
        const respuesta = await api.update("planAsignaturaSyllabus", {"Aprobado": apruebaSyllabus, "ObservacionAprobado": observacionSyllabus, "IdAsignatura": malla?.IdAsignatura}, malla?.IdAsignatura)
        if(respuesta.respuesta == "exito") {
            alerta("Éxito", "success")
        } else {
            alerta(respuesta.mensaje, "warning")
        }
        setModalSyllabus(false)
    }
    
    const addEstadoPlanMicro = async () => {
        const respuesta = await api.update("planAcademicoMicrocurriculo", {"Aprobado": apruebaMicro, "ObservacionAprobado": observacionMicro, "IdAsignatura": malla?.IdAsignatura}, malla?.IdAsignatura)
        if(respuesta.respuesta == "exito") {
            alerta("Éxito", "success")
        } else {
            alerta(respuesta.mensaje, "warning")
        }
        setModalMicrocurriculo(false)
    }
    
    const addEstadoAsignatura = async () => {
        const respuesta = await api.update("PlandeAsignatura", {"Aprobado": apruebaAsignatura, "ObservacionAprobado": observacionAsignatura, "IdMalla": asigantura?.IdMalla}, asigantura?.IdMalla)
        if(respuesta.respuesta == "exito") {
            alerta("Éxito", "success")
        } else {
            alerta(respuesta.mensaje, "warning")
        }
        setModalAsignatura(false)
    }
    
    const planeacionAcademica = (asignatura) => {
        navigate(`${asignatura.IdMalla}/plan_academico`, {
            state: {
                programa: curso,
                asignatura,
                IdUsuario: IdUsuario,
            },
        }) 
    }
    const guardarPlan = async()=>{
        const data={
            "Documento":documento,
            "IdUsuario":asigantura.IdUsuario,
            "IdMalla":asigantura.IdMalla,
            "IdUnidadAsignatura":asigantura.IdUnidadAsignatura,
            "IdOferta":curso.IdOferta
        }
        const respuesta=await api.createWithFormData("docente/guardarPlanAsignatura",data)

        if(respuesta.respuesta=="exito"){
            alerta(respuesta.mensaje)
            setModal()
        }else{
            alerta("Fallo desconocido")
        }
    }
    
    const guardarPlanTrabajo = async()=>{
        const data={
            "DocumentoPlanTrabajo": documentoPlanTrabajo,
            "DocumentoSyllabus": documentoSyllabus,
            "DocumentoMicrocurriculo": documentoMicrocurriculo,
            "IdUsuario":asigantura.IdUsuario,
            "IdMalla":asigantura.IdMalla,
            "IdUnidadAsignatura":asigantura.IdUnidadAsignatura,
            "IdOferta":curso.IdOferta
        }
        const respuesta=await api.createWithFormData("docente/guardarPlandeTrabajo", data)

        if(respuesta.respuesta=="exito"){
            alerta(respuesta.mensaje)
            setModal()
        }else{
            alerta("Fallo desconocido")
        }
    }

  return (
    <>
    {
        console.log(asigantura)
    }
        <Card>
            <Card.Title>Plan de Trabajo</Card.Title>
        </Card>

        {/* menu de tres botones */}
        <Row>
            {curso.IdDimension==1 &&
            <Col>
                <Card
                    onClick={() => {
                        setModalSyllabus(true)
                        getMalla()
                    }}
                >
                    <h6 style={{cursor: 'pointer'}}>Plan Académico - Syllabus</h6>
                </Card>
            </Col>
            }
            {curso.IdDimension==1 &&
            <Col>
                <Card
                    onClick={() => {
                        setModalMicrocurriculo(true)
                        getMalla()
                    }}
                >
                    <h6 style={{cursor: 'pointer'}}>Plan Académico - Microcurrículo</h6>
                </Card>
            </Col>
            }
            {curso.IdDimension!=1&&
            <Col>
                <Card 
                    style={{cursor:"pointer"}}
                    onClick={()=>{
                        setModalAsignatura(true)
                    }}
                >
                    <h6 style={{cursor: 'pointer'}}>Plan Académico de Asignatura</h6>
                </Card>
            </Col>
            }
        </Row>

    {/* modales */}
        <Modal 
            show={modal}
            onHide={()=>setModal()}
        >
            <Modal.Header closeButton>
                <Modal.Title>Plan de Asignatura</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>1. <DowloadFileText
                        nameFile={"Plan de Asignatura.xlsx"}
                        name={" Descargar la plantilla"}
                        icon={<AiOutlineDownload />}
                        endPoint={urlArchivo}
                    />del plan de Asignatura</p>
                <p>2. <DowloadFileText
                        nameFile={"Guia Plan de Asignatura.pdf"}
                        name={" Descargar Guia"}
                        icon={<AiOutlineDownload />}
                        endPoint={urlArchivoGuia}
                    />de plan de Asignatura</p>
                <p>3. Diligenciar los datos requeridos</p>
                <p>4. Adjuntar el documento plan de asignatura diligenciado a la plataforma mediante la siguiente opción.</p>
                <InputFile 
                    label={`Adjuntar documento`}
                    onChange={(e)=>setDocumento(e[0])}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant='secondary'
                    onClick={()=>setModal()}
                >Cancelar</Button>
                <Button
                    onClick={()=>guardarPlan()}
                >
                    Aceptar
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal 
            show={modalPlanTrabajo}
            onHide={()=>setModalPlanTrabajo()}
        >
            <Modal.Header closeButton>
                <Modal.Title>Plan de Trabajo Docente</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* <p>1. <a target="_blank" href={urlArchivoPlandeTrabajo}>Descargar la plantilla </a>del Plan de Trabajo Docente</p> */}
                <p>1. 
                    <DowloadFileText
                        nameFile={nameFile}
                        name={"Descargar formato Syllabus"}
                        icon={<AiOutlineDownload />}
                        endPoint={urlArchivoSyllabus}
                    />
                </p>
                <p>2. 
                    <DowloadFileText
                        nameFile={nameFileM}
                        name={"Descargar formato Microcurriculo"}
                        icon={<AiOutlineDownload />}
                        endPoint={urlArchivoMicrocurriculo}
                    />
                </p>
                <p>3. Diligenciar los datos requeridos</p>

                <p>4. Adjuntar el documento formato syllabus diligenciado a la plataforma mediante la siguiente opción.</p>
                <InputFile 
                    label={`Adjuntar documento`}
                    onChange={(e)=>setDocumentoSyllabus(e[0])}
                />
                
                <br />
                <p>5. Adjuntar el documento formato microcurrículo diligenciado a la plataforma mediante la siguiente opción.</p>
                <InputFile 
                    label={`Adjuntar documento`}
                    onChange={(e)=>setDocumentoMicrocurriculo(e[0])}
                />
                <br />
                <p>6. Adjuntar Otro documento a la plataforma mediante la siguiente opción.</p>
                <InputFile 
                    label={`Adjuntar documento`}
                    onChange={(e)=>setDocumentoPlanTrabajo(e[0])}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant='secondary'
                    onClick={()=>setModal()}
                >Cancelar</Button>
                <Button
                    onClick={()=>guardarPlanTrabajo()}
                >
                    Aceptar
                </Button>
            </Modal.Footer>
        </Modal>
        
        <Modal
            show={modalSyllabus}
            onHide={setModalSyllabus}
        >
            <ModalHeader closeButton={true}>
                <h2>Plan Académico Docente - Syllabus</h2>
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Select
                        label={"Docente"}
                        id="usuarioDocente"
                        name="usuarioDocente"
                        optionValue={"IdUsuario"}
                        optionName={"Docente"}
                        urlOptionsService={`docentes/asignatura?IdOferta=${curso.IdOferta}&IdMalla=${asigantura.IdMalla}`}
                        required={true}
                        value={usuarioDocente}
                        onChange={(e) => setUsuarioDocente(e)}
                    />
                    {usuarioDocente &&
                    <DowloadFileText
                        nameFile={nameFile}
                        name={" Descargar Formato Syllabus"}
                        endPoint={urlArchivoSyllabus}
                    />
                    }
                    {usuarioDocente &&
                    <Select
                        label={"¿Aprueba Plan de Trabajo?"}
                        id="Aprobado"
                        name="Aprobado"
                        optionValue={"Id"}
                        optionName={"Nombre"}
                        options={[
                            {"Id": "1", "Nombre": "Sí"},
                            {"Id": "0", "Nombre": "No"}
                        ]}
                        required={true}
                        value={apruebaSyllabus}
                        onChange={(e) => setApruebaSyllabus(e)}
                    />
                    }
                    {apruebaSyllabus == 0 &&                        
                    <TextArea
                        label={"Observación"}
                        name={"Observacion"}
                        id={"Observacion"}
                        type={"textarea"}
                        value={observacionSyllabus}
                        onChange={setObservacionSyllabus}
                    />
                    }
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={addEstadoPlanSyllabus}
                >
                    Guardar
                </Button>
            </ModalFooter>
        </Modal>
        
        <Modal
            show={modalMicrocurriculo}
            onHide={setModalMicrocurriculo}
        >
            <ModalHeader closeButton={true}>
                <h2>Plan Académico Docente - Microcurrículo</h2>
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Select
                        label={"Docente"}
                        id="usuarioDocente"
                        name="usuarioDocente"
                        optionValue={"IdUsuario"}
                        optionName={"Docente"}
                        urlOptionsService={`docentes/asignatura?IdOferta=${curso.IdOferta}&IdMalla=${asigantura.IdMalla}`}
                        required={true}
                        value={usuarioDocente}
                        onChange={(e) => setUsuarioDocente(e)}
                    />
                    {usuarioDocente &&
                    <DowloadFileText
                        nameFile={nameFileM}
                        name={" Descargar formato Microcurrículo"}
                        endPoint={urlArchivoMicrocurriculo}
                    />
                    }
                    {usuarioDocente &&
                    <Select
                        label={"¿Aprueba Plan de Trabajo?"}
                        id="Aprobado"
                        name="Aprobado"
                        optionValue={"Id"}
                        optionName={"Nombre"}
                        options={[
                            {"Id": "1", "Nombre": "Sí"},
                            {"Id": "0", "Nombre": "No"}
                        ]}
                        required={true}
                        value={apruebaMicro}
                        onChange={(e) => setApruebaMicro(e)}
                    />
                    }
                    {apruebaMicro == 0 &&                        
                    <TextArea
                        label={"Observación"}
                        name={"Observacion"}
                        id={"Observacion"}
                        type={"textarea"}
                        value={observacionMicro}
                        onChange={setObservacionMicro}
                    />
                    }
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={addEstadoPlanMicro}
                >
                    Guardar
                </Button>
            </ModalFooter>
        </Modal>
        
        <Modal
            show={modalAsignatura}
            onHide={()=>setModalAsignatura(false)}
        >
            <ModalHeader closeButton={true}>
                <h2>Plan Académico de Asignatura - Docente</h2>
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Select
                        label={"Docente"}
                        id="usuarioDocente"
                        name="usuarioDocente"
                        optionValue={"IdUsuario"}
                        optionName={"Docente"}
                        urlOptionsService={`docentes/asignatura?IdOferta=${curso.IdOferta}&IdMalla=${asigantura.IdMalla}`}
                        required={true}
                        value={usuarioDocente}
                        onChange={(e) => setUsuarioDocente(e)}
                    />
                    {usuarioDocente &&
                    <DowloadFileText
                        nameFile={nameFileA}
                        name={" Descargar Plan Académico de Asignatura"}
                        endPoint={urlArchivoAsignatura}
                    />
                    }
                    {usuarioDocente &&
                    <Select
                        label={"¿Aprueba Plan de Trabajo?"}
                        id="Aprobado"
                        name="Aprobado"
                        optionValue={"Id"}
                        optionName={"Nombre"}
                        options={[
                            {"Id": "1", "Nombre": "Sí"},
                            {"Id": "0", "Nombre": "No"}
                        ]}
                        required={true}
                        value={apruebaAsignatura}
                        onChange={(e) => setApruebaAsignatura(e)}
                    />
                    }
                    {apruebaAsignatura == 0 &&                        
                    <TextArea
                        label={"Observación"}
                        name={"Observacion"}
                        id={"Observacion"}
                        type={"textarea"}
                        value={observacionAsignatura}
                        onChange={setObservacionAsignatura}
                    />
                    }
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={addEstadoAsignatura}
                >
                    Guardar
                </Button>
            </ModalFooter>
        </Modal>
    </>
  )
}