import React from 'react'
import MostrarPreguntas from './MostrarPreguntas'
import { useFetchGet } from '../../hooks/useFetchGet'
import { useLocation } from 'react-router-dom'

export default function CuestionarioEstudiante() {
    const id_cuestionario=useLocation().state.id_cuestionario
    // consultar las preguntas
    const [preguntas]=useFetchGet(`preguntasLMS?IdCuestionario=${id_cuestionario}`)
  return (
    <>
        <MostrarPreguntas 
          preguntas={preguntas}
        />
    </>
  )
}
