import React from 'react'
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { DynamicFormContainer } from '../../components/DynamicForm/DynamicFormContainer';
import { DynamicTable } from '../../components/DynamicForm/dynamicTable/DynamicTable';

export default function Documentos() {
    const Navigation=useNavigate();
    const navegarFirmas=(e)=>{
      Navigation("firmas",{state:{documento:e}})
    }
    
  return (
    <>
        <Card>
          <Card.Title>
            Documentos
          </Card.Title>
        </Card>
        <DynamicTable
          titleColumn={["ID","Código","Nombre","Acciones"]}
          attributes={["Codigo","Nombre"]}
          endPoint={"documentos"}
          labelOptionClick={"Firmas"}
          handleOptionClick={(e)=>navegarFirmas(e)}
          onDelete={false}
          onUpdate={false}
        
        />
    </>
  )
}
