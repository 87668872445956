import React from 'react'

import { Input } from '../../input/input'
import { Button } from '../../button/button'
import { ButtonsContainer } from '../../componentsForm/buttonsContainer/buttonsContainer'
import { LeftButtonContainer } from '../../componentsForm/leftButtonContainer/leftButtonContainer'
import { RightButtonContainer } from '../../componentsForm/rightButtonContainer/rightButtonContainer'
import { FormContainerModal } from '../../componentsForm/formContainer/FormContainerModal'

const Form = props => {

    const {
        handleSubmit,
        handleChange,
        formData,
        loading,
        close,
        isCreate,
    } = props

    const {
        NombreUsuario,
        apellido,
        email,
        password,
        passwordRepeat,
    } = formData

    return (
        <FormContainerModal title={'Plan de estudio'}>
            <form onSubmit={ handleSubmit }>
                <Input
                    label='Nombre'
                    type='text'
                    name='NombreUsuario'
                    value={ NombreUsuario || ''}
                    onChange={ handleChange }
                />
                {/* <Input
                    label='Apellidos'
                    type='text'
                    name='apellido'
                    value={ apellido || ''}
                    onChange={ handleChange }
                /> */}
                <Input
                    label='Correo'
                    type='email'
                    name='email'
                    value={ email || ''}
                    onChange={ handleChange }
                />
                <Input
                    label='Contraseña'
                    type='password'
                    name='password'
                    value={ password || ''}
                    onChange={ handleChange }
                />
                {/* <Input
                    label='Repetir contraseña'
                    type='password'
                    name='passwordRepeat'
                    value={ passwordRepeat || ''}
                    onChange={ handleChange }
                /> */}
                <ButtonsContainer>
                    <LeftButtonContainer>
                        <Button type='back' onClick={ close }>
                            Cancelar
                        </Button>
                    </LeftButtonContainer>
                    <RightButtonContainer>
                        <Button 
                            type='send'
                            loading={ loading }
                        >
                            { isCreate ? 'Crear' : 'Actualizar' }
                        </Button>
                    </RightButtonContainer>
                </ButtonsContainer>
            </form>
        </FormContainerModal>
    )
}

export { Form }