import React from 'react';

import { Button } from '../button/button';

import './ReceiptUploaded.css';
import { BASE_SERVIDOR, BASE_URL } from '../../services/api/config';

const ReceiptUploaded = ({ fileName, text, dateCreated }) => {
    return (
        <div className="enrollment__receipt-of-payment-message">
            <h6>{text}</h6>
            <div className="enrollment__voucher-information">
                <section className="enrollment__creation-date">
                    <p>Fecha de creación</p>
                    <p>{dateCreated}</p>
                </section>
                <div className="d-flex flex-column align-items-center">
                    
                    <Button
                        isAnchor={true}
                        type="circle"
                        to={`${BASE_SERVIDOR}/pdf/${fileName}`}
                    >
                        <i className="bi bi-file-earmark-pdf"></i>
                    </Button>

                    <p className="texto_ver_doc">Ver Documento</p>
                </div>
            </div>
        </div>
    );
};

export { ReceiptUploaded };
