import React, { useState } from 'react'
import { DynamicTable } from '../../components/DynamicForm/dynamicTable/DynamicTable'
import { Card } from 'react-bootstrap'
import { DynamicSearch } from '../../components/DynamicForm/DynamicSearch/DynamicSearch'
import { useFetchGet } from '../../hooks/useFetchGet'
import { useNavigate } from 'react-router-dom'

export default function Funcionarios() {
    const navigate = useNavigate()
    const url="funcionarios"
    const [endPoint, setEndPoint] = useState(url)
    const [busqueda, setBusqueda] = useState("")
    const [camposForm]=useFetchGet("xcampos?id_formulario=113")


    // buscar funcinarios
    const buscarFuncionarios = async(data) => {
        setEndPoint(`${url}${data}`)
        setBusqueda(data)
    }

  return (
    <>
        <Card>
            <Card.Title as="h5">Funcionarios</Card.Title>
        </Card>
        {camposForm && camposForm.length>0 &&
            <DynamicSearch
                onSearch={buscarFuncionarios}
                camposForm={camposForm}
            />
        }
        <DynamicTable
            endPoint={endPoint}
            busqueda={busqueda}
            titleColumn={[
                'N°',
                'Nombre',
                'Cargo',
                'Identificación',
                'Acciones'
            ]}
            attributes={[
                'Nombre',
                'Cargo',
                'NumeroDocumento',
            ]}
            onDelete={false}
            onUpdate={false}
            labelOptionClick="ver más"
            handleOptionClick={(e)=>{
                navigate(`${e.IdFuncionario}`, {state:{funcionario:e}})
            }}
        ></DynamicTable>
    </>
  )
}
