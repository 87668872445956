import React from 'react'
import { useState } from 'react';
import { Button, Card} from 'react-bootstrap'
import { BsArrowLeft, } from 'react-icons/bs'
import { useLocation, useNavigate } from 'react-router-dom';
import YouTube from 'react-youtube';
import { TextArea } from '../../../components/componentsForm/textarea/textarea';
import { Select } from '../../../components/select/Select';
import { FormService } from '../../../services/api/formService';
import { alerta } from '../../../helpers/alertas';
import { LocalStorage } from '../../../services';
import { BASE_SERVIDOR } from '../../../services/api/config';
import Header from './Componentes/Header';

export default function FormSolicitud() {
    const {state:{usuario}}=useLocation()
    const storage= new LocalStorage();
    // const usuario= JSON.parse(storage.getItem("usuario"))
    const api=new FormService();
    const navigate=new useNavigate();
    const {state:{tipoSolicitud}}=useLocation();
    const urlDocumentos=`${BASE_SERVIDOR}`
    const [idCurso,setIdCurso]=useState(null);
    const [observaciones,setObservaciones]=useState(null);
    const [documentoSolicitud,setDocumentoSolicitud]=useState(null);

    const enviarSolicitud=async()=>{
        if(idCurso==null){
            alerta("Selecciona el programa")
        }
        const data={
            "IdCurso":idCurso,
            "IdTipoSolicitud":tipoSolicitud.IdTipoSolicitud,
            "IdUsuario":usuario.IdUsuario,
            "Observaciones":observaciones,
            "DocumentoSolicitud":documentoSolicitud
        }

        const respuesta=await api.createWithFormData("solicitudes",data);
        if(respuesta.respuesta=="exito"){
            alerta(`${respuesta.mensaje} redirigiendo a mis solicitudes...`)
            navigate(-2)
        }

    }


  return (
    <>
    <div style={{maxWidth:1000,margin:"0 auto",marginTop:40}}>
        <Header usuario={usuario}/>
        <Card>
        <Card.Title>
                Solicitud de {tipoSolicitud.Nombre.toLowerCase()}
            </Card.Title>
        </Card>
        <div>
            <Card>
                <Card.Title style={{textAlign:"center"}}>Instrucciones para realizar su solicitud</Card.Title>
                <div style={{textAlign:"center"}}>
                    {tipoSolicitud.Video&&
                        <YouTube
                            videoId={tipoSolicitud.Video}
                        />
                    }
                </div>
                    <div style={{textAlign: "justify"}}>
                        {tipoSolicitud.Descripcion}
                    </div>
                    {/* {tipoSolicitud.Documento&&
                        <>
                        <Card.Title style={{textAlign:"center",padding:20}}>Documento instructivo</Card.Title>
                            <a target="_blank" href={`${urlDocumentos}/pdf/${tipoSolicitud.Documento}`}>Descargar documento</a>
                        </>
                    } */}
                    <div className='Formulario'>
                    <Card.Title style={{textAlign:"center",padding:20}}>Formulario de solicitud</Card.Title>
                    <Select
                        label="Selecciona el programa académico"
                        optionName={`Nombre`}
                        optionValue={`IdCurso`}
                        value={idCurso}
                        onChange={(e)=>setIdCurso(e)}
                        urlOptionsService={`solicitudes/cursosDisponibles?IdUsuario=${usuario.IdUsuario}`}
                    />
                    <TextArea 
                        label="Observaciones"
                        value={observaciones}
                        onChange={(e)=>setObservaciones(e)}
                    />
                    <label style={{marginTop:15}}><b>Adjuntar documento si lo requiere</b></label><br/>
                    <input
                        type="file"
                        onChange={(e)=>setDocumentoSolicitud(e.target.files[0])}
                    />
                    </div>

                    <Button style={{marginTop:20}}
                        onClick={()=>enviarSolicitud()}
                    >
                        Solicitar
                    </Button>
                </Card>
        </div>
        </div>
    </>
  )
}
