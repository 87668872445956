import React, { useEffect, useState } from 'react'
import { UserProfile } from './UserProfile'
import { useUpdate } from '../../hooks/useUpdate'
import { FormService } from '../../services/api/formService'
import { LocalStorage } from '../../services'
import PerfilUsuario from '../../modules/componentesC/PerfilUsuario/Inicio'

const UserProfileContainer = () => {

    const [userData, setUserData] = useState({})
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [ready, setReady] = useState(false)
    const [update, errorUpdate] = useUpdate('usuario')
    const localStorage = new LocalStorage()
    const [IdUsuario,setIdUsuario]=useState('');

    const fecthUserData = async () => {
        try {
            const formService = new FormService()
            const user = localStorage.getAsJsonItem('usuario')
            const ID = user?.usuario?.IdUsuario
            setIdUsuario(user?.usuario?.IdUsuario)
            const userDataFetched = await formService.getById('usuario', ID)
            setUserData(userDataFetched)
        } catch (err) {
            setError(err)
        }
    }

    useEffect(() => {
        const fecthData = async () => {
            await fecthUserData()
        }
        fecthData()
    }, [])

    const handleSudmit = async (e) => {
        e.preventDefault()
        try {
            setError(null)
            setReady(false)
            setLoading(true)
            await update(userData, 'IdUsuario', null)
            console.log('userData', userData)
            // localStorage.removeItem('usuario')
            // localStorage.setItem('usuario', userData)
            setReady(true)
            setLoading(false)
        } catch (err) {
            setError(err)
            setLoading(false)
        }
    }

    const handleChange = (value, name) => {
        setReady(false)
        setError(null)
        setUserData({
            ...userData,
            [name]: value
        })
    }

    return (
        <PerfilUsuario/>
        // <UserProfile
        //     IdUsuario={IdUsuario}
        //     handleSudmit={ handleSudmit }
        //     handleChange={ handleChange }
        //     loading={ loading }
        //     ready={ ready }
        //     error={ error }
        //     userData={ userData }
        // />
    )
}

export { UserProfileContainer }