

import { useNavigate } from "react-router-dom"
import { Card } from "../../../components/card/Card"
import { AddButton } from "../../../components/componentsForm/addButton/addButton"
import { DynamicTable } from "../../../components/DynamicForm/dynamicTable/DynamicTable"
import { LocalStorage } from "../../../services"
import { Title } from "../../../shared/components/title/Title"

function EstudianteCalendarioContainer () {
  const ls = new LocalStorage()
  const IdUsuario = ls.getAsJsonItem('usuario').usuario?.IdUsuario
  const navigate = useNavigate()

  const handleOptionClick = (item) => {
    navigate(`${item.IdOferta}/detalle_calendario`,{state:{item}})
  }

  return (<>
  <AddButton></AddButton>
  <Card>
    <Title>Mi Calendario</Title>
  </Card>
  <Card>

  <DynamicTable
  titleColumn={['N°','Nombre','Acción']}
  attributes = { ['Nombre']}
  onUpdate={false}
  onDelete={false}
  handleOptionClick= {handleOptionClick}
  labelOptionClick= {'Ver calendario del programa'}
  classNameOptionIcon={'bi bi-book'}
  endPoint={`estudiante/calendario?IdUsuario=${IdUsuario}`}
  ></DynamicTable>
  </Card>
  </>
  )
}

export { EstudianteCalendarioContainer }