import React from 'react'
import { HeaderHome } from '../../components/headerHome/HeaderHome'

const HabeasContainer = () => {
  return (
    <>
    <HeaderHome/>
    <div className='mx-auto w-75 d-flex flex-column justify-content-center align-items-center'>
        
        
        <h1 className='text-center'>Habeas DATA</h1><br /><br />

        <p className=''>
        “Manifiesto bajo la gravedad del juramento que la información suministrada en el presente formato de inscripción es verídica y autorizo la confirmación de la misma, en caso de ser necesario.”<br /><br />
        “Ley 1581 del 2012: La Información recolectada a través del presente formulario será procesada y almacenada a fin de gestionar su solicitud de inscripción a alguna de las capacitaciones brindadas por el Centro de Estudios Aeronáuticos, así como las actividades derivadas del proceso de enseñanza- aprendizaje que busca formar integralmente a nuestros estudiantes. Puede conocer nuestra Política de Protección de Datos Personales y ejercer sus derechos como titular de la información a actualizar, rectificar, conocer y oponerse a través de nuestra página web www.aerocivil.gov.co.
</p>


    </div>
    </>
  )
}

export default HabeasContainer