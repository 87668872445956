import { Input } from '../input/input'
import { InputFile } from '../inputFile/inputFile'
import { Select } from '../select/Select'
import { TextArea } from '../componentsForm/textarea/textarea'
import { SelectDataList } from '../SelectDataList/SelectDataList'
import { Checkbox } from '../componentsForm/checkbox/checkbox'
const RenderTags = ({ input, index, fields, onChangeHandler }) => {
    switch (input.tipo) {
        case 'select':
            return ((input.render_form == '1' && input.render_form == '1') && 
                    <>
                    {input.tipo_select == '2' ? 
                        <Select
                          key={index}
                          name={input.name}
                          value={fields?.[input.name] || ''}
                          label={input.label}
                          onChange={onChangeHandler}
                          optionValue={input.optionValue}
                          optionName={input.optionName}
                          required={input.requerido == '1'}
                          options={JSON.parse(input.opciones)}
                        />
                    :
                    <Select
                          key={index}
                          name={input.name}
                          value={fields?.[input.name] || ''}
                          label={input.label}
                          onChange={onChangeHandler}
                          optionValue={input.optionValue}
                          optionName={input.optionName}
                          required={input.requerido == '1'}
                          urlOptionsService={input.params ? `${input.opciones}&${input.params}=${fields[input.params]}` : input.opciones}
                        />
                    }
                    </>
        )
        case 'text':
            return (
                input.render_form == '1' && (
                    <Input
                        key={index}
                        label={input.label}
                        value={fields?.[input.name] || ''}
                        name={input.name}
                        type={input.tipo}
                        onChange={onChangeHandler}
                        placeholder={input.placeholder || ''}
                        required={input.requerido&&input.requerido!=="0" ? true:false}
                    />
                )
            )
        case 'textarea':
            return (
                input.render_form == '1' && (
                    <TextArea
                        label={input.label}
                        name={input.name}
                        type={input.tipo}
                        value={fields?.[input.name] || ''}
                        onChange={onChangeHandler}
                        placeholder={input.placeholder || ''}
                        required={input.requerido && input.requerido!=="0" ? true:false}
                    />
                )
            )
        case 'email':
            return (
                input.render_form == '1' && (
                    <Input
                        label={input.label}
                        name={input.name}
                        value={fields?.[input.name] || ''}
                        type={input.tipo}
                        onChange={onChangeHandler}
                        placeholder={input.placeholder || ''}
                        required={input.requerido&&input.requerido!=="0" ? true:false}
                    />
                )
            )
        case 'number':
            return (
                input.render_form == '1' && (
                    <Input
                        label={input.label}
                        name={input.name}
                        value={fields?.[input.name] || ''}
                        type={input.tipo}
                        onChange={onChangeHandler}
                        placeholder={input.placeholder || ''}
                        required={input.requerido&&input.requerido!=="0" ? true:false}
                    />
                )
            )
        case 'date':
            return (
                input.render_form == '1' && (
                    <Input
                        label={input.label}
                        name={input.name}
                        value={fields?.[input.name] || ''}
                        onChange={onChangeHandler}
                        type={input.tipo}
                        required={input.requerido&&input.requerido!=="0" ? true:false}
                    />
                )
            )
            case 'time':
                return (
                    input.render_form == '1' && (
                        <Input
                            label={input.label}
                            name={input.name}
                            value={fields?.[input.name] || ''}
                            onChange={onChangeHandler}
                            type={input.tipo}
                            required={input.requerido&&input.requerido!=="0" ? true:false}
                        />
                    )
                )
        case 'file':
            return (
                input.render_form == '1' && (
                    <>
                    <InputFile
                        label={input.label}
                        archivoActual={fields?.[input.name] || ''}
                        name={input.name}
                        accept="image/*,.pdf"
                        onChange={onChangeHandler}
                        required={input.requerido==1? input.requerido :false}
                    />
                    </>
                )
            )
        case 'checkbox':
            return (
                input.render_form == '1' && (
                    <Checkbox
                        title={input.label}
                        onChange={(value, name) => {
                            let newValue = value == true ? 'true' : '0'
                            onChangeHandler(newValue, name)
                        }}
                        name={input.name}
                        checked={fields?.[input.name] == 'true' ? true : false}
                        value={fields?.[input.name] == 'true' ? true : false}
                    >
                        {input.label}
                    </Checkbox>
                )
            )
        case 'busqueda':
            return (
                input.render_form == '1' && (
                    <SelectDataList
                        input={input}
                        label={input.label}
                        placeholder={input.placeholder || ''}
                        optionValue={input.identificador}
                        optionName={ input.optionName }
                        onChangeHandler={onChangeHandler}
                        name={input.name}
                        endPoinLastUrl={input.opciones}
                        value={fields?.[input.identificador||input.optionName] || ''}
                        valorActual={fields?.[input.name] || fields?.[input.optionValue] || ''}
                        required={input.requerido==1? input.requerido :false}
                    />
                )
            )
        case 'title':
            return (
                input.render_form == '1' && <>{input.label}</>
            )
        case 'subtitle':
            return input.render_form == '1' && <p>{input.label}</p>
        case 'radio':
            return input.render_form == '1' && <p>{input.label}</p>
        case 'html':
            return (
                input.render_form == '1' && (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: input.label,
                        }}
                    />
                )
            )
        default:
            return (
                <div>
                    <small>Dynamic Input undefined</small>
                    <b style={{ color: 'red' }}>{input.tipo}</b>
                </div>
            )
    }
}

export { RenderTags }
