import { Route, Routes } from "react-router-dom";
import { CalendarioAulasContainer } from "../admin/planeacionAcademica/CalendarioAcademico/CalendarioAulasContainer";
import Calendar from "../admin/planeacionAcademica/CalendarioAcademico/Calendar";
import { AsignaturasCurso } from "../componentesC/AsignaturasCurso/AsignaturasCurso";
import { MomentosEvaluativos } from "../componentesC/momentosEvaluativos/MomentosEvaluativos";
import { EstudianteNotas } from "../componentesC/EstudianteNotas/EstudianteNotas";
import { EstudianteAsistencias } from "../componentesC/EstudianteAsistencias/EstudianteAsistencias";
import { AsignaturasEstudiantes } from "../componentesC/AsignaturasEstudiante/AsignaturasEstudiantes";
import { CalificarNotas } from "../componentesC/CalificarNotas/CalificarNotas";
import { CalificarAsistencias } from "../componentesC/CalificarAsistencias/CalificarAsistencias";
import { CursosFinalizados } from "./CursosFinalizados";
import { CursosActivos } from "./CursosActivos";
import Horario from "./Horario/Horario";
import AsistenciasDocentes from "./AsistenciasDocentes";
import { UnidadesAsignatura } from "../componentesC/UnidadesAsignatura/UnidadesAsignatura";
import { HorariosLight } from "./HorariosLight/HorariosLight";
import HorarioLight from "./HorariosLight/HorarioLight";
import { CursosInscripciones } from "./CursosInscripciones";
import TutoriasAsesorias from "./TutoriasAsesorias/TutoriasAsesorias";
import Vinculados from "./TutoriasAsesorias/Vinculados";
import PlanTrabajo from "./PlanTrabajo";

const CoordinadorAcademico=()=>{
    return(
        <Routes>
            <Route path="cursos_activos" element={<CursosActivos/>} />
            <Route path="cursos_activos/:idoferta/horarios" element={<HorariosLight/>} />
            <Route path="cursos_activos/:idoferta/horarios/horario" element={<HorarioLight/>} />
            <Route 
                path="cursos_activos/:idoferta/asignaturas" 
                element={<AsignaturasCurso editable={false} full={false} coordinador={true}/>}
            />
            <Route path="cursos_activos/:idoferta/asignaturas/:idmalla/plan_trabajo" element={<PlanTrabajo/>}></Route>
            <Route path="cursos_activos/tutorias_asesorias" element={<TutoriasAsesorias/>}/>
            <Route path="cursos_activos/tutorias_asesorias/vinculados" element={<Vinculados/>}/>
            <Route 
                path="cursos_activos/:idoferta/asignaturas/horarios" 
                element={<HorariosLight editable={false}/>}
            />
            <Route 
                path="cursos_activos/:idoferta/asignaturas/horarios/horario" 
                element={<HorarioLight editable={false}/>}
            />
            <Route 
                path="cursos_activos/:idoferta/asistencias_docentes" 
                element={<AsistenciasDocentes />}
            />
            <Route
                path="cursos_activos/:idoferta/asignaturas/:idmalla/asignatura_estudiantes"
                element={<AsignaturasEstudiantes />}
            />
            <Route
                path="cursos_activos/:idoferta/asignaturas/:idmalla/momentos_evaluativos"
                element={<MomentosEvaluativos editable={true} />}
            ></Route>
             <Route
                path="cursos_activos/:idoferta/asignaturas/:idmalla/unidades_asignatura"
                element={<UnidadesAsignatura editable={true} />}
            ></Route>
            <Route
                path="cursos_activos/:idoferta/asignaturas/:idmalla/unidades_asignatura/notas_y_asistencias"
                element={<AsignaturasEstudiantes editable={true} />}
            ></Route>
            <Route
                path="cursos_activos/:idoferta/asignaturas/:idmalla/unidades_asignatura/notas_y_asistencias/notas"
                element={<CalificarNotas gestionable={false} />}
            ></Route>
            <Route
                path="cursos_activos/:idoferta/asignaturas/:idmalla/unidades_asignatura/notas_y_asistencias/asistencias"
                element={<CalificarAsistencias canEdit={false}/>}
            ></Route>
            <Route path="cursos_inscripciones" element={<CursosInscripciones/>} />
            <Route path="cursos_inscripciones/:idoferta/horarios" element={<HorariosLight/>} />
            <Route path="cursos_inscripciones/:idoferta/horarios/horario" element={<HorarioLight/>} />
            <Route path="cursos_inscripciones/:idoferta/calendario" element={<Horario/>} />
{/*            
            <Route  path="cursos_activos/:idoferta/asignaturas/:idusario/detalle_programa/:id/asistencia" 
                    element = {<EstudianteAsistencias/>}>
            </Route> 
            <Route
                path="cursos_activos/:idoferta/asignaturas/:idmalla/asignatura_estudiantes/notas"
                element={<CalificarNotas/>}
            ></Route> */}

             <Route  path="cursos_activos/:idoferta/asignaturas/:idusario/detalle_programa" 
                    element = {<EstudianteNotas gestionable={false} />}>
            </Route> 
            <Route
                path="cursos_activos/:idoferta/asignaturas/:idmalla/asignatura_estudiantes/asistencias"
                element={<CalificarAsistencias canEdit={false}/>}
            ></Route>

            <Route 
                path="cursos_activos/:idoferta/horario" 
                element={<Horario/>}
            />

            <Route path="cursos_finalizados" element={<CursosFinalizados/>} />
            <Route 
                path="cursos_finalizados/:idoferta/asignaturas" 
                element={<AsignaturasCurso editable={false}/>}
            />
            <Route
                path="cursos_finalizados/:idoferta/asignaturas/:idmalla/asignatura_estudiantes"
                element={<AsignaturasEstudiantes />}
            />
            <Route
                path="cursos_finalizados/:idoferta/asignaturas/:idmalla/momentos_evaluativos"
                element={<MomentosEvaluativos editable={true} />}
            ></Route>
            <Route  path="cursos_finalizados/:idoferta/asignaturas/:idusario/detalle_programa" 
                    element = {<EstudianteNotas gestionable={false} />}>
            </Route> 
            <Route  path="cursos_finalizados/:idoferta/asignaturas/:idusario/detalle_programa/:id/asistencia" 
                    element = {<EstudianteAsistencias/>}>
            </Route> 
            <Route
                path="cursos_finalizados/:idoferta/asignaturas/:idmalla/asignatura_estudiantes/notas"
                element={<CalificarNotas/>}
            ></Route>
            <Route
                path="cursos_finalizados/:idoferta/asignaturas/:idmalla/asignatura_estudiantes/asistencias"
                element={<CalificarAsistencias canEdit={false}/>}
            ></Route>

            <Route  path="horario_clase" element = {<CalendarioAulasContainer />}></Route>
            <Route  path="horario_clase/:id/calendario" element = {<Calendar editable cordinador/>}></Route>
        </Routes>
    )
}

export {CoordinadorAcademico};
