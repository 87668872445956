import React, { useEffect, useState } from 'react'

import { Arrow } from '../../arrow/Arrow'
import { Card } from '../../card/Card'
import { Input } from '../../input/input'
import { OfferHeader } from '../../OfferHeader/OfferHeader'
import { useParams, useLocation } from 'react-router-dom'
import { FormService } from '../../../services/api/formService'
import { Message } from '../../Message/Message'
import { Badge } from '../../Badge/Badge'
import { Button } from '../../button/button'
import { Modal } from '../../modal/Modal'
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner'
import { ExportList } from '../../ExportList/ExportList'
import { AddUserAs } from '../../AddUserAs/AddUserAs'

import './StudentsList.css'

const StudentsList = () => {

    const [loadingFull, setLoadingFull] = useState(true)
    const [error, setError] = useState(null)
    const [students, setStudents] = useState([])
    const [inputSearch, setInputSearch] = useState('')
    const [filterData, setFilterData] = useState([])
    const [isOpenModalAddStudent, setIsOpenModalAddStudent] = useState(false)
    const { idCourse } = useParams()
    const { state: { studentsList } } = useLocation()
    const ENDPOINT = 'estudiantesCurso?IdOferta='
    const LIST_EXPORT_LINK = 'https://halconerp.com/v1/public/api/exportar/estudiantes?IdOferta='

    const fetchDataStudents = async () => {
        setLoadingFull(true)
        try {
            const formService = new FormService()
            const data = await formService.getAll(`${ENDPOINT}${idCourse}`)
            setStudents(data)
            setFilterData(data)
            setLoadingFull(false)
        } catch (err) {
            setError(err)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchDataStudents()
        }
        fetchData()
    }, [])

    const handleOpenModalAddStudent = () => {
        setIsOpenModalAddStudent(!isOpenModalAddStudent)
    }

    const searchApplicants = (value) => {
        const result = students.filter(item => {
            if (
                item.PrimerNombres.toLowerCase().includes(value.toLowerCase())
                || item.NumeroDocumento.toLowerCase().includes(value.toLowerCase())
            ) {
                return item
            }
        })
        setFilterData(result)
    }

    const onSearch = (value) => {
        setFilterData(students)
        setInputSearch(value)
        searchApplicants(value)
    }

    return (
        <>
            <section>
                <Arrow />
            </section>
            <section className='students-list'>
                <OfferHeader
                    img={ studentsList?.Repositorio }
                    offerName={ studentsList?.Nombre }
                    offerDescription={ studentsList?.Observaciones }
                    idOffer={ studentsList?.IdOferta }
                    convert={ true }
                    isFinally={ true }
                />
                <Card>
                    <Input 
                        type='search'
                        placeholder= 'Busca por nombre o cédula del estudiante'
                        value={ inputSearch }
                        onChange={ onSearch }
                    />
                    {
                        loadingFull ? <LoadingSpinner /> : (
                            Array.isArray(filterData) && (
                                <div className='students-list__list-container'>
                                    {
                                        filterData.map((student, key) => (
                                            <Badge 
                                                to={ 
                                                    `estado-estudiante/${student?.IdAspirante}` 
                                                }
                                                state={{ student }}
                                                img={ student?.Foto }
                                                alt='Foto de perfil de estudiante'
                                                user={ student }
                                                key={ key }
                                            />
                                        ))
                                    }
                                </div>
                            )
                        )
                    }
                </Card>
                {
                    error && (
                        <Message type='error'>
                            { error.message }
                        </Message>
                    )
                }
            </section>
            <Modal 
                isOpen={ isOpenModalAddStudent }
                close={ handleOpenModalAddStudent }
            >
                <AddUserAs 
                    SearchEndpoint={ 'buscarUsuario?numero=' }
                    setError={ setError }
                    idCourse={ idCourse }
                />
            </Modal>
        </>
    )
}

export { StudentsList }