export const ID_FORM = {
    PAISES: '1',
    DEPARTAMENTOS: '2',
    TIPO_MECANISMO: '3',
    TIPO_TERCERO_PERSONA: '4',
    PROGRAMAS_O_ACTIVIDADES: '7',
    ASIGNATURAS: '6',
    DEFAULT:'0',
    OFERTAS: '13',
    AUTO_EVALUACION:'52',
    GESTION_INSTRUMENTOS:'74',
    EVALUACION_PARA_DOCENTES:'76',
    EVALUACION_PARA_COORDINADORES:'75',

}