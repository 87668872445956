
import React, { useEffect } from 'react'
import { Button, Card, Col, Dropdown, Form, Modal, Row } from 'react-bootstrap'
import { useFetchGet } from '../../hooks/useFetchGet'
import { useLocation, useNavigate } from 'react-router-dom'
import { FormService } from '../../services/api/formService';
import { alerta } from '../../helpers/alertas';

export default function TutoriasAsesorias() {
    const location= useLocation();
    const api= new FormService();
    const navigate=useNavigate()

    const [show, setShow] = React.useState(false)
    const [showDelete, setShowDelete] = React.useState(false)
    const [formData, setFormData] = React.useState({})
    // consutlar asignaturas

    const [aulas] =useFetchGet(`aulas`)
    const [eventos,setEventos] = React.useState([])
    useEffect(() => {
        consultarEventos()
    }, [])

    // guardar evento
    const guardarEvento = async() => {
        const data={
            ...formData
        }
        let respuesta
        if(!formData.actualizar){
            respuesta= await api.create("bienestar/crearEventoTA",data)
        }else{
            respuesta= await api.update("bienestar/actualizarEventoTA",data,formData.IdEventoAula)
        }
        
        if(respuesta){
            setShow(false)
            consultarEventos()
        }else{
            alerta("No se pudo guardar el evento","error")
        }
    }
    // consultar eventos
    const consultarEventos=async()=>{
        const eventos=await api.getAll(`bienestar/consultarEventosTA`)
        setEventos(eventos)
    }
    // eliminar evento
    const eliminarEvento=async()=>{
        const respuesta=await api.delete("bienestar/eliminarEventoTA",{},formData.IdEventoAula)
        if(respuesta){
            setShowDelete(false)
            consultarEventos()
        }else{
            alerta("No se pudo eliminar el evento","error")
        }
    }
  return (
    <>
        <Card>
            <Row>
                <Col>
                    <Card.Title>Tutorias, Asesorias y Talleres</Card.Title>
                </Col>
                <Col style={{textAlign:"right"}}>
                    <Button
                        onClick={() => {
                            setShow(true)
                            setFormData({})
                        }}
                        variant="primary"
                    >Agregar</Button>
                </Col>
            </Row>
        </Card>
        {/* tabla para mostar los eventos */}
        <Card>
            <Card.Body>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Fecha</th>
                            <th scope="col">Tema</th>
                            <th scope="col">Modalidad</th>
                            <th scope="col">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {eventos.map((evento) => (
                            <tr key={evento.IdEventoAula}>
                                <td>
                                    { evento.FechaInicio.split(" ")[0] } :
                                    { evento.FechaInicio.split(" ")[1].slice(0,-3) }
                                    /{evento.FechaFin.split(" ")[0]} :
                                    { evento.FechaFin.split(" ")[1].slice(0, -3) }
                                </td>
                                <td>{evento.eventoPersonalizado}</td>
                                <td>{evento.Modalidad}</td>
                                <td>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                            Acciones
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                onClick={() => {
                                                    setShow(true)
                                                    setFormData({
                                                        fecha: evento.FechaInicio.split(" ")[0],
                                                        horaInicial: evento.FechaInicio.split(" ")[1].slice(0,-3),
                                                        horaFinal: evento.FechaFin.split(" ")[1].slice(0, -3),
                                                        aula: evento.IdAula,
                                                        tema: evento.eventoPersonalizado,
                                                        tipoEvento: evento.TipoEvento,
                                                        asignatura: evento.IdMalla,
                                                        tipoAsesoria: evento.TipoAsesoria,
                                                        modalidad: evento.Modalidad,
                                                        IdEventoAula: evento.IdEventoAula,
                                                        actualizar: true
                                                    })
                                                }}
                                            >Editar</Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => {
                                                    navigate("vinculados",{state:{evento}})
                                                }}
                                            >Vinculados</Dropdown.Item>
                                            <Dropdown.Item 
                                                onClick={() => {
                                                    setShowDelete(true)
                                                    setFormData({...evento,actualizar:true})
                                                }}                                                
                                            >Eliminar</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Card.Body>
        </Card>

        {/* modal para crear evento */}
        <Modal show={show} onHide={() => setShow(false)}>
            <Form 
                onSubmit={(e) => {
                    e.preventDefault()
                    guardarEvento()
                }}
            >
            <Modal.Header closeButton>
                <Modal.Title>Crear Evento</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <Row>
                        <Col>
                        <Form.Group>
                            <Form.Label>Fecha Inicial</Form.Label>
                            <Form.Control type="date" 
                                onChange={(e) => setFormData({...formData, fechaInicial: e.target.value})}
                                value={formData.fechaInicial}
                                required
                            />
                        </Form.Group>
                        </Col>
                        <Col>
                        <Form.Group>
                            <Form.Label>Hora Inicial</Form.Label>
                            <Form.Control type="time"
                                onChange={(e) => setFormData({...formData, horaInicial: e.target.value})}
                                value={formData.horaInicial}
                                required
                            />
                        </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Fecha Final</Form.Label>
                                <Form.Control type="date"
                                    onChange={(e) => setFormData({...formData, fechaFinal: e.target.value})}
                                    value={formData.fechaFinal}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Hora Final</Form.Label>
                                <Form.Control type="time"
                                    onChange={(e) => setFormData({...formData, horaFinal: e.target.value})}
                                    value={formData.horaFinal}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group>
                        <Form.Label>Aula</Form.Label>
                        <Form.Control 
                            as='select'
                            onChange={(e) => setFormData({...formData, aula: e.target.value})}
                            value={formData.aula}
                            required
                        >
                        <option value="">Seleccione</option>
                        {aulas.map((aula) => (
                            <option key={aula.IdAula} value={aula.IdAula}>{aula.numero}</option>
                        ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Tema</Form.Label>
                        <Form.Control type="text"
                            onChange={(e) => setFormData({...formData, tema: e.target.value})}
                            value={formData.tema}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Tipo de Evento</Form.Label>
                        <Form.Control as="select"
                            onChange={(e) => setFormData({...formData, tipoEvento: e.target.value})}
                            value={formData.tipoEvento}
                            required
                        >
                            <option value="">Seleccione</option>
                            <option value="Tutoria">Tutoría</option>
                            <option value="Asesoria">Asesoría</option>
                            <option value="Taller">Taller</option>
                        </Form.Control>
                    </Form.Group>                  
                    {formData.tipoEvento === "Asesoria" && (
                        <Form.Group>
                            <Form.Label>Tipo de Asesoria</Form.Label>
                            <Form.Control as="select"
                                onChange={(e) => setFormData({...formData, tipoAsesoria: e.target.value})}
                                value={formData.tipoAsesoria}
                                required
                            >
                                <option value="Prácticas">Practicas</option>
                                <option value="Pasantías">Pasantias</option>
                                <option value="Trabajo de grado">Trabajo de grado</option>
                                <option value="Otros">Otros</option>
                            </Form.Control>
                        </Form.Group>
                    )}
                    {formData.tipoAsesoria === "Otros" && (
                        <Form.Group>
                            <Form.Label>Especificar</Form.Label>
                            <Form.Control type="text"
                                onChange={(e) => setFormData({...formData, especificar: e.target.value})}
                                value={formData.especificar}
                                required
                            />
                        </Form.Group>
                    )}

                    <Form.Group>
                        <Form.Label>Modalidad</Form.Label>
                        <Form.Control as="select"
                            onChange={(e) => setFormData({...formData, modalidad: e.target.value})}
                            value={formData.modalidad}
                            required
                        >
                            <option value="Virtual">Virtual</option>
                            <option value="Apoyo Remoto">Apoyo Remoto</option>
                            <option value="Presencial">Presencial</option>
                            <option value="Mixta">Mixta</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Observacion</Form.Label>
                        <Form.Control type="text"
                            onChange={(e) => setFormData({...formData, observacion: e.target.value})}
                            value={formData.observacion}
                        />
                    </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    Cerrar
                </Button>
                <Button variant="primary" type="submit">
                    {formData.actualizar ? "Actualizar" : "Guardar"}
                </Button>
            </Modal.Footer>
        </Form>
        </Modal>
        {/* moadl para eliminar evento */}
        <Modal show={showDelete} onHide={() => setShowDelete(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Eliminar Evento</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>¿Esta seguro que desea eliminar el evento?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowDelete(false)}>
                    Cerrar
                </Button>
                <Button variant="danger" onClick={() => eliminarEvento()}>
                    Eliminar
                </Button>
            </Modal.Footer>
        </Modal>
        {/* incluir estilos */}
        <style jsx>{`
            .modal-body div {
                margin-top: 10px;
            }
        `}</style>
    </>
  )
}
