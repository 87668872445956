// 

import { useLocation, useNavigate } from "react-router-dom"
import { ICON } from "../../../../constants/icons"
import { SubTitle } from "../../../../shared/components/subtitle/SubTitle"
import Table from 'react-bootstrap/Table';
import { useState } from "react"
import { Input } from "../../../../components/input/input"
import { FormService } from "../../../../services/api/formService"
import { useEffect } from "react"
import { alerta } from "../../../../helpers/alertas"
import { Button, Card, Modal } from "react-bootstrap";

function ListadoEvaluacionesEstudiante () {

  const {state:{estudiante,programa}} = useLocation()
  console.log(programa)
  // const [evaluaciones,isLoading,error] = useFetchGet(`gestionInstrumentos/evaluacionesEstudiantes?IdEstudiante=${estudiante.IdEstudiante}&IdOferta=${programa.IdOferta}`)
  const [isOpenModalEvaluacion,setIsOpenModalEvaluacion] = useState(false)
  const [isOpenModalEvaluacionDelete,setIsOpenModalEvaluacionDelete] = useState(false)
  const [ evaluaciones,setEvaluaciones] = useState([])
  const [ itemSelected,setItemSelected] = useState({})
  const [ dateRangeEvaluacion,setDateRangeEvaluacion] = useState({})
  const navigate = useNavigate()
  const api = new FormService()
  useEffect(()=>{
    fetchEvaluaciones()
  },[])

  const handleEditClick = (item) => {
    console.log({item})
    console.log({programa},{estudiante})

    setItemSelected(item)
    setDateRangeEvaluacion({FechaInactivo:item.FechaInactivo,FechaActivo:item.FechaActivo})
    setIsOpenModalEvaluacion(true)
  }
  const handleDeleteClick = (item) => {
    setItemSelected(item)
    setIsOpenModalEvaluacionDelete(true)
  }
  const onHandleChange =(value,name)=>{
    console.log(dateRangeEvaluacion)
    setDateRangeEvaluacion({...dateRangeEvaluacion,[name]:value})
  }
  const fetchEvaluaciones = async()=>{
    api.getAll(`gestionInstrumentos/evaluacionesEstudiantes?IdEstudiante=${estudiante.IdEstudiante}&IdOferta=${programa.IdOferta}`)
    .then(res=>{
      setEvaluaciones(res)
    })
  }
  
  const onUpdateDate =async ()=>{
    
    try {
      const res = await api.update(`gestionInstrumentos`,dateRangeEvaluacion,itemSelected.IdGestionInstrumento)
      console.log(res)
      setIsOpenModalEvaluacion(false)
      fetchEvaluaciones()
    } catch (error) {
      alerta('error')
    }
  }
  const onDeleteEvaluacion =async ()=>{
    try {
      const res = await api.update(`gestionInstrumentos`,{Estado:0},itemSelected.IdGestionInstrumento)
      console.log(res)
      setIsOpenModalEvaluacionDelete(false)
      fetchEvaluaciones()
    } catch (error) {
      alerta('error')
    }
  }
  
  return (
    <>
    <Card>
      <Card.Title>Estudiante {estudiante.PrimerNombres} {estudiante.PrimerApellidos} {estudiante.SegundoApellidos}</Card.Title>
      <p>{estudiante.email}</p>
    </Card>

    <Card>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>N°</th>
            <th>Nombre del instrumento</th>
            <th>Docente</th>
            <th>Plazo (A/M/D)</th>
            <th>Realizado</th>
            <th>Cambiar fecha</th>
            <th>Eliminar</th>
          </tr>
        </thead>
        <tbody>
          {!!evaluaciones && evaluaciones.map((item,index)=>{
            return(
              <tr key={index} >
                <td>{index+1}</td>
                <td>{item.nombre}</td>
                <td>{item.Docente}</td>
                <td>{item.FechaActivo} / {item.FechaInactivo}</td>
                <td>{item.EstadoInstrumento=='1'?` ${ICON.CHECKED}` :` ${ICON.UNCHECKED}`}</td>
              {item.EstadoInstrumento=='1'?
                <>
                  <td></td>
                  <td></td>
                </>
                :
                <>
                  <td onClick={()=>handleEditClick(item)}> <i className="bi bi-pencil"></i></td>
                  <td onClick={()=>handleDeleteClick({...item,index:(index+1)})} ><i className="bi bi-trash3"></i></td>
                </>
              }

              </tr>
            )
          }

          )}
        </tbody>
      </Table>
    </Card>

      <Modal
        show={isOpenModalEvaluacion}
        onHide={()=>setIsOpenModalEvaluacion(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Actualizar fecha de presentación
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
        
          <label>Fecha inicial</label>
          <Input
            name={'FechaActivo'}
            onChange={onHandleChange}
            value={dateRangeEvaluacion.FechaActivo || '' }
            type={'date'}
          ></Input>
          <br></br>

          <label>Fecha limite</label>
          <Input
          onChange={onHandleChange}
          name={'FechaInactivo'}
          value={dateRangeEvaluacion.FechaInactivo ||  '' }
          type={'date'}
          ></Input>
              
        </Modal.Body>
        <Modal.Footer>
        <Button
          variant="secondary" 
          onClick={()=>setIsOpenModalEvaluacion(false)}
          >
          Cancelar
        </Button>
        <Button 
          onClick={onUpdateDate} 
        >
          Actualizar
        </Button>
        </Modal.Footer>
        
      </Modal>

      <Modal
      show={isOpenModalEvaluacionDelete}
      onHide={()=>setIsOpenModalEvaluacionDelete(false)}
      >
        <Modal.Body>
          ¿ Estas seguro que desea eliminar la evaluación asignada?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={()=>setIsOpenModalEvaluacionDelete(false)}
          >Cancelar
          </Button>

          <Button
            variant="danger"
            onClick={onDeleteEvaluacion}  
          >Eliminar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export { ListadoEvaluacionesEstudiante }