import { useLocation } from "react-router-dom"
import { Card } from "../../../components/card/Card"
import { DynamicForm } from "../../../components/DynamicForm/DynamicForm"
import { SubTitle } from "../../../shared/components/subtitle/SubTitle"

function Evaluacion () {

  const {state:{evaluacion}} = useLocation()
  const retroceder=()=>{
    setTimeout(() => {
      window.history.go(-1)
    }, 3000);
  }

  return (
    <>
 ¿
    <Card>
      <SubTitle>Docente {evaluacion.PrimerNombres} {evaluacion.PrimerApellidos} {evaluacion.SegundoApellidos}</SubTitle>
    </Card>
    <Card>
      <DynamicForm
        parentField = { null}
        ventanaFormName={evaluacion.nombre }
        titleForm={evaluacion.nombre }
        extraData={{IdGestionInstrumento:evaluacion.IdGestionInstrumento}}
        endPointSubmit={'instrumento'}
        isContentTypeFormData={'0'}
        isCreate={true}
        justDynamicForm={{flag:true,idForm:evaluacion?.IdForm}}
        accionMysql={()=>retroceder()}
      ></DynamicForm>

    </Card>
    </>
  )
}

export { Evaluacion }