import { useNavigate } from "react-router-dom"
import { DynamicFormContainer } from "../../../components/DynamicForm/DynamicFormContainer";
import { Card } from "../../../components/card/Card"
import { DynamicTable } from "../../../components/DynamicForm/dynamicTable/DynamicTable"
import Swal from "sweetalert2";


const ConsultaProgramaSeguridadAccidentes=()=>{
  const Navigate=useNavigate()
  
  const Notificar=(e)=>{
    Navigate("accidentes_notificacion_seguridad",{state:{alerta:e}})
  }

  /* const mostrarAlerta=()=>{
    Swal({
      title: "Aviso!",
      text: "Este curso se encuentra a punto de vencer",
      icon: "warning",
      button: "Aceptar"
    });
  } */
   return(
    // <Card>
    //   <h2>Cursos</h2>
    //   <DynamicTable
    //             endPoint={'programaseguridad'}
    //             titleColumn={[
    //                 'N°',
    //                 'Codigo Programa',
    //                 'Descripción',
    //                 'Horas',
    //                 'Tipo De Programa',
    //                 'Vigencia',
    //                 'Acciones'
    //             ]}
    //             attributes={[
    //                 'COD_PROGRAMA',
    //                 'DESCRIPCION',
    //                 'HORAS',
    //                 'TIPO_PROGRAMA',
    //                 'VIGENCIA'
    //             ]}
    //             labelOptionClick='Notificar'
    //             handleOptionClick={Notificar}
    //   />
    // </Card>
    <DynamicFormContainer idForm={'103'}
      labelOptionClick='Notificar'
      handleOptionClick={Notificar}
    />
   )
}
export { ConsultaProgramaSeguridadAccidentes };

