import { useNavigate } from "react-router-dom";
import { LocalStorage } from "../../../services";
import { DynamicTable } from "../../../components/DynamicForm/dynamicTable/DynamicTable";
import { Card } from "react-bootstrap";


function ProgramasADictar() {

  const api = new LocalStorage();
  const idUsuario = api.getAsJsonItem("usuario")?.usuario?.IdUsuario || 'localstorage_empty'
  const urlProgramas = `coordinadores/cursosActivos?IdUsuario=${idUsuario}`;
  const navigate = useNavigate();

  const onNavigate = (programa) => {
    navigate(`${programa.IdOferta}/asignaturas`,{state:{programa,idUsuario}});  
  }

  return (
    <>
    <Card>
      <Card.Title>Programas activos</Card.Title>
    </Card>
    <DynamicTable
      titleColumn={['N°','Nombre','Descripción', 'Acción']}
      attributes={['Nombre','Descripcion']}
      endPoint={urlProgramas}
      onDelete={false}
      onUpdate={false}
      labelOptionClick={`Ver más`}
      handleOptionClick={onNavigate}
      classNameOptionIcon={`bi bi-view-list`}
    ></DynamicTable>
    </>
  )
}

export  { ProgramasADictar };