import React, { useEffect, useState } from 'react';

import { Select } from '../../select/Select';
import { Button } from '../../button/button';
import { Input } from '../../input/input';
import { ButtonsContainer } from '../../componentsForm/buttonsContainer/buttonsContainer';
import { LeftButtonContainer } from '../../componentsForm/leftButtonContainer/leftButtonContainer';
import { RightButtonContainer } from '../../componentsForm/rightButtonContainer/rightButtonContainer';
import { FormContainer } from '../../componentsForm/formContainer/FormContainer';

import ApiDashboard from '../../../services/api/dashboard';
import { alertaSinBoton, alertaTimer } from '../../../helpers/alertas';
import { Modal } from '../../modal/Modal';
import { Table } from '../../componentsForm/table/Table';
import { ConvocatoriaFaseContainer } from '../convocatoriaFase/ConvocatoriaFaseContainer';
import { AdmisionDetalle } from './AdmisionDetalle';
import { AddButton } from '../../componentsForm/addButton/addButton';

// modal type : create-delete-update

const Admision = () => {
    const [descripcion, setDescripcion] = useState('');
    const [idConvocatoria, setIdConvocatoria] = useState('');
    const [idPeriodo, setIdPeriodo] = useState('');
    const [observacion, setObservacion] = useState('');

    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [isOpenModalCreate, setIsOpenModalCreate] = useState(false);
    const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
    const [isOpenModalUpdate, setIsOpenModalUpdate] = useState(false);
    const [isOpenModalFases, setIsOpenModalFases] = useState(false);
    const [itemSelected, setItemSelected] = useState({});
    const [dataTable, setDataTable] = useState([]);
    const [modalType, setModalType] = useState('');
    const endPointLastUrl = 'admision'; /// url for service

    //! llamar list */
    const [list, setList] = useState([]);

    //! LLAMAR Y ASIGNAR LIST
    const fecthDataSelect = async () => {
        const apiSelect = new ApiDashboard();
        const periodo = await apiSelect.getData('periodo');

        if (periodo?.status === 'Token is Expired') {
            periodo('La sesion ha expirado', 'info', '4000');
            return false;
        }
        setList({
            periodo,
        });
    };

    //! LOAD_DATA_API */
    useEffect(() => {
        const fecthData = async () => {
            setLoading(true);
            await fetchDataTable();
            await fecthDataSelect();
            setLoading(false);
        };
        fecthData();
    }, []);

    //! SUBMIT CREATE OR UPDATE
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if (modalType === '/create') {
            createService();
        }
        if (modalType === '/update') {
            updateService();
        }

        setLoading(false);
    };

    //! VALIDACION DE TOKEN
    const fetchDataTable = async () => {
        try {
            const api = new ApiDashboard();
            const data = await api.getData(endPointLastUrl);
            console.log('DATA PARA TABLE', data);
            if (!!data.length) setDataTable(data);
            if (data?.status === 'Token is Expired') {
                alertaTimer('La sesion ha expirado', 'info', '4000');
                console.log('data exp', data);
            }
        } catch (e) {
            console.log('#Error fetching dataTable');
        }
    };

    //! LIMPIAR FORMULARIO
    const cleanFormValues = () => {
        setDescripcion('');
        setIdConvocatoria('');
        setIdPeriodo('');
        setObservacion('');
    };

    //! CLICK EVENTS
    const onCreate = () => {
        cleanFormValues();
        setIsOpenModalCreate(true);
        setModalType('/create');
        console.log('Array Select', list);
    };
    const onDelete = (item) => {
        setItemSelected(item);
        console.log(item, 'on delete selected:');
        setIsOpenModalDelete(true);
        setModalType('/delete');
    };
    const onAddFases = (item) => {
        setItemSelected(item);
        setModalType('/fases');
        setIsOpenModalFases(true);
    };
    const onUpdate = (item) => {
        setItemSelected(item);
        console.log('update item->', item);
        setDescripcion(item.Descripcion);
        setIdPeriodo(item.IdPeriodo);
        setObservacion(item.Observacion);

        setModalType('/update');
        setIsOpenModalUpdate(true);
    };

    //!CLOSE ALL MODALES
    const handleCloseModal = () => {
        setIsOpenModalCreate(false);
        setIsOpenModalUpdate(false);
        setIsOpenModalDelete(false);
        setIsOpenModalFases(false);
    };

    //! SERVICE CREATE
    const createService = async () => {
        try {
            const api = new ApiDashboard();
            const data = await api.createData(
                {
                    Descripcion: descripcion,
                    IdPeriodo: idPeriodo,
                    Observacion: observacion,
                },
                endPointLastUrl
            );
            console.log('data create', data);
            if (!!data.length) await setDataTable(data);
            if (data?.status === 'Token is Expired') {
                alertaTimer('La sesión ha expirado', 'info', '4000');
                console.log('data exp', data);
            }
            setIsOpenModalCreate(false);
            alertaSinBoton(
                'Se ha creado con éxito',
                'success',
                'center',
                '2000'
            );
            fetchDataTable();
            cleanFormValues();
        } catch (e) {}
    };

    //! SERVICE DELETE
    const deleteService = async () => {
        try {
            console.log('datain delete serivce exp', itemSelected);
            const api = new ApiDashboard();
            const data = await api.deleteData(
                {
                    Estado: 0,
                },
                itemSelected.CodAdmision,
                endPointLastUrl
            );
            if (!!data.length) setDataTable(data);
            if (data?.status === 'Token is Expired') {
                alertaTimer('La sesion ha expirado', 'info', '4000');
            }
            setIsOpenModalCreate(false);
            handleCloseModal();
            alertaSinBoton(
                'Se ha eliminado con Exito',
                'success',
                'center',
                '2000'
            );

            fetchDataTable();
            cleanFormValues();
            setItemSelected({});
        } catch (e) {
            console.log('error delete');
        }
    };

    //! SERVICE UPDATE
    const updateService = async () => {
        try {
            const api = new ApiDashboard();
            const data = await api.updateData(
                {
                    Descripcion: descripcion,
                    IdPeriodo: idPeriodo,
                    Observacion: observacion,
                },
                itemSelected.CodAdmision,
                endPointLastUrl
            );
            if (!!data.length) setDataTable(data);
            if (data?.status === 'Token is Expired') {
                alertaTimer('La sesion ha expirado', 'info', '4000');
                console.log('data exp', data);
            }
            setIsOpenModalUpdate(false);
            alertaSinBoton(
                'Se ha actualizado con Exito',
                'success',
                'center',
                '2000'
            );
            fetchDataTable();
            setItemSelected({});
        } catch (error) {}
    };

    //!FORM
    const Form = () => {
        return (
            <>
                {/* ***FORM CREATE AND UPDATE**** */}
                <form onSubmit={handleSubmit}>
                    <div className="container">
                        <div className="row">
                            <Input
                                label="Descripción"
                                type="text"
                                onChange={setDescripcion}
                                value={descripcion}
                                required={true}
                            />
                            <Select
                                id="IdPeriodo"
                                name="IdPeriodo"
                                onChange={setIdPeriodo}
                                value={idPeriodo}
                                label="Periodo"
                                required={true}
                                optionValue="IdPeriodo"
                                optionName="Descripcion"
                                options={list.periodo}
                            />
                            <Input
                                label="Observacion"
                                type="text"
                                onChange={setObservacion}
                                value={observacion}
                                required={true}
                            />
                        </div>
                    </div>
                    <ButtonsContainer>
                        <LeftButtonContainer>
                            <Button type="back" onClick={handleCloseModal}>
                                Cerrar
                            </Button>
                        </LeftButtonContainer>
                        <RightButtonContainer>
                            {modalType === '/update' && (
                                <Button type="send" loading={loading}>
                                    Actualizar
                                </Button>
                            )}

                            {modalType === '/create' && (
                                <Button type="send" loading={loading}>
                                    Crear
                                </Button>
                            )}
                        </RightButtonContainer>
                    </ButtonsContainer>
                </form>
            </>
        );
    };

    return (
        // MAIN CONTAINER
        <FormContainer progress="45" style={{ width: '60%' }}>
            {/* ***CREATE BUTTON**** */}
            <AddButton
                buttonTitle="Agregar Admisión"
                createModal={onCreate}
                formTitleValue="Sucursal"
            />

            {/* TABLE */}
            <Table
                titleColumn={[
                    'Id',
                    'Descripcion',
                    'Periodo',
                    'Observacion',

                    'Acción',
                ]}
                onDelete={onDelete}
                onUpdate={onUpdate}
                onDetails={onAddFases}
                iconTitleDetails={'bi-aspect-ratio'} //Icono de detalles
                data={dataTable}
                attributes={['Descripcion', 'Periodo', 'Observacion']} //codigo
            ></Table>

            {/* ****** MODALES ******  */}

            <Modal isOpen={isOpenModalCreate} close={handleCloseModal}>
                {Form()}
            </Modal>
            <Modal isOpen={isOpenModalUpdate} close={handleCloseModal}>
                {Form()}
            </Modal>

            <Modal close={handleCloseModal} isOpen={isOpenModalFases}>
                {/* <AddFases
                itemSelected={itemSelected}
                ></AddFases> */}
                <AdmisionDetalle itemSelectedx={itemSelected}></AdmisionDetalle>
            </Modal>

            <Modal close={handleCloseModal} isOpen={isOpenModalDelete}>
                <h1 style={{ marginBottom: '4rem' }}>
                    Estas seguro que deseas eliminar "
                    {itemSelected.Descripcion || ''}" ?{' '}
                </h1>
                <button onClick={deleteService} className="btn btn-danger">
                    Si
                </button>
                <button
                    style={{ marginLeft: '2rem' }}
                    onClick={handleCloseModal}
                    className="btn btn-secondary"
                >
                    No
                </button>
            </Modal>
        </FormContainer>
    );
};

export { Admision };
