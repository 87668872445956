import React from 'react'
import { useNavigate } from 'react-router-dom'
import { LocalStorage } from '../../../services'
import { SubTitle } from '../../../shared/components/subtitle/SubTitle'
import { Card } from '../../card/Card'

import { AddButton } from '../../componentsForm/addButton/addButton'
import { DynamicTable } from '../../DynamicForm/dynamicTable/DynamicTable'

const ActiveCourses = () => {
    const ls = new LocalStorage()
    const IdUsuario = ls.getAsJsonItem('usuario').usuario.IdUsuario
    
    const navigate = useNavigate()

    const titleColumnsTable = [
        'N°',
        'Programas y actividades',
        'Mecanismo de ingreso',
        'Cupos',
        'Acciones'
    ]

    const attributes = [
        'Nombre',
        'NombrePlanEstudio',
        'horas',
    ]

    const onNavigateDetails = (item) => {
        navigate(
            `lista-estudiantes/${item.IdOferta}`, 
            { state: { studentsList: item } }
        ) //to StudentsList.jsx
    }

    return (
        <>
            <AddButton />
            <Card>
                <SubTitle>Cursos activos</SubTitle>
            </Card>
            <DynamicTable
                titleColumn={ titleColumnsTable }
                attributes={ attributes }
                endPoint={`cursos/activos?IdUsuario${IdUsuario}` }
                onDelete={ false }
                onUpdate={ false }
                labelOptionClick={ 'Lista de estudiantes' }
                handleOptionClick={ onNavigateDetails }
                classNameOptionIcon='bi bi-view-list'
            />
        </>
    )
}

export { ActiveCourses }