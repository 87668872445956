import { DynamicFormContainer } from "../../../../components/DynamicForm/DynamicFormContainer";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, Col, Modal, Row, Alert } from "react-bootstrap";
import { DynamicTable } from "../../../../components/DynamicForm/dynamicTable/DynamicTable";
import { DynamicForm } from "../../../../components/DynamicForm/DynamicForm";
import { useState } from "react";
import { FormService } from "../../../../services/api/formService";
import { alerta } from "../../../../helpers/alertas";
import { useDispatch, useSelector } from "react-redux";
import { setItemSelected,setForm } from "../../../../components/DynamicForm/FormSlice";
import { useEffect } from "react";
import { BASE_URL } from '../../../../services/api/config';
import DowloadFile from '../../../../components/DowloadFile/DowloadFile';
import DowloadFileText from '../../../../components/DowloadFileText/DowloadFileText';
import { BsArrowReturnRight,BsFillFilePdfFill,BsFillFileExcelFill } from 'react-icons/bs';
import { Totales } from "../../../../components/DynamicForm/dynamicTable/Totales";
import { Input } from "../../../../components/input/input";
import { Form } from "react-bootstrap";
import { FormCheck } from "react-bootstrap";

const Malla=({})=>{
    const location=useLocation();
    const navigate=useNavigate();
    const api=new FormService()
    const dispatch = useDispatch()
    const planEstudio=location.state.planEstudio
    const idPlandeEstudio=location.state.planEstudio.IdPlandeEstudio
    const urlPlanesEstudios=`malla?IdPlandeEstudio=${idPlandeEstudio}`
    const [modal,setModal]=useState();
    const [modalEliminar,setModalEliminar]=useState();
    const [modalActualizar,setModalActualizar]=useState();
    const [endPoint,setEndPoint]=useState(urlPlanesEstudios);
    const itemSelected = useSelector((state) => state.form.itemSelected)
    const [idForm,setIdForm]=useState(null);
    const [notaHabilitacion, setNotaHabilitacion] = useState()
    const [habilitar, setHabilita] = useState(false)
    const [i, setI] = useState(0)

    useEffect(()=>{
        console.log(planEstudio)
        if(planEstudio.IdDimension==1){
            setIdForm(112)
        }else{
            setIdForm(32)
        }
    },[])
    

    const actualizar=()=>{
        setTimeout(() => {
            setModal(false)
            setModalActualizar()
            setEndPoint(`${urlPlanesEstudios}&i${Math.random()}`)
            setTimeout(() => {
                setI(Math.random())
            },3000);
        }, 2000);
    }

    const eliminar=async()=>{
        const data={
            "Estado":0
        }
        const respuesta=await api.update("malla",data,itemSelected.IdMalla)
        if(respuesta.respuesta=="exito"){
            actualizar()
            setModalEliminar()
            alerta(respuesta.mensaje)
        }else{
            alerta("Fallo desconocido")
        }
        setTimeout(() => {
            setI(Math.random())
        },3000);
    }
   
    return(
        <>
        <Card>
            <Row>
                <Col>
                    <Card.Title>Malla Curricular</Card.Title>
                </Col>
                <Col style={{textAlign:"right"}}>
                    {/* <Button
                        style={{margin:2}}
                        variant="primary"
                        onClick={() => document.querySelector('.exportar').click()}
                    >
                        <DowloadFileText
                        nameFile={"Plan de Estudio.pdf"}
                        name={"Exportar.pdf"}
                        colortext={"#ffffff"}
                        icon={<BsFillFilePdfFill />}
                        classname="exportar"
                        endPoint={`${BASE_URL}/planeEstudio/descargar?IdPlandeEstudio=${planEstudio.IdPlandeEstudio}`}
                    />
                    </Button> */}
                    <Button 
                        onClick={()=>{
                            setModal(true)
                            dispatch(setItemSelected(null)) 
                        }}
                    >
                        Vincular Asignatura
                    </Button>
                </Col>
            </Row>
        </Card>
        <Alert variant={'info'}>
            Recuerde que el porcentaje total de pesos debe sumar 100% para garantizar un cálculo adecuado del promedio general.
        </Alert>
        {planEstudio.IdDimension!=1 &&
            <>
            <DynamicTable
                endPoint={endPoint}
                titleColumn={["N°","Código","Asignatura","Horas","Peso","Nota Aprueba","Nota Habilita","Acciones"]}
                attributes={["CodigoExt","Asignatura","horas","Peso","Nota_aprueba","Nota_habilita"]}
                labelOptionClick="Unidades"
                handleOptionClick={(e)=>navigate("unidades",{state:{malla:e}})}
                /* labelOptionClick2="Requisitos IES"
                handleOptionClick2={(e)=>navigate("requisitos",{state:{malla:e,planEstudio:planEstudio}})} */
                actualizarExterno={(e)=>{
                    setModalActualizar(true)
                    dispatch(setItemSelected(e))
                    dispatch(setForm({llave_primaria:"IdMalla",form_data:"0"}))
                }}
                elimimarExterno={(e)=>{
                    setModalEliminar(true)
                    dispatch(setItemSelected(e))
                }}
            />
            <Totales IdPlanEstudio={planEstudio.IdPlandeEstudio} i={i} />
            
            </>
        }
        

        <Modal 
            show={modal}
            onHide={()=>setModal()}
        >
            <DynamicForm
                title={`Vincular Asignatura`}
                endPointSubmit={`malla`}
                onClickCancelBtn={setModal}
                justDynamicForm={{ flag: true, idForm:idForm }}
                isCreate={true}
                extraData={{IdPlandeEstudio:idPlandeEstudio, IdTipoCalificacion:1, Nota_habilita: notaHabilitacion, Habilita: habilitar}}
                accionMysql={()=> {
                    actualizar()
                    // setTimeout(() => {
                    //     window.location.reload();
                    // }, 4000)
                }}
            >
                <Form.Check 
                    name="Habilita"
                    id="Habilita"
                    label="Habilita"
                    value={habilitar}
                    //checked={habilitar}
                    onChange = {(e) => {
                        if(e.target.checked) {
                            setHabilita(true)
                        } else {
                            setHabilita(false)
                        }
                        console.log(habilitar);
                    }}
                />
                {habilitar && 
                <Input
                    label={"Nota para Aprobar la Habilitación"}
                    type={'number'}
                    value={notaHabilitacion}
                    onChange={(e) => setNotaHabilitacion(e)}
                    required={true}
                />
                }
            </DynamicForm>            
        </Modal>

        {/* modal eliminar */}
        <Modal
            show={modalEliminar}
            onHide={()=>setModalEliminar()}
        >
            <Modal.Body>
                <b>¿Desea eliminar esta asigantua vinculada?</b>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={()=>setModalEliminar()}
                >
                    Cancelar
                </Button>
                <Button
                    variant="danger"
                    onClick={()=>eliminar()}
                >
                    Eliminar
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal
            show={modalActualizar}
            onHide={setModalActualizar}
        >
            <DynamicForm
                title={`Editar Malla`}
                endPointSubmit={`malla`}
                onClickCancelBtn={setModalActualizar}
                justDynamicForm={{ flag: true, idForm:idForm }}
                isCreate={false}
                extraData={{IdPlandeEstudio:itemSelected?.IdPlandeEstudio, IdTipoCalificacion:1, Nota_habilita: notaHabilitacion, Habilita: habilitar}}
                accionMysql={()=>{
                    actualizar()
                    // setTimeout(() => {
                    //     window.location.reload();
                    // }, 4000)
                }}
                // isContentTypeFormData={`1`}
            > 
                <Form.Check 
                    name="Habilita"
                    id="Habilita"
                    label="Habilita"
                    value={habilitar}
                    // checked={habilitar}
                    onChange = {(e) => {
                        if(e.target.checked) {
                            setHabilita(true)
                        } else {
                            setHabilita(false)
                        }
                    }}
                />
                {habilitar && 
                <Input
                    label={"Nota para Aprobar la Habilitación"}
                    type={'number'}
                    value={notaHabilitacion}
                    onChange={(e) => setNotaHabilitacion(e)}
                    required={true}
                />
                }
            </DynamicForm>            
        </Modal>

        </>
    )
}
export  default Malla;