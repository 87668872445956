import { Card } from "../../../../components/card/Card"
import { DynamicTable } from "../../../../components/DynamicForm/dynamicTable/DynamicTable"

const Alertas=()=>{

    return(
        <Card>
        <h2>Asignaturas por Terminar</h2>
            <DynamicTable 
                endPoint={'alertas/asigaturasTermiando'}
                titleColumn={["#","Descripción","Acciones"]}
                attributes={["Descripcion"]}
                onDelete={false}
                onUpdate={false}
            />
        </Card>
    )
}

export {Alertas}