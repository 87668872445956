import { Card, Modal, Nav, Tab, Tabs } from "react-bootstrap"
import { useLocation } from "react-router-dom"
import { DynamicTable } from "../../../components/DynamicForm/dynamicTable/DynamicTable";
import { Title } from "../../../shared/components/title/Title";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../services/api/config";
import ActividadExterna from "../ActividadExterna/ActividadExterna";
import { useState } from "react";
import DowloadFile from "../../../components/DowloadFile/DowloadFile";

const PerfilEstudiante = ({
  full = true
}) => {
  const location = useLocation();
  const idUsuario = location.state.estudiante.IdUsuario
  const numeroDocumento = location.state.estudiante.NumeroDocumento
  const navigate = useNavigate();
  const urlHistoriaAcademica = `${BASE_URL}/estudiante/historialAcademico?IdUsuario=${idUsuario}`
  const urlActividadesAcademicas = `${BASE_URL}/estudiante/certificadoActividadesAcademicas?IdUsuario=${idUsuario}`
  const urlHistorialActiviadesExternas = `${BASE_URL}/estudiante/historialActividadesExternas?IdUsuario=${idUsuario}`
  const [modalActividad, setModalActividad] = useState(null)
  const [actividad, setActividad] = useState(null)

  const verAsignaturas = (e) => {
    navigate("asignaturas", { state: { programa: e, estudiante: e } })
  }

 
  return (
    <>
      <Card>
        <Title>
          {location.state.estudiante.PrimerNombres}{' '}
          {location.state.estudiante.SegundoNombre}{' '}
          {location.state.estudiante.PrimerApellidos}{' '}
          {location.state.estudiante.SegundoApellidos}
        </Title>
      </Card>
      <Card>
        <Tab.Container defaultActiveKey="actividades">
          <Nav variant="pills">
            <Nav.Item>
              <Nav.Link eventKey="actividades">Actividades Académicas</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="tercerizadas">Actividades Tercerizadas</Nav.Link>
            </Nav.Item>
            {full&&
                <Nav.Item>
                    <Nav.Link eventKey="canceladas">Inscripciones eliminadas</Nav.Link>
                </Nav.Item>
            }
            <Nav.Item>
              <Nav.Link eventKey="reportes">Reportes</Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey="actividades">
              <DynamicTable
                titleColumn={["Id", "Nombre", "Fecha", "Acciones"]}
                attributes={["Nombre", "Fecha"]}
                endPoint={`estudiante/historialEstudiante?IdUsuario=${idUsuario}`}
                labelOptionClick="Ver más"
                handleOptionClick={(e) => verAsignaturas(e)}
                onDelete={false}
                onUpdate={false}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="tercerizadas">
              <DynamicTable
                titleColumn={["Id", "Nombre", "Fecha", "Acciones"]}
                attributes={["NOMBRE_CURSO", "FECHA_INICIO"]}
                labelOptionClick="Ver registro"
                handleOptionClick={(e) => {
                  setActividad(e)
                  setModalActividad(true)
                }}
                endPoint={`estudiante/actividadesExternas?IdUsuario=${idUsuario}`}
                onDelete={false}
                onUpdate={false}
              />
            </Tab.Pane>
            {full&&
                <Tab.Pane eventKey="canceladas">
                    <DynamicTable
                        titleColumn={["Id","Nombre","Fecha","Acciones"]}
                        attributes={["NombrePlanEstudio","FECHA_INICIO"]}
                        labelOptionClick="Ver más"
                        endPoint={`estudiante/inscripcionesEliminadas?IdUsuario=${idUsuario}`}
                        onDelete={false}
                        onUpdate={false}
                    />
                </Tab.Pane>
            }
            
            <Tab.Pane eventKey="reportes">
              <Card>
                <DowloadFile
                  nameFile={"Historia académica.pdf"}
                  name={"Historia académica"}
                    endPoint={urlHistoriaAcademica}
                />
                <DowloadFile
                  nameFile={"Certificado Actividades Academicas.pdf"}
                  name={"Certificado Actividades Académicas"}
                    endPoint={urlActividadesAcademicas}
                />
                {/* <DowloadFile
                    nameFile={"Historial de actividades externas.pdf"}
                    name={"Historial de actividades externas"}
                    endPoint={urlHistorialActiviadesExternas}
                /> */}
                </Card>
            </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
        </Card>
        <Modal
            show={modalActividad}
            onHide={setModalActividad}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Actividad externa
                </Modal.Title>
            </Modal.Header>
            <ActividadExterna actividad={actividad}/>
        </Modal>
        </>
    )
}

export { PerfilEstudiante }
