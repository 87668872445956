import React, { useState } from 'react'
import { Button, Card, Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { DynamicForm } from '../../../components/DynamicForm/DynamicForm'
import { DynamicTable } from '../../../components/DynamicForm/dynamicTable/DynamicTable'
import { 
    setForm,
    setItemSelected 
} from '../../../components/DynamicForm/FormSlice'
import { alerta } from '../../../helpers/alertas'
import { FormService } from '../../../services/api/formService'
import { LocalStorage } from '../../../services'
import { DynamicSearch } from '../../../components/DynamicForm/DynamicSearch/DynamicSearch'
import { BASE_URL } from '../../../services/api/config'
import { useEffect } from 'react'

export default function Convocatorias() {
    const [modalEliminar, setModalEliminar] = useState(false)
    const [modalEditar, setModalEditar] = useState(false)
    const [modalCrear, setModalCrear] = useState(false)
    const itemSelected = useSelector((state) => state.form.itemSelected)
    const api = new FormService();
    const storage= new LocalStorage();
    const dimension=storage.getItem("dimension")
    const url="convocatoria";
    const [enpooint, setEnpoint] = useState(url)
    const navigate=useNavigate();
    const [camposForm, setCamposForm] = useState()
    const [urlExportar, setUrlExportar] = useState(`${BASE_URL}/exportarConvocatorias`)
    const [modalDuplicar, setModalDuplicar] = useState(false)
    const [idConvocatoriaDuplicar, setIdConvocatoriaDuplicar] = useState(null)

    const dispatch = useDispatch()

    const eliminarConvocatoria = async() => {
        const data={
            "Estado":0
        }
        const respuesta=await api.update("convocatoria",data,itemSelected.IdConvocatoria)
        if(respuesta.respuesta=="exito"){
            setModalEliminar()
            dispatch(setItemSelected(null))
            alerta(respuesta.mensaje)
            cerrarModales()
        }else{
            alerta("Fallo desconocido")
        }
    }
    const cerrarModales = () => {
        setModalEliminar(false)
        setModalEditar(false)
        setModalCrear(false)
        setTimeout(() => {
            dispatch(setItemSelected(null))
            setEnpoint(`${url}?i${Math.random()}`)
        }, 3000);
    }
    
    const onSearch = (urlsParams) => {
        setEnpoint(`${url}${urlsParams}`)
        setUrlExportar(urlExportar+urlsParams)
    }

    const fetchCamposFormForFilter = ()=>{
        
          api.getAll(`xcampos?id_formulario=31`)
        .then((res)=>{
            setCamposForm(res)
  
        })
      
    }

    useEffect(() => {
        fetchCamposFormForFilter()
    }, [])

    const duplicarConvocatoria = async (e) => {
        const data={
            "IdConvocatoria":idConvocatoriaDuplicar
        }
        const respuesta=await api.create("convocatoria/duplicar",data)
        if(respuesta.respuesta=="exito"){
            alerta(respuesta.mensaje)
            cerrarModales()
            setModalDuplicar(false)
            
        }else{
            alerta("Fallo desconocido")
        }
    }

  return (
    <>
        <Card>
            <Row>
                <Col>
                {dimension=='"true"'?
                    <Card.Title> Etapas ES </Card.Title>
                    :
                    <Card.Title> Convocatorias </Card.Title>

                }
                </Col>
                <Col style={{textAlign:"right"}}>
                    <Button  onClick={()=>{
                        setModalCrear(true)
                        dispatch(setItemSelected(null))
                        }}>Crear</Button>
                </Col>
            </Row>
        </Card>
        
        <Card>
            {camposForm &&
                <DynamicSearch onSearch={onSearch} camposForm={camposForm} />
            }
        </Card>

        {/* tabla para mostras convocatoriías */}
        <DynamicTable
            exportar={urlExportar}
            idTable="1"
            endPoint={enpooint}
            titleColumn={["Nombre","Acciones"]}
            attributes={["Nombre"]}
            showIndexColumn ={false}
            elimimarExterno={(e)=>{
                setModalEliminar(true)
                dispatch(setItemSelected(e))
            }}
            actualizarExterno={(e)=>{
                dispatch(setItemSelected(e))
                dispatch(setForm({llave_primaria:"IdConvocatoria",form_data:"0"}))
                setModalEditar(true)
            }}
            labelOptionClick={dimension=='"true"' ? "Etapas" : "Fases"}
            handleOptionClick={(e)=>{
                navigate("fases", { state: e });
            }}
            handleOptionClick2={(e)=>{
                setModalDuplicar(true)
                setIdConvocatoriaDuplicar(e.IdConvocatoria)
            }}
            labelOptionClick2={"Duplicar"}

        />
        {/* moodal eliminar */}
        <Modal show={modalEliminar} onHide={()=>setModalEliminar()}>
            <Modal.Header closeButton>
                <Modal.Title>Eliminar este Registro</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                ¿Está seguro que desea eliminar el registro?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={()=>setModalEliminar()}>
                    Cerrar
                </Button>
                <Button variant="danger" onClick={()=>eliminarConvocatoria()}>
                    Eliminar
                </Button>
            </Modal.Footer>
        </Modal>

        {/* modal crear convocatoria */}
        <Modal show={modalCrear} onHide={()=>setModalCrear()}>
                <DynamicForm
                    title={"Formulario para Crear"}
                    // idForm={31}
                    justDynamicForm={{flag:true,idForm:31}}
                    endPointSubmit={"convocatoria"}
                    onClickCancelBtn={setModalCrear}
                    idItem={null}
                    isCreate={true}
                    accionMysql={cerrarModales}
                />
        </Modal>
        {/* modal actulizar */}
        <Modal show={modalEditar} onHide={()=>setModalEditar()}>
                <DynamicForm
                    title={"Formulario para Actualizar"}
                    justDynamicForm={{flag:true,idForm:31}}
                    isContentTypeFormData={false}
                    extraData={{IdConvocatoria:itemSelected?.IdConvocatoria}}
                    endPointSubmit={`convocatoria`}
                    onClickCancelBtn={setModalEditar}
                    isCreate={false}
                    accionMysql={cerrarModales}
                />
        </Modal>
        {/* modal para confirmar la duplicacion de la convocatoria */}
        <Modal show={modalDuplicar} onHide={()=>setModalDuplicar()}>
            <Modal.Header closeButton>
                <Modal.Title>Duplicar Etapa</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                ¿Está seguro que desea duplicar la etapa con sus items?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={()=>setModalDuplicar()}>
                    Cerrar
                </Button>
                <Button variant="danger" onClick={()=>duplicarConvocatoria()}>
                    Duplicar
                </Button>
            </Modal.Footer>
        </Modal>

    </>
  )
}
