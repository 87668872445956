import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { AddButton } from "../../../components/componentsForm/addButton/addButton"
import { useFetchGet } from "../../../hooks/useFetchGet"
import { Title } from "../../../shared/components/title/Title"
import { Card, Table } from "react-bootstrap"
import { ICON } from "../../../constants/icons"
import { Button } from "../../../components/button/button"
import { SubTitle } from "../../../shared/components/subtitle/SubTitle"

function ListadoEvaluaciones () {

  const {state:{programa}} = useLocation()
  // const IdUsuario = ls.getAsJsonItem('usuario').usuario.IdUsuario
  const [evaluaciones,isLoading,error] = useFetchGet(`gestionInstrumentos/evaluacionesParaEstudiantes?IdEstudiante=${programa.IdEstudiante}&IdOferta=${programa.IdOferta}`)

  const [isOpenModalEvaluacion,setIsOpenModalEvaluacion] = useState(false)
  const [ itemSelected,setItemSelected] = useState({})
  const [isOpenModalAsignarEvaluacion,setIsOpenModalAsignarEvaluacion] = useState(false)

  const handleOptionClick = (item) => {
    setItemSelected(item)
    setIsOpenModalEvaluacion(true)
  }

  return(
    <>
    <Card>
      <Card.Title>{programa.Nombre} </Card.Title>
      <p>Listado Evaluaciones</p>
    </Card>
    <Card>

    <Table striped bordered hover>
  <thead>
    <tr>
      <th>N°</th>
      <th>Instrumento</th>
      <th>Docente</th>
      <th>Asignatura</th>
      <th>Plazo (A/M/D)</th>
      <th>Realizar evaluación</th>
    </tr>
  </thead>
  <tbody>
    {!!evaluaciones && evaluaciones.map((item,index)=>{
      return(
        <tr key={index} onClick={()=>handleOptionClick(item)}>
          <td>{index+1}</td>
          <td>{item.nombre}</td>
          <td>{item.Docente}</td>
          <td>{item.Asignatura}</td>
          <td> {item.FechaActivo} / {item.FechaInactivo}</td>
          <td>
          <Button
            hasButtonCss={false}
            className="btn btn-outline-primary"
            isLink={true}
            to={`${item.id}`}
            state={{evaluacion:item}}
          >Evaluar</Button></td>
        </tr>
      )
    }
    
    )}
  </tbody>
</Table>
    </Card>
    </>
  )
}

export { ListadoEvaluaciones }