import React, { useRef, useState } from 'react'
import { alerta } from '../../helpers/alertas'
import { BASE_URL } from "../../services/api/config"
import { Button, Card, Col, Modal, Row, Table } from 'react-bootstrap'
import { DynamicForm } from '../../components/DynamicForm/DynamicForm'
import { DynamicFormContainer } from '../../components/DynamicForm/DynamicFormContainer'
import { DynamicTable } from "../../components/DynamicForm/dynamicTable/DynamicTable"
import { useNavigate } from "react-router-dom"
import TablePaginate from '../../components/TablePaginate/TablePaginate'
import DowloadFileText from '../../components/DowloadFileText/DowloadFileText'
import { BsArrowReturnRight,BsFillFilePdfFill,BsFillFileExcelFill } from 'react-icons/bs';
import Swal from 'sweetalert2'
import { FormService } from '../../services/api/formService'
import { useDispatch } from 'react-redux'
import { setSearchFields } from '../../shared/store/table/tableSlice'
const CalendarioAcademico=()=>{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const api= new FormService();
    const [modalCrear, setModalCrear] = useState();
    const DataCalendario = [
        ["titulo", "PROMOCIÓN Y DIVULGACIÓN DEL PROGRAMA TECNOLOGÍA EN GESTIÓN DE TRÁNSITO ÁEREO"],
        ["subtitulo", "N°", "Actividad", "Fecha", ""],
        ["encabezado", 1, "Inicio de campaña publicitaria del programa.", "date"],
        ["titulo", "LANZAMIENTO DEL PROGRAMA"],
        ["encabezado", 2, "Acto Protocolarío de lanzamiento del programa.", "date"],
        // Resto de los datos...
      ];
      
      const calendarioArr = [];
      
      DataCalendario.forEach((item) => {
        const obj = {};
        for (let i = 0; i < item.length; i++) {
          if (i === 0) {
            obj["tipo"] = item[i];
          } else {
            obj["dato" + i] = item[i];
          }
        }
        calendarioArr.push(obj);
      });
      
    console.log(calendarioArr);
    // const exportar = (e) => {
    // // abir una nueva pagina con el archivo, hacerlo con javascript
    // window.open(`${BASE_URL}/formatosIES/calendarioAcademico?IdCalendarioAcademico=${e.IdCalendarioAcademico}`, '_blank');
    // }

    // eliminar el calendario con confirmación de alerta
    const eliminarCalendario = async(e) => {
    const result= await Swal.fire({
                    title: '¿Está seguro de eliminar el calendario académico?',
                    text: "¡No podrás revertir esto!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#6c757d',
                    confirmButtonText: 'Si, eliminar',
                    cancelButtonText: 'Cancelar'
                })
        if(result.isConfirmed){
            const response = await api.delete('calendarios',{Estado:0},e)
            if(response){
                dispatch(setSearchFields({i:Math.random()}))
            }
        }
    }


    return(
        <>
        <Card>
            <Row>
                <Col>
                    <Card.Title>Calendario Academico</Card.Title>
                </Col>
                <Col
                    className="d-flex justify-content-end"
                >
                    <Button variant="primary" 
                        onClick={()=>navigate("crear")}>Crear</Button>
                </Col>
            </Row>
        </Card>
        <TablePaginate
            apiUrl="calendarios"
            tableHeaders={[
                {title:"Nombre",attribute:"Nombre"},
            ]}
            s
            rowActions={[
                ({rowData})=>(
                    <>
                    <Button 
                        onClick={()=>navigate(`${rowData.IdCalendario}`,{state:{calendario:rowData}})}
                    >
                        Ver Calendario
                    </Button>
                    <Button
                        style={{margin:2}}
                        variant="primary"
                        onClick={() => document.querySelector('.exportarCalendario').click()}
                    >
                        <DowloadFileText
                            nameFile={"Calendario Academico.pdf"}
                            name={"Exportar.pdf"}
                            colortext={"#ffffff"}
                            icon={<BsFillFilePdfFill />}
                            classname="exportarCalendario"
                            endPoint={`${BASE_URL}/formatosIES/calendarioAcademico?IdCalendario=${rowData.IdCalendario}`}
                        />
                    </Button>
                    </>
                    ),
                    // boton para eliminar
                    ({rowData})=>(
                        <Button
                            variant="danger"
                            onClick={() => eliminarCalendario(rowData.IdCalendario)}
                        >
                            Eliminar
                        </Button>
                    )
            ]}
        />
        </>
    )

}
export {CalendarioAcademico}