import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Solicitudes from './Solicitudes'
import TiposSolicitudes from './TiposSolicitudes'

export default function GestionarSolicitudes() {
  return (
    
    <Routes>
        <Route path='solicitudes' element={<Solicitudes/>}/>
        <Route path='tipos_solicitudes' element={<TiposSolicitudes/>}/>
    </Routes>
  )
}
