import React, { useState, useEffect } from 'react'

import { useLocation, useParams } from 'react-router-dom'
import { FormService } from '../../../services/api/formService'
import { useSubmitCreate } from '../../DynamicForm/hooks/useSubmitCreate'
import { Admission } from './Admission'

const AdmissionContainer = ({
  item: offer,
  aspirante
}) => {

  const [loading, setLoading] = useState(false)
  const [loadingFull, setLoadingFull] = useState(true)
  const [error, setError] = useState(null)
  const [admissionType, setAdmissionType] = useState('')
  const [announcement, setAnnouncement] = useState([])
  const [phase, setPhase] = useState({})
  const [isOpenModalDecline, setIsOpenModalDecline] = useState(false)
  const [isOpenModalAccept, setIsOpenModalAccept] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [observation, setObservation] = useState('')
  const { idApplicant } = useParams()
  const id = useParams()
  const ENDPOINT_PHASE = 'aspiranteFase'
  const URL = `aspiranteFase?IdAspirante=${offer.IdAspirante}&IdConvocatoria=`
  const [fetchDataCreate, statusCreate] = useSubmitCreate(
    'aspiranteFase', 
    null, 
    null,
    '1'
  )

  const DIRECT = 'Directo'
  const AGREEMENT = 'Convenio'
  const ANNOUNCEMENT = 'Convocatoria'

  const fetchDataAnnouncement = async () => {
    try {
      setLoadingFull(true)
      const formService = new FormService()
      const data = await formService.getAll(`${URL}${offer?.IdConvocatoria}`)
      setAnnouncement(data) 
      setLoadingFull(false)
    } catch (err) {
      setError(err)
    }
  }

  const defineAdmissionType = async () => {
    await fetchDataAnnouncement()
    if (["1", "2","5","6"].includes(offer?.IdTipoMecanismo)) setAdmissionType(DIRECT) 
    else if (offer?.IdTipoMecanismo == "3") setAdmissionType(AGREEMENT) 
    else if (offer?.IdTipoMecanismo == "4" || offer?.IdTipoMecanismo == "7") {
        await fetchDataAnnouncement()
        setAdmissionType(ANNOUNCEMENT)  
    }
  }

  useEffect(() => {
    defineAdmissionType()
  }, [])

  const handleSubmit = async (e, item) => {
    e.preventDefault()
    try {
      const formData = new FormData(e.currentTarget)
      formData.set('IdAspirante', offer.IdAspirante)
      formData.set('IdConvocatoriaFase', item?.IdConvocatoriaFase)
      
      await fetchDataCreate(formData)

    } catch (err) {
      setError(err)
    }
  }

  const onAccept = async (item) => {
      setIsOpenModalAccept(!isOpenModalAccept)
      setPhase(item)
      setIsEmpty(false)
      setObservation('')
  }

  const onDecline = (item) => {
    setIsOpenModalDecline(!isOpenModalDecline)
    setPhase(item)
    setIsEmpty(false)
    setObservation('')
  }

  const handleState = async (state) => {
    if (state == 2 && observation.length < 10) {
      setIsEmpty(true)
      return
    }

    const data = {
      IdAspirante: idApplicant,
      IdConvocatoriaFase: phase?.IdConvocatoriaFase,
      Aprobo: state,
    }

    if (state == 2) {
      data.Observaciones = observation
    }

    try {
      setLoading(true)
      const formService = new FormService()
      await formService.create(ENDPOINT_PHASE, data)
      setLoading(false)
      if (state == 1) setIsOpenModalAccept(!isOpenModalAccept)
      if (state == 2) setIsOpenModalDecline(!isOpenModalDecline)
    } catch(err) {
      if (state == 1) setIsOpenModalAccept(!isOpenModalAccept)
      if (state == 2) setIsOpenModalDecline(!isOpenModalDecline)
      setLoading(false)
      setLoadingFull(false)
      setError(err)
    }
  }

  const handleChangeObservation = (value) => {
    setIsEmpty(false)
    setObservation(value)
  }


  return (
    <Admission 
      loadingFull={ loadingFull }
      error={ error }
      admissionType={ admissionType }
      announcement={ announcement }
      isOpenModalDecline={ isOpenModalDecline }
      onDecline={ onDecline }
      isOpenModalAccept={ isOpenModalAccept }
      onAccept={ onAccept }
      observation={ observation }
      handleChangeObservation={ handleChangeObservation }
      isEmpty={ isEmpty }
      loading={ loading }
      handleState={ handleState }
      handleSubmit={ handleSubmit }
      oferta={offer}
    />
  )
}

export { AdmissionContainer }