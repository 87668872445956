import { useEffect } from 'react'
import { useState } from 'react'
import {Button, Col, Modal, Row } from 'react-bootstrap'
import { Checkbox } from '../../../components/componentsForm/checkbox/checkbox'
import { Input } from '../../../components/input/input'
import { Select } from '../../../components/select/Select'
import { SelectDataList } from '../../../components/SelectDataList/SelectDataList'
import { useFetchGet } from '../../../hooks/useFetchGet'
import { AddNewDates } from './parts/AddNewDates'

import { LocalStorage } from '../../../services'

function FormEvento({
    dateStrSelected,
    itemSelected,
    onAddEvent,
    loading,
    onCloseModal,
    formData,
    setFormData,
    isUpdate,
    oferta,
    fecha_actual
}) {

    const storage= new LocalStorage();
    const usuario=JSON.parse(storage.getItem("usuario"))
    const [showAsignatura, setShowAsignatura] = useState(false)
    const [showAddFechas, setShowAddFechas] = useState(false)
    const [listadoFechas, setListadoFechas] = useState([])
    const [newDate, setNewDate] = useState(null)
    const [aulas, loadingOferta, error] = useFetchGet(`aulas/disponibles?IdOferta=${oferta.IdOferta}`)
    const [personalizado,setPersonalizado]=useState(false)
    const [r_fecha,setR_fecha]=useState();

    useEffect(() => {
        console.log(fecha_actual)
        setR_fecha(traformarFecha(fecha_actual))
     
    }, [loading])
    const handleSubmit = async (e) => {
        e.preventDefault()
        let data;
        if(!isUpdate){
             data = {
                IdAula: formData.IdAula,
                IdOferta: oferta.IdOferta,
                IdMalla: formData.IdMalla,
                IdUsuario: formData.IdUsuario,
                Observaciones: formData.Observaciones,
                IdUnidadAsignatura:formData.IdUnidadAsignatura,
                eventoPersonalizado:formData.eventoPersonalizado,
                horaInicial: `${formData.horaInicial}:00`,
                horaFinal: `${formData.horaFinal}:00`,
                fechas: [...listadoFechas, formData.fechaInicial],
                fechas: [...listadoFechas, dateStrSelected],
            }
        }else{
            data = {
                IdAula: formData.IdAula,
                IdOferta: oferta.IdOferta,
                IdMalla: formData.IdMalla,
                IdUsuario: formData.IdUsuario,
                Observaciones: formData.Observaciones,
                IdUnidadAsignatura:formData.IdUnidadAsignatura,
                eventoPersonalizado:formData.eventoPersonalizado,
                horaInicial: `${formData.horaInicial}:00`,
                horaFinal: `${formData.horaFinal}:00`,
                fechas:[formData.Fecha||fecha_actual]
            }
        }
        

        onAddEvent(data, formData)
    }

    const onHandleChange = (value, name) => {
        if (name === 'masFechas' && value === true) {
            console.log('fechasmas', value, name)
            setShowAddFechas(true)
        }
        if (name === 'masFechas' && value === false) {
            console.log('fechasmas', value, name)
            setShowAddFechas(false)
            setListadoFechas([])
        }
        if (name === 'IdOferta') {
            setShowAsignatura(true)
        }
        // console.log(name,value)
        
        setFormData({ ...formData, [name]: value })

        console.log(formData)
    }
    const deleteNewDate = (indexSelected, fechaSelected) => {
        let copyListadoFechas = listadoFechas
        const filtered = copyListadoFechas.filter(
            (fecha, index) => index !== indexSelected
        )
        setListadoFechas([...filtered])
    }
    const onAddNewDate = () => {
        const dateAlreadyExist = listadoFechas.find(
            (fecha) => fecha === newDate
        )
        console.log(newDate, listadoFechas, dateAlreadyExist)
        if (
            newDate !== null &&
            dateAlreadyExist === undefined &&
            newDate !== ''
        ) {
            setListadoFechas([...listadoFechas, newDate])
        }
    }

    const cambairForm=(e)=>{
        setPersonalizado(e)
    }

    const traformarFecha=(fecha)=>{
        // console.log(fecha.slice(0, -9))
        return fecha?fecha.slice(0, -9):null;
    }

    return (
        <>
        <form onSubmit={handleSubmit}>
        <Modal.Body>
                <Checkbox
                    children='Evento Personalizado'
                    onChange={(e)=>{cambairForm(e)}}
                ></Checkbox>

                <Select
                    label={'Aulas'}
                    name={'IdAula'}
                    onChange={onHandleChange}
                    value={formData.IdAula}
                    optionName={'numero'}
                    optionValue={'IdAula'}
                    options={aulas}
                ></Select>
                {!personalizado &&
                    <>
                        <Select
                            label={'Asignatura'}
                            name={'IdMalla'}
                            onChange={onHandleChange}
                            value={formData.IdMalla}
                            optionName={'Asignatura'}
                            optionValue={'IdMalla'}
                            urlOptionsService={`malla?IdOferta=${oferta.IdOferta}`}
                        ></Select>
                        <Select
                            label={'Unidad'}
                            name={'IdUnidadAsignatura'}
                            onChange={onHandleChange}
                            value={formData.IdUnidadAsignatura}
                            optionName={'Nombre'}
                            optionValue={'IdUnidadAsignatura'}
                            urlOptionsService={`unidadesAsignaturas?IdMalla=${formData.IdMalla}`}
                        ></Select>
                    </>
                    
                }
                {personalizado &&
                    <Input
                        label={'Nombre del Evento'}
                        type={'textarea'}
                        value={formData?.eventoPersonalizado}
                        name="eventoPersonalizado"
                        onChange={onHandleChange}
                        required={false}
                    />
                    
                }
                <Row>
                    <SelectDataList
                        label={"Docente"}
                        optionValue={"IdUsuario"}
                        optionName={"Nombre"}
                        onChangeHandler={onHandleChange}
                        name={"IdUsuario"}
                        endPoinLastUrl={"docente/full"}
                        value={formData?.Nombre}
                    /> 
                </Row>
                <Row style={{marginTop:'20'}}>
                    <Col>
                        <b style={{marginTop:'20'}}><i class="bi bi-calendar"></i> Hora Inicial</b>                        
                        <input
                                type="time"
                                className="form-control"
                                placeholder="hora inicial"
                                aria-label="hora-inicial"
                                step="600"
                                value={formData.horaInicial}
                                name="horaInicial"
                                aria-describedby="hora-inicial"
                                onChange={(e) =>
                                    onHandleChange(
                                        e.target.value,
                                        e.target.name
                                    )
                                }
                            />
                    </Col>
                    <Col>
                    <b style={{marginTop:'20'}}><i class="bi bi-calendar"></i> Hora Final</b>
                        <input
                                type="time"
                                step="600"
                                className="form-control"
                                placeholder="hora final"
                                aria-label="horaInicial"
                                value={formData.horaFinal}
                                name="horaFinal"
                                aria-describedby="hora-final"
                                onChange={(e) =>
                                    onHandleChange(
                                        e.target.value,
                                        e.target.name
                                    )
                                }
                            />
                    </Col>
                </Row>
                {!isUpdate&&
                    <Checkbox
                        label={'Repetir semanalmente para todo el semestre'}
                        name={'masFechas'}
                        value={formData.repetir === '1' ? true : false}
                        onChange={onHandleChange}
                    >
                        Repetir para Otras Fechas
                    </Checkbox>
                }
                
                {showAddFechas && (
                    <AddNewDates
                        dateStrSelected={dateStrSelected}
                        itemSelected={itemSelected}
                        setNewDate={setNewDate}
                        listadoFechas={listadoFechas}
                        deleteNewDate={deleteNewDate}
                        onAddNewDate={onAddNewDate}
                    />
                )}
                {isUpdate&&
                    <Input 
                        label="Fecha"
                        type={"date"}
                        name={"Fecha"}
                        onChange={onHandleChange}
                        value={formData.Fecha||fecha_actual}
                    />
                }
                

                <Input
                    label={'Observaciones'}
                    type={'textarea'}
                    value={formData?.Observaciones}
                    name="Observaciones"
                    onChange={onHandleChange}
                    required={false}
                ></Input>
        </Modal.Body>

            <Modal.Footer>
                <Button
                        variant="danger"
                        onClick={onCloseModal}
                    >
                        Cancelar
                    </Button>
                    <Button type="submit" >
                        {isUpdate?
                            <>Actualizar Evento</>
                        :
                            <>Guardar Evento</>
                        }
                        
                        {loading && (
                            <div
                                className="spinner-border spinner-border-sm text-light"
                                role="status"
                            >
                                <span className="sr-only"></span>
                            </div>
                        )}
                    </Button>
                </Modal.Footer>
            </form>
        </>
    )
}

export { FormEvento }
