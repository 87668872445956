import React from 'react'
import { Card } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { DynamicTable } from '../../../components/DynamicForm/dynamicTable/DynamicTable'
import { LocalStorage } from '../../../services'

export default function PeridosActivos() {
    
    const {state:{IdCurso}}=useLocation();
    const ls = new LocalStorage()
    const IdUsuario = ls.getAsJsonItem('usuario').usuario.IdUsuario
    const urlProgramas = `educacionIES/peridosActivos?IdUsuario=${IdUsuario}&IdCurso=${IdCurso}`;

    const navigate = useNavigate()

    const onNavigate = (programa) => {
      navigate(`${programa.IdOferta}/asignaturas`,{state:{programa,IdUsuario}});
    }

  return (
    <>
      <Card>
        <Card.Title>Periodos Activos</Card.Title>
      </Card>
      <DynamicTable
            titleColumn={['Nombre','Fecha Inicio','Fecha Fin', 'Acción']}
            attributes={['Nombre','FechaInicioCurso','FechaFinCurso']}
            endPoint={urlProgramas}
            onDelete={false}
            indice={false}
            onUpdate={false}
            labelOptionClick={`Ver más`}
            handleOptionClick={onNavigate}
            classNameOptionIcon={`bi bi-view-list`}
      />
    </>
  )
}
