import React from 'react'
import { Col, ListGroup, Modal, Row } from 'react-bootstrap'
import { BASE_SERVIDOR } from '../../../services/api/config'

export default function ActividadExterna({
    actividad
}) {
    console.log(actividad)
    const urlDocumentos=`${BASE_SERVIDOR}/pdf`

  return (
    <>
        <Modal.Body>
                <ListGroup variant="flush">
                <ListGroup.Item>
                    <Row>
                        <Col xs="4" style={{fontWeight:700}}>USUARIO</Col>
                        <Col>{actividad.NombreUsuario}</Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Row>
                        <Col xs="4" style={{fontWeight:700}}>ENTIDAD</Col>
                        <Col>{actividad.NOMBRE_ENTIDAD}</Col>
                    </Row>
                </ListGroup.Item>
                {/* <ListGroup.Item>
                    <Row>
                        <Col xs="4" style={{fontWeight:700}}>TIPO_ESTUDIO</Col>
                        <Col></Col>
                    </Row>
                </ListGroup.Item> */}
                <ListGroup.Item>
                    <Row> 
                        <Col xs="4" style={{fontWeight:700}}>TÍTULO</Col>
                        <Col>{actividad.TITULO}</Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Row>
                        <Col xs="4" style={{fontWeight:700}}>FECHA INICIO</Col>
                        <Col>{actividad.FECHA_INICIO}</Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Row>
                        <Col xs="4" style={{fontWeight:700}}>FECHA TERMINA</Col>
                        <Col>{actividad.FECHA_TERMINA}</Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Row>
                        <Col xs="4" style={{fontWeight:700}}>CALENDARIO</Col>
                        <Col>{actividad.CALENDARIO}</Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Row>
                        <Col xs="4" style={{fontWeight:700}}>JORNADA</Col>
                        <Col>
                            {actividad.JORNADA==1&&"Día Completo"}
                            {actividad.JORNADA==2&&"Mañana"}
                            {actividad.JORNADA==3&&"Nocturna"}
                            {actividad.JORNADA==4&&"Tarde"}
                        </Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Row>
                        <Col xs="4" style={{fontWeight:700}}>VALIDA</Col>
                        <Col>
                        {actividad.VALIDA==1&& "S" }
                        {actividad.VALIDA==2&& "N" }
                        </Col>
                    </Row>
                </ListGroup.Item>
                {/* <ListGroup.Item>
                    <Row>
                        <Col xs="4" style={{fontWeight:700}}>MODALIDAD</Col>
                        <Col>{actividad.MODALIDAD}</Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Row>
                        <Col xs="4" style={{fontWeight:700}}>NIVEL FORMACION</Col>
                        <Col>{actividad.NIVEL_FORMACION}</Col>
                    </Row>
                </ListGroup.Item> */}
                <ListGroup.Item>
                    <Row>
                        <Col xs="4" style={{fontWeight:700}}>TIPO DE ESTUDIO</Col>
                        <Col>{actividad.TipoEstudio}</Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Row>
                        <Col xs="4" style={{fontWeight:700}}>TARJETA PROFESIÓN</Col>
                        <Col>{actividad.TARJETA_PROFESION}</Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Row>
                        <Col xs="4" style={{fontWeight:700}}>ACTIVIDAD ACADÉMICA</Col>
                        <Col>{actividad.NOMBRE_CURSO}</Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Row>
                        <Col xs="4" style={{fontWeight:700}}>HORAS CURSO</Col>
                        <Col>{actividad.HORAS_CURSO}</Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Row>
                        <Col xs="4" style={{fontWeight:700}}>PROGRAMA PIESO0</Col>
                        <Col>{actividad.PROGRAMA_PIESOO}</Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Row>
                        <Col xs="4" style={{fontWeight:700}}>PROGRAMA PIESO1</Col>
                        <Col>{actividad.PROGRAMA_PIESO1}</Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Row>
                        <Col xs="4" style={{fontWeight:700}}>CÓDIGO OJT</Col>
                        <Col>{actividad.CODIGO_OJT}</Col>
                    </Row>
                </ListGroup.Item>
                {actividad.Halcon!=1?
                    <ListGroup.Item>
                        <Row>
                            <Col xs="4" style={{fontWeight:700}}>NOMBRE SOPORTE</Col>
                            <Col>{actividad.NOMBRE_SOPORTE}</Col>
                        </Row>
                    </ListGroup.Item>
                :actividad.Archivo &&
                    <ListGroup.Item>
                        <Row>
                            <Col xs="4" style={{fontWeight:700}}>Archivo</Col>
                            <Col >
                                <a target="_blank" href={`${urlDocumentos}/${actividad.Archivo}`}>Ver archivo</a>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                }
                
                <ListGroup.Item>
                    <Row>
                        <Col xs="4" style={{fontWeight:700}}> TIPO DE EDUCACCIÓN</Col>
                        <Col>{actividad.TipoEducacion}</Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Row>
                        <Col xs="4" style={{fontWeight:700}}>FECHA DE REGISTRO </Col>
                    {actividad.FechaCreacion}</Row>
                </ListGroup.Item>
                </ListGroup>
        </Modal.Body>
    </>
  )
}
