import React from 'react'

import './Card.css'

const Card = ({ 
    children,
    className,
}) => {

    const classCard = `card ${className}`

    return (
        <div className={ classCard }>
            { children }
        </div>
    )
}

export {  Card }