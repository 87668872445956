import { useState, useEffect } from 'react'

import { useCreate } from './useCreate'
import { useUpdate } from './useUpdate'
import { useDelete } from './useDelete'
import { FormService } from '../services/api/formService'

const useForm = (endPoint, id, endPointDependence, complement, deleteKey) => {

    const [allData, setAllData] = useState([])
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)
    const [isOpenForm, setIsOpenForm] = useState(false)
    const [isOpenDelete, setIsOpenDelete] = useState(false)
    const [isCreate, setIsCreate] = useState(true)
    const formService = new FormService()
    const [create, errorCreating] = useCreate(endPoint)
    const [update, errorUpdate] = useUpdate(endPoint)
    const [drop, errorDeleting] = useDelete(endPoint)

    const loadAllData = async () => {
        try {
            if(endPoint){
                const url = endPointDependence ? endPointDependence : endPoint
                const response = await formService.getAll(url)
                setAllData(response)
                console.log('allData', response)
            }
            
        } catch (err) {

        }
    }

    useEffect(() => {
        const fecthData = async () => {
            setLoading(true)
            await loadAllData()
            setLoading(false)
        }
        fecthData()
    }, []);

    const handleClickAdd = () => {
        setIsOpenForm(!isOpenForm)
    }

    const handleClickDelete = () => {
        setIsOpenDelete(!isOpenDelete)
    }

    const handleSubmit = async e => {
        e.preventDefault()
        setLoading(true)
        let respuesta;
        if (isCreate) {
            const dataLocal = {
                ...data,
                ...complement
            }
            console.log('create datasss', dataLocal)
        } else {
            console.log('update data', data)
        }

        // await loadAllData()
        handleClickAdd()
        setLoading(false)
        return respuesta
    }

    const handleChange = (value, name) => {
        console.log(value,name)
        setData({
            ...data,
            [name]: value
        })
    }

    const onCreate = () => {
        setData({})
        setIsCreate(true)
        handleClickAdd()
    }

    const onUpdate = tuple => {
        console.log(tuple)
        setData(tuple)
        setIsCreate(false)
        handleClickAdd()
    }

    const onDelete = tuple => {
        setData(tuple)
        handleClickDelete()
    }

    const deleteTuple = async () => {
        setLoading(true)
        console.log('drop', data)
        await drop(data, 'Estado', id, deleteKey) /*deleteKey is a array with key to delete*/
        await loadAllData()
        handleClickDelete()
        setLoading(false)
    }

    return {
        onCreate,
        onDelete,
        onUpdate,
        allData,
        data,
        isOpenForm,
        isOpenDelete,
        isCreate,
        handleSubmit,
        handleChange,
        handleClickDelete,
        handleClickAdd,
        deleteTuple,
        loading,
        setData,
        setLoading,
        loadAllData,
        setIsCreate,
    }
}

export { useForm }