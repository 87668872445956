import { Fragment, useEffect, useState } from 'react'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import ApiDashboard from '../../services/api/dashboard'

function SelectDataList({
    label,
    endPoinLastUrl,
    placeholder,
    optionName = 'Descripcion',
    paramSearch='Descripcion',
    name,
    value,
    wordLength = 3,
    onInputSelected,
    onChangeHandler, // return a (name, value) selected
    optionValue = null, // attribute key
    input,
    valorActual,
    required=true
    // optionValue
}) {
    const apiDash = new ApiDashboard()
    const [singleSelections, setSingleSelections] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [options, setOptions] = useState([])
    const [valueOption,setValueOption]=useState()
    useEffect(() => {console.log(value)}, [
        label,
        endPoinLastUrl,
        optionName,
        value,
        wordLength,
        paramSearch,
        onInputSelected,
        optionValue,
        valorActual
    ])

    const onSearch = async (query) => {
        
        try {
            const data = await apiDash.getData(
                `${endPoinLastUrl}?${paramSearch}=${query}`
            )
            if (data?.data) {
                setOptions(data?.data || [])
            } else setOptions(data || [])
        } catch (error) {
            console.log('error selectdatalist')
        }
    }

    const onChange = (item) => {
        ///todo something
        if (onInputSelected) onInputSelected(item)
        console.log('Item', { item })
        console.log('Item  option', optionValue)
        if (onChangeHandler) {
            if (item.length > 0){
                onChangeHandler(item[0][input?.optionValue]||item[0][optionValue],name)
                setValueOption(item[0][input?.optionValue]||item[0][optionValue])
            }
        }
 
    }

    return (
        <>
        <div  className="form-group m-1">
            {label&&
                <label className="input__label">{label}</label>
            }        
            {!!endPoinLastUrl && (
                <>
                <AsyncTypeahead
                    inputProps={{ required: required ? 'required' : false}}
                    defaultInputValue={value}
                    name="holis"
                    filterBy={['Descripcion']}
                    id="async-search-halcon"
                    isLoading={isLoading}
                    labelKey={option => `${option[optionName]}`}
                    minLength={wordLength}
                    onSearch={onSearch}
                    onChange={onChange}
                    options={options}
                    optionValue={"1"}
                    placeholder={'Buscar ' + placeholder + '...'}
                    renderMenuItemChildren={(option, props) =>!!option && 
                        // <Fragment key={option.IdUsuario}>
                            <span key={props}>
                                {option[optionName] || 'Error: optionName NOT VALID'}
                            </span>
                        // {/* </Fragment>                         */}
                    }
                />
                <input type="hidden" name={name} value={valorActual}></input>               
                </>                
            )}
             </div>
        </>
       
    )
}

export { SelectDataList }
