import axios from "axios";
import { ACCESS_TOKEN } from "./config";

const getTokenLocal = async()=>localStorage.getItem(ACCESS_TOKEN)?localStorage.getItem(ACCESS_TOKEN):'empty token in localstorage'

const authAxiosFormData = axios.create();



authAxiosFormData.interceptors.request.use(function (config) {
  return getTokenLocal() 
    .then((token) => {
     
      config.headers.put["Content-Type"] = 'multipart/form-data';
      config.headers.put["Authorization"] = `Bearer ${token}`;
      return config;
    });
});

export default authAxiosFormData;