
import { useLocation, useNavigate } from "react-router-dom";
import { Card } from "../../../components/card/Card";
import { DynamicTable } from "../../../components/DynamicForm/dynamicTable/DynamicTable";
import { LocalStorage } from "../../../services";
import { SubTitle } from "../../../shared/components/subtitle/SubTitle";
import { Title } from "../../../shared/components/title/Title";

function CalendarioAsignatura () {

  const ls = new LocalStorage()
  const IdUsuario = ls.getAsJsonItem('usuario').usuario?.IdUsuario
  const navigate = useNavigate()

  const handleOptionClick = (item) => {
    navigate(`${item.IdOferta}/detalle_calendario`,{state:{oferta:item}})
  }

  return (<>
  <Card>

  <Title>Calendario</Title>
  </Card>
  <DynamicTable
  titleColumn={['N°','Nombre','Acción']}
  attributes = { ['Nombre']}
  onUpdate={false}
  onDelete={false}
  handleOptionClick= {handleOptionClick}
  labelOptionClick= {'Ver Programa'}
  classNameOptionIcon={'bi bi-book'}
  endPoint={`docente/cursos?IdUsuario=${IdUsuario}`}
  // endPoint={`docente/calendario?IdUsuario=${IdUsuario}`}
  ></DynamicTable>
  </>
  )

}

export { CalendarioAsignatura}