
import { useNavigate } from "react-router-dom"
import { Card } from "../../components/card/Card"
import { DynamicTable } from "../../components/DynamicForm/dynamicTable/DynamicTable"
import { BASE_URL } from "../../services/api/config"
import { Row, Col, Button, Form } from "react-bootstrap"
import { Input } from "../../components/input/input"
import { useState } from "react"

const AlertasInasistencias=({full=false})=>{
    const Navigate=useNavigate()
    const urlExportar = `${BASE_URL}/exportarAlertaInasistencias`
    const [fechaI, setFechaI] = useState()
    const [fechaII, setFechaII] = useState()
    const [estado, setEstado] = useState('Activa') 
    const [busqueda, setBusqueda] = useState()
    const ulrExportar = `alertas/inasistencia?full${full}`
    const [endPoint, setEndPoint] = useState(ulrExportar)


    const verMas=(e)=>{
        Navigate("seguimiento_alerta",{state:{alerta:e}})
    }

    const onSearch = () => {
        let busqueda = ''
        if(estado) busqueda += `&Estado=${estado}`
        if(fechaI) busqueda += `&FechaUno=${fechaI}`
        if(fechaII) busqueda += `&FechaDos=${fechaII}`
        setEndPoint(ulrExportar+busqueda)
        setBusqueda(busqueda)

    }

    return(
        <>
        <Card>
            <h2>Alertas de Inasistencias</h2>
        </Card>
        <Card>
            <Row>
                <Col>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>Fecha I</Form.Label>
                        <Form.Control type="date" onChange={(e)=>setFechaI(e.target.value)}/>
                    </Form.Group>
                </Col>
                <Col>
    
                    <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>Fecha II</Form.Label>
                        <Form.Control type="date" onChange={(e) => setFechaII(e.target.value)}/>
                    </Form.Group>
                </Col>
                {/* select, activa y cerrada */}
                <Col>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>Estado</Form.Label>
                        <Form.Control as="select"
                            onChange={(e) => setEstado(e.target.value)}
                        >
                            <option>Activa</option>
                            <option>Cerrada</option>
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col>
                    <br />
                    <div style={{textAlign: 'right'}}>
                        <Button onClick={() => onSearch()}>Buscar</Button>
                    </div>
                </Col>
            </Row>
        </Card>
        <Card>
            <DynamicTable 
                endPoint={endPoint}
                titleColumn={["#","Estudiante","Actividad Académica - Programa","Asignatura","Acciones"]}
                attributes={["Estudiante","Curso","Asignatura"]}
                onDelete={false}
                onUpdate={false}
                labelOptionClick='Ver más'
                handleOptionClick={verMas}
                exportar={urlExportar}
                busqueda={busqueda}
            />
        </Card>
        </>
    )
}

export {AlertasInasistencias}