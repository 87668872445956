import React, { useState } from 'react'
import { Card } from 'react-bootstrap'
import SeleccionarAsignaturas from '../../components/Student/SeleccionarAsignaturas/SeleccionarAsignaturas'
import { useLocation } from 'react-router-dom'

export default function AsignaturasAntiguos() {
    // recibir parametros de la ruta
    const { oferta, aspirante } = useLocation().state
    console.log(oferta.IdOferta)
    const [asignaturasAprobadas, setAsignaturasAprobadas] = useState(aspirante.AsignaturasAprobadas)

  return (
    <>
        <Card>
            <Card.Title>Asignaturas Antiguos</Card.Title>
        </Card>

        <SeleccionarAsignaturas 
            aspirante={aspirante}
            IdOferta={oferta.IdOferta}
            admision={true}
            asignaturasAprobadas={asignaturasAprobadas}
            setAsignaturasAprobadas={setAsignaturasAprobadas}
        />
    </>
  )
}
