import React, { useState } from 'react'
import { Button, Card, Col, Form, ListGroup, ListGroupItem, Modal, Row } from 'react-bootstrap'
import DowloadFile from '../../../components/DowloadFile/DowloadFile';
import DowloadFileText from '../../../components/DowloadFileText/DowloadFileText';
import { BASE_URL } from '../../../services/api/config'
import { Select } from '../../../components/select/Select';

export default function FormatosCursoIES({IdOferta,oferta,fasesLista,full=true}) {
    const URL_EXPORT = `${BASE_URL}/exportar/`
    const [modalDescargar,setModalDescargar]=useState();
    const [fechaInicial,setFechaInicial]=useState(oferta.FechaInicioCurso);
    const [fechaFinal,setFechaFinal]=useState(oferta.FechaFinCurso);
    const [modalHorario,setModalHorario]=useState();
    const [horario, setHorario] = useState();
    const [modalAsistencia,setModalAsistencia]=useState();
    const [malla, setMalla] = useState({})
    const [modalCalificaciones,setModalCalificaciones]=useState();
    const [tipoArchivo,setTipoArchivo]=useState();
    // sumar 6 dias ala fecha inicial
    const sumarDias = (FechaInicioCurso, dias) => {
        // Convertir fecha de MySQL a fecha de JavaScript
        const fecha = new Date(FechaInicioCurso.replace(/-/g, '/'));
        fecha.setDate(fecha.getDate() + dias);
        fecha.setHours(0, 0, 0, 0); // Establecer horas, minutos, segundos y milisegundos en 0
        // Convertir fecha de JavaScript a fecha de MySQL
        const fechaMySQL = fecha.toISOString().slice(0, 10);
        return fechaMySQL;
      }

  return (
    <>
        <Row>
                        <Col>
                            <Card>
                                <Button 
                                    variant="outline-primary"
                                    onClick={()=>setModalAsistencia(true)}
                                >
                                    Asistencias
                                </Button>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Button
                                    variant="outline-primary"
                                    onClick={()=>setModalCalificaciones(true)}
                                    >
                                        Exportar calificaciones
                                    </Button>
                            </Card>
                        </Col>
                        {full &&
                            <Col>
                                <Card>
                                    <DowloadFile 
                                    nameFile={"Inscritos.xlsx"}
                                    name={"Inscritos"}
                                    endPoint={`${BASE_URL}/formatosIES/exportar/cursoFinalizado/inscritos?IdOferta=${IdOferta}`}
                                    />
                                </Card>
                            </Col>        
                        }
                    </Row>
                    <Row>
                        <Col>
                        <Card>
                        <Button 
                            variant="outline-primary"
                            onClick={()=>setModalHorario(true)}
                        >
                            Horario del Curso
                        </Button>
                        </Card>
                        </Col>
                        {full &&
                            <Col>
                                <Card>
                                <DowloadFile
                                    nameFile={"Inscritos Eliminados.xlsx"}
                                    name={"Inscritos Eliminados"}
                                    endPoint={`${BASE_URL}/formatosIES/exportar/cursoFinalizado/inscritosEliminados?IdOferta=${IdOferta}`}
                                />
                                </Card>
                            </Col>
                        }                        
                        <Col>
                            <Card>
                            <DowloadFile 
                            nameFile={"Estudiantes.xlsx"}
                            name={"Estudiantes"}
                            endPoint={`${BASE_URL}/formatosIES/exportar/cursoFinalizado/estudiantes?IdOferta=${IdOferta}`}
                        />
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                            <DowloadFile
                                nameFile={"Promedios del Programa.pdf"}
                                name={"Promedios del Programa"}
                                endPoint={`${BASE_URL}/formatosIES/promedioCurso?IdOferta=${IdOferta}`}
                            />
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <DowloadFile
                                    nameFile={"Estudiantes Becados.pdf"}
                                    name={"Estudiantes Becados"}
                                    endPoint={`${BASE_URL}/formatosIES/estudiantesBecados?IdOferta=${IdOferta}`}
                                />
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <DowloadFile
                                    nameFile={"Estudiantes notas no regulares.xlsx"}
                                    name={"Estudiantes notas no regulares"}
                                    endPoint={`${BASE_URL}/formatosIES/estudiantesNotasNoRegulares?IdOferta=${IdOferta}`}
                                />
                            </Card>
                        </Col>
                    </Row>
                    {full &&
                        <Row>
                            <Col>
                            <Card>
                                <Button onClick={()=>setModalDescargar(true)}>
                                    Otros Formatos
                                </Button>
                                
                            </Card>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                    }
                    
        <Modal 
            show={modalDescargar} 
            onHide={()=>setModalDescargar(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>Exportables y Formatos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ul className="list-group">

                    {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                        <DowloadFile
                            nameFile={"Listado de Aspirantes.xlsx"}
                            name={"Listado de Aspirantes"}
                            endPoint={`${URL_EXPORT}formatosIES/aspirantesExcel?IdOferta=${IdOferta}`}
                        />
                    </li> */}
                    <ListGroup>
                        <ListGroup.Item>
                            <DowloadFile
                                nameFile={"Listado de Preinscritos.xlsx"}
                                name={"Listado de Preinscritos"}
                                endPoint={`${BASE_URL}/formatosIES/exportar/preinscritos?IdOferta=${IdOferta}`}
                            />
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <DowloadFile
                                nameFile={"Listado de Aspirantes.pdf"}
                                name={"Listado de Aspirantes"}
                                endPoint={`${BASE_URL}/formatosIES/exportar/aspirantes?IdOferta=${IdOferta}`}
                            />
                        </ListGroup.Item>
                        <ListGroup.Item>
                                <DowloadFile 
                                nameFile={"Inscritos.pdf"}
                                name={"Listado de Inscritos"}
                                endPoint={`${BASE_URL}/formatosIES/cursos/exportarAspirantesInscritos?IdOferta=${IdOferta}`}
                                />
                        </ListGroup.Item>
                        {oferta.IdConvocatoria&&
                            <ListGroup.Item>
                                <DowloadFile 
                                nameFile={"Admitidos a pruebas.pdf"}
                                name={"Admitidos a pruebas"}
                                endPoint={`${BASE_URL}/formatosIES/cursos/exportarAdmitidosPrueba?IdOferta=${IdOferta}`}
                                />
                            </ListGroup.Item>
                        }
                        <ListGroup.Item>
                                <DowloadFile 
                                nameFile={"Admitidos.pdf"}
                                name={"Listado de Admitidos"}
                                endPoint={`${BASE_URL}/formatosIES/cursos/exportarAspirantesAdmitidos?IdOferta=${IdOferta}`}
                                />
                        </ListGroup.Item>
                        <ListGroup.Item>
                                <DowloadFile 
                                nameFile={"Matriculados.pdf"}
                                name={"Reporte de Matriculados"}
                                endPoint={`${BASE_URL}/formatosIES/cursos/exportarAspirantesMatriculados?IdOferta=${IdOferta}`}
                                />
                        </ListGroup.Item>
                    </ListGroup>
                </ul>
                <h5 className='text-center p-2'>Exportar Admitidos por Etapas</h5>
                <ul className="list-group">
                {fasesLista.map((fase,key)=>
                <>
                {fase.ExportableHijos == "true" || fase.ExportableHijos == 1 ? (
                <>
                  <ListGroup>
                    <ListGroup.Item>
                      <DowloadFile
                        nameFile={`${fase.Nombre}.xlsx`}
                        name={`${fase.Nombre}.xlsx`}
                        endPoint={`${BASE_URL}/convocatoriaFase/exportarFasesAsociadas?IdConvocatoriaFase=${fase.IdConvocatoriaFase}&IdOferta=${IdOferta}`}
                      />
                    </ListGroup.Item>
                  </ListGroup>
                  {fase.IdFasePadre==null&&
                    <ListGroup>
                        <ListGroup.Item>
                        <DowloadFile
                                    nameFile={`${fase.Nombre}.pdf`}
                                    name={`${fase.Nombre}.pdf`}
                                    endPoint={`${BASE_URL}/formatosIES/exportar/admitidosFase?IdConvocatoriaFase=${fase.IdConvocatoriaFase}&IdOferta=${IdOferta}`}
                                    />
                        </ListGroup.Item>
                    </ListGroup>
                }
                </>
                )
                :
                <>
                {fase.IdFasePadre==null&&
                    <ListGroup>
                        <ListGroup.Item>
                        <DowloadFile
                                    nameFile={`${fase.Nombre}.xlsx`}
                                    name={`${fase.Nombre}.xlsx`}
                                    endPoint={`${BASE_URL}/formatosIES/exportar/admitidosFaseExcel?IdConvocatoriaFase=${fase.IdConvocatoriaFase}&IdOferta=${IdOferta}`}
                                    />
                        </ListGroup.Item>
                        <ListGroup.Item>
                        <DowloadFile
                                    nameFile={`${fase.Nombre}.pdf`}
                                    name={`${fase.Nombre}.pdf`}
                                    endPoint={`${BASE_URL}/formatosIES/exportar/admitidosFase?IdConvocatoriaFase=${fase.IdConvocatoriaFase}&IdOferta=${IdOferta}`}
                                    />
                        </ListGroup.Item>
                    </ListGroup>
                }
                </>
                }
                </>
                )}
                </ul>                
            </Modal.Body>
            </Modal>

            {/* filtra descarga del horario por fecha inicial y fecha fina en un modal    */}
            <Modal
                show={modalHorario}
                onHide={()=>setModalHorario(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Descarga Horario del Curso</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* seleccionar si desea dercar excel o pdf */}
                    <Select
                        id="TipoArchivo"
                        name="TipoArchivo"
                        onChange={ (e) => setTipoArchivo(e) }
                        value={ tipoArchivo || null }
                        label="Seleccionar Tipo de Archivo"
                        required={true}
                        optionValue="IdTipoArchivo"
                        optionName="Nombre"
                        options={[
                            {IdTipoArchivo:1,Nombre:"PDF"},
                            {IdTipoArchivo:2,Nombre:"Excel"}
                        ]}
                    />
                    <Select
                        id="Horarios"
                        name="Horarios"
                        onChange={ (e) => setHorario(e) }
                        value={ horario || null }
                        label="Seleccionar Horario"
                        required={true}
                        optionValue="IdHorario"
                        optionName="Nombre"
                        urlOptionsService={`cursos/horarios?IdOferta=${IdOferta}`}
                    />
                </Modal.Body>
                <Modal.Footer>
                    {horario&&
                        <>
                            {tipoArchivo==2
                                ?
                                <DowloadFile
                                    nameFile={"Horario del Curso.xlsx"}
                                    name={"Descargar Horario"}
                                    endPoint={`${BASE_URL}/formatosIES/exportar/horarioResumido?IdOferta=${IdOferta}&IdHorario=${horario}&IdTipoArchivo=${tipoArchivo}`}
                                />
                                :
                                <DowloadFile
                                    nameFile={"Horario del Curso.pdf"}
                                    name={"Descargar Horario"}
                                    endPoint={`${BASE_URL}/formatosIES/exportar/horarioResumido?IdOferta=${IdOferta}&IdHorario=${horario}&IdTipoArchivo=${tipoArchivo}`}
                                />
                            }
                        </>
                    }
                        
                </Modal.Footer>
            </Modal>
            {/* modal para descargar asistencias de la asigantura */}
            <Modal
                show={modalAsistencia}
                onHide={()=>setModalAsistencia(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Descarga Asistencia</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Select
                                id="IdMalla"
                                name="IdMalla"
                                value={malla}
                                onChange={ (e) => setMalla(e)}
                                label="Asignatura"
                                required={true}
                                optionValue="IdMalla"
                                optionName="Descripcion"
                                urlOptionsService={`cursos/asignaturasAsistencias?IdOferta=${IdOferta}`}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {malla &&
                        <>
                        <DowloadFile 
                            nameFile={"Asistencias.pdf"}
                            name={"Asistencias"}
                            endPoint={`${BASE_URL}/formatosIES/exportar/cursoFinalizado/asistencias?IdOferta=${IdOferta}&IdMalla=${malla}`}
                        />
                        </>
                    }
                </Modal.Footer>
            </Modal>
            {/* modal para descargar las calificaciones de la asignatura */}
            <Modal
                show={modalCalificaciones}
                onHide={()=>setModalCalificaciones(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Descarga Calificaciones</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                        <Select
                                id="IdMalla"
                                name="IdMalla"
                                value={malla}
                                onChange={ (e) => setMalla(e)}
                                label="Asignatura"
                                required={true}
                                optionValue="IdMalla"
                                optionName="Descripcion"
                                urlOptionsService={`cursos/asignaturasCalficaciones?IdOferta=${IdOferta}`}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {malla &&
                        <>
                        <DowloadFile
                            nameFile={"Calificaciones.pdf"}
                            name={"Calificaciones"}
                            endPoint={`${BASE_URL}/formatosIES/exportar/cursoFinalizado/calificaciones?IdOferta=${IdOferta}&IdMalla=${malla}`}
                        />
                        </>
                    }
                </Modal.Footer>
            </Modal>

    </>
  )
}
