// TablaMetrica.js

import React from 'react';
import { Card, Table } from 'react-bootstrap';

const TablaMetricas = ({ metrica }) => {
  return (
    <Card>
      <strong>{metrica.metrica}</strong>
      <Table>
        <tbody>
          {metrica.data.map((dato, index) => (
            <tr key={index}>
              <td>{dato.label}</td>
              <td>{dato.total}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export default TablaMetricas;
