
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Alert, Button, Modal, Spinner,Form, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Checkbox } from '../../../components/componentsForm/checkbox/checkbox';
import { Select } from '../../../components/select/Select';
import { alerta } from '../../../helpers/alertas';
import { LocalStorage } from '../../../services';
import { FormService } from '../../../services/api/formService';
import { Input } from '../../../components/input/input';

export default function FormInscripcion({
    itemSelected
}) {
    const navigate= new useNavigate();
    const api=new FormService();
    const storage= new LocalStorage();
    const usuario=JSON.parse(storage.getItem("usuario"))
    const urlAntiguedad=`educacionIES/miPrimerPerido?IdUsuario=${usuario?.usuario.IdUsuario}&IdOferta=${itemSelected.IdOferta}`
    const [consentimiento1,setConsetimiento1]=useState(null);
    const [consentimiento2,setConsetimiento2]=useState(null);
    const [tipoVinculacion,setTipoVinculacion]=useState(null);
    const [periodoReintegro, setPeriodoReintegro] = useState(null);
    const [semestreReintegro, setSemestreReintegro] = useState(null);
    const [reintegroAntiguo, setReintegroAntiguo] = useState(null);
    const [cargando,setCargando]=useState(true);
    const [antiguedad,setAntiguedad]=useState();
    const [datos,setDatos]=useState({
        "IdOferta": itemSelected?.IdOferta,
        "IdUsuario": usuario?.usuario.IdUsuario,
        "Email":usuario?.usuario.Email,
        "Celular":usuario?.usuario.Celular,
        "PrimerNombre":usuario?.usuario.PrimerNombres,
        "SegundoNombre":usuario?.usuario.SegundoNombre,
        "PrimerApellido":usuario?.usuario.PrimerApellidos,
        "SegundoApellido":usuario?.usuario.SegundoApellidos,
        "IdTipoVinculacion":tipoVinculacion,
        "antiguedad":antiguedad
    })
    const [modal,setModal]=useState(false)

    useEffect(()=>{
        if(itemSelected.IdDimension==1){
            (async()=>{
                consultarAntiguedad()
            })()
        }else{
            setAntiguedad(0)
            setCargando(false)
        }

    },[])

    const guardarAspirante=async()=> {
        if(itemSelected.Concentimientos==1&&antiguedad==0){
            if(consentimiento1==null||consentimiento2==null){
                alerta("Acepta los concentiminetos")
                return
            }
        }
        if(itemSelected.IdDimension==1&&antiguedad==0){
            if(tipoVinculacion==null){
                alerta("Selecciona el tipo de vinculación")
                return
            }
        }
        if(itemSelected.Periocidad=="Intersemestral"){
            if(antiguedad==0){
                alerta("Oferta solo para estudiantes antiguos")
                return
            }
        }
        const data={
            "IdOferta": itemSelected.IdOferta,
            "IdUsuario": usuario.usuario.IdUsuario,
            "IdTipoVinculacion":tipoVinculacion,
            "antiguedad":antiguedad,
            "PeriodoReintegro": periodoReintegro,
            "SemestreReintegro": semestreReintegro
        }

        const respuesta=await api.create("aspirantes",data)
        if(respuesta.respuesta=="exito"){
            alerta(`${respuesta.mensaje} <br/> Redirigiendo al Dashboard...`)
            setTimeout(() => { 
                navigate("/dashboard/aspirante/inscripciones")
            }, 3000);
        }else if(respuesta.respuesta=="error"){
            alerta(respuesta.mensaje)
        }else{
            alerta("Fallo desconocido")
        }
    }

    // consultar si es estudiante antiguo
    const consultarAntiguedad=async()=>{
        setCargando(true)
        const respuesta=await api.getAll(urlAntiguedad)
        if(respuesta.respuesta=="error"){
            setCargando(false)
            setAntiguedad(1)
        }else if(respuesta.respuesta=="exito"){
            setCargando(false)
            setAntiguedad(0)
        }else{
            alerta("Fallo desconocido")
            setModal(false)
        }
    }

    const handleInputChange=(e)=>{
        setDatos({
            ...datos,
            [e.target.name]:e.target.value
        })
    }



return (
    <>
        <Modal 
            show={modal} 
            onHide={()=>setModal(false)} 
        >
            <Modal.Header closeButton>
                <Modal.Title>Inscripción</Modal.Title>
            </Modal.Header>

        <>
        {cargando?
            <Modal.Body>
                <div style={{textAlign:"center"}}>
                    <Spinner animation="border" role="status" /><br></br>
                    <span className="">Consultando antiguedad</span>
                </div>
            </Modal.Body>
        :
        <>
        <Modal.Body>
            {itemSelected.IdDimension==1&&
                <>
                {antiguedad==0 ?
                <Alert>Aspirante nuevo</Alert>
                :
                <Alert>Estudiante antiguo</Alert>
                }
                </>
            }

            {antiguedad==0 &&
                <>
                {itemSelected.Concentimientos == 1 &&
                <>
                    <Checkbox
                        id="id_status"
                        name="status"
                        checked={consentimiento1}
                        onChange={setConsetimiento1}
                    >
                        <a target="_blank" href="/consentimiento1">
                            Declaro y acepto que he leído el Consentimiento 1 anexado en presente link.
                        </a>
                    </Checkbox>
                    <Checkbox
                        id="id_status"
                        name="status"
                        checked={consentimiento2}
                        onChange={setConsetimiento2}
                    >
                        <a target="_blank" href="/consentimiento2">
                            Declaro y acepto que he leído el Consentimiento 2 anexado en presente link.
                        </a>
                    </Checkbox>
                </>
                }
                {itemSelected.IdDimension==1&&
                    <>
                    <Select 
                        label="Tipo de ingreso"
                        optionName="Descripcion"
                        optionValue="IdTipoVinculacion"
                        value={tipoVinculacion}
                        onChange={(e)=>setTipoVinculacion(e)}
                        urlOptionsService="tipoVinculacion"
                    />
                    
                    {tipoVinculacion == 8 &&
                        <>
                        <Select 
                            label="¿ Es estudiante de reintegro de antes de 1998 ?"
                            optionName="Id"
                            optionValue="Nombre"
                            value={reintegroAntiguo}
                            onChange={(e)=>setReintegroAntiguo(e)}
                            options={[
                                {"Id": 1, "Nombre": "Sí"},
                                {"Id": 2, "Nombre": "No"}
                            ]}
                        />
                        {reintegroAntiguo == 1 &&
                            <>
                            <Input
                                label={'Año de Reintegro'}
                                type={'number'}
                                value={periodoReintegro}
                                onChange={(e) => setPeriodoReintegro(e)}
                                name={"periodoReintegro"}
                            />
                            <Input
                                label={'Semestre de Reintegro'}
                                type={'number'}
                                value={semestreReintegro}
                                onChange={(e) => setSemestreReintegro(e)}
                                name={"semestreReintegro"}
                            />
                            </>
                        }
                        </>
                    }
                    </>
                }
                </>
            }

         </Modal.Body>
         <Modal.Footer>
            {/* <Button
                variant='secondary'
                onClick={()=>setIsOpenModal()}
            >
                CANCELAR
            </Button> */}
            {/* <Button
                onClick={guardarAspirante}
            >
                INSCRIBIRSE
            </Button> */}
            <Button
                onClick={()=>setModal(false)}
            >
                CANCELAR
            </Button>

            <Button onClick={()=>guardarAspirante()}>Inscribirse</Button>
         </Modal.Footer>
         </>
        }
    </>
        </Modal>
        <Button onClick={()=>setModal(true)}>Inscribirse</Button>
    </>
    )
}
