import { Route, Routes } from 'react-router-dom'
import { DynamicFormContainer } from '../../../components/DynamicForm/DynamicFormContainer'
import { AsignaturaFormContainer } from './asignatura/AsignaturaFormContainer'
import { Prerrequisitos } from './asignatura/Prerrequisitos'
import { CursoForm } from './curso/Curso'
import Malla from './Malla/Malla'
import Requisitos from './Malla/Requisitos'
import Unidades from './Malla/Unidades'
import GestionConvocatoria from './ofertaAsignaturas/GestionConvocatoria'
import { RequisitosOffer } from './Requisitos/RequisitosOffer'
import { OfertaAsignaturas } from './ofertaAsignaturas/OfertaAsignaturas'
import { PlanEstudio } from './planEstudio/planestudio'
/* import {Etapas} from './Etapas/Etapas' */


function OfertaAcademica() {
    return (
        <Routes>
            <Route path="curso/plan_estudio" element={<PlanEstudio></PlanEstudio>} ></Route>
            <Route path="curso/plan_estudio/malla" element={<Malla/>} ></Route>
            <Route path="curso/plan_estudio/malla/unidades" element={<Unidades/>} ></Route>
            <Route path="curso/plan_estudio/malla/requisitos" element={<Requisitos/>} ></Route>
            <Route
                path="actividad_academica_externa"
                element={<DynamicFormContainer idForm="72" />}
            ></Route>
            <Route path="ofertas" element={<OfertaAsignaturas />}></Route>
            <Route path="ofertas/requisitos" element={<RequisitosOffer/>}></Route>
            <Route path="ofertas/gestion_convocatoria" element={<GestionConvocatoria />}></Route>
            {/* etapas para IES */}
            {/* <Route
                path="ofertas/etapas"
                element={<Etapas/>}
            ></Route> */}
            
            {/* <Route exact  path="actividad_académica_externa" element = {<DynamicFormContainer idForm={'72'}/>}></Route> */}
            <Route
                exact
                path="asignaturas"
                element={<AsignaturaFormContainer />}
            />
            <Route
                exact
                path="asignaturas/prerrequsitos"
                element={<Prerrequisitos/>}
            />
            <Route exact path="curso" element={<CursoForm />} />
            <Route path="*" element={<DynamicFormContainer />}></Route>
        </Routes>
    )
}
export { OfertaAcademica }
