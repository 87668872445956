import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useFetchGet } from "../../../hooks/useFetchGet";
import user from '../../../assets/img/avatar.jpg';
import { useState } from "react";
import { Title } from "../../../shared/components/title/Title";
import { SubTitle } from "../../../shared/components/subtitle/SubTitle";
import { Button } from "../../../components/button/button";
import { FormService } from "../../../services/api/formService";
import { Modal, Card, ListGroup, Badge } from "react-bootstrap";
import { alerta } from "../../../helpers/alertas";

function AsignaturasEstudiantes () {
  
  const location = useLocation();
  const navigate = useNavigate()
  const api = new FormService()
  const { unidad,programa,asignatura,IdUsuario } = location.state;
  const [estudiantes,isLoadingEstudiantes,error] = useFetchGet(`docente/estudiantes?IdOferta=${programa.IdOferta}`);
  const [showModalDetails, setShowModalDetails] = useState(false);
  const [showModalAsistencias, setShowModalAsistencias] = useState(false);
  const [showModalNotas, setShowModalNotas] = useState(false);
  const [loadingEventAsistencia, setLoadingEventAsistencia] = useState(false);
  const [loadingEventoNota, setLoadingEventoNota] = useState(false);
  const [key, setKey] = useState('notas');
  const [estudianteSelected, setEstudianteSelected] = useState({});
  
  const [eventoAsignatura,loadingAsistencias,errorAsistencias] = useFetchGet(`docente/eventosAsignatura2?IdUsuario=${IdUsuario}&IdMalla=${asignatura.IdMalla}&IdOferta=${programa.IdOferta}&IdUnidadAsignatura=${unidad.IdUnidadAsignatura}`)
  const [eventoNotas,loadingNotas,errorNotas] = useFetchGet(`docente/notasAsignatura?IdUnidadAsignatura=${unidad.IdUnidadAsignatura}&IdOferta=${programa.IdOferta}`)

  const handleModalOpenDetails = (estudiante) => {
    setEstudianteSelected(estudiante);
    setShowModalDetails(true);

  }
  // const handleSelect = (eventKey) => setKey(eventKey);
  
  const onSelectEventoAsistencia=(evento,index)=>{
    console.log({index})
    setLoadingEventAsistencia(true)
    api.getAll('docente/planillaAsistencia?IdEventoAula='+evento.IdEventoAula).then(res=>{
      setLoadingEventAsistencia(false)
      console.log({...evento,index})
      navigate('asistencias',{
        state:{
          evento:{...evento,index},asignatura,programa,IdUsuario,estudiantes:res}
        })
        
    }).catch(err=>{console.log("error getting event asis")})

  }
  const onSelectEventoNota=(evento,index)=>{
    setLoadingEventoNota(true)
    api.getAll('docente/planillaNota?IdNotaAsignatura='+evento.IdNotaAsignatura).then(res=>{
      setLoadingEventoNota(false)
      navigate('notas',{
        state:{
          evento:{...evento,index},asignatura,programa,IdUsuario,estudiantes:res}
        })
        
    }).catch(err=>{console.log("error getting event asis")})

  }

  return (
        <>
        {/* <AddButton></AddButton> */}
        <Card>
          <Card.Title>{programa.Nombre}</Card.Title>
          <p>{asignatura.Descripcion}</p>
        </Card>
        
        
        <div className="d-flex justify-content-center">
          <Button
          className='btn btn-outline-primary pt-2 m-2'
          hasButtonCss={false}
          type={"send"}
          label={"Notas"}
          onClick={()=>setShowModalNotas(true)}
          >Notas</Button>

        <Button
        hasButtonCss={false}
        type={"send"}
        className='btn btn-outline-primary pt-2 m-2'
        onClick={()=>setShowModalAsistencias(true)}
        label={"Asistencias"}
        >Asistencias</Button>

        </div>


    <Modal
      show={showModalAsistencias}
      onHide={()=>setShowModalAsistencias(false)}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Asistencias</Modal.Title>
      </Modal.Header> 
      <Modal.Body>
          <div className="evento-container">
          {!!eventoAsignatura  && eventoAsignatura.map((evento,index)=>{
            return(
              <>
                <div onClick={()=>onSelectEventoAsistencia(evento,index)} className="evento-date-container">
                <div className="evento-date-container__evento-header">Fecha</div>
                <div className="evento-date-container__evento-asistencias">
                <div >{new Date(evento.FechaInicio).toLocaleDateString('es-co')}</div><br/>
                <div>{new Date(evento.FechaInicio).toLocaleTimeString('es-CO')} {new Date(evento.FechaFin).toLocaleTimeString('es-CO')}</div>
                  {loadingEventAsistencia && 
                    <div className="spinner-border spinner-border-sm" role="status">
                      <span className="visually-hidden">cargando...</span>
                    </div>
                  }
              </div>
              </div>
              </>
            )
          })}
      </div>
      </Modal.Body>
    </Modal>
{/* MODAL NOTAS */}
    <Modal
      show={showModalNotas}
      onHide={()=>setShowModalNotas(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Notas</Modal.Title>
      </Modal.Header>
        <Modal.Body>
        <ListGroup>
      {eventoNotas.notasasignatura && eventoNotas?.notasasignatura.map((evento,index)=>{
        return(
          <>
            <ListGroup.Item
              key={index}
              onClick={eventoNotas.calificable==1?()=>onSelectEventoNota(evento,index+1):()=>alerta("No puede calificar notas")} 
              as="li"
              className="d-flex justify-content-between align-items-start item-nota"
              
              
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">{evento.Nombre} </div>
                {evento.Corte}
              </div>
              <Badge bg="primary" pill>
                {evento?.Porcentaje}%
              </Badge>
            </ListGroup.Item>
          </>
        )
      })}
        <ListGroup.Item
          as="li"
          className="d-flex justify-content-between align-items-start"
        >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">Total</div>
                  {eventoNotas?.calificable==1?
                    <div style={{color:"green"}}>Puede calificar notas</div>
                    :
                    <div style={{color:"red"}}>No puede calificar notas</div>
                  }
                </div>
        </ListGroup.Item>
      </ListGroup>
        {/* <div className="notas-container">
        {console.log(eventoNotas)}
          {eventoNotas.notasasignatura && eventoNotas?.notasasignatura.map((evento,index)=>{
            return(
              <>
              <div onClick={()=>onSelectEventoNota(evento,index+1)}  className="nota-item">
                  <div><b>Nota {index+1} </b></div>
                  {loadingEventoNota && <div className="spinner-border spinner-border-sm" role="status">
                  <span className="visually-hidden">cargando...</span>
                  </div>}
                  <div className="porcentaje-nota"> {evento?.Porcentaje}% </div>
                  </div>
              </>
            )
          })}
          </div> */}
        </Modal.Body>
      </Modal>

      <Modal
      show={showModalDetails}
      onHide={()=>setShowModalDetails(false)}
      >
          <div className="content-student">

                      <img 
                          src={ estudianteSelected?.Foto || user }
                          alt={`foto estudiante ${estudianteSelected.PrimerNombres} ${estudianteSelected.PrimerApellidos}`}
                          className='applicant-list__img-user student'
                          />
                      <span>{estudianteSelected.PrimerNombres} {estudianteSelected.PrimerApellidos} {estudianteSelected.SegundoApellidos}</span>                    
                      <div>{estudianteSelected.email}</div>
                    </div>
                    <div className="d-flex  justify-content-around">

                    <div>Teléfono: {estudianteSelected.TelefonoContacto}</div>
                    <div>N° Documento: {estudianteSelected.NumeroDocumento}</div>
                    </div>
      </Modal>
    </>
  )
}

export  { AsignaturasEstudiantes };


