import { useState } from "react";
import { useEffect } from "react";
import { Card } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { JobOfferCard } from "../../../components/JobOfferCard/JobOfferCard";
import { FormService } from "../../../services/api/formService";
import Header from "./Componentes/Header";


const OfertasEmpleo=()=>{
    const api= new FormService();
    const[ofertas,setOfertas]=useState([]);
    const {state:{usuario}}=useLocation()
    useEffect(()=>{
        const fetchData=async()=>{
            ofertasGet()
        }
        fetchData()

        // return fetchData()
    },[])

    const ofertasGet=async()=>{
        const ofertas=await api.getAll("ofertasTrabajo")
        setOfertas(ofertas)
    }
    console.log(ofertas)
    return(
        <>
        <div style={{maxWidth:1000,margin:"0 auto",marginTop:40}}>
        <Header usuario={usuario}/>
        <Card>
            <Card.Title>
                Ofertas de Empleo
            </Card.Title>
        </Card>
        {ofertas.map((oferta,key)=>
                <JobOfferCard
                    key={key}
                    nombre={oferta.Nombre}
                    descripcion={oferta.Descripcion}
                    lugar={oferta.Lugar}
                    empresa={oferta.Empresa}
                    telefono={oferta.Telefono}
                    email={oferta.Email}
                />
            )
        }
        </div>
        </>
    )

}

export {OfertasEmpleo};