import React, { useState } from 'react'
import { Card } from 'react-bootstrap'
import { DynamicTable } from '../../components/DynamicForm/dynamicTable/DynamicTable'
import { LocalStorage } from '../../services'
import { BASE_URL } from '../../services/api/config'
import { useNavigate } from 'react-router-dom'

export default function MatriculaAntiguos({full}) {
    const ls = new LocalStorage()
    const IdUsuario = ls.getAsJsonItem('usuario').usuario.IdUsuario
    const dimension = ls.getItem('dimension')
    const navigate = useNavigate()
    const [endPoint, setEndPoint] = useState(full?`cursos/activosFull?IdUsuario${IdUsuario}`:`cursos/inscripciones?IdUsuario=${IdUsuario}`)
    const [urlExportar, setUrlExportar] = useState(`${BASE_URL}/exportarInscripciones`)
    const titleColumnsTable = [
        'N°',
        'Actividad',
        'Coordinador de Actividad',
        'Email coordinador',
        'Cupos',
        'Acción'
    ]
    // titulos para ies
    if (dimension === '"true"') {
        titleColumnsTable[1] = 'Programa'
        titleColumnsTable[2] = 'Coordinador de Programa'
    }

    const attributes = [
        'Nombre',
        'Cordinador',
        'email',
        'cupos',
    ]

    const onNavigateDetails = (item) => {
        navigate(
            `estudiantes_antiguos/${item.IdOferta}`, 
            { state: { offer: item } }
        ) //to ApplicantList.jsx
    }
    
  return (
    <>
        <Card>
            <Card.Title>Matrícula Antiguos</Card.Title>
        </Card>
        {/* tabla de programas  */}

        <DynamicTable
                exportar={urlExportar}
                titleColumn={ titleColumnsTable }
                attributes={ attributes }
                endPoint={endPoint}
                onDelete={ false }
                onUpdate={ false }
                labelOptionClick={ 'Lista de aspirantes' }
                handleOptionClick={ onNavigateDetails }
                classNameOptionIcon='bi bi-view-list'
            />
    </>
  )
}
