import React, { useState } from 'react'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import { DynamicTable } from '../../components/DynamicForm/dynamicTable/DynamicTable'
import { useLocation, useParams } from 'react-router-dom'
import { useFetchGet } from '../../hooks/useFetchGet'
import { FormService } from '../../services/api/formService'
import { alerta } from '../../helpers/alertas'

export default function CursosRequeridos() {
    // recibior parametros por url
    const api=new FormService();
    const { id } = useParams()
    const urlCursos=`seguridadAerea/cursosRequisitoFuncion?IdFuncionSaa=${id}`
    const [endPoint, setEndPoint] = useState(urlCursos)
    const [modalVincular, setModalVincular] = useState(false)
    const [cursos]=useFetchGet("cursosPieso/full")
    const [idCurso, setIdCurso] = useState(null)
    
    const vincularCurso = async() => {
        // enviar formulario
        const data = {
            IdFuncionSaa: id,
            IdCursoPieso: idCurso
        }
        const res = await api.create("seguridadAerea/agregarCursoFuncion", data)
        if (res.success) {
            setModalVincular(false)
            setEndPoint(`${urlCursos}?id=${Math.random()}`)
        }else{
            alerta("Ocurrio un error","error")
        }
        
    }
  return (
    <>
        <Card>
            <Row>
                <Col>
                    <Card.Title as="h5">Cursos Requeridos</Card.Title>
                </Col>
                <Col 
                    style={{
                        display:"flex",
                        justifyContent:"flex-end"
                    }}
                >
                    <Button 
                        variant="primary"
                        onClick={()=>{
                            setModalVincular(true)
                        }}
                    >
                        Vincular Curso
                    </Button>
                </Col>
            </Row>
            
        </Card>

        <DynamicTable
            endPoint={endPoint}
            titleColumn={["Nombre","Código del Programa","Acciones"]}
            attributes={["Descripcion","CodigoPrograma"]}
            indice={false}
            onDelete={false}
            onUpdate={false}
            labelOptionClick={"Desvincular"}
            handleOptionClick={(e)=>{
                console.log(e)
            }}

        />
        {/* modal para vincular curso */}
        <Modal
            show={modalVincular}
            onHide={()=>{ setModalVincular(false) }}
        >
            {/* enviar formulario */}
            <Form onSubmit={(e)=>{ e.preventDefault() ;vincularCurso() }} >
            <Modal.Header closeButton>
                <Modal.Title>Vincular Curso</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               
                    {/* seleccionar curso a vincular */}
                    <Form.Group>
                        <Form.Label>Curso</Form.Label>
                        <Form.Control as="select"
                            required
                            value={idCurso}
                            onChange={(e)=>{
                                setIdCurso(e.target.value)
                            }}
                        >
                            <option value="">Selecciona un curso</option>
                            {cursos.map((curso)=>{
                                return <option value={curso.IdCursoPieso}>{curso.Descripcion}</option>
                        })}
                        </Form.Control>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{ setModalVincular(false) }}>
                        Cerrar
                    </Button>
                    {/* boton submit */}
                    <Button variant="primary" type="submit">
                        Vincular
                    </Button>
                </Modal.Footer>
                </Form>
        </Modal> 
    </>
  )
}
