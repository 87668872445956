import React, { useState, useEffect } from 'react'

import { Button } from '../button/button'
import { useParams } from 'react-router-dom'
import { FormService } from '../../services/api/formService'
import { Message } from '../Message/Message'
import { ProfileHeader } from '../ProfileHeader/ProfileHeader'
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner'

import './Graduate.css'


const Graduate = ({
    student,
    setError,
    loading,
    setLoading,
    fetchDataStudents,
}) => {

    const [cancel, setCancel] = useState(false)
    const [issued, setIssued] = useState(false)
    const [idTime, setIdTime] = useState(null)
    const [graduate, setGraduate] = useState(false)
    const [isIssued, serIsIssued] = useState(false)
    const [loadingFull, setLoadingFull] = useState(false)
    const { idCourse } = useParams()
    const LINK_DOWNLOAD = 'https://halconerp.com/v1/public/api/estudiante/diploma?IdDiploma='
    const formService = new FormService()
    const TIMETOREVERSE = 3800

    const fetchDataCertificate = async () => {
        const formService = new FormService()
        const response = await formService.getAll(
            `diplomaEstudiante?IdOferta=${idCourse}&IdEstudiante=${student.IdEstudiante}`
        )
        serIsIssued(response?.respuesta)
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchDataCertificate()
        }
        fetchData()
    }, [])

    const onGraduate = async () => {
        const ORDER = 1
        const data = {
            Graduado: ORDER,
        }
        try {
            setCancel(true)
            setGraduate(false)
            const idTimeout = setTimeout(async () => {
                setLoadingFull(true)
                await formService.update(
                    'estudiante', 
                    data, 
                    student.IdEstudiante
                )
                setGraduate(true)
                setLoadingFull(false)
                fetchDataStudents()
            }, TIMETOREVERSE)
            setIdTime(idTimeout)
        } catch (err) {
            setError(err)
            setLoadingFull(false)
            setCancel(false)
            setGraduate(false)
        }
    }

    const cancelGraduation = () => {
        clearTimeout(idTime)
        setCancel(false)
    }

    const onIssueCertificate = async () => {
        const data = {
            IdEstudiante: student.IdEstudiante,
            IdOferta: idCourse,
        }
        try {
            setLoading(true)
            await formService.create('diplomas', data)
            fetchDataStudents()
            setLoading(false)
            setIssued(true)
        } catch (err) {
            setError(err)
            setIssued(false)
            setLoading(false)
        }
    }

    return (
        <>
            {
                loadingFull
                ? <LoadingSpinner />
                : <>
                    {
                        graduate && (
                            <Message type='ok'>
                                ¡Enhorabuena, estudiante graduado! 🎉
                            </Message>
                        )
                    }
                    <ProfileHeader 
                        profile={ student }
                        actions={ false }
                        isGraduate={ student.Graduado || graduate }
                    />
                    <section className='graduate__controller'>
                        {
                            (student.Graduado === '0' || !!graduate) && (
                                cancel 
                                ? !graduate && (
                                    <Button 
                                        type='loading-progress'
                                        onClick={ cancelGraduation }
                                    >
                                        Revertir
                                    </Button>
                                )
                                : <Button 
                                    type='send'
                                    onClick={ onGraduate }
                                >
                                    Graduar 🎓
                                </Button>
                            )
                        }
                        {
                            (student.Graduado === '1' || graduate) && (
                                issued || isIssued === 'true'
                                ? <Button
                                    isAnchor={ true }
                                    to={ `${LINK_DOWNLOAD}${student.IdEstudiante}` }
                                    type='send'
                                >
                                    Descargar diploma
                                </Button>
                                : <Button 
                                    type='send'
                                    onClick={ onIssueCertificate }
                                    loading={ loading }
                                >
                                    Expedir diploma
                                </Button>
                            )
                        }
                        <p className='graduate__guide'>
                            Graduar | Expedir diploma | Descargar diploma
                        </p>
                    </section>   
                </>
            }
        </>
    )
}

export { Graduate }