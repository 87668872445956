import React from 'react'
import { useState } from 'react'
import { Button, Card, Col, ListGroup, Modal, Row, Table } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { useFetchGet } from '../../../hooks/useFetchGet'
import Header from './Componentes/Header'
import { BsFillPencilFill,BsCheckLg } from "react-icons/bs";

export default function HistorialIES({perfil=true}) {
  const usuario=JSON.parse(localStorage.getItem("usuario")).usuario
    const [data,loading,error]=new useFetchGet(`educacionIES/cursosIES?IdUsuario=${usuario.IdUsuario}`)
    const [modal,setModal]=useState();
    const [Item,setItem]=useState();
    const [periodos,loading2,error2]=new useFetchGet(`educacionIES/periodosCurso?IdUsuario=${usuario.IdUsuario}&IdCurso=${Item?.IdCurso}`)
    const [asignaturas,setAsignaturas]=useState(null);
    const grid={
      "display": "grid",
      "grid-template-columns": "repeat(auto-fit, minmax(400px, 1fr))"
    }
    
  return (
    <>
    <div style={{maxWidth:1000,margin:"0 auto",marginTop:40}}>
        {perfil&&
          <Header usuario={usuario}/>
        }
        <Card>
          <Card.Title>
              Historial Educación Superior
          </Card.Title>
      </Card>
      <Table>
            <thead>
                <tr>
                    <th>Nombre</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                {data.map((curso,key)=>
                <tr key={key}>
                    <td>{curso.Nombre}</td>
                    <td>
                      <Button
                        onClick={()=>{
                          setModal(true)
                          setAsignaturas(curso)
                          setItem(curso)
                        }}
                      >Ver más</Button>
                    </td>
                </tr>
                )
                }
            </tbody>
        </Table>
    </div>
    
    <Modal
      show={modal}
      onHide={()=>setModal()}
      fullscreen={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>{Item?.Nombre}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
              <Row>
                <Col sm="3" style={{borderRight:"1px solid #ccc"}}>
                  <div style={{textAlign:"center"}}>
                    <b>Peridos</b>
                  </div>
                  <ListGroup variant='flush'>
                    <ListGroup.Item
                      onClick={()=>setAsignaturas(Item)}
                      active={asignaturas?.IdOferta==Item?.IdOferta?true:false}
                    >
                          <Row>
                            <Col sm="8">
                              <b>Total</b>
                              <div>---- -- --</div>
                            </Col>
                            <Col>
                            { periodos.reduce((acumulador, actual) => acumulador + actual.promedio_periodo, 0)/periodos.length}
                            </Col>
                          </Row>
                      </ListGroup.Item>
                    {periodos.map((periodo,key)=>
                        <ListGroup.Item 
                          key={key}
                          onClick={()=>setAsignaturas(periodo)}
                          active={periodo.IdOferta==asignaturas.IdOferta?true:false}
                        >
                          <Row>
                            <Col sm="8">
                              <b>
                                {periodo.IdEstadoOferta==3?
                                  <BsCheckLg/>                                  
                                  :
                                  <BsFillPencilFill/>                                  
                                }
                                {' '}
                                {periodo.codigo_grupo}
                                </b>
                              <br></br>
                              {periodo.Fecha}
                            </Col>
                            <Col>{periodo.promedio_periodo}</Col>
                          </Row>
                        </ListGroup.Item>
                    )}
                  </ListGroup>
                </Col>
                <Col>
                  <div style={{textAlign:"center"}}>
                    <b>Asignaturas</b>
                  </div>
                  <div style={grid}>
                      {asignaturas && asignaturas?.asignaturas.map((asignatura,key)=>
                        <div style={{padding:10}}>
                          <div style={{
                            background:"#fff",
                            border:"1px solid #ccc",
                            padding:10,
                            borderRadius:10
                            }}>
                          {asignatura.asignatura}

                          {asignatura.IdMallaAspirante?
                            <>
                              {asignatura.IdEstadoOferta!=3?
                                <div style={{color:"blue"}}>En curso</div>
                                :
                                <>
                                  {asignatura.definitiva>3.5?
                                    <div style={{color:"green"}}>Aprobo</div>
                                    :
                                    <div style={{color:"red"}}>Reprobo</div>
                                  }
                                </>
                              }                              
                            </>
                            :
                            <div style={{color:"grey"}}>Sin matricular</div>
                          }
                          
                          </div>
                        </div>
                      )}
                  </div>
                </Col>
              </Row>
      </Modal.Body>
    </Modal>
    </>
  )
}
