import React, { useState, useEffect } from 'react'

import { useParams, useLocation, Navigate, useNavigate } from 'react-router-dom'
import { FormService } from '../../../services/api/formService'
import { ApplicantList } from './ApplicantList'
import { useSearch } from '../../../hooks/useSearch'
import { useToggleList } from '../../../hooks/useToggleList'

import { BASE_URL } from '../../../services/api/config'

const ApplicantListContainer = ({}) => {

    const [loading, setLoading] = useState(false)
    const [loadingFull, setLoadingFull] = useState(true)
    const [error, setError] = useState(false)
    const [preRegistered, setPreRegistered] = useState([])
    const [inscribed, setInscribed] = useState([])
    const [enrolled, setEnrolled] = useState([])
    const [admitted, setAdmitted] = useState([])
    const [selectedList, setSelectedList] = useState([])
    const [isOpenModalNextStop, setIsOpenModalNextStop] = useState()
    const [isOpenModalAddApplicant, setIsOpenModalAddApplicant] = useState(false)
    const [fasesLista, setFasesLista] = useState([]);

    const [response, setResponse] = useState({})
    const URL_EXPORT = `${BASE_URL}/exportar/`

    const URL_DOWNLOAD_PRE_REGISTERED= `${URL_EXPORT}preInscritos?IdOferta=`
    const URL_DOWNLOAD_INSCRIBED = `${URL_EXPORT}inscritos?IdOferta=`
    const URL_DOWNLOAD_ENROLLED = `${URL_EXPORT}admitidos?IdOferta=`
    const URL_DOWNLOAD_ADMITTED= `${URL_EXPORT}matriculados?IdOferta=`
    const { idApplicantList } = useParams()
    const { state: { offer,empresa } } = useLocation()
    const URL_APPLICANTS = 'ofertas/aspirantes?IdOferta='
    const ENDPOINT_A_INSCRITOS = 'aspirantes/inscribir'
    const ENDPOINT_A_ADMIDOS = 'aspirantes/admitir'
    const ENDPOINT_A_ESTUDIANTES = 'aspirantes/matricular'
    const [optionHandlingData, setOptionHandlingData] = useState({
        search: offer.requiere_inscripcion == '1' ? 'pre-inscrito' : 'admitido',
        assignedButtonName: 'Pasar a inscritos',
        responsibleEndpoint: ENDPOINT_A_INSCRITOS,
        urlDownload: `${URL_DOWNLOAD_PRE_REGISTERED}${idApplicantList}`
    })
    const [inputSearch, searchResult, onSearch] = useSearch(selectedList)
    const { 
        selectedNextStop,
        toggled,
        checked,
        handleChangeCheckbox,
        switchSelect,
        numberIsSelected,
        onReset,
    } = useToggleList(selectedList, 'IdAspirante', offer.IdOferta)

    const OPCION_INSCRIPCIONES = {
        PRE_INSCRITOS:1,
        INSCRITOS:2,
        ADMITIDOS:3,
        ESTUDIANTES:4
    }

    
    const fetchApplicants = async () => {
        let urlAspirantes;
        if(empresa?.IdEmpresa){
            urlAspirantes=`ofertas/aspirantesEmpresas?IdOferta=${idApplicantList}&IdEmpresa=${empresa?.IdEmpresa}`
        }else{
            urlAspirantes=`ofertas/aspirantes?IdOferta=${idApplicantList}`
        }
        
        try {
            setLoadingFull(true)
            const formService = new FormService()
            const data = await formService.getAll(urlAspirantes)
            setPreRegistered(data?.pre_inscritos)
            setInscribed(data?.inscritos)
            setEnrolled(data?.admitidos)
            setAdmitted(data?.matriculados)
            setSelectedList(
                offer.requiere_inscripcion == '1' 
                && optionHandlingData.search == 'pre-inscrito' 
                ? data?.pre_inscritos
                : data?.admitidos
            )
            setLoadingFull(false)
        } catch (err) {
            setLoadingFull(false)
            setError(err)
        }
    }

    const fases = async (IdConvocatoria) => {

        try {
            setLoadingFull(true)
            const formService = new FormService()
            const data = await formService.getAll(`convocatoriaFase/fasesLista?IdConvocatoria=${IdConvocatoria}`)
            setFasesLista(data)

            setLoadingFull(false)
        } catch (err) {
            setLoadingFull(false)
            setError(err)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchApplicants()
            if(offer.IdTipoMecanismo=="4" || offer.IdTipoMecanismo=="7" || offer.IdTipoMecanismo=="9"){
                await fases(offer.IdConvocatoria)
            }
            
        }
        fetchData()
    }, [])

    const selectList = (list) => {
        onReset()
        switch (list) {
            case OPCION_INSCRIPCIONES.PRE_INSCRITOS:
                setSelectedList(preRegistered)
                setOptionHandlingData({
                    search: 'pre-inscrito',
                    assignedButtonName: 'Pasar a inscritos',
                    responsibleEndpoint: ENDPOINT_A_INSCRITOS,
                    urlDownload: `${URL_DOWNLOAD_PRE_REGISTERED}${idApplicantList}`
                })
                break;
            case OPCION_INSCRIPCIONES.INSCRITOS:
                setSelectedList(inscribed)
                setOptionHandlingData({
                    search: 'inscrito',
                    assignedButtonName: 'Pasar a admitidos',
                    responsibleEndpoint: ENDPOINT_A_ADMIDOS,
                    urlDownload: `${URL_DOWNLOAD_INSCRIBED}${idApplicantList}`
                })
                break;
            case OPCION_INSCRIPCIONES.ADMITIDOS:
                setSelectedList(enrolled)
                setOptionHandlingData({
                    search: 'admitido',
                    assignedButtonName: 'Pasar a estudiantes',
                    responsibleEndpoint: ENDPOINT_A_ESTUDIANTES,
                    urlDownload: `${URL_DOWNLOAD_ENROLLED}${idApplicantList}`
                })
                break;
            case OPCION_INSCRIPCIONES.ESTUDIANTES:
                setSelectedList(admitted)
                setOptionHandlingData({
                    search: 'estudiante',
                    assignedButtonName: null,
                    responsibleEndpoint: null,
                    urlDownload: `${URL_DOWNLOAD_ADMITTED}${idApplicantList}`
                })
                break;
            default:
                break;
        }
    }

    const handleOpenModalAddApplicant = () => {
        setIsOpenModalAddApplicant(!isOpenModalAddApplicant)
    }

    const handleOpenModalNextStop = () => {
        setIsOpenModalNextStop(!isOpenModalNextStop)
    }
    
    const goToTheNextStop = async () => {
        const formService = new FormService()
        try {
            setLoading(true)
            setError(null)
            const response = await formService.create(
                optionHandlingData.responsibleEndpoint, 
                {aspirantes: selectedNextStop}
            )
            setResponse(response?.respuesta)
            fetchApplicants()
            setIsOpenModalNextStop(!isOpenModalNextStop)
            setLoading(false)
        } catch (err) {
            setError(err)
            setLoading(false)
        }
    }


    return (
        <ApplicantList 
            empresa={empresa}
            offer={ offer }
            selectList={ selectList }
            optionHandlingData={ optionHandlingData }
            loading={ loading }
            inputSearch={ inputSearch }
            loadingFull={ loadingFull }
            OPCION_INSCRIPCIONES={ OPCION_INSCRIPCIONES }
            error={ error }
            setError={ setError }
            handleOpenModalAddApplicant={ handleOpenModalAddApplicant }
            isOpenModalAddApplicant={ isOpenModalAddApplicant }
            selectedList={ selectedList }
            searchResult={ searchResult }
            onSearch={ onSearch }
            toggled={ toggled }
            checked={ checked }
            handleChangeCheckbox={ handleChangeCheckbox }
            switchSelect={ switchSelect }
            numberIsSelected={ numberIsSelected }
            isOpenModalNextStop={ isOpenModalNextStop }
            handleOpenModalNextStop={ handleOpenModalNextStop }
            goToTheNextStop={ goToTheNextStop }
            fetchApplicants={ fetchApplicants }
            fasesLista={fasesLista}
            URL_EXPORT={URL_EXPORT}
        />
    )
}

export { ApplicantListContainer }