  import React, { useEffect, useState } from "react";
  import { Link, useNavigate } from "react-router-dom";
  import { HeaderHome } from "../../components/headerHome/HeaderHome";
  import { FooterHome } from "../../components/Inicio/FooterHome/FooterHome";
  import ApiInicio from "../../services/api/inicio/InicioService";

  import '../inicio/Inicio.css'
  import './OfertaAcademica.css'
  
function OfertaAcademicaPage() {

    const [dimensiones, setDimensiones] = useState([])
    const navigate = useNavigate() 

    const fetchData = async () => {
      const apiInicio = new ApiInicio()
      const data = await apiInicio.getPublicDimensiones()
      setDimensiones(data)
    }

    useEffect(() => {
      navigate(
        `../oferta/${1}/cursos`,
        {
          state: {
            item:{IdDimension:1}
          },
          replace:true
        }
      )
      fetchData()
    }, [])

    const onClickDimension = async (item) => {
      // navigate("cursos",{state:item})
      navigate(
        `../oferta/${item.IdDimension}/cursos`,
        {
          state:item,
          replace:true
        }
      )
      // const nav = navigate('login',{replace:true})
    }
    
  return(
    <>
      <header className="cursos__header">
        <HeaderHome type='dark'/>
      </header>
      <div className="content">
        <div className="row col-md-12 col-sm-12">
          <div className="col-md-7 col-sm-12 left-content">
            <div className="cards-container">
              <div className="cards-container">
                {
                  !!dimensiones && dimensiones.map((item,index) => {
                    return(
                      <div 
                        className="card dimensiones" 
                        onClick={()=>onClickDimension(item)} 
                      >
                        <div 
                          key={index} 
                          className="card-body"
                        >
                          <div className="titles-container">
                            <h5 className="card-title">
                              { item.Descripcion }
                            </h5>
                            <h6 className="card-subtitle">
                              { item.Siglas }
                            </h6>
                          </div>
                        </div>
                      </div>
                    )
                })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="inicio__footer">
        <FooterHome type='dark'/>
      </footer>
    </>
  )
}
  
 
export {OfertaAcademicaPage}

