import React from 'react'

import './ExportList.css'

const ExportList = ({
    link,
    title='Descargar listado',
    children=''
}) => (
    <a 
        href={link} 
        download
        title={title}
        className='export-list__btn-download'
    >
       {children} <i className="bi bi-download"></i>
    </a>
)

export { ExportList }