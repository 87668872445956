import React, { useEffect, useState } from 'react'
import Header from './Componentes/Header'
import { LocalStorage } from '../../../services'
import { Button, Card, Modal } from 'react-bootstrap'
import { FormService } from '../../../services/api/formService'
import TablePaginate from '../../../components/TablePaginate/TablePaginate'
import Swal from 'sweetalert2'
import { alerta } from '../../../helpers/alertas'
import DowloadFile from '../../../components/DowloadFile/DowloadFile'
import { BASE_URL } from '../../../services/api/config'
import { setSearchFields } from '../../../shared/store/table/tableSlice'
import { useDispatch, useSelector} from 'react-redux'

export default function Firmas() {
    const storage=new LocalStorage()
    const usuario_full=JSON.parse(storage.getItem("usuario"))
    const usuario=usuario_full.usuario
    const api=new FormService()
    const [modal,setModal]=useState(false)
    const searchFields=useSelector(state=>state.table.searchFields)
    const dispatch=useDispatch();

    const firmarDocumento = async (item) => {
        const data = {
            EstadoFirma: 1
        };
        
        const result = await Swal.fire({
            title: 'Confirmar',
            text: '¿Está seguro de firmar este documento?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Firmar',
            cancelButtonText: 'Cancelar'
        });

        if (result.isConfirmed) {
            const respuesta = await api.update(`solicitudesFirmas`, data, item.IdSolicitudFirma);
            if(respuesta.success){
                alerta("El documento ha sido firmado", "success")
            }
               dispatch(setSearchFields({...searchFields, "i": Math.random()}));
        }
    }
    const obtnerUrl =  (rowData) => {
        let url
        url=`${BASE_URL}/${rowData.Enlace}?IdOferta=${rowData.IdOferta}`
        if(rowData.Fraccionado){
            if(rowData.TipoFraccionado=="IdEstudiante"){
                url=`${BASE_URL}/${rowData.Enlace}?IdOferta=${rowData.IdOferta}&IdEstudiante=${rowData.IdEstudiante}`
            }else{
                url=`${BASE_URL}/${rowData.Enlace}?IdOferta=${rowData.IdOferta}&IdMalla=${rowData.IdMalla}`
            }
        }
        return url
    }
      

  return (
    <>
    <div style={{maxWidth:1000,margin:"0 auto",marginTop:40}}>
        <Header usuario={usuario}/>
        <Card>
          <Card.Title>
            Documentos por Firmar
          </Card.Title>
      </Card>   
      <TablePaginate 
        apiUrl={`solicitudesFirmas?IdUsuario=${usuario.IdUsuario}`}
        tableHeaders={[
            {title:"Nombre de Documento",attribute:"Nombre"},
            {title:"Fecha de Solicitud",attribute:"FechaCreacion"},
        ]}
        rowActions={[
            ({rowData})=>{
                return(
                    <DowloadFile 
                        endPoint={obtnerUrl(rowData)}
                        nameFile={`${rowData.Nombre}.pdf`}
                        name={rowData.Nombre}

                    />
                )
            },
            ({rowData})=>{
                return(<Button onClick={()=>firmarDocumento(rowData)}>Firmar</Button> )
            }
        ]}
      />
    </div>
    <Modal show={modal} onHide={()=>setModal(false)}>
        <Modal.Header closeButton>
            <Modal.Title>Firmar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>¿Está seguro de firmar este documento?</p>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={()=>setModal(false)}>
                Cancelar
            </Button>
            <Button variant="primary" onClick={()=>setModal(false)}>
                Firmar
            </Button>
        </Modal.Footer>
    </Modal>

    </>
  )
}
