import { useEffect } from 'react'
import { useState } from 'react'
import { Card, Table } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { Button } from '../../../components/button/button' 
import { alertaSinBoton } from '../../../helpers/alertas'
import { FormService } from '../../../services/api/formService'

function CalificarNotas({ canEdit }) {
    const { state: { estudiantes, evento, asignatura, programa, IdUsuario }, } = useLocation()

    const [estudiantesAsistencia, setEstudiantesAsistencia] = useState( estudiantes || [])
    const [calificado,setCalificado]=useState(false);
    

    const onUpdateAsistenciaEstudiante = (value, name, estudiante) => {
        let calificacion=0;
        if(asignatura.IdTipoCalificacion=="1"){
            calificacion=100
        }else{
            calificacion=5
        }

        if (parseFloat(value) > calificacion || parseFloat(value) < 0) return
        let updateEstudiante = { ...estudiante, Nota: value }
        const newEstudianteList = estudiantesAsistencia.map((es) => {
            if (es.IdEstudianteNota === estudiante.IdEstudianteNota)
                return updateEstudiante
            return es
        })
        setEstudiantesAsistencia([...newEstudianteList])
    }


    useEffect(() => {

        if(estudiantes[0].FechaCreacion==estudiantes[0].FechaActualizacion){
            setCalificado(true)
        }else{
            setCalificado(false)
        }
    }, [estudiantesAsistencia])

    const onSave = () => {
        const api = new FormService()
        const data = estudiantesAsistencia.map((item) => {
            return {
                Nota: item.Nota,
                IdEstudianteNota: item.IdEstudianteNota,
            }
        })

        api.create('docente/guardarNotas', { notas: data,IdDocente:IdUsuario }).then((res) => {
            alertaSinBoton('Guardado!', 'success', 'bottom-end', 1500)
        })
    }

    return (
        <>
            <Card>
                <Card.Title>{programa.Nombre}</Card.Title>
                <p>{asignatura.Descripcion}, Nota {evento.index}, Porcentaje: {evento.Porcentaje}%</p>
            </Card>
            <Table>
                <thead>
                    <tr>
                        <th>
                            <b>
                                <h5 style={{ marginLeft: '9px' }}>
                                    Estudiante
                                </h5>
                            </b>
                        </th>
                        <th>
                            <b>
                                <h5>Nota</h5>{' '}
                            </b>{' '}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {!!estudiantesAsistencia &&
                        estudiantesAsistencia.map(
                            (estudiante, index) => (
                                <tr key={index} className="row-student">
                                    <td>
                                        {/* <div className="content-student">
                                            <img
                                                src={estudiante?.Foto ||user}
                                                alt={`foto estudiante ${estudiante.PrimerNombres} ${estudiante.PrimerApellidos}`}
                                                className="applicant-list__img-user student"
                                            /> */}
                                            <span>
                                                {
                                                    estudiante.PrimerNombres
                                                }{' '}
                                                {
                                                    estudiante.PrimerApellidos
                                                }
                                            </span>
                                        {/* </div> */}
                                    </td>
                                    <div style={{ width: '100px' }}>
                                        <div className="form-group m-1">
                                            {canEdit && calificado ? (
                                                <input
                                                    value={ estudiante.Nota || '' }
                                                    onChange={(e) =>
                                                        onUpdateAsistenciaEstudiante(
                                                            e.target
                                                                .value,
                                                            e.target
                                                                .name,
                                                            estudiante
                                                        )
                                                    }
                                                    onDoubleClick={(
                                                        e
                                                    ) =>
                                                        onUpdateAsistenciaEstudiante(
                                                            '',
                                                            e.target
                                                                .name,
                                                            estudiante
                                                        )
                                                    }
                                                    className="form-control "
                                                    type="number"
                                                    min="0"
                                                    max="100"
                                                    step="1"
                                                ></input>
                                            ) : (
                                                <div className=" ">
                                                    {estudiante.Nota || ''}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </tr>
                            )
                        )}
                </tbody>
            </Table>
            {canEdit && calificado && (
                <Button
                    type={'send'}
                    onClick={() => {
                        onSave()
                    }}
                >
                    Guardar
                </Button>
            )}
        </>
    )
}

export {CalificarNotas}
