import React from 'react'
import { Card } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { DynamicTable } from '../../../components/DynamicForm/dynamicTable/DynamicTable'
import { LocalStorage } from '../../../services'

export default function PeridosInscritos() {
    /* const {state:{IdCurso}}=useLocation(); */
    const ls = new LocalStorage()
    const IdUsuario = ls.getAsJsonItem('usuario').usuario.IdUsuario
    const urlProgramas = `educacionIES/periodosInscritos?IdUsuario=${IdUsuario}&IdCurso=${642}`;

    const navigate = useNavigate()

    const onNavigateDetails = (item) => {
      navigate(
          `${item.IdOferta}/asignaturas`, 
          { state: { programa: item, idUsuario: IdUsuario } } 
      ) 
    }
  
  return (
    <>
      <Card>
        <Card.Title>Periodos Inscritos</Card.Title>
      </Card>
      <DynamicTable
            titleColumn={['N°','Nombre','Fecha Inicio','Fecha Fin', 'Acción']}
            attributes={['Nombre','FechaInicioCurso','FechaFinCurso']}
            endPoint={urlProgramas}
            onDelete={false}
            onUpdate={false}
            labelOptionClick={`Ver más`}
            handleOptionClick={onNavigateDetails}
            classNameOptionIcon={`bi bi-view-list`}
      />
    </>
  )
}
