import { Col, Row, Card as Carb, Table, Button, Card } from "react-bootstrap"

import { Bar } from 'react-chartjs-2';
import { BASE_URL } from "../../services/api/config";
import { useEffect, useState } from "react";
import { FormService } from "../../services/api/formService";
import { setError } from "../../components/DynamicForm/FormSlice";
import { Input } from "../../components/input/input";
import { alerta } from "../../helpers/alertas";



const Usuarios=()=>{
    const formService = new FormService();
    const [barData, setBarData] = useState([]);
    const [datos,setDatos]=useState()
    const [FechaInicio, setFechaInicio] = useState(null)
    const [FechaFin, setFechaFin] = useState(null)
    
    

    const consultarUsuarios = async () => {
        if(FechaInicio === null || FechaFin === null){
            return alerta('Debe seleccionar una fecha inicial y una fecha final')
        }
       
        try {
            const response = await formService.getAll(`planeacion/usuarios?FechaInicio=${FechaInicio}&FechaFin=${FechaFin}`)
            setDatos(response)
            setBarData(response.edades)
        } catch (err) {
            setError(err)
        }
    }

    // const filterData = (data) => {
    //     const  dataForBar= []
    //     data?.tipos_evaluaciones.map(tipo => {
    //         dataForBar.push(tipo.contador)
    //     })
    //     return dataForBar;
    // }

    // useEffect(() => {
    //     const fetchData = async () => {
    //         await consultarUsuarios();
    //     }
    //     fetchData();
    // }, [])

    const labels = [
        // '1-20', 
        // '20-35', 
        // '35-45', 
        // '45-60', 
        // '>60',
    ];

    const dataVerticalBar = {
        labels,
        datasets: [
            {
                label: 'Número de Usuarios',
                data: barData,
                backgroundColor: '#009490',
            }
        ],
    }
    return(
        <>
        <Card>
            <Card.Title>Reporte de Usuarios</Card.Title>
        </Card>
            <Row>
                <Col>
                    <Input
                        type="date"
                        name="fecha"
                        label="Fecha Inicial"
                        value={FechaInicio}
                        onChange={(e) => setFechaInicio(e)}
                    />
                </Col>
                <Col>
                    <Input
                        type="date"
                        name="fecha"
                        label="Fecha Final"
                        value={FechaFin}
                        onChange={(e) => setFechaFin(e)}
                    />
                </Col>
                <Col>
                    <br />
                    <Button
                        variant="primary" 
                        onClick={consultarUsuarios}
                    >
                        Buscar
                    </Button>
                </Col>
            </Row>
            
            
            

            {/* <Table striped bordered hover>
                <tbody>
                <tr>
                    <td>
                        <a href={`${BASE_URL}/data/usuarios?opcion=1`} title="Descargar excel">
                            Formato usuarios I
                        </a>
                    </td>
                    {datos?.usuario > 10999 &&
                    <td>
                        <a href={`${BASE_URL}/data/usuarios?opcion=2`} title="Descargar excel">
                            Formato usuarios II
                        </a>
                        
                    </td>
                    }
                    {datos?.usuario > 19999 &&
                    <td>
                        <a href={`${BASE_URL}/data/usuarios?opcion=3`} title="Descargar excel">
                            Formato usuarios III
                        </a>
                    </td>
                    }
                    {datos?.usuario > 26999 &&
                    <td>
                        <a href={`${BASE_URL}/data/usuarios?opcion=4`} title="Descargar excel">
                            Formato usuarios IV 
                        </a>
                    </td>
                    }
                    {datos?.usuario > 33999 &&
                    <td>
                        <a href={`${BASE_URL}/data/usuarios?opcion=5`} title="Descargar excel">
                            Formato usuarios V
                        </a>
                    </td>
                    }
                </tr>
                <tr>
                    {datos?.usuario > 40999 &&
                    <td>
                        <a href={`${BASE_URL}/data/usuarios?opcion=6`} title="Descargar excel">
                            Formato usuarios VI
                        </a>
                    </td>
                    }
                    {datos?.usuario > 47999 &&
                    <td>
                        <a href={`${BASE_URL}/data/usuarios?opcion=7`} title="Descargar excel">
                            Formato usuarios VII
                        </a>
                    </td>
                    }
                </tr>
                </tbody>
            </Table> */}
        {/* </Card> */}
        <Card>
            <p style={{textAlign:'center'}}>Número de Usuarios: {datos?.usuario}</p>
        </Card>
        <Card>
            <h3>Usuarios por Sexo</h3>
            <Row>
                <Col>
                    <Carb border="primary" style={{ width: '18rem' }}>
                        <Carb.Header>
                            Hombres
                        </Carb.Header>
                        <Carb.Body>
                            <Carb.Title>
                            {datos?.hombres}
                            </Carb.Title>                           
                        </Carb.Body>
                    </Carb>
                </Col>
                <Col>
                <Carb>
                        <Carb.Header>
                            Mujeres
                        </Carb.Header>
                        <Carb.Body>
                            <Carb.Title>
                            {datos?.mujeres}
                            </Carb.Title>                           
                        </Carb.Body>
                    </Carb>
                </Col>
                <Col>
                <Carb>
                        <Carb.Header>
                            No Binarios
                        </Carb.Header>
                        <Carb.Body>
                            <Carb.Title>
                            {datos?.binarios}
                            </Carb.Title>                           
                        </Carb.Body>
                    </Carb>
                </Col>
                <Col>
                <Carb>
                        <Carb.Header>
                            Otros
                        </Carb.Header>
                        <Carb.Body>
                            <Carb.Title>
                            {datos?.otros}
                            </Carb.Title>                           
                        </Carb.Body>
                    </Carb>
                </Col>
            </Row>
            <div style={{marginTop:20,marginBottom:20}}>
                <h3>Usuarios por Rango de Edades</h3>
            </div>
            <Bar data={dataVerticalBar} />

        </Card>
        </>
    )
}   

export {Usuarios}