import React from 'react'
import { Accordion, Col, Row } from 'react-bootstrap'

export default function PreguntasFrecuentes() {
  return (
    <div
    style={{
        backgroundColor: '#f5f5f5',
        textAlign: 'center',
        padding: '50px 0',
    }}
>
    <div
        style={{
            maxWidth:'1200px',
            margin: '0 auto',
            padding: '50px 0',
        }}                    
    >
    <Accordion>
        <Accordion.Item eventKey="0">
            <Accordion.Header
                style={{
                    textAlign: 'center',
                    display:'flex',
                    justifyContent:'center',
                }}
            >
                <h3
                    style={{
                        textAlign: 'center',
                        display: 'inline-block',
                    }}
                >
                    Preguntas frecuentes
                </h3>
                </Accordion.Header>
            <Accordion.Body>
                <Row>
                    <Col>
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>1. ¿Cómo puedo recuperar mi contraseña de Halcón ERP versión CEA?</Accordion.Header>
                                <Accordion.Body>
                                    Sigue el proceso de recuperación de contraseña en el sistema en www.cea.halconerp.com o ponte en contacto con el equipo de soporte. Puede ver el siguiente video: <a target='_blank' href='https://youtu.be/3Yp2sevHyCU'>https://youtu.be/3Yp2sevHyCU</a>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>2. ¿Cómo accedo a mi cuenta en Halcón ERP versión CEA?</Accordion.Header>
                                <Accordion.Body>
                                Ingresa tus credenciales (correo electrónico y contraseña) de inicio de sesión en la página de inicio de Halcón ERP versión CEA. Puede ver el siguiente video: <a target='_blank' href='https://youtu.be/3Yp2sevHyCU'>https://youtu.be/3Yp2sevHyCU</a>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>3. ¿Cómo actualizo mi información personal en Halcón ERP versión CEA?</Accordion.Header>
                                <Accordion.Body>
                                Navega hasta la sección de perfil en Halcón ERP versión CEA y actualiza la información requerida. Le recomendamos que actualice toda la información referente a Hoja de vida del estudiante y hoja de vida docente.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>4. ¿Qué hago si encuentro un error en mi perfil de Halcón ERP versión CEA?</Accordion.Header>
                                <Accordion.Body>
                                Informa sobre el error al equipo de soporte de Halcón ERP versión CEA a través del correo electrónico soporte@halconerp.com
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>5. ¿Cómo me inscribo en una nueva actividad académica (curso) o programa académico en Halcón ERP versión CEA?</Accordion.Header>
                                <Accordion.Body>
                                Explora la sección de Portafolio en Halcón ERP versión CEA y sigue los pasos de inscripción o en IES podría realizar una preinscripción
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>6. ¿Qué debo hacer si no puedo acceder a ciertos módulos en Halcón ERP versión CEA?</Accordion.Header>
                                <Accordion.Body>
                                Comunícate con el equipo de soporte de Halcón ERP versión CEA para obtener los permisos de acceso adecuados.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>7. ¿Cómo recupero un archivo eliminado en Halcón ERP versión CEA?</Accordion.Header>
                                <Accordion.Body>
                                    Contacta al equipo de soporte de Halcón ERP y proporciona detalles sobre el archivo eliminado.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="7">
                                <Accordion.Header>8. ¿Qué debo hacer si mis calificaciones no se muestran correctamente en Halcón ERP versión CEA?</Accordion.Header>
                                <Accordion.Body>
                                Informa a la mesa de ayuda de Halcón ERP versión CEA sobre el problema y solicita una revisión de tus calificaciones o podría hacerlo a través de la oficina de Registro y Control del CEA. 
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="8">
                                <Accordion.Header>9. ¿Cómo solicito una constancia de estudio en Halcón ERP versión CEA?</Accordion.Header>
                                <Accordion.Body>
                                    Explora la opción correspondiente en Halcón ERP versión CEA, en el módulo autoservicios que se encuentra en la ventana de Mi Perfil y sigue los pasos para solicitar la constancia.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="9">
                                <Accordion.Header>10. ¿Qué hago si tengo problemas con el pago de matrícula en Halcón ERP versión CEA?</Accordion.Header>
                                <Accordion.Body>
                                    Comunícate Financiera o con la mesa de ayuda de Halcón ERP versión CEA para recibir asistencia con el proceso de pago.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="10">
                                <Accordion.Header>11. ¿Cómo actualizo mi información de contacto en Halcón ERP versión CEA? </Accordion.Header>
                                <Accordion.Body>
                                    Accede a la sección de perfil en Halcón ERP versión CEA y actualiza la información de contacto.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="11">
                                <Accordion.Header>12. ¿Qué debo hacer si no puedo ver las fechas y horarios de mis clases en Halcón ERP?</Accordion.Header>
                                <Accordion.Body>
                                Comunícate con el equipo de soporte de Halcón ERP versión CEA para resolver el problema.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="12">
                                <Accordion.Header>13. ¿Cómo puedo obtener ayuda adicional para usar Halcón ERP versión CEA?</Accordion.Header>
                                <Accordion.Body>
                                    Explora la sección de ayuda “Las preguntas más frecuentes en Halcón ERP versión CEA” o ponte en contacto con el equipo de soporte.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="13">
                                <Accordion.Header>14. ¿Cómo puedo cambiar mi nombre de usuario en Halcón ERP versión CEA?</Accordion.Header>
                                <Accordion.Body>
                                    Consulta la sección de configuración de perfil en Halcón ERP versión CEA para realizar cambios en tu nombre de usuario, previa autorización de registro y control. Recuerda que la información personal no podrá ser modificada porque podría actualizarse el historial académico reportado en la plataforma.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="14">
                                <Accordion.Header>15. ¿Qué debo hacer si olvidé mi correo electrónico del usuario de Halcón ERP versión CEA?</Accordion.Header>
                                <Accordion.Body>
                                    Ponte en contacto con el equipo de soporte de Halcón ERP versión CEA para solicitar asistencia con su correo electrónico de usuario.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="15">
                                <Accordion.Header>16. ¿Qué hago si mi cuenta de Halcón ERP versión CEA está bloqueada?</Accordion.Header>
                                <Accordion.Body>
                                    Comunícate con el equipo de soporte de Halcón ERP versión CEA para resolver el problema de bloqueo de tu cuenta.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="16">
                                <Accordion.Header>17. ¿Cómo puedo cambiar mi contraseña en Halcón ERP versión CEA?</Accordion.Header>
                                <Accordion.Body>
                                    Accede a la sección de configuración de perfil en Halcón ERP versión CEA y sigue los pasos para cambiar tu contraseña.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="17">
                                <Accordion.Header>18. ¿Qué debo hacer si tengo problemas para cargar archivos en Halcón ERP versión CEA?</Accordion.Header>
                                <Accordion.Body>
                                    Verifica el tamaño y formato de los archivos que intentas cargar. Si el problema persiste, comunícate con el equipo de soporte de Halcón ERP versión CEA.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="18">
                                <Accordion.Header>19. ¿Qué hago si no puedo ver los anuncios importantes en Halcón ERP versión CEA?</Accordion.Header>
                                <Accordion.Body>
                                    Asegúrate de estar navegando en la sección correspondiente a los anuncios o contacta al equipo de soporte de Halcón ERP versión CEA para resolver el problema.
                                </Accordion.Body>
                            </Accordion.Item>
                            </Accordion>
                    </Col>
                    <Col>
                        <Accordion >
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>20. ¿Cómo puedo agregar una nueva actividad académica o programa a mi lista en Halcón ERP versión CEA?</Accordion.Header>
                                <Accordion.Body>
                                Busca la opción de "Portafolio Académico" en Halcón ERP versión CEA y sigue los pasos para participar en el nuevo proceso de selección en tu lista.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>21. ¿Cómo puedo solicitar una transferencia de créditos en Halcón ERP versión CEA?</Accordion.Header>
                                <Accordion.Body>
                                    Ponte en contacto con el equipo de soporte de Halcón ERP versión CEA y proporciona los detalles de la transferencia de créditos que deseas realizar.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>22. ¿Qué hago si mi cuenta de Halcón ERP versión CEA ha sido comprometida o hackeada?</Accordion.Header>
                                <Accordion.Body>
                                Notifica de inmediato al equipo de soporte para tomar las medidas necesarias para asegurar tu cuenta.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>23. ¿Cómo puedo eliminar una asignatura de mi lista en Halcón ERP versión CEA?</Accordion.Header>
                                <Accordion.Body>
                                Accede a la sección correspondiente en Halcón ERP y busca la opción para eliminar la asignatura de tu lista y realiza la solicitud en los plazos establecidos por el CEA
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>24. ¿Qué debo hacer si no puedo encontrar un archivo que subí a Halcón ERP versión CEA?</Accordion.Header>
                                <Accordion.Body>
                                Verifica la ubicación correcta del archivo en Halcón o comunícate con el equipo de soporte para obtener ayuda en la búsqueda del archivo.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>25. ¿Qué hago si mi cuenta de Halcón ERP versión CEA está desactivada?</Accordion.Header>
                                <Accordion.Body>
                                Comunícate con el equipo de soporte para resolver el problema de desactivación de tu cuenta.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>26. : ¿Cómo puedo solicitar una tutoría en Halcón ERP versión CEA?</Accordion.Header>
                                <Accordion.Body>
                                Ingresa a tu cuenta de Halcón ERP versión CEA y busca la opción para solicitar una tutoría, sigue los pasos proporcionados.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="7">
                                <Accordion.Header>27. ¿Qué debo hacer si mi nombre aparece incorrectamente en los documentos generados por Halcón ERP versión CEA?</Accordion.Header>
                                <Accordion.Body>
                                Comunícate con el equipo de soporte de Halcón ERP versión CEA y proporciona la corrección necesaria para tu nombre.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="8">
                                <Accordion.Header>28. ¿Cómo puedo consultar el horario de atención de la mesa de ayuda?</Accordion.Header>
                                <Accordion.Body>
                                Busca la sección correspondiente donde se muestra el horario de atención de la mesa de ayuda y escribanos a soporte@halconerp.com
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="9">
                                <Accordion.Header>29. ¿Qué debo hacer si mi documento no se guarda correctamente?</Accordion.Header>
                                <Accordion.Body>
                                Asegúrate de tener una conexión a internet estable y suficiente espacio de almacenamiento. Si el problema persiste, comunícate con el equipo de soporte.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="10">
                                <Accordion.Header>30. : ¿Qué debo hacer si mi archivo adjunto en Halcón ERP versión CEA contiene virus o malware?</Accordion.Header>
                                <Accordion.Body>
                                No abras el archivo y comunícate de inmediato con el equipo de soporte de Halcón ERP versión CEA para informar sobre la situación y recibir asesoramiento adicional.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="11">
                                <Accordion.Header>31. ¿Cómo puedo cancelar mi inscripción a una actividad académica en Halcón ERP versión CEA?</Accordion.Header>
                                <Accordion.Body>
                                Busca la opción "Cancelar inscripción" junto a la actividad o programa académico que deseas cancelar en Halcón ERP versión CEA.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="12">
                                <Accordion.Header>32. ¿Qué debo hacer si mi correo electrónico de usuario o contraseña en Halcón ERP versión CEA no funcionan?</Accordion.Header>
                                <Accordion.Body>
                                Ponte en contacto con el equipo de soporte de Halcón ERP versión CEA para recibir asistencia para restablecer su usuario o contraseña.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="13">
                                <Accordion.Header>33. ¿Qué debo hacer si no puedo ver correctamente los gráficos o diagramas en Halcón ERP versión CEA?</Accordion.Header>
                                <Accordion.Body>
                                Asegúrate de tener habilitada la visualización de gráficos en tu navegador web. Si el problema persiste, comunícate con el equipo de soporte de Halcón ERP versión CEA para recibir asistencia adicional.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="14">
                                <Accordion.Header>34. ¿Cómo puedo actualizar mi foto de perfil en Halcón ERP versión CEA?</Accordion.Header>
                                <Accordion.Body>
                                    Ingresa a tu cuenta de Halcón ERP versión CEA, ve a la sección de "Perfil" y busca la opción para actualizar tu foto de perfil.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="15">
                                <Accordion.Header>35. ¿Cómo puedo descargar mis certificados en Halcón ERP versión CEA?</Accordion.Header>
                                <Accordion.Body>
                                Explora la sección de certificados en Halcón ERP versión CEA y busca la opción para descargar tus certificados.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="16">
                                <Accordion.Header>36. ¿Qué hago si mi navegador no es compatible con Halcón ERP versión CEA?</Accordion.Header>
                                <Accordion.Body>
                                Asegúrate de utilizar un navegador compatible con Halcón ERP, como Google Chrome o Mozilla Firefox. Si aún tienes problemas, comunícate con el equipo de soporte de Halcón ERP versión CEA.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="17">
                                <Accordion.Header>37. ¿Qué debo hacer si mi cuenta de Halcón ERP versión CEA está suspendida?</Accordion.Header>
                                <Accordion.Body>
                                Comunícate con el equipo de soporte de Halcón ERP versión CEA para resolver el problema de suspensión de tu cuenta.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="18">
                                <Accordion.Header>38. ¿Cómo puedo cambiar mi dirección de correo electrónico en Halcón ERP versión CEA?</Accordion.Header>
                                <Accordion.Body>
                                Accede a la sección de configuración de perfil en Halcón ERP versión CEA y busca la opción para cambiar tu dirección de correo electrónico.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>                            
                    </Col>
                </Row>           
            </Accordion.Body>
        </Accordion.Item>  
    </Accordion>
    </div>
</div>
  )
}
