import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useFetchGet } from "../../../hooks/useFetchGet";
import user from '../../../assets/img/avatar.jpg';
import  './CursosActivos.css'
import { useState } from "react";
import { FormService } from "../../../services/api/formService";
import { optionsDate } from "../../../shared/util/date";

import { TextArea } from "../../../components/componentsForm/textarea/textarea";
import { alerta, alertaSinBoton } from '../../../helpers/alertas'
import { Badge, Button, Card, Col, Dropdown, DropdownButton, ListGroup, Modal, Row, Table } from "react-bootstrap";
import { useEffect } from "react";
import { BASE_SERVIDOR, BASE_URL} from "../../../services/api/config";
import { Input } from "../../../components/input/input";
import DowloadFile from "../../../components/DowloadFile/DowloadFile";
import { DynamicForm } from "../../../components/DynamicForm/DynamicForm";
import { DynamicTable } from "../../../components/DynamicForm/dynamicTable/DynamicTable";
import { LocalStorage } from "../../../services";
import { Alert } from "react-bootstrap";


function PlaneacionAsignatura () {
  
  const api = new FormService()
  const location = useLocation()
  const localStorage = new LocalStorage()
  const user = localStorage.getAsJsonItem('usuario')
  const ID = user?.usuario?.IdUsuario
  const [dataAsignatura, setDataAsignatura] = useState({})
  const [error, setError] = useState(null)
  const [ready, setReady] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalRubrica, setModalRubrica] = useState()
  const [modalRubrica2, setModalRubrica2] = useState()
  const [modalEvaluacion, setModalEvaluacion] = useState()
  const [modalEvaluacion2, setModalEvaluacion2] = useState()
  const [modalActividades, setModalActividades] = useState()
  const [modalActividades2, setModalActividades2] = useState()
  const [modalRecurso, setModalRecurso] = useState()
  const [modalListaRecurso, setModalListaRecurso] = useState()
  const [recurso, setRecurso] = useState()
  const {
    IdPlanAsignatura,
    PresentacionAsignatura, 
    ObjetivoGeneralAsignatura, 
    CompetenciaSerADesarrollar,
    CompetenciaSaberADesarrollar,
    CompetenciaHacerADesarrollar, 
    ContenidoTematico,
    Metodologia,
    Aprobado,
    ObservacionAprobado
  } = dataAsignatura

  const fecthDataAsignatura = async () => {
    try {
        const userDataFetched = await api.getAll(`PlandeAsignatura/byAsignatura?IdAsignatura=${location.state.item.IdAsignatura}`)
        setDataAsignatura(userDataFetched)
    } catch (err) {
        setError(err)
    }
  }

  const handleChange = (value, name) => {
    setReady(false)
    setError(null)
    setDataAsignatura({
        ...dataAsignatura,
        [name]: value
    })
  }

  const handleSudmit = async (e) => {
    e.preventDefault()
    
    if(IdPlanAsignatura) {
      try {
          setError(null)
          setReady(false)
          setLoading(true)
          const respuesta = await api.update("PlandeAsignatura", 
                                              {
                                                "PresentacionAsignatura":dataAsignatura.PresentacionAsignatura,
                                                "ObjetivoGeneralAsignatura":dataAsignatura.ObjetivoGeneralAsignatura,
                                                "CompetenciaSerADesarrollar":dataAsignatura.CompetenciaSerADesarrollar,
                                                "CompetenciaSaberADesarrollar":dataAsignatura.CompetenciaSaberADesarrollar,
                                                "CompetenciaHacerADesarrollar":dataAsignatura.CompetenciaHacerADesarrollar,
                                                "ContenidoTematico":dataAsignatura.ContenidoTematico,
                                                "Metodologia":dataAsignatura.Metodologia
                                              }, 
                                              IdPlanAsignatura)
          if(respuesta.respuesta=="exito"){
            alerta("Éxito", "success")
          } else {
            alerta("Fallo desconocido")
          }
          console.log(respuesta)
      } catch (err) {
        setError(err)
        setLoading(false)
      }
    } else {

      try {
          setError(null)
          setReady(false)
          setLoading(true)
          const respuesta = await api.create("PlandeAsignatura", {...dataAsignatura, IdAsignatura: location.state.item.IdAsignatura, IdUsuario: ID})
          if(respuesta.respuesta=="exito"){
            alerta("Éxito", "success")
          } else {
            alerta("Fallo desconocido")
          }
        } catch (err) {
          setError(err)
          setLoading(false)
        }
    }
  }

    useEffect(()=>{
      fecthDataAsignatura()
    }, [])



  return (
    <>
    {console.log(dataAsignatura)}
    <Card>
      <h3>Asignatura</h3>
      <h6>{location.state.item.Descripcion}</h6>
    </Card>
    <Card>
        {Aprobado == 0 &&
          <Alert bsStyle="warning">
            <Button variant="danger"></Button>
            <strong>Estado:</strong> Rechazado
            <br />
            <strong>{ObservacionAprobado}</strong>
          </Alert>
        }
        {Aprobado == 1 &&
          <Alert bsStyle="success">
            <Button variant="success"></Button>
            <strong>Estado:</strong> Aprobado
            <br />
            <strong>{ObservacionAprobado}</strong>
          </Alert>
        }
        <form 
          onSubmit={ handleSudmit }
          className='user-profile__form'
        >
          <Col>
            <Row>
              <TextArea
                id={'PresentacionAsignatura'}
                name={'PresentacionAsignatura'}
                label={"Presentación de la Asignatura (máx 250 palabras)"}
                value={PresentacionAsignatura || ''}
                onChange={handleChange}
              />
              <TextArea
                id={'ObjetivoGeneralAsignatura'}
                name={'ObjetivoGeneralAsignatura'}
                label={"Objetivo Geneneral de la Asignatura"}
                value={ObjetivoGeneralAsignatura || ''}
                onChange={handleChange}
              />
              <hr />
              <b><h6>Competencias de Aprendizaje a Desarrollar en la Asignatura</h6></b>
              <TextArea
                id={'CompetenciaSerADesarrollar'}
                name={'CompetenciaSerADesarrollar'}
                label={"Ser"}
                value={CompetenciaSerADesarrollar || ''}
                onChange={handleChange}
              />
              <TextArea
                id={'CompetenciaSaberADesarrollar'}
                name={'CompetenciaSaberADesarrollar'}
                label={"Saber"}
                value={CompetenciaSaberADesarrollar || ''}
                onChange={handleChange}
              />
              <TextArea
                id={'CompetenciaHacerADesarrollar'}
                name={'CompetenciaHacerADesarrollar'}
                label={"Hacer"}
                value={CompetenciaHacerADesarrollar || ''}
                onChange={handleChange}
              />
              <hr />
              <TextArea
                id={'ContenidoTematico'}
                name={'ContenidoTematico'}
                label={"Contenido Temático de la Asignatura"}
                value={ContenidoTematico || ''}
                onChange={handleChange}
              />
              
              <TextArea
                id={'Metodologia'}
                name={'Metodologia'}
                label={"Metología (máx 250 palabras)"}
                value={Metodologia || ''}
                onChange={handleChange}
              />

              <Card>
                <Row>
                  <label htmlFor=""><b> Rúbrica de Evaluación de la Asignatura </b></label>
                  <Col> 
                    <Button
                      variant="secondary"
                      onClick={() => setModalRubrica(true)}
                    >
                      Agregar
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      variant="secondary"
                      onClick={() => setModalRubrica2(true)}
                    >
                      Listar
                    </Button>
                  </Col>
                </Row>
              </Card>

              <Card>
                <Row>
                  <label htmlFor=""><b> Evaluación de las Competencias</b> </label>
                  <Col> 
                    <Button
                      variant="secondary"
                      onClick={() => setModalEvaluacion(true)}
                    >
                      Agregar
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      variant="secondary"
                      onClick={() => setModalEvaluacion2(true)}
                    >
                      Listar
                    </Button>
                  </Col>
                </Row>
              </Card>

              <Card>
                <Row>
                  <label htmlFor=""><b> Actividades (Se deben diligenciar tantas sesiones como se necesiten) </b> </label>
                  <Col> 
                    <Button
                      variant="secondary"
                      onClick={() => setModalActividades(true)}
                    >
                      Agregar
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      variant="secondary"
                      onClick={() => setModalActividades2(true)}
                    >
                      Listar
                    </Button>
                  </Col>
                </Row>
              </Card>
              
              <Card>
                <Row>
                  <label htmlFor=""><b> Recursos Bibliográficos </b> </label>
                  <Col> 
                    <Button
                      variant="secondary"
                      onClick={() => setModalRecurso(true)}
                    >
                      Agregar
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      variant="secondary"
                      onClick={() => setModalListaRecurso(true)}
                    >
                      Listar
                    </Button>
                  </Col>
                </Row>
              </Card>
              
              {IdPlanAsignatura ?
              <div style={{textAlign: "right", marginBottom:"15px", marginTop: "12px"}}>
                <Button 
                  type="send"
                >
                  Actualizar
                </Button>
              </div>
              :
              <div style={{textAlign: "right", marginBottom:"15px", marginTop: "12px"}}>
                <Button 
                  type="send"
                >
                  Guardar
                </Button>
              </div>
              }
            </Row>
          </Col>
        </form>
        </Card>

        <Modal
          show={modalRubrica}
          onHide={()=>setModalRubrica(false)}
        >
            <DynamicForm 
              title={"Rúbrica de Evaluación"}
              justDynamicForm = {{ flag: true, idForm: "165" }}
              isCreate={true}
              endPointSubmit={"rubricaEvaluacion/planAsignatura"}
              extraData={
                  {
                    IdUsuario: ID,
                    IdAsignatura: location.state.item.IdAsignatura
                  }
              }
              accionMysql={() => setModalRubrica(false)}
            />
        </Modal>

        <Modal
          show={modalRubrica2}
          onHide={()=>setModalRubrica2(false)}
          size="lg"
        >
          <DynamicTable
            titleColumn={["No", "Criterios", "4. Excelente 91-100", "3. Satisfactorio 81-90", "2. Puede Mejorar 75-80", "1. Inadecuado < 75"]}
            attributes={[
                          "Criterios", "NivelExcelente", "NivelSatisfactorio", "NivelAceptable", "NivelInadecuado"
                        ]}
            endPoint={`rubricaEvaluacion/planAsignatura?IdAsignatura=${location.state.item.IdAsignatura}&IdUsuario=${ID}`}
            onActions={false}
          />
        </Modal>
        
        <Modal
          show={modalEvaluacion}
          onHide={()=>setModalEvaluacion(false)}
        >
            <DynamicForm 
              title={"Evaluación de las Competencias"}
              justDynamicForm = {{ flag: true, idForm: "166" }}
              isCreate={true}
              endPointSubmit={"evaluaciondeCompetencias/planAsignatura"}
              extraData={
                  {
                    IdUsuario: ID,
                    IdAsignatura: location.state.item.IdAsignatura
                  }
              }
              isContentTypeFormData={'1'}
              accionMysql={() => setModalEvaluacion(false)}
            />
        </Modal>

        <Modal
          show={modalEvaluacion2}
          onHide={()=>setModalEvaluacion2(false)}
          size="lg"
        >
          <DynamicTable
            titleColumn={["No de Evaluaciones a Realizar", "Fecha Evaluación", "Elementos de la Evaluación", "Criterios de Verificación"]}
            attributes={[
                          "FechaEvaluacion", "ElementosEvaluacion", "CriteriosVerificacion"
                        ]}
            endPoint={`evaluaciondeCompetencias/planAsignatura?IdAsignatura=${location.state.item.IdAsignatura}&IdUsuario=${ID}`}
            onActions={false}
          />
        </Modal>
        
        <Modal
          show={modalActividades}
          onHide={()=>setModalActividades(false)}
        >
            <DynamicForm 
              title={"Actividades"}
              justDynamicForm = {{ flag: true, idForm: "167" }}
              isCreate={true}
              endPointSubmit={"actividades/planAsignatura"}
              extraData={
                  {
                    IdUsuario: ID,
                    IdAsignatura: location.state.item.IdAsignatura
                  }
              }
              accionMysql={() => setModalActividades(false)}
            />
        </Modal>

        <Modal
          show={modalActividades2}
          onHide={()=>setModalActividades2(false)}
          size="lg"
        >
          <DynamicTable
            titleColumn={["Sesión", "Fecha", "Temática", "Actividades", "Recursos", "Lecturas de Apoyo"]}
            attributes={["Fecha", "Tematica", "Actividad", "Recurso", "LecturaApoyo"]}
            endPoint={`actividades/planAsignatura?IdAsignatura=${location.state.item.IdAsignatura}&IdUsuario=${ID}`}
            onActions={false}
          />
        </Modal> 
        
        <Modal
          show={modalRecurso}
          onHide={()=>setModalRecurso(false)}
        >
            <DynamicForm 
              title={"Recursos Bibliográficos"}
              justDynamicForm = {{ flag: true, idForm: "138" }}
              isCreate={true}
              endPointSubmit={"recursoBibliograficoSyllabus"}
              extraData={
                  {
                    IdUsuario: ID,
                    IdAsignatura: location.state.item.IdAsignatura
                  }
              }
              accionMysql={() => setModalRecurso(false)}
            />
        </Modal>

        <Modal
          show={modalListaRecurso}
          onHide={()=>setModalListaRecurso(false)}
          size="lg"
        >
          <DynamicTable
            titleColumn={["No", "Lectura Obligatoria", "Lectura de Referencia"]}
            attributes={["LecturaObligatoria", "LecturaReferencia"]}
            endPoint={`recursoBibliograficoSyllabus?IdAsignatura=${location.state.item.IdAsignatura}&IdUsuario=${ID}`}
            onActions={false}
          />
        </Modal> 
    </>
  )
}

export  { PlaneacionAsignatura };


