import React from 'react';

import { Button } from '../../button/button';
import { InputFile } from '../../inputFile/inputFile';
import { Input } from '../../input/input';
import { Modal } from '../../modal/Modal';
import { Message } from '../../Message/Message';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { ReceiptUploaded } from '../../ReceiptUploaded/ReceiptUploaded';
import { Navigate, useNavigate } from 'react-router-dom';

const Admission = ({
    loadingFull,
    admissionType,
    announcement,
    isOpenModalDecline,
    onDecline,
    isOpenModalAccept,
    onAccept,
    observation,
    handleChangeObservation,
    isEmpty,
    loading,
    handleState,
    handleSubmit,
    oferta,
}) => {

    return (
        <div className="admission__content-container">
            {loadingFull ? (
                <LoadingSpinner />
            ) : (
                <>
                    <h5 className="admission__admission-type">
                        {admissionType}
                    </h5>
                    {admissionType == 'Convocatoria' && (
                        <div className="admission__phase-container">
                            {announcement.map((item) => (
                                <div
                                    key={item.IdConvocatoriaFase}
                                    className="admission__phase"
                                >
                                    <section className="admission__phase-title-container">
                                        <h5>{item.Nombre}</h5>
                                    </section>
                                    <section className="admission__phase-content">
                                        <section className="admission__phase-info">
                                            <h5>{item.Descripcion}</h5>
                                            <span className="admission__phase-info-text">
                                                Presenta esta fase entre
                                                <span>{item.FechaAplicacion}</span>y el
                                                <span>{item.FechaAplicacionHasta}</span>
                                                <p>Fecha de publicación de admitidos a la siguiente fase: <span className="fw-bold">{item.FechaAdmitidos}</span></p>
                                                {item.fase != null ? (
                                                    <p className="fw-bold">
                                                        Fase calificada
                                                    </p>
                                                ) : (
                                                    <p>
                                                        Esperando Calificación
                                                    </p>
                                                )}
                                                {item.fase?.Aprobo == '1' &&
                                                item.fase != null ? (
                                                    <p>
                                                        <span className="fw-bold">
                                                            Estado:
                                                        </span>
                                                        <span className="text-success">
                                                            {item.IdRespuesta=='1' && 'Apto'}
                                                            {item.IdRespuesta=='2' && 'Aprobo'}                                                            
                                                        </span>
                                                    </p>
                                                ) : null}
                                                {item.fase?.Aprobo == '2' &&
                                                item.fase != null ? (
                                                    <p>
                                                        <span className="fw-bold">
                                                            Estado:
                                                        </span>
                                                        <span className="text-danger">
                                                            {item.IdRespuesta=='1' && 'No Apto'}   
                                                            {item.IdRespuesta=='2' && 'No Aprobo'}                                                            
                                                        </span>
                                                    </p>
                                                ) : null}
                                                {item.fase?.Puntaje && (
                                                    <p className="fw-bold">
                                                        Puntaje:{' '}
                                                        <span className="fw-normal">
                                                            {item.fase?.Puntaje}
                                                        </span>
                                                    </p>
                                                )}
                                                {item.fase?.Observaciones && (
                                                    <p>
                                                        <span className="fw-bold">
                                                            Observaciones:
                                                        </span>{' '}
                                                        {
                                                            item.fase
                                                                ?.Observaciones
                                                        }
                                                    </p>
                                                )}
                                            </span>
                                        </section>
                                        {item.IdTipoFase == '1' && item.fase == null && 
                                        oferta.EstadoAdmision=="0" &&
                                                <form
                                                    onSubmit={(e) =>
                                                        handleSubmit(e, item)
                                                    }
                                                    className="admission__phase-form"
                                                >                                                
                                                    <InputFile
                                                        label="Adjuntar documento"
                                                        name="Archivo"
                                                        accept=".pdf"
                                                    />
                                                    <section>
                                                        <Button type="send">
                                                            Subir
                                                        </Button>
                                                    </section>
                                                </form>
                                        }
                                        {item.IdTipoFase == '1' && item.fase != null && 
                                        item.fase.Aprobo ==null && oferta.EstadoAdmision=="0" &&
                                                <div>
                                                    <ReceiptUploaded
                                                        text="Documento adjuntado"
                                                        dateCreated={
                                                            item.fase
                                                                .FechaCreacion
                                                        }
                                                        fileName={
                                                            item.fase.Archivo
                                                        }
                                                    />
                                                    <form
                                                        onSubmit={(e) =>handleSubmit(e,item)}
                                                        className="admission__phase-form"
                                                    >
                                                        <InputFile
                                                            label="Adjuntar documento"
                                                            name="Archivo"
                                                            accept=".pdf"
                                                        />
                                                        <section>
                                                            <Button type="send">
                                                                Actualizar
                                                            </Button>
                                                        </section>
                                                    </form>
                                                </div>
                                            }
                                    </section>
                                </div>
                            ))}
                        </div>
                    )}
                </>
            )}
            <Modal isOpen={isOpenModalDecline} close={onDecline}>
                <div>
                    <h5>
                        ¿Estás seguro de rechazar la presentación de esta fase?
                    </h5>
                    <Input
                        label="Motivo"
                        placeholder="Explica el motivo de rechazo"
                        name="Observacion"
                        value={observation}
                        onChange={handleChangeObservation}
                    />
                    {isEmpty && (
                        <Message type="warning-white">
                            Describe el motivo de rechazo
                        </Message>
                    )}
                    <section className="d-flex">
                        <Button
                            type="send"
                            onClick={() => handleState(2)}
                            loading={loading}
                        >
                            Rechazar
                        </Button>
                        <Button type="danger" onClick={onDecline}>
                            Cancelar
                        </Button>
                    </section>
                </div>
            </Modal>
            <Modal isOpen={isOpenModalAccept} close={onAccept}>
                <div className="documents_modal-accept">
                    <h5>¿Estás seguro de aprobar esta fase?</h5>
                    <section className="d-flex">
                        <Button
                            type="send"
                            onClick={() => handleState(1)}
                            loading={loading}
                        >
                            Aprobar
                        </Button>
                        <Button type="danger" onClick={onAccept}>
                            Cancelar
                        </Button>
                    </section>
                </div>
            </Modal>
        </div>
    );
};

export { Admission };
