import React, { useEffect } from 'react'
import { useState } from 'react'
import { Button, Card, Table } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { DynamicFormContainer } from '../../components/DynamicForm/DynamicFormContainer'
import { BASE_URL } from '../../services/api/config'
import { FormService } from '../../services/api/formService'

export default function Solicitudes() {
    const Location=useLocation()
    const api=new FormService();
    const convocatoria=Location.state.convocatoria
    const enpoint=`solicitudGrados/solicitudEstudiantesGrado?IdConvocatoriaGrado=${convocatoria?.IdConvocatoriaGrado}`
    const [solicitudes,setSolicitudes]=useState([]);
    const Navigate=useNavigate()

    useEffect(()=>{
        const fecthData=async()=>{
            consultarSolicitudes()
        }
        fecthData();
    },[])

    const consultarSolicitudes=async()=>{
        const solicitudes= await api.getAll(enpoint)
        setSolicitudes(solicitudes)
    }

    const navegarSolicitud=(solicitud)=>{
        Navigate("solicitud",{state:{solicitud:solicitud}})
    }    
    console.log(convocatoria)
  return (
    <>
        <Card>
            <Card.Title>Solicitudes de grado</Card.Title>
        </Card>
        <Card>
            <Table>
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Curso</th>
                        <th>Estado</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                    <tbody>
                        {solicitudes.map((solicitud,key)=>
                            <tr>
                                <td>{solicitud.Nombre}</td>
                                <td>{solicitud.curso}</td>
                                <td>{solicitud.EstadoSolicitud}</td>
                                <td><Button 
                                    onClick={()=>navegarSolicitud(solicitud)}
                                >Ver solicitud</Button></td>
                            </tr>
                        )
                        }
                       
                    </tbody>

            </Table>
        </Card>
    </>
  )
}
