import { Col, Row } from "react-bootstrap"
import { Card } from "../../components/card/Card"
import { BASE_URL } from "../../services/api/config"
import { Title } from "../../shared/components/title/Title"

const TablasGenerales=()=>{



    return(
        <>
            <Card>
                <Title>Tablas generales</Title>
            </Card>
            <Card>
                <p>Exportables de las principales tablas </p>
                <Row>
                    <Col>
                        <a  target="blank" href={`${BASE_URL}/data/usuarios`}>
                            <Card>
                                Usuarios
                            </Card>
                        </a>
                    </Col>
                    <Col>
                        <a  target="blank" href={`${BASE_URL}/data/cursos`}>
                            <Card>
                                Programas y actividades
                            </Card>
                        </a>
                    </Col>
                    <Col>
                        <a  target="blank" href={`${BASE_URL}/data/asignaturas`}>
                            <Card>
                                Asignaturas
                            </Card>                            
                        </a>
                    </Col> 
                </Row>
                <Row>
                    <Col>
                        <a href={`${BASE_URL}/data/oferta`}>
                            <Card>
                                Ofertas
                            </Card>                            
                        </a>
                    </Col>
                    <Col>
                        <a  target="blank" href={`${BASE_URL}/data/notas`}>
                            <Card>
                                Notas
                            </Card>
                        </a>
                    </Col>
                    <Col>
                        <a  target="blank" href={`${BASE_URL}/data/planestudio`}>
                            <Card>
                                Planes de estudio
                            </Card>
                        </a>
                    </Col> 
                </Row>
                <Row>
                    <Col>
                        <a  target="blank" href={`${BASE_URL}/data/gruposhorarios`}>
                            <Card>
                                Grupos y horarios 
                            </Card>                            
                        </a>
                    </Col>
                    <Col>
                        <a  target="blank" href={`${BASE_URL}/data/roles`}>
                            <Card>
                                Tipos y roles de usuario
                            </Card>
                        </a>
                    </Col>
                    <Col>
                        <a  target="blank" href={`${BASE_URL}/data/aspirantes`}>
                            <Card>
                                Aspirantes
                            </Card>
                        </a>
                    </Col> 
                </Row>
            </Card>
        </>
    )
}
export {TablasGenerales}