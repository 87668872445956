import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Modal, Row, Table } from 'react-bootstrap'
import { BsPencilSquare, BsPlus, BsTrashFill, BsXCircleFill } from 'react-icons/bs';
import { useLocation } from 'react-router-dom';
import { DynamicForm } from '../../../components/DynamicForm/DynamicForm';
import { Select } from '../../../components/select/Select';
import { SelectDataList } from '../../../components/SelectDataList/SelectDataList';
import { alerta } from '../../../helpers/alertas';
import { FormService } from '../../../services/api/formService';
import DowloadFileText from '../../../components/DowloadFileText/DowloadFileText';
import DowloadFile from '../../../components/DowloadFile/DowloadFile';
import EventosBloque from './EventosBloque';

import './HorarioLight.css'
import { BASE_URL } from '../../../services/api/config';

export default function HorarioLight() {
    const {state:{horario,oferta}} = useLocation();
    const [showModal, setShowModal] = useState(false);
    const api= new FormService()
    const [bloques,setBloques]=useState([]);
    const [modalEvento, setModalEvento] = useState(false);
    const [IdAula, setIdAula] = useState(null);
    const [IdMalla, setIdMalla] = useState(null);
    const [IdUnidadAsignatura, setIdUnidadAsignatura] = useState(null);
    const [modalDocente, setModalDocente] = useState(false);
    const [docente, setDocente] = useState(null);
    const [docentes, setDocentes] = useState([]);
    const [dataAuxiliar, setDataAuxiliar] = useState(null);
    const [modalEditarEvento, setModalEditarEvento] = useState(false);
    const [eventoActual, setEventoActual] = useState(null);
    const [isUpdate, setIsUpdaate] = useState(false);
    const [modalEliminarEvento, setModalEliminarEvento] = useState(false);
    const [bloqueActual, setBloqueActual] = useState(null);
    const [modalEliminarBloque, setModalEliminarBloque] = useState(false);
    const [modalEditarBloque, setModalEditarBloque] = useState(false);
    const [horaFin, setHoraFin] = useState(null);
    const [horaInicio, setHoraInicio] = useState(null);

    const cerrarModal=()=>{
        setTimeout(() => {
            setShowModal(false);
            consultarBloques()
        }, 3000);
    }

    useEffect(() => {
        const fetchData = async () => {
          consultarBloques();
        };
        fetchData();
      }, []);
      

    const consultarBloques=async()=>{
        const data=await api.getAll(`bloquesHorario?IdHorario=${horario?.IdHorario}`)
        setBloques(data)
    }

    const seleccionarDia=async(IdBloqueHorario,dia)=>{
        const data={IdBloqueHorario:IdBloqueHorario,dia:dia}
        setDataAuxiliar(data)
        setIdAula(null)
        setIdMalla(null)
        setIdUnidadAsignatura(null)
        setDocentes([])
        setModalEvento(false)
        setModalEvento(true)
        setIsUpdaate(false)
    }
    const editarEvento=(evento)=>{
        setIsUpdaate(true)
        setEventoActual(evento)
        setIdAula(evento.IdAula)
        setIdMalla(evento.IdMalla)
        setIdUnidadAsignatura(evento.IdUnidadAsignatura)
        setDocentes(evento.docentes)
        setModalEvento(true)
    }
    const guardarEdicion=async()=>{
        const data={
            IdAula:IdAula,
            IdMalla:IdMalla,
            IdUnidadAsignatura:IdUnidadAsignatura,
            docentes:docentes
        }
        const respuesta=await api.update(`eventosBloque`,data,eventoActual?.IdEventoBloque)
        if(respuesta.respuesta=="exito"){
            setModalEvento(false)
            alerta(respuesta.mensaje)
            consultarBloques()
        }else{
            alerta(respuesta.mensaje,'error')
        }

    }
    const eliminarDocente=(docente)=>{
        const docentesFiltrados=docentes.filter(doc=>doc.IdUsuario!=docente.IdUsuario)
        setDocentes(docentesFiltrados)
    }
    const eliminarEvento=(evento)=>{
        setEventoActual(evento)
        setModalEliminarEvento(true)
    }
    const confirmarEliminarEvento=async()=>{
        console.log(eventoActual.IdEventoBloque)
        const data={
            "Estado":0
        }
        const respuesta=await api.delete(`eventosBloque`,data,eventoActual?.IdEventoBloque,)
        if(respuesta.respuesta=="exito"){
            setModalEliminarEvento(false)
            alerta(respuesta.mensaje)
            consultarBloques()
        }else{
            alerta(respuesta.mensaje,'error')
        }
    }
    const  agregarDocente=()=>{
        setModalDocente()
        setDocentes([...docentes,docente[0]])
        console.log(docentes)
    }
    const guardarEvento=async()=>{
        const data={
            IdBloqueHorario:dataAuxiliar.IdBloqueHorario,
            dia:dataAuxiliar.dia,
            IdAula:IdAula,
            IdMalla:IdMalla,
            IdUnidadAsignatura:IdUnidadAsignatura,
            docentes:docentes
        }
        const respuesta=await api.create('eventosBloque',data)
        if(respuesta.respuesta=="exito"){
            setDataAuxiliar(null)
            setIdAula(null)
            setIdMalla(null)
            setIdUnidadAsignatura(null)
            setDocentes([])
            setModalEvento(false)
            alerta(respuesta.mensaje)
            consultarBloques()
        }else{
            alerta(respuesta.mensaje,'error')
        }
    }
    const eliminarBloque=(bloque)=>{
        setBloqueActual(bloque)
        setModalEliminarBloque(true)
    }
    const confirmarEliminarBloque=async()=>{
        const data={
            "Estado":0
        }
        const respuesta=await api.delete(`bloquesHorario`,data,bloqueActual.IdBloqueHorario)
        if(respuesta.respuesta=="exito"){
            alerta(respuesta.mensaje)
            setModalEliminarBloque(false)
            consultarBloques()
        }else{
            alerta(respuesta.mensaje,'error')
        }
    }

    const onChageAsignatura=async(e)=>{
        setIdMalla(e)
        const data=await api.getAll(`unidadesAsignaturas?IdMalla=${e}`)
        // otener prime elemento de la lista
        console.log(data)
        setIdUnidadAsignatura(data[0].IdUnidadAsignatura)
    }
    
    // editar bloque
    const editarBloque=async()=>{
        const data={
            HoraInicio:horaInicio,
            HoraFin:horaFin,
        }
        const respuesta=await api.update(`bloquesHorario`,data,bloqueActual.IdBloqueHorario)
        if(respuesta.respuesta=="exito"){
            setModalEditarBloque(false)
            consultarBloques()
        }else{
            alerta(respuesta.mensaje,'error')
        }
    }

  return (
    <>
        <Card>
            <Row>
                <Col sm="8">
                <Card.Title>{horario?.Nombre} del {horario.FechaInicio} al {horario.FechaFin}</Card.Title>
                </Col>
                <Col style={{textAlign:"right"}}>
                    <Button
                    style={{margin: '3px'}}
                        variant='secondary'
                        // href={`${BASE_URL}/formatosIES/exportar/horarioResumido?IdOferta=${oferta?.IdOferta}&IdHorario=${horario.IdHorario}`}
                    >
                        <DowloadFileText
                            nameFile={"Horario.pdf"}
                            name={"Descargar"}
                            colortext={"#ffffff"}
                            endPoint={`${BASE_URL}/formatosIES/exportar/horarioResumido?IdOferta=${oferta?.IdOferta}&IdHorario=${horario.IdHorario}`}
                        />
                    </Button>
                    <Button 
                        style={{margin: '3px'}}
                        onClick={()=>setShowModal(true)}
                    >
                        Crear Bloque
                    </Button>
                </Col>
            </Row>
            
        </Card>
        <div className='semana'>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Bloque</th>
                        <th>Lunes</th>
                        <th>Martes</th>
                        <th>Miércoles</th>
                        <th>Jueves</th>
                        <th>Viernes</th>
                        <th>Sábado</th>
                    </tr>
                </thead>
                <tbody>
                    {bloques&&bloques.map((bloque,index)=>(
                        <tr key={index}>
                            <td>
                                <Button
                                    onClick={()=>eliminarBloque(bloque)}
                                    size="sm"
                                    variant="secondary"
                                >
                                    <BsTrashFill/>
                                </Button>
                                {/* boton para edirar bloque */}
                                <Button
                                    onClick={()=>{
                                        setModalEditarBloque(true)
                                        setBloqueActual(bloque)
                                        setHoraInicio(bloque.HoraInicio)
                                        setHoraFin(bloque.HoraFin)
                                    }}
                                    size="sm"
                                    variant="secondary"
                                >
                                    <BsPencilSquare/>
                                </Button>
                                {bloque.HoraInicio} 
                                <br></br>
                                {bloque.HoraFin}
                            </td>
                            <td>
                                <EventosBloque
                                    bloque={bloque}
                                    dia="Lunes"
                                    seleccionarDia={seleccionarDia}
                                    editarEvento={editarEvento}
                                    eliminarEvento={eliminarEvento}
                                />
                            </td>
                            <td>
                                
                                <EventosBloque 
                                    bloque={bloque}
                                    dia="Martes"
                                    seleccionarDia={seleccionarDia}
                                    editarEvento={editarEvento}
                                    eliminarEvento={eliminarEvento}
                                />
                            </td>
                            <td>
                                <EventosBloque 
                                    bloque={bloque}
                                    dia="Miércoles"
                                    seleccionarDia={seleccionarDia}
                                    editarEvento={editarEvento}
                                    eliminarEvento={eliminarEvento}
                                />
                            </td>
                            <td>
                                <EventosBloque 
                                    bloque={bloque}
                                    dia="Jueves"
                                    seleccionarDia={seleccionarDia}
                                    editarEvento={editarEvento}
                                    eliminarEvento={eliminarEvento}
                                />
                            </td>
                            <td>
                                <EventosBloque 
                                    bloque={bloque}
                                    dia="Viernes"
                                    seleccionarDia={seleccionarDia}
                                    editarEvento={editarEvento}
                                    eliminarEvento={eliminarEvento}
                                />
                            </td>
                            <td>
                                <EventosBloque 
                                    bloque={bloque}
                                    dia="Sábado"
                                    seleccionarDia={seleccionarDia}
                                    editarEvento={editarEvento}
                                    eliminarEvento={eliminarEvento}
                                />
                            </td>
                        </tr> 
                    ))}
                </tbody>
            </Table>
        </div>

        <Modal show={showModal} onHide={()=>setShowModal(false)}>
            <Modal.Body>
                <DynamicForm
                    title={'Crear Bloque'}
                    justDynamicForm={{flag:true,idForm:129}}
                    extraData={{idhorario:horario?.IdHorario}}
                    endPointSubmit={'bloquesHorario'}
                    isContentTypeFormData={'0'}
                    isCreate={true}
                    accionMysql={cerrarModal}
                />
            </Modal.Body>
        </Modal>

        <Modal show={modalEvento} onHide={()=>setModalEvento(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Crear Evento el Día {dataAuxiliar?.dia}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Select 
                    name={'IdAula'}
                    label={'Aula'}
                    optionName={'numero'}
                    optionValue={'IdAula'}
                    urlOptionsService={'aulas'}
                    value={IdAula}
                    onChange={setIdAula}
                />
                <Select 
                    name={'IdMalla'}
                    label={'Asignatura'}
                    optionName={'Asignatura'}
                    optionValue={'IdMalla'}
                    urlOptionsService={`malla?IdPlandeEstudio=${oferta?.IdPlandeEstudio}`}
                    value={IdMalla}
                    onChange={onChageAsignatura}
                />
                {/* {IdMalla&&
                    <Select 
                        name={'IdUnidadAsignatura'}
                        label={'Unidad'}
                        optionName={'Nombre'}
                        optionValue={'IdUnidadAsignatura'}
                        urlOptionsService={`unidadesAsignaturas?IdMalla=${IdMalla}`}
                        value={IdUnidadAsignatura}
                        onChange={onChageAsignatura}
                    />
                    // seleccionar la primer unidad de la asignatura   
                } */}
                {IdUnidadAsignatura&&
                    <div style={{textAlign:'right',marginTop:'20px'}}>
                        <Button
                            onClick={()=>setModalDocente(true)}
                        >
                            Agregar Docente
                        </Button>
                    </div>
                }
                {docentes&&docentes.map((docente,index)=>
                    <div key={index}>
                       <div
                        onClick={()=>eliminarDocente(docente)}
                        style={{float:'right',cursor:'pointer'}}
                       >
                            <BsXCircleFill/>
                        </div> {docente.Nombre}
                    </div>
                    
                )}
                {docentes.length>0&&
                    <div style={{textAlign:'right',marginTop:'20px'}}>
                        {isUpdate?
                            <Button
                                onClick={()=>guardarEdicion()}
                            >
                                Actualizar Evento
                            </Button>
                            :
                            <Button
                                onClick={()=>guardarEvento()}
                            >
                                Guardar Evento
                            </Button>
                        }
                        
                    </div>

                }

            </Modal.Body>
        </Modal>

        <Modal show={modalDocente} onHide={()=>setModalDocente(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Buscar Docente</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                 <SelectDataList
                        label={"Docente"}
                        optionValue={"IdUsuario"}
                        optionName={"Nombre"}
                        // onChangeHandler={setDocente}
                        onInputSelected={setDocente}
                        name={"IdUsuario"}
                        endPoinLastUrl={"docente/full"}
                        value={docente?.Nombre}
                    /> 
                {docente&&
                    <div style={{textAlign:'right',marginTop:'20px'}}>
                        <Button
                            onClick={()=>agregarDocente()}
                        >
                            Agregar
                        </Button>
                    </div>
                }
            </Modal.Body>
        </Modal>
        <Modal show={modalEliminarEvento} onHide={()=>setModalEliminarEvento(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Eliminar Evento</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>¿Está seguro que desea eliminar el evento?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={()=>setModalEliminarEvento(false)}>
                    Cancelar
                </Button>
                <Button variant="danger" onClick={()=>confirmarEliminarEvento()}>
                    Eliminar
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={modalEliminarBloque} onHide={()=>setModalEliminarBloque(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Eliminar Bloque</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>¿Está seguro que desea eliminar el bloque?</p>
                <p>Se eliminara en bloque junto con los eventos asignados</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={()=>setModalEliminarBloque(false)}>
                    Cancelar
                </Button>
                <Button variant="danger" onClick={()=>confirmarEliminarBloque()}>
                    Eliminar
                </Button>
            </Modal.Footer>
        </Modal>

        {/* modal para editar bloque */}
        <Modal show={modalEditarBloque} onHide={()=>setModalEditarBloque(false)}>
            <Form>
            <Modal.Header closeButton>
                <Modal.Title>Actualizar Bloque</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Hora Inicio</Form.Label>
                        <Form.Control
                            type="time"
                            value={horaInicio}
                            onChange={(e)=>setHoraInicio(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Hora Fin</Form.Label>
                        <Form.Control
                            type="time"
                            value={horaFin}
                            onChange={(e)=>setHoraFin(e.target.value)}
                        />
                    </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={()=>setModalEditarBloque(false)}>
                    Cancelar
                </Button>
                <Button variant="primary" onClick={()=>editarBloque()}>
                    Actulizar
                </Button>
            </Modal.Footer>
            </Form>
        </Modal>
    </>
  
  )
}
