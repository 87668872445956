import React from 'react'

import noData from '../../assets/img/no-data.png'

const NoData = ({
    title = 'No hay datos',
    text = 'No se encontraron datos',
}) => {
    return (
        <div className='no-data'>
            <img 
                src={ noData } 
                alt='Icono de no datos' 
                className='no-data__icon'
            />
            <h5 className='no-data__text-main'>
                { title }
            </h5>
            <h6 className='no-data__text-secondary'>
                { text }
            </h6>
        </div>
    )
}

export { NoData }