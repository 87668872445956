import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { Card } from "../../../components/card/Card"
import { Title } from "../../../shared/components/title/Title"
import { Table, NavDropdown, Button, Modal, ModalBody, ModalHeader, ModalFooter, ModalTitle} from "react-bootstrap"
import { BsThreeDotsVertical } from "react-icons/bs"
import { FormService } from "../../../services/api/formService"
import { DynamicForm } from "../../../components/DynamicForm/DynamicForm"
import { setItemSelected, setForm } from "../../../components/DynamicForm/FormSlice"
import { useDispatch, useSelector } from "react-redux"
import { alerta } from "../../../helpers/alertas"

import { BASE_SERVIDOR } from "../../../services/api/config"

const EstudianteOfertaExterna=()=>{

    const api = new FormService()
    const { id } = useParams()
    const urlArchivo = `${BASE_SERVIDOR}/CertificadosActividadesExternas/`
    const [estudiantes, setEstudiantes] = useState()
    const [ofertas, setOfertas] = useState()
    const [modal, setModal] = useState()
    const [modalActualizar, setModalActualizar] = useState()
    const [modalEliminar, setModalEliminar] = useState()
    const dispatch = useDispatch()
    const itemSelected = useSelector((state) => state.form.itemSelected)
    const [IdEstudiante, setIdEstudiante] = useState("");

    useEffect(()=>{

        const  fetchData=async()=>{
            consultarEstudiantes()
            consultarOfertas()
         }
         fetchData();
     },[])
 
    const consultarEstudiantes = async()=>{
        const estudiante = await api.getAll(`estudiantesOfertaExterna?IdOfertaExterna=${id}`)
        setEstudiantes(estudiante);
    }
    
    const consultarOfertas = async()=>{
        const oferta = await api.getAll(`ofertasExternas/consultar?IdOfertaExterna=${id}`)
        setOfertas(oferta);
    }
    
    const crear=()=>{
        setTimeout(() => {
            setModal(false)
            consultarEstudiantes()
        }, 3000);
    }
    const actualizar=()=>{
        setTimeout(() => {
            setModalActualizar(false)
            consultarEstudiantes()
        }, 3000);
    }
    const eliminar=()=>{
        setTimeout(() => {
            setModalActualizar(false)
            consultarEstudiantes()
        }, 3000);
    }

    const delet = async()=>{
        
        const data={
            "Estado": 0
        }
        const respuesta=await api.update("estudiantesOfertaExterna", data, itemSelected.IdEstudianteOferta)
        if(respuesta.respuesta==="exito"){
            eliminar()
            setModalEliminar(false)
            alerta("Estudiante Eliminado")
        }else{
            alerta("Fallo desconocido")
        }
    }

    return(
        <>
        <Card>
            <Title>{ofertas?.NOMBRE_CURSO} - {ofertas?.NOMBRE_ENTIDAD}</Title>
            <div style={{textAlign: "right"}}>
                <Button 
                    onClick={ ()=>{
                        setModal(true)
                        dispatch(setItemSelected(id))
                        dispatch(setForm({llave_primaria:"IdEstudianteOferta",form_data:"1"}))
                    }}
                >Agregar Estudiante</Button>
            </div>
        </Card>
        <Card>
            <Table>
                <thead>
                    <th>N°</th>
                    <th>Documento</th>
                    <th>Estudiante</th>
                    <th>PDF</th>
                    <th>Acción</th> 
                </thead>
                <tbody>
                    {estudiantes &&
                        estudiantes.map((estudiante, key)=>
                            <tr key={key}>  
                                <td>{key+1}</td>
                                <td>{estudiante.NumeroDocumento}</td>
                                <td>{estudiante.Estudiante}</td>
                                <td>
                                    {estudiante.Archivo ?
                                        <a target='_blank' href={`${urlArchivo}${estudiante.Archivo}`}>Ver pdf</a>
                                    :
                                    ""
                                    }
                                </td>
                                <td>
                                <NavDropdown  title={<BsThreeDotsVertical/>} >
                                    <NavDropdown.Item 
                                    onClick={()=>{
                                        setModalActualizar(true)
                                        dispatch(setItemSelected(estudiante))
                                        dispatch(setForm({llave_primaria:"IdEstudianteOferta",form_data:"1"}))
                                    }}
                                    eventKey="4.1">Actualizar</NavDropdown.Item>
                                    <NavDropdown.Item
                                    onClick={()=>{
                                        setModalEliminar(true)
                                        dispatch(setItemSelected(estudiante))
                                        dispatch(setForm({llave_primaria:"IdEstudianteOferta",form_data:"1"}))
                                    }}
                                    eventKey="4.2">Eliminar</NavDropdown.Item> 
                                </NavDropdown>
                                </td>
                            </tr>
                        )
                    }

                </tbody>
            </Table>
        </Card>

        <Modal
            show={modal}
            onHide={() => setModal(false)}
        >
            <DynamicForm
                title={`Agregar Estudiante`}
                endPointSubmit={`estudiantesOfertaExterna`}
                onClickCancelBtn={setModal}
                justDynamicForm={{ flag: true, idForm:"126" }}
                isCreate={true}
                extraData={{IdOfertaExterna: itemSelected}}
                accionMysql={()=>{crear()}}
                isContentTypeFormData={`1`}
            />
        </Modal>
        <Modal
            show={modalActualizar}
            onHide={() => setModalActualizar(false)}
        >
            <DynamicForm
                title={`Actualizar Estudiante`}
                endPointSubmit={`estudiantesOfertaExterna`}
                onClickCancelBtn={setModalActualizar}
                justDynamicForm={{ flag: true, idForm:"126" }}
                isCreate={false}
                extraData={
                    {
                        IdOfertaExterna: itemSelected?.IdOfertaExterna, 
                        IdUsuario: itemSelected?.IdUsuario, 
                        Archivo: itemSelected?.Archivo,
                        Estudiante: itemSelected?.Estudiante,
                        IdEstudianteOferta: itemSelected?.IdEstudianteOferta,
                        IdOfertaExterna: itemSelected?.IdOfertaExterna,
                        NumeroDocumento: itemSelected?.NumeroDocumento
                    }
                }
                accionMysql={()=>{actualizar()}}
                isContentTypeFormData={`1`}
            />
        </Modal>
        <Modal
            show={modalEliminar}
            onHide={() => setModalEliminar(false)}
        >
            <ModalHeader>
                <ModalTitle>
                    Eliminar Estudiante
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                <h6>Se encuentra seguro de eliminar este estudiante ?</h6>
            </ModalBody>
            <ModalFooter>
                <div style={{textAlign: "right"}}>
                    <Button onClick={()=>setModalEliminar(false)} style={{margin: "7px"}} variant="secondary">
                        Cancelar
                    </Button>
                    <Button onClick={()=>{delet()}} variant="danger">
                        Eliminar
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
        </>
    )
}

export {EstudianteOfertaExterna}