import React, { useRef } from 'react'
import { useState } from 'react';
import { Tab, Tabs, Button, Col, Row, Popover, Toast, Overlay, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom"
import { Card } from "../../components/card/Card";
// import { Card } from "../../../../components/card/Card";
import { DynamicTable } from "../../components/DynamicForm/dynamicTable/DynamicTable"
import { DynamicForm } from "../../components/DynamicForm/DynamicForm"
import { LocalStorage } from "../../services"
import { BASE_URL } from "../../services/api/config";
import { Title } from "../../shared/components/title/Title";
import DowloadFileText from '../../components/DowloadFileText/DowloadFileText';
import { BsArrowReturnRight,BsFillFilePdfFill,BsFillFileExcelFill } from 'react-icons/bs';
import { Select } from '../../components/select/Select';

function Alertas () {
  const Navigate=useNavigate()
  const api = new LocalStorage();
  const idUsuario = api.getAsJsonItem("usuario")?.usuario?.IdUsuario || 'localstorage_empty'
  const dimension = api.getAsJsonItem("usuario").dimension || 'localstorage_empty'
  const urlProgramas = `alertas?IdUsuario=${idUsuario}`;
  const [showOverlay, setShowOverlay] = useState(false);
  const urlAlertasAsistencia=`alertas/inasistenciaEstudiante?IdUsuario=${idUsuario}`
  const urlAlertasAsignaturas=`alertas/asignaturasEstudiante?IdUsuario=${idUsuario}`
  const urlAlertasAcademicasUsuario=`alertasEstudianteAcademicaUsuario?IdUsuario=${idUsuario}`
  const urlAlertasPsicologicasUsuario=`alertasEstudiantePsicologicaUsuario?IdUsuario=${idUsuario}`
  const urlAlertasSalud=`alertas/saludEstudiante?IdUsuario=${idUsuario}`
  const exportarAlertasInasistencias=`alertas/saludEstudiante?IdUsuario=${idUsuario}`
  const exportarAlertasAsignaturas=`alertas/asignaturasEstudiante/exportar?IdUsuario=${idUsuario}`
  const [show,setShow]=useState();
  const [modalAcademica, setModalAcademica] = useState(false)
  const [modalPsicologica, setModalPsicologica] = useState(false)
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const [tipoAlerta, setTipoAlerta] = useState()
  const [archivo, setArchivo] = useState()
  const [IdOferta, setIdOferta] = useState()
  const [IdMalla, setIdMalla] = useState()
  const [IdDocente, setIdDocente] = useState()

  const cerrarModalesAcademica=()=>{
        setModalAcademica(false)
    }
  const cerrarModalesPsicologica=()=>{
        setModalPsicologica(false)
    }
  const verMas=(e)=>{
    Navigate("seguimiento_alerta",{state:{alerta:e}})
}
  const targetRef = useRef(null);

  const toggleOverlay = () => {
    setShowOverlay(!showOverlay);
  };
  const handleOptionClick = () => {
    setShowOverlay(false);
  };
  const handleCrearAlertaClick = () => {
    // Aquí puedes agregar la lógica para el onClick original del botón "Crear Alerta"
    console.log('Se hizo clic en el botón "Crear Alerta"');
  };

  return (
    <>
    <Card>
     <Title>Alertas</Title>
    </Card>

    <Tabs
      defaultActiveKey="inasistencias"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="inasistencias" title="Inasistencia">
          <Row>
            <Col style={{textAlign:"right", marginRight:10}}>
              <Button
                  variant='primary'
                  onClick={() => document.querySelector('.Inasistenciaspdf').click()}
              >
                  <DowloadFileText
                      nameFile={"inasistencias.pdf"}
                      name={"Exportar"}
                      colortext={"#ffffff"}
                      icon={<BsFillFilePdfFill />}
                      classname="Inasistenciaspdf"
                      endPoint={`${BASE_URL}/alertas/inasistenciaEstudiante/exportar?IdUsuario=${idUsuario}`}
                  />
              </Button>
            </Col>
          </Row>
          <DynamicTable 
              endPoint={urlAlertasAsistencia}
              titleColumn={["#","Estudiante","Curso","Asignatura","Acciones"]}
              attributes={["Estudiante","Curso","Asignatura"]}
              onDelete={false}
              onUpdate={false}
              handleOptionClick={verMas}
              labelOptionClick='Ver más'
          />
      </Tab>
      <Tab eventKey="asignaturas" title="Asignatura">
          <Row>
            <Col style={{textAlign:"right", marginRight:10}}>
              <Button
                  variant='primary'
                  onClick={() => document.querySelector('.Asignaturapdf').click()}
              >
                  <DowloadFileText
                      nameFile={"inasistencias.pdf"}
                      name={"Exportar"}
                      colortext={"#ffffff"}
                      icon={<BsFillFilePdfFill />}
                      classname="Asignaturapdf"
                      endPoint={`${BASE_URL}/alertas/asignaturasEstudiante/exportar?IdUsuario=${idUsuario}`}
                  />
              </Button>
            </Col>
          </Row>
          <DynamicTable 
              endPoint={urlAlertasAsignaturas}
              titleColumn={["#","Estudiante","Curso","Asignatura","Acciones"]}
              attributes={["Estudiante","Curso","Asignatura"]}
              onDelete={false}
              onUpdate={false}
              handleOptionClick={verMas}
              labelOptionClick='Ver más'
          />
      </Tab>
      <Tab eventKey="misalertas" title="Mis Alertas">
          <>
            <Row>
              <Col style={{textAlign:"right", marginRight:10}}>
                <Button
                  variant="primary"
                  ref={targetRef}
                  onClick={(e) => { toggleOverlay(); handleCrearAlertaClick();
                  }}>
                  Crear Alerta
                </Button>

                <Overlay
                  show={showOverlay}
                  target={targetRef.current}
                  placement="bottom"
                  container={targetRef.current}
                  containerPadding={20}
                  onHide={() => setShowOverlay(false)}
                  rootClose={true}
                >
                  <Popover id="popover-contained">
                    <Popover.Body>
                      <Button
                        onClick={(e) => {
                        handleOptionClick(); setModalAcademica(true)
                        console.log('Se hizo clic en la opción "Académica"');
                      }}
                      >
                          Académica
                      </Button>
                      {' '}
                      <Button
                        onClick={(e) => {
                          handleOptionClick(); setModalPsicologica(true)
                          console.log('Se hizo clic en la opción "Psicológica"');
                        }}
                        >
                          Psicológica
                      </Button>
                    </Popover.Body>
                  </Popover>
                </Overlay>
              </Col>
                <Modal
                    show={modalAcademica}
                    onHide={()=>setModalAcademica(false)}
                >
                  <DynamicForm
                    title={"Crear Alerta Académica"}
                    isCreate={true}
                    isContentTypeFormData={'1'}
                    justDynamicForm={{ flag:true,idForm:'154'}}
                    extraData={{
                      IdUsuario:idUsuario,
                      IdTipo:"6",
                      IdOferta: IdOferta,
                      IdMalla: IdMalla,
                      IdDocente: IdDocente
                    }}
                    endPointSubmit={"alertasEstudiante"}
                    onClickCancelBtn={setModalAcademica}
                    accionMysql={()=>cerrarModalesAcademica()}
                  >
                            <Select
                              label={dimension?"Programa*":"Curso*"}
                              name={"IdOferta"}
                              value={IdOferta}
                              onChange={(e) => setIdOferta(e)}
                              optionValue={"IdOferta"}
                              optionName={"Nombre"}
                              urlOptionsService={`estudiante/cursosActivos?IdUsuario=${idUsuario}`}
                            />
                            {IdOferta &&
                            <Select
                              label={"Asignatura*"}
                              name={"IdMalla"}
                              value={IdMalla}
                              onChange={(e) => setIdMalla(e)}
                              optionValue={"IdMalla"}
                              optionName={"Nombre"}
                              urlOptionsService={`asignaturasEstudiante/consulta?IdOferta=${IdOferta}`}
                            />
                            }
                            {IdMalla &&
                            <Select
                              label={"Docente*"}
                              name={"IdDocente"}
                              value={IdDocente}
                              onChange={(e) => setIdDocente(e)}
                              optionValue={"IdDocente"}
                              optionName={"Nombre"}
                              urlOptionsService={`DocentesEstudiante/consulta?IdMalla=${IdMalla}&IdOferta=${IdOferta}`}
                            />
                            }
                  </DynamicForm>
                </Modal>
                <Modal
                    show={modalPsicologica}
                    onHide={()=>setModalPsicologica(false)}
                >
                  <DynamicForm
                    title={"Crear Alerta Psicológica"}
                    isCreate={true}
                    isContentTypeFormData={'1'}
                    justDynamicForm={{ flag:true,idForm:'162' }}
                    extraData={{
                      IdUsuario:idUsuario,
                      IdTipo:"7",
                      IdOferta: IdOferta,
                      IdMalla: IdMalla,
                      IdDocente: IdDocente

                    }}
                    endPointSubmit={"alertasEstudiante"}
                    onClickCancelBtn={setModalAcademica}
                    accionMysql={()=>cerrarModalesPsicologica()}
                  >
                            <Select
                              label={dimension?"Programa*":"Curso*"}
                              name={"IdOferta"}
                              value={IdOferta}
                              onChange={(e) => setIdOferta(e)}
                              optionValue={"IdOferta"}
                              optionName={"Nombre"}
                              urlOptionsService={`estudiante/cursosActivos?IdUsuario=${idUsuario}`}
                            />
                            {IdOferta &&
                            <Select
                              label={"Asignatura*"}
                              name={"IdMalla"}
                              value={IdMalla}
                              onChange={(e) => setIdMalla(e)}
                              optionValue={"IdMalla"}
                              optionName={"Nombre"}
                              urlOptionsService={`asignaturasEstudiante/consulta?IdOferta=${IdOferta}`}
                            />
                            }
                            {IdMalla &&
                            <Select
                              label={"Docente*"}
                              name={"IdDocente"}
                              value={IdDocente}
                              onChange={(e) => setIdDocente(e)}
                              optionValue={"IdDocente"}
                              optionName={"Nombre"}
                              urlOptionsService={`DocentesEstudiante/consulta?IdMalla=${IdMalla}&IdOferta=${IdOferta}`}
                            />
                            }
                  </DynamicForm>
                </Modal>
            </Row>
            <Tabs
            defaultActiveKey="academicas"
            id="uncontrolled-tab-example"
            className="mb-3"
            >
            <Tab eventKey="academicas" title="Académicas">
                <DynamicTable
                    endPoint={urlAlertasAcademicasUsuario}
                    titleColumn={["#","Motivo","Fecha Creación"]}
                    attributes={["Motivo","FechaCreacion"]}
                    onDelete={false}
                    onUpdate={false}
                    onActions={false}
                />
            </Tab>
            <Tab eventKey="asignaturas" title="Psicológicas">
                <DynamicTable
                    endPoint={urlAlertasPsicologicasUsuario}
                    titleColumn={["#","Motivo","Fecha Creación"]}
                    attributes={["Motivo","FechaCreacion"]}
                    onDelete={false}
                    onUpdate={false}
                    onActions={false}
                />
            </Tab>
          </Tabs>
        </>
      </Tab>
    </Tabs>
    </>
  )
}

export { Alertas }