import React, { useEffect, useState } from 'react'
import { Button, Card, Col, ListGroup, Modal, Row, Table } from 'react-bootstrap'
import { BsArrowLeft } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom';
import { LocalStorage } from '../../services';
import { BASE_SERVIDOR, BASE_URL } from '../../services/api/config';
import { FormService } from '../../services/api/formService';
import {alerta} from "../../helpers/alertas"

export default function MisSolicitudes() {
    const navigate=new useNavigate();
    const storage= new LocalStorage();
    const api=new FormService();
    const urlDocumentos=`${BASE_SERVIDOR}/pdf`
    const usuario= JSON.parse(storage.getItem("usuario"))
    const [solicitudes,setSolicitudes]=useState([]);
    const [solicitud,setSolicitud]=useState();
    const [modalSeguimiento,setModalSeguimiento]=useState();
    const [modalPagar,setModalPagar]=useState();
    const [comprobante,setComprobante]=useState();
    
    const urlInstructivo=`${BASE_URL}/solicitudes/instructivoPagoSolicitud?IdSolicitud=${solicitud?.IdSolicitud}`

   useEffect(()=>{
        (async()=>{
            consultarSolicitudes()
        })()

   },[])

    const consultarSolicitudes=async()=>{
        const respuestas=await api.getAll(`solicitudes/misSolicitudes?IdUsuario=${usuario.usuario.IdUsuario}`)
        setSolicitudes(respuestas)
    }

    const enviarPago=async()=>{
        const data={
            "ComprobantePago":comprobante,
            "IdSolicitud":solicitud.IdSolicitud
        }
        const respuesta=await api.createWithFormData("solicitudes/pagarMiSolicitud",data);
        if(respuesta.respuesta=="exito"){
            alerta(respuesta.mensaje)
            consultarSolicitudes()
            setModalPagar()
            setModalSeguimiento()
        }else{
            alerta("Fallo desconocido")
        }
    }
    
  return (
    <>
         <div style={{textAlign:"left",padding:20}}>
            <Button
                variant='light'
                onClick={()=>navigate("/solicitudes")}
            >
                <BsArrowLeft/> Regresar                 
            </Button>   
        </div>
            <Card.Title style={{textAlign:"center",fontSize:30,marginTop:0}}>
                Mis solicitudes
            </Card.Title>
        <Table>
            <thead>
                <tr>
                    <th>Programa</th>
                    <th>Solicitud</th>
                    <th>Estado</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                {solicitudes.map((solicitud,key)=>
                    <tr key={key}>
                        <td>{solicitud.Nombre}</td>
                        <td>{solicitud.Solicitud}</td>
                        <td>
                        {solicitud.EstadoSolicitud==0&&
                            <>Rechazada</>
                        }
                        {solicitud.EstadoSolicitud==1&&
                            <>Aprobada</>
                        }
                         {solicitud.EstadoSolicitud==3&&
                            <>En revisión</>
                         }
                         {solicitud.EstadoSolicitud==2&&
                            <>
                            {solicitud.EstadoPago ==null&&
                                <>Pago por realizar</>
                            }
                            {solicitud.EstadoPago==0&&
                                <>Validando pago</>
                            }
                            {solicitud.EstadoPago==1&&
                                <>Pago aprobado</>
                            }
                            {solicitud.EstadoPago==2&&
                                <>Pago rechazado</>
                            }
                            </>
                         }
                        </td>
                        <td>
                            <Button
                                onClick={()=>{
                                    setModalSeguimiento(true)
                                    setSolicitud(solicitud)
                                }}
                            >
                                Ver más
                            </Button>
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
        <Modal
            size="lg"
            show={modalSeguimiento}
            onHide={()=>setModalSeguimiento()} 
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Seguimiento de mi solicitud
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <ListGroup variant="flush">
                <ListGroup.Item>
                    <Row>
                        <Col xs={4}><b>Programa:</b></Col><Col>{solicitud?.Nombre}</Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Row>
                        <Col xs={4}><b>Solicitud:</b></Col><Col>{solicitud?.Solicitud}</Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Row>
                        <Col xs={4}><b>Fecha de solicitud:</b></Col><Col>{solicitud?.FechaCreacion}</Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    {solicitud?.DocumentoSolicitud&&
                    <Row>
                        <Col xs={4}><b>Documento Adjuntado:</b></Col>
                        <Col>
                            <a target="_blank" href={`${urlDocumentos}/${solicitud?.DocumentoSolicitud}`}>Ver documento</a>
                        </Col>
                    </Row>
                    }
                </ListGroup.Item>
                <ListGroup.Item>
                <Row>
                    <Col xs={4}><b>Estado:</b></Col>
                    <Col>
                        {solicitud?.EstadoSolicitud==0&&
                            <>Rechazada</>
                        }
                        {solicitud?.EstadoSolicitud==1&&
                            <>Aprobada</>
                        }
                         {solicitud?.EstadoSolicitud==3&&
                            <>En revisión</>
                         }
                         {solicitud?.EstadoSolicitud==2 &&
                            <>
                            {solicitud.EstadoPago ==null&&
                                <>Pago por realizar</>
                            }
                            {solicitud.EstadoPago==0&&
                                <>Validando pago</>
                            }
                            {solicitud.EstadoPago==1&&
                                <>Pago aprobado</>
                            }
                            {solicitud.EstadoPago==2&&
                                <>Pago rechazado</>
                            }
                            </>
                         }
                    </Col>
                </Row>
                </ListGroup.Item>
                {solicitud?.EstadoSolicitud==2&&
                (solicitud.EstadoPago ==null||solicitud.EstadoPago==2)&&
                    <Button
                        onClick={()=>setModalPagar(true)}
                    >
                        Realizar pago
                    </Button>
                }
                {solicitud?.MotivoRechazo&&
                <ListGroup.Item>
                <Row>
                    <Col xs={4}><b>Motivo de rechazo de la solicitud:</b></Col>
                    <Col>
                        {solicitud?.MotivoRechazo}
                    </Col>
                </Row>
                </ListGroup.Item>
                }
                {solicitud?.MotivoPagoRechazo&&
                <ListGroup.Item>
                <Row>
                    <Col xs={4}><b>Motivo  de rechazado del pago:</b></Col>
                    <Col>
                        {solicitud?.MotivoPagoRechazo}
                    </Col>
                </Row>
                </ListGroup.Item>
                }
                {solicitud?.ObservacionesRespuesta&&
                <ListGroup.Item>
                <Row>
                    <Col xs={4}><b>Respuesta:</b></Col>
                    <Col>
                        {solicitud?.ObservacionesRespuesta}
                    </Col>
                </Row>
                </ListGroup.Item>
                }
                {solicitud?.DocumentoRespuesta&&
                <ListGroup.Item>
                <Row>
                    <Col xs={4}><b>Documento de respuesta:</b></Col>
                    <Col>
                        <a target="_blank" href={`${urlDocumentos}/${solicitud?.DocumentoSolicitud}`}>Ver documento</a>
                    </Col>
                </Row>
                </ListGroup.Item>
                }
                
            </ListGroup>
            
            </Modal.Body>
        </Modal>
        <Modal 
            show={modalPagar}
            onHide={()=>setModalPagar()}
        >
            <Modal.Header closeButton>
                <Modal.Title>Realizar el pago</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                    <a target="_blank" href={urlInstructivo}>
                    <div>Descargar instrutivo de pago</div>
                    </a>
                    
                    <label style={{marginTop:30}}>Adjuntar comprobante</label>
                    <input
                        type="file"
                        // value={comprobante}
                        onChange={(e) => setComprobante(e.target.files[0])}
                    />
            </Modal.Body>
             
            <Modal.Footer>
                <Button
                variant="secondary"
                onClick={()=>setModalPagar()}
                >
                    Cancelar
                </Button>
                <Button
                onClick={()=>enviarPago()}
                >
                    Aceptar
                </Button>
            </Modal.Footer>
        </Modal>
    </>
  )
}
