import { DynamicFormContainer } from '../../../../components/DynamicForm/DynamicFormContainer'
import { ExportList } from '../../../../components/ExportList/ExportList'
import { BASE_URL } from '../../../../services/api/config'

function UsuarioDocentes() {
    return (
        <>
            <DynamicFormContainer idForm={'60'}>
                <div className="d-flex justify-content-end">
                    <ExportList
                        title={'Exportar listado Docentes'}
                        link={`${BASE_URL}/exportar/docentes`}
                    ></ExportList>
                </div>
            </DynamicFormContainer>
        </>
    )
}

export { UsuarioDocentes }
