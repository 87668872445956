import React from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'

import { AiOutlineFileSearch } from 'react-icons/ai'
import DowloadFile from '../../components/DowloadFile/DowloadFile'
import { BASE_URL } from '../../services/api/config'
import { useState } from 'react'
import { useEffect } from 'react'

export default function EstadoDocumento() {
    const {rowData}=useLocation().state
    const [url, setUrl] = useState('')

    useEffect(() => {
        setUrl(`${BASE_URL}/${rowData.Enlace}?IdOferta=${rowData.IdOferta}`)
        if(rowData.Fraccionado){
            if(rowData.TipoFraccionado=="IdEstudiante"){
                setUrl(`${BASE_URL}/${rowData.Enlace}?IdOferta=${rowData.IdOferta}&IdEstudiante=${rowData.IdEstudiante}`)
            }else{
                setUrl(`${BASE_URL}/${rowData.Enlace}?IdOferta=${rowData.IdOferta}&IdMalla=${rowData.IdMalla}`)
            }
        }
    })
  return (
    <>
        <Card>
            <Row>
                <Col>
                    <Card.Title>{rowData.NombreDocumento}</Card.Title>
                    <p>{rowData.Curso}</p>
                </Col>
                <Col>
                    
                </Col>
            </Row>
        </Card>
        <Row>
            <Col sm={4}>
                <Card>
                    <Card.Title>Documento</Card.Title>
                    <div
                        style={{
                            textAlign: 'center',
                            marginTop: '20px',
                            color: 'blue',
                        }}
                    >
                        <AiOutlineFileSearch
                            size={100}
                        />
                        <div> Ver Documento </div>
                        <DowloadFile
                            endPoint={url}
                            nameFile={`${rowData.NombreDocumento}.pdf`}
                            name={rowData.NombreDocumento}
                        />
                    </div>
                </Card>
            </Col>
            <Col>
                <Card>
                    <Card.Title>Firmas</Card.Title>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Usuario</th>
                                <th>Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                        {rowData?.firmas.map((firma)=>{
                            return(
                                <tr>
                                    <td>{firma?.usuario?.PrimerApellidos} {firma?.usuario?.SegundoApellidos} {firma?.usuario?.PrimerNombres} {firma?.usuario?.SegundoNombre}</td>
                                    <td>{firma?.EstadoFirma==1
                                        ?<span style={{color:'green'}}>Firmado</span>
                                        :<span style={{color:'red'}} >Sin Firmar</span>
                                    }</td>                                        
                                </tr>                           
                            )
                        })}
                        </tbody>
                    </Table>
                </Card>
            </Col>
        </Row>
    </>
  )
}
