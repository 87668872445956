
import { useNavigate } from "react-router-dom"
import { Card } from "../../components/card/Card"
import { DynamicTable } from "../../components/DynamicForm/dynamicTable/DynamicTable"

const AlertasSocioeconomicas=()=>{

    const Navigate=useNavigate()

    const verMas=(e)=>{
        Navigate("seguimiento_alerta",{state:{alerta:e}})
    }

    return(
        <Card>
        <h2>Alertas socioeconómicas</h2>
            <DynamicTable 
                endPoint={'alertas/socioeconomica'}
                titleColumn={["#","Usuario","Motivo","Acciones"]}
                attributes={["Estudiante","Motivo"]}
                onDelete={false}
                onUpdate={false}
                labelOptionClick='Ver más'
                handleOptionClick={verMas}
            />
        </Card>
    )
}

export {AlertasSocioeconomicas}