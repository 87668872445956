import { Route, Routes } from "react-router-dom"
import { DynamicFormContainer } from "../../components/DynamicForm/DynamicFormContainer"
import { EstudianteAsistencias } from "../componentesC/EstudianteAsistencias/EstudianteAsistencias"
import { EstudianteHistorial } from "../componentesC/estudianteHistorial/EstudianteHistorial"
import { PerfilEstudiante } from "../componentesC/estudianteHistorial/PerfilEstudiante"
import { EstudianteNotas } from "../componentesC/EstudianteNotas/EstudianteNotas"


const GestionEgresados=()=>{

    return(
        <Routes>
            <Route  path="ofertas_de_empleo" element={<DynamicFormContainer idForm={63} />}/>
            <Route path="historial_estudiantes" element={<EstudianteHistorial/>}  />
            <Route path="historial_estudiantes/perfil_estudiante" element={<PerfilEstudiante/>}  />
            <Route path="historial_de_estudiante/perfil_estudiante/asignaturas" element={<EstudianteNotas/>}/>
            <Route path="historial_de_estudiante/perfil_estudiante/asignaturas/:id/asistencia" element = {<EstudianteAsistencias/>}></Route> 
        </Routes>
    )
}


export {GestionEgresados}