import React from 'react'
import { Input } from '../../input/input'
import { Badge } from '../../Badge/Badge'
import { NoData } from '../../NoData/NoData'
import { Message } from '../../Message/Message'
import { AddUserAs } from '../../AddUserAs/AddUserAs'
import { ListByCourse } from '../../ListByCourse/ListByCourse'
import { ListContainer } from '../../ListContainer/ListContainer'

import './ApplicantList.css'
import { useState } from 'react'
import { BASE_URL } from '../../../services/api/config'
import { Col, Modal, Row,Button, ListGroup } from 'react-bootstrap'
import { BsFillSdCardFill } from 'react-icons/bs'
import { Select } from '../../select/Select'
import { FormService } from '../../../services/api/formService'
import { alerta } from '../../../helpers/alertas'
import DowloadFile from '../../DowloadFile/DowloadFile'
import ModalFormatosAdmision from '../../../modules/componentesC/Formatos/ModalFormatosAdmision'

const ApplicantList = ({
    empresa,
    offer,
    selectList,
    optionHandlingData,
    inputSearch,
    onSearch,
    loadingFull,
    loading,
    OPCION_INSCRIPCIONES,
    error,
    setError,
    isOpenModalAddApplicant,
    handleOpenModalAddApplicant,
    toggled,
    checked,
    isOpenModalNextStop,
    handleOpenModalNextStop,
    searchResult,
    handleChangeCheckbox,
    switchSelect,
    numberIsSelected,
    goToTheNextStop,
    fetchApplicants,
    fasesLista,
    URL_EXPORT
}) => {

    const api=new FormService();
    const [modalDescargar,setModalDescargar]=useState(false)
    const [modalReservas,setModalReservas]=useState();
    const [numeroDocumento,setNumeroDocumento]=useState(null);
    const [reservas,setReservas]=useState(null);
    const [IdAspirante,setIdAspirante]=useState();

    const urlReservas=`educacionIES/consultarReservas?IdCurso=${offer.IdCurso}`

    const buscarReservas=async()=>{
       if(numeroDocumento==null){
            alerta("Escribe el número del documento")
            return
       }      
       const reservas=await api.getAll(`${urlReservas}&NumeroDocumento=${numeroDocumento}`)
       if(reservas.length>0){
            setReservas(reservas)
       }else{
            alerta("No se econtraron reservas")
            setReservas(null)
            setIdAspirante(null)
       }
    }

    
    const usarReserva=async()=>{
        const data={
            "IdAspirante":IdAspirante,
            "IdOferta":offer.IdOferta
        }
        const respuesta=await api.create("educacionIES/usarReserva",data);
        if(respuesta.respuesta=="exito"){
            alerta(respuesta.mensaje)
            fetchApplicants();

            setModalReservas();
        }else{
            alerta("Fallo desconocido")
        }
    }


    return (
        <>
            <ListByCourse
                empresa={empresa}
                offer={ offer }
                convert={ true }
            >
                <>
                    <section className='applicant__navbar'>
                        <nav className='nav-applicant-list'>
                            <ul className='nav-applicant-list__ul'>
                                {
                                    (offer.requiere_inscripcion == '1' || offer.requiere_inscripcion == 'true') && (
                                        <>
                                            <li className='nav-applicant-list__item'>
                                                <div 
                                                    className={
                                                        optionHandlingData.search == 'pre-inscripto'
                                                        ? `nav-tag__nav-window-item nav-tag__nav-window-item--active`
                                                        : `nav-tag__nav-window-item`
                                                    }
                                                    onClick={ 
                                                        () => selectList(OPCION_INSCRIPCIONES.PRE_INSCRITOS) 
                                                    }
                                                >
                                                    Listado de Aspirantes
                                                </div>
                                            </li>
                                            <li className='nav-applicant-list__item'>
                                                <div 
                                                    className={
                                                        optionHandlingData.search == 'inscripto'
                                                        ? `nav-tag__nav-window-item nav-tag__nav-window-item--active`
                                                        : `nav-tag__nav-window-item`
                                                    }
                                                    onClick={ 
                                                        () => selectList(OPCION_INSCRIPCIONES.INSCRITOS)
                                                    }
                                                >
                                                    Listado de Inscritos
                                                </div>
                                            </li>
                                        </>
                                    )
                                }
                                <li className='nav-applicant-list__item'>
                                    <div 
                                        className={
                                            optionHandlingData.search == 'admitido'
                                            ? `nav-tag__nav-window-item nav-tag__nav-window-item--active`
                                            : `nav-tag__nav-window-item`
                                        }
                                        onClick={ () => selectList(OPCION_INSCRIPCIONES.ADMITIDOS) }
                                    >
                                        Listado de Admitidos
                                    </div>
                                </li>
                                {offer.modo_full == 1 && (
                                        <li className='nav-applicant-list__item'>
                                            <div 
                                                className={
                                                    optionHandlingData.search == 'estudiante'
                                                    ? `nav-tag__nav-window-item nav-tag__nav-window-item--active`
                                                    : `nav-tag__nav-window-item`
                                                }
                                                onClick={ 
                                                    () => selectList(OPCION_INSCRIPCIONES.ESTUDIANTES)
                                                }
                                            >
                                                Listado de Estudiante
                                            </div>
                                        </li>
                                    )
                                }
                            </ul>
                        </nav>
                        { offer.modo_full == 1 && 
                            <>
                                <Button onClick={()=>setModalDescargar(true)}>
                                    <i class="bi bi-file-arrow-down"></i>
                                    Exportar
                                </Button>
                                <ModalFormatosAdmision
                                    modalDescargar={modalDescargar}
                                    setModalDescargar={setModalDescargar}
                                    IdOferta={offer.IdOferta}
                                    fasesLista={fasesLista}
                                    oferta={offer}
                                />
                            </>
                        }
                    </section>
                    <Input 
                        type='search'
                        placeholder={
                            `Busca por nombre o número de documento del ${optionHandlingData.search}`
                        }
                        value={ inputSearch }
                        onChange={ onSearch }
                    />
                    <div className='applicant-list__manipulate-list'>
                        {offer.modo_full ==1&&
                        <>
                            {(searchResult.length > 0 && optionHandlingData.search != 'estudiante') && 
                                    <Button
                                    variant="outline-dark"
                                        onClick={ toggled }
                                    >
                                        { switchSelect ? 'Seleccionar todos' : 'Deseleccionar todos' }
                                    </Button>
            
                            }
                        </>
                        }
                        <div className='applicant-list__button-add-applicant'>
                            <div>
                                {offer.modo_full ==1&&
                                <>
                                    {(numberIsSelected>=2&& optionHandlingData.assignedButtonName)&& 
                                            <Button 
                                                variant='outline-primary'
                                                onClick={handleOpenModalNextStop}
                                            >
                                                {optionHandlingData.assignedButtonName }
                                            </Button>
                                    }
                                </>
                                }
                                {
                                    (optionHandlingData.search == 'pre-inscrito' || (
                                    optionHandlingData.search == 'admitido' &&
                                    offer.modo_full == 1)) && 
                                        <Button
                                            variant='outline-primary'
                                            onClick={ handleOpenModalAddApplicant }  
                                        >
                                            <>  
                                                <i className="bi bi-person-plus-fill m-1"></i>
                                                <span>
                                                    {`Agregar ${
                                                        optionHandlingData.search == 'pre-inscrito'
                                                        ? 'aspirante'
                                                        : 'admitido'
                                                    }`}
                                                </span>
                                            </>
                                        </Button>
                                }
                                {/* {optionHandlingData.search == 'admitido' && offer.modo_full == 1&&
                                    <Button
                                        variant='outline-primary'
                                        onClick={()=>setModalReservas(true)}
                                    >
                                        <BsFillSdCardFill/>
                                        Usar Reserva
                                    </Button>
                                } */}
                            </div>
                        </div>
                    </div>

                    {
                        error && (
                            <Message type='error'>
                                { error.message }
                            </Message>
                        )
                    }   
                     <ListContainer loading={ loadingFull }>
                        {
                            searchResult.length > 0 
                            ? !!searchResult && searchResult.map(applicant => (
                                <Badge 
                                    to={ `estado-aspirante/${applicant?.IdAspirante}` }
                                    state={{ offer, applicant }}
                                    img={ applicant?.Foto }
                                    alt='Foto de perfil'
                                    user={ applicant }
                                    key={ applicant.IdUsuario }
                                    tag={ applicant.IdAspirante }
                                    value={ checked[applicant.IdAspirante] }
                                    isCheck={ optionHandlingData.search == 'estudiante' ? false : true }
                                    handleChangeCheckbox={ handleChangeCheckbox }
                                />
                            ))
                            
                            : <NoData
                                title='No se encontraron registros'
                                text='Selecciona otro listado para seguir explorando'
                            />                           
                        }                         
                    </ListContainer>
                    <div style={{textAlign:"right"}}>Total: {searchResult.length}</div>
                </>
            </ListByCourse>

            <Modal 
                show={ isOpenModalAddApplicant }
                onHide={ handleOpenModalAddApplicant }
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                    Agregar Usuario
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>Buscar un usuario por número de documento</b>
                <AddUserAs 
                    SearchEndpoint={ 'buscarUsuario?numero=' }
                    setError={ setError }
                    idCourse={ offer?.IdOferta }
                    isApplicant={ true }
                    isAdmitted={ optionHandlingData.search == 'admitido' ? true : false }
                    fetchData={ fetchApplicants }
                    handleOpenModalAddApplicant={handleOpenModalAddApplicant}
                    empresa={empresa}
                />

                </Modal.Body>
               
            </Modal>
            <Modal
                show={ isOpenModalNextStop }
                onHide={ handleOpenModalNextStop }               
            >
                <Modal.Body>
                <b>
                    ¿Esta seguro que desea {' '}
                    {optionHandlingData.search=="pre-inscrito" &&
                        <> inscribir a</>    
                    } 
                    {optionHandlingData.search=="inscrito"&&
                        <> admitir a</>    
                    }
                    {optionHandlingData.search=="admitido"&&
                        <> matricular a</>    
                    }
                    {' '}
                    {numberIsSelected}
                    {' '}
                    {optionHandlingData.search=="pre-inscrito"&&
                        <> pre-inscritos</>    
                    } 
                    {optionHandlingData.search=="inscrito"&&
                        <> inscrito</>    
                    }
                    {optionHandlingData.search=="admitido"&&
                        <> amitidos</>    
                    }
                    ?
                </b>
                                    
                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        variant='danger'
                        onClick={ handleOpenModalNextStop }
                    >
                        Cancelar
                    </Button>
                    <Button 
                        onClick={ goToTheNextStop }
                    >
                        Pasar
                    </Button>
                </Modal.Footer>
                
            </Modal>

    
            {/* modal para ver y usar reservas */}

            <Modal 
                show={modalReservas}
                onHide={()=>setModalReservas()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Usar Reserva
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>Buscar reservas de usuarios por número de documento</b>
                    <Input 
                        label="Numero de Documento"
                        value={numeroDocumento}
                        onChange={(e)=>setNumeroDocumento(e)}
                    />
                    <div style={{textAlign:"center"}}>
                        <Button
                            onClick={buscarReservas}
                        >
                            Buscar Reservas
                        </Button>
                    </div>
                    
                    {reservas&&
                        <Select 
                            label="Reservas Encontradas"
                            optionName={"Reserva"}
                            optionValue={"IdAspirante"}
                            options={reservas}
                            value={IdAspirante}
                            onChange={(e)=>setIdAspirante(e)}
                        />

                    }

                </Modal.Body>
                {IdAspirante&&
                    <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={()=>setModalReservas()}
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={()=>usarReserva()}
                    >
                        Aceptar
                    </Button>
                </Modal.Footer>
                }
                
            </Modal>
        </>
    )
}

export { ApplicantList }