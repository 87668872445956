import React from 'react'
import { Card } from 'react-bootstrap'
import { DynamicTable } from '../../components/DynamicForm/dynamicTable/DynamicTable'
import { useNavigate } from 'react-router-dom'

export default function IncripcionesGrados() {
    const navigate = useNavigate()
  return (
    <>
        <Card>
            <Card.Title> Inscripciones a grados </Card.Title>
        </Card>
        {/* listar las convocatorias a grados */}
        <DynamicTable
            endPoint={'convocatoriaGrados'}
            titleColumn={['N°','Nombre de la convocatoría','Acciones']}
            attributes={['Nombre']}
            onDelete={false}
            onUpdate={false}
            labelOptionClick={'Ver inscritos'}
            handleOptionClick={(e)=>{navigate(`${e.IdConvocatoriaGrado}/inscritos`)}}        
        />
    </>
  )
}
