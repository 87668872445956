import { useNavigate } from 'react-router-dom'
import { DynamicTable } from '../../../../components/DynamicForm/dynamicTable/DynamicTable'
import { SubTitle } from '../../../../shared/components/subtitle/SubTitle'
import { Title } from '../../../../shared/components/title/Title'

function CalendarioAulasContainer() {
    const navigate = useNavigate()

    const onNavigateDetails = (item) => {
        console.log({ item })
        navigate(`${item.numero}/calendario`, { state: { item } }) //to Calendar.jsx
    }

    return (
        <>
            <Title>Calendario Académico</Title>
            <SubTitle>Listado de aulas</SubTitle>
            <DynamicTable
                titleColumn={['N°', 'Aula', 'Edificio', 'Capacidad', 'Acción']}
                attributes={['numero', 'Edificio', 'capacidad']}
                endPoint={'aulas'}
                onDelete={false}
                onUpdate={false}
                labelOptionClick={'Ver Calendario'}
                classNameOptionIcon={'bi bi-calendar-event'}
                handleOptionClick={onNavigateDetails}
            ></DynamicTable>
        </>
    )
}

export { CalendarioAulasContainer }
