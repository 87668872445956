import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { FormService } from '../../services/api/formService'
import { alerta } from '../../helpers/alertas'
import { useFetchGet } from '../../hooks/useFetchGet'
import FormsAR from './FormsAR'
import GeneradoContenido from './GeneradoContenido'

export default function ConfigurarCurso() {
  const api=new FormService()
  const curso=useLocation().state.curso
  const [modalSeccion, setModalSeccion] = useState(false)
  const [dataSeccion, setDataSeccion] = useState({})
  const [secciones, setSecciones] = useState([])
  const [seccionActual, setSeccionActual] = useState({})
  const [modalAR, setModalAR] = useState(false)
  const [dataAR, setDataAR] = useState({})
  const[tiposAR]=useFetchGet("actividadesCursosLMS/tipos")
  // consultar secciones
  const consultarSecciones=async()=>{
    const response=await api.getAll(`seccionesCursosLMS?IdCurso=${curso.id}`)
    setSecciones(response)
  }

  useEffect(()=>{
    consultarSecciones()
  },[])


  const crearSeccion=async()=>{
    const data={
      ...dataSeccion,
      id_curso:curso.id
    }
    const response=await api.create("seccionesCursosLMS",data)
    if(response.success){
      setModalSeccion(false)
      consultarSecciones()
    }else{
      alerta("No se pudo crear la seccion","error")
    }
  }

  const crearAR=async()=>{
    const data={
      ...dataAR,
      id_tipo:dataAR.tipo.id,
      id_seccion:seccionActual.id
    }

    const response=await api.createWithFormData("recursoActividadLMS",data)
      if(response.success){
        setModalAR(false)
        setDataAR({})
        consultarSecciones()
      }else{
        alerta("No se pudo crear la actividad","error")
      }
  }

  return (
    <>
        <Card>
          <Row>
            <Col>
              <Card.Body>
                  <Card.Title>Curso: {curso?.nombre}</Card.Title>
              </Card.Body>
            </Col>
            <Col
              style={{textAlign:'right'}}
            >
              <Button
                onClick={() => setModalSeccion(true)}
              >
                  Crear Sección
              </Button>
            </Col>
          </Row>
        </Card>
        {secciones.map((seccion,i)=>{
          return(
           <GeneradoContenido
              seccion={seccion}
              setModalAR={setModalAR}
              setSeccionActual={setSeccionActual}
              modalAR={modalAR}
              setDataAR={setDataAR}
           />
          )
        })
        }

      
      <Modal
        show={modalSeccion}
        onHide={() => setModalSeccion(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Crear Sección</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setDataSeccion({ ...dataSeccion, nombre: e.target.value })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                as={"textarea"}
                onChange={(e) => setDataSeccion({ ...dataSeccion, descripcion: e.target.value })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalSeccion(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={() => crearSeccion()}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* moda para crear una activdiad o recurso */}
      <Modal
        show={modalAR}
        onHide={() => setModalAR(false)}
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title>Crear Actividad o Recurso</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px',alignItems:'center' }} >
                <h4>Recursos</h4>
                {tiposAR.filter(tipo=>tipo.tipo=="Recurso").map((tipo,i)=>{
                  return(
                    <Form.Label key={i}
                      style={{ 
                        border: '1px solid #cccc', 
                        padding: '5px', 
                        cursor: 'pointer',
                        textAlign: 'center',
                        borderRadius: '5px',
                       }}
                    >{tipo.nombre}
                      <Form.Check
                        key={i}
                        type="radio"
                        // checked={dataAR?.tipo.id==tipo?.id}
                        name="tipo"
                        value={tipo.id}
                        onChange={(e) => setDataAR({ ...dataAR, tipo: tiposAR.filter(tipo=>tipo.id==e.target.value)[0] })}
                      />
                    </Form.Label>
                  )
                })}
              </div>
              <div
                style={{ display: 'flex', flexWrap: 'wrap', gap: '10px',alignItems:'center' }}
              >
                <h4>Actividades</h4>
                {tiposAR.filter(tipo=>tipo.tipo=="Actividad").map((tipo,i)=>{
                  return(
                    <Form.Label key={i}
                      style={{ 
                        border: '1px solid #cccc', 
                        padding: '5px', 
                        cursor: 'pointer',
                        textAlign: 'center',
                        borderRadius: '5px',
                       }}
                    >{tipo.nombre}
                      <Form.Check
                        key={i}
                        type="radio"
                        name="tipo"
                        value={tipo.id}
                        // checked={dataAR?.tipo.id==tipo?.id}
                        onChange={(e) => setDataAR({ ...dataAR, tipo: tiposAR.filter(tipo=>tipo.id==e.target.value)[0] })}
                      />
                    </Form.Label>
                  )
                })}
              </div>
              <hr/>
              {dataAR?.tipo &&
                  <FormsAR
                    dataAR={dataAR}
                    setDataAR={setDataAR}
                    tipo={dataAR?.tipo}
                />
              }              
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <>
              <Button variant="secondary" onClick={() => setModalAR(false)}>
                Cancelar
              </Button>
              <Button variant="primary" onClick={() => crearAR()}>
                Guardar
              </Button>
           </>
        </Modal.Footer>
      </Modal>
    </>
  )
}
