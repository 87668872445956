import React, { useRef } from 'react'
import { useState } from 'react';
import { Tab, Tabs, Button, Col, Row, Popover, Toast, Overlay, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom"
import { Card } from "../../components/card/Card";
import { DynamicTable } from "../../components/DynamicForm/dynamicTable/DynamicTable"
import { DynamicFormContainer } from "../../components/DynamicForm/DynamicFormContainer"
import { DynamicForm } from "../../components/DynamicForm/DynamicForm"
import { LocalStorage } from "../../services"
import { BASE_URL } from "../../services/api/config";
import { Title } from "../../shared/components/title/Title";
import { Input } from "../../components/input/input"

const AlertasEstudiantes=({full=false})=>{
  const Navigate=useNavigate()
  const urlExportar = `${BASE_URL}/exportarAlertasSocioeconomica`
  // const [endPoint, setEndPoint] = useState(`alertasEstudianteTodoAcademicas?full=${full}`)
  const endPointAcademica = `alertasEstudianteTodoAcademicas?full=${full}`
  const endPointPsicologica = `alertasEstudianteTodoPsicologicas?full=${full}`
  const [fechaI, setFechaI] = useState()
  const [fechaII, setFechaII] = useState()
  const api = new LocalStorage();
  const idUsuario = api.getAsJsonItem("usuario")?.usuario?.IdUsuario || 'localstorage_empty'
  const exportarAlertasAsignaturas=`alertas/asignaturasEstudiante/exportar?IdUsuario=${idUsuario}`
  const verMas=(e)=>{
        Navigate("seguimiento_alerta",{state:{alerta:e}})
    }
  // const onSearch = () => {
  //       setEndPoint(endPoint+`&FechaUno=${fechaI}&FechaDos=${fechaII}`)
  //   }
  return (
    <>
    <Card>
            <h2>Alertas Estudiantes</h2>
        </Card>
    <Tabs
      defaultActiveKey="academicas"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="academicas" title="Alertas Académicas">
          <DynamicTable
                endPoint={endPointAcademica}
                titleColumn={["#","Estudiante","Motivo","Fecha Creación","Acciones"]}
                attributes={["Estudiante","Motivo","FechaCreacion"]}
                onDelete={false}
                onUpdate={false}
                labelOptionClick='Ver más'
                handleOptionClick={verMas}
            />
      </Tab>
      <Tab eventKey="psicologicas" title="Alertas Psicologicas">
          <DynamicTable
                endPoint={endPointPsicologica}
                titleColumn={["#","Estudiante","Motivo","Fecha Creación","Acciones"]}
                attributes={["Estudiante","Motivo","FechaCreacion"]}
                onDelete={false}
                onUpdate={false}
                labelOptionClick='Ver más'
                handleOptionClick={verMas}
            />
      </Tab>
    </Tabs>
    </>
  )
}

export { AlertasEstudiantes }