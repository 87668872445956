import React, { useEffect, useState } from 'react'

import { RolUsuario } from './RolUsuario'
import { useForm } from '../../../hooks/useForm'

const RolUsuarioContainer = ({ dependence }) => {

    const {
        onCreate,
        onDelete,
        onUpdate,
        allData,
        data,
        isOpenForm,
        isOpenDelete,
        isCreate,
        handleSubmit,
        handleChange,
        handleClickDelete,
        handleClickAdd,
        deleteTuple,
        loading,
    } = useForm(
        'rolUsuario',
        'idRolUsuario',
        `dimension?IdModulo=${dependence.IdModulo}`,
        {
            IdModulo: dependence.IdModulo,
        }
    )

    const title = [
        'ID',
        'ROL',
        'Estado',
        'Acciones'
    ]

    const attributes = [
        'nombre',
        'Descripcion',
        'path',
    ]

    return (
        <>
            <h2>{ dependence.Descripcion }</h2>
            <RolUsuario
                title={ title }
                ventanas={ allData }
                ventana={ data }
                attributes={ attributes }
                onCreate={ onCreate }
                onDelete={ onDelete }
                onUpdate={ onUpdate }
                isOpenForm={ isOpenForm }
                isOpenDelete={ isOpenDelete }
                isCreate={ isCreate }
                handleSubmit={ handleSubmit }
                handleChange={ handleChange }
                handleClickAdd={ handleClickAdd }
                handleClickDelete={ handleClickDelete }
                loading={ loading }
                deleteTuple={ deleteTuple }
            />
        </>
    )
}

export { RolUsuarioContainer }