import React, { useEffect, useState } from 'react'
import { Button, Card, Table } from 'react-bootstrap'
import { FormService } from '../../../services/api/formService'
import { useLocation, useNavigate } from 'react-router-dom'
import { alerta } from '../../../helpers/alertas'

export default function PeriodosOfertados() {
    // recibir parametros por useLocation
    const {IdCurso}=useLocation().state
    const usuario=JSON.parse(localStorage.getItem("usuario")).usuario
    const api= new FormService()
    const [periodos,setPeriodos]=useState([])
    const [loading,setLoading]=useState(true)

    const navigate=useNavigate()

    useEffect(()=>{
        getPeriodos()
    },[])

    const getPeriodos=async()=>{
        const periodos=await api.getAll(`educacionIES/periodosOfertados?IdCurso=${IdCurso}&IdUsuario=${usuario.IdUsuario}`)
        setPeriodos(periodos)
        setLoading(false)
    }

    const matricularPeriodo=async(IdOferta)=>{
        // matricular el periodo
        const data={
            IdOferta:IdOferta,
            IdUsuario:usuario.IdUsuario
        }
        const res=await api.create(`educacionIES/matricularPeriodo`,data)
        if(res.respuesta=="exito"){
            getPeriodos()
            alerta(res.mensaje,"success")
        }else{
            alerta(res.mensaje,"error")
        }
    }


  return (
    <>
        <Card>
            <Card.Title>Periodos Ofertados</Card.Title>
        </Card>
        {/* crear una tabla para mostrar la fecha incial y al fecha final del perido y un boton para matricular */}
        <Card>
            <Card.Body>
                <Table>
                    <thead>
                        <tr>
                            <th>Periodo</th>
                            <th>Fecha Inicial</th>
                            <th>Fecha Final</th>
                            <th>Matricular</th>
                        </tr>
                    </thead>
                    <tbody>
                        {periodos.map((periodo,index)=>(
                            <tr key={index}>
                                <td>{periodo.codigo_grupo}</td>
                                <td>{periodo.FechaInicioCurso}</td>
                                <td>{periodo.FechaFinCurso}</td>
                                <td>
                                    {!periodo.aspirante
                                        ?
                                        <Button
                                            onClick={()=>{
                                                matricularPeriodo(periodo.IdOferta)
                                            }}
                                        >
                                            Matricular Período
                                        </Button>
                                        :
                                        // asignaturas
                                        <Button
                                            onClick={()=>{
                                                navigate("asignaturas",{state:{oferta:periodo}})
                                            }}
                                        >
                                            Matrícula Antiguos
                                        </Button>

                                    }
                                    
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>

    </>
  )
}
