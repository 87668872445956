import React from 'react';
import { CanvasJSChart } from 'canvasjs-react-charts';
import { Card } from 'react-bootstrap';

const EmbudoConversion = ({data}) => {
  var dataPoint;
  var total;
  console.log(data);
  const options = {
    // theme: "dark2",
    animationEnabled: true,
    title:{
      // text: "Matriculados"
    },
    data: [{
      type: "funnel",
      indexLabel: "{label} - {y}",
      toolTipContent: "<b>{label}</b>: {y} <b>({percentage}%)</b>",
      neckWidth: 20,
      neckHeight: 0,
      valueRepresents: "area",
      dataPoints: data
      // [
      //   { y: 265, label: "Aspirantes" },
      //   { y: 134, label: "Admitidos aprueba piscologica " },
      //   { y: 100, label: "Admitidos a examen medico" },
      //   { y: 48, label: "Admitidos" },
      //   { y: 26, label: "Matriculados" }
      // ]
    }]
  }
  return (
    <Card>
      <Card.Title>
        Embudo de conversion
      </Card.Title>
      <CanvasJSChart options={options} />
    </Card>
  );
};

export  default EmbudoConversion;
