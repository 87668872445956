import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { useLocation } from 'react-router';
import { FormService } from '../../../services/api/formService';
import TablaCreditos from './TablaCreditos';
import EmbudoConversion from './EmbudoConversion';
import GraficoBarras from './GraficoBarras';
import TablaMetricas from './TablaMetricas';
import { Select } from '../../../components/select/Select';

export default function Graficas() {
    const api= new FormService();
    const [inasistenciasDocentes, setInasistenciasDocentes]= useState([]);
    const [inasistenciasEstudiantes, setInasistenciasEstudiantes]= useState([]);
    const [embudoConversion, setEmbudoConversion]= useState([]);
    const [promedioEstudiantes, setPromedioEstudiantes]= useState([]);
    const [metricasGenerales, setMetricasGenerales]= useState([]);

    const fechaActual = new Date();

    const [fechaInicio, setFechaInicio]= useState(new Date(fechaActual.getFullYear(), 0, 1).toISOString().split('T')[0]);
    const [fechaFin, setFechaFin]= useState(new Date(fechaActual.getFullYear(), 11, 31).toISOString().split('T')[0]);
    const [IdTipoIngreso, setIdTipoIngreso]= useState('');

    const [dataTable, setDataTable]= useState(null);

  const getDataTable= async () => {
    const data= await api.getAll(`graficas/creditosEstudiantes?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&IdTipoIngreso=${IdTipoIngreso}`)
    setDataTable(data);
  }
  const getMetricasGenerales= async () => {
    const data= await api.getAll(`analitica/metricasGenerales?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&IdTipoIngreso=${IdTipoIngreso}`)
    setMetricasGenerales(data);
}


  const getData= async () => {
    const data= await api.getAll(`analitica/ofertas?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&IdTipoIngreso=${IdTipoIngreso}`)
    console.log(data);
    setInasistenciasDocentes(data.inasistenciasDocentes);
    setInasistenciasEstudiantes(data.inasistenciasEstudiantes);
    setEmbudoConversion(data.embudoConversion);
    setPromedioEstudiantes(data.promedioEstudiantes);
  }

  const data=[]
  useEffect(() => {
    // inciar las fechas del año actual desde dia uno hasta el utimo
    const fechaActual = new Date();
    setFechaInicio(new Date(fechaActual.getFullYear(), 0, 1).toISOString().split('T')[0]);
    setFechaFin(new Date(fechaActual.getFullYear(), 11, 31).toISOString().split('T')[0]);
    getDataTable();
    getData();
    getMetricasGenerales();
  }, [])

//   consultar datos de la tabla de creditos
  const consultarDatos= async () => {
    // const data= await api.getAll(`graficas/creditosEstudiantes?IdPlanEstudio=${programa?.IdPlandeEstudio}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`)
    // setDataTable(data);
    getDataTable();
    getData();
    getMetricasGenerales();
  }

  return (
    <>
        <Card>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr 1fr',
                    gap: '1rem',
                }}
            >
                {/* select */}
                <Select
                    id="IdTipoIngreso"
                    name="IdTipoIngreso"
                    onChange={(e) => setIdTipoIngreso(e) }
                    value={ IdTipoIngreso || null }
                    // label="Tipo de Ingreso"
                    required={true}
                    optionValue="Id"
                    optionName="nombre"
                    urlOptionsService={"selects/tipoOferta"}
                /> 

                <Form.Control
                    type="date"
                    name="FechaInicio"
                    onChange={(e) => setFechaInicio(e.target.value)}
                    value={fechaInicio}
                />
                <Form.Control
                    type="date"
                    name="FechaFin"
                    onChange={(e) => setFechaFin(e.target.value)}
                    value={fechaFin}
                />
                <Button onClick={consultarDatos}>Consultar</Button>                     
            </div>
        </Card>
        
        {/* <TablaCreditos data={dataTable} /> */}
        <Card>
            <Card.Title>Métricas Generales</Card.Title>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gap: '1rem',
                }}
            >
                {metricasGenerales.map((metrica, index) =>
                    <TablaMetricas  metrica={metrica} />
                )}            
            </div>
        </Card>
        <Card>
            <Row>
                <Col>
                    <Card.Title>Gráficas Por período</Card.Title>
                </Col>
                <Col
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {/* <Form.Control 
                        as="select"
                        name='IdOferta'
                        onChange={(e) => setIdOferta(e.target.value)}
                        value={idOferta}
                    >
                        {programa.ofertas.map((oferta, index) => 
                            <option key={index} value={oferta.IdOferta}>{oferta.codigo_grupo}</option>
                        )}
                    </Form.Control> */}
                </Col>
            </Row>
            <Row>
                <Col>
                    <GraficoBarras
                        data={inasistenciasDocentes}
                        titulo="Inasistencias de Docentes"
                        label='Docentes'
                    />
                </Col>
                <Col>
                    <GraficoBarras 
                    data={inasistenciasEstudiantes}
                    titulo="Inasistencias de Estudiantes"
                    label='Estudiantes'
                    />
                </Col>
            </Row>
            <Row>
            <Col>
                <GraficoBarras 
                    data={promedioEstudiantes}
                    titulo="Promedio Estudiantes"
                    label='Estudiantes'
                />
            </Col>
                <Col>
                    <EmbudoConversion data={embudoConversion}/>
                </Col>
            </Row>
        </Card>
    </>
  )
}
