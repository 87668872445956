import { Card } from "../../../components/card/Card"
import { DynamicFormContainer } from "../../../components/DynamicForm/DynamicFormContainer" 
import { useNavigate } from "react-router-dom"
import { BASE_URL } from "../../../services/api/config"

const OfertasExternas=()=>{
    
    const navigate = useNavigate()
    const exportar = `${BASE_URL}/exportar/actividadesExternas`

    const onNavigateDetails = (e) => {
        navigate(`estudiantesOfertaExterna/${e.IdOfertaExterna}`)
    }

    return(
        <>
        <Card>
            <DynamicFormContainer 
                idForm={128}
                labelOptionClick="Listar Estudiantes"
                handleOptionClick={ onNavigateDetails}
                endPointSubmit={false}
                exportar={exportar}
            />
        </Card>
        </>
    )
}

export {OfertasExternas}