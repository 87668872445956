// 

import { useLocation, useNavigate } from "react-router-dom"
import { SubTitle } from "../../../../shared/components/subtitle/SubTitle"
import { Title } from "../../../../shared/components/title/Title"
import Table from 'react-bootstrap/Table';
import { useState } from "react"
import { Input } from "../../../../components/input/input"
import { FormService } from "../../../../services/api/formService"
import { useEffect } from "react"
import { alerta } from "../../../../helpers/alertas"
import { DynamicForm } from "../../../../components/DynamicForm/DynamicForm"
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { BASE_URL } from "../../../../services/api/config";
import DowloadFile from "../../../../components/DowloadFile/DowloadFile";

function Seguimiento () {

 
  const [isOpenModalEvaluacion,setIsOpenModalEvaluacion] = useState(false)
  const [isOpenModalEvaluacionDelete,setIsOpenModalEvaluacionDelete] = useState(false)
  const [ evaluaciones,setEvaluaciones] = useState([])
  const [ itemSelected,setItemSelected] = useState({})
  const [ dateRangeEvaluacion,setDateRangeEvaluacion] = useState({})
  const [isOpenModalAsignarEvaluacion,setIsOpenModalAsignarEvaluacion] = useState(false)
  const navigate = useNavigate()
  const api = new FormService()
  useEffect(()=>{
    fetchEvaluaciones()
  },[])

  const handleEditClick = (item) => {
    setItemSelected(item)
    setDateRangeEvaluacion({FechaInactivo:item.FechaInactivo,FechaActivo:item.FechaActivo})
    setIsOpenModalEvaluacion(true)
  }
  const handleDeleteClick = (item) => {
    setItemSelected(item)
    setIsOpenModalEvaluacionDelete(true)

  }
  const onHandleChange =(value,name)=>{
    setDateRangeEvaluacion({...dateRangeEvaluacion,[name]:value})
  }
  const fetchEvaluaciones = async()=>{
    api.getAll(`gestionInstrumentos/seguimiento`)
    .then(res=>{
      setEvaluaciones(res)
    })
  }
  
  const onUpdateDate =async ()=>{
    
    try {
      const res = await api.update(`gestionInstrumentos`,dateRangeEvaluacion,itemSelected.IdGestionInstrumento)
      console.log(res)
      setIsOpenModalEvaluacion(false)
      fetchEvaluaciones()
    } catch (error) {
      alerta('error')
    }
  }
  const onDeleteEvaluacion =async ()=>{
    try {
      const res = await api.update(`gestionInstrumentos`,{Estado:0},itemSelected.IdGestionInstrumento)
      console.log(res)
      setIsOpenModalEvaluacionDelete(false)
      fetchEvaluaciones()
    } catch (error) {
      alerta('error')
    }
  }
  const actualizar=()=>{
    setTimeout(() => {
      fetchEvaluaciones();
      setIsOpenModalAsignarEvaluacion(false)
    }, 3000);
  }
   const contestar=(item)=>{
      navigate("evaluacion",{state:{evaluacion:item}})
   }
  
  return (
    <>
      <Card>
        <Row>
          <Col>
          <Card.Title>Seguimiento en Aula</Card.Title>
          </Col>
          <Col style={{textAlign:"right"}}>
          <Button
                onClick={()=>setIsOpenModalAsignarEvaluacion(true)}
                >Asignar Evaluación</Button>
          </Col>
        </Row>
      </Card>

      <Card>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>N°</th>
            <th>Nombre del Instrumento</th>
            <th>Docente</th>
            <th>Plazo (A/M/D)</th>
            <th>Acciones</th>
            <th>Cambiar Fecha</th>
            <th>Eliminar</th>
          </tr>
        </thead>
        <tbody>
          {!!evaluaciones && evaluaciones.map((item,index)=>{
            return(
              <tr key={index} >
                <td>{index+1}</td>
                <td>ACOMPAÑAMIENTO PEDAGÓGICO EN AULA</td>
                <td>{item.Nombre}</td>
                <td> {item.FechaActivo} / {item.FechaInactivo}</td>
                {!item.IdInstrumento?
                <>
                  <td onClick={()=>contestar(item)}>Contestar</td>
                  <td onClick={()=>handleEditClick(item)}> <i className="bi bi-pencil"></i></td>
                  <td onClick={()=>handleDeleteClick({...item,index:(index+1)})} ><i className="bi bi-trash3"></i></td>
                </>
                :
                <>
                  <td>
                    <DowloadFile
                      nameFile={"Seguimiento.pdf"}
                      name={"Descargar"}
                      endPoint={`${BASE_URL}/instrumento/descagarSeguimiento?IdGestionInstrumento=${item.IdGestionInstrumento}`}
                    />
                    {/*<a target="_blank" href={`${BASE_URL}/instrumento/descagarSeguimiento?IdGestionInstrumento=${item.IdGestionInstrumento}`}>Descargar</a>*/}
                  </td>
                  <td></td>
                  <td></td>
                </>          


                }
                
              </tr>
            )
          }

          )}
        </tbody>
      </Table>
      </Card>

  <Modal
    show={isOpenModalEvaluacion}
    onHide={()=>setIsOpenModalEvaluacion(false)}
  >
    <Modal.Header closeButton>
      <Modal.Title>
          
      </Modal.Title>
    </Modal.Header>
  <Card>
     <label>Fecha inicial</label>
     <Input
    name={'FechaActivo'}
    onChange={onHandleChange}
     value={dateRangeEvaluacion.FechaActivo || '' }
     type={'date'}
     ></Input>
    <br></br>
    <label>Fecha limite</label>
    <Input
    onChange={onHandleChange}
    name={'FechaInactivo'}
    value={dateRangeEvaluacion.FechaInactivo ||  '' }
    type={'date'}
    ></Input>
  </Card>
  <Modal.Footer>
    <Button 
      variant="secondary"
     onClick={()=>setIsOpenModalEvaluacion(false)}
    >
      Cancelar
    </Button>
    <Button 
      onClick={onUpdateDate}
    >
      Actualizar
    </Button>
  </Modal.Footer>
  </Modal>

  <Modal
    show={isOpenModalEvaluacionDelete}
    onHide={()=>setIsOpenModalEvaluacionDelete(false)}
  >
  <Modal.Body>
     ¿ Estas seguro que desea eliminar esta evaluaión?
  </Modal.Body>
  <Modal.Footer>
  <Button 
      variant="secondary"
    onClick={()=>setIsOpenModalEvaluacionDelete(false)}
    >
      Cancelar
    </Button>
    <Button
      variant="danger" 
      onClick={onDeleteEvaluacion}
    >
      Eliminar
    </Button>
  </Modal.Footer>
  </Modal>
  <Modal
    show={isOpenModalAsignarEvaluacion}
    onHide={()=>setIsOpenModalAsignarEvaluacion(false)}
    >
      <DynamicForm
          title={'Gestión instrumentos'}
          endPointSubmit={'gestionInstrumentos'}
          isCreate={true}
          extraData={{IdTipoEvaluacion:9}}
          ventanaFormName={'Gestión instrumentos'}
          justDynamicForm={{ flag:true, idForm:"107" }}
          onClickCancelBtn={setIsOpenModalAsignarEvaluacion}
          accionMysql={()=>actualizar()}
      ></DynamicForm>
</Modal>
    </>
  )
}

export { Seguimiento }