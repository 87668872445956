import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Applicant } from '../../components/outlet/Applicant/Applicant'
import { ApplicantListContainer } from '../../components/outlet/ApplicantList/ApplicantListContainer'
import { OfertasConvenios } from './OfertasConvenios'
import { OfertasEmpresas } from './OfertasEmpresas'

export default function Empresas() {
  return (
    <>
        <Routes>
            <Route path='ofertas_por_convenios' element={<OfertasConvenios/>}/>
            <Route path="ofertas_por_convenios/lista-aspirante/:idApplicantList" element={<ApplicantListContainer />}></Route> 
            <Route path="ofertas_por_convenios/lista-aspirante/:idApplicantList/estado-aspirante/:idApplicant" element={<Applicant />}></Route> 
            <Route path='ofertas_empresas' element={<OfertasEmpresas/>}/>
            <Route path="ofertas_empresas/lista-aspirante/:idApplicantList" element={<ApplicantListContainer empresa={true} />}></Route> 
            <Route path="ofertas_empresas/lista-aspirante/:idApplicantList/estado-aspirante/:idApplicant" element={<Applicant />}></Route> 
        </Routes>
    </>
  )
}
