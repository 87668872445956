import React from 'react'
import { DynamicFormContainer } from '../../components/DynamicForm/DynamicFormContainer'
import { Route, Routes } from 'react-router-dom'
import ItemsEstabilizacion from './ItemsEstabilizacion'
import Item from './Item'
import ItemsMensajes from './ItemsMensajes'

export default function PlanEstabilizacion() {
  return (
    <>
        <Routes>
            <Route path="items_estabalizacion" element={<ItemsEstabilizacion/>} />  
            <Route path="items_estabalizacion/:id" element={<Item/>} />
            <Route path="items_con_mensajes" element={<ItemsMensajes/>} />
            <Route path="items_con_mensajes/:id" element={<Item administrador/>} />
            <Route path="*" element={<DynamicFormContainer/>} />
        </Routes>
    </>
  )
}
