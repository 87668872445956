import React from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { BASE_URL } from '../../services/api/config'

export default function Funcionario() {

    const funcionario=useLocation().state.funcionario

    const navigate=useNavigate()
    const url=`${BASE_URL}/funcionarios/exportarFuncionario?IdUsuario=${funcionario.IdUsuario}&IdFuncionario=${funcionario.IdFuncionario}`

  return (
    <>
        <Card>
            <Row>
                <Col>
                    <Card.Title>{funcionario?.Nombre}</Card.Title>
                </Col>
                <Col
                    style={{textAlign:'right'}}
                >
                    <Button
                        href={url}
                        target="_blank"
                    >
                        Exportar
                    </Button>
                </Col>
            </Row>
            
        </Card>
        {/* menu en cuadriculas */}
        <Row>
            <Col>
                <Card>
                    <Card.Body>
                        <Card.Title>Cursos</Card.Title>
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Card>
                    <Card.Body>
                        <Card.Title>Actividades</Card.Title>
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Card
                    onClick={()=>{
                        navigate("funciones",{state:{funcionario}})
                    }}
                >
                    <Card.Body>
                        <Card.Title>Funciones</Card.Title>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <Row>
            <Col>
                <Card>
                    <Card.Body>
                        <Card.Title>Asignaciones</Card.Title>
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Card>
                    <Card.Body>
                        <Card.Title>Entrenamientos</Card.Title>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </>
  )
}
