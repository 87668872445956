import { useNavigate } from "react-router-dom";
import { LocalStorage } from "../../../services";
import { DynamicTable } from "../../../components/DynamicForm/dynamicTable/DynamicTable";
import { Title } from "../../../shared/components/title/Title";
import { AddButton } from "../../../components/componentsForm/addButton/addButton";
import { Modal } from "../../../components/modal/Modal";
import { useState } from "react";
import { Input } from "../../../components/input/input";
import { Button } from "../../../components/button/button";

import { BASE_URL} from "../../../services/api/config";

function Docentes() {

  const urlProgramas = `docente`;
  const navigate = useNavigate();
  const [modalOpen,isModalOpen]=useState(false)
  const [titulo,isTitulo]=useState('')
  const [urlDownload,isUrlDowload]=useState('')
  const [FechaInicio,setFechaInicio]=useState(null)
  const [FechaFin, setFechaFin]=useState(null)

  const[docente, setDocente]=useState([])

  const onNavigate = (docente) => {
    let titulo="Exportar el certificado de notas de: "+docente.PrimerNombres+" "+docente.SegundoApellidos
    isTitulo(titulo)
    isModalOpen(true)
    setDocente(docente)
    const url = `${BASE_URL}/docente/certificadoHoras?IdDocente=${docente.IdDocente}&FechaInicio=`
    
  }
  const input=(e,fecha)=>{
    
    if(fecha==1){
        setFechaInicio(e)
    }
    if(fecha==2) {
        setFechaFin(e)

        if (FechaInicio) {
            const url = `${BASE_URL}/docente/certificadoHoras?IdDocente=${docente.IdDocente}&FechaInicio=${FechaInicio}&FechaFin=${e}`
            isUrlDowload(url)
            console.log(urlDownload)
        }
    }
    
    
    

  } 

  return (
    <>
    <AddButton></AddButton>
    <Title>Docentes</Title>
    <DynamicTable
      titleColumn={['N°','Nombre','Apellido', 'Acción']}
      attributes={['PrimerNombres','SegundoApellidos']}
      endPoint={urlProgramas}
      onDelete={false}
      onUpdate={false}
      labelOptionClick={`Certificado de horas`}
      handleOptionClick={onNavigate}
      classNameOptionIcon={`bi bi-view-list`}
    ></DynamicTable>

    <Modal 
        title={titulo}
        isOpen={modalOpen} 
        close={()=>isModalOpen(false)}       
    >
        <Input
            label={'Fecha Inicial'}
            type={'date'}
            onChange={(e)=>input(e,1)}
        />
        <Input
            label={'Fecha Final'}
            type={'date'}
            onChange={(e)=>input(e,2)}
        />
    {urlDownload &&
      <Button
      type={'send'}
      >
        <a 
        target={'_blank'}
        rel="noreferrer"
        style={{color:'white'}}
        // onClick={()=>onSolicitarDiploma} 
        href={urlDownload} 
        download
        ><i class="bi bi-file-earmark-text"></i>  Solicitar Certificado</a>
      </Button>
      }

    </Modal>
    </>
  )
}

export  { Docentes };