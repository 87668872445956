import { useState } from "react"
import { Modal } from "react-bootstrap"
import { Card } from "../../../components/card/Card"
import { DynamicFormContainer } from "../../../components/DynamicForm/DynamicFormContainer"
import ActividadExterna from "../../componentesC/ActividadExterna/ActividadExterna"
import { BASE_URL } from "../../../services/api/config"

const Activiades=()=>{

    const [modalActividad,setModalActividad]=useState(null)
    const [actividad,setActividad]=useState(null)
    const exportar = `${BASE_URL}/exportar/actividadExterna`

    return(
        <>
        <Card>
            <DynamicFormContainer 
                idForm={95}
                handleOptionClick={(e)=>{
                    setActividad(e)
                    setModalActividad(true)
                }}
                labelOptionClick="Ver registro"
                exportar={exportar}
            />
        </Card>
        <Modal
            show={modalActividad}
            onHide={setModalActividad}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Actividad Tercerizada
                </Modal.Title>
            </Modal.Header>
            <ActividadExterna actividad={actividad}/>
        </Modal>
        </>
    )
}

export {Activiades}