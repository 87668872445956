import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { Button } from '../button/button'
import './Modal.css'

const Modal = ({
    isOpen,
    title,
    close,
    children,
    className,
    modalAnimate = true
}) => {

    if (!isOpen) return null

    const container = e => {
        e.stopPropagation()
    }

    return ReactDOM.createPortal(
        <div 
            className='modal'
            onClick={ close }>
            <div 
                className={`modal__container ${className} ${modalAnimate ? 'modal_animated' : ''}`}
                onClick={ container }>
                    {
                        close && (
                            <section className='d-flex justify-content-end'>
                                <Button
                                    type='button'
                                    onClick={ close }
                                    setIsOpenModal={ false }
                                    >
                                    <i className="bi bi-x-lg"></i>
                                </Button>
                            </section>
                        )
                    }
                <section className='modal__content'>
                    <h2>{title}</h2>
                    { children }
                </section>
            </div>
        </div>,
        document.getElementById('modal')
    )
}

export { Modal }