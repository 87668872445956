import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Card, Modal, Table } from 'react-bootstrap'
import { useLocation } from 'react-router-dom';
import { Input } from '../../components/input/input';
import { alerta } from '../../helpers/alertas';
import { BASE_SERVIDOR } from '../../services/api/config';
import { FormService } from '../../services/api/formService'
import { SelectDataList } from '../../components/SelectDataList/SelectDataList';

export default function Firmas() {
  const Location=useLocation();
  const documento=Location.state.documento
  const api=new FormService();
  const [firmas,setFirmas]=useState([]);
  const[modal,setModal]=useState();
  const[usuario,setUsuario]=useState(null);
  const[firma,setFirma]=useState([]);
  const[selecionado,setSelecionado]=useState();
  const[IdUsuario, setIdUsuario]=useState();

  useEffect(()=>{
      const fecthData=async()=>{
        consultarFirmas()
      }
      fecthData();
  },[])


  const consultarFirmas=async()=>{
    const firmas= await api.getAll(`firmasDocumentos?IdDocumento=${documento.IdDocumento}`);
    setFirmas(firmas)
  }

  const onModal=(firma)=>{
    setSelecionado(firma)
    setUsuario(firma?.Usuario)
    setModal(true)
  }

  const actualizarFirma=async()=>{
    const data={
      "Documento":firma,
      IdUsuario
    }
    const respuesta = await api.updateWithFormData("firmasDocumentos",data,selecionado.IdFirmaDocumento)

    if(respuesta.respuesta=="exito"){
        alerta(respuesta.mensaje)
        consultarFirmas()
        setModal()
    }else{
        alerta(respuesta.mensaje)
    }

  }

  return (
    <>
        <Card>
            <Card.Title>
                Firmas
            </Card.Title>
          <p>Actualizar las imagenes de las firmas en formato .jpg con fondo blanco.</p>
          <p>Tenga en cuenta las siguientes medidas 332px por 152px para cargar su imagen</p>
        </Card>
        <Card>
          <Table>
              <thead>
                <tr>                 
                  <th>Cargo</th>
                  <th>Usuario</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {firmas.map((firma,key)=>
                <tr key={key}>                  
                  <td>{firma?.Cargo}</td>
                  <td>{firma?.Usuario}</td>
                  <td><Button
                    onClick={()=>onModal(firma) }
                  >
                    Actualizar
                    </Button></td>
                </tr>
                )}
              </tbody>
          </Table>
        </Card>

        <Modal 
          show={modal}
          onHide={()=>setModal()}
        >
          <Modal.Header closeButton>
            <Modal.Title>Atualizar firma y funcionario</Modal.Title>
          </Modal.Header>
          <form>
          <Modal.Body>
            <SelectDataList 
              label="Usuario"
              optionValue={'IdUsuario'}
              optionName='Nombre'
              endPoinLastUrl={`usuarios/completos`}
              onChangeHandler={(e)=>setIdUsuario(e)}
              valorActual={usuario}
              // value={usuario}
            />

          </Modal.Body>
          <Modal.Footer>
                  <Button 
                  variant="secondary"
                  onClick={()=>setModal()}
                  >
                    Cancelar
                  </Button>
                  <Button onClick={()=>actualizarFirma()}>
                    Guardar
                  </Button>
          </Modal.Footer>
          </form>
        </Modal>
    </>
  )
}
