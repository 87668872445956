import { Route, Routes } from "react-router-dom"
import { Coordinadores } from "./Coordinadores/Coordinadores"
import { CoordinadorListadoEvaluacionesDocentes } from "./Coordinadores/CoordinadorListadoEvaluacionesDocentes"
import { CursoFinalizado } from "./cursosFinalizados/CursoFinalizado"
import { CursosFinalizados } from "./cursosFinalizados/CursosFinalizados"
import { Docentes } from "./docentes/Docentes"
import { ListadoEvaluacionesDocente } from "./docentes/ListadoEvaluacionesDocente"
import { Instrumento } from "./Instrumentos/Instrumento"
import { MenuInstrumentos } from "./Instrumentos/MenuInstrumentos"
import { Trimester } from "./reportes/Trimester/Trimester"
import { ListadoEvaluacionesCursosCercaFinalizar } from "./cursosActivos/ListadoEvaluacionesCursosCercaFinalizar"
import { ListadoEvaluacionesCursosFinalizados } from "./cursosFinalizados/ListadoEvaluacionesCursosFinalizados"

import { Curso160 } from "./cursos160/Curso160"
import { Cursos160} from "./cursos160/Cursos160"
import { ListadoEvaluacionesCursos160} from "./cursos160/ListadoEvaluacionesCursos160"

import { ListaDocentes } from "./reportes/Trimester/ListaDocentes"
import { ListaCoordinadores } from "./reportes/Trimester/ListaCoordinadores"
import { ListaEventos } from "./reportes/Trimester/ListaEventos"
import { Coevaluaciones } from "./coevaluacion/Coevaluaciones"
import { Seguimiento } from "./seguimiento/seguimiento"
import { Evaluacion } from "./seguimiento/Evaluacion"
import { Alertas } from "./alertas/Alertas"
import { ConsultaHistorico } from "./consulta/ConsultaHistorico"
import { CursosActivos } from "./cursosActivos/CursosActivos"
import { CursoActivo } from "./cursosActivos/CursoActivo"
import { Estudiantes } from "./cursos160/Estudiantes"
import Configuraciones from "./Configuraciones/Configuraciones"
import FormPorcentajes from "./Configuraciones/FormPorcentajes"


function Evaluaciones () {

  return (
    <Routes>
      <Route 
      key={'instrumentos_de_evaluacion'} 
      path="instrumentos_de_evaluacion" 
      element={<MenuInstrumentos/>} 
      />
      <Route
       key={'2'} 
       path="instrumentos_de_evaluacion/:id" 
       element={<Instrumento/>} 
       />
      <Route
       key={'cursos_finalizados'} 
       path="cursos_finalizados" 
       element={<CursosFinalizados/>} 
       />
      <Route path="cursos_finalizados/:IdCurso" element={<CursoFinalizado/>} />
      <Route path="cursos_finalizados/:IdCurso/estudiante/:IdEstudiante" element={<ListadoEvaluacionesCursosFinalizados/>} />
      <Route path="actividades_sup_160_hrs" element={<Cursos160/>} />
      <Route path="actividades_sup_160_hrs/:IdCurso" element={<Curso160/>}/>
      <Route path="actividades_sup_160_hrs/:IdCurso/estudiantes" element={<Estudiantes/>}/>
      <Route path="actividades_sup_160_hrs/:IdCurso/estudiantes/estudiante/:IdEstudiante" element={<ListadoEvaluacionesCursos160/>} />
      <Route path="cursos_a_finalizar" element={<CursosActivos/>} />
      <Route path="cursos_a_finalizar/:IdCurso" element={<CursoActivo/>} />
      <Route path="cursos_a_finalizar/:IdCurso/estudiante/:IdEstudiante" element={<ListadoEvaluacionesCursosCercaFinalizar/>} />
      <Route path="docentes" element={<Docentes/>} />
      <Route path="docentes/:IdUsuario/evaluaciones" element={<ListadoEvaluacionesDocente/>} />
      <Route path="coordinadores" element={<Coordinadores/>} />
      <Route path="coordinadores/:IdUsuario/evaluaciones" element={<CoordinadorListadoEvaluacionesDocentes/>} />
      <Route path="reportes" element={<Trimester/>} />
      <Route path="reportes/docentes" element={<ListaDocentes/>} />
      <Route path="reportes/coordinadores" element={<ListaCoordinadores />} />
      <Route path="reportes/eventos" element={<ListaEventos />} />
      <Route path="coevaluacion" element={<Coevaluaciones/>} />
      <Route path="seguimiento_en_aulas" element={<Seguimiento/>} />
      <Route path="seguimiento_en_aulas/evaluacion" element={<Evaluacion/>} />
      <Route path="alertas" element={<Alertas/>}/>
      <Route path="consulta_de_historico" element={<ConsultaHistorico/>}/>
      <Route path="configuraciones" element={<Configuraciones/>} />
      <Route path="configuraciones/porcente-indicadores" element={<FormPorcentajes/>} />

    </Routes>
  )
}
export { Evaluaciones }