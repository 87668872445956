import { DynamicFormContainer } from "../../../../components/DynamicForm/DynamicFormContainer";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { DynamicTable } from "../../../../components/DynamicForm/dynamicTable/DynamicTable";
import { BsArrowReturnRight,BsFillFilePdfFill,BsFillFileExcelFill } from 'react-icons/bs';
import { DynamicForm } from "../../../../components/DynamicForm/DynamicForm";
import { useState } from "react";
import { FormService } from "../../../../services/api/formService";
import { alerta } from "../../../../helpers/alertas";
import { useDispatch, useSelector } from "react-redux";
import DowloadFile from '../../../../components/DowloadFile/DowloadFile';
import DowloadFileText from '../../../../components/DowloadFileText/DowloadFileText';
import { setItemSelected,setForm } from "../../../../components/DynamicForm/FormSlice";
import { LocalStorage } from "../../../../services";
import { BASE_URL } from "../../../../services/api/config";

const PlanEstudio=({})=>{
  



    const location=useLocation();
    const navigate=useNavigate();
    const api=new FormService()
    const dispatch = useDispatch()
    const idCurso=location.state.curso.IdCurso
    const urlPlanesEstudios=`planEstudio?IdCurso=${idCurso}`
    const [modal,setModal]=useState();
    const [modalEliminar,setModalEliminar]=useState();
    const [modalActualizar,setModalActualizar]=useState();
    const [endPoint,setEndPoint]=useState(urlPlanesEstudios);
    const itemSelected = useSelector((state) => state.form.itemSelected)
    

    const actualizar=()=>{
        setTimeout(() => {
            setModal(false)
            setModalActualizar()
            setEndPoint(`${urlPlanesEstudios}&i${Math.random()}`)
        }, 5000);
    }

    const eliminar=async()=>{
        const data={
            "Estado":0
        }
        const respuesta=await api.update("planEstudio",data,itemSelected.IdPlandeEstudio)
        if(respuesta.respuesta=="exito"){
            actualizar()
            setModalEliminar()
            alerta("Plan estudio eliminado")
        }else{
            alerta("Fallo desconocido")
        }

    }

    const ExportarPlanEstudio = (e) => {
        window.open(`${BASE_URL}/planeEstudio/descargar?IdPlandeEstudio=${e.IdPlandeEstudio}`);
    }
   
    return(
        <>
        <Card>
            <Row>
                <Col>
                    <Card.Title>Planes de Estudio</Card.Title>
                </Col>
                <Col style={{textAlign:"right"}}>
                    <Button 
                        onClick={()=>setModal(true)}
                    >
                        Crear
                    </Button>
                </Col>
            </Row>
        </Card>
        <DynamicTable
            endPoint={endPoint}
            titleColumn={["N°","Número Plan Estudio","Nombre","Acciones"]}
            attributes={["NumeroPlan","NombrePlanEstudio"]}
            labelOptionClick="Malla"
            handleOptionClick={(e)=>navigate("malla",{state:{planEstudio:e}})}
            labelOptionClick2={"Exportar"}
            handleOptionClick2={(e) => ExportarPlanEstudio(e)
            }
            actualizarExterno={(e)=>{
                setModalActualizar(true)
                dispatch(setItemSelected(e))
                dispatch(setForm({llave_primaria:"IdPlandeEstudio",form_data:"1"}))
            }}
            elimimarExterno={(e)=>{
                setModalEliminar(true)
                dispatch(setItemSelected(e))
            }}

        />
        <Modal 
            show={modal}
            onHide={()=>setModal()}
        >
            <DynamicForm
                title={`Crear Plan Estudio `}
                endPointSubmit={`planEstudio`}
                onClickCancelBtn={setModal}
                justDynamicForm={{ flag: true, idForm:"7" }}
                isCreate={true}
                extraData={{IdCurso:idCurso}}
                accionMysql={()=>actualizar()}
                isContentTypeFormData={`1`}
            />
        </Modal>
        {/* modal eliminar */}
        <Modal
            show={modalEliminar}
            onHide={()=>setModalEliminar()}
        >
            <Modal.Body>
                <b>¿Desea eliminar esta plan de estudio?</b>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={()=>setModalEliminar()}
                >
                    Cancelar
                </Button>
                <Button
                    variant="danger"
                    onClick={()=>eliminar()}
                >
                    Eliminar
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal
            show={modalActualizar}
            onHide={setModalActualizar}
        >
            <DynamicForm
                title={`Editar Plan Estudio `}
                endPointSubmit={`planEstudio`}
                onClickCancelBtn={setModalActualizar}
                justDynamicForm={{ flag: true, idForm:"7" }}
                isCreate={false}
                extraData={{IdPlandeEstudio:itemSelected?.IdPlandeEstudio}}
                accionMysql={()=>actualizar()}
                isContentTypeFormData={`1`}
            />
        </Modal>
        </>
        
    )
}
export{PlanEstudio};