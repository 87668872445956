import { useSelector } from "react-redux"
import { Route, Routes } from "react-router-dom"

import { EstudianteCalendarioContainer } from "./calendario/EstudianteCalendarioContainer"
import { EstudianteDetalleCalendario } from "./calendario/EstudianteDetalleCalendario"
import {PrivateRoute} from "../../shared/components/PrivateRoute"
import { EstudianteCursosContainer } from "./cursosFinalizados/EstudianteCursosContainer"
import { EstudianteDetalleCurso } from "./cursosFinalizados/EstudianteDetalleCurso"
import { EstudianteAsistencias } from "./cursosFinalizados/EstudianteAsistencias"
import { EvaluacionCurso } from "./cursosFinalizados/EvaluacionCurso"
import { EvaluacionDocente } from "./cursosFinalizados/EvaluacionDocente"

import { ListadoEvaluaciones } from "./cursosActivos/ListadoEvaluaciones"
import { PlanTrabajo } from "./cursosActivos/PlanTrabajo"

import { CursosActivosContainer } from "./cursosActivos/CursosActivosContainer"
import { EstudianteDetalleCursoActivo } from "./cursosActivos/EstudianteDetalleCursoActivo"
import { EstudianteAsistenciasCursoActivo } from "./cursosActivos/EstudianteAsistenciasCursoActivo"


import { Alertas } from "./Alertas"
import { SeguiminetoAlerta } from "../bienestar/SeguiminetoAlerta"
import SolicitarAsignaturas from "./cursosActivos/SolicitarAsignaturas"
import ActualizarDatosAspirante from "../../components/outlet/aspiranteModulo/Inscripciones/ActualizarDatosAspirante"
import MisProgramas from "./MisProgramas/MisProgramas"
import MenuPrograma from "./MisProgramas/MenuPrograma"
import PeridosActivos from "../registro/EducacionSuperior/PeriodosActivos"
import { PeriodosFinalizados } from "./MisProgramas/PeriodosFinalizados"
import Semaforizacion from "../registro/EducacionSuperior/Semaforizacion"
import PeriodosOfertados from "./MisProgramas/PeriodosOfertados"
import Asignaturas from "./MisProgramas/Asignaturas"
import { PeriodosActivos } from "./MisProgramas/PeriodosActivos"

function Estudiante  ({ventanas})  {

  // const permissions = useSelector((state) => state.user.isAuthenticated)
  const permissions = true
  return (

     <>
    <Routes>

      <Route  path="micalendario" element = {<EstudianteCalendarioContainer />}></Route>
      <Route  path="micalendario/:idCurso/detalle_calendario" element = {<EstudianteDetalleCalendario />}></Route>            
      
      <Route  path="mis_cursos_activos" element = {<CursosActivosContainer />}></Route>
      <Route  path="mis_cursos_activos/:idCurso/detalle_programa" element = {<EstudianteDetalleCursoActivo/>}></Route>
      <Route  path="mis_cursos_activos/:idCurso/detalle_programa/solicitar_asignaturas" element = {<SolicitarAsignaturas/>}></Route>
      <Route  path="mis_cursos_activos/:idCurso/detalle_programa/:id/asistencia" element = {<EstudianteAsistenciasCursoActivo/>}></Route>
      <Route  path="mis_cursos_activos/:idCurso/detalle_programa/:id/plandeTrabajo" element = {<PlanTrabajo />}></Route>  
      <Route  path="mis_cursos_activos/:idCurso/detalle_programa/:id/evaluaciones" element = {<ListadoEvaluaciones />}></Route>                    
      <Route  path="mis_cursos_activos/:idCurso/detalle_programa/:id/evaluaciones/:IdEvaluacion" element = {<EvaluacionCurso />}></Route>

      <Route  path="mis_cursos_finalizados" element = {<EstudianteCursosContainer />}></Route>
      <Route  path="mis_cursos_finalizados/:idCurso/detalle_programa" element = {<EstudianteDetalleCurso/>}></Route>                            
      <Route  path="mis_cursos_finalizados/:idCurso/detalle_programa/:id/evaluacion_docente" element = {<EvaluacionDocente />}></Route>           
      <Route  path="mis_cursos_finalizados/:idCurso/detalle_programa/:id/asistencia" element = {<EstudianteAsistencias/>}></Route>                     

      <Route  path="alertas" element = {<Alertas />}></Route>
      <Route  path="alertas/seguimiento_alerta" element = {<SeguiminetoAlerta responder={false} />}></Route>
      <Route  path="mis_programas" element = {<MisProgramas/>}></Route>
      <Route  path="mis_programas/menu_programa" element = {<MenuPrograma/>}></Route>
      <Route  path="mis_programas/menu_programa/periodos_activos" element = {<PeriodosActivos/>}></Route>
      <Route  path="mis_programas/menu_programa/periodos_activos/:idCurso/detalle_programa" element = {<EstudianteDetalleCursoActivo/>}></Route>
      <Route  path="mis_programas/menu_programa/periodos_activos/:idCurso/detalle_programa/solicitar_asignaturas" element = {<SolicitarAsignaturas/>}></Route>
      <Route  path="mis_programas/menu_programa/periodos_activos/:idCurso/detalle_programa/:id/asistencia" element = {<EstudianteAsistenciasCursoActivo/>}></Route>
      <Route  path="mis_programas/menu_programa/periodos_activos/:idCurso/detalle_programa/:id/evaluaciones" element = {<ListadoEvaluaciones />}></Route>           
      <Route  path="mis_programas/menu_programa/periodos_activos/:idCurso/detalle_programa/:id/plandeTrabajo" element = {<PlanTrabajo />}></Route>           
      <Route  path="mis_programas/menu_programa/periodos_activos/:idCurso/detalle_programa/:id/evaluaciones/:IdEvaluacion" element = {<EvaluacionCurso />}></Route>

      <Route  path="mis_programas/menu_programa/periodos_finalizados" element = {<PeriodosFinalizados/>}></Route>
      <Route  path="mis_programas/menu_programa/periodos_finalizados/:idCurso/detalle_programa" element = {<EstudianteDetalleCurso/>}></Route>                            
      <Route  path="mis_programas/menu_programa/periodos_finalizados/:idCurso/detalle_programa/:id/evaluacion_docente" element = {<EvaluacionDocente />}></Route>           
      <Route  path="mis_programas/menu_programa/periodos_finalizados/:idCurso/detalle_programa/:id/asistencia" element = {<EstudianteAsistencias/>}></Route>   

      <Route path="mis_programas/menu_programa/semaforizacion" element = {<Semaforizacion/>}></Route>
      <Route path="mis_programas/menu_programa/periodos_ofertados" element = {<PeriodosOfertados/>}></Route>
      <Route path="mis_programas/menu_programa/periodos_ofertados/asignaturas" element = {<Asignaturas/>}></Route>
      
      
      |<Route  path="hoja_de_vida" element = {<ActualizarDatosAspirante/>}></Route>
    </Routes>
     
      
  </>
  
  )
}
export { Estudiante } 