import { DynamicTable } from '../../../../components/DynamicForm/dynamicTable/DynamicTable'

function UsuarioFuncionarios() {
    return (
        <>
            <DynamicTable
                endPoint={'funcionarios'}
                titleColumn={[
                    'N°',
                    'Nombre',
                    'Apellido',
                    'Cargo',
                    'Identificación',
                ]}
                attributes={[
                    'nombres',
                    'apellidos',
                    'descripcion_cargo',
                    'identificacion',
                ]}
                onDelete={false}
                onUpdate={false}
                onActions={false}
            ></DynamicTable>
        </>
    )
}

export { UsuarioFuncionarios }
