import React from 'react'
import { Route, Routes } from 'react-router'
import Actividades from './Actividades'

export default function GestionEducacion() {
  return (
    <Routes>
        <Route path="actividades" element={<Actividades/>} />
    </Routes>
  )
}
