import React from 'react';
import { Card } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';

const GraficoBarras = ({ data,titulo='',label='' }) => {
  if (!data) {
    return null;
  }

  const labels = data.map(item => item.rango);
  const values = data.map(item => item.cantidad);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: label,
        data: values,
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1
      }
    ]
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true
      }
    }
  };

  return(
    <Card>
      <Card.Title>{titulo}</Card.Title>
      <div style={{ height: '400px', width: '100%' }}>
        <Bar data={chartData} options={chartOptions} />
      </div>
    </Card>
  )
};

export default GraficoBarras;
