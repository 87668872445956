import { Button } from "../button/button";
import './CardPay.css';


function CardPay({
  email,
  title,
  description,
  price,
  onClick,
  buttonTitle,
  children,
  isLoading,
  isDownload=false
}) {
  
  let formatter = new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP'
  })

  console.log("WW-WW")

  return (
    <>
      <div className="card-pay">
        <div className="card-pay__header">
          {
            isDownload 
            ? <img 
                className="card-pay__header-img" 
                src="/img/decoration_images/undraw_download_re_li50.svg" 
                alt="Imagen pago" 
              />
          : <img 
              className="card-pay__header-img" 
              src="/img/decoration_images/undraw_transfer_money_re_6o1h.svg" 
              alt="Imagen pago" 
            />
          }
        
          <div className="card-pay-header-icon">
          </div>
        </div>
        <div className="card-pay__body">
          
            <h3 className="card-pay__body-title">{title}</h3>
            <h1>{formatter.format(price)}</h1>
            <span>{description}</span>
          
          <div className="card-pay__body__button">
           {children && 
           children}
            {!children && 
            <Button
              className={'p-3'}
              type={'send'}
              onClick={onClick}
            >{buttonTitle} 
            {
              isLoading && 
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="visually-hidden">cargando...</span>
              </div>
            }
            </Button>}
          </div>
        </div>
      </div>

    </>
  );
}

export { CardPay }