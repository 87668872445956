import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { DynamicFormContainer } from '../../components/DynamicForm/DynamicFormContainer'
import AreasSaa from './AreasSaa'
import FuncionesSaa from './FuncionesSaa'
import Funcionarios from './Funcionarios'
import Funciones from './Funciones'
import Funcion from './Funcion'
import Estudios from './Estudios'
import FuncionesFuncionario from './FuncionesFuncionario'
import Funcionario from './Funcionario'


export default function ParametricasSAA() {
  return (
    <Routes>
        <Route path="direcciones_saa" element={<DynamicFormContainer idForm="132"/>} />
        <Route path="tipos_de_capacitacion_saa" element={<DynamicFormContainer idForm="134"/>}/>
        <Route path="funciones" element={<Funciones/>}/>
        <Route path="funciones/:id/cursos_requeridos" element={<Funcion/>}/>
        <Route path="areas" element={<DynamicFormContainer idForm="143"/>}/>
        <Route path="funcionarios" element={<Funcionarios/>} />
        <Route path="funcionarios/:id" element={<Funcionario/>} />
        <Route path="funcionarios/:id/funciones" element={<FuncionesFuncionario/>} />
        <Route path="funcionarios/:id/estudios" element={<Estudios/>} />

        <Route path="*" element={<DynamicFormContainer/>} />
    </Routes>
  )
}
