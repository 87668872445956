import { useEffect } from "react" 
import { useState } from "react"
import { DynamicFormContainer } from "../../../../components/DynamicForm/DynamicFormContainer"
import { DynamicForm } from "../../../../components/DynamicForm/DynamicForm"
import { DynamicTable } from "../../../../components/DynamicForm/dynamicTable/DynamicTable" 
import { FormService } from "../../../../services/api/formService"
import { Card, Tabs, Tab, Modal, ModalHeader, ModalFooter, ModalBody, ModalTitle, Row, Col, Button} from "react-bootstrap"
import { Input } from "../../../../components/input/input"
import { Select } from "../../../../components/select/Select"
import { alerta } from "../../../../helpers/alertas"
import { BASE_URL } from "../../../../services/api/config"
import { useDispatch, useSelector } from "react-redux"
import { setItemSelected,setForm } from "../../../../components/DynamicForm/FormSlice"
const Conceptos = () => {
    const dispatch = useDispatch()
    const itemSelected = useSelector((state) => state.form.itemSelected)

    const api = new FormService()
    const fecha = new Date()
    const fechaActual = fecha.getFullYear() 
    let fechaInicial = 2023
    let periodos = []
    let periodoSelect = {}
    let selectPeriodo = []
    const [periodo, setPeriodo] = useState()
    const [valorUVT, setValorUVT] = useState()
    const [modal, setModal] = useState(false)
    const [modalCrear, setModalCrear] = useState(false)
    const [modalActualizar, setModalActualizar] = useState(false)
    const [fechaI, setFechaI] = useState()
    const [fechaII, setFechaII] = useState()
    const urlConceptos = `pagos?e=1`
    const [endPoint, setEndPoint] = useState(urlConceptos)
      
    const addValorUVT = async () => {
        const respuesta = await api.getAll(`pagos/updateValores?ValorUVT=${valorUVT}&Periodo=${periodo}`)

        if(respuesta.respuesta == 'exito') {
            alerta("Éxito", "success")
        } else {
            alerta("Algo va mal", "warning")
        }
        
        setModal(false)  
        setTimeout(() => {
            window.location.reload()  
        }, 3000)
        
           
    }
    
    const contarPeriodos = async () =>  {
        for(let i = 0; fechaInicial <= parseInt(fechaActual); i++ ) {
            periodos.push(fechaInicial)
            fechaInicial++
        }
        let n=1;
        for (let periodo of periodos) {
            periodoSelect = {...periodoSelect, "Id": n, "Periodo": periodo}
            selectPeriodo.push(periodoSelect)
            n++
        }
    }

    useEffect(() => {

    }, [contarPeriodos()]);

    const refreshPage = () => {
        setTimeout(() => {
            setEndPoint(`${urlConceptos}&i=${Math.random()}`)
            setModalActualizar(false)
            setModalCrear(false)
        }, 1000)
    }
    // eliminar concepto

    const eliminarConcepto = async (e) => {
        const respuesta = await api.delete("pagos",{Estado:0}, e.IdPago)
        alerta("Concepto eliminado", "success")
        refreshPage()
    }

    return (
        <>
            <Card>
                <h2>Conceptos</h2>
                <div style={{textAlign: 'right'}}>
                    {/* <Button onClick={() => setModal(true)} style={{marginRight: '10px'}} variant="secondary">Agregar Valor UVT</Button> */}
                    <Button onClick={() => setModalCrear(true)}> Crear </Button>
                </div>
            </Card>
            <Tabs
                defaultActiveKey="periodo2023"
                id="uncontrolled-tab-example"
                className="mb-3"
            >   
                {periodos ? 
                    periodos.map((item, index) => (
                        <Tab eventKey={"periodo"+item} title={item} key={index}>
   
                            <Card>
                                <DynamicTable
                                    titleColumn={["No", "Nombre", "Código", "UVT", "Valor UVT ($)", "Valor Total UVT ($)", "Acción"]}
                                    attributes={["Descripcion", "Codigo", "UVT", "ValorUVT", "VariableUVT"]}
                                    endPoint={`${endPoint}&Periodo=${item}`}
                                    onUpdate={false}
                                    labelOptionClick={"Actualizar"}
                                    handleOptionClick={(e) =>{
                                        setModalActualizar(true)
                                        dispatch(setItemSelected(e))
                                        dispatch(setForm({llave_primaria:"IdPago",form_data:"0"}))
                                    }}
                                    exportar={`${BASE_URL}/pagos/exportar?Periodo=${item}`}
                                    elimimarExterno={(e) => {
                                        eliminarConcepto(e)
                                    }}
                                />
                            </Card>
                        </Tab>
                    ))
                :
                    <Tab eventKey={"periodo2021"} title={"Periodos"}>
                        <DynamicFormContainer idForm="43"/>
                    </Tab>
                }
            </Tabs> 
            
             {/* <Modal
                show={modal}
                onHide={() => setModal(false)}
            >
                <ModalHeader closeButton>
                    <ModalTitle>Agregar Valor UVT</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <Input
                                type={'number'}
                                label={"Valor UVT Actual"}
                                id={'valorUVT'}
                                name={'valorUVT'}
                                value={valorUVT}
                                onChange={(e) => setValorUVT(e)}
                            />
                            <Select
                                label={"Valor de Año - Periodo"}
                                id={"Periodo"}
                                name={"Periodo"}
                                value={periodo}
                                onChange={(e) => setPeriodo(e)}
                                optionValue={"Periodo"}
                                optionName={"Periodo"}
                                options={selectPeriodo}
                            />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => addValorUVT()}>Guardar</Button>
                </ModalFooter>
            </Modal> */}

            <Modal
                show={modalCrear}
                onHide={() => setModalCrear(false)}
            >
                <DynamicForm
                    title={"Crear Concepto"}
                    justDynamicForm={{flag:true,idForm:'43'}}
                    endPointSubmit={`pagos`}
                    onClickCancelBtn={() => setModalCrear(false)}
                    isCreate={true}
                    accionMysql={() => refreshPage()}
                />
            </Modal>
            
            <Modal
                show={modalActualizar}
                onHide={() => setModalActualizar(false)}
            >
                <DynamicForm
                    title={"Actualizar Concepto"}
                    justDynamicForm={{flag:true,idForm:'43'}}
                    endPointSubmit={`pagos`}
                    onClickCancelBtn={() => setModalActualizar(false)}
                    isCreate={false}
                    accionMysql={() => refreshPage()}
                />
            </Modal>
        </>   
    )
}

export {Conceptos}