import React from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { DynamicFormContainer } from '../../components/DynamicForm/DynamicFormContainer'
import ConfigurarCurso from './ConfigurarCurso'
import ConfigurarCuestionario from './ConfigurarCuestionario'
import CuestionarioEstudiante from './CuestionarioEstudiante'

export default function LMS() {
    const navigate = useNavigate()
  return (
    <Routes>
        <Route path="cursos" element={
            <DynamicFormContainer 
                idForm="184" 
                labelOptionClick={"Configurar"}
                handleOptionClick={(e) => navigate(`cursos_lms/${e.id}/configurar`,{state:{curso:e}})}
        
            />
        } />
        <Route path="cursos_lms/:id/configurar" element={<ConfigurarCurso />} />
        <Route path="cursos_lms/:id/configurar/:id/cuestionario" element={<CuestionarioEstudiante />} />
        <Route path="categorias_cursos" element={<DynamicFormContainer idForm="185" />} />
        <Route path="cuestionarios" element={
        <DynamicFormContainer 
          idForm="186" 
          labelOptionClick={"Configurar"}
          handleOptionClick={(e) => navigate(`cuestionarios/${e.id}/configurar`,{state:{cuestionario:e}})}
        />} />
        <Route path="cuestionarios/:id/configurar" element={<ConfigurarCuestionario />} />
        <Route path="*" element={<DynamicFormContainer />} />
    </Routes>
  )
}
