import React from 'react'
import { DynamicFormContainer } from '../../components/DynamicForm/DynamicFormContainer'

export default function ListadoAlertas() {
  return (
    <>
        <DynamicFormContainer idForm="86" />
    </>
  )
}
