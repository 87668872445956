import React, { useEffect, useState } from 'react'
import { Accordion, Badge, Button, Card, Form, ListGroup, Modal, Tab, Table, Tabs } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { alerta } from '../../../helpers/alertas'
import { BASE_SERVIDOR } from '../../../services/api/config'
import { FormService } from '../../../services/api/formService'

export default function TrasabilidadAdmision() {
    const {estudiante,programa}=useLocation().state
    const api= new FormService()
    const [requisitos, setRequisitos] =useState([])
    const [requisito, setRequisito] =useState(null)
    const [requisitosMatricula, setRequisitosMatricula] =useState([])
    const [archivo, setArchivo] =useState(null)
    const [modalDocumento, setModalDocumento] =useState(false)
    const [modalFase, setModalFase] =useState(false)
    const [fases, setFases] =useState([])
    const [fase, setFase] =useState(null)
    const [aprobo, setAprobo] =useState(1)
    const [puntaje, setPuntaje] =useState(null)
    const [observaciones, setObservaciones] =useState(null)
    const [archivoSoporte, setArchivoSoporte] =useState(null)
    const [itemActual,setItemActual]=useState(null)
    const [modalEliminar,setModalEliminar]=useState(false)
    const [modalEliminarFase,setModalEliminarFase]=useState(false)

    useEffect(() => {
       (async()=>{
            consultarRequsitos()
            consultarRequsitosMatricula()   
            consultarFases()
         })();
    }, [])
 
    // consultar requisitos del estudiante
    const consultarRequsitos=async()=>{
        const respuesta=await api.getAll(`requisitosAspirante/trazabilidad?IdAspirante=${estudiante.IdAspirante}&IdOferta=${estudiante.IdOferta}&IdTipoRequisito=1`)
        setRequisitos(respuesta)
    }
    // consultar requisitos matricula del estudiante
    const consultarRequsitosMatricula=async()=>{
        const respuesta=await api.getAll(`requisitosAspirante/trazabilidad?IdAspirante=${estudiante.IdAspirante}&IdOferta=${estudiante.IdOferta}&IdTipoRequisito=2`)
        setRequisitosMatricula(respuesta)
    }
    // guardar documento
    const guardarDocumento=async()=>{
        if(archivo==null || archivoSoporte==null){
            return 
        }
        const data={
            IdAspirante:estudiante.IdAspirante,
            IdRequisito:requisito.IdRequisito,
            Archivo:archivo,
            ArchivoSoporte:archivoSoporte,
            Posterior:1
        
        }
        const respuesta=await api.createWithFormData(`requisitosAspirante/documentoAdicional`,data)
        if(respuesta){
            setModalDocumento(false)
            consultarRequsitos()
            alerta("Documento guardado","success")
        }else{
            alerta("No se pudo guardar el documento","error")
        }
    }



    // consultar fases del estudiante
    const consultarFases=async()=>{
        const respuesta=await api.getAll(`aspiranteFase/trazabilidad?IdAspirante=${estudiante.IdAspirante}&IdConvocatoria=${estudiante.IdConvocatoria}`)
        setFases(respuesta)
    }
    // guardar respuesta de fase
    const guardarRespuesta=async()=>{
        const data={
            IdAspirante:estudiante.IdAspirante,
            IdConvocatoriaFase:fase.IdConvocatoriaFase,
            Aprobo:aprobo,
            Puntaje:puntaje,
            Observaciones:observaciones,
            ArchivoSoporte:archivoSoporte,
            Posterior:1

        }
        // return console.log(data)
        const respuesta=await api.createWithFormData(`aspiranteFase`,data)
        if(respuesta){
            setModalFase(false)
            consultarFases()
            alerta("Respuesta guardada","success")
        }else{
            alerta("No se pudo guardar la respuesta","error")
        }
    }
    // eliminar documento
    const eliminarDocumento=async(item)=>{
        const  data={
            Estado:0
        }
        const respuesta=await api.delete(`requisitosAspirante`,data,itemActual.IdRequisitoAspirante)
        if(respuesta){
            consultarRequsitos()
                setModalEliminar()
            alerta("Documento eliminado","success")
        }else{
            alerta("No se pudo eliminar el documento","error")
        }
    }
    // eliminar fase
    const eliminarFase=async(item)=>{
        const  data={
            Estado:0
        }
        const respuesta=await api.delete(`aspiranteFase`,data,itemActual.IdAspiranteFase)
        if(respuesta){
            consultarFases()
            setModalEliminarFase()
            alerta("Fase eliminada","success")
        }else{
            alerta("No se pudo eliminar la fase","error")
        }
    }



  return (
    <>
        <Card>
            <Card.Title>
                Trazabilidad Admisión de {estudiante.Nombre}
            </Card.Title>
            <p>{programa.Nombre}</p>
        </Card>
        <Card>
            {/*tabs de react boostrap  */}
            <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                <Tab eventKey="home" title="Requisitos">
                {programa?.IdDimension == 1 ? 
                <>
                {estudiante.Semestre==1 &&

                <Accordion>
                    {requisitos.map((item,index)=>{
                        return(                           
                            <Accordion.Item key={index} eventKey={index}>
                                <Accordion.Header>{item.Nombre}</Accordion.Header>
                                <Accordion.Body>
                                    <div style={{padding:30}}>
                                        <Table 
                                            striped
                                        >
                                            <thead>
                                                <tr> 
                                                    <th>N°</th>
                                                    <th>Fecha</th>
                                                    <th>Documento</th>
                                                    <th>Estado</th>
                                                    <th>Acciones</th>
                                                </tr> 
                                            </thead>
                                            <tbody>
                                                {item?.documentos.map((item,index)=>{
                                                    return(
                                                        <tr key={index}>
                                                            <td>{index+1}</td>
                                                            <td>{item?.FechaCreacion}</td>
                                                            <td>
                                                                <a  
                                                                    href={`${BASE_SERVIDOR}/pdf/${item.Archivo}`} 
                                                                    target="_blank" 
                                                                    rel="noreferrer"
                                                                >
                                                                    <i className="fas fa-file-pdf"></i>
                                                                    Ver Documento
                                                                </a>
                                                            </td>
                                                            <td>
                                                                {item.EstadoRequisito==1 &&
                                                                    <Badge bg="success">Aprobado</Badge>
                                                                }
                                                                {item.EstadoRequisito==2 &&
                                                                    <Badge bg="danger">Rechazado</Badge>
                                                                }
                                                            </td>
                                                            <td>
                                                                {item.Posterior==1 &&
                                                                    <Button
                                                                        variant="danger"
                                                                        onClick={()=>{
                                                                            setModalEliminar(true)
                                                                            setItemActual(item)
                                                                        }}
                                                                    >
                                                                        Eliminar
                                                                    </Button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                        {/* adicionar documento de requisito */}
                                        <div style={{textAlign:"right"}} >
                                            <Button 
                                                variant="primary"
                                                onClick={()=>{
                                                    setModalDocumento(true)
                                                    setRequisito(item)
                                                }}
                                            >
                                                Adicionar Documento
                                            </Button>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>                    
                        )
                    })
                    }
                   </Accordion>
                }
                </>
                :
                <>
                    <Accordion>
                    {requisitos.map((item,index)=>{
                        return(                           
                            <Accordion.Item key={index} eventKey={index}>
                                <Accordion.Header>{item.Nombre}</Accordion.Header>
                                <Accordion.Body>
                                    <div style={{padding:30}}>
                                        <Table 
                                            striped
                                        >
                                            <thead>
                                                <tr> 
                                                    <th>N°</th>
                                                    <th>Fecha</th>
                                                    <th>Documento</th>
                                                    <th>Estado</th>
                                                    <th>Acciones</th>
                                                </tr> 
                                            </thead>
                                            <tbody>
                                                {item?.documentos.map((item,index)=>{
                                                    return(
                                                        <tr key={index}>
                                                            <td>{index+1}</td>
                                                            <td>{item?.FechaCreacion}</td>
                                                            <td>
                                                                <a  
                                                                    href={`${BASE_SERVIDOR}/pdf/${item.Archivo}`} 
                                                                    target="_blank" 
                                                                    rel="noreferrer"
                                                                >
                                                                    <i className="fas fa-file-pdf"></i>
                                                                    Ver Documento
                                                                </a>
                                                            </td>
                                                            <td>
                                                                {item.EstadoRequisito==1 &&
                                                                    <Badge bg="success">Aprobado</Badge>
                                                                }
                                                                {item.EstadoRequisito==2 &&
                                                                    <Badge bg="danger">Rechazado</Badge>
                                                                }
                                                            </td>
                                                            <td>
                                                                {item.Posterior==1 &&
                                                                    <Button
                                                                        variant="danger"
                                                                        onClick={()=>{
                                                                            setModalEliminar(true)
                                                                            setItemActual(item)
                                                                        }}
                                                                    >
                                                                        Eliminar
                                                                    </Button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                        {/* adicionar documento de requisito */}
                                        <div style={{textAlign:"right"}} >
                                            <Button 
                                                variant="primary"
                                                onClick={()=>{
                                                    setModalDocumento(true)
                                                    setRequisito(item)
                                                }}
                                            >
                                                Adicionar Documento
                                            </Button>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>                    
                        )
                    })
                    }
                   </Accordion>
                </>
                }
                </Tab>
                {/* fases y etapas */}
                <Tab eventKey="profile" title="Admisión">
                    {programa.IdDimension == 1 ?
                    <>
                        {estudiante.Semestre==1 &&
                            <Accordion>
                                {fases.map((item,index)=>{
                                    return(
                                        <Accordion.Item key={index} eventKey={index}>
                                            <Accordion.Header>{item.Nombre}</Accordion.Header>
                                            <Accordion.Body>
                                                <div style={{padding:30}}>
                                                    <div>
                                                        Tipo {' '}
                                                        { item?.IdTipoFase== 1 && "Documental"}
                                                        { item?.IdTipoFase== 2 && "Eliminatoria"}
                                                        { item?.IdTipoFase== 3 && "Acomulativa"}
                                                    </div>
                                                    <Table
                                                        striped
                                                    >
                                                        <thead>
                                                            <tr>
                                                                <th>N°</th>
                                                                <th>Fecha</th>
                                                                {item.IdTipoFase==1&&
                                                                    <th>Documento</th>
                                                                }
                                                                {item.IdTipoFase==2&&
                                                                    <th>Aprobó</th>
                                                                }
                                                                {item.IdTipoFase==3&&
                                                                    <th>Puntaje</th>
                                                                }    
                                                                <th>Acciones</th>                                                                                                            
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {item?.respuestas.map((respuesta,index)=>{
                                                                return(
                                                                    <tr key={index}>
                                                                        <td>{index+1}</td>
                                                                        <td>{respuesta?.FechaCreacion}</td>
                                                                        {item.IdTipoFase==1&&
                                                                            <td>
                                                                                {respuesta.Archivo&&
                                                                                    <a
                                                                                    href={`${BASE_SERVIDOR}/pdf/${respuesta.Archivo}`}
                                                                                    target="_blank"
                                                                                    rel="noreferrer"
                                                                                >
                                                                                    <i className="fas fa-file-pdf"></i>
                                                                                    Ver Documento
                                                                                </a>

                                                                                }                                                                   
                                                                            </td>
                                                                        }
                                                                        {item.IdTipoFase==2&&
                                                                            <td>{respuesta?.Aprobo==1?"Si":"No"}</td>
                                                                        }
                                                                        {item.IdTipoFase==3&&
                                                                            <td>{respuesta?.Puntaje}</td>
                                                                        }
                                                                        <td>
                                                                            {respuesta.Posterior==1 &&
                                                                                <Button
                                                                                    variant="danger"
                                                                                    onClick={()=>{
                                                                                        setModalEliminarFase(true)
                                                                                        setItemActual(respuesta)
                                                                                    }}
                                                                                >
                                                                                    Eliminar
                                                                                </Button>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                    {/* adicionar documento de requisito */}
                                                    <div style={{textAlign:"right"}} >
                                                        <Button
                                                            variant="primary"
                                                            onClick={()=>{
                                                                setModalFase(true)
                                                                setFase(item)
                                                            }}
                                                        >
                                                            Adicionar Respuesta
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )
                                })
                                }
                            </Accordion>
                        }
                    </>
                    :
                    <>
                        <Accordion>
                        {fases.map((item,index)=>{
                            return(
                                <Accordion.Item key={index} eventKey={index}>
                                    <Accordion.Header>{item.Nombre}</Accordion.Header>
                                    <Accordion.Body>
                                        <div style={{padding:30}}>
                                            <div>
                                                Tipo {' '}
                                                { item?.IdTipoFase== 1 && "Documental"}
                                                { item?.IdTipoFase== 2 && "Eliminatoria"}
                                                { item?.IdTipoFase== 3 && "Acomulativa"}
                                            </div>
                                            <Table
                                                striped
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>N°</th>
                                                        <th>Fecha</th>
                                                        {item.IdTipoFase==1&&
                                                            <th>Documento</th>
                                                        }
                                                        {item.IdTipoFase==2&&
                                                            <th>Aprobó</th>
                                                        }
                                                        {item.IdTipoFase==3&&
                                                            <th>Puntaje</th>
                                                        }    
                                                        <th>Acciones</th>                                                                                                            
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {item?.respuestas.map((respuesta,index)=>{
                                                        return(
                                                            <tr key={index}>
                                                                <td>{index+1}</td>
                                                                <td>{respuesta?.FechaCreacion}</td>
                                                                {item.IdTipoFase==1&&
                                                                    <td>
                                                                        {respuesta.Archivo&&
                                                                            <a
                                                                            href={`${BASE_SERVIDOR}/pdf/${respuesta.Archivo}`}
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                        >
                                                                            <i className="fas fa-file-pdf"></i>
                                                                            Ver Documento
                                                                        </a>

                                                                        }                                                                   
                                                                    </td>
                                                                }
                                                                {item.IdTipoFase==2&&
                                                                    <td>{respuesta?.Aprobo==1?"Si":"No"}</td>
                                                                }
                                                                {item.IdTipoFase==3&&
                                                                    <td>{respuesta?.Puntaje}</td>
                                                                }
                                                                <td>
                                                                    {respuesta.Posterior==1 &&
                                                                        <Button
                                                                            variant="danger"
                                                                            onClick={()=>{
                                                                                setModalEliminarFase(true)
                                                                                setItemActual(respuesta)
                                                                            }}
                                                                        >
                                                                            Eliminar
                                                                        </Button>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                            {/* adicionar documento de requisito */}
                                            <div style={{textAlign:"right"}} >
                                                <Button
                                                    variant="primary"
                                                    onClick={()=>{
                                                        setModalFase(true)
                                                        setFase(item)
                                                    }}
                                                >
                                                    Adicionar Respuesta
                                                </Button>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        })
                        }
                        </Accordion>
                    </>
                    }
                </Tab>
                <Tab eventKey="contact" title="Matrícula" >
                    <Accordion>
                        {requisitosMatricula.map((item,index)=>{
                            return(
                                <Accordion.Item key={index} eventKey={index}>
                                    <Accordion.Header>{item.Nombre}</Accordion.Header>
                                    <Accordion.Body>
                                        <div style={{padding:30}}>
                                            <Table
                                                striped
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>N°</th>
                                                        <th>Fecha</th>
                                                        <th>Documento</th>
                                                        <th>Acciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {item?.documentos.map((item,index)=>{
                                                        return(
                                                            <tr key={index}>
                                                                <td>{index+1}</td>
                                                                <td>{item?.FechaCreacion}</td>
                                                                <td>
                                                                    <a
                                                                        href={`${BASE_SERVIDOR}/pdf/${item.Archivo}`}
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        <i className="fas fa-file-pdf"></i>
                                                                        Ver Documento
                                                                    </a>
                                                                </td>
                                                                <td>
                                                                    {item.Posterior==1 &&
                                                                        <Button
                                                                            variant="danger"
                                                                            onClick={()=>{
                                                                                setModalEliminar(true)
                                                                                setItemActual(item)
                                                                            }}
                                                                        >
                                                                            Eliminar
                                                                        </Button>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                            {/* adicionar documento de requisito */}
                                            <div style={{textAlign:"right"}} >
                                                <Button
                                                    variant="primary"
                                                    onClick={()=>{
                                                        setModalDocumento(true)
                                                        setRequisito(item)
                                                    }}
                                                >
                                                    Adicionar Documento
                                                </Button>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        })
                        }
                    </Accordion>
                </Tab>
            </Tabs>
        </Card>
        {/* modal para cargar documento */}
        <Modal
            show={modalDocumento}
            onHide={()=>setModalDocumento()}
        >
            <Modal.Header closeButton>
                <Modal.Title>Adicionar Documento</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Documento</Form.Label>
                        <Form.Control
                            type="file"
                            onChange={(e)=>{
                                setArchivo(e.target.files[0])
                            }
                            }
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Documento de Soporte</Form.Label>
                        <Form.Control
                            type="file"
                            onChange={(e)=>{
                                setArchivoSoporte(e.target.files[0])
                            }
                            }
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={()=>{ setModalDocumento(false) }}>
                    Cerrar
                </Button>
                <Button
                    variant="primary"
                    onClick={()=>guardarDocumento()}
                >
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
        {/* modal para agregar una nueva respuesta a la fase */}
        <Modal
            show={modalFase}
            onHide={()=>setModalFase()}
        >
            <Modal.Header closeButton>
                <Modal.Title>Adicionar Respuesta</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {fase?.IdTipoFase==1&&
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Documento</Form.Label>
                        <Form.Control
                            type="file"
                            onChange={(e)=>{
                                setArchivo(e.target.files[0])
                            }
                            }
                        />
                    </Form.Group>
                    }
                    {fase?.IdTipoFase==2&&
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Aprobo</Form.Label>
                            <Form.Control
                                as="select"
                                onChange={(e)=>{
                                    setAprobo(e.target.value)
                                }
                                }
                            >
                                <option value="1">Si</option>
                                <option value="0">No</option>
                            </Form.Control>
                        </Form.Group>
                    }
                    {fase?.IdTipoFase==3&&
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Puntaje</Form.Label>
                            <Form.Control
                                type="number"
                                onChange={(e)=>{
                                    setPuntaje(e.target.value)
                                }
                                }
                            />
                        </Form.Group>
                    }
                    
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Observacion</Form.Label>
                        <Form.Control
                            as="textarea"
                            onChange={(e)=>{
                                setObservaciones(e.target.value)
                            }
                            }
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Soporte de Actualización</Form.Label>
                        <Form.Control
                            type="file"
                            onChange={(e)=>{
                                setArchivoSoporte(e.target.files[0])
                            }
                            }
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={()=>{ setModalFase(false) }}>
                    Cerrar
                </Button>
                <Button
                    variant="primary"
                    onClick={()=>guardarRespuesta()}
                >
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
        {/* modal para eliminar documetn */}
        <Modal
            show={modalEliminar}
            onHide={()=>setModalEliminar()}
        >
            <Modal.Header closeButton>
                <Modal.Title>Eliminar Documento</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>¿Está seguro que desea eliminar el documento?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={()=>{ setModalEliminar(false) }}>
                    Cerrar
                </Button>
                <Button
                    variant="danger"
                    onClick={()=>eliminarDocumento()}
                >
                    Eliminar
                </Button>
            </Modal.Footer>
        </Modal>
        {/* modal eliminar fase */}
        <Modal
            show={modalEliminarFase}
            onHide={()=>setModalEliminarFase()}
        >
            <Modal.Header closeButton>
                <Modal.Title>Eliminar Fase</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>¿Está seguro que desea eliminar la fase?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={()=>{ setModalEliminarFase(false) }}>
                    Cerrar
                </Button>
                <Button
                    variant="danger"
                    onClick={()=>eliminarFase()}
                >
                    Eliminar
                </Button>
            </Modal.Footer>
        </Modal>

    </>
    
  )
}
