import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tableData: [],
  searchFields: {},
  currentPage: 1,
  totalPages: 1,
};

export const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
    setSearchFields: (state, action) => {
      state.searchFields = action.payload;
    },
    incrementPage: (state) => {
      state.currentPage += 1;
    },
    decrementPage: (state) => {
      state.currentPage -= 1;
    },
    resetPage: (state) => {
      state.currentPage = 1;
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
  },
});

export const { setTableData, setSearchFields, incrementPage, decrementPage, resetPage, setTotalPages } = tableSlice.actions;

export default tableSlice.reducer;
