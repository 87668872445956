import { Route, Routes } from 'react-router-dom'
import { Docentes } from './Docentes'
import {Docente} from './Docente'
import { AsignacionDocente } from './AsignacionDocente'
function GestionDocentes() {
    return (
        <Routes>
            <Route path='docentes' element={<Docentes/>} />
            <Route path='docentes/docente' element ={<Docente/>} />
            <Route path='docentes/docente/asignacion_docente' element={<AsignacionDocente/>}/>
        </Routes>
    )
}

export {GestionDocentes}
