import { Route, Routes, useNavigate } from "react-router-dom";
import { DynamicFormContainer } from "../../../components/DynamicForm/DynamicFormContainer";
import { AsignaturaProfesorContainer } from "../../../components/outlet/asignaturaProfesor/AsignaturaProfesorContainer";
import Calendar from "./CalendarioAcademico/Calendar";
import { CalendarioAcademicoContainer } from "./CalendarioAcademico/CalendarioAcademicoContainer";
import { CalendarioAulasContainer } from "./CalendarioAcademico/CalendarioAulasContainer";
import { BASE_URL } from "../../../services/api/config";

function PlaneacionAcademica (){
// const isAdmin
  const navigate=useNavigate();

  const descargar = (e) => {
    // abrir ventana de descarga
    window.open(`${BASE_URL}/aulas/exportarAula?IdAula=${e.IdAula}`, "_blank");
  }

  return(
    <>
    <Routes>
      {/* PLANEACION ACADEMICA /planeacion_academica */}
      <Route key={"aulas"} path="aulas" 
        element = {
        <DynamicFormContainer
          idForm="36"
          handleOptionClick={(e)=>descargar(e)}
          labelOptionClick={"Exportar Información"}
      />}
      ></Route>
      <Route  path="asignatura_docente" element = {<AsignaturaProfesorContainer />}></Route>
      <Route  path="edificio" element = {<DynamicFormContainer idForm="35"/>}></Route>
      <Route  path="calendario_academico" element = {<CalendarioAcademicoContainer />}></Route>
      <Route  path="calendario_academico/aulas" element = {<CalendarioAulasContainer />}></Route>
      <Route  path="calendario_academico/aulas/:id/calendario" element = {<Calendar editable/>}></Route>
      <Route  path="*" element = {<DynamicFormContainer />}></Route>
    </Routes>
    </>
  )
}

export { PlaneacionAcademica }