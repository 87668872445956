
function TopHeadTable ({title='empty',bgColor='#005AA6',children,width='100%'}){
  return(
    <>
    <div 
    className="justify-content-center" 
    style={
      {
        backgroundColor:`${bgColor}`,
        width:`${width}`,
        color:'white',
        borderRadius:'0.5rem 0.5rem 0rem 0rem',
        padding:15,
      }
    }
      >{children || title}</div>
    </>
  )
}
export { TopHeadTable }