//crear el componente funcional 
import React, { useState } from 'react';


import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DynamicForm } from '../../../components/DynamicForm/DynamicForm';
import { DynamicTable } from '../../../components/DynamicForm/dynamicTable/DynamicTable';
import { FormService } from '../../../services/api/formService';
import { useDispatch, useSelector } from 'react-redux';
import { setForm, setItemSelected } from '../../../components/DynamicForm/FormSlice';
import { alerta } from '../../../helpers/alertas';


function HorariosLight () {
    const api= new FormService();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const oferta = location.state?.oferta;
    const [modal, setModal] = useState(false);
    const [modalActulizar, setModalActualizar] = useState(false);
    const [modalEliminar, setModalEliminar] =useState(false); 
    const urlProgramas = `horarios?IdOferta=${oferta.IdOferta}`; 
    const [endPoint, setEndPoint] = useState(urlProgramas);
    // oteber itemSelected de fomrSlice
    const horario = useSelector(state => state.form.itemSelected);


    const cerrarModal = () => {
        setTimeout(() => {
            setModal(false);
            setModalActualizar(false);
            setEndPoint(`${urlProgramas}&i${Math.random()}`);
        }, 3000);
    }
    const eliminarHorario =async() => {
        const data={
            "Estado":"0"
        }
        const respuesta= await api.update(`horarios`,data,horario.IdHorario);
        if(respuesta){
            setModalEliminar(false);
            setEndPoint(`${urlProgramas}&i${Math.random()}`);
        }else{
            alerta("Fallo desconocido");
        }
    }


    return (
        <>
        <Card>
            <Row>
                <Col sm="8">
                    <Card.Title>Horarios / {oferta?.Nombre}</Card.Title>
                </Col>
                <Col style={{textAlign:"right"}}>
                    <Button 
                        variant="primary"
                        onClick={()=>setModal(true)}
                    >
                        Crear
                    </Button> 
                </Col>
            </Row>
           
        </Card>
        <DynamicTable
            titleColumn={['N°','Nombre','Fecha Inicio','Fecha Fin', 'Acción']}
            attributes={['Nombre','FechaInicio','FechaFin']}
            endPoint={endPoint}
            elimimarExterno={(e)=>{
                setModalEliminar(true);
                dispatch(setItemSelected(e))
            }}
            actualizarExterno={(e)=>{
                setModalActualizar(true);
                dispatch(setItemSelected(e))
                dispatch(setForm({llave_primaria:"IdHorario",form_data:"0"}))
                
            }}
            labelOptionClick={`Ver más`}
            handleOptionClick={(e)=>navigate("horario", {state:{horario:e,oferta:oferta}})}
        />
    
        <Modal 
            show={modal} 
            onHide={()=>setModal(false)}
        >
            <Modal.Body>
                <DynamicForm 
                    title="Crear Horario"
                    endPointSubmit='horarios'
                    extraData={{IdOferta:oferta?.IdOferta}}
                    justDynamicForm={{flag:true,idForm:122}}
                    onClickCancelBtn={()=>setModal(false)}
                    isContentTypeFormData={"0"}
                    accionMysql={()=>cerrarModal()}
                    isCreate={true}
                />  
            </Modal.Body>
        </Modal>
        {/* modal para actuliazar */}
        <Modal
            show={modalActulizar}
            onHide={()=>setModalActualizar(false)}
        >
            <Modal.Body>
                <DynamicForm
                    title="Actualizar Horario"
                    endPointSubmit={`horarios`}
                    justDynamicForm={{flag:true,idForm:122}}
                    onClickCancelBtn={()=>setModalActualizar(false)}
                    isContentTypeFormData={"0"}
                    accionMysql={()=>cerrarModal()}
                    isCreate={false}
                />
            </Modal.Body>
        </Modal>


        {/* modal para eliminar horario */}
        <Modal
            show={modalEliminar}
            onHide={()=>setModalEliminar(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>Eliminar Horario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>¿Está seguro que desea eliminar el horario?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={()=>setModal(false)}>
                    Cancelar
                </Button>
                <Button variant="primary" onClick={()=>eliminarHorario(false)}>
                    Eliminar
                </Button>
            </Modal.Footer>
        </Modal>

        </>
    )
}

export { HorariosLight }


