import React, { useState } from 'react'
import { Button } from '../../button/button'

import './NavTag.css'

const NavTag = ({
    onChangeWindow,
    windowSelector,
    isCompletedDocuments,
    isCompletedAdmission,
    isCompletedEnrollment,
    setIsIncompleted,
    requiereInscripcion,
    isModuloFull,
    lock,
}) => {

    const classDocument = isCompletedDocuments == '1' 
    ? 'nav-tag__completed' 
    : 'nav-tag__not-completed'
    const classAdmission = isCompletedAdmission == '1' 
    ? 'nav-tag__completed' 
    : 'nav-tag__not-completed'
    const classEnrollment = isCompletedEnrollment == '1' 
    ? 'nav-tag__completed' 
    : 'nav-tag__not-completed'

    const onAdmission = () => {
        if (lock) {
            if(isCompletedDocuments == '1') onChangeWindow(2) 
            else {
                setIsIncompleted(true)
                setTimeout(() => {
                    setIsIncompleted(false)
                }, 2000)
            }
        } else {
            onChangeWindow(2)
        }
    }

    const onEnroll = () => {
        if (lock) {
            if(isCompletedAdmission == '1') onChangeWindow(3)
            else {
                setIsIncompleted(true)
                setTimeout(() => {
                    setIsIncompleted(false)
                }, 2000)
            }
        } else {
            onChangeWindow(3)
        }
    }

    return (
        <nav className='nav-tag__nav-window'>
            <ul className='nav-tag__ul-window'>
                { requiereInscripcion == '1' && (
                        <li className='nav-tag__li'>
                            <div className='nav-tag__steps-progress-bar'>
                                <div className={`nav-tag__node ${classDocument}`}></div>
                                <div className='nav-tag__edge'></div>
                            </div>
                            <Button 
                                onClick={ () => onChangeWindow(1) }
                                className={
                                    windowSelector == 1
                                    ? `nav-tag__nav-window-item nav-tag__nav-window-item--active`
                                    : `nav-tag__nav-window-item`
                                }
                            >
                                <span>
                                    Documentos
                                </span>
                            </Button>
                        </li>
                    )
                }
                <li className='nav-tag__li'>
                   <div className='nav-tag__steps-progress-bar'>
                        <div className={`nav-tag__node ${classAdmission}`}></div>
                        {
                            isModuloFull == 1 && (
                                <div className='nav-tag__edge'></div>
                            )
                        }
                    </div>
                    <Button 
                        onClick={ onAdmission }
                        className={
                            windowSelector == 2
                            ? `nav-tag__nav-window-item nav-tag__nav-window-item--active`
                            : `nav-tag__nav-window-item`
                        }
                    >
                        <span>
                            Admisión
                        </span>
                    </Button>
                </li>
                {
                    isModuloFull == 1 && (
                        <li className='nav-tag__li'>
                        <div className='nav-tag__steps-progress-bar'>
                                <div className={`nav-tag__node ${classEnrollment}`}></div>
                            </div>
                            <Button 
                                onClick={ onEnroll }
                                className={
                                    windowSelector == 3
                                    ? `nav-tag__nav-window-item nav-tag__nav-window-item--active`
                                    : `nav-tag__nav-window-item`
                                }
                            >
                                <span>
                                    Matrícula
                                </span>
                            </Button>
                        </li>
                    )
                }
            </ul>
        </nav>
    )
}

export { NavTag }