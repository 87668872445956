import { useState } from "react";
import { Card } from "react-bootstrap";
import { JobOfferCard } from "../../components/JobOfferCard/JobOfferCard";
import { Title } from "../../shared/components/title/Title";
import { FormService } from "../../services/api/formService";
import { useEffect } from "react";


const OfertasEmpleo=()=>{
    const api= new FormService();
    const[ofertas,setOfertas]=useState([]);

    useEffect(()=>{
        const fetchData=async()=>{
            ofertasGet()
        }
        fetchData()

        // return fetchData()
    },[])

    const ofertasGet=async()=>{
        const ofertas=await api.getAll("ofertasTrabajo")
        setOfertas(ofertas)
    }
    console.log(ofertas)
    return(
        <>
        <Card>
            <Title>
                Ofertas de Empleo
            </Title>
        </Card>
            {
                ofertas.map((oferta,key)=>
                    <JobOfferCard
                        key={key}
                        nombre={oferta.Nombre}
                        descripcion={oferta.Descripcion}
                        lugar={oferta.Lugar}
                        empresa={oferta.Empresa}
                        telefono={oferta.Telefono}
                        email={oferta.Email}
                        imagen={oferta.Imagen}
                    />
                )
            }
        </>
    )

}

export {OfertasEmpleo};