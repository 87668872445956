import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { FormService } from '../../../services/api/formService'
import { onCloseModals, onRefreshTableToggle } from '../FormSlice'

export function useSubmitDelete(
    endPointSubmit,
    keySubmit,
    extraData,
    isContentTypeFormData,
    urlWithParams
) {
    const [statusDelete, setStatusDelete] = useState(' ')
    const [responseDataDelete, setResponseDataDelete] = useState([])
    const dispatch = useDispatch()
    const formService = new FormService()

    const camposForm = useSelector((state) => state.form.fields)
    const formSnapshot = useSelector((state) => state.form.formSnapshot)
    const itemSelected = useSelector((state) => state.form.itemSelected)
    const historyForm = useSelector((state) => state.form.historyForm)
    const location = useLocation()

    const fetchDataDelete = async (data) => {
        setStatusDelete('Cargando...')
        // let datafinal={}
        // const obj = insertExtraDataViaHistoryForm(data,historyForm,location.pathname)
        // datafinal = {...data,...obj}
        try {
            let dataResponse = null
            let dataCleaned = cleanFormData(camposForm, data)
            // if(extraData){
            //   dataResponse = await formService.delete(endPointSubmit,{...extraData,...formData,Estado:0},formData[keySubmit]) //(endPointSubmit,formData)

            // }else{
            //   dataResponse = await formService.delete(endPointSubmit,{...formData, Estado:0},formData[keySubmit])

            // }
            dataResponse = await formService.delete(
                endPointSubmit,
                {
                    ...dataCleaned,
                    Estado: 0,
                },
                data[keySubmit]
            )
            setResponseDataDelete(dataResponse)
            setStatusDelete('Exito') //to validate if response is result ok etc
            dispatch(onCloseModals())
            dispatch(onRefreshTableToggle()) //so update dynamictable via  props
        } catch (err) {
            setResponseDataDelete(err)
            setStatusDelete(
                'Error: ' +
                    err?.message +
                    '. LOG: ' +
                    err.response?.data?.message
            )
        }
    }

    const cleanFormData = (campos, formData) => {
        console.log('ORIGINAL DATA', formData)
        let formDataCleaned = {}
        for (let campo of campos) {
            if (campo.render_form === '1') {
                for (const property in formData) {
                    if (campo.name === property)
                        formDataCleaned[property] = formData[property]
                }
            }
        }
        formDataCleaned[formSnapshot.llave_primaria] =
            formData[formSnapshot.llave_primaria]

        return formDataCleaned
    }

    return {
        fetchDataDelete,
        statusDelete,
        responseDataDelete,
    }
}
