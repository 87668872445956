import React, { useEffect, useState } from 'react'
import { Button, Card, Table, Modal } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { BASE_URL } from '../../../services/api/config'
import { FormService } from '../../../services/api/formService'
import { DynamicFormContainer } from "../../../components/DynamicForm/DynamicFormContainer"
import { DynamicTable } from "../../../components/DynamicForm/dynamicTable/DynamicTable"
import Header from './Componentes/Header'

export default function ActividadesBienestar() {
    const {state:{usuario}}=useLocation()
    const api= new FormService();
    const [showModal, setShowModal] = useState(false); // Estado para controlar la apertura/cierre del modal
    const [selectedActivityId, setSelectedActivityId] = useState(null); // Estado para almacenar el ID de la actividad seleccionada
    const infoActivity = useState(null); // Estado para almacenar la informacion de la Actividad
    const handleButtonClick = (item) => {
        // Lógica del botón
        console.log('Botón clickeado:', item);
        setSelectedActivityId(item.IdActividadBienestar); // Almacenar el ID de la actividad seleccionada
        setShowModal(true); // Abrir el modal al hacer clic en el botón
    };
    const handleCloseModal = () => {
        setShowModal(false); // Cerrar el modal
        setSelectedActivityId(null); // Reiniciar el ID de la actividad seleccionada
    };
    const actividadesBienestar = `actividadesBienestarTodo`
    const actividadesBienestarId = `actividadesBienestarTodoInfo?IdActividadBienestar=${selectedActivityId}`
  return (
    <>
    <div style={{maxWidth:1000,margin:"0 auto",marginTop:40}}>
        <Header usuario={usuario}/>
        <Card>
            <Card.Title>Actividades Bienestar</Card.Title>
        </Card>
        <DynamicTable
            titleColumn={['N°','Descripción','Fecha Inicio','Acciones']}
            attributes={['Descripcion','Fecha']}
            endPoint={actividadesBienestar}
            onDelete={false}
            onUpdate={false}
            onActions={true}
            handleOptionClick={handleButtonClick} 
            labelOptionClick="Ver Detalles" 
            classNameOptionIcon="bi bi-search" 
        />
        <Modal
            show={showModal}
            onHide={handleCloseModal}
            fullscreen={true}
            >
              <Modal.Header closeButton>
                <Modal.Title>Detalles de la Actividad</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <DynamicTable
                    titleColumn={['Descripción','Tipo Actividad Bienestar','Fecha Inicio','Fecha Finalización','Número Participantes','Lugar','Pais Financiador','Código Unidad Organizacional']}
                    attributes={['Descripcion','TipoActividadBienestar','Fecha','FechaII','NumeroParticipantes','Lugar','PaisFinanciador','CodigoUnidadOrganizacional']}
                    endPoint={actividadesBienestarId}
                    onDelete={false}
                    onUpdate={false}
                    onActions={false}
                    showPagination={false} // Deshabilitar paginado
                    showIndexColumn={false}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  Cerrar
                </Button>
              </Modal.Footer>
        </Modal>
    </div>
    </>
  )
}
