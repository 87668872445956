import { useDispatch, useSelector } from 'react-redux'
import { onCloseModals } from './FormSlice'
import { useSubmitDelete } from './hooks/useSubmitDelete'
import { Button, Modal } from 'react-bootstrap'

export function DeleteDialog({
    isContentTypeFormData = '0',
    endPoint,
    keySubmit,
    parentField,
}) {
    const itemSelected = useSelector((state) => state.form.itemSelected)
    const { fetchDataDelete, statusDelete, responseDataDelete } =
        useSubmitDelete(endPoint, keySubmit, parentField, isContentTypeFormData)
    const dispatch = useDispatch()
    const onClickDelete = async () => {
        await fetchDataDelete(itemSelected)
    }

    return (
        <>
        <Modal.Header closeButton>
            <Modal.Title>
            Deseas eliminar"
                    {itemSelected?.Nombre ||
                        itemSelected?.Descripcion ||
                        itemSelected?.ApeNombres ||
                        'este elemento'}
                {' '}
                " ?
            </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
            <Button
                onClick={() => dispatch(onCloseModals())}
                variant="secondary"
            >
                Cancelar
            </Button>
            <Button
                onClick={() => onClickDelete(itemSelected)}
                type="danger"
            >
                Aceptar
            </Button>
        </Modal.Footer>
        </>
    )
}
