import { useEffect } from 'react'
import { useState } from 'react'
import { Button } from '../../../../components/button/button'
import { Checkbox } from '../../../../components/componentsForm/checkbox/checkbox'
import { Input } from '../../../../components/input/input'
import { Select } from '../../../../components/select/Select'
import { useFetchGet } from '../../../../hooks/useFetchGet'
import { optionsDate } from '../../../../shared/util/date'
import { AddNewDates } from './parts/AddNewDates'



function FormAsignarDocenteAula({
    dateStrSelected,
    itemSelected,
    onAddEvent,
    loading,
    onCloseModal,
}) {
    const [formData, setFormData] = useState({
        horaInicial: '',
        horaFinal: '',
        IdPlanEstudio: '',
        IdUsuario: '',
        fechaInicial: dateStrSelected,
        fechaFinal: dateStrSelected,
        Observaciones: '',
        fechas: [],
        IdMalla: '',
    })
    const [showAsignatura, setShowAsignatura] = useState(false)
    const [showAddFechas, setShowAddFechas] = useState(false)
    const [listadoFechas, setListadoFechas] = useState([])
    const [newDate, setNewDate] = useState(null)
    const [ofertas, loadingOferta, error] = useFetchGet('ofertas')

    useEffect(() => {
        // setFormData({...formData,horaInicial:dateStrSelected})
        console.log('form asignatura', { itemSelected })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading])
    const handleSubmit = async (e) => {
        e.preventDefault()
        // setFormData({[name],value})
        // setFormData(e.target.currentTarget)
        // console.log(e.target.currentTarget)
        // setFormData({...formData,})
        // setListadoFechas((listadoFechas)=>[...listadoFechas,formData.fechaInicial])
        const idOfertaSelected = ofertas.find(
            (element) => element.IdPlanEstudio === formData.IdPlanEstudio
        )
        console.log(
            ofertas,
            idOfertaSelected,
            'oferta selected',
            formData.IdMalla
        )
        const data = {
            IdAula: itemSelected.item.IdAula,
            // IdAula:'1',
            IdOferta: idOfertaSelected?.IdOferta,
            IdMalla: formData.IdMalla,
            IdUsuario: formData.IdUsuario,
            Observaciones: formData.Observaciones,
            // FechaInicio:`${formData.fechaInicial} ${formData.horaInicial}:00`,
            // FechaFin:`${formData.fechaFinal} ${formData.horaFinal}:00`,
            horaInicial: `${formData.horaInicial}:00`,
            horaFinal: `${formData.horaFinal}:00`,
            fechas: [...listadoFechas, formData.fechaInicial],
            // Estado:'1',
            // FechaCreacion:null
        }
        console.log({ data }, { formData })
        onAddEvent(data, formData)
        // {"IdAula":"7",
        //     "IdMalla": "1",
        //     "IdUsuario": "17",
        //     "Observaciones": "registrando materia horario evento",
        //     "FechaInicio": "2022-06-23T12:30:00",
        //     "FechaFin": "2022-06-23T17:30:00",
        //     "FechaCreacion":null,
        //     "Estado": "0"
        // }
    }
    const onHandleChange = (value, name) => {
        if (name === 'masFechas' && value === true) {
            console.log('fechasmas', value, name)
            setShowAddFechas(true)
        }
        if (name === 'masFechas' && value === false) {
            console.log('fechasmas', value, name)
            setShowAddFechas(false)
            setListadoFechas([])
        }
        if (name === 'IdPlanEstudio') {
            setShowAsignatura(true)
        }
        setFormData({ ...formData, [name]: value })
    }
    const deleteNewDate = (indexSelected, fechaSelected) => {
        let copyListadoFechas = listadoFechas
        const filtered = copyListadoFechas.filter(
            (fecha, index) => index !== indexSelected
        )
        setListadoFechas([...filtered])
    }
    const onAddNewDate = () => {
        const dateAlreadyExist = listadoFechas.find(
            (fecha) => fecha === newDate
        )
        console.log(newDate, listadoFechas, dateAlreadyExist)
        if (
            newDate !== null &&
            dateAlreadyExist === undefined &&
            newDate !== ''
        ) {
            setListadoFechas([...listadoFechas, newDate])
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <h2>
                    {' '}
                    Aula {itemSelected.item.numero},{' '}
                    {itemSelected?.item.Edificio}
                </h2>
                <h4 style={{ color: 'gray' }}>
                    <i class="bi bi-calendar"></i>{' '}
                    {
                        // itemSelected?.date.toLocaleTimeString('es-co')
                        itemSelected.date.toLocaleDateString(
                            'es-co',
                            optionsDate
                        )
                    }
                </h4>

                <Select
                    label={'Ofertas'}
                    name={'IdPlanEstudio'}
                    onChange={onHandleChange}
                    value={formData.IdPlanEstudio}
                    optionName={'Nombre'}
                    optionValue={'IdPlanEstudio'}
                    // urlOptionsService={ofertas}
                    options={ofertas}
                ></Select>

                {showAsignatura && (
                    <Select
                        label={'Asignatura'}
                        name={'IdMalla'}
                        onChange={onHandleChange}
                        value={formData.IdMalla}
                        optionName={'Asignatura'}
                        optionValue={'IdMalla'}
                        urlOptionsService={`malla?IdPlandeEstudio=${formData.IdPlanEstudio}`}
                        //  urlOptionsService={`asignatura/${formData?.planEstudio || ''}`}
                    ></Select>
                )}
                <Select
                    label={'Responsable'}
                    name={'IdUsuario'}
                    onChange={onHandleChange}
                    value={formData.IdUsuario}
                    optionName={'PrimerNombres'}
                    optionValue={'IdUsuario'}
                    urlOptionsService={'docente'}
                ></Select>
                <div className="form-group m-1 row">
                    <span className="mb-2 mt-3 fw-bold"> Horario </span>
                    <div className="col-6">
                        <div className="input-group mb-3">
                            <span
                                className="input-group-text"
                                id="hora-inicial"
                            >
                                <i class="bi bi-calendar"></i> Hora inicial
                            </span>
                            <input
                                type="time"
                                className="form-control"
                                placeholder="hora inicial"
                                aria-label="hora-inicial"
                                step="600"
                                value={formData.horaInicial}
                                name="horaInicial"
                                aria-describedby="hora-inicial"
                                onChange={(e) =>
                                    onHandleChange(
                                        e.target.value,
                                        e.target.name
                                    )
                                }
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="hora-final">
                                <i class="bi bi-calendar"></i> Hora final
                            </span>
                            <input
                                type="time"
                                step="600"
                                className="form-control"
                                placeholder="hora final"
                                aria-label="horaInicial"
                                value={formData.horaFinal}
                                name="horaFinal"
                                aria-describedby="hora-final"
                                onChange={(e) =>
                                    onHandleChange(
                                        e.target.value,
                                        e.target.name
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>
                {/* <Input
      type={'date'}
      value={formData?.fechaInicial}
      name='fechaInicial'
      label={'Fecha Inicial'}
      onChange={onHandleChange}
      disabled={true}
      ></Input>*/}
                <Checkbox
                    label={'Repetir semanalmente para todo el semestre'}
                    name={'masFechas'}
                    value={formData.repetir === '1' ? true : false}
                    onChange={onHandleChange}
                >
                    Repetir para otras fechas
                </Checkbox>
                {showAddFechas && (
                    <AddNewDates
                        dateStrSelected={dateStrSelected}
                        itemSelected={itemSelected}
                        setNewDate={setNewDate}
                        listadoFechas={listadoFechas}
                        deleteNewDate={deleteNewDate}
                        onAddNewDate={onAddNewDate}
                    />
                )}
                {/* <Checkbox
      label={'Repetir semanalmente para todo el semestre'}
      name={'repetir'}
      //  value={formData.repetir==='1'?true:false}
      // onChange={onHandleChange}
      >Repetir para cada semana durante el semestre actual</Checkbox> */}
                <Input
                    label={'Observaciones'}
                    type={'textarea'}
                    value={formData?.Observaciones}
                    name="Observaciones"
                    onChange={onHandleChange}
                    required={false}
                ></Input>
                <div className="d-flex  justify-content-end">
                    <Button
                        onClick={onCloseModal}
                        type={'back'}
                        // className='btn btn-secondary'
                    >
                        {' '}
                        Cancelar{' '}
                    </Button>
                    <Button type={'send'}>
                        {' '}
                        Guardar evento{' '}
                        {loading && (
                            <div
                                className="spinner-border spinner-border-sm text-light"
                                role="status"
                            >
                                <span className="sr-only"></span>
                            </div>
                        )}
                    </Button>
                </div>
            </form>
        </>
    )
}

export { FormAsignarDocenteAula }
