import React, { useState } from 'react';
import { Card, Table, Form, Button, Col, Row, Dropdown, Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import TablePaginate from '../../components/TablePaginate/TablePaginate';
import { setSearchFields } from '../../shared/store/table/tableSlice';
import Swal from 'sweetalert2';

export default function EmisionGrados() {
  const dispatch = useDispatch();
  const searchFieldsRedux = useSelector(state => state.table.searchFields);
  const [searchFields2, setSearchFields2] = useState({...searchFieldsRedux});
  const [showModal, setShowModal] = useState(false);

  const handleSearchChange = event => {
    setSearchFields2({ ...searchFields2, [event.target.name]: event.target.value });
  };

  const handleSearch = () => {
    console.log(searchFields2);
    dispatch(setSearchFields({ ...searchFields2 }));
  };

    const handleAction1 = (rowData) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: 'Esta acción no se puede deshacer',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
          }).then((result) => {
            if (result.isConfirmed) {
              // El usuario ha aceptado la confirmación
              // Realiza la acción deseada
              console.log('Confirmación aceptada');
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              // El usuario ha cancelado la confirmación
              console.log('Confirmación cancelada');
            }
          });    
    };
    
      // Acciones personalizadas
      const rowActions = [
        ({ rowData }) => (
          <Button variant="primary" onClick={() => handleAction1(rowData)}>
            Graduar
          </Button>
        )
      ];

  return (
    <>
      <Card>
        <Card.Title>Emisión de grados</Card.Title>
      </Card>
      <div
        style={{
            padding:10
        }}
      >
        <Row>
            <Col>
                <Form.Group controlId="searchName">
                <Form.Label>Nombre:</Form.Label>
                <Form.Control
                    type="text"
                    name="NombreCompleto"
                    value={searchFields2.name}
                    onChange={handleSearchChange}
                />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group controlId="searchEmail">
                <Form.Label>Numero de documento:</Form.Label>
                <Form.Control
                    type="text"
                    name="NumeroDocumento"
                    value={searchFields2.email}
                    onChange={handleSearchChange}
                />
                </Form.Group>
            </Col>
            <Col style={{ display: 'flex', alignItems: 'flex-end'}}>
                <Button variant="primary" onClick={handleSearch}>
                    Buscar
                </Button>
            </Col>
        </Row>
      </div>
        <TablePaginate
          apiUrl="convocatoriaGrados/inscritosAprovados"
          tableHeaders={[
            { title: 'Nombre', attribute: 'Nombre' },
            { title: 'Programa', attribute: 'Curso' }
          ]}
          rowActions={rowActions}
        />
    </>
  );
}
