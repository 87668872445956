import React, { useState } from 'react'
import { Button } from '../button/button'
import { Message } from '../Message/Message'
import { FormService } from '../../services/api/formService'
import { useNavigate } from 'react-router-dom'
import { alertaSinBoton } from '../../helpers/alertas'
import notFoundImgCourse from '../../assets/img/img_curso.png'
import { Modal } from '../modal/Modal'

import './OfferHeader.css'
import { BASE_URL_REPOSITORIO } from '../../services/api/config'

const ESTADO_CURSO = {
    ACTIVO:'2',
    FINALIZADO:'3'
}

const OfferHeader = ({ 
    img, 
    offerName = '[Curso sin nombre]',
    offerDescription = '[No se ha encontrado la descripción del curso]',
    idOffer,
    convert,
    isFinally,
    mechanism,
    type,
    business,
    IdOferta,
    horas,
    oferta,
    empresa
}) => {

    const [classText, setClassText] = useState('offer-header__limit-text')
    const [nameButton, setNameButton] = useState('Ver más')
    const [isOpenModalConvertToCourse, setIsOpenModalConvertToCourse] = useState(false)
    const [isOpenModalBusiness, setIsOpenModalBusiness] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const navigate = useNavigate()
    const PATH_IMG = BASE_URL_REPOSITORIO
    const srcCourse = img ? `${PATH_IMG}${img}` : notFoundImgCourse

    const toggle = () => {
        setClassText(
            classText === 'offer-header__limit-text' 
            ? '' 
            : 'offer-header__limit-text'
        )
        setNameButton(
            nameButton === 'Ver más' ? 'Ver menos' : 'Ver más'
        )
    }

    const handleChangeToCourse = async () => {
        setError(null)
        const ORDER = isFinally ? ESTADO_CURSO.FINALIZADO : ESTADO_CURSO.ACTIVO
        try {
            const formService = new FormService()
            const data = {
                IdEstadoOferta: ORDER
            }
            setLoading(true)
            await formService.update('ofertas', data, idOffer)
            setIsOpenModalConvertToCourse(false)
            navigate(-1)
            alertaSinBoton('Curso establecido', 'success', 'bottom-end', 2000)
            setLoading(false)
        } catch (err) {
            setLoading(false)
            setError(err)
        }
    }

    const onHandleClickToCourse = () => {
        setIsOpenModalConvertToCourse(!isOpenModalConvertToCourse)
    }

    const onHandleOpenModalBusiness = () => {
        setIsOpenModalBusiness(!isOpenModalBusiness)
    }
    return (
        <>
            <div className='offer-header'>
                <figure className='offer-header__img-container'>
                    <img
                        className='offer-header__img'
                        src={ srcCourse }
                        alt='Imagen representativa de la oferta' 
                    />
                </figure>
                <section>
                    <section className='offer-header__title'>
                        <section className='offer-header__controller'>
                            {empresa?.Nombre &&
                                <div>Empresa gestora:<b> {empresa?.Nombre} </b></div>
                            }
                            {oferta?.modo_admin==1 && 
                                    <Button 
                                        type='outline-primary'
                                        onClick={ onHandleClickToCourse }
                                        className='offer-header__button'
                                    >
    
                                        {oferta.IdDimension==1
                                            ? isFinally?'Finalizar':'Activar' 
                                            : isFinally? 'Finalizar curso':'Establecer como curso'
                                        }
                                    </Button>  
                            }
                        </section>
                        
                        <h2 className='fw-bold'>
                            { oferta.NombrePlanEstudio}
                            {  oferta.IdDimension!=1&&
                                <>-{oferta?.codigo_grupo}</>
                            }
                        </h2>
                        <section className='d-flex'>
                            {oferta.IdDimension==1&&
                                <div className='p-2'> 
                                <strong>SNIES</strong>: {oferta?.SNIES} 
                                </div> 
                            }
                            <div className='p-2'>
                                {oferta.IdDimension==1?
                                    <div><strong>Créditos: </strong> {oferta.Creditos}</div>
                                    :
                                    <>
                                        <strong>Intensidad: </strong>
                                        {horas}
                                    </>
                                }
                             </div>
                        </section>
                            {oferta.IdDimension==1&&
                                <div className='p-2'>
                                <strong>Cohorte: </strong>: {oferta?.codigo_grupo}
                                </div>
                            }
                        <section className='d-flex'>
                            <div className='p-2'> <strong>Fecha Inicial</strong>: {oferta?.FechaInicioCurso} {'  '}</div>                            
                            <div className='p-2'>
                                <strong>Fecha Final: </strong>{oferta?.FechaFinCurso}
                             </div>
                        </section>
                        {
                            mechanism && (
                                <p className='mb-1'>{`#OfertaPor${mechanism}`}</p>
                            )
                        }
                        {
                            business?.Nombre && (
                                <p>
                                    Por <Button 
                                        type='link'
                                        className='m-0 p-0'
                                        onClick={ onHandleOpenModalBusiness }>
                                            { business?.Nombre }
                                    </Button>
                                </p>
                            )
                        }
                    </section>
                    <section>
                        <span className={classText}>
                            { offerDescription || '' }
                        </span>
                        {
                            !!offerDescription && offerDescription.length > 340 && (
                                <Button 
                                    type='link'
                                    onClick={ toggle }
                                    className='m-0 p-0'
                                >
                                    { nameButton }
                                </Button>
                            )
                        }
                        {
                            error && (
                                <Message type='error'>
                                    { error.message }
                                </Message>
                            )
                        }
                    </section>
                </section>
            </div>
            <Modal 
                isOpen={ isOpenModalBusiness }
                close={ onHandleOpenModalBusiness }
            >
                <section>
                    <h2>
                        { business?.Nombre }
                    </h2>
                    <div className='profile-header__data-user'>
                        <section className='profile-header__data'>
                            <h6>Nit</h6>
                            <h5>{ business?.Nit || '' }</h5>
                        </section>
                        <section className='profile-header__data'>
                            <h6>Dirección</h6>
                            <h5>{ business?.Direccion || ''}</h5>
                        </section>
                        <section className='profile-header__data'>
                            <h6>Fecha de registro</h6>
                            <h5>{ business?.FechaRegistro || '' }</h5>
                        </section>
                    </div>
                </section>
            </Modal>
            <Modal 
                isOpen={ isOpenModalConvertToCourse }
                close={ onHandleClickToCourse }
            >
                <div className='offer-header__modal'>
                    <h4>
                        {
                            isFinally 
                            ? '¿Estás seguro de finalizar este curso?'
                            : '¿Estás seguro de establecer esta oferta como curso?'                        
                        }
                    </h4>
                    <section className='offer-header__buttons-to-course'>
                        <Button 
                            type='send'
                            onClick={ handleChangeToCourse }
                            loading={ loading }
                        >
                            Establecer
                        </Button>
                        <Button 
                            type='danger'
                            onClick={ onHandleClickToCourse }
                        >
                            Cancelar
                        </Button>
                    </section>
                </div>
            </Modal>
        </>
    )
}

export { OfferHeader }