import { Card } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { useLocation } from "react-router-dom";
import { ICON } from '../../../constants/icons';
import { useFetchGet } from "../../../hooks/useFetchGet";
import { SubTitle } from '../../../shared/components/subtitle/SubTitle';

function EstudianteAsistenciasCursoActivo (props) {

  const {state:{ programa, oferta }} = useLocation()
  const [asistencias, loading, error] = useFetchGet(
    `estudiante/asistencia?IdMalla=${oferta.IdMalla}&IdEstudiante=${programa.IdEstudiante}&IdOferta=${programa.IdOferta}`
  )
  return (
    <>
      <Card>
        <Card.Title>Asistencias</Card.Title>
        <p>{oferta.asignatura}</p>   
      </Card>

      
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>N°</th>
          <th>Fecha incial</th>
          <th>Fecha final</th>
          <th>Asistencia</th>
        </tr>
      </thead>
      <tbody>
        {!!asistencias && asistencias.map((item,index)=>{
          return(
            <tr key={index}>
              <td>{index+1}</td>
              <td>{item.FechaInicio}</td>
              <td>{item.FechaFin}</td>
              {/* <td>{item.Asistencia==1?'Asistió':'No asistió'}</td> */}
              <td>{item.Asistencia==='1'?`Asistió ${ICON.CHECKED}` :`No asistió ${ICON.UNCHECKED}`}</td>
            </tr>
          )
        }

        )}
      </tbody>
    </Table>
  </>
  )
}

export { EstudianteAsistenciasCursoActivo }