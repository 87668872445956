
import { useState } from "react"
import { Button, Col, Row, Modal, ModalHeader, ModalBody, ModalFooter } from "react-bootstrap"
import { Card } from "../../../components/card/Card"
import { DynamicTable } from "../../../components/DynamicForm/dynamicTable/DynamicTable"
import { Input } from "../../../components/input/input"
import { alerta } from "../../../helpers/alertas"
import { Title } from "../../../shared/components/title/Title"

import { FormService } from "../../../services/api/formService"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { useDispatch,useSelector } from "react-redux"
import { BASE_URL } from "../../../services/api/config"
import { Alert } from "react-bootstrap"

import{
    setCodigo,
    setFecha1,
    setFecha2

} from '../.././../redux/formularioSlice'


const HistorialCurso=()=>{

    // const[codigo,setCodigo]=useState('');
    // const[fecha1,setFecha1]=useState('');
    // const[fecha2,setFecha2]=useState('');
    
    const dispatch=useDispatch();
    const [modal, setModal] = useState()
    const codigo=useSelector(state=>state.formulario.codigo);
    const fecha1=useSelector(state=>state.formulario.fecha1);
    const fecha2=useSelector(state=>state.formulario.fecha2);
    const [FechaInicio, setFechaInicio] = useState()
    const [FechaFin, setFechaFin] = useState()
    const [code, setCode] = useState()
    const[endpoint,setEndpoint]=useState(`ofertas/historial?codigo=${code}&fechaInicial=${FechaInicio}&fechaFinal=${FechaFin}`);
    const url=`ofertas/historial?codigo=${codigo}&fechaInicial=${fecha1}&fechaFinal=${fecha2}`

    const api=new FormService();
    const Navigate=useNavigate();

    

    const consultarOfertas=()=>{
        /* console.log(codigo) */
        /* if(codigo==''){
            alerta("Escribe el codigo de la actividad")
            return
        } */   
        if(fecha1==''){
            alerta("Escribe la fecha inicial")
        }
        if(fecha2==''){
            alerta("Escribe la fecha final")
        }
        const url=`ofertas/historial?codigo=${code}&fechaInicial=${FechaInicio}&fechaFinal=${FechaFin}`
        setEndpoint(url);
    }

    const verMas=(e)=>{
        Navigate(e.IdOferta+"/asignaturas",{state:{programa:e}})
    }

    useEffect(()=>{

        
    }, [])

    return(
        <>
        <Card>
            <Title>
                Historial de Actividades
            </Title>     
            <p>Consultar por código de la actividad y rango de fecha</p>    
            <div style={{textAlign: 'right'}}>
                <Button onClick={() => setModal(true)}> Exportar </Button>    
            </div>   
        </Card>
        <Card>
            <Row>
                <Col>
                    <Input
                        label={"Código de Actividad"} 
                        onChange={(e) => setCode(e)}
                        value={codigo}
                    />
                </Col>
                <Col>
                    <Input
                        name={"fechaInicial"}
                        label={"Fecha Inicial"}
                        type="date"
                        onChange={(e) => setFechaInicio(e)}
                        value={FechaInicio}
                        required={true}
                    />
                </Col>
                <Col>
                    <Input
                        name={"fechaFinal"}
                        label={"Fecha Final"} 
                        type="date"
                        onChange={(e) => setFechaFin(e)}
                        value={FechaFin}
                        required={true}
                    />
                </Col>
                <Col
                    style={{display:'flex',alignItems:'center'}}
                >

                <div style={{display:'flex',alignItems:'center'}}>
                    <Button
                        onClick={() => consultarOfertas()}
                    >
                        Buscar
                    </Button> 
                </div>
                    
                </Col>
            </Row>
        </Card>
        <Card>
            <DynamicTable
                titleColumn={["No","Nombre","Fechas","Acción"]}
                attributes={["Nombre","Fechas"]}
                endPoint={endpoint}
                onDelete={false}
                onUpdate={false}
                labelOptionClick="Ver más"
                handleOptionClick={(e)=>verMas(e)}
            />
        </Card>
        <Modal
            show={modal}
            onHide={() => setModal(false)}
        >
            <ModalHeader closeButton={true}>
                <h2>Exportar Actividades en General</h2>
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <Input
                            name={"FechaInicial"}
                            label={"Fecha Inicial"}
                            type="date"
                            onChange={(e) => setFechaInicio(e)}
                            value={FechaInicio}
                            required={true}
                        />
                        <Input
                            name={"FechaFin"}
                            label={"Fecha Final"} 
                            type="date"
                            onChange={(e) => setFechaFin(e)}
                            value={FechaFin}
                            required={true}
                        />
                    </Col>
                    <Row>
                        <Col>
                            <br />
                            <Alert variant={'info'}>
                                <p><small> <b>Exportable en PDF:</b> La información a continuación tiene un tiempo delimitado para visualizarse. Por favor, guardar el archivo en pdf en su dispositivo. </small></p>
                                <br />
                                <p><small> <b>Exportable en Excel:</b> Soporta un filtro de fechas máximo de 6 meses; Si tiene inconvenientes, Por favor, solicitar reporte por medio de la mesa de ayuda. </small></p>
                            </Alert>
                        </Col>
                    </Row>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button variant="secondary" href={`${BASE_URL}/general/actividades?FechaInicio=${FechaInicio}&FechaFin=${FechaFin}`} target="_blank" onClick={() => setModal(false)}>
                    Exportar PDF
                </Button>
                <div style={{textAlign: 'right'}}>
                    <Button variant="success" href={`${BASE_URL}/general/actividadesExcel?FechaInicio=${FechaInicio}&FechaFin=${FechaFin}`} target="_blank" onClick={() => setModal(false)}>
                        Exportar Excel
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
        </>
    )    
}


export{HistorialCurso} 