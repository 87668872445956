import React, { useState, useEffect } from 'react'

import { useLocation } from 'react-router-dom'
import { FormService } from '../../../services/api/formService'
import { useParams } from 'react-router-dom'
import { Modal } from '../../modal/Modal'
import { Graduate } from '../../Graduate/Graduate'
import { Button } from '../../button/button'
import { useToggleList } from '../../../hooks/useToggleList'
import { ListByCourse } from '../../ListByCourse/ListByCourse'
import { Message } from '../../Message/Message'
import { Badge } from '../../Badge/Badge'
import { useSearch } from '../../../hooks/useSearch'
import { Input } from '../../input/input'
import { ListContainer } from '../../ListContainer/ListContainer'

import './GraduateList.css'

const GraduateList = () => {

    const { state: { offer } } = useLocation()
    const [loadingFull, setLoadingFull] = useState(true)
    const [loading, setLoading] = useState(false)
    const [response, setResponse] = useState({})
    const [error, setError] = useState(null)
    const [students, setStudents] = useState([])
    const [isOpenModalGraduateSelected, setIsOpenModalGraduateSelected] = useState(false)
    const [inputSearch, searchResult, setDataToSearch] = useSearch(students)
    const { idCourse } = useParams()
    const { 
        selectedNextStop: studentsToGraduate,
        toggled,
        checked,
        handleChangeCheckbox,
        switchSelect,
        numberIsSelected,
    } = useToggleList(students, 'IdEstudiante', idCourse)
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [student, setStudent] = useState({})
    const ENDPOINT = 'estudiantesCurso?IdOferta='
    const formService = new FormService()

    const fetchDataStudents = async () => {
        setLoadingFull(true)
        try {
            const data = await formService.getAll(`${ENDPOINT}${idCourse}`)
            setStudents(data)
            setLoadingFull(false)
        } catch (err) {
            setError(err)
            setLoadingFull(false)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchDataStudents()
        }
        fetchData()
    }, [])

    const handleOpenModal = (student) => {
        setIsOpenModal(!isOpenModal)
        setStudent(student)
    }

    const handleOpenModalGraduateSelected = () => {
        setIsOpenModalGraduateSelected(!isOpenModalGraduateSelected)
    }

    const graduateSelected = async () => {
        try {
            setLoading(true)
            setError(null)
            const response = await formService.create(
                'estudiante/graduar', 
                {estudiantes: studentsToGraduate}
            )
            setResponse(response?.respuesta)
            fetchDataStudents()
            setIsOpenModalGraduateSelected(!isOpenModalGraduateSelected)
            setLoading(false)
        } catch (err) {
            setError(err)
            setLoading(false)
        }
    }

    const onSearch = (value) => {
        setDataToSearch(value)
    }
  
    return (
        <>
            <ListByCourse
                offer={offer}
            >
                <>
                    <h3 className='text-center m-5'>
                        Lista de estudiantes que terminaron el curso
                    </h3>
                    <Input 
                        type='search'
                        placeholder= 'Busca por nombre o cédula del estudiante'
                        value={ inputSearch }
                        onChange={ onSearch }
                    />
                    <div className='graduate-list__manipulate-list'>
                        <Button 
                            onClick={ toggled }
                            className='graduate-list__select-all'
                        >
                            { switchSelect ? 'Seleccionar todos' : 'Deseleccionar todos' }
                        </Button>
                        {
                            (numberIsSelected >= 2 && 
                            !response === 'exito') && (
                                <Button 
                                    type='outline-primary'
                                    onClick={ handleOpenModalGraduateSelected }
                                >
                                    Graduar
                                </Button>
                            )
                        }
                    </div>
                    {
                        error && (
                            <Message type='error'>
                                { error.message }
                            </Message>
                        )
                    }
                    <ListContainer loading={ loadingFull }>
                        {
                            Array.isArray(searchResult) && (
                                searchResult.map((student, key) => (
                                    <Badge
                                        isButton={ true }
                                        img={ student?.Foto }
                                        alt='Foto de perfil de estudiante'
                                        user={ student }
                                        onClick={ () => handleOpenModal(student) }
                                        isGraduate={ student.Graduado }
                                        tag={ student.IdEstudiante }
                                        value={ checked[student.IdEstudiante] }
                                        handleChangeCheckbox={ handleChangeCheckbox }
                                        key={ key }
                                    />
                                ))
                            )
                        }
                    </ListContainer>
                </>
            </ListByCourse>
            <Modal
                isOpen={ isOpenModal }
                close={ handleOpenModal }
                className='w-auto'
            >
                <Graduate 
                    student={ student }
                    setError={ setError }
                    loading={ loading }
                    setLoading={ setLoading }
                    fetchDataStudents={ fetchDataStudents }
                />
            </Modal>
            <Modal 
                isOpen={ isOpenModalGraduateSelected }
                close={ handleOpenModalGraduateSelected }
            >
                <h3>
                    {
                        `Estas a punto de graduar a ${numberIsSelected} 
                        estudiantes, ¿estás seguro?`
                    } 
                </h3>
                <Button 
                    type='send'
                    loading={ loading }
                    onClick={ graduateSelected }
                >
                    Graduar estudiantes 🎓
                </Button>
                <Button 
                    type='danger'
                    onClick={ handleOpenModalGraduateSelected }
                >
                    Cancelar
                </Button>
            </Modal>
        </>
    )
}

export { GraduateList } 