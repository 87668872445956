import { DynamicFormContainer } from "../../../../components/DynamicForm/DynamicFormContainer"
import { useLocation } from "react-router-dom"

function RequisitosOffer () {
    const {state:{oferta}}=useLocation();
    
    return (
        <DynamicFormContainer idForm={'40'} extraData={{"IdOferta": oferta?.IdOferta}} idPadre={`?IdOferta=${oferta?.IdOferta}`}/>
    )
}

export {RequisitosOffer}