import { useEffect } from 'react'
import { useState } from 'react'

export const useSearch = (items) => {

    const [inputSearch, setInputSearch] = useState('')
    const [leakedData, setLeakedData] = useState(items)

    useEffect(() => {
        setLeakedData(items)
    }, [items])

    const searchItem = (value) => {
        setInputSearch(value)
        if (value.length > 0) {
            const result = items.filter(item => {
                if (
                    item.PrimerNombres.toLowerCase().includes(value.toLowerCase())
                    || item.NumeroDocumento.toLowerCase().includes(value.toLowerCase())
                ) {
                    return item
                }
            })
            setLeakedData(result)
        } else {
            setLeakedData(items)
        }
    }

    return [
        inputSearch, //input value
        leakedData, //data filtered
        searchItem, //search function
    ]

}

