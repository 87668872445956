import { useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import { DynamicFormContainer } from '../../../components/DynamicForm/DynamicFormContainer'
import { ID_ROL_OPTION } from '../../../constants/idRoles'
import { BASE_URL } from '../../../services/api/config'
import FormulariosDinamicos from './FormulariosDinamicos'

function ParametricasGenerales() {
    const hasPermisions = true
    const urlExportar = `${BASE_URL}/exportarMecanismoIngreso`

    return (
        <>
            {hasPermisions ? (
                <Routes>
                    <Route
                        key={'paises'}
                        path="paises"
                        element={<DynamicFormContainer idForm="1" />}
                    />
                    <Route
                        key={'tipo_convenios'}
                        path="tipo_convenios"
                        element={<DynamicFormContainer idForm="10" />}
                    />
                    <Route
                        key={'tipo_convocatoria'}
                        path="tipo_convocatoria"
                        element={<DynamicFormContainer idForm="11" />}
                    />
                    <Route
                        key={'clase_convenio'}
                        path="clase_convenio"
                        element={<DynamicFormContainer idForm="9" />}
                    />
                    <Route
                        key={'tipo_estudio'}
                        path="tipo_estudio"
                        element={<DynamicFormContainer idForm="12" />}
                    />
                    <Route
                        key={'grupo'}
                        path="grupo"
                        element={<DynamicFormContainer idForm="16" />}
                    />{' '}
                    {/* no existe endpoint */}
                    <Route
                        key={'mecanismo_ingreso'}
                        path="mecanismo_ingreso"
                        element={<DynamicFormContainer idForm="17" exportar={urlExportar} />}
                    />
                    <Route
                        key={'dimension'}
                        path="dimension"
                        element={<DynamicFormContainer idForm="18" />}
                    />
                    <Route
                        key={'tipo_estado_actividad_programa'}
                        path="tipo_estado_actividad_programa"
                        element={<DynamicFormContainer idForm="19" />}
                    />
                    <Route
                        key={'tipo_tercero'}
                        path="tipo_tercero"
                        element={<DynamicFormContainer idForm="20" />}
                    />
                    <Route
                        key={'estrato'}
                        path="estrato"
                        element={<DynamicFormContainer idForm="22" />}
                    />
                    <Route
                        key={'sexo_biologico'}
                        path="sexo_biologico"
                        element={<DynamicFormContainer idForm="24" />}
                    />
                    <Route
                        key={'campos_de_formacion'}
                        path="campos_de_formacion"
                        element={<DynamicFormContainer idForm="27" />}
                    />
                    <Route
                        key={'area'}
                        path="area"
                        element={<DynamicFormContainer idForm="28" />}
                    />
                    <Route
                        key={'estado_civil'}
                        path="estado_civil"
                        element={<DynamicFormContainer idForm="25" />}
                    />
                    <Route
                        key={'tipos_identidad'}
                        path="tipos_identidad"
                        element={<DynamicFormContainer idForm="26" />}
                    />
                    <Route
                        key={'medio_pago'}
                        path="medio_pago"
                        element={<DynamicFormContainer idForm="29" />}
                    />
                    <Route
                        key={'tipo_de_areas'}
                        path="tipo_de_areas"
                        element={<DynamicFormContainer idForm="30" />}
                    />
                    <Route
                        key={'periodos'}
                        path="periodos"
                        element={<DynamicFormContainer idForm="41" />}
                    />
                    <Route
                        key={'condiciones_fisicas'}
                        path="condiciones_fisicas"
                        element={<DynamicFormContainer idForm="44" />}
                    />
                    <Route
                        key={'nucleos_de_conocimiento'}
                        path="nucleos_de_conocimiento"
                        element={<DynamicFormContainer idForm="58" />}
                    />
                    <Route
                        key={'tipos_de_discapacidad'}
                        path="tipos_de_discapacidad"
                        element={<DynamicFormContainer idForm="59" />}
                    />
                    <Route
                        key={'rh'}
                        path="rh"
                        element={<DynamicFormContainer idForm="80" />}
                    />

                    <Route
                        key={'dependencias'}
                        path="dependencias"
                        element={<DynamicFormContainer idForm="173" />}
                    />
                    <Route
                        key={'regionales'}
                        path="regionales"
                        element={<DynamicFormContainer idForm="174" />}
                    />
                    <Route
                        path='formularios_dinamicos'
                        element={<FormulariosDinamicos/>}
                    />
                    
                    {/* recursive dynamicform via state location (no idform props), [child tables]  ⬇️⬇️⬇️ */}
                    <Route path="*" element={<DynamicFormContainer />} />
                </Routes>
            ) : null}
        </>
    )
}
export { ParametricasGenerales }
