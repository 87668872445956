import React, { useEffect, useState } from 'react'

import { Button } from '../../button/button'
import { useSubmitCreate } from '../../DynamicForm/hooks/useSubmitCreate'
import { useParams } from 'react-router-dom'
import { FormService } from '../../../services/api/formService'
import { InputFile } from '../../inputFile/inputFile'
import { useFetchGet } from '../../../hooks/useFetchGet'
import { CardPay } from '../../cardPay/CardPay'
import { ApiPasarela } from '../../../services/api/ApiPasarela'
import { BASE_URL } from '../../../services/api/config'
import { Message } from '../../Message/Message'
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner'

import './Enrollment.css'
import { ReceiptUploaded } from '../../ReceiptUploaded/ReceiptUploaded'

const Enrollment = ({
    offer, 
    applicant,
    setIsCompletedEnrollment,
}) => {

    const ENDPOINT_APPLICANT = 'aspirantes'
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [data, setData] = useState({})
    const [receiptUploaded, setReceiptUploaded] = useState(false)
    const { idApplicant  } = useParams()
    const [payType, loadingPayType, errorPay] = useFetchGet(`pagos/${offer?.PagoMatricula}`)
    const [urlBill] = useState (
        `${BASE_URL}/estudiante/descargarRecibo?IdOFerta=${offer.IdOferta}&IdAspirante=${applicant.IdAspirante}&Concepto=${'matricula'}`
    )
    const formService = new FormService()
    const [fetchDataCreate, statusCreate] = useSubmitCreate(
        'ReciboMatricula',
        null, 
        null,
        '1'
    )

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                const data = await formService.getAll(`aspirantes/${idApplicant}`)
                setData(data)
                setLoading(false)
                console.log('Data rtecibo', data)
            } catch (err) {
                setError(err)
                setLoading(false)
            }
        }
        fetchData()
    }, [])

    // const onEnroll = async () => {
    //     setLoading(true)
    //     await formService.update(
    //         ENDPOINT_APPLICANT, 
    //         { EstadoEstudiante: "1" }, 
    //         idApplicant 
    //     )
    //     setLoading(false)
    // }

    const onPay = async () => {
        ApiPasarela(
            payType.Valor,
            payType.Descripcion+ ', '+offer?.Nombre,
            'email.com'
        ).then(res => {
            if(res?.success) {
                const newWindow = window.open(
                    res?.data?.routeLink, 
                    '_blank', 
                    'noopener,noreferrer'
                )
                if (newWindow) newWindow.opener = null
            }
        })
    }

    const handleSubmitUploadFile = async e => {
        e.preventDefault()
        try {
            setLoading(true)
            const formData = new FormData(e.currentTarget)
            let dataRaw = Object.fromEntries(formData.entries());
            await formService.createWithFormData(
                `aspirantes/${idApplicant}?_method=PUT`, 
                dataRaw
            )
            setLoading(false)
            setReceiptUploaded(true)
        } catch (err) {
            setError(err)
            setLoading(false)
        }
    }

    const endEnrollment = async () => {
        setLoading(true)
        const data = {
            IdAspirante: applicant.IdAspirante,
            IdOferta: applicant.IdOferta,
            IdUsuario: applicant.IdUsuario,
        }
        try {
            await formService.create(
                'aspirantes/convetirEstudiante', 
                data
            )
            setIsCompletedEnrollment(true)
            setLoading(false)
        } catch (err) {
            setError(err)
        }
    }
console.log(payType)
    return (
        loadingPayType ? <LoadingSpinner /> :
        <div className='enrollment'>
            {/* <>
            {
                loadingFull ? <LoadingSpinner /> : (
                    <>
                        <div className='documents__container'>
                            {
                                applicant.map((item, key) => (
                                    <ViewPDF 
                                        item={ item }
                                        key={ key }
                                        onAccept={ onAccept }
                                        onDecline={ onDecline }
                                        onUpload={ onUpload }
                                    />
                                ))
                            }
                            {
                                error && (
                                    <Message type='error'>
                                        { error.message }
                                    </Message>
                                )
                            }
                        </div>
                        <section className='documents__button-enroll'>
                            <Button 
                                type='send'
                                loading={ loading }
                                onClick={ onEnroll }
                            >
                                Inscribir aspirante
                            </Button>
                        </section>
                    </>
                )
            }
            <Modal isOpen={ isOpenModalDecline } close={ onDecline }>
                <div>
                    <h5>
                        ¿Estás seguro de rechazar este documento?
                    </h5>
                    <Input 
                        label='Motivo'
                        placeholder='Explica el motivo de rechazo'
                        name='Observacion'
                        value={ observation }
                        onChange={ handleChangeObservation }
                    />
                    {
                        isEmpty && (
                            <Message type='warning-white'>
                                Describe el motivo de rechazo
                            </Message>
                        )
                    }
                    <section className='d-flex'>
                        <Button 
                            type='send'
                            onClick={ () => handleState(2) }
                            loading={ loading }
                        >
                            Rechazar
                        </Button>
                        <Button 
                            type='danger'
                            onClick={ onDecline }
                        >
                            Cancelar
                        </Button>
                    </section>
                </div>
            </Modal>
            <Modal isOpen={ isOpenModalAccept } close={ onAccept }>
                <div className='documents_modal-accept'>
                    <h5>
                        ¿Estás seguro de aceptar este documento?
                    </h5>
                    <section className='d-flex'>
                        <Button 
                            type='send'
                            onClick={ () => handleState(1) }
                            loading={ loading }
                        >
                            Aceptar
                        </Button>
                        <Button 
                            type='danger' 
                            onClick={ onAccept }
                        >
                            Cancelar
                        </Button>
                    </section>
                </div>
            </Modal>
            <Modal isOpen={ isOpenModalUpload } close={ onUpload }>
                <form onSubmit={ handleSubmitUpload }>
                    <InputFile 
                        label={ document.Descripcion }
                        name='Archivo'
                        accept='.pdf'
                    />
                    <Button 
                        type='send'
                        loading={ loading }
                    >
                        Subir
                    </Button>
                </form>
            </Modal>
        </> */}
            <div className='enrollment__button-enroll'>
                <Button 
                    type='send'
                    onClick={ endEnrollment }
                >
                    Matricular aspirante
                </Button>
            </div>

        </div>
    )
}

export { Enrollment }