import React from 'react'
import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import { DynamicTable } from '../../components/DynamicForm/dynamicTable/DynamicTable' 
import { Select } from '../../components/select/Select'
import { alerta } from '../../helpers/alertas'
import { useFetchGet } from '../../hooks/useFetchGet'
import { LocalStorage } from '../../services' 
import { FormService } from '../../services/api/formService'

const OfertasEmpresas = () => {

    const navigate = useNavigate()
    const ls = new LocalStorage()

    const api= new FormService();
    const idUser = ls.getAsJsonItem('usuario').usuario.IdUsuario
    const [modalEmpresa,setModalEmpresa]=useState();
    const [idEmpresa,setIdEmpresa]=useState(null);
    const [oferta,setOferta]=useState();
    const [empresas]=useFetchGet(`empresa/misEmpresas?IdUsuario=${idUser}`)

    const titleColumnsTable = [
        'N°',
        'Programas y Actividades',
        'Fecha Incio',
        'Fecha Fin',
        'Cupos',
        'Acciones'
    ]

    const attributes = [
        'Nombre',
        'FechaInicioCurso',
        'FechaFinCurso',
        'Cupos',
    ]

    const onNavigateDetails = () => {
        
        const empresa =empresas.find(item=>item.IdEmpresa===idEmpresa) 
   
        if(empresa==null){
            alerta("Selecciona una empresa")
            return
        }
        navigate( `lista-aspirante/${oferta.IdOferta}`,{state:{offer:oferta,empresa:empresa}}) 
    }

    return (
        <>
            <DynamicTable
                titleColumn={ titleColumnsTable }
                attributes={ attributes }
                endPoint={ `empresa/ofertasEmpresas?IdUsuario` }
                onDelete={ false }
                onUpdate={ false }
                labelOptionClick={ 'Ver más' }
                handleOptionClick={(e)=>{
                    setModalEmpresa(true)
                    setOferta(e)
                }}
                classNameOptionIcon='bi bi-view-list'
            />
            <Modal 
                show={modalEmpresa}
                onHide={()=>setModalEmpresa()}
            >
                <Modal.Body>
                    <b>Seleccione la empresa con la desea gestionar la oferta</b><br></br>
                    <Select 
                        label="Empresas"
                        options={empresas}
                        optionName="Nombre"
                        optionValue="IdEmpresa"
                        value={idEmpresa} 
                        onChange={(e)=>setIdEmpresa(e)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant='secondary'
                        onClick={()=>setModalEmpresa()}
                    >
                        Cancelar
                    </Button>
                    <Button 
                        onClick={()=>onNavigateDetails()}
                    >
                        Acceder
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export { OfertasEmpresas }
