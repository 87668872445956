import React from 'react'
import { Card } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom';
import { DynamicTable } from '../../../components/DynamicForm/dynamicTable/DynamicTable'

export default function Estudiantes() {
  const {state:{IdCurso}}=useLocation();
  const navigate=useNavigate();
  return (
    <>
      <Card>
        <Card.Title>Estudiantes</Card.Title>
      </Card>

      <DynamicTable
            endPoint={`educacionIES/estudiantes?IdCurso=${IdCurso}`}
            titleColumn={["N°","Número Documento","Nombre","Acciones"]}
            attributes={["NumeroDocumento","NombreUsuario"]}
            handleOptionClick={(e)=>navigate("estudiante",{state:{estudiante:e,IdCurso:IdCurso}})}
            labelOptionClick={`Ver estudiante`}
            onDelete={false}
            onUpdate={false}
        />
    </>
  )
}
