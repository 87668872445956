import { Col, Row, Card as Carb } from "react-bootstrap"
import { Card } from "../../components/card/Card"

import { Bar } from 'react-chartjs-2';
import { useEffect, useState } from "react";
import { FormService } from "../../services/api/formService";
import { setError } from "../../components/DynamicForm/FormSlice";


const ActividadesYProgramas=()=>{
    
    const [barData, setBarData] = useState([]);
    const [datos,setDatos]=useState()

    const fetchDataTrimester = async () => {
        const formService = new FormService();
        
        try {
            const response = await formService.getAll(
                `planeacion/actividades`
            )
            setDatos(response)
            // const dataForBar = filterData(response.edades)
            setBarData(response.dimenciones)
        } catch (err) {
            setError(err)
        }
    }
    const filterData = (data) => {
        const  dataForBar= []
        data?.tipos_evaluaciones.map(tipo => {
            dataForBar.push(tipo.contador)
        })
        return dataForBar;
    }
    useEffect(() => {
        const fetchData = async () => {
            await fetchDataTrimester();
        }
        fetchData();
    }, [])

    const labels = [
        // '1-20', 
        // '20-35', 
        // '35-45', 
        // '45-60', 
        // '>60',
    ];

    const dataVerticalBar = {
        labels,
        datasets: [
            {
                label: 'Número de evaluaciones',
                data: barData,
                backgroundColor: '#009490',
            }
        ],
    }
    return(
        <>
        {/* <Card> */}
            <h1></h1>
        {/* </Card> */}
        <h1 style={{textAlign:'center',paddingTop:30}}> Reporte de actividades y programas</h1>
        <p style={{textAlign:'center'}}>Número de actividades y programas: {datos?.total_activiades}</p>
        <Card>
            {/* <h3>Usuarios por sexo</h3> */}
            <Row>
                <Col>
                    <Carb border="primary" style={{ width: '18rem' }}>
                        <Carb.Header>
                            Planes de estudio
                        </Carb.Header>
                        <Carb.Body>
                            <Carb.Title>
                            {datos?.planestudio}
                            </Carb.Title>                           
                        </Carb.Body>
                    </Carb>
                </Col>
                <Col>
                <Carb>
                        <Carb.Header>
                            Ofertas
                        </Carb.Header>
                        <Carb.Body>
                            <Carb.Title>
                            {datos?.ofertas}
                            </Carb.Title>                           
                        </Carb.Body>
                    </Carb>
                </Col>
                <Col>
                <Carb>
                        <Carb.Header>
                            Ofertas activas
                        </Carb.Header>
                        <Carb.Body>
                            <Carb.Title>
                            {datos?.activos}
                            </Carb.Title>                           
                        </Carb.Body>
                    </Carb>
                </Col>
            </Row>
            <div style={{marginTop:20,marginBottom:20}}>
                <h3>Activiades y programas por dimensiones</h3>
            </div>
            <Bar data={dataVerticalBar} />

        </Card>
        </>
    )
}   

export {ActividadesYProgramas}