import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LocalStorage } from "../../../services";
import { DynamicTable } from "../../../components/DynamicForm/dynamicTable/DynamicTable";
import { DynamicSearch } from "../../../components/DynamicForm/DynamicSearch/DynamicSearch";
import { Title } from "../../../shared/components/title/Title";
import { Card } from "react-bootstrap";
import { FormService } from "../../../services/api/formService";
import { BASE_URL } from "../../../services/api/config";

function ProgramasADictar() {

  const api = new LocalStorage();
  const service = new FormService();
  const idUsuario = api.getAsJsonItem("usuario")?.usuario?.IdUsuario || 'localstorage_empty'
  const urlProgramas = `cursos/activosRegistro?IdUsuario=${idUsuario}`;
  const navigate = useNavigate();
  const[campos,setCampos] = useState();
  const [endPoint, setEndPoint] = useState(urlProgramas);
  const [urlExportar, setUrlExportar] = useState(`${BASE_URL}/exportarOfertasActivas`)
  const [busqueda,setBusqueda] = useState();

  useEffect(() => {
    fetchCamposFormForFilter();
  }, []);

  const onNavigate = (programa) => {
    navigate(`${programa.IdOferta}/asignaturas`,{state:{programa,idUsuario}});
  }

  const buscarCursos=(e)=>{
    const nuevoEndPoint=`cursos/activosRegistro${e}`
    setEndPoint(nuevoEndPoint)
    setBusqueda(e)
    setUrlExportar(`${BASE_URL}/exportarOfertasActivas${e}`)
  }
  
  const fetchCamposFormForFilter = async ()=>{  
    const camp = await service.getAll(`xcampos?id_formulario=118`)
    .then((res)=>{
        setCampos(res)
    })
  }

  return (
    <>
    <Card>
      <Card.Title>Actividades Académicas Activas</Card.Title>
    </Card>

      {campos&&
        <DynamicSearch  onSearch={buscarCursos} camposForm={campos} ></DynamicSearch>
      }
 
    <DynamicTable
      titleColumn={['N°','Nombre','Fecha Inicio','Fecha Fin', 'Acción']}
      attributes={['Nombre','FechaInicioCurso','FechaFinCurso']}
      endPoint={endPoint}
      busqueda={busqueda}
      onDelete={false}
      onUpdate={false}
      labelOptionClick={`Ver más`}
      handleOptionClick={onNavigate}
      classNameOptionIcon={`bi bi-view-list`}
      exportar={urlExportar}
    ></DynamicTable>
    </>
  )
}

export  { ProgramasADictar };