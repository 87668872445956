import React from 'react'
import { Card } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom';
import { DynamicTable } from '../../../components/DynamicForm/dynamicTable/DynamicTable';
import { LocalStorage } from '../../../services';

export default function Cohortes() {
  const {state:{IdCurso}}=useLocation();
  const ls = new LocalStorage()
  const IdUsuario = ls.getAsJsonItem('usuario').usuario.IdUsuario
  const urlProgramas = `educacionIES/cohortes?IdUsuario=${IdUsuario}&IdCurso=${IdCurso}`;

  const navigate = useNavigate()

  const onNavigateDetails = (item) => {
    navigate( `matriculados`, { state: { oferta: item } } )
  }
  return (
    <>
      <Card>
        <Card.Title>Cohortes</Card.Title>
      </Card>
      <DynamicTable
            titleColumn={['N°','Nombre','Fecha Inicio','Acción']}
            attributes={['Nombre','FechaInicioCurso']}
            endPoint={urlProgramas}
            onDelete={false}
            onUpdate={false}
            labelOptionClick={`Matriculados`}
            handleOptionClick={onNavigateDetails}
            classNameOptionIcon={`bi bi-view-list`}
      />
    </>
  )
}
