import React from 'react'
import { HeaderHome } from '../../components/headerHome/HeaderHome'
import { Alert, Button } from 'react-bootstrap'
import { BASE_URL } from '../../services/api/config'
import { LocalStorage } from '../../services'

const Consentimiento1 = () => {
  const storage=new LocalStorage()
  const usuario=JSON.parse(storage.getItem('usuario'))
  const IdUsuario=usuario.usuario.IdUsuario

  return (
    <>
    <HeaderHome/>
    <div className='mx-auto w-75 d-flex flex-column justify-content-center align-items-center'>
        
        
        <h1 className='text-center'>CONSENTIMIENTO INFORMADO EXONERACIÓN DE RESPONSABILIDAD JURÍDICA</h1><br /><br />

        <p className=''>
            Yo, en condición de aspirante y/o estudiante del CENTRO DE ESTUDIOS AERONÁUTICOS “CEA” INSTITUCIÓN DE EDUCACIÓN SUPERIOR, de forma        voluntaria, informada y libre declaro lo siguiente: <br />
            Declaración inicial: Me he inscrito y/o matriculado al programa académico “TECNOLOGÍA EN GESTIÓN DE TRÁNSITO AÉREO TGTA”, ofertado por El CENTRO DE ESTUDIOS AERONÁUTICOS “CEA” INSTITUCIÓN DE EDUCACIÓN SUPERIOR”, Código SNIES 109430 según Resolución No. 02909 del 21 de febrero de 2018 y resolución No. 016859 del 19 de octubre 2018, por lo cual me comprometo a aceptar la normatividad aeronáutica contemplada en el Reglamento Aeronáutico Colombiano y las normas nacionales vigentes, en cumplir las competencias allí descritas.<br /><br />
            Responsabilidad de riesgo: Manifiesto entender que soy responsable de cumplir los requisitos necesarios para determinar la aptitud psicofísica y los procedimientos para obtener el certificado médico como aspirante y/o titular de licencia técnica estipulada en el Reglamento Aeronáutico Colombiano RAC 67 y RAC 65.<br /><br />
            Así, acepto que, en caso de ser considerado “NO APTO” en la aptitud psicofísica y los procedimientos para obtener el certificado médico como aspirante y/o titular de licencia técnica; Yo asumo completa responsabilidad de los resultados del examen médico, incluidos los que en caso de duda pudiesen reputarse de índole administrativo entre las entidades.<br /><br />
            Si la decisión es continuar con mi formación profesional impartida por el CENTRO DE ESTUDIOS AERONÁUTICOS “CEA” INSTITUCIÓN DE EDUCACIÓN SUPERIOR, la Institución solo tiene la obligación de medio de prestarme el servicio educativo, de formación profesional y estará exonerada de responsabilidad y no habrá lugar a reintegro de dinero, material educativo, cupo académico o derecho de similar naturaleza.<br /><br />

            Exoneración: Por la presente libero y eximo de responsabilidad, indemnización y me comprometo a no establecer demandas en contra del CENTRO DE ESTUDIOS AERONÁUTICOS “CEA” INSTITUCIÓN DE EDUCACIÓN SUPERIOR”, sus agentes, trabajadores, voluntarios y contratistas, de reclamos, demandas, acciones, responsabilidades, pérdidas, sentencias, costos y gastos de cualquier naturaleza incluyendo los reclamos por concepto “NO APTO” en el examen médico, sin importar su causa o razón.<br /><br />

            {/* En un solo ejemplar, este documento será vinculante para mí y mis representantes. Bajo
            mi firma certifico que he leído y entendido los riesgos que asumo y acepto de manera
            voluntaria este acuerdo con los términos pactados. */}
            {/* boton para descagar el concentimineto donde lleva el id del usuario */}
            <Alert  variant='success'>
                <Alert.Heading>Descarga y firma este consentimiento</Alert.Heading>
                <p>
                  <Button
                    variant='outline-success'
                    href={`${BASE_URL}/aspirante/descargarConsentimiento?Numero=1&IdUsuario=${IdUsuario}`}
                    target='_blank'
                  >
                    Descargar
                  </Button>
                </p>
            </Alert>
</p>


    </div>
    </>
  )
}

export default Consentimiento1
