import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormService } from '../../../services/api/formService';
import Header from './Componentes/Header';

export default function NuevaSolicitud(){
    const {state:{usuario}}=useLocation()
    const api=new FormService();
    const navigate=new useNavigate();
    const [tiposSolicitudes,setTiposSolictudes]=useState([]);
    const [tipoSolicitud,setTipoSolicitud]=useState();

    useEffect(()=>{
        (async()=>{
            consultarTiposSolicitudes();
        })()
    },[])

    const consultarTiposSolicitudes=async()=>{
        const tiposSolicitudes=await api.getAll("tiposSolicitudes")
        setTiposSolictudes(tiposSolicitudes)
    }

    const goSolicitud=()=>{
        navigate("form_solicitud",{state:{tipoSolicitud:tipoSolicitud,usuario:usuario}})
    }

  return (
    <>
    <div style={{maxWidth:1000,margin:"0 auto",marginTop:40}}>
        <Header usuario={usuario}/>
        {/* <Card>
            <Card.Title>
                Nueva solicitud
            </Card.Title>
        </Card> */}
        <Card>
            <Card.Title>Seleccione el Tipo de Solicitud que Desea Realizar</Card.Title>
            {tiposSolicitudes.map((solicitud,key)=>
                <Form.Check 
                    key={key}
                    type="radio"
                    id={`default${key}`}
                    name={`tipoSolicitud`}
                    onChange={()=>setTipoSolicitud(solicitud)}
                    label={`${solicitud.Nombre}`}
                />
            )}
            {tipoSolicitud&&
                <Button 
                    onClick={()=>goSolicitud()}
                >
                    Continuar
                </Button>
            }
            
        </Card>
        </div>
    </>
  )
}
