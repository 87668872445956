import React from 'react'
import { Route, Routes } from 'react-router-dom'
import CarnetEstudiantil from './CarnetEstudiantil'
import Documentos from './Documentos'
import Firmas from './Firmas'
import GenerarDocumentos from './GenerarDocumentos'
import EstadoDocumento from './EstadoDocumento'

export default function GestionDocumental() {
  return (
    <Routes>
        <Route path="carnet_estudiantil" element={<CarnetEstudiantil/>}/>
        <Route path="documentos" element = {<Documentos/>}></Route>
        <Route path="documentos/firmas" element = {<Firmas/>}></Route>
        <Route path="generar_documentos" element = {<GenerarDocumentos/>}></Route>
        <Route path="generar_documentos/:id" element = {<EstadoDocumento/>}></Route>
    </Routes>
  )
}
