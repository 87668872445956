import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Modal, Row, Table } from 'react-bootstrap'
import { FormService } from '../../services/api/formService'
import { alerta } from '../../helpers/alertas';
import { useLocation } from 'react-router-dom';
import { SelectDataList } from '../../components/SelectDataList/SelectDataList';
import { useFetchGet } from '../../hooks/useFetchGet';
import { Select } from '../../components/select/Select';

export default function Vinculados() {
    const api=new FormService();
    const location= useLocation();
    const evento=location.state.evento;
    const [vinculados,setVinculados]=useState(null)
    const [showEstudiante,setShowEstudiante]=useState();
    const [showDocente,setShowDocente]=useState();
    const [showEliminarDocente,setShowEliminarDocente]=useState();
    const [showEliminarEstudiante,setShowEliminarEstudiante]=useState();
    const [formDocente,setFormDocente]=useState();
    const [formEstudiante,setFormEstudiante]=useState();

    useEffect(()=>{
        consultarVinculados();
    },[])

    // consultar vinculados
    const consultarVinculados=async()=>{
        const vinculados=await api.getAll(`educacionIES/consultarVinculados?IdEventoAula=${evento.IdEventoAula}`)
        console.log(vinculados)
        setVinculados(vinculados);
    }
    // eliminar estudiante
    const desvincularEstudiante=async()=>{
        // const respuesta= await api.delete("educacionIES/desvincularEstudiante",{},formEstudiante.IdUsuario)
        const data={...formEstudiante}
        const respuesta=await api.create("educacionIES/desvincularEstudiante",data)
        if(respuesta.respuesta="exito"){
            setShowEliminarEstudiante()
            consultarVinculados();
        }else{
            alerta("No se pudo eliminar el estudiante","error")
        }
    }
    // eliminar docentes
    const desvincularDocente=async()=>{
        const data={...formDocente}
        const respuesta=await api.create("educacionIES/desvincularDocente",data)
        if(respuesta.respuesta="exito"){
            setShowEliminarDocente()
            consultarVinculados();
        }else{
            alerta("No se pudo eliminar el docente","error")
        }
    }
    // vincular docente
    const vincularDocente=async()=>{
        const data={
            ...formDocente,
            IdEventoAula:evento.IdEventoAula

        }
        const respuesta=await api.create("educacionIES/vincularDocente",data)
        if(respuesta.respuesta=="exito"){
            setShowDocente(false)
            consultarVinculados();
        }else{

        }
    }
    // vincular estudiante
    const vincularEstudiante=async()=>{
        const data={
            ...formEstudiante,
            IdEventoAula:evento.IdEventoAula
        }
        const respuesta=await api.create("educacionIES/vincularEstudiante",data)
        if(respuesta.respuesta=="exito"){
            setShowEstudiante(false)
            consultarVinculados();
        }else{
            alerta("No se pudo vincular el estudiante","error")
        }
    }


  return (
    <>
        <Card>
            <Card.Title>Vinculados al Evento</Card.Title>
        </Card>
        {/* tabla de docentes */}
        <Card>
            <Row>
                <Col>
                    <Card.Title>Docentes</Card.Title>
                </Col>
                <Col
                    style={{textAlign:'right'}} 
                >
                    <Button
                        onClick={()=>setShowDocente(true)}
                    >
                        Vincular Docente
                    </Button>
                </Col>
            </Row>
            <Table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Docente</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {vinculados?.docentes.map((docente,key)=>(
                        <tr key={key}>
                            <td>{key+1}</td>
                            <td>{docente.Nombre}</td>
                            <td>
                                <Button
                                    onClick={()=>{
                                        setShowEliminarDocente(true)
                                        setFormDocente({
                                            ...docente,
                                            actualizar:true
                                        })
                                    }}
                                >
                                    Desvincular
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Card>
        {/* Tabla de estudiantes */}
        <Card>
            <Row>
                <Col>
                    <Card.Title>Estudiantes</Card.Title>
                </Col>
                <Col 
                    style={{textAlign:'right'}}
                >
                    <Button
                        onClick={()=>setShowEstudiante(true)}
                    >
                        Vincular Estudiante
                    </Button>
                </Col>
            </Row>
            <Table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Nombre</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {vinculados?.estudiantes.map((estudiante,key)=>(
                        <tr key={key}>
                            <td>{key+1}</td>
                            <td>{estudiante.Nombre}</td>
                            <td>
                                <Button
                                    onClick={()=>{
                                        setShowEliminarEstudiante(true)
                                        setFormEstudiante({
                                            ...estudiante,
                                            actualizar:true
                                        })
                                    }}
                                >
                                    Desvincular
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Card>
        {/* modal para eliminar docente */}
        <Modal
            show={showEliminarDocente}
            onHide={setShowEliminarDocente}
        >
            <Modal.Header closeButton>
                <Modal.Title>Desvincular Docente</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>¿Esta seguro que desvincular a este docente?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant='secondary'
                    onClick={()=>setShowEliminarDocente(false)}
                >Cancelar</Button>
                <Button
                    variant='danger'
                    onClick={()=>desvincularDocente()}
                >
                    Desvincular
                </Button>
            </Modal.Footer>
        </Modal>
        {/* Modal para eliminar estudianet */}
        <Modal
            show={showEliminarEstudiante}
            onHide={setShowEliminarEstudiante}
        >
            <Modal.Header>
                <Modal.Title>Desvincular Estudiante</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>¿Esta seguro que desea desvincular este estudiante?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant='secondary'
                    onClick={setShowEliminarEstudiante}
                >
                    Cancelar
                </Button>
                <Button
                    onClick={()=>desvincularEstudiante()}
                >
                    Desvincular
                </Button>
            </Modal.Footer>
        </Modal>
        {/* modal para vincular docente */}
        <Modal
            show={showDocente}
            onHide={setShowDocente}
        >
            <Modal.Header closeButton>
                <Modal.Title>Vincular Docente</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* cammpo para buscar el docente */}
            <SelectDataList
                label="Docente"
                name="Nombre"
                value={formDocente?.Nombre}
                onChangeHandler={(e)=>setFormDocente({IdUsuario:e})}
                placeholder="Buscar Docente"
                endPoinLastUrl={'docente/full'}
                optionLabel="Nombre"
                optionName='Nombre'
                optionValue="IdUsuario"
            />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant='secondary'
                    onClick={()=>setShowDocente(false)}
                >
                    Cancelar
                </Button>
                <Button
                    onClick={()=>vincularDocente()}
                >
                    Vincular
                </Button>
            </Modal.Footer>
        </Modal>
        {/* modal para vincular estudiante */}
        <Modal
            show={showEstudiante}
            onHide={setShowEstudiante}
        >
            <Modal.Header closeButton>
                <Modal.Title>Vincular Estudiante</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SelectDataList
                    label="Estudiante"
                    name="Nombre"
                    value={formEstudiante?.Nombre}

                    onChangeHandler={
                        (e)=>setFormEstudiante({IdUsuario:e})   
                    }
                    placeholder="Buscar Estudiante"
                    endPoinLastUrl={'usuarios/completos'}
                    optionLabel="Nombre"
                    optionName='Nombre'
                    optionValue="IdUsuario"
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant='secondary'
                    onClick={()=>setShowEstudiante(false)}
                >
                    Cancelar
                </Button>
                <Button
                    onClick={()=>vincularEstudiante()}
                >
                    Vincular
                </Button>
            </Modal.Footer>
        </Modal>
    </>
  )
}
