import { useNavigate } from "react-router-dom"
// import { Card } from "../../../../components/card/Card";
import { DynamicTable } from "../../components/DynamicForm/dynamicTable/DynamicTable"
import { LocalStorage } from "../../services"
// import { Title } from "../../../../shared/components/title/Title"

function Notificaciones () {

  const api = new LocalStorage();
  const idUsuario = api.getAsJsonItem("usuario")?.usuario?.IdUsuario || 'localstorage_empty'
 
  const urlProgramas = `alertas?IdUsuario=${idUsuario}`;
  const navigate = useNavigate();

  const onNavigate = (programa) => {
    navigate(`${programa.IdOferta}/alertas`,{state:{programa,idUsuario}}); //to ListadoAsignatura.jsx
  }
  return (
    <>
    {/* <Card> */}
     {/* <Title>Programas Finalizados</Title> */}
    {/* </Card> */}
    <DynamicTable
      titleColumn={['N°','Descripción', 'Acción']}
      attributes={['Descripcion']}
      endPoint={urlProgramas}
      onDelete={false}
      onUpdate={false}
      // labelOptionClick={`Ver más`}
      // handleOptionClick={onNavigate}
      classNameOptionIcon={`bi bi-view-list`}
    ></DynamicTable>

    </>
  )
}
export { Notificaciones }