import React from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { DynamicFormContainer } from '../../components/DynamicForm/DynamicFormContainer'
import ConvocatoriasGrados from './ConvocatoriasGrados'
import Documentos from './Documentos'
import Firmas from './Firmas'
import Reconocimientos from './Reconocimientos'
import Solicitudes from './Solicitudes'
import IncripcionesGrados from './IncripcionesGrados'
import InscritosGrados from './InscritosGrados'
import ProcesoInscripcion from './ProcesoInscripcion'
import EmisionGrados from './EmisionGrados'

export default function GradosCertificados(){
 
  return (
    <Routes>
        <Route path="certificado" element = {''}></Route>
        <Route path="emision_grado" element = {<EmisionGrados/>}></Route>
        <Route path="reconocimientos" element = {<Reconocimientos/>}></Route>
        <Route path="documentos" element = {<Documentos/>}></Route>
        <Route path="documentos/firmas" element = {<Firmas/>}></Route>
        <Route path="convocatorias_para_grado" element = {<ConvocatoriasGrados/>}></Route>
        <Route path="convocatorias_para_grado/solicitudes" element = {<Solicitudes/>}></Route>
        <Route path="inscripciones_a_grados" element={<IncripcionesGrados />}></Route>
        <Route path="inscripciones_a_grados/:id/inscritos" element={<InscritosGrados />}></Route>
        <Route path="inscripciones_a_grados/:id/inscritos/:idApirante" element={<ProcesoInscripcion />}></Route>
        <Route path="*" element={<DynamicFormContainer />}></Route>
    </Routes>
  )
}
