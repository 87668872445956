import React, { useEffect, useState } from 'react';

import { Button } from '../../components/button/button';
import { Select } from '../../components/select/Select';
import { ButtonsContainer } from '../../components/componentsForm/buttonsContainer/buttonsContainer';
import { LeftButtonContainer } from '../../components/componentsForm/leftButtonContainer/leftButtonContainer';
import { RightButtonContainer } from '../../components/componentsForm/rightButtonContainer/rightButtonContainer';
import { FormContainer } from '../../components/componentsForm/formContainer/FormContainer';


import ApiDashboard from '../../services/api/dashboard';
import { alertaSinBoton, alertaTimer } from '../../helpers/alertas';
import { Modal } from '../../components/modal/Modal';
import { Table } from '../../components/componentsForm/table/Table';
import { Input } from '../../components/input/input';
import { Navigate, useNavigate } from 'react-router-dom';
import { Checkbox } from '../../components/componentsForm/checkbox/checkbox';

import { tr } from 'faker/lib/locales';




const AspiranteForm = (props) => {

    const valor = 1;

    //! DECLARACION DE VARIABLES

    console.log("props",props);

    const [cupos, setCupos] = useState('');
    const [idCurso, setIdCurso] = useState('');
    const [fechaNacimiento,setFechaNacimiento] = useState('')
    const [idTipoConvocatoria, setIdTipoConvocatoria] = useState('');
    const [flagPublico, setFlagPublico] = useState(null);


    const [test, setTest] = useState('');
    const [esFuncionario, setEsFuncionario] = useState(false);
    const [funcionarioId, setFuncionarioId] = useState('');
    const [checkbox1, setCheckbox1] = useState(false);
    const [checkbox2, setCheckbox2] = useState(false);
    const [checkbox3, setCheckbox3] = useState(false);
    const [isOpenModalHabeas, setIsOpenModalHabeas] = useState(false);
    const [isOpenConcentimiento1,  setIsOpenConcentimiento1] = useState(false);
    const [isOpenConcentimiento2,  setIsOpenConcentimiento2] = useState(false);

    const [globalCheckbox, setGlobalCheckbox] = useState({});



    const [cedula, setCedula] = useState('');
    const [apeNombres, setApeNombres] = useState('');
    const [direccion, setDireccion] = useState('');
    const [telefono, setTelefono] = useState('');
    const [idPais , setIdPais] = useState('');
    const [repdoc, setRepdoc] = useState('');
    const [especialidad, setEspecialidad] = useState('');
    const [idPersona, setIdPersona] = useState('');
    // const [puntaje, setPuntaje] = useState('');
    const [correo, setCorreo] = useState('');
    const [idProfesional, setIdProfesional] = useState('');
    const [idConvenio , setIdConvenio] = useState('');




    const [loading, setLoading] = useState(false);
    const [isOpenModalCreate, setIsOpenModalCreate] = useState(false);
    const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
    const [isOpenModalUpdate, setIsOpenModalUpdate] = useState(false);
    const [itemSelected, setItemSelected] = useState({});
    const [dataTable, setDataTable] = useState([]);
    const [modalType, setModalType] = useState('');
    const endPointLastUrl = 'aspirantes'; //! endPointLastUrl

    
    //! llamar list */
    const [list, setList] = useState([]);
    const navigate = useNavigate()

    //! LLAMAR Y ASIGNAR LIST
    const fecthDataSelect = async () => {
        const apiSelect = new ApiDashboard();
        /* const pais = await apiSelect.getData('pais') */
        const persona = await apiSelect.getData('persona')
        // const profesional = await apiSelect.getData('profesional')
        // const convenio = await apiSelect.getData('convenio')
        const paises = await apiSelect.getData('getPaises')

        /* console.log('Respuesta de pais', pais);
        if (pais?.status === 'Token is Expired') {
            alertaTimer("La sesion ha expirado", 'info', '4000')
            return false
        } */
        if (
            persona?.status === 'Token is Expired' || 
            // profesional?.status === 'Token is Expired' ||
            paises?.status === 'Token is Expired'
        ) {
            alertaTimer("La sesion ha expirado", 'info', '4000')
            return false
        }

        setList({
            paises, 
            persona,
            // profesional,
            // convenio
        })
    }

    // api.getData(`verifyCedula/${cedula}`).then(res => {


    const verifyCedula = (value) => {
            if (value.length >= 1) {
                fetchCedula(value);
            } else {
                console.log('El numero de cedula debe tener al menos 6 digitos');
            }
        }

        const fetchCedula = async (value) => {
            const api = new ApiDashboard();
            const res = await api.getData(`consultarCedula?Cedula=${value}`)
            console.log('Rta input Cedula ---->', res); 


            if (res?.funcionario.length > 0) {
                console.log('Existe funcionario');
                setEsFuncionario(true);
                setFuncionarioId(res.funcionario[0].id);
            } else {
                setEsFuncionario(false);
                console.log('No existe funcionario');
            }        


            if (res?.persona.length > 0) {
                setApeNombres(res.persona[0].PrimerNombres + ' ' + res.persona[0].SegundoNombre + ' ' + res.persona[0].PrimerApellidos + ' ' + res.persona[0].SegundoApellidos)
                setDireccion(res.persona[0].Direccion)
                setFechaNacimiento(res.persona[0].FechaNacimiento.slice(0, 10))
                setTelefono(res.persona[0].TelefonoContacto)
                setCorreo(res.persona[0].Email)
                setIdPais(8)   //DATO QUEMADO
                setEspecialidad(1) //DATO QUEMADO
                console.log('Existe persona');
                
            } else {
                console.log('No existe persona');
                cleanFormValues();
            }        
        }


    //! FUNCION DE LLAMADO A LA CONSULTA DE DATOS
    useEffect(  () => {
        const fecthData = async () => {
            setLoading(true);
            await fecthDataSelect();
            setLoading(false);
            //console.log(props,"state props")

        }
        fecthData();
    }, [props]);


    //! SUBMIT CREATE OR UPDATE
    const handleSubmit = (e) => {
        e.preventDefault();
        //Stop propagation of the event
        e.stopPropagation();
        console.log('enviando');
        
        const datos = [cedula, apeNombres, direccion,fechaNacimiento, telefono, idPais, especialidad, correo];
        if (datos.includes('')) {
            alertaTimer("Todos los campos son obligatorios", 'error', '4000')
            console.log(datos);
            return
        }
        console.log('enviando2');
        switch (valor) {
            case 0:
                if (globalCheckbox.habeas) {
                    setLoading(true)
                    createService();
                    setLoading(false);
                } else {
                    alert('Debe aceptar los terminos y condiciones');
                }
                break;
     
            case 1:
                if (globalCheckbox.habeas && globalCheckbox.consentimiento1 && globalCheckbox.consentimiento2) {
                    setLoading(true)
                    createService();
                    setLoading(false);
                } else {
                    alert('Debe aceptar los terminos y condiciones');
                }
                break;
     
            default:
                console.log('no existe');
        }
    };

    //! VALIDACION DE TOKEN
   

    //! LIMPIAR FORMULARIO
    const cleanFormValues = () => {
 
        setApeNombres('');
        setDireccion('');
        setTelefono('');
        setIdPais('');
        setRepdoc('');
        setFechaNacimiento('');
        setEspecialidad('');
        setIdPersona('');
        // setPuntaje('');
        setCorreo('');
        setIdProfesional('');
        setIdConvenio('');


    };

    //! CLICK EVENTS
    const onCreate = () => {
        // cleanFormValues();
        setIsOpenModalCreate(true);
        setModalType('/create');
        fecthDataSelect();
        console.log('Listado para selects-->', list);
    };
  
   
    //!CLOSE ALL MODALES
   

    //! SERVICE CREATE
    const createService = async () => {
        try {
            const api = new ApiDashboard();
            const data = await api.createData(
                {
                    IdOferta: props.cursoInformation.curso.IdOferta,
                    Cedula: cedula,
                    FechaNacimiento: fechaNacimiento,
                    ApeNombres: apeNombres,
                    Direccion: direccion,
                    telefono: telefono,
                    idpais: idPais,
                    repdoc: null, //repdoc, //idk
                    especialidad: especialidad,
                    IdPersona: null, //idPersona, //not apply
                    // Puntaje: puntaje,
                    Correo: correo,
                    // IdConvocatoria:null, //props.curso.IdConvocatoria
                    IdProfesional: null, //idProfesional, micro servicio de terceros
                    IdConvenio: null, //idConvenio, // not apply
                    /* Estado: 1, */
                    IdUsuario: 1 
                },
                endPointLastUrl
            );
            console.log('data create', data);
            if (!!data.length) await setDataTable(data);
            if (data?.status === 'Token is Expired') {
                alertaTimer('La sesión ha expirado', 'info', '4000');
                console.log('data exp', data);
            }
            setIsOpenModalCreate(false);
            alertaSinBoton( 'Se ha creado con éxito', 'success', 'center', '2000' );
            setTimeout(() => {
                alertaTimer(
                    'Un correo  llegará en los proximos minutos con información de la siguiente fase',
                    'success',
                    8000
                );
            }, 2000);
            // cleanFormValues();
        } catch (e) {
            alertaSinBoton( 'No se ha podido enviar tu solicitud', 'warning', 'center', 1000 );
            console.log('error create', e);
        }
    };

    const onBack =()=>{
      navigate('../')
    }

    //! SERVICE DELETE
    //! SERVICE UPDATE
   
    //! OPEN MODAL
    const openHabeas = () => {
        setIsOpenModalHabeas(!isOpenModalHabeas);
    }
    const openConstimiento1 = () => {
        setIsOpenConcentimiento1(!isOpenConcentimiento1);
    }
    const openConstimiento2 = () => {
        setIsOpenConcentimiento2(!isOpenConcentimiento2);
    }

    const cambio = (check, name) => {
        setGlobalCheckbox({
            ...globalCheckbox,
            [name]: check,
        });
    }

    console.log(globalCheckbox);

    //!FORM
    const Form = () => {
        return (
            <>
                {/* ***FORM CREATE AND UPDATE**** */}
                <form >
                    <div className="container">
                        <div className="row">
                            <Input
                                label="Cedula"
                                type="number"
                                value={cedula}
                                onChange={setCedula}
                                onBlur={() => {
                                    verifyCedula(cedula);
                                }}
                            />

                            {esFuncionario ? (
                                <div>
                                    <p className="text-primary">
                                        Estas Ingresando como funcionario
                                    </p>
                                </div>
                            ) : null}

                            <Input
                                label="Nombres y apellidos"
                                type="text"
                                onChange={setApeNombres}
                                value={apeNombres}
                                required={true}
                            />
                            <Input
                                label="Direccion"
                                type="text"
                                onChange={setDireccion}
                                value={direccion}
                                required={true}
                            />
                            <Input
                                label="Fecha nacimiento"
                                type="date"
                                onChange={setFechaNacimiento}
                                value={fechaNacimiento}
                                required={true}
                            />
                            <Input
                                label="Teléfono"
                                type="number"
                                onChange={setTelefono}
                                value={telefono}
                                required={true}
                            />
                            <Select
                                id="pais"
                                name="pais"
                                onChange={setIdPais}
                                value={idPais}
                                label="País"
                                required={true}
                                optionValue="Idpaises"
                                optionName="Descripcion"
                                options={list.paises}
                            />
                            <Select
                                id="especialidad"
                                name="especialidad"
                                onChange={setEspecialidad}
                                value={especialidad}
                                label="Especialidad"
                                required={true}
                                optionValue="Id"
                                optionName="Descripcion"
                                options={[
                                    {
                                        Id: 1,
                                        Descripcion:
                                            'Especialidad 1 Llamar desde API',
                                    },
                                ]}
                            />

                            <Input
                                label="Correo"
                                type="email"
                                onChange={setCorreo}
                                value={correo}
                                required={true}
                            />

                            {valor === 0 ? (
                                <>
                                    <div className="d-flex align-items-center">
                                        <Checkbox
                                            name="habeas"
                                            checked={globalCheckbox.habeas || ''}
                                            onChange={cambio}
                                        >
                                            Acepto Habeas
                                        </Checkbox>
                                        <Button onClick={openHabeas} className='btn-primary bg-primary text-white '>
                                            Abrir Habeas
                                        </Button>
                                    </div>
                                </>
                            ) : null}

                            {valor === 1 ? (
                                <>
                                    <div className="d-flex align-items-center">
                                        <Checkbox
                                            name="habeas"
                                            checked={globalCheckbox.habeas || ''}
                                            onChange={cambio}
                                        >
                                            Acepto Habeas
                                        </Checkbox>
                                        <Button onClick={openHabeas} className='btn-primary bg-primary text-white'>
                                            Abrir Habeas
                                        </Button>
                                    </div>

                                    <div className="d-flex align-items-center">
                                        <Checkbox
                                            name="consentimiento1"
                                            checked={globalCheckbox.consentimiento1 || ''}
                                            onChange={cambio}
                                        >
                                            Acepto Concentimiento 1
                                        </Checkbox>
                                        <Button onClick={openConstimiento1} className='btn-primary bg-primary text-white'>
                                            Abrir Concentimiento 1
                                        </Button>
                                    </div>

                                    <div className="d-flex align-items-center">
                                        <Checkbox
                                            name="consentimiento2"
                                            checked={globalCheckbox.consentimiento2 || ''}
                                            onChange={cambio}
                                        >
                                            Acepto Concentimiento 2
                                        </Checkbox>
                                        <Button onClick={openConstimiento2} className='btn-primary bg-primary text-white'>
                                            Abrir Concentimiento 2
                                        </Button>
                                    </div>
                                </>
                            ) : null}
                        </div>
                    </div>
                    <ButtonsContainer>
                        <LeftButtonContainer>
                            <Button type="back" onClick={onBack}>
                                Atrás
                            </Button>
                        </LeftButtonContainer>
                        <RightButtonContainer>
                            <Button
                                type="send"
                                loading={loading}
                                onClick={handleSubmit}
                            >
                                Enviar Inscripción
                            </Button>
                            
                           {/*  <button 
                                type="submit" className="btn btn-primary"
                            >
                                Enviar Inscripción
                            </button> */}
                        </RightButtonContainer>
                    </ButtonsContainer>
                </form>
            </>
        );
    };


   

    return (
        //! MAIN CONTAINER
        
        <>
        <FormContainer progress="45" style={{ width: '60%' }}>
    
            {Form()}

{/*             <Modal isOpen={isOpenModalHabeas} hasButton={true} close={openHabeas} >{<HabeasContainer />}</Modal>    
            <Modal isOpen={isOpenConcentimiento1} hasButton={true} close={openConstimiento1} >{<Consentimiento1 />}</Modal>    
            <Modal isOpen={isOpenConcentimiento2} hasButton={true} close={openConstimiento2} >{<Consentimiento2 />}</Modal>     */}

        </FormContainer>


        </>
    );
};

export { AspiranteForm };