import Swal from 'sweetalert2'
export function alerta(mensaje, tipo){
    Swal.fire({
        title: mensaje,
        icon: tipo,
        position: 'center',
        showConfirmButton: true,
        timer: '3000'
    });
}
export function alertaTimer(mensaje, tipo,timer){
    Swal.fire({
        title: mensaje,
        icon: tipo,
        position: 'center',
        showConfirmButton: true,
        timer: timer
    });

}

export function alertaSinBoton(message, type, position, timer){
    Swal.fire({
        position: position,
        icon: type,
        title: message,
        showConfirmButton: false,
        timer: timer
      })
    }


      export function alertaFooter(icon,title, text,footer){
        Swal.fire({
            icon: icon,
            title: title,
            text: text,
            footer: footer
          })
    }

    export function alertaDobleBtn( title, text, icon, confirmButtonText, usuarioLS ) {


        Swal.fire({
            title: title,
            text: text,
            icon: icon,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: confirmButtonText,
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              ) 
            }
        });
    }
          