import React from 'react'
import { HeaderHome } from '../../components/headerHome/HeaderHome'
import { Alert, Button } from 'react-bootstrap'
import { LocalStorage } from '../../services'
import { BASE_URL } from '../../services/api/config'

const Consentimiento2 = () => {
  const storage=new LocalStorage()
  const usuario=JSON.parse(storage.getItem('usuario'))
  const IdUsuario=usuario.usuario.IdUsuario
  return (
    <>
    <HeaderHome/>
    <div className='mx-auto w-75 d-flex flex-column justify-content-center align-items-center'>
        
        
        <h1 className='text-center'>CONSENTIMIENTO EXPRESO PARA TOMA DE EXAMEN TOXICOLÓGICO ASPIRANTES Y/O ESTUDIANTES RAC 120.315 TECNOLOGÍA EN GESTIÓN DE TRÁNSITO AÉREO</h1><br /><br />

        <p className=''>
            Yo en condición de aspirante y/o estudiante del CENTRO DE ESTUDIOS
            AERONÁUTICOS “CEA” INSTITUCIÓN DE EDUCACIÓN SUPERIOR”, de manera
            voluntaria y libre declaro lo siguiente:<br />
            Declaración inicial: Que me he inscrito y/o matriculado al programa académico
            “TECNOLOGÍA EN GESTIÓN DE TRÁNSITO AÉREO”, ofertado por El CENTRO DE
            ESTUDIOS AERONÁUTICOS “CEA” INSTITUCIÓN DE EDUCACIÓN SUPERIOR”,
            Código SNIES según Resolución No. 02909 del 21 de febrero de 2018 y resolución No.
            016859 del 19 de octubre 2018, por lo cual me comprometo a aceptar la normatividad
            aeronáutica contemplada en los Reglamentos Aeronáuticos de Colombia las normas
            nacionales vigentes y a cumplir con todo lo establecido en dichas normas.
            Declaración Consecuente: Que autorizo para que se me realice previa aprobación del
            Supervisor del Programa de PREVENCIÓN Y CONTROL DE USO INDEBIDO DE
            SUSTANCIAS PSICOACTIVAS, Manual vigilado por la Unidad Administrativa de
            Aeronáutica Civil: Una (1) prueba de sustancias psicoactivas en el momento de la
            vinculación académica (Matrícula), así como a pruebas aleatorias de sustancias
            psicoactivas de seguimiento (sin previo aviso) y varias pruebas de sustancias
            psicoactivas como sospecha justificada (sin previo aviso), en el desarrollo académico de
            la TECNOLOGÍA EN GESTIÓN DE TRÁNSITO AÉREO . Las pruebas debidamente
            certificadas tendrán la cadena de custodia correspondiente para su lectura y posterior
            archivo.<br /><br />
            Responsabilidad económica; Que acepto que el valor a pagar por todas las pruebas que
            se me realizarán (de ingreso, aleatoria, por sospecha justificada, o rehabilitación) serán
            sufragadas por mí, en el momento que se realice la misma.<br /><br />
                  

            Responsabilidad de riesgo: Manifiesto que soy responsable de cumplir los requisitos
            exigidos en los Reglamentos Aeronáuticos de Colombia RAC 120, relacionado con el
            Uso indebido de Sustancias Psicoactivas y que el resultado de los exámenes
            toxicológicos será evaluado con el fin de determinar si hay presencia de alcohol o
            drogas.<br /><br />
            Responsabilidad de valoración: Que autorizo para que el resultado de los exámenes
            toxicológicos sea socializado por el CENTRO DE ESTUDIOS AERONÁUTICOS “CEA”
            INSTITUCIÓN DE EDUCACIÓN SUPERIOR con el Grupo Admisión, Registro y Asuntos Jurídicos, Grupo de Educación Superior  y Movilidad Académica y Secretaría CEA; de ser necesario con un grupo interdisciplinario en el
            área de la salud, para un eventual seguimiento y ayuda profesional.<br /><br />

            Responsabilidad de aceptación: Que acepto que, en caso de que la prueba de examen
            toxicológico sea positiva, asumo completa responsabilidad de los resultados, incluidos
            los que en caso de duda pudiesen reputarse de índole administrativo o técnico entre las
            entidades y asumiré las consecuencias establecidas en la normatividad vigente,
            responsabilidad de negación: Si el aspirante o estudiante se niega en aplicarse la
            prueba toxicológica, si lapsa el tiempo de espera para aplicar la prueba, si llegare haber
            suplantación de persona o el estudiante dejaré las instalaciones del CENTRO DE
            ESTUDIOS AERONÁUTICOS “CEA” INSTITUCIÓN DE EDUCACIÓN SUPERIOR será
            retirado de la Institución Académica, sin perjuicio de incurrir en las faltas disciplinarias
            contempladas en el manual de convivencia de la Institución o en la normatividad vigente
            regulada por la Aeronáutica Civil de Colombia.<br /><br />
            Exoneración: Manifiesto que libero y exonero de responsabilidad y de cualquier
            indemnización y de establecer demandas en contra del CENTRO DE ESTUDIOS
            AERONÁUTICOS “CEA” INSTITUCIÓN DE EDUCACIÓN SUPERIOR, de los
            funcionarios y contratistas, así como a realizar reclamos por concepto de resultados
            “Positivo en las pruebas del examen toxicológico”, sin importar su causa o razón.
            En un solo ejemplar, este documento será vinculante para mí y mis representantes. Bajo
            mi firma certifico que he leído y entendido los riesgos que asumo y acepto de manera
            voluntaria este acuerdo en los términos pactados.

            <Alert  variant='success'>
                <Alert.Heading>Descarga y firma este consentimiento</Alert.Heading>
                <p>
                  <Button
                    variant='outline-success'
                    href={`${BASE_URL}/aspirante/descargarConsentimiento?Numero=2&IdUsuario=${IdUsuario}`}
                    target='_blank'
                  >
                    Descargar
                  </Button>
                </p>
            </Alert>
            </p>

    </div>
    </>
  )
}

export default Consentimiento2