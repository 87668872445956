import { Button, ListGroup, Modal } from "react-bootstrap"
import { Card } from "../../../../../components/card/Card"
import { DynamicTable } from "../../../../../components/DynamicForm/dynamicTable/DynamicTable"
import { Link, useLocation } from "react-router-dom"

import {BASE_URL} from "../../../../../services/api/config"
import DowloadFile from "../../../../../components/DowloadFile/DowloadFile"
import { useState } from "react"

const ListaEventos=()=>{

    const [modal,setModal]=useState();
    const [item,setItem]=useState();
    const{state:{ies,FechaInicio,FechaFin}}=useLocation();
    const enpoint=`instrumento/listaEventos?FechaInicio=${FechaInicio}&FechaFin=${FechaFin}&ies=${ies}`

    const actvidades = `${BASE_URL}/calificacionesCursos?FechaInicio=${FechaInicio}&FechaFin=${FechaFin}`;
    const actvidades_xlsx = `${BASE_URL}/exportar/calificacionesCursos?FechaInicio=${FechaInicio}&FechaFin=${FechaFin}`;
    const actvidades_ies = `${BASE_URL}/formatosIES/calificacionesCursos?FechaInicio=${FechaInicio}&FechaFin=${FechaFin}`;
    const actvidades_ies_xlsx = `${BASE_URL}/ies/exportar/calificacionesCursos?FechaInicio=${FechaInicio}&FechaFin=${FechaFin}`;

    const reporte_evento=`${BASE_URL}/exportar/reporteEvento?FechaInicio=${FechaInicio}&FechaFin=${FechaFin}&IdEvento=${item?.IdOferta}`;
    const reporte_evento_ies=`${BASE_URL}/formatosIES/exportar/reporteEvento?FechaInicio=${FechaInicio}&FechaFin=${FechaFin}&IdEvento=${item?.IdOferta}`;

    // links de descarga para percepcion de servicios educativos
    const percepcion_servicios_educativos = `${BASE_URL}/exportar/percepcionServiciosEducativos?FechaInicio=${FechaInicio}&FechaFin=${FechaFin}&IdOferta=${item?.IdOferta}`;
    const percepcion_servicios_educativos_ies = `${BASE_URL}/formatosIES/percepcionServiciosEducativos?FechaInicio=${FechaInicio}&FechaFin=${FechaFin}&IdOferta=${item?.IdOferta}`;
    // y links para evaluacion de egresados
    const evaluacion_egresados = `${BASE_URL}/exportar/evaluacionesEgresados?FechaInicio=${FechaInicio}&FechaFin=${FechaFin}&IdOferta=${item?.IdOferta}`;
    const evaluacion_egresados_ies = `${BASE_URL}/formatosIES/evaluacionesEgresados?FechaInicio=${FechaInicio}&FechaFin=${FechaFin}&IdOferta=${item?.IdOferta}`;


    return (
        <>
            <Card>
                <h2>Actividades Académicas</h2>
                <p>Perido: del {FechaInicio} al {FechaFin}</p>
                <div style={{textAlign:"right"}}>
                    <DowloadFile
                        endPoint={
                            !ies?actvidades:actvidades_ies
                        }
                        name={`Actividades Académicas.pdf`}
                        nameFile={`Actividades Academicas.pdf`}
                    />
                    <DowloadFile
                        endPoint={
                            !ies?actvidades_xlsx:actvidades_ies_xlsx
                        }
                        name={`Actividades Académicas.xlsx`}
                        nameFile={`Actividades Academicas.xlsx`}
                    />
                </div>
            </Card>
            <DynamicTable 
                endPoint={enpoint}
                titleColumn={["IdDocente","Nombre","Acciones"]}
                attributes={["Nombre"]}
                labelOptionClick={"Exportables"}
                handleOptionClick={(e)=>{
                    setModal(true)
                    setItem(e)
                }}
                onDelete={false}
                onUpdate={false}
            />

            <Modal 
                show={modal}
                onHide={()=>setModal()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Exportables</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup>
                        <ListGroup.Item>
                            <DowloadFile
                                endPoint={
                                    !ies?reporte_evento:reporte_evento_ies
                                }
                                name={"Calificaciones Actividad"}
                                nameFile={`Calificaciones Actividad.pdf`}
                            />
                        </ListGroup.Item>
                        {/* <ListGroup.Item>
                            <DowloadFile
                                endPoint={
                                    !ies?percepcion_servicios_educativos:percepcion_servicios_educativos_ies
                                }
                                name={"Percepción de servicios educativos"}
                                nameFile={`Percepción de servicios educativos.pdf`}
                            />
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <DowloadFile
                                endPoint={
                                    !ies?evaluacion_egresados:evaluacion_egresados_ies
                                }
                                name={"Evaluación de egresados"}
                                nameFile={`Evaluación de egresados.pdf`}
                            />
                        </ListGroup.Item> */}
                    </ListGroup>
                </Modal.Body>
            </Modal>            
        </>
    )
}

export {ListaEventos}