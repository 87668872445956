import { Card } from "../../components/card/Card"
import { Title } from "../../shared/components/title/Title"
import { useLocation } from "react-router-dom"
import { Button, Col, Modal, NavDropdown, Row, Tab, Tabs } from "react-bootstrap";
import { DynamicTable } from "../../components/DynamicForm/dynamicTable/DynamicTable";
import { BASE_URL } from "../../services/api/config";
import { LocalStorage } from "../../services";
import { Input } from "../../components/input/input";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { alerta } from "../../helpers/alertas";
import { DynamicForm } from "../../components/DynamicForm/DynamicForm";

const Docente=()=>{
    const store= new LocalStorage();
    const usuario=JSON.parse(store.getItem("usuario"))
    const IdUsuario=usuario.usuario.IdUsuario
    const Location=useLocation();
    const Navigate=useNavigate();
    const [fecha1,setFecha1]=useState(null);
    const [fecha2,setFecha2]=useState(null);
    const [modal1,setModal1]=useState(false);
    const [modal2,setModal2]=useState(false);
    const [modal3,setModal3]=useState(false);

    const docente=Location.state.docente;
   
    const urlCapacitaciones=`capacitacionesDocentes?IdDocente=${docente.IdDocente}`;
    const urlContratos=`contratosDocentes?IdDocente=${docente.IdDocente}`
    const urlDocumentos=`documentosDocentes?IdDocente=${docente.IdDocente}`

    const IdDocente=docente.IdUsuario;
    const NumeroDocumento=docente.NumeroDocumento
    const horasHalcon=`${BASE_URL}/docente/horasDocentesHalcon?IdDocente=${IdDocente}&IdUsuario=${IdUsuario}`
    const horasAlejandria=`${BASE_URL}/docente/horasDocentesAlejandria?NumeroDocumento=${NumeroDocumento}&IdUsuario=${IdUsuario}`

    const consultarAsignacion=()=>{
        if (fecha1!=null && fecha2!=null) {
            Navigate("asignacion_docente",{state:{docente:docente,fecha1:fecha1,fecha2:fecha2}})
        }else{
            alerta("Ingres las dos fechas")
        }
    }

    const actualizar=()=>{

    }

    return(
        <>
            <Card>
                <Title>
                    {docente.Nombre}
                </Title>
                <div style={{textAlign:"right"}}>
                    <NavDropdown title="Crear" style={{float:'right'}}>
                        <NavDropdown.Item 
                            onClick={()=>setModal1(true)}
                        >Capacitación</NavDropdown.Item>
                        <NavDropdown.Item 
                            onClick={()=>setModal2(true)}
                        >Contrato</NavDropdown.Item>
                        <NavDropdown.Item 
                            onClick={()=>setModal3(true)}
                        >Documento</NavDropdown.Item>                    
                    </NavDropdown>
                </div>
            </Card>
            <Card>
                <Tabs  defaultActiveKey='capacitaciones'>
                    <Tab eventKey='capacitaciones' title="Capacitaciones">
                        <DynamicTable
                            endPoint={urlCapacitaciones}
                            titleColumn={["N°","Nombre","Horas","Fecha","Acciones"]}
                            attributes={["NombreCurso","NumHorasCursadas","FechaCreacion"]}


                        />
                    </Tab>
                    <Tab eventKey='contratos' title="Contratos">
                        <DynamicTable 
                            endPoint={urlContratos}
                            titleColumn={["N°","Tipo de Contrato","Fecha de Expedición","Acciones"]}
                            attributes={["TipoContrato","FechaContrato"]}
                        />
                    </Tab>
                    <Tab eventKey='documentos' title="Documentos">
                        <DynamicTable 
                            endPoint={urlDocumentos}
                            titleColumn={["N°","Nombre","Fecha de Expedición","Acciones"]}
                            attributes={["Nombre","FechaExpedicion"]}
                        />
                    </Tab>
                    <Tab eventKey="reportes" title="Reportes">
                        <Row>
                            <Col>
                                <Card>
                                    <a target="blank" href={horasHalcon}>Horas Docente Halcón</a>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <a target="blank" href={horasAlejandria}>Horas Docente Alejandría</a>
                                </Card>
                            </Col>
                        </Row>
                        <Card>
                            <p>Indique el Periodo para Consultar la Asignación del Docente</p>
                        <Row>
                            <Col>
                                <Input 
                                    label="Fecha Inicial"
                                    type={'date'}
                                    value={fecha1}
                                    onChange={(e)=>setFecha1(e)}
                                />
                            </Col>
                            <Col>
                                <Input 
                                    label="Fecha Final"
                                    type={'date'}
                                    onChange={(e)=>setFecha2(e)}
                                    value={fecha2}
                                />
                            </Col>
                        </Row>
                        <div style={{textAlign:"right",paddingTop:30}}>
                            <Button 
                                onClick={()=>consultarAsignacion()}
                            >
                                Consultar Asignación
                            </Button>
                        </div>
                        </Card>                        
                    </Tab>
                </Tabs>
            </Card>

            <Modal show={modal1} onHide={()=>setModal1(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Nueva Capacitación</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DynamicForm 
                        justDynamicForm = {{ flag: true, idForm:"88"  }}
                        onClickCancelBtn={()=>setModal1(false)}
                        isCreate={true}
                        isContentTypeFormData="1"
                        extraData={{IdDocente:docente.IdDocente}}
                        accionMysql={()=>actualizar()}
                        endPointSubmit={"capacitacionesDocentes"}
                    />
                </Modal.Body>
            </Modal>
            <Modal show={modal2} onHide={()=>setModal2(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Nuevo Contrato</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DynamicForm 
                        justDynamicForm = {{ flag: true, idForm:"89"  }}
                        onClickCancelBtn={()=>setModal2(false)}
                        isCreate={true}
                        isContentTypeFormData="1"
                        extraData={{IdDocente:docente.IdDocente}}
                        accionMysql={()=>actualizar()}
                        endPointSubmit={"contratosDocentes"}
                    />
                </Modal.Body>
            </Modal>
            <Modal show={modal3} onHide={()=>setModal3(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Nuevo Documento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DynamicForm 
                        justDynamicForm = {{ flag: true, idForm:"90" }}
                        onClickCancelBtn={()=>setModal3(false)}
                        isCreate={true}
                        isContentTypeFormData="1"
                        extraData={{IdDocente:docente.IdDocente}}
                        accionMysql={()=>actualizar()}
                        endPointSubmit={"documentosDocentes"}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}


export {Docente}

