import React, { Fragment } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'; // Estilos del editor
import { useFetchGet } from '../../hooks/useFetchGet';

export default function FormsAR({tipo,dataAR,setDataAR}) {

    const [cuestionarios]=useFetchGet("cuestionariosLMS")
  return (
    <>
    {tipo.tipo=="Actividad" &&
    <>
            <Row>
            <Col>
                <Form.Group className="mb-3">
                <Form.Label>Fecha de inicio</Form.Label>
                <Form.Control
                    type="date"
                    placeholder="Fecha de inicio"
                    value={dataAR.fecha_inicio}
                    onChange={(e)=>{setDataAR({...dataAR,fecha_inicio:e.target.value})}}
                />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3">
                <Form.Label>Fecha de finalización</Form.Label>
                <Form.Control
                    type="date"
                    placeholder="Fecha de finalización"
                    value={dataAR.fecha_fin}
                    onChange={(e)=>{setDataAR({...dataAR,fecha_fin:e.target.value})}}
                />
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col>
                <Form.Group className="mb-3">
                <Form.Label>Tendra calificación?</Form.Label>
                <Form.Check
                type="checkbox"
                label="Si"
                checked={dataAR.RequiereCalificacion}
                onChange={(e)=>{setDataAR({...dataAR,RequiereCalificacion:e.target.checked})}}
                />
                </Form.Group>
            </Col>
            {dataAR.RequiereCalificacion &&
            <>
            <Col>            
                <Form.Group className="mb-3">
                <Form.Label>Porcentaje de calificación</Form.Label>
                <Form.Control
                    type="number"
                    value={dataAR.PorcentajeCalificacion}
                    onChange={(e)=>{setDataAR({...dataAR, PorcentajeCalificacion:e.target.value})}}
                />
                </Form.Group>

            </Col>
            <Col>
            <Form.Group className="mb-3">
                <Form.Label>Numero de intentos</Form.Label>
                <Form.Control
                    type="number"
                    value={dataAR.NumeroIntentos}
                    onChange={(e)=>{setDataAR({...dataAR,NumeroIntentos:e.target.value})}}
                />
            </Form.Group>
            </Col>
            </>
        }
        </Row>
        </>
    }
    <Form.Group className="mb-3">
      <Form.Label>Nombre</Form.Label>
      <Form.Control
        type="text"
        value={dataAR.nombre}
        onChange={(e)=>{setDataAR({...dataAR,nombre:e.target.value})}}
      />
    </Form.Group>
    <Form.Group className="mb-3">
      <Form.Label>Descripción</Form.Label>
      <Form.Control
        as="textarea"
        placeholder="Descripción"
        value={dataAR.descripcion}
        onChange={(e)=>{setDataAR({...dataAR,descripcion:e.target.value})}}
      />
    </Form.Group>
    {tipo.id==1 &&
    <Form.Group className="mb-3">
      <Form.Label>Enlace</Form.Label>
      <Form.Control
        type="text"
            placeholder="Enlace de youtube"
            value={dataAR.url}
            onChange={(e)=>{setDataAR({...dataAR,url:e.target.value})}}
        />
        </Form.Group>
    }
    {tipo.id==2 &&
    <Form.Group className="mb-3">
      <Form.Label>Enlace</Form.Label>
      <Form.Control
        type="text"
            placeholder="Enlace"
            value={dataAR.url}
            onChange={(e)=>{setDataAR({...dataAR,url:e.target.value})}}
        />
        </Form.Group>
    }

    {tipo.id==3 &&
        <Form.Group className="mb-3">
            {/* subir archivo */}
            <Form.Label>Archivo</Form.Label>
            <Form.Control
            type="file"
            placeholder="Archivo"
            onChange={(e)=>{setDataAR({...dataAR,archivo:e.target.files[0]})}}
            />
        </Form.Group>
    }
    {tipo.id==4 &&
        <div>
        <ReactQuill 
            value={dataAR.contenido}
            onChange={(e)=>{setDataAR({...dataAR,contenido:e})}}
            style={{ height: '300px' }}
            modules={{
                toolbar: [
                    [{ 'header': [1, 2, false] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                    ['link', 'image'],
                    ['clean']
                ],
            }}
        />
    </div>
    }
    {/* mostrar los custionarios diponibles  */}
    {tipo.id==6 &&
  
    <Form.Group className="mb-3">
            {console.log(cuestionarios)}  
    <Form.Label>Cuestionario</Form.Label>
    <Form.Select
        value={dataAR?.id_actividad}
        onChange={(e)=>{setDataAR({...dataAR,id_actividad:e.target.value})}}
    >
        <option value="">Seleccione un cuestionario</option>
        {cuestionarios.map((c)=>{
            return <option value={c?.id}>{c?.titulo}</option>
        })}
    </Form.Select>
    </Form.Group>
    }
    </>
  )
}
