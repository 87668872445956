import FullCalendar from "@fullcalendar/react"
import { useState } from "react"
import { useEffect } from "react"
import { useFetchGet } from "../../../hooks/useFetchGet"
import { LocalStorage } from "../../../services"

import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import esLocale from '@fullcalendar/core/locales/es';
import { useLocation } from "react-router-dom"
import { Card } from "../../../components/card/Card"
import { Button,Col } from "react-bootstrap"
import { AddButton } from "../../../components/componentsForm/addButton/addButton"
import { BASE_URL } from "../../../services/api/config"


function EstudianteDetalleCalendario () {
  const ls = new LocalStorage() 
  const {state:{item}} = useLocation()
  const IdUsuario = ls.getAsJsonItem('usuario').usuario.IdUsuario
  const [events,loading,error] = useFetchGet(`estudiante/calendario/eventos?IdOferta=${item.IdOferta}`)
  const urlexportarHorario=`${BASE_URL}/cursos/exportarHorario?IdOferta=${item?.IdOferta}`
  // const [events,loading,error] = useFetchGet(`eventosAula?IdAula=${IdUsuario}`)
  const [calendarEvents,setCalendarEvents] = useState([])

  const eventDataTransform = (event)=>{
      return(
        {
          id:event.IdEventoAula,
          title:event.Asignatura+','+event.Docente+', '+event.Aula,
          extendedProps:{
            aula:event.Aula,
            docente:event.Docente,
            asignatura:event.Asignatura
          },
          start:event.FechaInicio.replace(' ','T'),
          end:event.FechaFin.replace(' ','T'),
          allDay:false,
          classNames:['calendar__event']
        })
    
  }
  const eventContent=(arg) =>{
    console.log(arg)

    let aula=arg.event.extendedProps.aula
    let docente=arg.event.extendedProps.docente
    let asignatura=arg.event.extendedProps.asignatura
    let html = document.createElement('div')

    html.innerHTML='<li><i class="bi bi-geo-alt-fill"></i> '+aula+'</li>'
    +'<li>----</li>'
    +'<li><i class="bi bi-person-circle"></i> '+docente+'</li>'
    +'<li>----</li>'
    +'<li><i class="bi bi-book"></i> '+asignatura+'</li>'
    +'<li>----</li>'
    +'<li><i class="bi bi-clock"></i> '+arg.timeText+'</li>'
    // html+='<li>'+docente+'</li>' 
  
    let arrayOfDomNodes = [ html ]
    return { domNodes: arrayOfDomNodes }
}
  useEffect(()=>{
    (async=>{
      
    })()
  },[])
return (
  <>
  <Card>
  <Col><h2 className="d-flex justify-content-center mt-2">{item?.Nombre}</h2></Col>
  <Col className="d-flex flex-column gap-5 flex-md-row justify-content-end"><Button target="_blank" href={urlexportarHorario} >Exportar</Button></Col>
  </Card>
  <Card>

  
  <FullCalendar
    plugins={[ dayGridPlugin,timeGridPlugin, interactionPlugin ]}
    // dateClick={handleDateClick}
    initialView="dayGridMonth"
    // eventContent={renderEventContent}
    locale={esLocale}
    // eventClick={onEventClick}
    displayEventEnd={true}
    eventTimeFormat={ 
    { hour: '2-digit',
      minute: '2-digit',
      meridiem: true,
      hour12: true }
    }
    themeSystem={'bootstrap5'}
    headerToolbar= {
      {
        right: 'prev,next today',
        left: 'title',
        center: 'dayGridMonth,timeGridWeek,timeGridDay'
      }}
      events={ events}
      eventDataTransform={eventDataTransform}
      eventContent={eventContent}
  ></FullCalendar>  
  </Card>
  </>
)
}


export { EstudianteDetalleCalendario }