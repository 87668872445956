import React from 'react'

import './input.css'

const Input = ({
    id,
    label,
    type, 
    placeholder, 
    autoComplete,
    required = true,
    name,
    onChange, // return (value,name)
    value,
    className,
    onBlur,
    onPaste,
    tabIndex,
    disabled=false,
    min=null,
    max=null
}) => {

    const classInput = `form-control ${className}`
    return (
        <div className="form-group m-1">
            {
                label && (
                    <label 
                        htmlFor={ id }
                        className="input__label"
                    >
                        { label }
                    </label>
                )
            }
            <input
                id={ id }
                type={ type }
                placeholder={ placeholder }
                autoComplete={ autoComplete || 'off'}
                name={ name }
                onChange={ e => onChange(e.target.value, e.target.name) }
                onBlur={ onBlur }
                value={ value }
                required={ required ? 'required' : false }
                className={ classInput }
                disabled={disabled}
                onPaste={ onPaste }
                min={min}
                max={max}
                tabIndex={tabIndex}
                style={
                    type==='date'?{ borderStyle:'hidden',
                    borderBottomStyle:'solid'}:{}
                }
                maxLength={max}
            />
        </div>
    )
}

export { Input }