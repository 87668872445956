import { useState } from 'react'
import { Button, Card, Col, Modal, Row } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { DynamicTable } from '../../../../components/DynamicForm/dynamicTable/DynamicTable'
import DowloadFile from '../../../../components/DowloadFileText/DowloadFileText'
import { BASE_URL } from '../../../../services/api/config'


function ListadoAsignaturas() {
    const location = useLocation()
    const curso=location.state.programa
    const navigate = useNavigate()
 
 

    const urlAsignaturas = `docente/asignaturas?IdOferta=${location.state.programa.IdOferta}&IdUsuario=${location.state.idUsuario}`

    const onNavigate = (asignatura) => {
        navigate(`${asignatura.IdMalla}/asignatura_estudiantes`, {
            state: {
                programa: curso,
                asignatura,
                IdUsuario: location.state.idUsuario,
            },
        }) 
    }
    const onNavigate2 = (asignatura) => {
        navigate(`${asignatura.IdMalla}/momentos_evaluativos`, {
            state: {
                curso: curso,
                asignatura,
                IdUsuario: location.state.idUsuario,
            },
        }) 
    }
    
 

    return (
        <>
            <Card>
                <Row>
                    <Col>
                        <Card.Title>Asignaturas y Temas</Card.Title>
                    </Col>
                    <Col
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                        }}
                    >
                        <Button>
                            <DowloadFile
                                name={`Mis Asistencias`}
                                colortext={`#fff`}
                                nameFile={`Asignaturas.pdf`}
                                endPoint={`${BASE_URL}/docente/asistenciaDocenteLight?IdOferta=${curso.IdOferta}&IdUsuario=${location.state.idUsuario}`}
                           />
                        </Button>
                    </Col>
                </Row>
            </Card>
            <DynamicTable
                onActions={true}
                topHeadTitle={curso.Nombre}
                titleColumn={['N°', 'Descripción', 'Acción']}
                attributes={['Descripcion']}
                endPoint={urlAsignaturas}
                onDelete={false}
                onUpdate={false}
                labelOptionClick={curso?.IdEstadoOferta == 2 ? `Ver Estudiantes` : ''}
                handleOptionClick={curso?.IdEstadoOferta == 2 ? onNavigate : ''}
                labelOptionClick3={curso.IdDimension == 1 ? `Plan de Trabajo` : `Plan de Asignatura`}
                handleOptionClick3={(e)=>{
                    navigate(`${e.IdMalla}/plan_trabajo`, {
                        state: {
                            curso: curso,
                            asignatura: e,
                            IdUsuario: location.state.idUsuario,
                        },
                    })
                }}
                labelOptionClick2={ curso?.IdEstadoOferta == 2 ? `Programar Momentos` : ''}
                handleOptionClick2={curso?.IdEstadoOferta == 2 ? onNavigate2 : ''} 

                classNameOptionIcon={`bi bi-view-list`}
            ></DynamicTable>

        </>
    )
}

export { ListadoAsignaturas }
