import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    codigo: null,
    fecha1:null,
    fecha2:null,
};

const formularioSlice = createSlice({
    name: 'formulario',
    initialState,
    reducers: {
        setCodigo: (state, action) => {
            state.codigo = action.payload;
        },
        setFecha1: (state, action) => {
            state.fecha1 = action.payload;
        },
        setFecha2: (state, action) => {
            state.fecha2 = action.payload;
        },
    }
});

export const { setCodigo,setFecha1,setFecha2 } = formularioSlice.actions;

export default formularioSlice.reducer;