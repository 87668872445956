import React from 'react'
import { Accordion, Card, ListGroup } from 'react-bootstrap'
import { BASE_URL } from '../../../services/api/config' 

export default function FormatosMEN() {
  return (
    <>
        <Card>
            <Card.Title>Formatos MEN</Card.Title>
        </Card>
        <Accordion defaultActiveKey="0">
      {/* <Accordion.Item eventKey="0">
        <Accordion.Header>BIENESTAR</Accordion.Header>
        <Accordion.Body>
            <div style={{padding:20}}>
                <ListGroup>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/bienestar/Actividad_de_bienestar`}>
                        Actividad de Bienestar
                    </ListGroup.Item>
                </ListGroup>
            </div>
        </Accordion.Body>
      </Accordion.Item> */}

      {/* <Accordion.Item eventKey="1">
        <Accordion.Header>DERECHOS PECUNIARIOS</Accordion.Header>
        <Accordion.Body>
            <div style={{padding:20}}>
                <ListGroup>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/derechosPecunarios/Valores_de_matricula_IES_publicas_desde_2018`}>
                        Valores de Matrícula IES
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/derechosPecunarios/Otros_derechos_pecuniarios_ies_publicas_desde_2018`}>
                        Otros Derechos Pecuniarios IES
                    </ListGroup.Item>
                </ListGroup>
            </div>
        </Accordion.Body>
      </Accordion.Item> */}

      {/* <Accordion.Item eventKey="2">
        <Accordion.Header>EXTENSIÓN</Accordion.Header>
        <Accordion.Body>
            <div style={{padding:20}}>
                <ListGroup>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/extencion/Actividad_cultural`}>
                        Actividad Cultural
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/extencion/Consultoria`}>
                        Consultoría
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/extencion/Cursos`}>
                        Cursos
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/extencion/Educacion_continua`}>
                        Educación Continua
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/extencion/Evento_cultural`}>
                        Evento Cultural
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/extencion/Proyecto_de_extension`}>
                        Proyecto de Extensión
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/extencion/Servicios_de_extension`}>
                        Servicios de Extensión
                    </ListGroup.Item>
                </ListGroup>
            </div>
        </Accordion.Body>
      </Accordion.Item> */}
{/* 
      <Accordion.Item eventKey="3">
        <Accordion.Header>INFORMACIÓN INSTITUCIONAL</Accordion.Header>
        <Accordion.Body>
            <div style={{padding:20}}>
                <ListGroup>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/informacionInstitucional/Calendario_academico`}>
                        Calendario Académico
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/informacionInstitucional/Informacion_complementaria_de_la_IES`}>
                        Información Complementaria de la IES
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/informacionInstitucional/Recurso_historico`}>
                        Recurso Histórico
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/informacionInstitucional/Unidad_organizacional`}>
                        Unidad Organizacional
                    </ListGroup.Item>
                </ListGroup>
            </div>  
        </Accordion.Body>
      </Accordion.Item> */}
{/* 
      <Accordion.Item eventKey="4">
        <Accordion.Header>INTERNACIONALIZACIÓN</Accordion.Header>
        <Accordion.Body>
            <div style={{padding:20}}>
                <ListGroup>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/internacionalizacion/Convenios_internacionales`}>
                        Convenios Internacionales
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/internacionalizacion/Movilidad_de_docentes_del_exterior_hacia_colombia`}>
                        Movilidad de Docentes del Exterior hacia Colombia
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/internacionalizacion/Movilidad_de_docentes_hacia_el_exterior`}>
                        Movilidad de Docentes hacia el Exterior
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/internacionalizacion/Movilidad_de_estudiantes_del_exterior_hacia_colombia`}>
                        Movilidad de Estudiantes del Exterior hacia Colombia
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/internacionalizacion/Movilidad_de_estudiantes_hacia_el_exterior`}>
                        Movilidad de Estudiantes hacia el Exterior
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/internacionalizacion/Movilidad_de_personal_administrativo_del_exterior_hacia_colombia`}>
                        Movilidad de Personal Administrativo del Exterior hacia Colombia
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/internacionalizacion/Movilidad_de_personal_administrativo_hacia_el_exterior`}>
                        Movilidad del Personal Administrativo hacia el Exterior
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/internacionalizacion/Programas_presenciales_ofrecidos_en_el_exterior`}>
                        Programas Presenciales Ofrecidos en el Exterior
                    </ListGroup.Item>
                </ListGroup>
            </div>
        </Accordion.Body>
      </Accordion.Item>
       */}
      {/* <Accordion.Item eventKey="5">
        <Accordion.Header>INVESTIGACIÓN</Accordion.Header>
        <Accordion.Body>
            <div style={{padding:20}}>
                <ListGroup>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/investigacion/Centros_de_investigacion`}>
                        Centro de Investigación
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/investigacion/Grupos_de_investigacion`}>
                        Grupos de Investigación
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/investigacion/Otras_actividades_de_investigacion`}>
                        Otras Actividades de Investigación
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/investigacion/Productos_de_investigacion`}>
                        Productos de Investigación
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/investigacion/Proyectos_de_investigacion`}>
                        Proyectos de Investigación
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/investigacion/Redes_de_investigacion`}>
                        Redes de Investigación
                    </ListGroup.Item>
                </ListGroup>
            </div>
        </Accordion.Body>
      </Accordion.Item> */}

      <Accordion.Item eventKey="6">
        <Accordion.Header>PARTICIPANTE</Accordion.Header>
        <Accordion.Body>
            <div style={{padding:20}}>
                <ListGroup>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/participante/Participante`}>
                        Participante
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/participante/Actualizar_documento_participante`}>
                        Actualizar Documento Participante
                    </ListGroup.Item>
                </ListGroup>
            </div>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="7">
        <Accordion.Header>POBLACIÓN ESTUDIANTIL</Accordion.Header>
        <Accordion.Body>
            <div style={{padding:20}}>
                <ListGroup>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Admitidos`}>
                        Admitidos
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Apoyos_financieros__academicos_u_otros`}>
                        Apoyos Financieros Académicos u Otros
                    </ListGroup.Item>
                    {/* <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Cupos_proyectados_y_matricula_esperada`}>
                        Cupos Proyectados y Matrícula Esperada
                    </ListGroup.Item> */}
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Estudiantes__de_primer_curso`}>
                        Estudiantes de Primer Curso
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Estudiantes_de_articulacion`}>
                        Estudiantes de Articulación
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Estudiantes_de_estrategias_de_cobertura`}>
                        Estudiantes de Estrategias de Cobertura
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Estudiantes_primer_curso_masivo`}>
                        Estudiantes Primer Curso Masivo
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Graduados`}>
                        Graduados
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Graduados_masivo`}>
                        Graduados Masivos
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Inscrito_programa`}>
                        Inscritos Programa
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Inscritos_relacion_de_inscritos`}>
                        Inscritos - Relación de Inscritos
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Materias_matriculado`}>
                        Materias Matriculado
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Matriculados`}>
                        Matriculados
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Matriculados_masivo`}>
                        Matriculados Masivo
                    </ListGroup.Item>
                    {/* <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Retiros_estudiantiles`}>
                        Retiros Estudiantes
                    </ListGroup.Item> */}
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/CaracterizacionEstudiantes`}>
                        Caracterización de Estudiantes
                    </ListGroup.Item>
                </ListGroup>
            </div>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="8">
        <Accordion.Header>RECURSO HUMANO</Accordion.Header>
        <Accordion.Body>
            <div style={{padding:20}}>
                <ListGroup>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/recursoHumano/Autoridades`}>
                        Autoridades
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/recursoHumano/Directivos`}>
                        Directivos
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/recursoHumano/Docentes_IES`}>
                        Docentes IES
                    </ListGroup.Item>
                     
                </ListGroup>
            </div>
        </Accordion.Body>
      </Accordion.Item>

    </Accordion>
    </>
  )
}
