import axios from 'axios';
import fileDownload from 'js-file-download';
import React from 'react'
import { useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { alerta } from '../../helpers/alertas';
import { ACCESS_TOKEN } from '../../services/api/config';

function handleFileDownload() {
  console.log('Archivo descargado!');
}

export default function DowloadFile({
    endPoint,
    nameFile,
    name,
    icono,
    colortext,
    icon,
    classname
}){
    const getTokenLocal = async()=> localStorage.getItem(ACCESS_TOKEN)?localStorage.getItem(ACCESS_TOKEN):'empty token in localstorage'
    const [cargando,setCargando]=useState(false)
    function handleClick(event) {
        event.preventDefault();
        const functionName = event.currentTarget.getAttribute('data-onclick');
        if (functionName) {
            dowloadFile();
        }
    }
    const dowloadFile=async()=>{
        setCargando(true)
        return getTokenLocal()
        .then((token)=>{
            axios({
                url: endPoint,
                method: 'GET',
                responseType: 'blob',
                headers:{
                    Authorization: `Bearer ${token}`
                }
              }).then((response) => {
                // detecar si a ocurido un error
                console.log(response.data)
                if(response.data.type==='application/json'){
                    setCargando(false)

                        const reader = new FileReader();
                        reader.addEventListener('loadend', (e) => {
                            const text = e.srcElement.result;
                            const json = JSON.parse(text);
                            return alerta(json.mensaje,'error')
                        });

                        reader.readAsText(response.data);
                }else{
                    fileDownload(response.data, nameFile);
                    setCargando(false)
                }

              });
        })
    }

  return (
    <>
        <a
            style={{ margin: 5, color: colortext }}
            href={endPoint}
            onClick={handleClick}
            data-onclick="dowloadFile"
            className={classname}
        >

        {icon} {name}

        </a>
        <Modal
            show={cargando}
            centered
        >
            <Modal.Body>
                <div style={{textAlign:"center"}}>
                    <Spinner animation="border" role="status"/>
                    Descargando archivo
                </div>
            </Modal.Body>
        </Modal>

    </>
  )
}
