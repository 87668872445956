import { useLocation } from 'react-router-dom'
import { Checkbox } from '../../../components/componentsForm/checkbox/checkbox'
import user from '../../../assets/img/avatar.jpg'
import { Button } from '../../../components/button/button'
import { useState } from 'react'
import { FormService } from '../../../services/api/formService'
import { alertaSinBoton } from '../../../helpers/alertas'
import { optionsDate } from '../../../shared/util/date'
import { ICON } from '../../../constants/icons'
import { Card, Table } from 'react-bootstrap'

function CalificarAsistencias({ canEdit }) {
    const {
        state: { estudiantes, programa, asignatura, evento, IdUsuario},
    } = useLocation()
    const [estudiantesAsistencia, setEstudiantesAsistencia] = useState(
        estudiantes || []
    )

    const onUpdateAsistenciaEstudiante = (value, name, estudiante) => {
    
        let updateEstudiante = {
            ...estudiante,
            Asistencia: value === false ? '0' : '1',
        }
        const newEstudianteList = estudiantesAsistencia.map((es) => {
            if (es.IdEstudianteAsistencia === estudiante.IdEstudianteAsistencia)
                return updateEstudiante
            return es
        })

        setEstudiantesAsistencia([...newEstudianteList])
    }
    const onSave = () => {
        const api = new FormService()
        const data = estudiantesAsistencia.map((item) => {
            return {
                // IdEstudiante:item.IdEstudiante,
                Asistencia: item.Asistencia,
                IdEstudianteAsistencia: item.IdEstudianteAsistencia,
                // FechaInicio:evento.FechaInicio
            }
        })
        console.log(data)
        // api.create('docente/planillaAsistencia?IdEventoAula=11',data).then(res=>{
        api.create('docente/guardarAsistencias', { asistencias: data,IdDocente:IdUsuario }).then(
            (res) => {
                console.log(
                    'se ha guardado con exito',
                    estudiantesAsistencia,
                    res
                )
                alertaSinBoton('Guardado!', 'success', 'bottom-end', 1500)
            }
        )
    }
    return (
        <>
            <Card>
                <Card.Title>
                    {asignatura.Descripcion}
                </Card.Title>
                <p>
                {new Date(evento.FechaInicio).toLocaleDateString('es-co', optionsDate)} {'  de '}
                        {new Date(evento.FechaInicio).toLocaleTimeString('es-CO')} {' a '} {new Date(evento.FechaFin).toLocaleTimeString('es-CO')}
                </p>
            </Card>

            <Table>
                <thead >
                    <tr>
                        <th>
                            Estudiante
                        </th>
                        {/* <th>Asistencia</th> */}
                        <th>
                            Asistió
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {!!estudiantesAsistencia &&
                        estudiantesAsistencia.map(
                            (estudiante, index) => (
                                <tr key={index} className="row-student">
                                    <td>
                                        {/* <div className="content-student">
                                            <img
                                                src={
                                                    estudiante?.Foto ||
                                                    user
                                                }
                                                alt={`foto estudiante ${estudiante.PrimerNombres} ${estudiante.PrimerApellidos}`}
                                                className="applicant-list__img-user student"
                                            /> */}
                                            <span>
                                                {
                                                    estudiante.Nombre
                                                }
                                            </span>
                                        {/* </div> */}
                                    </td>
                                    <td>
                                        {canEdit ? (
                                            <Checkbox
                                                checked={
                                                    estudiante.Asistencia ===
                                                    '0'
                                                        ? false
                                                        : true
                                                }
                                                label="Asistió"
                                                value={
                                                    estudiante.Asistencia ===
                                                    '0'
                                                        ? false
                                                        : true
                                                }
                                                onChange={(
                                                    value,
                                                    name
                                                ) =>
                                                    onUpdateAsistenciaEstudiante(
                                                        value,
                                                        name,
                                                        estudiante
                                                    )
                                                }
                                            ></Checkbox>
                                        ) : (
                                            <div>
                                                {estudiante.Asistencia ===
                                                '0'
                                                    ? ICON.UNCHECKED
                                                    : ICON.CHECKED}
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            )
                        )}
                </tbody>
            </Table>
            {canEdit && (
                <Button
                    type={'send'}
                    onClick={() => {
                        onSave()
                    }}
                >
                    Guardar
                </Button>
            )}
        </>
    )
}

export { CalificarAsistencias }
