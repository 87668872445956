import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Card } from "../../../components/card/Card";
import { DynamicForm } from "../../../components/DynamicForm/DynamicForm";
import { Select } from "../../../components/select/Select";
import { useFetchGet } from "../../../hooks/useFetchGet";
import { SubTitle } from "../../../shared/components/subtitle/SubTitle";
import { Title } from "../../../shared/components/title/Title";


function EvaluacionCurso (props) {

  const {state:{evaluacion}} = useLocation()
  const [docentes]=useFetchGet(`instrumento/docentesParticipantes?IdOferta=${evaluacion.IdOferta}`)
  const [arrayDocentes,setAraayDocentes]=useState({})
  
  const retroceder=()=>{
    setTimeout(() => { 
      window.history.go(-1)
    }, 3000);
  }

  const onChangeHandler = (name,id) => {
    console.log(name, id)
    setAraayDocentes({...arrayDocentes,[name]:id})

    console.log(arrayDocentes)
  }


return (
  <>
  {evaluacion.Docente!="No aplica"&&
    <Card>
    <Title>Asignatura {evaluacion.Asignatura}</Title>
    <SubTitle>Docente {evaluacion.Docente} </SubTitle>
  </Card>
  }
  
  <Card>

 <DynamicForm
    title={evaluacion.nombre}
    parentField = { null}
    ventanaFormName={evaluacion.nombre }
    extraData={{IdGestionInstrumento:evaluacion.IdGestionInstrumento,docentes:arrayDocentes}}
    endPointSubmit={'instrumento'}
    // keySubmit={'k'}
    isContentTypeFormData={'0'}
    isCreate={true}
    justDynamicForm={{flag:true,idForm:evaluacion?.IdForm}}
    accionMysql={()=>retroceder()}
    retroceder={true}
 >
  {evaluacion?.IdForm==49&&
    <>
      <SubTitle>
        {`6. Evalúe la participación de un docente (s) o conferencistas (s). Por favor seleccione según corresponda, siendo 5 la nota más alta y 1 la más baja.`}
      </SubTitle>

      {docentes && docentes.map((docente,key)=>
        <Select
          key={key}
          label={docente.Nombre}
          name={docente.IdUsuario}
          optionName={"nombre"}
          value={arrayDocentes[docente.IdUsuario]}
          optionValue={docente.IdUsuario}
          onChange={(e)=>onChangeHandler(docente.IdUsuario,e)}
          urlOptionsService={"calificacion"}
        />
      )}

    </>
    
  
  }

 </DynamicForm>

  </Card>
  </>
  )
}

export { EvaluacionCurso }