
import FullCalendar from "@fullcalendar/react"
import { useState } from "react"
import { useEffect } from "react"
import { LocalStorage } from "../../services"

import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import esLocale from '@fullcalendar/core/locales/es';
import { useLocation } from "react-router-dom"
import { Card } from "../../components/card/Card"
import { useFetchGet } from "../../hooks/useFetchGet"
import { Button, Col, Row } from "react-bootstrap"
import { BASE_URL } from "../../services/api/config"
import DowloadFile from "../../components/DowloadFile/DowloadFile"


function AsignacionDocente () {
  const ls = new LocalStorage() 
  const {state:{docente,fecha1,fecha2}} = useLocation()
  const IdUsuario = ls.getAsJsonItem('usuario').usuario.IdUsuario || 'empty'
  const [events,loading,error] = useFetchGet(`docente/asginacionDocente?IdUsuario=${docente.IdUsuario}&fecha1=${fecha1}&fecha2=${fecha2}`)
  const [calendarEvents,setCalendarEvents] = useState([])
  const urlPdf=`${BASE_URL}/docente/exportarHorario?IdUsuario=${docente.IdUsuario}&fecha1=${fecha1}&fecha2=${fecha2}`

  const eventDataTransform = (event)=>{
      return(
        {
          id:event.IdEventoAula,
          title:event.Asignatura+','+event.Docente+', '+event.Aula,
          extendedProps:{
            aula:event.Aula,
            docente:event.Docente,
            asignatura:event.Asignatura
          },
          start:event.FechaInicio.replace(' ','T'),
          end:event.FechaFin.replace(' ','T'),
          allDay:false,
          classNames:['calendar__event']
        })
    
  }

  const eventContent=(arg) =>{
    console.log(arg)

    let aula=arg.event.extendedProps.aula
    let docente=arg.event.extendedProps.docente
    let asignatura=arg.event.extendedProps.asignatura
    let html = document.createElement('div')

    html.innerHTML='<li><i class="bi bi-geo-alt-fill"></i> '+aula+'</li>'
    +'<li>----</li>'
    +'<li><i class="bi bi-book"></i> '+asignatura+'</li>'
    +'<li>----</li>'
    +'<li><i class="bi bi-clock"></i> '+arg.timeText+'</li>'
    let arrayOfDomNodes = [ html ]
    return { domNodes: arrayOfDomNodes }
}
  useEffect(()=>{
    (async=>{

    })()
  },[])

  const descargarPdf=()=>{
    window.open(urlPdf,"blank")
  }


return (
  <>
  <h2 className="d-flex justify-content-center mt-2">
  </h2>
  <Card>
    <Row>
        <Col>
        <h4>{docente?.Nombre}</h4>
            <p>Asignación del {fecha1} al {fecha2}</p>
        </Col>
        <Col>
        <div style={{textAlign:'right'}}>
          <DowloadFile
            nameFile="HorarioDocente.pdf"
            name="Exportar"
            endPoint={`${BASE_URL}/docente/exportarHorario?IdUsuario=${docente.IdUsuario}&fecha1=${fecha1}&fecha2=${fecha2}`}
          />
            {/* <Button 
                onClick={()=>descargarPdf()}
            >
                Exportar
            </Button> */}
        </div>
        </Col>
    </Row>

  </Card>
    <Card>
    <FullCalendar
        plugins={[ dayGridPlugin,timeGridPlugin, interactionPlugin ]}
        initialView="dayGridMonth"
        locale={esLocale}
        displayEventEnd={true}
        eventTimeFormat={ 
        { hour: '2-digit',
            minute: '2-digit',
            meridiem: true,
            hour12: true }
        }
        themeSystem={'bootstrap5'}
        headerToolbar= {
            {
            right: 'prev,next today',
            left: 'title',
            center: 'dayGridMonth,timeGridWeek,timeGridDay'
            }
        }
        events={ events}
        eventDataTransform={eventDataTransform}
        eventContent={eventContent}
    ></FullCalendar>
    </Card>
    
  </>
  )
}

export { AsignacionDocente }