import React, { useState } from 'react'
import { DynamicTable } from '../../components/DynamicForm/dynamicTable/DynamicTable'
import { Card } from 'react-bootstrap'
import { DynamicSearch } from '../../components/DynamicForm/DynamicSearch/DynamicSearch'
import { useFetchGet } from '../../hooks/useFetchGet'
import { useNavigate } from 'react-router-dom'
export default function ItemsEstabilizacion() {
    // consultar items
    const[items,isLoading]=useFetchGet('xcampos?id_formulario=163')
    const navigate = useNavigate()
    const [endPoint, setEndPoint] = useState('itemsEstabilizacion')
    const [busqueda, setBusqueda] = useState('')
    const onSearch = (data) => {
        setEndPoint(`itemsEstabilizacion${data}`)
        setBusqueda(data)
    }
  return (
    <>
        <Card>
            <Card.Title>Items de Estabilizacion</Card.Title>
            {!isLoading &&
                <DynamicSearch camposForm={items} onSearch={onSearch} />
            }
        </Card>
        <DynamicTable 
            endPoint={endPoint}
            busqueda={busqueda}
            titleColumn={['Id','Estado','Grupo', 'Item','Acciones']}
            attributes={["EstadoItem","Grupo", "Item"]}
            onDelete={false}
            onUpdate={false}
            handleOptionClick={(e)=>navigate(`${e.IdItemEstabilizacion}`,{state:{Item:e}})}
            labelOptionClick={'Ver más'}
        />
    </>
  )
}
