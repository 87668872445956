import { configureStore } from '@reduxjs/toolkit';
import userSlice  from '../../pages/Login/UserSlice';
import FormSlice from '../../components/DynamicForm/FormSlice';
import formularioSlice from '../../redux/formularioSlice';
import tableSlice from './table/tableSlice';


export const store = configureStore({
  reducer: {
    user:userSlice,
    form:FormSlice,
    table:tableSlice,
    formulario:formularioSlice,
  },
});
