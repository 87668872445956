import React, { useState, useEffect } from 'react'

import { AsignaturaProfesor } from './AsignaturaProfesor'
import { useForm } from '../../../hooks/useForm'
import { FormService } from '../../../services/api/formService'

const AsignaturaProfesorContainer = () => {

    const [selectList, setSelectList] = useState([])
    const {
        onCreate,
        onDelete,
        onUpdate,
        allData,
        data,
        isOpenForm,
        isOpenDelete,
        isCreate,
        handleSubmit,
        handleChange,
        handleClickDelete,
        handleClickAdd,
        deleteTuple,
        loading,
        setData,
        setLoading,
        loadAllData,
    } = useForm(
        'asignaturaProfesor',
        'IdAsignaturaProfesor',
        null,
        null,
        [
            'Asginatura', 
            'Edificio', 
            'Nombre', 
            'Aula', 
            'IdAsignatura'
        ]
    )
    
    const fecthDataSelect = async () => {
        try {
            const formService = new FormService()
            const mesh = await formService.getAll('asignaturaProfesor/mallas')
            const profile = await formService.getAll('persona')
            const classroom = await formService.getAll('aulas')
            const calendar = await formService.getAll('calendarioDetalle')
            const type = await formService.getAll('tipoCurso')
            setSelectList({
                mesh,
                profile,
                classroom,
                calendar,
                type,
            })
        } catch (err) {

        }
    }

    useEffect(() => {
        const fecthData = async () => {
            await fecthDataSelect()
        }
        fecthData()
    }, []);

    const title = [
        'ID',
        'Nombre',
        'Asginatura',
        'Edificio - Aula',
        'Horas',
        'Acciones',
    ]

    const attributes = [
        'Nombre',
        'Asginatura',
        'Edificio',
        'Horas',
    ]

    return (
        <AsignaturaProfesor
            onCreate={ onCreate }
            title={ title }
            asignaturasProfesor={ allData }
            asignaturaProfesor={ data }
            attributes={ attributes }
            onDelete={ onDelete }
            onUpdate={ onUpdate }
            isOpenForm={ isOpenForm }
            isOpenDelete={ isOpenDelete }
            handleSubmit={ handleSubmit }
            handleChange={ handleChange }
            loading={ loading }
            handleClickAdd={ handleClickAdd }
            isCreate={ isCreate }
            handleClickDelete={ handleClickDelete }
            deleteTuple={ deleteTuple }
            selectList={ selectList }
        />
    )
}

export { AsignaturaProfesorContainer }