import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useFetchGet } from '../../../../hooks/useFetchGet'
import { FormService } from '../../../../services/api/formService'

function CalendarioAcademicoContainer() {
    // const apiForm = new FormService()
    // const [data, loading, error] = useFetchGet('aulas')
    const navigate = useNavigate()

    useEffect(() => {
        navigate('aulas') // to CalendarioAulasContainer.jsx
    }, [])

    return (
        <>
            {/* <CalendarioAulas
    ></CalendarioAulas> */}
        </>
    )
}

export { CalendarioAcademicoContainer }
