import React, { useEffect, useState } from 'react'
import { Button, Tabs, Tab, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, Col, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { Input } from '../../../input/input' 
import { Select } from '../../../select/Select'
import { Form, Card } from 'react-bootstrap' 
import { TextArea } from '../../../componentsForm/textarea/textarea'
import { InputFile } from '../../../inputFile/inputFile'
import { Message } from '../../../Message/Message'
import { Checkbox } from '../../../componentsForm/checkbox/checkbox'
import { alerta, alertaSinBoton } from '../../../../helpers/alertas'
import { useUpdate } from '../../../../hooks/useUpdate'
import { LocalStorage } from '../../../../services'
import { FormService } from '../../../../services/api/formService'
import Header from '../../../../modules/componentesC/PerfilUsuario/Componentes/Header'
import { tr } from 'faker/lib/locales'
import { DynamicForm } from '../../../DynamicForm/DynamicForm' 
import { DynamicTable } from '../../../DynamicForm/dynamicTable/DynamicTable'
import { BASE_URL } from '../../../../services/api/config'
import DowloadFile from '../../../DowloadFile/DowloadFile' 

export default function ActualizarDatosAspirante(
    {Docente=false}
) {
    /* const {state:{usuario}}=useLocation() */
    const location = useLocation();
    /* const { state: { offer } } = location; */
    const [userData, setUserData] = useState({})
    const [userDataFuncionario, setUserDataFuncionario] = useState({})
    const [userDataInscripcion, setUserDataInscripcion] = useState({})
    const [userDataMadre, setUserDataMadre] = useState({})
    const [userDataPadre, setUserDataPadre] = useState({})
    const [userDataConyuge, setUserDataConyuge] = useState({})
    const [userDataIcfes, setUserDataIcfes] = useState({})
    const [userDataEducacionSec, setUserDataEducacionSec] = useState({})
    const [userDataEducacionSup, setUserDataEducacionSup] = useState({})
    const [userDataActividades, setUserDataActividades] = useState({})
    const [userDataAspectos, setUserDataAspectos] = useState({})
    const [userDataInfoLaboral, setUserDataInfoLaboral] = useState({})
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [ready, setReady] = useState(false)
    const [update, errorUpdate] = useUpdate('usuario')
    const localStorage = new LocalStorage()
    const user = localStorage.getAsJsonItem('usuario')
    const ID = user?.usuario?.IdUsuario
    const formService = new FormService()
    const api= new FormService()
    let presentaDiscapacidad;
    const [discapicadActiva, setDiscapicadActiva] = useState(1);
    const [funcionarioSw, setFuncionarioSw] = useState(false)
    const [autoridad, setAutoridad] = useState(false)
    const [directivo, setDirectivo] = useState(false)
    const [capacitacionNac, setCapacitacionNac] = useState(false)
    const [sancionDisciplina, setSancionDisciplina] = useState(false)
    const [sancionInfraccionReglamento, setSancionInfraccionReglamento] = useState(false)
    const [madreVive, setMadreVive] = useState(false);
    const [padreVive, setPadreVive] = useState(false);
    const [victimaConflictoArmado, setVictimaConflictoArmado] = useState(false);
    const [perteneceGrupoVulnerable, setPerteneceGrupoVulnerable] = useState(false);
    const [poblacionNecesidadEspecial, setPoblacionNecesidadEspecial] = useState(false);
    const [validacionSecundaria, setValidacionSecundaria] = useState(false);
    const [gradoHonorSecundaria, setGradoHonorSecundaria] = useState(false);
    const [solicitaHomologacion, setSolicitaHomologacion] = useState(false);
    const [actualmenteTrabaja, setActualmenteTrabaja] = useState(false);
    const urlHojadeVida = `${BASE_URL}/docente/hoja_de_vida?IdUsuario=${ID}`
    
    const [artes, setArtes] = useState(false);
    const [cine, setCine] = useState(false);
    const [danzas, setDanzas] = useState(false);
    const [ecologia, setEcologia] = useState(false);
    const [literatura, setLiteratura] = useState(false);
    const [musica, setMusica] = useState(false);
    const [pintura, setPintura] = useState(false);
    const [teatro, setTeatro] = useState(false);
    const [aerobics, setAerobics] = useState(false);
    const [baloncesto, setBaloncesto] = useState(false);
    const [ciclismo, setCiclismo] = useState(false);
    const [futbol, setFutbol] = useState(false);
    const [gimnasio, setGimnasio] = useState(false);
    const [karate, setKarate] = useState(false);
    const [natacion, setNatacion] = useState(false);
    const [tenis, setTenis] = useState(false);
    const [veisbol, setVeisbol] = useState(false);
    
    const [calidadServicio, setCalidadServicio] = useState(false);
    const [condionesAdmision, setCondionesAdmision] = useState(false);
    const [costosMatricula, setCostosMatricula] = useState(false);
    const [horario, setHorario] = useState(false);
    const [nivelAcademico, setNivelAcademico] = useState(false);
    const [nivelFormacionOfrecido, setNivelFormacionOfrecido] = useState(false);
    const [planEstudioVinculacionLaboral, setPlanEstudioVinculacionLaboral] = useState(false);
    const [ubicacionGeografica, setUbicacionGeografica] = useState(false);
    
    const [autorizaDatosUser, setAutorizaDatosUser] = useState(false)
    const [autorizacionDat, setAutorizacionDat] = useState(false/* AutorizacionDatos */)
    const [solicitoInscripcion, setSolicitoInscripcion] = useState();
    const [pasos, setPasos] = useState("informacion_general")
    const [modal, setModal] = useState(false)
    const [modal2, setModal2] = useState(false)
    const [modalLaboral, setModalLaboral] = useState(false)
    const [modalLaboral2, setModalLaboral2] = useState(false)
    const [modalEducacionenCurso, setModalEducacionenCurso] = useState(false)
    const [modalEducacionenCurso2, setModalEducacionenCurso2] = useState(false)
    const [modalOtrosEstudios, setModalOtrosEstudios] = useState(false)
    const [modalOtrosEstudios2, setModalOtrosEstudios2] = useState(false)
    const [modalEstudioAmbitoAeronautico, setModalEstudioAmbitoAeronautico] = useState(false)
    const [modalEstudioAmbitoAeronautico2, setModalEstudioAmbitoAeronautico2] = useState(false)
    const [modalAsociacionRedAcademica, setModalAsociacionRedAcademica] = useState(false)
    const [modalAsociacionRedAcademica2, setModalAsociacionRedAcademica2] = useState(false)
    const [endpointLaboral, setEndpointLaboral] = useState(`informacionLaboralUsuario?IdUsuario=${ID}`)
    

    const {
        FechaPago,
        NumeroRecibo,
        Inscripcion,
        Periodo,
        ProgramaActividad,
        Metodologia,
        Jornada,
        HabiaSolicitadoInscripcion
    } = userDataInscripcion

    const {
        PrimerNombres,
        SegundoNombre,
        PrimerApellidos,
        SegundoApellidos,
        TelefonoContacto,
        email,
        NumeroDocumento,
        IdTipoIdentidad,
        FechaExpedicion,
        IdSexo,
        FechaNacimiento,
        IdPais,
        IdDepartamento,
        IdMunicipio,
        Direccion,
        IdEstadoCivil,
        IdEstrato,
        IdGrupoEtnico,
        OrientacionSexual,
        IdDiscapacidad,
        IdRH,
        IdComunidadIndigena,
        IdComunidadNegra,
        IdZonaResidencial,
        IdTalentoExcepcional,
        codigoSaber,
        IdDepDocumento,
        IdPaisDocumento,
        IdMuniDocumento,
        IdPaisDir,
        IdDepDir,
        IdMuniDir,
        Estatura,
        LibretaNumero,
        DistritoLibreta, 
        Barrio,
        TelefonoFijo1, 
        TelefonoFijo2,
        EPS,
        ClaseLibreta,
        Localidad,
        Fax,
        ExtensionTelFijo1,
        ExtensionTelFijo2,
        IdNivelIngles,
        InstitucionIdioma,
        OtroIdioma,
        SISBEN,
        PersonaEmergencias,
        TelefonoEmergencia,
        IdPaisPerEmergencia,
        IdDeptoPerEmergencia,
        IdCiudadPerEmergencia,
        IdReligion,
        IdFuenteFinanciacionEstudio,
        IdConocioCEA,
        PerteneceGrupoVulnerable,
        VictimaConflictoArmado,
        PoblacionNecesidadEspecial,
        AutorizaDatos,
        IdBeneficiarioLeyVeteranos
    } = userData

    const {
        EmailInstitucional,
        TelefonoInstitucional,
        ExtensionTelInstitucional,
        IdRegional,
        Aereopuerto,
        IdTipoNombramiento,
        UbicacionActual,
        FuncionActual,
        FechaIngreso,
        DescripcionCargo,
        CapacitacionNacional,
        SancionDisciplinaria,
        SancionRomperReglaEstudiantil,
        AutorizacionDatos,
        Autoridad,
        Directivo
    } = userDataFuncionario

    const {
        PrimerApellidosConyuge,
        SegundoApellidosConyuge,
        PrimerNombreConyuge,
        SegundoNombreConyuge,
        IdTipoIdentidadConyuge,
        NumeroDocumentoConyuge,
        OcupacionConyuge,
        DireccionConyuge,
        IdNivelEstudioConyuge,
        IdPaisConyuge,
        IdDeptoConyuge,
        IdCiudadConyuge,
        TelefonoConyuge
    } = userDataConyuge
    
    const {
        PrimerApellidosMadre,
        SegundoApellidosMadre,
        PrimerNombreMadre,
        SegundoNombreMadre,
        IdTipoIdentidadMadre,
        NumeroDocumentoMadre,
        OcupacionMadre,
        DireccionMadre,
        IdNivelEstudioMadre,
        IdPaisMadre,
        IdDeptoMadre,
        IdCiudadMadre,
        TelefonoMadre,
        MadreVive
    } = userDataMadre
    
    const {
        PrimerApellidosPadre,
        SegundoApellidosPadre,
        PrimerNombrePadre,
        SegundoNombrePadre,
        IdTipoIdentidadPadre,
        NumeroDocumentoPadre,
        OcupacionPadre,
        DireccionPadre,
        IdNivelEstudioPadre,
        IdPaisPadre,
        IdDeptoPadre,
        IdCiudadPadre,
        TelefonoPadre,
        PadreVive
    } = userDataPadre
    
    const {
        FinanciacionEstudioIcfes,
        NumeroRegistroNSP,
        FechaPresentacionSNP,
        PuntajeIcfes1999,
        PuntajeIcfes2000_2014,
        PuntajeIcfes2015
    } = userDataIcfes

    const {
        InstitucionSecundaria,
        ProcedenciaSecundaria,
        ModalidadSecundaria,
        NaturalezaSecundaria,
        JornadaSecundaria,
        CalendarioSecundaria,
        PeriodoGradoSecundaria,
        UltimoPeriodoSecundaria,
        FechaTerminacionSecundaria
    } = userDataEducacionSec
    
    const {
        institucionSuperior,
        ProgramaAcademicoSuperior,
        TituloSuperiorObtenido,
        IdNivelEducacionSuperior,
        JornadaSuperior,
        SemestresProgramaSuperior,
        TarjetaProfesionalSuperior,
        FechaTerminacionProgSuperior,
        FechaRetiroProgSuperior,
        MotivoRetiroProgSuperior,
        TransferenciaExternaProgSuperior
    } = userDataEducacionSup
    
    const {
        EmpresaLabora,
        CargoLabora,
        DireccionLabora,
        ActividadEmpresa,
        PaisLabora,
        DeptoLabora,
        CiudadEmpresa,
        TelefonoLabora,
        ExtensionTelLabora,
        EmailLabora,
        Archivo
    } = userDataInfoLaboral
    
    const {
        Artes,
        Cine,
        Danzas,
        Ecologia,
        Literatura,
        Musica,
        Pintura,
        Teatro,
        Aerobics,
        Baloncesto,
        Ciclismo,
        Futbol,
        Gimnasio,
        Karate,
        Natacion,
        Tenis,
        Veisbol,
        OtroActividad
    } = userDataActividades
    
    const {
        CalidadServicio,
        CondionesAdmision,
        CostosMatricula,
        Horario,
        NivelAcademico,
        NivelFormacionOfrecido,
        PlanEstudioVinculacionLaboral,
        UbicacionGeografica,
        OtroAspecto,
    } = userDataAspectos

    const fecthUserData = async () => {
        try {
            const user = localStorage.getAsJsonItem('usuario')
            const ID = user?.usuario?.IdUsuario
            /* setIdUsuario(user?.usuario?.IdUsuario) */
            const userDataFetched = await formService.getById('usuario', ID)
            setUserData(userDataFetched)
        } catch (err) {
            setError(err)
        }
    }
    const fecthUserDataFuncionario = async () => {
        try {
            const user = localStorage.getAsJsonItem('usuario')
            const ID = user?.usuario?.IdUsuario
            /* setIdUsuario(user?.usuario?.IdUsuario) */
            const userDataFetched = await formService.getAll(`funcionario?id=${ID}`)
            setUserDataFuncionario(userDataFetched)

            if(userDataFetched !== null){
                setFuncionarioSw(true)
            }
        } catch (err) {
            setError(err)
        }
    }
    const fecthUserDataInscripcion = async () => {
        try {
            const user = localStorage.getAsJsonItem('usuario')
            const ID = user?.usuario?.IdUsuario
            /* setIdUsuario(user?.usuario?.IdUsuario) */
            const userDataFetched = await formService.getAll(`inscripcionAspirante/usuario?IdUsuario=${ID}`)
            setUserDataInscripcion(userDataFetched)
        } catch (err) {
            setError(err)
        }
    }
    const fecthUserDataMadreUser = async () => {
        try {
            const user = localStorage.getAsJsonItem('usuario')
            const ID = user?.usuario?.IdUsuario
            const userDataFetched = await formService.getAll(`madreUsuario/byUsuario?IdUsuario=${ID}`)
            setUserDataMadre(userDataFetched)
        } catch (err) {
            setError(err)
        }
    }
    const fecthUserDataPadreUser = async () => {
        try {
            const user = localStorage.getAsJsonItem('usuario')
            const ID = user?.usuario?.IdUsuario
            const userDataFetched = await formService.getAll(`padreUsuario/byUsuario?IdUsuario=${ID}`)
            setUserDataPadre(userDataFetched)
        } catch (err) {
            setError(err)
        }
    }
    const fecthUserDataConyugeUser = async () => {
        try {
            const user = localStorage.getAsJsonItem('usuario')
            const ID = user?.usuario?.IdUsuario
            const userDataFetched = await formService.getAll(`conyugeUsuario/byUsuario?IdUsuario=${ID}`)
            setUserDataConyuge(userDataFetched)
        } catch (err) {
            setError(err)
        }
    }
    const fecthUserDataIcfesUser = async () => {
        try {
            const user = localStorage.getAsJsonItem('usuario')
            const ID = user?.usuario?.IdUsuario
            const userDataFetched = await formService.getAll(`icfesUsuario/byUsuario?IdUsuario=${ID}`)
            setUserDataIcfes(userDataFetched)
        } catch (err) {
            setError(err)
        }
    }
    const fecthUserDataEducacioSecUser = async () => {
        try {
            const user = localStorage.getAsJsonItem('usuario')
            const ID = user?.usuario?.IdUsuario
            const userDataFetched = await formService.getAll(`educacionSecUsuario/byUsuario?IdUsuario=${ID}`)
            setUserDataEducacionSec(userDataFetched)
        } catch (err) {
            setError(err)
        }
    }
    const fecthUserDataEducacioSupUser = async () => {
        try {
            const user = localStorage.getAsJsonItem('usuario')
            const ID = user?.usuario?.IdUsuario
            const userDataFetched = await formService.getAll(`educacionSupUsuario/byUsuario?IdUsuario=${ID}`)
            setUserDataEducacionSup(userDataFetched)
        } catch (err) {
            setError(err)
        }
    }
    const fecthUserDataActividadesUser = async () => {
        try {
            const user = localStorage.getAsJsonItem('usuario')
            const ID = user?.usuario?.IdUsuario
            const userDataFetched = await formService.getAll(`actividadesUsuario/byUsuario?IdUsuario=${ID}`)
            setUserDataActividades(userDataFetched)
        } catch (err) {
            setError(err)
        }
    }
    const fecthUserDataAspectosUser = async () => {
        try {
            const user = localStorage.getAsJsonItem('usuario')
            const ID = user?.usuario?.IdUsuario
            const userDataFetched = await formService.getAll(`aspectosUsuario/byUsuario?IdUsuario=${ID}`)
            setUserDataAspectos(userDataFetched)
        } catch (err) {
            setError(err)
        }
    }
    const fecthUserDataInfoLaboralUser = async () => {
        try {
            const user = localStorage.getAsJsonItem('usuario')
            const ID = user?.usuario?.IdUsuario
            const userDataFetched = await formService.getAll(`informacionLaboralUsuario/byUsuario?IdUsuario=${ID}`)
            setUserDataInfoLaboral(userDataFetched)
        } catch (err) {
            setError(err)
        }
    }
    
    useEffect(() => {
        const fecthData = async () => {
            await fecthUserData()
            await fecthUserDataFuncionario()
            await fecthUserDataInscripcion()
            await fecthUserDataMadreUser()
            await fecthUserDataPadreUser()
            await fecthUserDataConyugeUser()
            await fecthUserDataIcfesUser()
            await fecthUserDataEducacioSecUser()
            await fecthUserDataEducacioSupUser()
            await fecthUserDataActividadesUser()
            await fecthUserDataAspectosUser()
            await fecthUserDataInfoLaboralUser()
        }
            fecthData()
    }, [])
    
    const handleSudmit = async (e) => {
        e.preventDefault()
        setPasos("informacion_familiar")
        
        try {
            setError(null)
            setReady(false)
            setLoading(true)
            const respuesta= await api.update("usuario", {...userData}, userData.IdUsuario)
            if(respuesta.respuesta=="exito"){
                const respuesta= await api.update("inscripcionAspirante",
                                                    {...userDataInscripcion,
                                                        HabiaSolicitadoInscripcion:solicitoInscripcion, 
                                                        IdUsuario:userData.IdUsuario, perfil:1
                                                    }, 
                                                    userData.IdUsuario
                                                )
                if(respuesta.respuesta=="exito"){
                        if(funcionarioSw) {
                            const respuesta= await api.update("funcionarios", 
                                                            {...userDataFuncionario, 
                                                                perfil:1, IdUsuario: 
                                                                userData.IdUsuario, 
                                                                CapacitacionNacional: capacitacionNac,
                                                                SancionDisciplinaria:sancionDisciplina,
                                                                SancionRomperReglaEstudiantil:sancionInfraccionReglamento, 
                                                                AutorizacionDatos:autorizacionDat,
                                                                Autoridad: autoridad,
                                                                Directivo: directivo
                                                            }, 
                                                            userData.IdUsuario
                                                        )
                            fecthUserDataFuncionario()
                        }
                        alerta(respuesta.mensaje)
                        fecthUserDataInscripcion()
                }else{
                    alerta("Fallo desconocido")
                }
                fecthUserData()
            }else{
                alerta("Fallo desconocido")
            }

            setReady(true)
            setLoading(false)
        } catch (err) {
            setError(err)
            setLoading(false)
        }
    }

    const handleSudmitInfoFamily = async (e) => {
        e.preventDefault()
        setPasos("informacion_academica")
        try {
            setError(null)
            setReady(false)
            setLoading(true)
            
            if(NumeroDocumentoConyuge != null) {
                const respuesta= await api.update("conyugeUsuario", {...userDataConyuge, IdUsuario:userData.IdUsuario, perfil: 1}, userData.IdUsuario)
                fecthUserDataConyugeUser()
            }
            

                 const respuesta2= await api.update("madreUsuario",{...userDataMadre, IdUsuario:userData.IdUsuario, perfil:1, MadreVive:madreVive}, userData.IdUsuario)
                if(respuesta2.respuesta=="exito"){
                    
                    const respuesta3 = await api.update("padreUsuario", {...userDataPadre, perfil:1, IdUsuario: userData.IdUsuario, PadreVive:padreVive}, userData.IdUsuario)
                    if(respuesta3.respuesta=="exito"){
                        
                        const respuesta4 = await api.update("usuario", {...userData}, userData.IdUsuario)
                        if(respuesta4.respuesta=="exito") {
                            alerta(respuesta4.mensaje)
                        } else {
                            alerta("Fallo desconocido")
                        }
                        
                        fecthUserDataPadreUser()
                    }else{
                        alerta("Fallo desconocido")
                    }
                    fecthUserDataMadreUser()
                    
                }else{
                    alerta("Fallo desconocido")
                } 
                
            
            setReady(true)
            setLoading(false)
        } catch (err) {
            setError(err)
            setLoading(false)
        }

    }

    const handleSudmitInfoAcademica = async (e) => {
        e.preventDefault()
        setPasos("informacion_laboral")
        try {
            setError(null)
            setReady(false)
            setLoading(true)
            
            const respuesta= await api.update("IcfesUsuario", {...userDataIcfes, IdUsuario:userData.IdUsuario, perfil: 1}, userData.IdUsuario)

            if(respuesta.respuesta=="exito"){

                  const respuesta2= await api.update("educacionSecUsuario", 
                                                        {...userDataEducacionSec, 
                                                            IdUsuario:userData.IdUsuario, perfil:1,
                                                            GradoHonorSecundaria: gradoHonorSecundaria, 
                                                            ValidacionSecundaria:validacionSecundaria}, 
                                                            userData.IdUsuario
                                                    )
                if(respuesta2.respuesta=="exito"){
                    
                    const respuesta3 = await api.update("educacionSupUsuario", 
                                                            {...userDataEducacionSup, 
                                                                perfil:1, 
                                                                SolicitaHomologacion: solicitaHomologacion, 
                                                                IdUsuario: userData.IdUsuario
                                                            }, 
                                                            userData.IdUsuario
                                                        )
                    if(respuesta3.respuesta=="exito"){
                        
                        const respuesta4 = await api.update("usuario", 
                                                                {...userData, 
                                                                    VictimaConflictoArmado:victimaConflictoArmado, 
                                                                    PerteneceGrupoVulnerable: perteneceGrupoVulnerable, 
                                                                    PoblacionNecesidadEspecial:poblacionNecesidadEspecial
                                                                }, 
                                                                userData.IdUsuario
                                                            )
                        if(respuesta4.respuesta=="exito") {
                            alerta(respuesta.mensaje)
                        } else {
                            alerta("Fallo desconocido")
                        }
                        
                        fecthUserDataPadreUser()
                    }else{
                        alerta("Fallo desconocido")
                    }
                    fecthUserDataEducacioSecUser()
                }else{
                    alerta("Fallo desconocido")
                }  
                fecthUserDataIcfesUser()
            }else{
                alerta("Fallo desconocido")
            }

            setReady(true)
            setLoading(false)
        } catch (err) {
            setError(err)
            setLoading(false)
        }

    }

    const handleSudmitInfoLaboral = async (e) => {
        e.preventDefault()
        try {
            setError(null)
            setReady(false)
            setLoading(true)
            

                   const respuesta2 =   await api.update("actividadesUsuario", 
                                            {  
                                                ...userDataActividades, 
                                                IdUsuario:userData.IdUsuario, 
                                                perfil:1,
                                                Artes:artes,
                                                Cine:cine,
                                                Danzas:danzas,
                                                Ecologia:ecologia,
                                                Literatura:literatura,
                                                Musica:musica,
                                                Pintura:pintura,
                                                Teatro:teatro,
                                                Aerobics:aerobics,
                                                Baloncesto:baloncesto,
                                                Ciclismo:ciclismo,
                                                Futbol:futbol,
                                                Gimnasio:gimnasio,
                                                Karate:karate,
                                                Natacion:natacion,
                                                Tenis:tenis,
                                                Veisbol:veisbol
                                            }, 
                                            userData.IdUsuario)
                if(respuesta2.respuesta=="exito"){
                    
                    const respuesta3 = await api.update("aspectosUsuario", 
                                                        {
                                                            ...userDataAspectos, 
                                                            IdUsuario: userData.IdUsuario,
                                                            perfil:1,
                                                            CalidadServicio:calidadServicio,
                                                            CondionesAdmision:condionesAdmision,
                                                            CostosMatricula:costosMatricula,
                                                            horario:horario,
                                                            NivelAcademico:nivelAcademico,
                                                            NivelFormacionOfrecido:nivelFormacionOfrecido,
                                                            PlanEstudioVinculacionLaboral:planEstudioVinculacionLaboral,
                                                            UbicacionGeografica:ubicacionGeografica
                                                        }, 
                                                        userData.IdUsuario)
                    if(respuesta3.respuesta=="exito"){
                        
                        const respuesta4 = await api.update("usuario", {...userData, AutorizaDatos:autorizaDatosUser}, userData.IdUsuario)
                        if(respuesta4.respuesta=="exito") {
                            alerta(respuesta4.mensaje)
                        } else {
                            alerta("Fallo desconocido")
                        }
                        
                        fecthUserDataAspectosUser()
                    }else{
                        alerta("Fallo desconocido")
                    }
                    fecthUserDataActividadesUser()
                }else{
                    alerta("Fallo desconocido")
                }   

            setReady(true)
            setLoading(false)
        } catch (err) {
            setError(err)
            setLoading(false)
        }

    }

    const addExperiencia = async (e) => {
        e.preventDefault()
        const formData = new FormData(e.currentTarget)
        let dataRaw = Object.fromEntries(formData.entries());
        try {
            setError(null)
            setReady(false)
            setLoading(true)
            
            const respuesta= await api.createWithFormData("informacionLaboralUsuario", {...dataRaw, IdUsuario: userData.IdUsuario})
            if(respuesta.respuesta == "exito") {
                alerta("Éxito", "success")
            } else {
                alerta("Fallo Desconocido")
            }
            setModalLaboral(false)
            setEndpointLaboral(`informacionLaboralUsuario?IdUsuario=${ID}`)
            setReady(true)
            setLoading(false)
        } catch (err) {
            setError(err)
            setLoading(false)
        }

    }

    const handleChange = (value, name) => {
        setReady(false)
        setError(null)
        setUserData({
            ...userData,
            [name]: value
        })
    }
    const handleChangeIns = (value, name) => {
        setReady(false)
        setError(null)
        setUserDataInscripcion({
            ...userDataInscripcion,
            [name]: value
        })
    }
    const handleChangeConyuge = (value, name) => {
        setReady(false)
        setError(null)
        setUserDataConyuge({
            ...userDataConyuge,
            [name]: value
        })
    }
    const handleChangeMadre = (value, name) => {
        setReady(false)
        setError(null)
        setUserDataMadre({
            ...userDataMadre,
            [name]: value
        })
    }
    const handleChangePadre = (value, name) => {
        setReady(false)
        setError(null)
        setUserDataPadre({
            ...userDataPadre,
            [name]: value
        })
    }
    const handleChangeFunc = (value, name) => {
        setReady(false)
        setError(null)
        setUserDataFuncionario({
            ...userDataFuncionario,
            [name]: value
        })
    }
    const handleChangeIcfes = (value, name) => {
        setReady(false)
        setError(null)
        setUserDataIcfes({
            ...userDataIcfes,
            [name]: value
        })
    }
    
    const handleChangeEduSec = (value, name) => {
        setReady(false)
        setError(null)
        setUserDataEducacionSec({
            ...userDataEducacionSec,
            [name]: value
        })
    }
    
    const handleChangeEduSup = (value, name) => {
        setReady(false)
        setError(null)
        setUserDataEducacionSup({
            ...userDataEducacionSup,
            [name]: value
        })
    }    
    
    const handleChangeInfoLaboral = (value, name) => {
        setReady(false)
        setError(null)
        setUserDataInfoLaboral({
            ...userDataInfoLaboral,
            [name]: value
        })
    }    
    
    const handleChangeActividadUser = (value, name) => {
        setReady(false)
        setError(null)
        setUserDataActividades({
            ...userDataActividades,
            [name]: value
        })
    }    
    
    const handleChangeAspectos = (value, name) => {
        setReady(false)
        setError(null)
        setUserDataAspectos({
            ...userDataAspectos,
            [name]: value
        })
    }    

  return (
    <>
        {Docente &&
        <Card>
            <Row>
                <Col>
                    
                </Col>
                <Col>

                </Col>
                <Col>
                    <div style={{textAlign: 'right', width: '200px', marginLeft: '50px'}}>
                        <DowloadFile 
                            nameFile={"Hoja de Vida.pdf"}
                            name={"Exportar Hoja de Vida"}
                            endPoint={urlHojadeVida}
                        />
                    </div>
                </Col>
            </Row>
        </Card>
        }
        <div style={{margin:"0 auto", marginTop:40}}>
            {/* <h2 className='text-center'>Inscripción Capacitación Nacional</h2> */}
                <h2 className='text-center'>Hoja de Vida</h2>
                <Tabs
                    defaultActiveKey="informacion_general"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    /* activeKey={pasos} */
                >
                    <Tab eventKey="informacion_general" title="Información General">
                        
                        <form 
                        onSubmit={ handleSudmit }
                            className='user-profile__form'
                        >
                            <div>
                                {/* <center>
                                    <h5>Inscripción en el Período</h5>
                                </center>
                                <section className='form-sign-up__container'>
                                    <section className='form-sign-up__columnOne'>
                                        <Input
                                            label='Fecha de Pago *'
                                            type='date'
                                            name='FechaPago'
                                            required={true}
                                            value={FechaPago || null}
                                            onChange={ handleChangeIns }
                                        />
                                        <Input
                                            label='Inscripción *'
                                            required={true}
                                            type='text'
                                            name='Inscripcion'
                                            value={Inscripcion || null}
                                            onChange={ handleChangeIns }
                                        />
                                        <Input
                                            label="Programa - Actividad *"
                                            type="text"
                                            value={ProgramaActividad || null}
                                            name='ProgramaActividad'
                                            onChange={ handleChangeIns }
                                            required={true}
                                        />
                                        <Select
                                            id="Jornada"
                                            name="Jornada"
                                            onChange={ handleChangeIns }
                                            value={ Jornada || null }
                                            label="Jornada *"
                                            required={true}
                                            optionValue="Nombre"
                                            optionName="Nombre"
                                            options={[
                                                {"IdJornada": "1", "Nombre": "Diurna"},
                                                {"IdJornada": "2", "Nombre": "Nocturna"}
                                            ]}
                                        />
                                        
                                    </section>

                                    <section className='form-sign-up__columnTwo'>
                                        <Input
                                            label='Número Recibo *'
                                            required={true}
                                            type='number'
                                            name='NumeroRecibo'
                                            value={NumeroRecibo || null}
                                            onChange={ handleChangeIns }
                                        />
                                        <Select
                                            id="Periodo"
                                            name="Periodo"
                                            onChange={ handleChangeIns }
                                            value={ Periodo || null }
                                            label="Período al que Aspira *"
                                            required={true}
                                            optionValue="Periodo"
                                            optionName="Periodo"
                                            options={[
                                                {"IdPeriodo": "1", "Periodo": "I"},
                                                {"IdPeriodo": "2", "Periodo": "II"}
                                            ]}
                                        />
                                        <Input
                                            label='Metodología *'
                                            type='text'
                                            required={true}
                                            name='Metodologia'
                                            value={Metodologia || null}
                                            onChange={ handleChangeIns }
                                        />
                                        <br />
                                        <h6>Ha Solicitado Anteriormente Inscripción ?</h6>  
                                        <Form.Check 
                                            type="switch"
                                            name="HabiaSolicitadoInscripcion"
                                            id="HabiaSolicitadoInscripcion"
                                            label="Sí"
                                            value={1}
                                            checked={HabiaSolicitadoInscripcion}
                                            onChange = {(e) => {
                                                if(e.target.checked) {
                                                    setSolicitoInscripcion(e.target.value)
                                                } else {
                                                    setSolicitoInscripcion(0)
                                                }
                                            }}
                                        />
                                    </section>
                                </section> */}

                                <center>
                                    <h5>Información General</h5>
                                </center>
                                <section className='form-update__container'>
                                    <section className='form-update__columnOne'>
                                        <Input
                                            label='Primer Nombre *'
                                            type='text'
                                            name='PrimerNombres'
                                            required={true}
                                            value={ PrimerNombres || ''}
                                            onChange={ handleChange }
                                            tabIndex={1}
                                        />
                                        
                                        <Input
                                            label='Primer Apellido *'
                                            required={true}
                                            type='text'
                                            name='PrimerApellidos'
                                            value={ PrimerApellidos || ''}
                                            onChange={ handleChange }
                                            tabIndex={3}
                                        />
                                        
                                        {/* <Select
                                            id="IdTipoIdentidad"
                                            name="IdTipoIdentidad"
                                            onChange={ handleChange }
                                            value={ IdTipoIdentidad || null }
                                            label="Tipo de Documento *"
                                            optionValue="IdTipoIdentidad"
                                            optionName="Descripcion"
                                            required={true}
                                            urlOptionsService="tipoIdentidad"
                                            tabIndex={5}
                                        /> */}

                                        <Input
                                            label="Fecha de Expedición *"
                                            type="date"
                                            value={ FechaExpedicion || "" }
                                            name='FechaExpedicion'
                                            onChange={ handleChange }
                                            required={true}
                                            tabIndex={7}
                                        />
                                        {IdPaisDocumento==1 &&
                                            <Select
                                            id="IdDepDocumento"
                                            name="IdDepDocumento"
                                            onChange={ handleChange }
                                            value={IdDepDocumento || null }
                                            label="Departamento de Expedición *"
                                            required={false}
                                            optionValue="IdDepartamento"
                                            optionName="Descripcion"
                                            urlOptionsService={`departamentos?IdPaises=${IdPaisDocumento}`}
                                            />
                                        }
                                        <Input
                                            label="Fecha de Nacimiento *"
                                            type="date"
                                            value={ FechaNacimiento || "" }
                                            name='FechaNacimiento'
                                            onChange={ handleChange }
                                            required={true}
                                            tabIndex={9}
                                        />
                                        {IdPais==1 &&
                                            <Select
                                            id="IdDepartamento"
                                            name="IdDepartamento"
                                            onChange={ handleChange }
                                            value={ IdDepartamento || null }
                                            label="Departamento de Nacimiento *"
                                            required={false}
                                            optionValue="IdDepartamento"
                                            optionName="Descripcion"
                                            urlOptionsService={`departamentos?IdPaises=${IdPais}`}
                                        />
                                        }
                                        <Select
                                            id="IdRH"
                                            name="IdRH"
                                            onChange={ handleChange }
                                            value={IdRH || null}
                                            label="Grupo Sanguíneo - RH *"
                                            optionValue="IdRH"
                                            optionName="Nombre"
                                            urlOptionsService="RH"
                                            required={true}
                                            tabIndex={11}
                                        />
                                        <Input
                                            label="Estatura (Cm) *"
                                            type="text"
                                            value={Estatura || ''}
                                            name='Estatura'
                                            onChange={ handleChange }
                                            required={true}
                                            tabIndex={13}
                                        />
                                        <Select
                                            id="IdEstrato"
                                            name="IdEstrato"
                                            onChange={ handleChange }
                                            value={IdEstrato || 0}
                                            label="Estrato Socieconómico *"
                                            optionValue="IdEstrato"
                                            optionName="Descripcion"
                                            required={true}
                                            urlOptionsService="estracto"
                                            tabIndex={15}
                                        />
                                        <Select
                                            id="IdComunidadIndigena"
                                            name="IdComunidadIndigena"
                                            onChange={ handleChange }
                                            value={IdComunidadIndigena || null}
                                            label="Comunidades Indigenas *"
                                            optionValue="IdComunidadIndigena"
                                            optionName="Nombre"
                                            required={true}
                                            urlOptionsService="comunidadesIndigenas"
                                            tabIndex={17}
                                        />

                                        <Select
                                            id="IdEstadoCivil"
                                            name="IdEstadoCivil"
                                            onChange={ handleChange }
                                            value={ IdEstadoCivil || null}
                                            label="Estado Civil *"
                                            required={true}
                                            optionValue="IdEstadoCivil"
                                            optionName="Descripcion"
                                            urlOptionsService="estadoCivil"
                                            tabIndex={19}
                                        />
                                        <Select
                                            id="IdPaisDir"
                                            name="IdPaisDir"
                                            onChange={ handleChange }
                                            value={ IdPaisDir || null }
                                            label="País Dirección Residencia *"
                                            required={true}
                                            optionValue="Idpaises"
                                            optionName="Descripcion"
                                            urlOptionsService="paises"
                                            tabIndex={21}
                                        />
                                        {IdPaisDir == 1 &&
                                            <Select
                                                id="IdMuniDir"
                                                name="IdMuniDir"
                                                onChange={ handleChange }
                                                value={ IdMuniDir || null }
                                                label="Municipio Dirección Residencia *"
                                                required={false}
                                                optionValue="Idciudad"
                                                optionName="Descripcion"
                                                urlOptionsService={`ciudad?IdDepartamento=${IdDepDir}`}
                                            />
                                        }
                                        <Input
                                            label='Barrio *'
                                            type='text'
                                            required={true}
                                            name='Barrio'
                                            value={Barrio || null}
                                            onChange={ handleChange }
                                            tabIndex={23}
                                        />
                                        <Input
                                            label='Fax'
                                            type='number'
                                            required={false}
                                            name='Fax'
                                            value={Fax || null}
                                            onChange={ handleChange }
                                            tabIndex={25}
                                        />
                                        <Input
                                            label='N° Libreta Militar *'
                                            type='number'
                                            required={true}
                                            name='LibretaNumero'
                                            value={LibretaNumero || null}
                                            onChange={ handleChange }
                                            tabIndex={27}
                                        />
                                        <Input
                                            label='Distrito *'
                                            type='text'
                                            required={true}
                                            name='DistritoLibreta'
                                            value={DistritoLibreta || null}
                                            onChange={ handleChange }
                                            tabIndex={29}
                                        />
                                        <Input
                                            label="Código de Prueba Saber 11 *"
                                            type="text"
                                            info="Es el número que el ICFES asigna a quienes presenten la Prueba Saber 11, puede consultarlo en el desprendible del examen, o en la citación al mismo, lo visualiza en su examen con la nomenclatura AC o VG seguido de diez o doce dígitos, ejemplo: AC 200728975451, VG 2954872694"
                                            required={true}
                                            value={ codigoSaber || '' }
                                            name='codigoSaber'
                                            onChange={ handleChange }
                                            tabIndex={31}
                                        />
                                        {/* <Input
                                            label='Teléfono Fijo 1 *'
                                            type='number'
                                            required={true}
                                            name='TelefonoFijo1'
                                            value={TelefonoFijo1 || null}
                                            onChange={ handleChange }
                                        />
                                        <Input
                                            label='Teléfono Fijo 2 *'
                                            type='number'
                                            required={true}
                                            name='TelefonoFijo2'
                                            value={TelefonoFijo2 || null}
                                            onChange={ handleChange }
                                        /> */}
                                        {/* <Checkbox
                                            id="id_status"
                                            name=""
                                            checked={presentaDiscapacidad}
                                            onChange={discapicadActiva}
                                        ></Checkbox> 
                                        <label>¿Presenta Discapacidad?</label>*/}
                                    </section>

                                    <section className='form-update__columnTwo'>
                                        <Input
                                            label='Segundo Nombre'
                                            required={false}
                                            type='text'
                                            name='SegundoNombre'
                                            value={ SegundoNombre || ''}
                                            onChange={ handleChange }
                                            tabIndex={2}
                                        />
                                        <Input
                                            label='Segundo Apellido'
                                            type='text'
                                            required={false}
                                            name='SegundoApellidos'
                                            value={ SegundoApellidos || ''}
                                            onChange={ handleChange }
                                            tabIndex={4}
                                        />
                                        {/* <Input
                                            label="Número de Documento *"
                                            type="text"
                                            value={ NumeroDocumento || '' }
                                            name='NumeroDocumento'
                                            onChange={ handleChange }
                                            required={true}
                                            tabIndex={6}
                                        /> */}
                                        <Select
                                            id="IdPaisDocumento"
                                            name="IdPaisDocumento"
                                            onChange={ handleChange }
                                            value={IdPaisDocumento || null }
                                            label="País de Expedición *"
                                            required={true}
                                            optionValue="Idpaises"
                                            optionName="Descripcion"
                                            urlOptionsService="paises"
                                            tabIndex={8}
                                        />
                                        {IdPaisDocumento==1 &&
                                            <Select
                                                id="IdMuniDocumento"
                                                name="IdMuniDocumento"
                                                onChange={ handleChange }
                                                value={ IdMuniDocumento || null }
                                                label="Ciudad de Expedición *"
                                                required={false}
                                                optionValue="Idciudad"
                                                optionName="Descripcion"
                                                urlOptionsService={`ciudad?IdDepartamento=${IdDepDocumento}`}
                                            />
                                        }
                                        <Select
                                            id="IdPais"
                                            name="IdPais"
                                            onChange={ handleChange }
                                            value={ IdPais || null }
                                            label="País de Nacimiento *"
                                            required={true}
                                            optionValue="Idpaises"
                                            optionName="Descripcion"
                                            urlOptionsService="paises"
                                            tabIndex={10}
                                        />
                                        {IdPais == 1 &&
                                            <Select
                                                id="IdMunicipio"
                                                name="IdMunicipio"
                                                onChange={ handleChange }
                                                value={ IdMunicipio || null }
                                                label="Municipio de Nacimiento*"
                                                required={false}
                                                optionValue="Idciudad"
                                                optionName="Descripcion"
                                                urlOptionsService={`ciudad?IdDepartamento=${IdDepartamento}`}
                                            />
                                        }
                                        <Input
                                            label="EPS *"
                                            type="text"
                                            value={EPS || null}
                                            name='EPS'
                                            onChange={ handleChange }
                                            required={true}
                                            tabIndex={12}
                                        />
                                        <Select
                                            id="IdSexo"
                                            name="IdSexo"
                                            onChange={ handleChange }
                                            value={ IdSexo || null }
                                            label="Género de Nacimiento *"
                                            optionValue="IdSexo"
                                            required={true}
                                            optionName="Descripcion"
                                            urlOptionsService="sexoBiologico"
                                            tabIndex={14}
                                        />
                                        {IdSexo === "4" ? (
                                        <Input
                                            label="Orientación Sexual *"
                                            type="text"
                                            value={ OrientacionSexual || '' }
                                            name='OrientacionSexual'
                                            onChange={ handleChange }
                                            required={true}
                                        />) : ("")}
                                        <Select
                                            id="IdGrupoEtnico"
                                            name="IdGrupoEtnico"
                                            onChange={ handleChange }
                                            value={ IdGrupoEtnico || null }
                                            label="Grupo Étnico *"
                                            optionValue="IdGrupoEtnico"
                                            optionName="Descripcion"
                                            urlOptionsService="grupoEtnico"
                                            required={true}
                                            tabIndex={16}
                                        />
                                        <Select
                                            id="IdComunidadNegra"
                                            name="IdComunidadNegra"
                                            onChange={ handleChange }
                                            value={IdComunidadNegra || null}
                                            label="Comunidades Negras *"
                                            optionValue="IdComunidadNegra"
                                            optionName="Nombre"
                                            required={true}
                                            urlOptionsService="comunidadesNegras"
                                            tabIndex={18}
                                        />
                                        <Select
                                            id="IdZonaResidencial"                        
                                            label={'Zona Residencial *'}
                                            required={true}
                                            name={'IdZonaResidencial'}
                                            optionValue={"IdZonaResidencial"}
                                            value={IdZonaResidencial||null}
                                            optionName={"Nombre"}
                                            options={[{"IdZonaResidencial":"1","Nombre":"Rural"},{"IdZonaResidencial":"2","Nombre":"Urbana"}]}
                                            onChange={handleChange}
                                            tabIndex={20}
                                        />
                                        {IdPaisDir==1 &&
                                            <Select
                                            id="IdDepDir"
                                            name="IdDepDir"
                                            onChange={ handleChange }
                                            value={IdDepDir || null }
                                            label="Departamento Dirección Residencia*"
                                            required={false}
                                            optionValue="IdDepartamento"
                                            optionName="Descripcion"
                                            urlOptionsService={`departamentos?IdPaises=${IdPaisDir}`}
                                            />
                                        }
                                        {IdMuniDir == 149 &&
                                            <Select
                                                id="Localidad"
                                                name="Localidad"
                                                onChange={ handleChange }
                                                value={Localidad || null }
                                                label="Localidad *"
                                                required={false}
                                                optionValue="Nombre"
                                                optionName="Nombre"
                                                options={[
                                                    {"IdLocalidad": "1", "Nombre": "ANTONIO NARIÑO"},
                                                    {"IdLocalidad": "2", "Nombre": "BARRIOS UNIDOS"},
                                                    {"IdLocalidad": "3", "Nombre": "BOSA"},
                                                    {"IdLocalidad": "4", "Nombre": "CANDELARIA"},
                                                    {"IdLocalidad": "5", "Nombre": "CIUDAD BOLIVAR"},
                                                    {"IdLocalidad": "6", "Nombre": "CHAPINERO"},
                                                    {"IdLocalidad": "7", "Nombre": "ENGATIVA"},
                                                    {"IdLocalidad": "8", "Nombre": "FONTIBON"},
                                                    {"IdLocalidad": "9", "Nombre": "KENNEDY"},
                                                    {"IdLocalidad": "10", "Nombre": "LOS MARTIRES"},
                                                    {"IdLocalidad": "11", "Nombre": "PUENTE ARANDA"},
                                                    {"IdLocalidad": "12", "Nombre": "RAFAEL URIBE"},
                                                    {"IdLocalidad": "13", "Nombre": "SAN CRISTOBAL"},
                                                    {"IdLocalidad": "14", "Nombre": "SANTA FE"},
                                                    {"IdLocalidad": "15", "Nombre": "SUBA"},
                                                    {"IdLocalidad": "16", "Nombre": "SUMAPAZ"},
                                                    {"IdLocalidad": "17", "Nombre": "TEUSAQUILLO"},
                                                    {"IdLocalidad": "18", "Nombre": "TUNJUELITO"},
                                                    {"IdLocalidad": "19", "Nombre": "USAQUEN"},
                                                    {"IdLocalidad": "20", "Nombre": "USME"}
                                                ]}
                                            />
                                        }
                                        <Input
                                            label="Dirección Residencia Actual *"
                                            type="text"
                                            required={true}
                                            value={ Direccion || '' }
                                            name='Direccion'
                                            onChange={ handleChange }
                                            tabIndex={22}
                                        />
                                        <Input
                                            label='Número Celular *'
                                            type='text'
                                            required={true}
                                            name='TelefonoContacto'
                                            value={ TelefonoContacto || ''}
                                            onChange={ handleChange }
                                            tabIndex={24}
                                        />
                                        <Select
                                            id="IdTalentoExcepcional"                        
                                            label={'Capacidad Excepcional *'}
                                            required={true}
                                            name={'IdTalentoExcepcional'}
                                            optionValue={"IdTalentoExcepcional"}
                                            value={IdTalentoExcepcional||null}
                                            optionName={"Nombre"}
                                            options={[
                                                {"IdTalentoExcepcional":"1","Nombre":"No Aplica"},
                                                {"IdTalentoExcepcional":"2","Nombre":"Talento Excepcional General"},
                                                {"IdTalentoExcepcional":"2","Nombre":"Talento Excepcional Específico"}
                                            ]}
                                            onChange={handleChange}
                                            tabIndex={26}
                                        />
                                        <Select
                                            id="ClaseLibreta"                        
                                            label={'Clase Libreta *'}
                                            required={true}
                                            name={'ClaseLibreta'}
                                            value={ClaseLibreta || null}
                                            optionValue={"Nombre"}
                                            optionName={"Nombre"}
                                            options={[
                                                {"IdClaseLibreta":"1","Nombre":"Primera"},
                                                {"IdClaseLibreta":"2","Nombre":"Segunda"},
                                                {"IdClaseLibreta":"3","Nombre":"No Aplica"},
                                            ]}
                                            onChange={handleChange}
                                            tabIndex={28}
                                        />
                                        <Input
                                            label='Correo Electronico *'
                                            type='email'
                                            required={true}
                                            name='email'
                                            value={ email || ''}
                                            onChange={ handleChange }
                                            tabIndex={30}
                                        />
                                        {/* <Select
                                                id="IdMuniDir"
                                                name="IdMuniDir"
                                                onChange={ handleChange }
                                                value={ IdMuniDir || null }
                                                label="Ciudad de Residencia *"
                                                required={true}
                                                optionValue="Idciudad"
                                                optionName="Descripcion"
                                                urlOptionsService={`ciudad`}
                                        /> */}
                                        
                                        {/* <Input
                                            label='Extensión Teléfono 1 *'
                                            type='number'
                                            required={true}
                                            name='ExtensionTelFijo1'
                                            value={ExtensionTelFijo1 || null}
                                            onChange={ handleChange }
                                        />
                                        <Input
                                            label='Extensión Teléfono 2 *'
                                            type='number'
                                            required={true}
                                            name='ExtensionTelFijo2'
                                            value={ExtensionTelFijo2 || null}
                                            onChange={ handleChange }
                                        /> */}
                                        
                                        {presentaDiscapacidad === true ? (
                                        <Select
                                            id="IdDiscapacidad"
                                            name="IdTiposDiscapacidad"
                                            onChange={ handleChange }
                                            value={IdDiscapacidad || ''}
                                            label="Tipo de Discapacidad *"
                                            optionValue="IdTiposDiscapacidad"
                                            optionName="Descripcion"
                                            urlOptionsService="tiposDiscapacidad"
                                            required={false}
                                        />
                                        ):("")}
                                        <Select
                                            id="IdBeneficiarioLeyVeteranos"
                                            name="IdBeneficiarioLeyVeteranos"
                                            onChange={ handleChange }
                                            value={IdBeneficiarioLeyVeteranos || ''}
                                            label="¿ Es beneficiario de la ley de veteranos ? *"
                                            optionValue="IdBeneficiarioLeyVeteranos"
                                            optionName="Nombre"
                                            urlOptionsService="beneficios/leydeveteranos"
                                            required={true}
                                        />
                                    </section>
                                </section>

                                <center>
                                    <h5>Datos Funcionario</h5>  
                                </center>
                                <section className='form-update__container'>
                                    <section className='form-update__columnOne'>
                                        <h6>Soy Funcionario</h6>  
                                        <Form.Check 
                                            type="switch"
                                            name="funcionarioSw"
                                            id="funcionarioSw"
                                            label=""
                                            checked={funcionarioSw}
                                            value={funcionarioSw}
                                            onChange = {(e) => {
                                                setFuncionarioSw(e.target.checked)
                                            }}
                                            tabIndex={32}
                                        />
                                    </section>
                                </section>
                                {funcionarioSw &&
                                <section className='form-update__container'>
                                    <section className='form-update__columnOne'>
                                        
                                        <Input
                                            label="Correo Institucional *"
                                            type="text"
                                            value={EmailInstitucional || null}
                                            name='EmailInstitucional'
                                            onChange={ handleChangeFunc }
                                            required={true}
                                            tabIndex={33}
                                        />
                                        <Input
                                            label="Extensión Tel Institucional*"
                                            type="number"
                                            value={ ExtensionTelInstitucional || '' }
                                            name='ExtensionTelInstitucional'
                                            onChange={ handleChangeFunc }
                                            required={true}
                                            tabIndex={35}
                                        />
                                        <Select
                                            id="IdRegional"
                                            name="IdRegional"
                                            onChange={ handleChangeFunc }
                                            value={IdRegional || null}
                                            label="Pertenece a la Regional *"
                                            optionValue="IdRegional"
                                            optionName="Regional"
                                            urlOptionsService="regionales"
                                            required={true}
                                            tabIndex={37}
                                        />
                                    </section>
                                    <section className='form-update__columnTwo'>
                                        <Input
                                            label="Teléfono Institucional *"
                                            type="number"
                                            value={ TelefonoInstitucional || null }
                                            name='TelefonoInstitucional'
                                            onChange={ handleChangeFunc }
                                            required={true}
                                            tabIndex={34}
                                        />
                                        <Select
                                            id="Aereopuerto"
                                            name="Aereopuerto"
                                            onChange={ handleChangeFunc }
                                            value={Aereopuerto || null}
                                            label="Pertenece al Aereopuerto *"
                                            optionValue="IdAereopuerto"
                                            optionName="Nombre"
                                            urlOptionsService="aereopuertos"
                                            required={true}
                                            tabIndex={36}
                                        />
                                    </section>   
                                </section>
                                }
                                
                                {funcionarioSw &&
                                <center>
                                    <h5>Información Laboral</h5>
                                </center>
                                }
                                {funcionarioSw &&
                                <section className='form-update__container'>
                                    <section className='form-update__columnOne'>
                                        <Select
                                            id="IdTipoNombramiento"
                                            name="IdTipoNombramiento"
                                            onChange={ handleChangeFunc }
                                            value={IdTipoNombramiento || null}
                                            label="Tipo Nombramiento *"
                                            optionValue="IdTipoNombramiento"
                                            optionName="Nombre"
                                            options={[
                                                { "IdTipoNombramiento": "1", "Nombre": "Carrera administrativa"},
                                                { "IdTipoNombramiento": "2", "Nombre": "Libre nombramiento y remosión"},
                                                { "IdTipoNombramiento": "3", "Nombre": "Provisional"},
                                                { "IdTipoNombramiento": "4", "Nombre": "Período fijo"},
                                                { "IdTipoNombramiento": "5", "Nombre": "Período temporal"},
                                                { "IdTipoNombramiento": "6", "Nombre": "Otro"}
                                            ]}
                                            required={true}
                                            tabIndex={38}
                                        />
                                        <Input
                                            label="Ubicación Actual *"
                                            type="text"
                                            value={UbicacionActual || null}
                                            name='UbicacionActual'
                                            onChange={ handleChangeFunc }
                                            required={true}
                                            tabIndex={40}
                                        />
                                        <TextArea
                                            label="Funciones que desempeña actualmente relacionadas con el objeto de la capacitación a recibir *"
                                            type="text"
                                            value={ FuncionActual || null }
                                            name='FuncionActual'
                                            onChange={ handleChangeFunc }
                                            required={true}
                                            row="3"
                                            tabIndex={42}
                                        />
                                        <br />
                                        <br />
                                        <label htmlFor="">¿ Ha Participado en Capacitación Nacional Similar en el Último Año ?</label>
                                        <Form.Check 
                                            type="switch"
                                            name="CapacitacionNacional"
                                            id="CapacitacionNacional"
                                            label="Sí"
                                            value={capacitacionNac}
                                            checked={CapacitacionNacional}
                                            onChange = {(e) => {
                                                setCapacitacionNac(e.target.checked);
                                            }}
                                            tabIndex={44}
                                        />
                                        <br />
                                        <label htmlFor="">¿ Soy Autoridad ?</label>
                                        <Form.Check 
                                            type="switch"
                                            name="Autoridad"
                                            id="Autoridad"
                                            label="Sí"
                                            value={autoridad}
                                            checked={CapacitacionNacional}
                                            onChange = {(e) => {
                                                setAutoridad(e.target.checked);
                                            }}
                                            tabIndex={46}
                                        />
                                    </section>
                                    <section className='form-update__columnTwo'>
                                        <Input
                                            label="Fecha de Ingreso *"
                                            type="date"
                                            value={FechaIngreso || null}
                                            name='FechaIngreso'
                                            onChange={ handleChangeFunc }
                                            required={true}
                                            tabIndex={39}
                                        />
                                        <Select
                                            id="DescripcionCargo"
                                            name="DescripcionCargo"
                                            onChange={ handleChangeFunc }
                                            value={DescripcionCargo || ''}
                                            label="Cargo Actual *"
                                            optionValue="IdCargo"
                                            optionName="Descripcion"
                                            urlOptionsService="cargos"
                                            required={false}
                                            tabIndex={41}
                                        />
                                        {/* <Input
                                            label="Cargo Actual *"
                                            type="text"
                                            value={DescripcionCargo || null}
                                            name='DescripcionCargo'
                                            onChange={ handleChangeFunc }
                                            required={true}
                                            tabIndex={41}
                                        /> */}
                                        <br />
                                        <label htmlFor="">¿ Ha sido Sancionado Disciplinariamente en el Último Año ?</label>
                                        <Form.Check 
                                            type="switch"
                                            name="SancionDisciplinaria"
                                            id="SancionDisciplinaria"
                                            label="Sí"
                                            value={sancionDisciplina}
                                            checked={SancionDisciplinaria}
                                            onChange = {(e) => {
                                                setSancionDisciplina(e.target.checked);
                                            }}
                                            tabIndex={43}
                                        />
                                        <br />
                                        <label htmlFor="">¿ Ha sido Sancionado por Infracciones al Reglamento Estudiantil del CEA ?</label>
                                        <Form.Check 
                                            type="switch"
                                            name="SancionRomperReglaEstudiantil"
                                            id="SancionRomperReglaEstudiantil"
                                            label="Sí"
                                            value={sancionInfraccionReglamento}
                                            checked={SancionRomperReglaEstudiantil}
                                            onChange = {(e) => {
                                                setSancionInfraccionReglamento(e.target.checked);
                                            }}
                                            tabIndex={45}
                                        />
                                        <br />
                                        <label htmlFor="">¿ Autoriza el Tratamiento de Datos, de Conformidad a lo Indicado en el Registro ?</label>
                                        <Form.Check 
                                            type="switch"
                                            name="AutorizacionDatos"
                                            id="AutorizacionDatos"
                                            label="Sí"
                                            value={autorizacionDat}
                                            checked={Autoridad}
                                            onChange = {(e) => {
                                                setAutorizacionDat(e.target.checked);
                                            }}
                                            tabIndex={47}
                                        />
                                        <br />
                                        <label htmlFor="">¿ Soy Directivo ?</label>
                                        <Form.Check 
                                            type="switch"
                                            name="Directivo"
                                            id="Directivo"
                                            label="Sí"
                                            value={directivo}
                                            checked={Directivo}
                                            onChange = {(e) => {
                                                setDirectivo(e.target.checked);
                                            }}
                                            tabIndex={48}
                                        />
                                    </section>   
                                </section>
                                }
                                <div style={{textAlign:"right",paddingTop:15, marginRight: 65, marginBottom: 40}}>
                                    <Button 
                                        type='send'
                                        loading={ loading }
                                    >
                                        Actualizar
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </Tab>
                    <Tab eventKey="informacion_familiar" title="Información Familiar" id="informacion_familiar">
                        <form 
                        onSubmit={ handleSudmitInfoFamily }
                            className='user-profile__form'
                        >
                            <div>
                                <center>
                                    <h5>Datos Cónyuge</h5>
                                </center>
                                <section className='form-update__container'>
                                    <section className='form-update__columnOne'>
                                        <Input
                                            label='Primer Nombre'
                                            type='text'
                                            name='PrimerNombreConyuge'
                                            required={false}
                                            value={ PrimerNombreConyuge || ''}
                                            onChange={ handleChangeConyuge }
                                            tabIndex={1}
                                        />
                                        
                                        <Input
                                            label='Primer Apellido'
                                            required={false}
                                            type='text'
                                            name='PrimerApellidosConyuge'
                                            value={ PrimerApellidosConyuge || ''}
                                            onChange={ handleChangeConyuge }
                                            tabIndex={3}
                                        />
                                        
                                        <Input
                                            label='Ocupación'
                                            required={false}
                                            type='text'
                                            name='OcupacionConyuge'
                                            value={ OcupacionConyuge || ''}
                                            onChange={ handleChangeConyuge }
                                            tabIndex={5}
                                        />
                                        {IdPaisConyuge == 1 &&
                                            <Select
                                            id="IdDeptoConyuge"
                                            name="IdDeptoConyuge"
                                            onChange={ handleChangeConyuge }
                                            value={IdDeptoConyuge || null }
                                            label="Departamento de Residencia*"
                                            required={false}
                                            optionValue="IdDepartamento"
                                            optionName="Descripcion"
                                            urlOptionsService={`departamentos?IdPaises=${IdPaisConyuge}`}
                                            />
                                        }
                                        <Input
                                            label="Dirección"
                                            type="text"
                                            value={ DireccionConyuge || '' }
                                            name='DireccionConyuge'
                                            onChange={ handleChangeConyuge }
                                            required={false}
                                            tabIndex={7}
                                        />
                                        <Select
                                            id="IdTipoIdentidadConyuge"
                                            name="IdTipoIdentidadConyuge"
                                            onChange={ handleChangeConyuge }
                                            value={ IdTipoIdentidadConyuge || null }
                                            label="Tipo de Documento "
                                            optionValue="IdTipoIdentidad"
                                            optionName="Descripcion"
                                            required={false}
                                            urlOptionsService="tipoIdentidad"
                                            tabIndex={9}
                                        />
                                        <Select
                                            id="IdNivelEstudioConyuge"
                                            name="IdNivelEstudioConyuge"
                                            onChange={ handleChangeConyuge }
                                            value={ IdNivelEstudioConyuge || null }
                                            label="Nivel de Estudio"
                                            required={false}
                                            optionValue="IdNivelEstudio"
                                            optionName="Nombre"
                                            options={
                                                [
                                                    {"IdNivelEstudio" : "1", "Nombre" : "Primaria"},
                                                    {"IdNivelEstudio" : "2", "Nombre" : "Bachiller"},
                                                    {"IdNivelEstudio" : "3", "Nombre" : "Técnico"},
                                                    {"IdNivelEstudio" : "4", "Nombre" : "Tecnología"},
                                                    {"IdNivelEstudio" : "5", "Nombre" : "Pregrado"},
                                                    {"IdNivelEstudio" : "6", "Nombre" : "Postgrado"},
                                                    {"IdNivelEstudio" : "7", "Nombre" : "Profesional"}
                                                ]
                                            }
                                            tabIndex={11}
                                        />
                                    
                                    </section>

                                    <section className='form-update__columnTwo'>
                                        <Input
                                            label='Segundo Nombre'
                                            required={false}
                                            type='text'
                                            name='SegundoNombreConyuge'
                                            value={ SegundoNombreConyuge || ''}
                                            onChange={ handleChangeConyuge }
                                            tabIndex={2}
                                        />
                                        <Input
                                            label='Segundo Apellido'
                                            type='text'
                                            required={false}
                                            name='SegundoApellidosConyuge'
                                            value={ SegundoApellidosConyuge || ''}
                                            onChange={ handleChangeConyuge }
                                            tabIndex={4}
                                        />
                                        <Select
                                            id="IdPaisConyuge"
                                            name="IdPaisConyuge"
                                            onChange={ handleChangeConyuge }
                                            value={ IdPaisConyuge || null }
                                            label="País de Residencia *"
                                            required={true}
                                            optionValue="Idpaises"
                                            optionName="Descripcion"
                                            urlOptionsService="paises"
                                            tabIndex={6}
                                        />
                                        {IdPaisConyuge == 1 &&
                                            <Select
                                                id="IdCiudadConyuge"
                                                name="IdCiudadConyuge"
                                                onChange={ handleChangeConyuge }
                                                value={ IdCiudadConyuge || null }
                                                label="Ciudad Residencia"
                                                required={false}
                                                optionValue="Idciudad"
                                                optionName="Descripcion"
                                                urlOptionsService={`ciudad?IdDepartamento=${IdDeptoConyuge}`}
                                            />
                                        }
                                        <Input
                                            label="Teléfono"
                                            type="text"
                                            value={ TelefonoConyuge || '' }
                                            name='TelefonoConyuge'
                                            onChange={ handleChangeConyuge }
                                            required={false}
                                            tabIndex={8}
                                        />
                                        <Input
                                            label="Número Documento"
                                            type="text"
                                            value={ NumeroDocumentoConyuge || '' }
                                            name='NumeroDocumentoConyuge'
                                            onChange={ handleChangeConyuge }
                                            required={false}
                                            tabIndex={10}
                                        />
                                    </section>
                                </section>

                                {/* sección Madre */}
                                <center>
                                    <h5>Datos Madre</h5>
                                </center>
                                <section className='form-update__container'>
                                    <section className='form-update__columnOne'>
                                        <Input
                                            label='Primer Nombre *'
                                            type='text'
                                            name='PrimerNombreMadre'
                                            required={true}
                                            value={ PrimerNombreMadre || ''}
                                            onChange={ handleChangeMadre }
                                            tabIndex={12}
                                        />
                                        
                                        <Input
                                            label='Primer Apellido *'
                                            required={true}
                                            type='text'
                                            name='PrimerApellidosMadre'
                                            value={ PrimerApellidosMadre || ''}
                                            onChange={ handleChangeMadre }
                                            tabIndex={14}
                                        />
                                        
                                        <Input
                                            label='Ocupación *'
                                            required={true}
                                            type='text'
                                            name='OcupacionMadre'
                                            value={ OcupacionMadre || ''}
                                            onChange={ handleChangeMadre }
                                            tabIndex={16}
                                        />
                                        {IdPaisMadre == 1 &&
                                            <Select
                                            id="IdDeptoMadre"
                                            name="IdDeptoMadre"
                                            onChange={ handleChangeMadre }
                                            value={IdDeptoMadre || null }
                                            label="Departamento de Residencia*"
                                            required={false}
                                            optionValue="IdDepartamento"
                                            optionName="Descripcion"
                                            urlOptionsService={`departamentos?IdPaises=${IdPaisMadre}`}
                                            />
                                        }
                                        <Input
                                            label="Dirección *"
                                            type="text"
                                            value={ DireccionMadre || '' }
                                            name='DireccionMadre'
                                            onChange={ handleChangeMadre }
                                            required={true}
                                            tabIndex={18}
                                        />
                                        <Select
                                            id="IdTipoIdentidadMadre"
                                            name="IdTipoIdentidadMadre"
                                            onChange={ handleChangeMadre }
                                            value={ IdTipoIdentidadMadre || null }
                                            label="Tipo de Documento *"
                                            optionValue="IdTipoIdentidad"
                                            optionName="Descripcion"
                                            required={true}
                                            urlOptionsService="tipoIdentidad"
                                            tabIndex={20}
                                        />
                                        <Select
                                            id="IdNivelEstudioMadre"
                                            name="IdNivelEstudioMadre"
                                            onChange={ handleChangeMadre }
                                            value={ IdNivelEstudioMadre || null }
                                            label="Nivel de Estudio  *"
                                            required={true}
                                            optionValue="IdNivelEstudio"
                                            optionName="Nombre"
                                            options={
                                                [
                                                    {"IdNivelEstudio" : "1", "Nombre" : "Primaria"},
                                                    {"IdNivelEstudio" : "2", "Nombre" : "Bachiller"},
                                                    {"IdNivelEstudio" : "3", "Nombre" : "Técnico"},
                                                    {"IdNivelEstudio" : "4", "Nombre" : "Tecnología"},
                                                    {"IdNivelEstudio" : "5", "Nombre" : "Pregrado"},
                                                    {"IdNivelEstudio" : "6", "Nombre" : "Postgrado"},
                                                    {"IdNivelEstudio" : "7", "Nombre" : "Profesional"}
                                                ]
                                            }
                                            tabIndex={22}
                                        />
                                    </section>

                                    <section className='form-update__columnTwo'>
                                        <Input
                                            label='Segundo Nombre'
                                            required={false}
                                            type='text'
                                            name='SegundoNombreMadre'
                                            value={ SegundoNombreMadre || ''}
                                            onChange={ handleChangeMadre }
                                            tabIndex={13}
                                        />
                                        <Input
                                            label='Segundo Apellido'
                                            type='text'
                                            required={false}
                                            name='SegundoApellidosMadre'
                                            value={ SegundoApellidosMadre || ''}
                                            onChange={ handleChangeMadre }
                                            tabIndex={15}
                                        />
                                        <Select
                                            id="IdPaisMadre"
                                            name="IdPaisMadre"
                                            onChange={ handleChangeMadre }
                                            value={ IdPaisMadre || null }
                                            label="País de Residencia *"
                                            required={true}
                                            optionValue="Idpaises"
                                            optionName="Descripcion"
                                            urlOptionsService="paises"
                                            tabIndex={17}
                                        />
                                        {IdPaisMadre == 1 &&
                                            <Select
                                                id="IdCiudadMadre"
                                                name="IdCiudadMadre"
                                                onChange={ handleChangeMadre }
                                                value={ IdCiudadMadre || null }
                                                label="Ciudad de Residencia*"
                                                required={true}
                                                optionValue="Idciudad"
                                                optionName="Descripcion"
                                                urlOptionsService={`ciudad?IdDepartamento=${IdDeptoMadre}`}
                                            />
                                        }
                                        <Input
                                            label="Teléfono *"
                                            type="number"
                                            value={ TelefonoMadre || '' }
                                            name='TelefonoMadre'
                                            onChange={ handleChangeMadre }
                                            required={true}
                                            tabIndex={19}
                                        />
                                        <Input
                                            label="Número Documento *"
                                            type="text"
                                            value={ NumeroDocumentoMadre || '' }
                                            name='NumeroDocumentoMadre'
                                            onChange={ handleChangeMadre }
                                            required={true}
                                            tabIndex={21}
                                        />
                                        <br />
                                        <label htmlFor="">¿ Vive ?</label>                                
                                        <Form.Check 
                                            type="switch"
                                            id="MadreVive"
                                            label="Sí"
                                            value={madreVive}
                                            checked={MadreVive}
                                            onChange = {(e) => {
                                                setMadreVive(e.target.checked);
                                            }}
                                            tabIndex={23}
                                        />
                                    </section>
                                </section>

                                {/* sección Padre */}
                                <center>
                                    <h5>Datos Padre</h5>
                                </center>
                                <section className='form-update__container'>
                                    <section className='form-update__columnOne'>
                                        <Input
                                            label='Primer Nombre *'
                                            type='text'
                                            name='PrimerNombrePadre'
                                            required={true}
                                            value={ PrimerNombrePadre || ''}
                                            onChange={ handleChangePadre }
                                            tabIndex={24}
                                        />
                                        
                                        <Input
                                            label='Primer Apellido *'
                                            required={true}
                                            type='text'
                                            name='PrimerApellidosPadre'
                                            value={ PrimerApellidosPadre || ''}
                                            onChange={ handleChangePadre }
                                            tabIndex={26}
                                        />
                                        
                                        <Input
                                            label='Ocupación *'
                                            required={true}
                                            type='text'
                                            name='OcupacionPadre'
                                            value={ OcupacionPadre || ''}
                                            onChange={ handleChangePadre }
                                            tabIndex={28}
                                        />
                                        {IdPaisPadre == 1 &&
                                            <Select
                                            id="IdDeptoPadre"
                                            name="IdDeptoPadre"
                                            onChange={ handleChangePadre }
                                            value={IdDeptoPadre || null }
                                            label="Departamento de Residencia*"
                                            required={false}
                                            optionValue="IdDepartamento"
                                            optionName="Descripcion"
                                            urlOptionsService={`departamentos?IdPaises=${IdPaisPadre}`}
                                            />
                                        }
                                        <Input
                                            label="Dirección *"
                                            type="text"
                                            value={ DireccionPadre || '' }
                                            name='DireccionPadre'
                                            onChange={ handleChangePadre }
                                            required={true}
                                            tabIndex={30}
                                        />
                                        <Select
                                            id="IdTipoIdentidadPadre"
                                            name="IdTipoIdentidadPadre"
                                            onChange={ handleChangePadre }
                                            value={ IdTipoIdentidadPadre || null }
                                            label="Tipo de Documento *"
                                            optionValue="IdTipoIdentidad"
                                            optionName="Descripcion"
                                            required={true}
                                            urlOptionsService="tipoIdentidad"
                                            tabIndex={32}
                                        />
                                        <Select
                                            id="IdNivelEstudioPadre"
                                            name="IdNivelEstudioPadre"
                                            onChange={ handleChangePadre }
                                            value={ IdNivelEstudioPadre || null }
                                            label="Nivel de Estudio  *"
                                            required={true}
                                            optionValue="IdNivelEstudio"
                                            optionName="Nombre"
                                            options={
                                                [
                                                    {"IdNivelEstudio" : "1", "Nombre" : "Primaria"},
                                                    {"IdNivelEstudio" : "2", "Nombre" : "Bachiller"},
                                                    {"IdNivelEstudio" : "3", "Nombre" : "Técnico"},
                                                    {"IdNivelEstudio" : "4", "Nombre" : "Tecnología"},
                                                    {"IdNivelEstudio" : "5", "Nombre" : "Pregrado"},
                                                    {"IdNivelEstudio" : "6", "Nombre" : "Postgrado"},
                                                    {"IdNivelEstudio" : "7", "Nombre" : "Profesional"}
                                                ]
                                            }
                                            tabIndex={34}
                                        />
                                    </section>

                                    <section className='form-update__columnTwo'>
                                        <Input
                                            label='Segundo Nombre'
                                            required={false}
                                            type='text'
                                            name='SegundoNombrePadre'
                                            value={ SegundoNombrePadre || ''}
                                            onChange={ handleChangePadre }
                                            tabIndex={25}
                                        />
                                        <Input
                                            label='Segundo Apellido'
                                            type='text'
                                            required={false}
                                            name='SegundoApellidosPadre'
                                            value={ SegundoApellidosPadre || ''}
                                            onChange={ handleChangePadre }
                                            tabIndex={27}
                                        />
                                        <Select
                                            id="IdPaisPadre"
                                            name="IdPaisPadre"
                                            onChange={ handleChangePadre }
                                            value={ IdPaisPadre || null }
                                            label="País de Residencia *"
                                            required={true}
                                            optionValue="Idpaises"
                                            optionName="Descripcion"
                                            urlOptionsService="paises"
                                            tabIndex={29}
                                        />
                                        {IdPaisPadre == 1 &&
                                            <Select
                                                id="IdCiudadPadre"
                                                name="IdCiudadPadre"
                                                onChange={ handleChangePadre }
                                                value={ IdCiudadPadre || null }
                                                label="Ciudad de Residencia*"
                                                required={true}
                                                optionValue="Idciudad"
                                                optionName="Descripcion"
                                                urlOptionsService={`ciudad?IdDepartamento=${IdDeptoPadre}`}
                                            />
                                        }
                                        <Input
                                            label="Teléfono *"
                                            type="text"
                                            value={ TelefonoPadre || '' }
                                            name='TelefonoPadre'
                                            onChange={ handleChangePadre }
                                            required={true}
                                            tabIndex={31}
                                        />
                                        <Input
                                            label="Número Documento *"
                                            type="text"
                                            value={ NumeroDocumentoPadre || '' }
                                            name='NumeroDocumentoPadre'
                                            onChange={ handleChangePadre }
                                            required={true}
                                            tabIndex={33}
                                        />
                                        <br />
                                        <label htmlFor="">¿ Vive ?</label>                                
                                        <Form.Check 
                                            type="switch"
                                            id="PadreVive"
                                            label="Sí"
                                            value={padreVive}
                                            checked={PadreVive}
                                            onChange = {(e) => {
                                                setPadreVive(e.target.checked);
                                            }}
                                            tabIndex={35}
                                        />
                                        
                                    </section>
                                </section>
                                <hr />
                                <section className='form-update__container'>
                                    <section className='form-update__columnOne'>
                                        <Input
                                            label='En Emergencias Llamar a *'
                                            type='text'
                                            name='PersonaEmergencias'
                                            required={true}
                                            value={PersonaEmergencias || ''}
                                            onChange={ handleChange }
                                            tabIndex={36}
                                        />
                                        <Select
                                            id="IdPaisPerEmergencia"
                                            name="IdPaisPerEmergencia"
                                            onChange={ handleChange }
                                            value={ IdPaisPerEmergencia || null }
                                            label="País de Residencia *"
                                            required={true}
                                            optionValue="Idpaises"
                                            optionName="Descripcion"
                                            urlOptionsService="paises"
                                            tabIndex={38}
                                        />
                                        {IdPaisPerEmergencia == 1 &&
                                            <Select
                                                id="IdCiudadPerEmergencia"
                                                name="IdCiudadPerEmergencia"
                                                onChange={ handleChange }
                                                value={ IdCiudadPerEmergencia || null }
                                                label="Ciudad *"
                                                required={true}
                                                optionValue="Idciudad"
                                                optionName="Descripcion"
                                                urlOptionsService={`ciudad?IdDepartamento=${IdDeptoPerEmergencia}`}
                                            />
                                        }
                                    </section>

                                    <section className='form-update__columnTwo'>
                                        <Input
                                            label='Teléfono *'
                                            required={true}
                                            type='number'
                                            name='TelefonoEmergencia'
                                            value={ TelefonoEmergencia || ''}
                                            onChange={ handleChange }
                                            tabIndex={37}
                                        />
                                        {IdPaisPerEmergencia == 1 &&
                                            <Select
                                            id="IdDeptoPerEmergencia"
                                            name="IdDeptoPerEmergencia"
                                            onChange={ handleChange }
                                            value={IdDeptoPerEmergencia || null }
                                            label="Departamento de Residencia*"
                                            required={false}
                                            optionValue="IdDepartamento"
                                            optionName="Descripcion"
                                            urlOptionsService={`departamentos?IdPaises=${IdPaisPerEmergencia}`}
                                            />
                                        }
                                        <Select
                                            id="IdFuenteFinanciacionEstudio"
                                            name="IdFuenteFinanciacionEstudio"
                                            onChange={ handleChange }
                                            value={ IdFuenteFinanciacionEstudio || null }
                                            label="Fuente Financiación Estudios *"
                                            required={true}
                                            optionValue="IdFuenteFinanciacion"
                                            optionName="Nombre"
                                            options={[
                                                {"IdFuenteFinanciacion": "1", "Nombre": "Recursos Propios"},
                                                {"IdFuenteFinanciacion": "2", "Nombre": "Crédito"},
                                                {"IdFuenteFinanciacion": "3", "Nombre": "Auxilio Empresa"},
                                                {"IdFuenteFinanciacion": "4", "Nombre": "Padres"},
                                                {"IdFuenteFinanciacion": "5", "Nombre": "Otro"}
                                            ]}
                                            tabIndex={39}
                                        />
                                    </section>
                                </section>

                                <div style={{textAlign:"right",paddingTop:15, marginRight: 65, marginBottom: 40}}>
                                    <Button 
                                        type='send'
                                        loading={ loading }
                                    >
                                        Actualizar
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </Tab>
                    <Tab eventKey="informacion_academica" title="Información Académica" disabled={false}>
                        <form 
                        onSubmit={ handleSudmitInfoAcademica }
                            className='user-profile__form'
                        >
                            <div>
                                <center>
                                    <h5>Información Socieconómica E Icfes</h5>
                                </center>
                                <section className='form-update__container'>
                                    <section className='form-update__columnOne'>
                                        <Select
                                            id="IdReligion"
                                            name="IdReligion"
                                            onChange={ handleChange }
                                            value={ IdReligion || null }
                                            label="Religión *"
                                            required={true}
                                            optionValue="IdReligion"
                                            optionName="Nombre"
                                            options={[
                                                {"IdReligion": "1", "Nombre": "Baha'i"},
                                                {"IdReligion": "2", "Nombre": "Budismo"},
                                                {"IdReligion": "3", "Nombre": "Cao Dai"},
                                                {"IdReligion": "4", "Nombre": "Cristianismo"},
                                                {"IdReligion": "5", "Nombre": "Espiritísmo"},
                                                {"IdReligion": "6", "Nombre": "Hinduismo"},
                                                {"IdReligion": "7", "Nombre": "Islam"},
                                                {"IdReligion": "8", "Nombre": "Jainismo"},
                                                {"IdReligion": "9", "Nombre": "Judaísmo"},
                                                {"IdReligion": "10", "Nombre": "Neo-Paganism"},
                                                {"IdReligion": "11", "Nombre": "Primal Indígenas"},
                                                {"IdReligion": "12", "Nombre": "Rastafari"},
                                                {"IdReligion": "13", "Nombre": "Sijismo"},
                                                {"IdReligion": "14", "Nombre": "Shinto"},
                                                {"IdReligion": "15", "Nombre": "Religión Tradicional China"},
                                                {"IdReligion": "16", "Nombre": "Tradicional Africana"},
                                                {"IdReligion": "17", "Nombre": "Tenrikyo"},
                                                {"IdReligion": "18", "Nombre": "Unitarian-Universalism"},
                                                {"IdReligion": "19", "Nombre": "Zoroastrismo"},
                                                {"IdReligion": "20", "Nombre": "Ninguna"},
                                                {"IdReligion": "21", "Nombre": "Otra"}
                                            ]}
                                            tabIndex={1}
                                        />
                                        <Input
                                            label='SISBEN *'
                                            type='text'
                                            name='SISBEN'
                                            required={true}
                                            value={ SISBEN || ''}
                                            onChange={ handleChange }
                                            tabIndex={3}
                                        />
                                        <br />
                                        <label htmlFor="">¿ Es Víctima del Conflicto Armado ?</label>                                
                                        <Form.Check 
                                            type="switch"
                                            id="VictimaConflictoArmado"
                                            label="Sí"
                                            value={victimaConflictoArmado}
                                            checked={VictimaConflictoArmado}
                                            onChange = {(e) => {
                                                setVictimaConflictoArmado(e.target.checked);
                                            }}
                                            tabIndex={5}
                                        />
                                    </section>

                                    <section className='form-update__columnTwo'>
                                        <Input
                                            label='Financiación de Estudios *'
                                            required={true}
                                            type='text'
                                            name='FinanciacionEstudioIcfes'
                                            value={ FinanciacionEstudioIcfes || ''}
                                            onChange={ handleChangeIcfes }
                                            tabIndex={2}
                                        />
                                        <br />
                                        <label htmlFor="">¿ Pertenece a Grupos Vulnerables ?</label>                                
                                        <Form.Check 
                                            type="switch"
                                            id="PerteneceGrupoVulnerable"
                                            label="Sí"
                                            value={perteneceGrupoVulnerable}
                                            onChange = {(e) => {
                                                setPerteneceGrupoVulnerable(e.target.checked);
                                            }}
                                            tabIndex={4}
                                        />
                                        <br />
                                        <label htmlFor="">¿ Pertenece a Población con Necesidad Especial ?</label>                                
                                        <Form.Check 
                                            type="switch"
                                            id="VictimaConflictoArmado"
                                            label="Sí"
                                            value={poblacionNecesidadEspecial}
                                            onChange = {(e) => {
                                                setPoblacionNecesidadEspecial(e.target.checked);
                                            }}
                                            tabIndex={6}
                                        />
                                    </section>
                                </section>
                                
                                <center>
                                    <h5>Información Icfes O Saber 11</h5>
                                </center>
                                <section className='form-sign-up__container'>
                                    <section className='form-update__columnOne'>
                                        <Input
                                            label='N° Registro SNP *'
                                            type='text'
                                            name='NumeroRegistroNSP'
                                            required={true}
                                            value={ NumeroRegistroNSP || ''}
                                            onChange={ handleChangeIcfes }
                                            tabIndex={7}
                                        />
                                        <Input
                                            label='Puntaje Hasta 1999 *'
                                            type='text'
                                            name='PuntajeIcfes1999'
                                            required={true}
                                            value={ PuntajeIcfes1999 || ''}
                                            onChange={ handleChangeIcfes }
                                            tabIndex={9}
                                        />
                                        <Input
                                            label='Puntaje Desde 2015 *'
                                            type='text'
                                            name='PuntajeIcfes2015'
                                            required={true}
                                            value={ PuntajeIcfes2015 || ''}
                                            onChange={ handleChangeIcfes }
                                            tabIndex={11}
                                        />
                                    </section>

                                    <section className='form-update__columnTwo'>
                                        <Input
                                            label='Fecha Presentación SNP*'
                                            required={true}
                                            type='date'
                                            name='FechaPresentacionSNP'
                                            value={ FechaPresentacionSNP || ''}
                                            onChange={ handleChangeIcfes }
                                            tabIndex={8}
                                        />
                                        <Input
                                            label='Puntaje Desde 2000 Hasta 2014 *'
                                            type='text'
                                            name='PuntajeIcfes2000_2014'
                                            required={true}
                                            value={ PuntajeIcfes2000_2014 || ''}
                                            onChange={ handleChangeIcfes }
                                            tabIndex={10}
                                        />
                                    </section>
                                </section>

                                <center>
                                    <h5>Información Académica Educación Secundaria</h5>
                                </center>
                                <section className='form-update__container'>
                                    <section className='form-update__columnOne'>
                                        <Input
                                            label='Institución (Colegio) *'
                                            type='text'
                                            name='InstitucionSecundaria'
                                            required={true}
                                            value={ InstitucionSecundaria || ''}
                                            onChange={ handleChangeEduSec }
                                            tabIndex={11}
                                        />
                                        <Input
                                            label='Modalidad *'
                                            type='text'
                                            name='ModalidadSecundaria'
                                            required={true}
                                            value={ ModalidadSecundaria || ''}
                                            onChange={ handleChangeEduSec }
                                            tabIndex={13}
                                        />
                                        <Select
                                            id="JornadaSecundaria"
                                            name="JornadaSecundaria"
                                            onChange={ handleChangeEduSec }
                                            value={ JornadaSecundaria || null }
                                            label="Jornada *"
                                            required={true}
                                            optionValue="Nombre"
                                            optionName="Nombre"
                                            options={[
                                                {"IdJornada": "1", "Nombre": "Diurna"},
                                                {"IdJornada": "2", "Nombre": "Nocturna"},
                                            ]}
                                            tabIndex={15}
                                        />
                                        <Input
                                            label='Año Grado *'
                                            type='date'
                                            name='PeriodoGradoSecundaria'
                                            required={true}
                                            value={ PeriodoGradoSecundaria || ''}
                                            onChange={ handleChangeEduSec }
                                            tabIndex={17}
                                        />
                                        <Input
                                            label='Fecha Terminación *'
                                            type='date'
                                            name='FechaTerminacionSecundaria'
                                            required={true}
                                            value={ FechaTerminacionSecundaria || ''}
                                            onChange={ handleChangeEduSec }
                                            tabIndex={19}
                                        />
                                        <br />
                                        <label htmlFor="">¿ Grado de Honor ?</label>                                
                                        <Form.Check 
                                            type="switch"
                                            id="GradoHonorSecundaria"
                                            label="Sí"
                                            value={gradoHonorSecundaria}
                                            onChange = {(e) => {
                                                setGradoHonorSecundaria(e.target.checked);
                                            }}
                                            tabIndex={21}
                                        />
                                    </section>

                                    <section className='form-update__columnTwo'>
                                        <Select
                                            id="ProcedenciaSecundaria"
                                            name="ProcedenciaSecundaria"
                                            onChange={ handleChangeEduSec }
                                            value={ ProcedenciaSecundaria || null }
                                            label="Procedencia *"
                                            required={true}
                                            optionValue="Nombre"
                                            optionName="Nombre"
                                            options={[
                                                {"IdProcedencia": "1", "Nombre": "Oficial"},
                                                {"IdProcedencia": "2", "Nombre": "Privado"},
                                            ]}
                                            tabIndex={12}
                                        />
                                        <Select
                                            id="NaturalezaSecundaria"
                                            name="NaturalezaSecundaria"
                                            onChange={ handleChangeEduSec }
                                            value={ NaturalezaSecundaria || null }
                                            label="Naturaleza *"
                                            required={true}
                                            optionValue="Nombre"
                                            optionName="Nombre"
                                            options={[
                                                {"IdNaturaleza": "1", "Nombre": "Urbano"},
                                                {"IdNaturaleza": "2", "Nombre": "Nocturno"},
                                            ]}
                                            tabIndex={14}
                                        />
                                        <Select
                                            id="CalendarioSecundaria"
                                            name="CalendarioSecundaria"
                                            onChange={ handleChangeEduSec }
                                            value={ CalendarioSecundaria || null }
                                            label="Calendario *"
                                            required={true}
                                            optionValue="Nombre"
                                            optionName="Nombre"
                                            options={[
                                                {"IdCalendario": "1", "Nombre": "A"},
                                                {"IdCalendario": "2", "Nombre": "B"}
                                            ]}
                                            tabIndex={16}
                                        />
                                        
                                        <Input
                                            label='Último Año Cursado *'
                                            type='number'
                                            name='UltimoPeriodoSecundaria'
                                            required={true}
                                            value={ UltimoPeriodoSecundaria || ''}
                                            onChange={ handleChangeEduSec }
                                            tabIndex={18}
                                        />
                                        <br />
                                        <label htmlFor="">¿ Validación ?</label>                                
                                        <Form.Check 
                                            type="switch"
                                            id="ValidacionSecundaria"
                                            label="Sí"
                                            value={validacionSecundaria}
                                            onChange = {(e) => {
                                                setValidacionSecundaria(e.target.checked);
                                            }}
                                            tabIndex={20}
                                        />
                                    </section>
                                </section>

                                <center>
                                    <h5>Información Académica Educación Superior</h5>
                                </center>
                                <section className='form-update__container'>
                                    <section className='form-update__columnOne'>
                                        <div style={{textAlign: 'right', marginTop: '10px'}}>
                                            <Button variant="secondary" onClick={() => setModal(true)}>Agregar</Button>{' '}
                                            {/* <Button variant="secondary" onClick={() => setModal2(true)}>Ver Estudios</Button>{' '} */}
                                        </div>
                                        {/* <Input
                                            label='Institución *'
                                            type='text'
                                            name='institucionSuperior'
                                            required={true}
                                            value={ institucionSuperior || ''}
                                            onChange={ handleChangeEduSup }
                                            tabIndex={22}
                                        />
                                        <Input
                                            label='Título Obtenido *'
                                            type='text'
                                            name='TituloSuperiorObtenido'
                                            required={true}
                                            value={ TituloSuperiorObtenido || ''}
                                            onChange={ handleChangeEduSup }
                                            tabIndex={24}
                                        />
                                        <Select
                                            id="JornadaSuperior"
                                            name="JornadaSuperior"
                                            onChange={ handleChangeEduSup }
                                            value={ JornadaSuperior || null }
                                            label="Jornada *"
                                            required={true}
                                            optionValue="Nombre"
                                            optionName="Nombre"
                                            options={[
                                                {"IdJornada": "1", "Nombre": "Diurna"},
                                                {"IdJornada": "2", "Nombre": "Nocturna"}
                                            ]}
                                            tabIndex={26}
                                        />
                                        <Input
                                            label='Tarjeta Profesional *'
                                            type='text'
                                            name='TarjetaProfesionalSuperior'
                                            required={true}
                                            value={ TarjetaProfesionalSuperior || ''}
                                            onChange={ handleChangeEduSup }
                                            tabIndex={28}
                                        />
                                        <Input
                                            label='Fecha Retiro *'
                                            type='date'
                                            name='FechaRetiroProgSuperior'
                                            required={true}
                                            value={ FechaRetiroProgSuperior || ''}
                                            onChange={ handleChangeEduSup }
                                            tabIndex={30}
                                        />
                                        <br />
                                        <h6>Solicita Tramite de Homologación</h6>  
                                        <Form.Check 
                                            type="switch"
                                            name="SolicitaHomologacion"
                                            id="SolicitaHomologacion"
                                            label="Sí"
                                            value={solicitaHomologacion}
                                            onChange = {(e) => {
                                                setSolicitaHomologacion(e.target.checked)
                                            }}
                                            tabIndex={32}
                                        /> */}
                                    </section>

                                    <section className='form-update__columnTwo'>
                                        <div style={{textAlign: 'right', marginTop: '10px'}}>
                                            {/* <Button variant="secondary" onClick={() => setModal(true)}>Agregar</Button>{' '} */}
                                            <Button variant="secondary" onClick={() => setModal2(true)}>Ver Estudios</Button>{' '}
                                        </div>
                                        {/* <Input
                                            label='Programa Académico *'
                                            required={true}
                                            type='text'
                                            name='ProgramaAcademicoSuperior'
                                            value={ ProgramaAcademicoSuperior || ''}
                                            onChange={ handleChangeEduSup }
                                            tabIndex={23}
                                        />
                                        <Select
                                            id="IdNivelEducacionSuperior"
                                            name="IdNivelEducacionSuperior"
                                            onChange={ handleChangeEduSup }
                                            value={ IdNivelEducacionSuperior || null }
                                            label="Nivel de Formación *"
                                            required={true}
                                            optionValue="IdNivelEstudioSuperior"
                                            optionName="Nombre"
                                            options={[
                                                {"IdNivelEstudioSuperior" : "1", "Nombre" : "Técnico Profesional"},
                                                {"IdNivelEstudioSuperior" : "2", "Nombre" : "Tecnológico"},
                                                {"IdNivelEstudioSuperior" : "3", "Nombre" : "Profesional"},
                                                {"IdNivelEstudioSuperior" : "4", "Nombre" : "Especialización Tecnológica"},
                                                {"IdNivelEstudioSuperior" : "5", "Nombre" : "Especialización Profesional"},
                                                {"IdNivelEstudioSuperior" : "6", "Nombre" : "Maestría"},
                                                {"IdNivelEstudioSuperior" : "7", "Nombre" : "Doctorado"},
                                            ]}
                                            tabIndex={25}
                                        />
                                        <Input
                                            label='Semestres *'
                                            required={true}
                                            type='text'
                                            name='SemestresProgramaSuperior'
                                            value={ SemestresProgramaSuperior || ''}
                                            onChange={ handleChangeEduSup }
                                            tabIndex={27}
                                        />
                                        <Input
                                            label='Fecha Terminación *'
                                            type='date'
                                            name='FechaTerminacionProgSuperior'
                                            required={true}
                                            value={ FechaTerminacionProgSuperior || ''}
                                            onChange={ handleChangeEduSup }
                                            tabIndex={29}
                                        />
                                        <Input
                                            label='Motivo Retiro *'
                                            type='text'
                                            name='MotivoRetiroProgSuperior'
                                            required={true}
                                            value={ MotivoRetiroProgSuperior || ''}
                                            onChange={ handleChangeEduSup }
                                            tabIndex={31}
                                        />
                                        <Input
                                            label='(Transferencia Externa) Registro SNIES o Registro Calificado Número: *'
                                            type='text'
                                            name='TransferenciaExternaProgSuperior'
                                            required={true}
                                            value={ TransferenciaExternaProgSuperior || ''}
                                            onChange={ handleChangeEduSup }
                                            tabIndex={33}
                                        /> */}  
                                        {/* <div style={{textAlign: 'right', marginTop: '10px'}}>
                                            <Button variant="secondary" onClick={() => setModal(true)}>Agregar</Button>{' '}
                                            <Button variant="secondary" onClick={() => setModal2(true)}>Ver Estudios</Button>{' '}
                                        </div> */}
                                    </section>
                                    {/* <div style={{textAlign:"right",paddingTop:15, marginRight: 65, marginBottom: 40}}>
                                    <Button 
                                        type='send'
                                        loading={ loading }
                                    >
                                        Agregar
                                    </Button>
                                    <Button 
                                        type='send'
                                        loading={ loading }
                                    >
                                        Ver
                                    </Button>
                                </div> */}
                                </section>
                                {Docente &&
                                <>
                                <center>
                                    <h5>Información Académica en Curso</h5>
                                </center>
                                <section className='form-update__container'>
                                    <section className='form-update__columnOne'>
                                        <div style={{textAlign: 'right', marginTop: '10px'}}>
                                            <Button variant="secondary" onClick={() => setModalEducacionenCurso(true)}>Agregar</Button>{' '}
                                        </div>
                                    </section>

                                    <section className='form-update__columnTwo'>
                                        <div style={{textAlign: 'right', marginTop: '10px'}}>
                                            <Button variant="secondary" onClick={() => setModalEducacionenCurso2(true)}>Ver Estudios</Button>{' '}
                                        </div>
                                    </section>
                                </section>
                                </>
                                }
                                
                                {Docente &&
                                <>
                                <center>
                                    <h5>Información Académica Otros Estudios de Cualificación</h5>
                                </center>
                                <section className='form-update__container'>
                                    <section className='form-update__columnOne'>
                                        <div style={{textAlign: 'right', marginTop: '10px'}}>
                                            <Button variant="secondary" onClick={() => setModalOtrosEstudios(true)}>Agregar</Button>{' '}
                                        </div>
                                    </section>

                                    <section className='form-update__columnTwo'>
                                        <div style={{textAlign: 'right', marginTop: '10px'}}>
                                            <Button variant="secondary" onClick={() => setModalOtrosEstudios2(true)}>Ver Estudios</Button>{' '}
                                        </div>
                                    </section>
                                </section>
                                </>
                                }
                                
                                {Docente &&
                                <>
                                <center>
                                    <h5>Información Académica en el Ámbito Aeronáutico</h5>
                                </center>
                                <section className='form-update__container'>
                                    <section className='form-update__columnOne'>
                                        <div style={{textAlign: 'right', marginTop: '10px'}}>
                                            <Button variant="secondary" onClick={() => setModalEstudioAmbitoAeronautico(true)}>Agregar</Button>{' '}
                                        </div>
                                    </section>

                                    <section className='form-update__columnTwo'>
                                        <div style={{textAlign: 'right', marginTop: '10px'}}>
                                            <Button variant="secondary" onClick={() => setModalEstudioAmbitoAeronautico2(true)}>Ver Estudios</Button>{' '}
                                        </div>
                                    </section>
                                </section>
                                </>
                                }

                                <center>
                                    <h5>Idiomas</h5>
                                </center>
                                <section className='form-update__container'>
                                    <section className='form-update__columnOne'>
                                        <Select
                                            id="IdNivelIngles"
                                            name="IdNivelIngles"
                                            onChange={ handleChange }
                                            value={ IdNivelIngles || null }
                                            label="Nivel de Inglés *"
                                            required={true}
                                            optionValue="IdNivelIngles"
                                            optionName="Nombre"
                                            options={[
                                                {"IdNivelIngles":"1", "Nombre":"A0"},
                                                {"IdNivelIngles":"2", "Nombre":"A1 - A2"},
                                                {"IdNivelIngles":"3", "Nombre":"A2 - B1"},
                                                {"IdNivelIngles":"4", "Nombre":"B1"},
                                                {"IdNivelIngles":"5", "Nombre":"B2"},
                                                {"IdNivelIngles":"6", "Nombre":"C1 - C2"},
                                                {"IdNivelIngles":"7", "Nombre":"Otro"},
                                            ]}
                                            tabIndex={34}
                                        />
                                        <Input
                                            label='Otro'
                                            type='text'
                                            name='OtroIdioma'
                                            required={false}
                                            value={ OtroIdioma || ''}
                                            onChange={ handleChange }
                                            tabIndex={36}
                                        />
                                    </section>

                                    <section className='form-update__columnTwo'>
                                        <Input
                                            label='Institución *'
                                            required={true}
                                            type='text'
                                            name='InstitucionIdioma'
                                            value={ InstitucionIdioma || ''}
                                            onChange={ handleChange }
                                            tabIndex={35}
                                        />
                                    </section>
                                </section>

                                <div style={{textAlign:"right",paddingTop:15, marginRight: 65, marginBottom: 40}}>
                                    <Button 
                                        type='send'
                                        loading={ loading }
                                    >
                                        Actualizar
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </Tab>
                    <Tab eventKey="informacion_laboral" title="Información Laboral y Adicional">
                        <form 
                        onSubmit={ handleSudmitInfoLaboral }
                            className='user-profile__form'
                        >    
                            <div>
                                <center>
                                    <h5>Información Laboral</h5>
                                </center>
                                <section className='form-update__container'>
                                    <section className='form-update__columnOne'>
                                        <div style={{textAlign: 'right', marginTop: '10px'}}>
                                            <Button 
                                                variant="secondary" onClick={() => setModalLaboral(true)}
                                            >
                                                Agregar Experiencia
                                            </Button>
                                        </div>
                                        
                                        {/* <br />
                                        <h6>Trabaja Actualmente</h6>  
                                        <Form.Check 
                                            type="switch"
                                            name="ActualmenteTrabaja"
                                            id="ActualmenteTrabaja"
                                            label="Sí"
                                            checked = {actualmenteTrabaja}
                                            value={actualmenteTrabaja}
                                            onChange={ (e) => {
                                                setActualmenteTrabaja(e.target.checked)
                                            }}
                                            tabIndex={1}
                                        />
                                        {actualmenteTrabaja &&
                                            <>
                                            <Input
                                                label='Cargo *'
                                                type='text'
                                                name='CargoLabora'
                                                required={true}
                                                value={ CargoLabora || ''}
                                                onChange={ handleChangeInfoLaboral }
                                                tabIndex={3}
                                            />
                                            <Input
                                                label='Actividad de la Empresa  *'
                                                type='text'
                                                name='ActividadEmpresa'
                                                required={true}
                                                value={ ActividadEmpresa || ''}
                                                onChange={ handleChangeInfoLaboral }
                                                tabIndex={5}
                                            />
                                            <Input
                                                label='Teléfono Fijo *'
                                                type='number'
                                                name='TelefonoLabora'
                                                required={true}
                                                value={ TelefonoLabora || ''}
                                                onChange={ handleChangeInfoLaboral }
                                                tabIndex={7}
                                            />
                                            <Input
                                                label='Correo Electrónico Laboral *'
                                                type='email'
                                                name='EmailLabora'
                                                required={true}
                                                value={ EmailLabora || '' }
                                                onChange={ handleChangeInfoLaboral }
                                                tabIndex={9}
                                            />
                                            </>
                                        } */}
                                    </section>

                                    <section className='form-update__columnTwo'>
                                        <div style={{textAlign: 'right', marginTop: '10px'}}>
                                            <Button 
                                                variant="secondary" onClick={() => setModalLaboral2(true)}
                                            >
                                                Ver Experiencia
                                            </Button>
                                        </div>
                                        {/* {actualmenteTrabaja &&
                                            <>
                                            <Input
                                                label='Nombre de la Empresa *'
                                                required={true}
                                                type='text'
                                                name='EmpresaLabora'
                                                value={ EmpresaLabora || ''}
                                                onChange={ handleChangeInfoLaboral }
                                                tabIndex={2}
                                            />
                                            <Input
                                                label='Dirección *'
                                                required={true}
                                                type='text'
                                                name='DireccionLabora'
                                                value={ DireccionLabora || ''}
                                                onChange={ handleChangeInfoLaboral }
                                                tabIndex={4}
                                            />
                                            <Select
                                                id="CiudadEmpresa"
                                                name="CiudadEmpresa"
                                                onChange={ handleChangeInfoLaboral }
                                                value={ CiudadEmpresa || null }
                                                label="Ciudad *"
                                                required={true}
                                                optionValue="Idciudad"
                                                optionName="Descripcion"
                                                urlOptionsService={`ciudad`}
                                                tabIndex={6}
                                            />
                                            <Input
                                                label='Extensión *'
                                                type='number'
                                                name='ExtensionTelLabora'
                                                required={true}
                                                value={ ExtensionTelLabora || ''}
                                                onChange={ handleChangeInfoLaboral }
                                                tabIndex={8}
                                            />
                                            </>
                                        } */}
                                    </section>
                                </section>
                                
                                {Docente &&
                                <>
                                <center>
                                    <h5>Información de Asociaciones o Redes Académicas</h5>
                                </center>
                                <section className='form-update__container'>
                                    <section className='form-update__columnOne'>
                                        <div style={{textAlign: 'right', marginTop: '10px'}}>
                                            <Button 
                                                variant="secondary" onClick={() => setModalAsociacionRedAcademica(true)}
                                            >
                                                Agregar Asociación
                                            </Button>
                                        </div>
                                    </section>

                                    <section className='form-update__columnTwo'>
                                        <div style={{textAlign: 'right', marginTop: '10px'}}>
                                            <Button 
                                                variant="secondary" onClick={() => setModalAsociacionRedAcademica2(true)}
                                            >
                                                Ver Asociaciones
                                            </Button>
                                        </div>
                                    </section>
                                </section>
                                </>
                                }

                                <center>
                                    <h5>Actividades de Interés Cultural</h5>
                                </center>
                                <section className='form-update__container'>
                                    <section className='form-update__columnOne'>
                                        <h6>Artes</h6>  
                                        <Form.Check 
                                            type="switch"
                                            name="Artes"
                                            id="Artes"
                                            label="Sí"
                                            value={artes}
                                            onChange={(e) => {
                                                setArtes(e.target.checked)
                                            }}
                                            tabIndex={9}
                                        />
                                        <br />
                                        <h6>Danzas</h6>  
                                        <Form.Check 
                                            type="switch"
                                            name="Danzas"
                                            id="Danzas"
                                            label="Sí"
                                            value={danzas}
                                            onChange={(e) => {
                                                setDanzas(e.target.checked)
                                            }}
                                            tabIndex={11}
                                        />
                                        <br />
                                        <h6>Literatura</h6>  
                                        <Form.Check 
                                            type="switch"
                                            name="Literatura"
                                            id="Literatura"
                                            label="Sí"
                                            value={literatura}
                                            onChange={(e) => {
                                                setLiteratura(e.target.checked)
                                            }}
                                            tabIndex={13}
                                        />
                                        <br />
                                        <h6>Pintura</h6>  
                                        <Form.Check 
                                            type="switch"
                                            name="Pintura"
                                            id="Pintura"
                                            label="Sí"
                                            value={pintura}
                                            onChange={(e) => {
                                                setPintura(e.target.checked)
                                            }}
                                            tabIndex={15}
                                        />
                                        <br />
                                        <h6>Aeróbics</h6>  
                                        <Form.Check 
                                            type="switch"
                                            name="Aerobics"
                                            id="Aerobics"
                                            label="Sí"
                                            value={aerobics}
                                            onChange={(e) => {
                                                setAerobics(e.target.checked)
                                            }}
                                            tabIndex={17}
                                        />
                                        <br />
                                        <h6>Ciclismo</h6>  
                                        <Form.Check 
                                            type="switch"
                                            name="Ciclismo"
                                            id="Ciclismo"
                                            label="Sí"
                                            value={ciclismo}
                                            onChange={(e) => {
                                                setCiclismo(e.target.checked)
                                            }}
                                            tabIndex={19}
                                        />
                                        <br />
                                        <h6>Gimnasio</h6>  
                                        <Form.Check 
                                            type="switch"
                                            name="Gimnasio"
                                            id="Gimnasio"
                                            label="Sí"
                                            value={gimnasio}
                                            onChange={(e) => {
                                                setGimnasio(e.target.checked)
                                            }}
                                            tabIndex={21}
                                        />
                                        <br />
                                        <h6>Natacion</h6>  
                                        <Form.Check 
                                            type="switch"
                                            name="Natacion"
                                            id="Natacion"
                                            label="Sí"
                                            value={natacion}
                                            onChange={(e) => {
                                                setNatacion(e.target.checked)
                                            }}
                                            tabIndex={23}
                                        />
                                        <br />
                                        <h6>Veisbol</h6>  
                                        <Form.Check 
                                            type="switch"
                                            name="Veisbol"
                                            id="Veisbols"
                                            label="Sí"
                                            value={veisbol}
                                            onChange={(e) => {
                                                setVeisbol(e.target.checked)
                                            }}
                                            tabIndex={25}
                                        />
                                        <br />
                                    </section>

                                    <section className='form-update__columnTwo'>
                                        <h6>Cine</h6>  
                                        <Form.Check 
                                            type="switch"
                                            name="Cine"
                                            id="Cine"
                                            label="Sí"
                                            value={cine}
                                            onChange={(e) => {
                                                setCine(e.target.checked)
                                            }}
                                            tabIndex={10}
                                        />  
                                        <br />
                                        <h6>Ecología</h6>  
                                        <Form.Check 
                                            type="switch"
                                            name="Ecologia"
                                            id="Ecologia"
                                            label="Sí"
                                            value={ecologia}
                                            onChange={(e) => {
                                                setEcologia(e.target.checked)
                                            }}
                                            tabIndex={12}
                                        />  
                                        <br />
                                        <h6>Música</h6>  
                                        <Form.Check 
                                            type="switch"
                                            name="Musica"
                                            id="Musica"
                                            label="Sí"
                                            value={musica}
                                            onChange={(e) => {
                                                setMusica(e.target.checked)
                                            }}
                                            tabIndex={14}
                                        />  
                                        <br />
                                        <h6>Teatro</h6>  
                                        <Form.Check 
                                            type="switch"
                                            name="Teatro"
                                            id="Teatro"
                                            label="Sí"
                                            value={teatro}
                                            onChange={(e) => {
                                                setTeatro(e.target.checked)
                                            }}
                                            tabIndex={16}
                                        />  
                                        <br />
                                        <h6>Baloncesto</h6>  
                                        <Form.Check 
                                            type="switch"
                                            name="Baloncesto"
                                            id="Baloncesto"
                                            label="Sí"
                                            value={baloncesto}
                                            onChange={(e) => {
                                                setBaloncesto(e.target.checked)
                                            }}
                                            tabIndex={18}
                                        />  
                                        <br />
                                        <h6>Futbol</h6>  
                                        <Form.Check 
                                            type="switch"
                                            name="Futbol"
                                            id="Futbol"
                                            label="Sí"
                                            value={futbol}
                                            onChange={(e) => {
                                                setFutbol(e.target.checked)
                                            }}
                                            tabIndex={20}
                                        />  
                                        <br />
                                        <h6>Karate</h6>  
                                        <Form.Check 
                                            type="switch"
                                            name="Karate"
                                            id="Karate"
                                            label="Sí"
                                            value={karate}
                                            onChange={(e) => {
                                                setKarate(e.target.checked)
                                            }}
                                            tabIndex={22}
                                        />  
                                        <br />
                                        <h6>Tenis</h6>  
                                        <Form.Check 
                                            type="switch"
                                            name="Tenis"
                                            id="Tenis"
                                            label="Sí"
                                            value={tenis}
                                            onChange={(e) => {
                                                setTenis(e.target.checked)
                                            }}
                                            tabIndex={24}
                                        />  
                                        <Input
                                            label='Otro'
                                            type='text'
                                            name='OtroActividad'
                                            required={false}
                                            value={ OtroActividad || ''}
                                            onChange={ handleChangeActividadUser }
                                            tabIndex={26}
                                        />
                                    </section>
                                </section>
                                <center>
                                    <h5>Información Adicional</h5>
                                    <h6>Aspectos Principales Sobre su Desición de Ingreso al CEA</h6>
                                </center>
                                <section className='form-update__container'>
                                    <section className='form-update__columnOne'>
                                        <h6>Calidad del Servicio</h6>  
                                        <Form.Check 
                                            type="switch"
                                            name="CalidadServicio"
                                            id="CalidadServicio"
                                            label="Sí"
                                            value={calidadServicio}
                                            onChange={(e) => {
                                                setCalidadServicio(e.target.checked)
                                            }}
                                            tabIndex={27}
                                        />
                                        <br />
                                        <h6>Costos de Matrícula</h6>  
                                        <Form.Check 
                                            type="switch"
                                            name="CostosMatricula"
                                            id="CostosMatricula"
                                            label="Sí"
                                            value={costosMatricula}
                                            onChange={(e) => {
                                                setCostosMatricula(e.target.checked)
                                            }}
                                            tabIndex={29}
                                        />
                                        <br />
                                        <h6>Nivel Académico</h6>  
                                        <Form.Check 
                                            type="switch"
                                            name="NivelAcademico"
                                            id="NivelAcademico"
                                            label="Sí"
                                            value={nivelAcademico}
                                            onChange={(e) => {
                                                setNivelAcademico(e.target.checked)
                                            }}
                                            tabIndex={31}
                                        />
                                        <br />
                                        <h6>Plan de Estudios Posibilidad de Vinculación Laboral</h6>  
                                        <Form.Check 
                                            type="switch"
                                            name="PlanEstudioVinculacionLaboral"
                                            id="PlanEstudioVinculacionLaboral"
                                            label="Sí"
                                            value={planEstudioVinculacionLaboral}
                                            onChange={(e) => {
                                                setPlanEstudioVinculacionLaboral(e.target.checked)
                                            }}
                                            tabIndex={33}
                                        />
                                        <Input
                                            label='Otro'
                                            type='text'
                                            name='OtroAspecto'
                                            required={false}
                                            value={ OtroAspecto || ''}
                                            onChange={ handleChangeAspectos }
                                            tabIndex={35}
                                        />
                                        <br />
                                        <h6>Autorización y Refendación de Uso de Datos Personales</h6>  
                                        <Form.Check 
                                            type="switch"
                                            name="AutorizaDatos"
                                            id="AutorizaDatos"
                                            label="Acepto"
                                            value={autorizaDatosUser}
                                            onChange={ (e) => {
                                                setAutorizaDatosUser(e.target.checked)
                                            }}
                                            tabIndex={37}
                                        />
                                    </section>

                                    <section className='form-update__columnTwo'>
                                        <h6>Condiciones de Admisión</h6>  
                                        <Form.Check 
                                            type="switch"
                                            name="CondionesAdmision"
                                            id="CondionesAdmision"
                                            label="Sí"
                                            value={condionesAdmision}
                                            onChange={(e) => {
                                                setCondionesAdmision(e.target.checked)
                                            }}
                                            tabIndex={28}
                                        />
                                        <br />
                                        <h6>Horario</h6>  
                                        <Form.Check 
                                            type="switch"
                                            name="Horario"
                                            id="Horario"
                                            label="Sí"
                                            value={horario}
                                            onChange={(e) => {
                                                setHorario(e.target.checked)
                                            }}
                                            tabIndex={30}
                                        />
                                        <br />
                                        <h6>Nivel de Formación Ofrecido</h6>  
                                        <Form.Check 
                                            type="switch"
                                            name="NivelFormacionOfrecido"
                                            id="NivelFormacionOfrecido"
                                            label="Sí"
                                            value={nivelFormacionOfrecido}
                                            onChange={(e) => {
                                                setNivelFormacionOfrecido(e.target.checked)
                                            }}
                                            tabIndex={32}
                                        />
                                        <br />
                                        <h6>Ubicación Geográfica</h6>  
                                        <Form.Check 
                                            type="switch"
                                            name="UbicacionGeografica"
                                            id="UbicacionGeografica"
                                            label="Sí"
                                            value={ubicacionGeografica}
                                            onChange={(e) => {
                                                setUbicacionGeografica(e.target.checked)
                                            }}
                                            tabIndex={34}
                                        />
                                        <Select
                                            id="IdConocioCEA"
                                            name="IdConocioCEA"
                                            onChange={ handleChange }
                                            value={ IdConocioCEA || null }
                                            label="Medio por el Cuál Conoció al CEA *"
                                            required={true}
                                            optionValue="IdConocioCEA"
                                            optionName="Descripcion"
                                            options={[
                                                {"IdConocioCEA": "1", "Descripcion": "Familiar"},
                                                {"IdConocioCEA": "2", "Descripcion": "Internet"},
                                                {"IdConocioCEA": "3", "Descripcion": "Feria"},
                                                {"IdConocioCEA": "4", "Descripcion": "Prensa"},
                                                {"IdConocioCEA": "5", "Descripcion": "Radio"},
                                                {"IdConocioCEA": "6", "Descripcion": "Redes Sociales"},
                                                {"IdConocioCEA": "7", "Descripcion": "Otro"}
                                            ]}
                                            tabIndex={36}
                                        />
                                    </section>
                                </section>
                                
                                <div style={{textAlign:"right",paddingTop:15, marginRight: 65, marginBottom: 40}}>
                                    <Button 
                                        type='send'
                                        loading={ loading }
                                    >
                                        Actualizar
                                    </Button>
                                </div>
                            </div>
                            
                        </form>
                    </Tab>
                </Tabs>

                <Modal 
                    show={modal}
                    onHide={()=>setModal(false)}
                >
                        <DynamicForm 
                            title={"Agregar Educación Superior"}
                            justDynamicForm = {{ flag: true, idForm: "131" }}
                            isCreate={true}
                            endPointSubmit={"educacionSupUsuario"}
                            extraData={
                                {
                                    IdUsuario: ID
                                }
                            }
                            accionMysql={() => setModal(false)}
                        />
                </Modal>
                
                <Modal 
                    show={modalEducacionenCurso}
                    onHide={()=>setModalEducacionenCurso(false)}
                >
                        <DynamicForm 
                            title={"Agregar Educación en Curso"}
                            justDynamicForm = {{ flag: true, idForm: "160" }}
                            isCreate={true}
                            endPointSubmit={"EducacionenCurso"}
                            extraData={
                                {
                                    IdUsuario: ID
                                }
                            }
                            accionMysql={() => setModalEducacionenCurso(false)}
                        />
                </Modal>
                
                <Modal 
                    show={modalOtrosEstudios}
                    onHide={()=>setModalOtrosEstudios(false)}
                >
                        <DynamicForm 
                            title={"Agregar Educación en Curso"}
                            justDynamicForm = {{ flag: true, idForm: "158" }}
                            isCreate={true}
                            endPointSubmit={"OtraEducacion"}
                            extraData={
                                {
                                    IdUsuario: ID
                                }
                            }
                            accionMysql={() => setModalOtrosEstudios(false)}
                        />
                </Modal>
                
                <Modal 
                    show={modalEstudioAmbitoAeronautico}
                    onHide={()=>setModalEstudioAmbitoAeronautico(false)}
                >
                        <DynamicForm 
                            title={"Agregar Educación en el Ámbito Aeronáutico"}
                            justDynamicForm = {{ flag: true, idForm: "159" }}
                            isCreate={true}
                            endPointSubmit={"EducaciondeAmbitoAeronautico"}
                            extraData={
                                {
                                    IdUsuario: ID
                                }
                            }
                            accionMysql={() => setModalEstudioAmbitoAeronautico(false)}
                        />
                </Modal>
                
                <Modal 
                    show={modalAsociacionRedAcademica}
                    onHide={()=>setModalAsociacionRedAcademica(false)}
                >
                        <DynamicForm 
                            title={"Agregar Asociaciones o Redes Académicas"}
                            justDynamicForm = {{ flag: true, idForm: "161" }}
                            isCreate={true}
                            endPointSubmit={"asociaciones_redAcademica"}
                            extraData={
                                {
                                    IdUsuario: ID
                                }
                            }
                            accionMysql={() => setModalAsociacionRedAcademica(false)}
                        />
                </Modal>
                
                <Modal 
                    show={modalLaboral}
                    onHide={()=>setModalLaboral(false)}
                >
                    <ModalHeader closeButton={true}>
                        <ModalTitle>
                            <h4> Agregar Experiencia</h4>
                        </ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <form 
                        onSubmit={ addExperiencia }
                        >
                        <Col>
                            <Row>
                                <Input
                                    label='Nombre de la Empresa *'
                                    required={true}
                                    type='text'
                                    name='EmpresaLabora'
                                    value={ EmpresaLabora || ''}
                                    onChange={ handleChangeInfoLaboral }
                                />
                                <Input
                                    label='Cargo *'
                                    type='text'
                                    name='CargoLabora'
                                    required={true}
                                    value={ CargoLabora || ''}
                                    onChange={ handleChangeInfoLaboral }
                                />
                                <Input
                                    label='Actividad de la Empresa  *'
                                    type='text'
                                    name='ActividadEmpresa'
                                    required={true}
                                    value={ ActividadEmpresa || ''}
                                    onChange={ handleChangeInfoLaboral }
                                />
                                <Select
                                    id="PaisLabora"
                                    name="PaisLabora"
                                    onChange={ handleChangeInfoLaboral }
                                    value={ PaisLabora || null }
                                    label="País *"
                                    required={true}
                                    optionValue="Idpaises"
                                    optionName="Descripcion"
                                    urlOptionsService={'paises'}
                                />
                                {PaisLabora == 1 &&
                                <Select
                                    id="DeptoLabora"
                                    name="DeptoLabora"
                                    onChange={ handleChangeInfoLaboral }
                                    value={ DeptoLabora || null }
                                    label="Departamento *"
                                    required={false}
                                    optionValue="IdDepartamento"
                                    optionName="Descripcion"
                                    urlOptionsService={`departamentos?IdPaises=${PaisLabora}`}
                                />
                                }
                                {PaisLabora == 1 &&
                                <Select
                                    id="CiudadEmpresa"
                                    name="CiudadEmpresa"
                                    onChange={ handleChangeInfoLaboral }
                                    value={ CiudadEmpresa || null }
                                    label="Ciudad *"
                                    required={false}
                                    optionValue="Idciudad"
                                    optionName="Descripcion"
                                    urlOptionsService={`ciudad?IdDepartamento=${DeptoLabora}`}
                                />
                                }
                                <Input
                                    label='Dirección *'
                                    required={true}
                                    type='text'
                                    name='DireccionLabora'
                                    value={ DireccionLabora || ''}
                                    onChange={ handleChangeInfoLaboral }
                                />
                                <Input
                                    label='Teléfono Fijo *'
                                    type='number'
                                    name='TelefonoLabora'
                                    required={true}
                                    value={ TelefonoLabora || ''}
                                    onChange={ handleChangeInfoLaboral }
                                />
                                <Input
                                    label='Extensión *'
                                    type='number'
                                    name='ExtensionTelLabora'
                                    required={true}
                                    value={ ExtensionTelLabora || ''}
                                    onChange={ handleChangeInfoLaboral }
                                />
                                <Input
                                    label='Correo Electrónico Laboral *'
                                    type='email'
                                    name='EmailLabora'
                                    required={true}
                                    value={ EmailLabora || '' }
                                    onChange={ handleChangeInfoLaboral }
                                />
                                <InputFile
                                    label={"Soporte"}
                                    name={"Archivo"}
                                    accept={".pdf"}
                                    onChange={handleChangeInfoLaboral}
                                />
                                <div>
                                    <br />
                                    <label htmlFor="TrabajaActualmente"> <b> ¿ Actualmente Labora Aquí ?</b></label>
                                    <Form.Check 
                                        type="switch"
                                        name="TrabajaActualmente"
                                        id="TrabajaActualmente"
                                        label="Sí"
                                        checked = {actualmenteTrabaja}
                                        value={actualmenteTrabaja}
                                        onChange={ (e) => {
                                            setActualmenteTrabaja(e.target.checked)
                                        }}
                                        tabIndex={1}
                                    />
                                    <br />
                                </div>
                                
                            </Row>
                            <Row>
                                <hr />
                                <div style={{textAlign : "right"}}>
                                    <Button type='send'>
                                        Guardar
                                    </Button>
                                </div>
                            </Row>
                        </Col>
                        
                        </form>
                    </ModalBody>
                        
                </Modal>
                <Modal 
                    show={modal2}
                    onHide={()=>setModal2()}
                    fullscreen={true}
                >
                    <ModalHeader closeButton={true}>
                        <h2>Educación Superior</h2>           
                    </ModalHeader>
                        <DynamicTable
                            titleColumn={["No", "Nivel de Formación", "Nombre Institución", "Título Obtenido", "Año Finalización"]}
                            attributes={[
                                "IdNivelEducacionSuperior", "institucionSuperior", "TituloSuperiorObtenido", "FechaTerminacionProgSuperior"
                            ]}
                            endPoint={`educacionSupUsuario?IdUsuario=${ID}`}
                            onActions={false}
                        />
                </Modal>
                <Modal 
                    show={modalLaboral2}
                    onHide={()=>setModalLaboral2()}
                    fullscreen={true}
                >
                    <ModalHeader closeButton={true}>
                        <h2>Experiencia Laboral</h2>           
                    </ModalHeader>
                        <DynamicTable
                            titleColumn={[
                                "No", "Nombre de la Empresa", "Cargo", "Actividad de la Empresa", 
                                "Teléfono Fijo", "Extensión", "E-mail Laboral"
                            ]}
                            attributes={[
                                "EmpresaLabora", "CargoLabora", "ActividadEmpresa", 
                                "TelefonoLabora", "ExtensionTelLabora", "EmailLabora"
                            ]}
                            endPoint={endpointLaboral}
                            onActions={false}
                        />
                </Modal>
                <Modal 
                    show={modalEducacionenCurso2}
                    onHide={()=>setModalEducacionenCurso2(false)}
                    fullscreen={true}
                >
                    <ModalHeader closeButton={true}>
                        <h2>Información Académica en Curso</h2>           
                    </ModalHeader>
                        <DynamicTable
                            titleColumn={[
                                "No", "Nivel", "Institución", "Titulo Obtenido", "Semestre Actual"
                            ]}
                            attributes={[
                                "NivelEducacion", "InstitucionenCurso",  "TituloenCurso", "SemestreenCurso"
                            ]}
                            endPoint={'EducacionenCurso'}
                            onActions={false}
                        />
                </Modal>
                
                <Modal 
                    show={modalOtrosEstudios2}
                    onHide={()=>setModalOtrosEstudios2(false)}
                    fullscreen={true}
                >
                    <ModalHeader closeButton={true}>
                        <h2>Información Académica Otros Estudios</h2>           
                    </ModalHeader>
                        <DynamicTable
                            titleColumn={[
                                "No", "Nombre Diplomado - Curso", "Institución", "Fecha", "Duración en Horas"
                            ]}
                            attributes={[
                                "CursoOtraEducacion", "InstitucionOtraEducacion",  "PeriodoOtraEducacion", "DuracionHorasOtraEducacion"
                            ]}
                            endPoint={'OtraEducacion'}
                            onActions={false}
                        />
                </Modal>
                
                <Modal 
                    show={modalEstudioAmbitoAeronautico2}
                    onHide={()=>setModalEstudioAmbitoAeronautico2(false)}
                    fullscreen={true}
                >
                    <ModalHeader closeButton={true}>
                        <h2>Información Académica en el Ámbito Aeronáutico</h2>           
                    </ModalHeader>
                        <DynamicTable
                            titleColumn={[
                                "No", "Nombre del Curso", "Institución", "Año", "Duración"
                            ]}
                            attributes={[
                                "CursoAmbitoAeronautico", "InstitucionAmbitoAeronautico",  "PeriodoAmbitoAeronautico", "DuracionAmbitoAeronautico",
                            ]}
                            endPoint={'EducaciondeAmbitoAeronautico'}
                            onActions={false}
                        />
                </Modal>
                
                <Modal 
                    show={modalAsociacionRedAcademica2}
                    onHide={()=>setModalAsociacionRedAcademica2(false)}
                    fullscreen={true}
                >
                    <ModalHeader closeButton={true}>
                        <h2>Información Académica en el Ámbito Aeronáutico</h2>           
                    </ModalHeader>
                        <DynamicTable
                            titleColumn={[
                                "No", "Nombre de la Red", "Fecha de Vinculación", "Título del Proyecto de Investigación"
                            ]}
                            attributes={[
                                "NombreRed", "FechaVinculacion",  "TituloProyectoInvestigacion"
                            ]}
                            endPoint={'asociaciones_redAcademica'}
                            onActions={false}
                        />
                </Modal>
        </div>
    </>
    )
}
