import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom"
import { DynamicTable } from "../../components/DynamicForm/dynamicTable/DynamicTable"
import { LocalStorage } from "../../services"
import { BASE_URL } from "../../services/api/config"; 

function CursosFinalizados () {

  const api = new LocalStorage();
  const idUsuario = api.getAsJsonItem("usuario")?.usuario?.IdUsuario || 'localstorage_empty'
  const dimension = api.getItem("dimension")
  const urlExportar = `${BASE_URL}/exportarCursoFinalizado/coordinador?IdUsuario=${idUsuario}`
 
  const urlProgramas = `coordinadoresAcademico/cursosFinalizados?IdUsuario=${idUsuario}`;
  const navigate = useNavigate();

  const onNavigate = (programa) => {
    navigate(`${programa.IdOferta}/asignaturas`,{state:{programa,idUsuario}}); //to ListadoAsignatura.jsx
  }
  return (
    <>
    <Card>
     <Card.Title>
      {dimension =='"true"' ?
        <>
          Periodos Finalizadas ES
        </>
        :
        <>
          Actividades Académicas Finalizadas
        </>
      }
        
      </Card.Title>
    </Card>
    <DynamicTable
      exportar={urlExportar}
      titleColumn={['N°','Nombre','Fecha Incio','Fecha Fin', 'Acción']}
      attributes={['Nombre','FechaInicioCurso','FechaFinCurso']}
      endPoint={urlProgramas}
      onDelete={false}
      onUpdate={false}
      labelOptionClick={`Ver asignaturas`}
      handleOptionClick={onNavigate}
      classNameOptionIcon={`bi bi-view-list`}
    ></DynamicTable>

    </>
  )
}
export { CursosFinalizados }