import React from 'react'
import { BASE_SERVIDOR } from '../../../services/api/config'
import { useLocation, useNavigate } from 'react-router-dom'
import { Card, Col, Row } from 'react-bootstrap'

export default function MenuPrograma() {
    // obtener el usuario
    const usuario=JSON.parse(localStorage.getItem("usuario")).usuario
    const {curso}=useLocation().state
    const navigate=useNavigate()
    
  return (
    <>
        <div style={{textAlign:"center",padding:"50px 0"}}>
            <Card.Title>{curso.Nombre}</Card.Title>
        </div>
        <div style={{maxWidth:800,margin:"0 auto",textAlign:"center"}}>
        <Row>
            <Col>
                <Card  
                    style={{padding:"30px 20px",cursor:"pointer"}}
                    onClick={()=>navigate("periodos_ofertados",{state:{IdCurso:curso?.IdCurso}})}
                >
                    Matricular Período
                </Card>
            </Col>
            <Col>
                <Card 
                    style={{padding:"30px 20px",cursor:"pointer"}}
                    onClick={()=>navigate("periodos_activos",{state:{IdCurso:curso?.IdCurso}})}
                >
                    Periodos Activos
                </Card>
            </Col>
            <Col>
                <Card 
                    style={{padding:"30px 20px",cursor:"pointer"}}
                    onClick={()=>navigate("periodos_finalizados",{state:{IdCurso:curso?.IdCurso}})}
                >
                    Periodos Finalizados
                </Card>
            </Col>
        </Row>
        <Row>
            {/* <Col>
                <Card 
                    style={{padding:"30px 20px",cursor:"pointer"}}
                    onClick={()=>navigate("cohortes",{state:{IdCurso:curso?.IdCurso}})}
                >
                    Cohortes 
                </Card>
            </Col> */}
            <Col>
                <Card 
                    style={{padding:"30px 20px",cursor:"pointer"}}
                    onClick={()=>navigate("semaforizacion",{state:{curso:curso,estudiante:usuario}})}
                >
                    Semaforización
                </Card>
            </Col>
            <Col>
                <Card 
                    style={{padding:"30px 20px",cursor:"pointer"}}
                    onClick={()=>{
                        window.open(`${BASE_SERVIDOR}/pdf/${curso.ArchivoMalla}`)
                    }}
                >
                    Malla del programa
                </Card>
            </Col>
        </Row>
        </div>
    </>
  )
}
