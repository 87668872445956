import { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Button } from 'react-bootstrap' 
import { RenderTagsSearch } from './RenderTagsSearch'
import Accordion from 'react-bootstrap/Accordion'
import { useEffect } from 'react'

function DynamicSearch({ onSearch, camposForm = null }){

    const camposFormSelector = useSelector((state) => state.form.fields)
    const [campos, setCampos] = useState(camposForm)
    const [fields, setFields] = useState({})
    useEffect(() => {
        console.log(camposForm)
        if (camposForm == null) {
            setCampos(camposFormSelector)
        }
    }, [camposForm, camposFormSelector])

    const onChangeHandler = (value, name) => {
        setFields({
            ...fields,
            [name]: value,
        })
    }
    const onLocalSearch = () => {
        const params = getParamsURLByFields(fields)
        onSearch(params)
    }
    const getParamsURLByFields = (fields) => {
        let params = '?'
        for (let element in fields) {
            params = params + `${element}=${fields[element]}&`
        }
        return params
    }

    const filtarCampos=(obj)=>{
        if(obj.render_busqueda==1){
            return true;
        }else{
            return false;
        }
    }

    return (
        <>
        {campos && campos.filter(filtarCampos).length>0 &&
            <div style={{border: 'none', padding: '1rem', margin: '1rem 0', }}>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            Ver Filtros <i className="bi bi-filter"></i>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className="d-flex" >
                                {!!campos &&
                                    campos.map((input, index) => (
                                        <RenderTagsSearch
                                            key={index}
                                            input={input}
                                            index={index}
                                            fields={fields}
                                            onChangeHandler={onChangeHandler}
                                        />
                                    ))}
                            </div>
                            <div className="d-flex justify-content-end" style={{padding:10}}>
                                <Button onClick={onLocalSearch} className={'btn btn-secondary'}>
                                    Buscar <i className="bi bi-search"></i>
                                </Button>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        }
        </>
    )
}

DynamicSearch.propTypes = {
    onSearch: PropTypes.func,
    camposForm: PropTypes.arrayOf(
        PropTypes.exact({
            id: PropTypes.string,
            id_formulario: PropTypes.string,
            requerido: PropTypes.oneOf(['0', '1']),
            tipo: PropTypes.oneOf([
                'select',
                'text',
                'textarea',
                'email',
                'number',
                'date',
                'file',
                'checkbox',
                'busqueda',
                'title',
                'subtitle',
                'radio',
            ]),
            label: PropTypes.string,
            placeholder: PropTypes.string,
            name: PropTypes.string,
            identificador: PropTypes.string,
            opciones: PropTypes.string,
            optionValue: PropTypes.string,
            optionName: PropTypes.string,
            render_form: PropTypes.oneOf(['0', '1']),
            render_tabla: PropTypes.oneOf(['0', '1']),
            render_busqueda: PropTypes.oneOf(['0', '1']),
            fecha: PropTypes.string,
            orden: PropTypes.string,
            columna_numero: PropTypes.string,
        })
    ),
}
export { DynamicSearch }
