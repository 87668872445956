import React, { useState, useEffect } from 'react'

import { FormService } from '../../../services/api/formService'
import { useSubmitCreate } from '../../DynamicForm/hooks/useSubmitCreate'
import { useFetchGet } from '../../../hooks/useFetchGet'
import { BASE_URL } from '../../../services/api/config'
import { ApiPasarela } from '../../../services/api/ApiPasarela'
import { MatriculaAspirante } from './MatriculaAspirante'

const ESTADO_DOCUMENTO = {
    NO_SE_HA_CARGADO_NADA:'0',
    APROBADO:'1',
    RECHAZADO:'2'
}

const MatriculaAspiranteContainer = ({
    item, 
    offer,
    aspirante
}) => {
    const URL = `requisitosAspirante?IdOferta=${item.IdOferta}&IdAspirante=`
    const [loading, setLoading] = useState(false)
    const [loadingFull, setLoadingFull] = useState(false)
    const [error, setError] = useState(null)
    const [documents, setDocuments] = useState([])
    const [respondio, setRespondio] = useState(false)
    const [registerPay, loadingRegister, errorRegister] = useFetchGet(`pagos/${item?.PagoMatricula}`)
    const [fetchDataCreate, statusCreate] = useSubmitCreate(
        'requisitosAspirante', 
        null, 
        null,
        '1'
    )
    const [urlBill,_] = useState(
        `${BASE_URL}/estudiante/descargarRecibo?IdOFerta=${item.IdOferta}&IdAspirante=${item.IdAspirante}&Concepto=${'matricula'}`
    )
    const [isloadingPay, setIsLoadingPay] = useState(false)
    const fetchDataDocuments = async () => {
        setLoadingFull(true)
        try {
            const formService = new FormService()
            const response = await formService.getAll(`${URL}${item?.IdAspirante}&IdTipoRequisito=2`)
            setLoadingFull(false)
            setDocuments(response)
        } catch (error) {
            setLoadingFull(false)
            setError(error)
        }
    }
    const consultarRespondio=async()=>{
        const api = new FormService()
        const respuesta =await api.getAll(`estudiante/respondio?IdOferta=${item.IdOferta}&IdAspirante=${aspirante.IdAspirante}&IdUsuario=${aspirante.IdUsuario}`)
        if(respuesta.respuesta=="exito"){
          setRespondio(false)
        }else{
          setRespondio(true)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchDataDocuments()
            await consultarRespondio()
        }
        fetchData()
    }, [loading])

    const handleChange = (e) => {}

    const created = async (formData, document) => {
        formData.set('IdAspirante', item?.IdAspirante)
        formData.set('IdRequisito', document?.IdRequisito)
        await fetchDataCreate(formData)
    }

    const update = async (formData, document) => {
        const formService = new FormService()
        formData.set('EstadoRequisito', "0")
        let dataRaw = Object.fromEntries(formData.entries());
        const id = document?.requisito?.IdRequisitoAspirante
        await formService.createWithFormData(
            `requisitosAspirante/${id}?_method=PUT`, 
            dataRaw
        )
    }

    const onPay = async () => {
        setIsLoadingPay(false)
        setIsLoadingPay(true)
        ApiPasarela(
            registerPay.Valor,
            registerPay.Descripcion+', '+offer?.Nombre ,
            'email@email.com'
        )
        .then(res => { 
            if(res?.success) {
                setIsLoadingPay(false)
                const newWindow = window.open(
                    res?.data?.routeLink, 
                    '_blank', 
                    'noopener,noreferrer'
                )
                if (newWindow) newWindow.opener = null
            }
        })
    } 

    const onHandleSubmit = async (e, document) => {
        e.preventDefault()
        try {
            setLoading(true)
            const formData = new FormData(e.currentTarget)
            if (
                document?.requisito?.EstadoRequisito == ESTADO_DOCUMENTO.NO_SE_HA_CARGADO_NADA ||
                document?.requisito?.EstadoRequisito == ESTADO_DOCUMENTO.RECHAZADO
            ) await update(formData, document)
            else await created(formData, document)
            setLoading(false)
        } catch(err) {
            setError(err)
        }
    }

    return (
        <MatriculaAspirante 
            registerPay={ registerPay }
            isloadingPay={ isloadingPay }
            loadingFull={ loadingFull }
            onPay={ onPay }
            urlBill={ urlBill }
            documents={ documents }
            onHandleSubmit={ onHandleSubmit }
            error={ error }
            handleChange={ handleChange }
            loading={ loading }
            oferta={item}
            aspirante={aspirante}
            consultarRespondio={consultarRespondio}
            respondio={respondio}
        />
    )
}

export { MatriculaAspiranteContainer }