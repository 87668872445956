import { SubTitle } from '../../../shared/components/subtitle/SubTitle'
import { Checkbox } from '../../componentsForm/checkbox/checkbox'
import { TextArea } from '../../componentsForm/textarea/textarea'
import { Input } from '../../input/input'
import { InputFile } from '../../inputFile/inputFile'
import { Select } from '../../select/Select'
import { SelectDataList } from '../../SelectDataList/SelectDataList'

const RenderTagsSearch = ({ input, index, fields, onChangeHandler }) => {
    switch (input.tipo) {
        case 'select':
            // console.log('input.optionName', input)
            return (
                input.render_busqueda == '1' && (
                    <Select
                        key={index}
                        name={input.name}
                        value={fields?.[input.name] || ''}
                        label={input.label}
                        onChange={onChangeHandler}
                        optionValue={input.optionValue}
                        optionName={input.optionName}
                        required={input.requerido == '1' ? true : false}
                        urlOptionsService={input.opciones}
                    />
                )
            )
        case 'text':
            return (
                input.render_busqueda == '1' && (
                    <Input
                        key={index}
                        label={input.label}
                        value={fields?.[input.name] || ''}
                        name={input.name}
                        type={input.tipo}
                        onChange={onChangeHandler}
                        placeholder={input.placeholder || ''}
                        required={false}
                    />
                )
            )
        case 'textarea':
            return (
                input.render_busqueda == '1' && (
                    <TextArea
                        label={input.label}
                        name={input.name}
                        type={input.tipo}
                        value={fields?.[input.name] || ''}
                        onChange={onChangeHandler}
                        placeholder={input.placeholder || ''}
                        required={false}
                    />
                )
            )
        case 'email':
            return (
                input.render_busqueda == '1' && (
                    <Input
                        label={input.label}
                        name={input.name}
                        value={fields?.[input.name] || ''}
                        type={input.tipo}
                        onChange={onChangeHandler}
                        placeholder={input.placeholder || ''}
                        required={false}
                    />
                )
            )
        case 'number':
            return (
                input.render_busqueda == '1' && (
                    <Input
                        label={input.label}
                        name={input.name}
                        value={fields?.[input.name] || ''}
                        type={input.tipo}
                        onChange={onChangeHandler}
                        placeholder={input.placeholder || ''}
                        required={false}
                    />
                )
            )
        case 'date':
            return (
                input.render_busqueda == '1' && (
                    <Input
                        label={input.label}
                        name={input.name}
                        value={fields?.[input.name] || ''}
                        onChange={onChangeHandler}
                        type={input.tipo}
                        required={false}
                    />
                )
            )
        case 'file':
            return (
                input.render_busqueda == '1' && (
                    <InputFile
                        label={input.label}
                        name={input.name}
                        accept="image/*,.pdf"
                        onChange={onChangeHandler}
                    />
                )
            )
        case 'checkbox':
            return (
                input.render_busqueda == '1' && (
                    <Checkbox
                        title={input.label}
                        onChange={(value, name) => {
                            let newValue = value == true ? 'true' : '0'
                            onChangeHandler(newValue, name)
                        }}
                        name={input.name}
                        checked={fields?.[input.name] == 'true' ? true : false}
                        value={fields?.[input.name] == 'true' ? true : false}
                    >
                        {input.label}
                    </Checkbox>
                )
            )
        case 'busqueda':
            return (
                input.render_busqueda == '1' && (
                    <SelectDataList
                        label={input.label}
                        placeholder={input.placeholder || ''}
                        optionValue="IdAsignatura"
                        // optionName={ input.optionName }
                        optionName={'Descripcion'}
                        onChangeHandler={onChangeHandler} /// TRAER DE BASE DE DATOS
                        name={input.name}
                        endPoinLastUrl = {input.opciones}
                        // endPoinLastUrl={'asignatura'}
                    />
                )
            )
        case 'title':
            return null
        case 'subtitle':
            return null
        case 'radio':
            return null
        default:
            return (
                <div>
                    Dynamic Input undefined
                    <b style={{ color: 'red' }}>{input.tipo}</b>
                </div>
            )
    }
}

export { RenderTagsSearch }
