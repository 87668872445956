
import React from 'react'
import { Route, Routes } from 'react-router-dom'
// import CursosAprobados from './CursosAprobados'
// import SolicitudGrado from './SolicitudGrado'

export default function AutoservicioGrado() {
  return (
    <Routes>
        {/* <Route path='solicitud_de_grados' element={<CursosAprobados/>}/>
        <Route path='solicitud_de_grados/solicitar' element={<SolicitudGrado/>} /> */}
    </Routes>
  )
}
