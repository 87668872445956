import { Card } from "../../../components/card/Card"
import { DynamicTable } from "../../../components/DynamicForm/dynamicTable/DynamicTable"
import { DynamicSearch } from "../../../components/DynamicForm/DynamicSearch/DynamicSearch"
import { useNavigate } from "react-router-dom"
import { BASE_URL } from "../../../services/api/config"
import { useState } from "react"
import { useEffect } from "react"
import { FormService } from "../../../services/api/formService"

const OfertasExternas=()=>{
    
    const navigate = useNavigate()
    const api = new FormService()
    const [exportar, setExportar] = useState(`${BASE_URL}/exportar/actividadesExternas`)
    const [endPoint, setEndPoint] = useState('ofertasExternas')
    const [camposForm, setCamposForm] = useState()

    const onSearch = (urlsParams) => {
        setEndPoint(`ofertasExternas${urlsParams}`)
        setExportar(exportar+urlsParams)
    }

    const fetchCamposFormForFilter = ()=>{
        api.getAll(`xcampos?id_formulario=128`)
        .then((res)=>{
            setCamposForm(res)
        })
    }

    const onNavigateDetails = (e) => {
        navigate(`estudiantesOfertaExterna/${e.IdOfertaExterna}`)
    }

    useEffect(() => {
        fetchCamposFormForFilter()
    }, []);

    return(
        <>
        <Card>
            <h2>Ofertas - Actividades Tercerizadas</h2>
        </Card>
        {camposForm &&
        <Card>
            <DynamicSearch onSearch={onSearch} camposForm={camposForm}/>
        </Card>
        }
        <Card>
            <DynamicTable 
                endPoint={endPoint}
                titleColumn={["No", "Nombre Oferta - Actividad Tercerizada", "Entidad", "Fecha Inicio", "Fecha Fin", "Acción"]}
                attributes={["NOMBRE_CURSO", "NOMBRE_ENTIDAD", "FechaInicio", "FechaFinal"]}
                labelOptionClick="Listar Estudiantes"
                handleOptionClick={ onNavigateDetails}
                endPointSubmit={false}
                exportar={exportar}
                onUpdate={false}               
                onDelete={false}
            />
        </Card>
        </>
    )
}

export {OfertasExternas}