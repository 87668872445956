import { Route, Routes } from "react-router-dom"
import { DynamicFormContainer } from "../../components/DynamicForm/DynamicFormContainer"
import { Eventos } from "./eventos/Eventos"
import { CalendarioAcademico } from "./CalendarioAcademico"
import { EventosCalendario } from "./EventosCalendario"
import FormCalendario from "./FormCalendario"


const Calendario=()=>{
        return(
            <Routes>
                {/* <Route path="periodos" element={<DynamicFormContainer idForm="41"/>} />
                <Route path="eventos" element={<Eventos/>}/> */}
                {/* <Route path="calendario_academico" element={<CalendarioAcademico/>}/> */}
                {/* <Route path="calendario_academico/:calendario/sesiones" element={<EventosCalendario/>} /> */}
                {/* <Route path="*" element={<DynamicFormContainer/>} /> */}
                <Route path="*" element={<FormCalendario/>} />
            </Routes>
        )
}

export{Calendario}