import { useLocation } from "react-router-dom"
import { DynamicForm } from "../../../components/DynamicForm/DynamicForm"
import { Card } from "react-bootstrap"


function Coevaluacion (props) {
  const {state:{evaluacion}} = useLocation()
  console.log(evaluacion)

  const retroceder=()=>{
    setTimeout(() => { 
      window.history.go(-1)
    }, 3000);
    
  }
  return (
    <>
    <Card>
      <Card.Title>Docente: {evaluacion?.Nombre}</Card.Title>
      <DynamicForm
        parentField = { null}
        ventanaFormName={evaluacion.nombre }
        titleForm={evaluacion.nombre }
        extraData={{IdGestionInstrumento:evaluacion.IdGestionInstrumento}}
        endPointSubmit={'instrumento'}
        // keySubmit={'k'}
        isContentTypeFormData={'0'}
        isCreate={true}
        justDynamicForm={{flag:true,idForm:evaluacion?.IdForm}}
        accionMysql={()=>retroceder()}
        retroceder={true}
      ></DynamicForm>

    </Card>
    </>
  )
}

export { Coevaluacion }