import React from 'react'
import { DynamicFormContainer } from '../../components/DynamicForm/DynamicFormContainer'
import { useNavigate } from 'react-router-dom'

export default function Funcionarios() {
  const navigate = useNavigate()  
  return (
    <DynamicFormContainer 
      idForm="113"
      labelOptionClick={"Ver más"}
      handleOptionClick={(e)=>navigate(`${e.IdFuncionario}`,{state:{funcionario:e}})}
      // labelOptionClick={"Funciones"}
      // handleOptionClick={(e)=>navigate(`${e.IdFuncionario}/funciones`,{state:{funcionario:e}})}
      // labelOptionClick2={"Estudios"}
      // handleOptionClick2={(e)=>navigate(`${e.IdFuncionario}/estudios`,{state:{funcionario:e}})}
      
    />
  )
}
