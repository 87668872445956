import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { DynamicSearch } from '../../../../components/DynamicForm/DynamicSearch/DynamicSearch';
import { DynamicTable } from '../../../../components/DynamicForm/dynamicTable/DynamicTable';
import { FormService } from '../../../../services/api/formService';

const Cursos160=()=>{
    const urlCursos = 'instrumento/cursos160';
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const[campos,setCampos]=useState(null);
    const[endPoint,setEndPoint]=useState(urlCursos);
    const api = new FormService();
    
    useEffect(() => {
        const fecthData = async () => {
            fetchCamposFormForFilter();
            setLoading(true);
            setLoading(false);
        };
        fecthData();
    }, []);
    const onNavigateDetails = (programa)=>{
        console.log(programa)
        navigate(`${programa.IdCurso}`,{state:{programa}})
    }
    const fetchCamposFormForFilter = ()=>{   
          api.getAll(`xcampos?id_formulario=97`)
        .then((res)=>{
            setCampos(res)
        })
    }
    
    const buscarCursos=(e)=>{
    const nuevoEndPoint=`${urlCursos}${e}`
    setEndPoint(nuevoEndPoint)
    }

    return (
            <>
            <Card>
                <Card.Title>
                    Actividades Sup. 160 Hrs.
                </Card.Title>
            </Card>
            {campos&&
                <DynamicSearch  onSearch={buscarCursos} camposForm={campos} ></DynamicSearch>
            }
            <DynamicTable
                titleColumn={[
                    'Id',
                    'Nombre',
                    'Horas',
                    // 'Días restantes',
                    'Acciones',
                ]}
                endPoint={endPoint}
                attributes={[
                    'Nombre',
                    'horas',
                    // 'dias_restantes'
                ]} 
                labelOptionClick='Ver programa'
                handleOptionClick={onNavigateDetails}
                onDelete={false}
                onUpdate={false}
            />
        </>
    );
}


export{ Cursos160 };