import { Route, Routes } from "react-router-dom"
import { OfertasTerceros } from "../actividadesTerceros/ofertasTerceros/OfertasTerceros"
import { EstudianteOfertaTerceros } from "./estudiantesOfertaTerceros/EstudianteOfertaTerceros"


const ActividadesTerceros=()=>{

    return(
        <Routes>
            <Route path="ofertas_actividad_externa" element={<OfertasTerceros/>} />
            <Route path="ofertas_actividad_externa/estudiantesOfertaExterna/:id" element={<EstudianteOfertaTerceros/>} />
        </Routes>
    )

}

export {ActividadesTerceros}