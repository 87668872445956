import React from 'react'
import { Link } from 'react-router-dom'

import progress from '../../assets/gif/progress.gif'
import './button.css'

const Button = ({
    id,
    children, 
    type, //back = gray,send = blue, danger = red
    loading, 
    onClick,
    onBlur,
    className,
    isLink,
    isAnchor,
    to,
    state,
    dataBsToggle,
    ariaExpanded,
    hasButtonCss=true,
    buttonType='submit'
}) => {
    
    const classType = `${hasButtonCss?'button':''} ${type?type:''} ${className}` 

    if (isLink) {
        return (
            <Link 
                className={ classType }
                to={ to }
                state={ state }>
                { children }
            </Link>
        )
    }

    if (isAnchor) {
        return (
            <a 
                href={ to } 
                className={ classType } 
                target='_blank' 
                rel='noreferrer noopener'>
                { children }
            </a>
        )
    }

    return (
        <button
            id={ id }
            type={ buttonType }
            onClick={ onClick }
            disabled={ loading }
            className={ classType }
            onBlur={ onBlur }
            data-bs-toggle={ dataBsToggle }
            aria-expanded={ ariaExpanded}
        >
            { 
                loading 
                ? <div>Espere... <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></div> 
                : type === 'loading-progress' 
                ? <>
                    { children }
                    <img 
                        src={ progress }
                        alt='progreso'
                    />
                </>
                : children 
            }
        </button>
    )
}

export { Button }