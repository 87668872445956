import { Button, Card, Col, Form, Row } from "react-bootstrap"
import { Select } from "../../components/select/Select"
import { Title } from "../../shared/components/title/Title"

import { FormService } from "../../services/api/formService"
import { useState } from "react"
import { BASE_URL } from "../../services/api/config"
import { alerta } from "../../helpers/alertas"


const Reportes=()=>{
    const [perido,setPerido]=useState();
    const [perido2,setPerido2]=useState();
    const api= new FormService();

    const exportar=()=>{
        if(perido){
            window.open(`${BASE_URL}/exportar/caracterizacionPsicosocial?IdPerido=${perido}`,"_blank",)
        }else{
            alerta("Selecciona un periodo¡")
        }  
    }

    const exportar2=()=>{
        if(perido2){
            window.open(`${BASE_URL}/exportar/caracterizacionSocioeconomomica?IdPerido=${perido2}`,"_blank",)
        }else{
            alerta("Selecciona un periodo¡")
        }  
    }


    return(
        <>
            <Card>
                <Title>Reportes</Title>
            </Card>
            <Card>
                <Row>
                    <Col><p>Seleccione el periodo para exportar los resultados de caracterización psicosocial</p></Col>
                    <Col>
                        <Select
                            onChange={(e)=>setPerido(e)}
                            value={perido}
                            optionValue={"IdPeriodo"}
                            optionName={"Descripcion"}
                            urlOptionsService={'periodo'}
                        />
                    </Col>
                    <Col>
                        <Button 
                            onClick={()=>exportar()}
                        >
                            Exportar
                        </Button>
                    </Col>
                </Row>                
            </Card>
            <Card>
                <Row>
                    <Col><p>Seleccione el periodo para exportar los resultados de caracterización socioeconómica</p></Col>
                    <Col>
                        <Select
                            onChange={(e)=>setPerido2(e)}
                            value={perido2}
                            optionValue={"IdPeriodo"}
                            optionName={"Descripcion"}
                            urlOptionsService={'periodo'}
                        />
                    </Col>
                    <Col>
                        <Button 
                            onClick={()=>exportar2()}
                        >
                            Exportar
                        </Button>
                    </Col>
                </Row>                
            </Card>
        </>
    )
}



export {Reportes}