
import { Card, Tab, Tabs, Button, Col, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { AddButton } from "../../../components/componentsForm/addButton/addButton"
import { DynamicTable } from "../../../components/DynamicForm/dynamicTable/DynamicTable"
import { LocalStorage } from "../../../services"
import { BsArrowReturnRight,BsFillFilePdfFill,BsFillFileExcelFill } from 'react-icons/bs';
import DowloadFileText from '../../../components/DowloadFileText/DowloadFileText';
import { BASE_URL } from "../../../services/api/config";


function PeriodosActivos() {
  const ls = new LocalStorage()
  const IdUsuario = ls.getAsJsonItem('usuario').usuario?.IdUsuario
  const navigate = useNavigate()

  const handleOptionClick = (item) => {
    navigate(`${item.IdOferta}/detalle_programa`,{state:{programa:item}})
  }

  return (<>
  <Card>
      <Row>
        <Col>
          <Card.Title>Periodos Activos</Card.Title>
        </Col>
      </Row>
  </Card>
  <DynamicTable
    titleColumn={['N°','Nombre','Acción']}
    attributes = { ['Nombre']}
    onUpdate={false}
    onDelete={false}
    handleOptionClick= {handleOptionClick}
    labelOptionClick= {'Ver Programa'}
    classNameOptionIcon={'bi bi-book'}
    endPoint={`estudiante/cursosActivos?IdUsuario=${IdUsuario}`}
  ></DynamicTable>
  </>)
}

export { PeriodosActivos }