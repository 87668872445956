import { useState } from "react";
import { HeaderHome } from "../headerHome/HeaderHome";
import { Input } from "../input/input";
import { FormService } from "../../services/api/formService";
import { Button } from "../button/button";
import { Message } from "../Message/Message";
import { Card } from '../card/Card'
import { useLocation } from "react-router-dom";
import './RecoverPassword.css';

const RecoverPassword = () => {

    const Location=useLocation();
    const correo=Location.state.correo
    const [code, setCode] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState(null)
    const [passwordChangeResponse, setPasswordChangeResponse] = useState(null)
    const [password, setPassword] = useState({})
    const [badCode, setBadCode] = useState(false)
    const [same, setSame] = useState(false)
    const [IdUsuario,setIdUsuario]=useState(null)

    const validCode = async () => {
        const formService = new FormService();
        try {
            const data = {
                codigo: code,
                correo:correo
            }
            setLoading(true);
            if (code !== '') {
                const response = await formService.create('ValidarUsuario', data);
                setResponse(response.respuesta);
                setIdUsuario(response.IdUsuario)
                if (response.respuesta === 'fallo') {
                    setBadCode(true)
                }
            }
            setLoading(false);
        } catch (err) {
            setError(err);
            setLoading(false);
        }
    }

    const handleCode = (value) => {
        setBadCode(false);
        setCode(value);
    }

    const handleNewPassword = (value, name) => {
        setSame(false)
        setPassword({
            ...password,
            [name]: value
        })
    }

    const changePassword = async () => {
        const formService = new FormService();
        if (
            password.newPassword !== '' && (
                password.newPassword ===
                password.confirmPassword
            )
        ) {
            try {
                const data = {
                    codigo: code,
                    IdUsuario:IdUsuario,
                    pass: password.newPassword
                }
                setLoading(true);
                const response = await formService.create('RecuperarPass', data);
                setPasswordChangeResponse(response.respuesta)
                setLoading(false)
            } catch (err) {
                setError(err)
                setLoading(false)
            }
        } else {
            setSame(true)
        }
    }

    return (
        <>
            <HeaderHome />
            <main className="recover-password">
                <Card className='recover-password__card'>
                    {
                        response !== 'exito' && (
                            <>
                                <h3 className='mb-5'>
                                    Ingrese el Código Enviado a su Correo
                                </h3>
                                <Input 
                                    type='number'
                                    value={ code }
                                    onChange={ handleCode }
                                    placeholder='Ingresa el código'

                                />
                                {
                                    badCode && (
                                        <Message type='warning'>
                                            Código incorrecto o caducado
                                        </Message>
                                    )
                                }
                                <Button 
                                    type='send'
                                    onClick={ validCode }
                                    loading={ loading }
                                >
                                    Validar
                                </Button>
                            </>
                        )
                    }
                    {
                        (
                            response === 'exito' &&
                            passwordChangeResponse !== 'exito'
                        ) && (
                            <>
                                <h3 className='mb-5'>
                                    Restaurar Contraseña
                                </h3>
                                <Input 
                                    type='password'
                                    name='newPassword'
                                    value={ password.newPassword || '' }
                                    onChange={ handleNewPassword }
                                    placeholder='Nueva contraseña'
                                />
                                <Input 
                                    type='password'
                                    name='confirmPassword'
                                    value={ password.confirmPassword || '' }
                                    onChange={ handleNewPassword }
                                    placeholder='Confirmar contraseña'
                                />
                                {
                                    same && (
                                        <Message type='warning'>
                                            Las contraseñas no coinciden
                                        </Message>
                                    )
                                }
                                <Button 
                                    type='send'
                                    onClick={ changePassword }
                                    loading={ loading }
                                >
                                    Cambiar Contraseña
                                </Button>
                            </>
                        )
                        
                    }
                    {
                        passwordChangeResponse === 'exito' && (
                            <>
                                <h3 className='recover-password__success'>
                                    Contraseña Actualizada
                                </h3>
                                <Button 
                                    isLink={true} 
                                    to='/'
                                    type='link'
                                >
                                    Inicie sesión aquí
                                </Button>
                            </>
                        )
                    }
                    {
                        error && (
                            <Message type='error'>
                                { error.message }
                            </Message>
                        )
                    }
                </Card>
            </main>
        </>
    )
}

export { RecoverPassword };