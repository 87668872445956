import React from 'react'
import { Button } from '../button/button'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setItemSelected } from './FormSlice'

const RenderAction = ({ accion, item, index, onDeleteItem, onUpdateItem }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const formSnapshot = useSelector((state) => state.form.formSnapshot)
    console.log("accion-----")
    return (
        <>
            {accion.render == '1' &&
            (accion.nombre != 'Actualizar' ||
                accion.nombre != 'Eliminar' ||
                accion.nombre != 'actualizar' ||
                accion.nombre != 'eliminar') ? (
                <li>
                    <div key={index} className="dropdown-item">
                        <Button
                            className="m-0 p-0"
                            onClick={() => {
                                dispatch(setItemSelected(item))
                                return navigate(`${accion.endpoint}`, {
                                    state: {
                                        accion,
                                        item,
                                        formSnapshot,
                                    },
                                })
                            }}
                            // onClick={ () => onUpdateItem(item) }
                        >
                            <>
                                <i className="bi bi-pencil-square"></i>
                                <span className="m-3">{accion.nombre}</span>
                            </>
                        </Button>
                    </div>
                </li>
            ) : null}
            {accion.render == '1' &&
            (accion.nombre == 'Actualizar' ||
                accion.nombre == 'actualizar') ? (
                <li>
                    <div className="dropdown-item">
                        <Button
                            className="m-0 p-0"
                            onClick={() => onUpdateItem(item)}
                        >
                            <>
                                <i className="bi bi-pencil-square"></i>
                                <span className="m-3">{accion?.nombre}</span>
                            </>
                        </Button>
                    </div>
                </li>
            ) : null}
            {accion.render == '1' &&
            (accion.nombre == 'Eliminar' || accion.nombre == 'eliminar') ? (
                <li>
                    <li>
                        <hr className="dropdown-divider" />
                    </li>
                    <div className="dropdown-item d-flex justify-content-center">
                        <Button
                            type="danger"
                            onClick={() => onDeleteItem(item)}
                            className="m-0"
                        >
                            <>
                                <i className="bi bi-trash"></i>
                                <span className="m-2">{accion?.nombre}</span>
                            </>
                        </Button>
                    </div>
                </li>
            ) : null}
        </>
    )
}

export { RenderAction }
