import { Card } from "react-bootstrap";
import { DynamicTable } from "../../../components/DynamicForm/dynamicTable/DynamicTable";
import { DynamicFormContainer } from "../../../components/DynamicForm/DynamicFormContainer";
import { DynamicForm } from "../../../components/DynamicForm/DynamicForm";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row } from "react-bootstrap";
import { useState } from "react";
import { FormService } from "../../../services/api/formService";

export function MovilidadAdministrativos() {
    const [modalUpdate, setModalUpdate] = useState()
    const api = new FormService()
    

    return (
        <>
            <Card>
                <h2>
                    Movilidad de Administrativos
                </h2>
            </Card>
            <DynamicFormContainer idForm={181} showTable={false}/>
            <Card>
                <DynamicTable
                    titleColumn={["No", "Administrativo", "Institución de Procedencia", "Movilidad Hacia el:", "Días de Movilidad", "Valor Financiación Nacional", "Acción"]}
                    attributes={[
                        "Administrativo", "InstitucionExtranjera", "MovilidadHacia", "NumeroDiasMovilidad", 
                        "ValorFinanciacionNacional"
                    ]}
                    endPoint={"movilidad_administrativos"}
                    onDelete={true}
                    onUpdate={false}
                    labelOptionClick={"Actualizar"}
                    handleOptionClick={(e) => setModalUpdate(true)}
                />
            </Card>
            <Modal
                show={modalUpdate}
                onHide={()=>setModalUpdate(false)}
            >
                <DynamicForm
                    title={"Actualizar Movilidad Administrativos"}
                    justDynamicForm={{flag: true, idForm:'181'}}
                    endPointSubmit="movilidad_administrativos"
                    isCreate={false}
                    accionMysql={()=>setModalUpdate(false)}
                    onClickCancelBtn={true}
                />                
            </Modal>
        </>
    );

}