import { useLocation } from "react-router-dom";
import { Card } from "../../../../components/card/Card";
import { DynamicForm } from "../../../../components/DynamicForm/DynamicForm";
import { LocalStorage } from "../../../../services";
import { SubTitle } from "../../../../shared/components/subtitle/SubTitle";
import { Title } from "../../../../shared/components/title/Title";


function Evaluacion () {

  const storage= new LocalStorage();
  // const usuario=JSON.parse(storage.getItem(usuario))
  const {state:{evaluacion}} = useLocation()

  // console.log(usuario.usuario)
  const retroceder=()=>{
    setTimeout(() => { 
      window.history.go(-1)
    }, 3000);
    
  }

return (
  <>
  <Card>

    <SubTitle>Docente: {evaluacion.Nombre} </SubTitle>
        <SubTitle>Tipo de Reporte: 
        {evaluacion.Reporte==2
        ?<> IES</>
        :<> Continuada</>  
        }
        </SubTitle>
    
  </Card>
  <Card>

 <DynamicForm
  parentField = { null}
  ventanaFormName={evaluacion.nombre }
  extraData={{IdGestionInstrumento:evaluacion.IdGestionInstrumento}}
  endPointSubmit={'instrumento'}
  // keySubmit={'k'}
  isContentTypeFormData={'0'}
  isCreate={true}
  justDynamicForm={{flag:true,idForm:evaluacion?.IdForm}}
  accionMysql={()=>retroceder()}
  retroceder={true}

 ></DynamicForm>

  </Card>
  </>
  )
}

export { Evaluacion }