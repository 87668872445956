import React, { useEffect } from 'react'
import { useState } from 'react'
import { Button, Card, Col, Dropdown, DropdownButton, Modal, Row, Table } from 'react-bootstrap'
import { Select } from '../../components/select/Select';
import { SelectDataList } from '../../components/SelectDataList/SelectDataList';
import { alerta } from '../../helpers/alertas';
import { BASE_URL } from '../../services/api/config';
import { FormService } from '../../services/api/formService';

export default function Reconocimientos() {
    const api= new FormService();
    const [modal,setModal]=useState();
    const [IdUsuario,setIdUsuario]=useState(null);
    const [IdOferta,setIdOferta]=useState(null);
    const [IdTipoReconocimiento,setIdTipoReconocimiento]=useState(null);
    const [reconocimientos,setReconocimientos]=useState([]);
    const [Item,setItem]=useState();
    const [modalEliminar,setModalEliminar]=useState();

    const urlReconocimiento=`${BASE_URL}/educacionIES/descargarReconocimiento`

    useEffect(()=>{
        (async()=>{
            consultarReconocimientos()
        })()

    },[])

    const crearReconociminento=async()=>{
        const data={
            "IdUsuario":IdUsuario,
            "IdOferta":IdOferta,
            "IdTipoReconocimiento":IdTipoReconocimiento
        }
        const respuesta=await api.create("educacionIES/crearReconocimiento",data)
        if(respuesta.respuesta=="exito"){
            alerta(respuesta.mensaje)
            consultarReconocimientos();
            setModal()
        }else{
            alerta("Fallo desconocido")
        }   
    }

    const consultarReconocimientos=async()=>{
        const reconocimientos=await api.getAll("educacionIES/consultarReconocimientos");
        setReconocimientos(reconocimientos)
    }

    const eliminar=async()=>{
        const data={
            "IdReconocimiento":Item.IdReconocimiento,
        }
        const respuesta=await api.create("educacionIES/eliminarReconocimiento",data)
        if(respuesta.respuesta="exito"){
            alerta(respuesta.mensaje)
            setModalEliminar()
            consultarReconocimientos()
        }else{
            alerta("Fallo desconocido")
        }
    }

  return (
    <>
        <Card>
            <Row>
                <Col>
                    <Card.Title>Reconocimientos</Card.Title>
                </Col>
                <Col style={{textAlign:"right"}}>
                    <Button
                        onClick={()=>setModal(true)}
                    >
                        Crear Reconocimiento
                    </Button>
                </Col>
            </Row>
        </Card>
        <Table>

            <thead>
                <tr>
                    <th>Nombre</th>
                    <th>Curso</th>
                    <th>Reconocimiento</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                {reconocimientos.map((reconocimiento,key)=>
                    <tr key={key}>
                        <td>{reconocimiento.Nombre}</td>
                        <td>{reconocimiento.Curso}</td>
                        <td>{reconocimiento.TipoReconocimiento}</td>
                        <td>
                            <DropdownButton>
                                <Dropdown.Item
                                    href={`${urlReconocimiento}?IdReconocimiento=${reconocimiento.IdReconocimiento}`}
                                    target="_blank"
                                >
                                    Descargar
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={()=>{
                                        setModalEliminar(true)
                                        setItem(reconocimiento)
                                    }}
                                >
                                    Eliminar
                                </Dropdown.Item>
                            </DropdownButton>
                            
                        </td>
                    </tr>
                )}
                
            </tbody>

        </Table>

        <Modal 
            show={modal}
            onHide={()=>setModal()}
        >
            <Modal.Header closeButton>
                <Modal.Title>Crear reconocimiento</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SelectDataList
                        label={"Usuario"}
                        placeholder={""}
                        optionValue={"IdUsuario"}
                        optionName={"NombreUsuario"}
                        onChangeHandler={(e)=>setIdUsuario(e)}
                        name={"Idusuario"}
                        endPoinLastUrl={"usuarios/completos2"}
                        required={true}
                    />

                {IdUsuario&&
                    <Select
                        label={`Curso finalizados`}
                        value={IdOferta}
                        optionName={`Nombre`}
                        optionValue={`IdOferta`}
                        onChange={(e)=>setIdOferta(e)}
                        urlOptionsService={`educacionIES/cursosFinalizados?IdUsuario=${IdUsuario}`}
                    />
                }
                {IdOferta&&
                    <Select
                        label={`Reconocimiento`}
                        optionName={`Nombre`}
                        optionValue={`IdTipoReconocimiento`}
                        value={IdTipoReconocimiento}
                        onChange={(e)=>setIdTipoReconocimiento(e)}
                        urlOptionsService={`educacionIES/tiposReconocimientos`}
                    />
                }
                
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant='secondary'
                    onClick={()=>setModal()}
                >
                    Cancelar
                </Button>
                <Button
                    onClick={()=>crearReconociminento()}
                >
                    Crear
                </Button>
            </Modal.Footer>
        </Modal>
        {/* modal de elimianr */}

        <Modal 
            show={modalEliminar}
            onHide={()=>setModalEliminar()}
        >
            <Modal.Body>
                <b>¿Desea eliminar este reconocimiento?</b>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant='secondary'
                    onClick={()=>setModalEliminar()}
                >
                    Cancelar
                </Button>
                <Button
                    variant='danger'
                    onClick={()=>eliminar()}
                >
                    Aceptar
                </Button>
            </Modal.Footer>
        </Modal>
    </>
  )
}
