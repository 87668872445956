import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom"
import { DynamicTable } from "../../components/DynamicForm/dynamicTable/DynamicTable"
import { LocalStorage } from "../../services"
import { BASE_URL } from "../../services/api/config";

function CursosActivos () {

  const api = new LocalStorage();
  const idUsuario = api.getAsJsonItem("usuario")?.usuario?.IdUsuario || 'localstorage_empty'
  const dimension = api.getItem("dimension")
  const urlExportar = `${BASE_URL}/exportarCursoActivo/coordinador?IdUsuario=${idUsuario}`
 
  const urlProgramas = `coordinadoresAcademico/cursosActivos?IdUsuario=${idUsuario}`;
  const navigate = useNavigate();

  const onNavigate = (programa) => {
    navigate(`${programa.IdOferta}/asignaturas`,{state:{programa,idUsuario}});
  }

  const goHorario=(programa)=>{
    navigate(`${programa.IdOferta}/horario`,{state:{programa,idUsuario}});
  }
  const goHorarioGeneral=(programa)=>{
    navigate(`${programa.IdOferta}/horarios`,{state:{oferta:programa,idUsuario}});
  }
  const goAsistencias=(programa)=>{
    navigate(`${programa.IdOferta}/asistencias_docentes`,{state:{programa,idUsuario}});
  }
  
  return (
    <>
    
    {dimension === '"true"' ?
      <>
        <Card>
          <Card.Title>Cohortes Activas ES</Card.Title>
        </Card>
          <DynamicTable
          exportar={urlExportar}
          titleColumn={['N°','Nombre','Fecha Inicio','Fecha Fin', 'Acción']}
          attributes={['Nombre','FechaInicioCurso','FechaFinCurso']}
          endPoint={urlProgramas}
          onDelete={false}
          onUpdate={false}
          labelOptionClick={`Ver más`}
          handleOptionClick={onNavigate}
          labelOptionClick3={`Asistencia docentes`}
          handleOptionClick3={goAsistencias}
          classNameOptionIcon={`bi bi-view-list`}
          labelOptionClick2={`Tutorías y Asesorías`}
          handleOptionClick2={(e)=>{
            navigate('tutorias_asesorias',{state:{oferta:e}})
          }}
        ></DynamicTable>
      </>
    :
    <>
      <Card>
        <Card.Title>Actividades Académicas Activas</Card.Title>
      </Card>
      <DynamicTable
        titleColumn={['N°','Nombre','Fecha Inicio','Fecha Fin', 'Acción']}
        attributes={['Nombre','FechaInicioCurso','FechaFinCurso']}
        endPoint={urlProgramas}
        onDelete={false}
        onUpdate={false}
        labelOptionClick={`Ver más`}
        handleOptionClick={onNavigate}
        labelOptionClick2={`Horario general`}
        handleOptionClick2={goHorarioGeneral}
        labelOptionClick3={`Programación Académica`}
        handleOptionClick3={goHorario}
        labelOptionClick4={`Asistencia docentes`}
        handleOptionClick4={goAsistencias}
        classNameOptionIcon={`bi bi-view-list`}
        exportar={urlExportar}
      ></DynamicTable>
    </>

    }
    

    </>
  )
}
export { CursosActivos }