import React from 'react'

import { DeleteDialog } from '../../componentsForm/deleteDialog/DeleteDialog'
import { Table } from '../../componentsForm/table/Table'
import { Modal } from '../../modal/Modal'
import { AddButton } from '../../componentsForm/addButton/addButton'
import { FormContainer } from '../../componentsForm/formContainer/FormContainer';
import { Form } from './Form'

const TipoConvocatoria = props => {

    const {
        onCreate,
        title,
        tipoConvocatorias,
        attributes,
        onDelete,
        onUpdate,
        isOpenForm,
        handleSubmit,
        handleChange,
        loading,
        tipoConvocatoria,
        handleClickAdd,
        isCreate,
        isOpenDelete,
        handleClickDelete,
        deleteTuple,
    } = props

    return (
        <FormContainer>    
            <AddButton 
                buttonTitle='Agregar tipo convocatoria'
                createModal={onCreate}
                formTitleValue='Tipos de Covocatoría' 
            />
            <Table 
                titleColumn={ title }
                data={ tipoConvocatorias }
                attributes={ attributes }
                onDelete={ onDelete }
                onUpdate={ onUpdate }
                isDetails={false} 
                isLoading={ loading }
            />
            <Modal isOpen={ isOpenForm }>
                <Form 
                    handleSubmit={ handleSubmit }
                    handleChange={ handleChange }
                    loading={ loading }
                    formData={ tipoConvocatoria }
                    close={ handleClickAdd }
                    isCreate={ isCreate }
                />
            </Modal>
            <Modal isOpen={ isOpenDelete } close={ handleClickDelete } >
                <DeleteDialog 
                    deleteTuple={ deleteTuple }
                    handleClickDelete={ handleClickDelete }
                    element={ tipoConvocatoria.Descripcion }
                    loading={ loading }
                />
            </Modal>
        </FormContainer>  
    )
}

export { TipoConvocatoria }