import React from 'react'
import { BASE_URL } from '../../../services/api/config'
import { ListGroup, Modal } from 'react-bootstrap'
import DowloadFile from '../../../components/DowloadFile/DowloadFile'

export default function ModalFormatosAdmision({
    modalDescargar,
    setModalDescargar,
    IdOferta,
    fasesLista,
    oferta,
}) {

    const URL_EXPORT = `${BASE_URL}/exportar/`
    
  return (
    <Modal 
    show={modalDescargar} 
    onHide={()=>setModalDescargar(false)}
>
    <Modal.Header closeButton>
        <Modal.Title>Exportables y Formatos</Modal.Title>
    </Modal.Header>
    <Modal.Body>
                <ul className="list-group">
                    {/* <li className="list-group-item d-flex justify-content-between align-items-center">                        
                        <DowloadFile 
                            nameFile={"Listado de Apirantes.xlsx"}
                            name={"Listado de Apirantes"}
                            endPoint={`${URL_EXPORT}aspirantes?IdOferta=${IdOferta}`}
                        />
                        Listado de Aspirantes
                        <a target="blank" href={`${URL_EXPORT}aspirantes?IdOferta=${IdOferta}`}>
                        <i className="bi bi-file-arrow-down"></i>
                        </a>
                    </li>
                    <br></br> */}
                    <ListGroup>
                        <ListGroup.Item>
                            <DowloadFile 
                                nameFile={"Lista de Aspirantes.pdf"}
                                name={"Lista de Aspirantes"}
                                endPoint={`${URL_EXPORT}aspirantes?IdOferta=${IdOferta}`}
                            />
                        </ListGroup.Item>
                        <br></br>
                        <ListGroup.Item>
                            <DowloadFile 
                                nameFile={"Listado de Inscritos.pdf"}
                                name={"Inscritos"}
                                endPoint={`${BASE_URL}/cursos/exportarAspirantesInscritos?IdOferta=${IdOferta}`}
                            />
                        </ListGroup.Item>
                        {oferta.IdConvocatoria&&
                            <ListGroup.Item>
                                <DowloadFile 
                                    nameFile={"Admitidos a pruebas.pdf"}
                                    name={"Admitidos a pruebas"}
                                    endPoint={`${BASE_URL}/cursos/exportarAdmitidosPrueba?IdOferta=${IdOferta}`}
                                />
                            </ListGroup.Item>
                        }
                        <ListGroup.Item>
                            <DowloadFile 
                                nameFile={"Admitidos.pdf"}
                                name={"Admitidos"}
                                endPoint={`${BASE_URL}/cursos/exportarAspirantesAdmitidos?IdOferta=${IdOferta}`}
                            />
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <DowloadFile 
                                nameFile={"Matriculados.pdf"}
                                name={"Matriculados"}
                                endPoint={`${BASE_URL}/cursos/exportarAspirantesMatriculados?IdOferta=${IdOferta}`}
                            />
                        </ListGroup.Item>
                        {oferta.MasInformacion=="true" && oferta.IdFormulario!=null &&
                            <ListGroup.Item>
                                <DowloadFile 
                                    nameFile={"Más Información - Formulario Dinamico.xlsx"}
                                    name={"Más Información - Formulario Dinamico"}
                                    endPoint={`${BASE_URL}/cursos/exportarMasInformacion?IdOferta=${IdOferta}`}
                                />
                            </ListGroup.Item>
                        }
                    </ListGroup>
                    {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                        Pre inscritos
                        <a href={`${URL_EXPORT}preInscritos?IdOferta=${IdOferta}`}>
                            <i class="bi bi-file-arrow-down"></i>
                        </a>
                        </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        Inscritos
                        <a href={`${URL_EXPORT}inscritos?IdOferta=${IdOferta}`}>
                            <i class="bi bi-file-arrow-down"></i>
                        </a>
                        </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        Admitidos
                        <a href={`${URL_EXPORT}admitidos?IdOferta=${IdOferta}`}>
                            <i class="bi bi-file-arrow-down"></i>
                        </a>                       
                        </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        Estudiantes
                        <a href={`${URL_EXPORT}matriculados?IdOferta=${IdOferta}`}>
                        <i class="bi bi-file-arrow-down"></i>
                        </a>
                    </li> */}
                </ul>
        <h5 className='text-center p-2'>Exportar Admitidos por Etapas</h5>
        <ul className="list-group">
        {fasesLista.map((fase,key)=>
        <>
            {fase.ExportableHijos == "true" || fase.ExportableHijos == 1 
            ? 
                (
                    <>
                    <ListGroup>
                        <ListGroup.Item>
                        <DowloadFile
                            nameFile={`${fase.NombreExportable||fase.Nombre}.xlsx`}
                            name={`${fase.Nombre}.xlsx`}
                            endPoint={`${BASE_URL}/convocatoriaFase/exportarFasesAsociadas?IdConvocatoriaFase=${fase.IdConvocatoriaFase}&IdOferta=${IdOferta}`}
                        />
                        </ListGroup.Item>
                    </ListGroup>
                    {fase.IdFasePadre==null&&
                            <ListGroup>
                                <ListGroup.Item>
                                <DowloadFile
                                    nameFile={`${fase.NombreExportable||fase.Nombre}.pdf`}
                                    name={`${fase.Nombre}.pdf`}
                                    endPoint={`${BASE_URL}/formatosIES/exportar/admitidosFase?IdConvocatoriaFase=${fase.IdConvocatoriaFase}&IdOferta=${IdOferta}`}
                                />
                                </ListGroup.Item>
                            </ListGroup>
                        }
                    </>
                )
            :
                <>
                {fase.IdFasePadre==null&&
                    <ListGroup>
                        <ListGroup.Item>
                        <DowloadFile
                                    nameFile={`${fase.NombreExportable||fase.Nombre}.xlsx`}
                                    name={`${fase.Nombre}.xlsx`}
                                    endPoint={`${BASE_URL}/formatosIES/exportar/admitidosFaseExcel?IdConvocatoriaFase=${fase.IdConvocatoriaFase}&IdOferta=${IdOferta}`}
                                    />
                        </ListGroup.Item>
                        <ListGroup.Item>
                        <DowloadFile
                                    nameFile={`${fase.NombreExportable||fase.Nombre}.pdf`}
                                    name={`${fase.Nombre}.pdf`}
                                    endPoint={`${BASE_URL}/formatosIES/exportar/admitidosFase?IdConvocatoriaFase=${fase.IdConvocatoriaFase}&IdOferta=${IdOferta}`}
                                    />
                        </ListGroup.Item>
                    </ListGroup>
                }
                </>
        }
        </>
        )}
        </ul>                
    </Modal.Body>
    </Modal>
  )
}
