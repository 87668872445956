import React from 'react';

import { Button } from '../../button/button';

import './FooterHome.css';
// importar imagen
import logo_seguridad from '../../../assets/gif/logo_seguridad.gif'

const FooterHome = ({ type }) => {

    const LINK_FACEBOOK = 'https://www.facebook.com/CEAestudiosaeronauticos/'
    const LINK_INSTAGRAM = 'https://www.instagram.com/cea_aerocivil/'
    const LINK_TWITTER = 'https://twitter.com/CEA_AEROCIVIL'
    const LINK_YOUTUBE = 'https://www.youtube.com/channel/UCs3VkrwDVbdA0PHMASH4DNg'
    const LINK_ICAO = 'https://www.icao.int/Pages/default.aspx'
    const date ={
        year:  new Date().getFullYear()
    }
    const classFooter = `footer-home ${type}`
    const classButton = `footer_button ${type}`

    const linksOfInterest = [
        {
            text: 'AERONÁUTICA CIVIL DE COLOMBIA'
        },
        {
            text: 'Av. Eldorado 103-15'
        },
        {
            text: 'Nuevo Edificio Aerocivil'
        },
        {
            text: 'Bogotá D.C. Colombia'
        },
    ]

    return (
        <>
            <footer className={ classFooter }>
                <div className='footer-home__container'>
                    <section>
                        {/* <h2>Sobre nosotros</h2> */}
                    </section>
                    <section className='footer-home__contact'>
                        <div>
                            <h3 className='mb-4'>ATENCIÓN AL CIUDADANO</h3>
                            <h5>soporte@halconerp.com</h5>
                            <section className='footer-home__email'>
                                <p>HORARIO DE ATENCIÓN:</p>
                                <h5>Lunes a viernes 8:00am a 4:00pm</h5>
                            </section>
                        </div>
                        <div className="footer-home__networks">
                            <Button 
                                isAnchor={ true }
                                to={ LINK_FACEBOOK }
                                className={`fs-4 ${classButton}`}
                            >
                                <i className="bi bi-facebook"></i>
                            </Button>
                            <Button 
                                isAnchor={ true }
                                to={ LINK_TWITTER }
                                className={`fs-4 ${classButton}`}
                            >
                                <i className="bi bi-twitter"></i>
                            </Button>
                            <Button 
                                isAnchor={ true }
                                to={ LINK_INSTAGRAM }
                                className={`fs-4 ${classButton}`}
                            >
                                <i className="bi bi-instagram"></i>
                            </Button>
                            <Button 
                                isAnchor={ true }
                                to={ LINK_YOUTUBE }
                                className={`fs-4 ${classButton}`}
                            >
                                <i className="bi bi-youtube"></i>
                            </Button>
                        </div>
                    </section>
                    <section className='footer-home__links'>
                        <h3 className='mb-4'>Información</h3>
                        <div>
                            {
                                linksOfInterest.map((item, index) => (
                                    <div 
                                        className='footer-home__international-aeronautics'
                                        key={ index }
                                    >
                                        <h3 className={`${classButton}`}>
                                            { item.text }
                                        </h3>
                                    </div>
                                ))
                            }
                        </div>
                        {/* importar imagen  */}
                        <div>
                            <Button
                                isAnchor={ true }
                                to={ LINK_ICAO }
                                className={`fs-4 ${classButton}`}
                            >
                                <img
                                    src={logo_seguridad}
                                    alt="logo icao"
                                    className='footer-home__international-aeronautics-img'
                                />
                            </Button>
                        </div>
                    </section>
                </div>
                <div 
                    className="footer-home__copyright" 
                >
                <span 
                    style={{
                        display:"block",
                        borderBottom:"1px solid #ccc",
                        padding:20
                    }}
                    >Vigilada Mineducación</span>
                    <span>
                        
                        Copyright {date.year}  -Propiedad: HALCÓN ERP © Registrado en la Dirección Nacional de Derechos de Autor del Ministerio del Interior de Colombia y protegido por la normatividad vigente y tratados internacionales de derechos de autor y propiedad intelectual.
                    </span>
                </div>
            </footer>
        </>
    );
};

export { FooterHome };