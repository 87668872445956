import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { Button, Card, Col, Dropdown, Form, Modal, Row, Table } from 'react-bootstrap'
import { BsArrowReturnRight,BsFillFilePdfFill,BsFillFileExcelFill } from 'react-icons/bs';
import { useLocation } from 'react-router-dom';
import DowloadFile from '../../components/DowloadFile/DowloadFile';
import DowloadFileText from '../../components/DowloadFileText/DowloadFileText';
import { alerta } from '../../helpers/alertas';
import { BASE_URL } from '../../services/api/config';
import { FormService } from '../../services/api/formService';
import { useFetchGet } from '../../hooks/useFetchGet';
import { AiFillFileExcel,AiFillFilePdf } from 'react-icons/ai';

export default function AsistenciasDocentes(){
    const api=new FormService();
    const location=useLocation();
    const curso=location.state.programa
    const [eventos,setEventos]=useState([]);
    const [modal,setModal]=useState();
    const [asistio,setAsistio]=useState(null)
    const [item,setItem]=useState();
    const [show,setShow]=useState(false)
    const [formDocente,setFormDocente]=useState();
    const [docentes]=useFetchGet(`docente/full`)

    useEffect(()=>{
        (async()=>{
            consultarEventos()
        })()

    },[])

    const consultarEventos=async()=>{
        const respuesta=await api.getAll(`docente/eventos?IdOferta=${curso.IdOferta}`)
        setEventos(respuesta)
        console.log(respuesta)
    }

    const guardarAsistencia=async()=>{
        let asistencia=0;
        if(asistio){
            asistencia=1
        }
        const data={
            "IdEventoAula":item.IdEventoAula,
            "IdUsuario":item.IdUsuario,
            "Asistencia":asistencia
        }
        const respuesta=await api.create("asistenciaDocentes",data)
        if(respuesta.respuesta=="exito"){
            alerta(respuesta.mensaje)
            setModal()
            setAsistio(false)
            consultarEventos();
        }else{
            alerta("Fallo desconocido")
        }
    }
  return (
    <>
        <Card>
            <Row>
                <Col>
                <Card.Title>Asistencias Docentes</Card.Title>
                </Col>
                <Col style={{textAlign:"right"}}>
                     <Button
                        style={{margin:2}}
                        variant="primary"
                        onClick={() => document.querySelector('.Asistenciaspdf').click()}
                    >
                        <DowloadFileText
                        nameFile={"Asistencias Docentes.pdf"}
                        name={"Exportar Asistencias.pdf"}
                        colortext={"#ffffff"}
                        icon={<BsFillFilePdfFill />}
                        classname="Asistenciaspdf"
                        endPoint={`${BASE_URL}/docente/asistencia?IdOferta=${curso.IdOferta}`}
                    />
                    </Button>
                    <Button
                        style={{margin:2}}
                        variant="primary"
                        onClick={() => document.querySelector('.Asistenciasxlsx').click()}
                    >
                        <DowloadFileText
                        nameFile={"Asistencias Docentes.xlsx"}
                        name={"Exportar Asistencias.xlsx"}
                        colortext={"#ffffff"}
                        icon={<BsFillFileExcelFill />}
                        classname="Asistenciasxlsx"
                        endPoint={`${BASE_URL}/exportar/excel/asistenciasdocente?IdOferta=${curso.IdOferta}`}
                    />
                    </Button>
                </Col>
            </Row>
        </Card>
        {/* {curso.IdDimension!=1&& */}
                <Table>
                    <thead>
                        <tr>
                            <th>Docente</th>
                            <th>Aula</th>
                            <th>Fecha</th>
                            <th>Estado</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                        <tbody>
                            {eventos.map((evento,key)=>
                                <tr>
                                    <td>
                                        {evento.Apoyo==1&&
                                            <BsArrowReturnRight style={{margin:"0 auto"}}/>
                                        }
                                        {evento.Nombre}
                                    </td>
                                    <td>{evento.NombreAula}</td>
                                    <td>
                                        {evento.FechaInicio.split(" ")[0]}{', '}
                                        {evento.FechaInicio.split(" ")[1].slice(0,-3)}{'-'}
                                        {evento.FechaFin.split(" ")[1].slice(0,-3)}
                                    </td>
                                    <td>
                                        {evento.Asistencia==null&&
                                            <>Sin calificar</>
                                        }
                                        {evento.Asistencia==0&&
                                            <>No asistió</>
                                        }
                                        {evento.Asistencia==1&&
                                            <>Asistió</>
                                        }
                                    </td>
                                    <td>
                                        {evento.Asistencia==null&&
                                        <Button 
                                             onClick={()=>{
                                                setModal(true)
                                                setItem(evento)
                                            }}
                                         >
                                                 Calificar
                                         </Button>
                                        }
                                       
                                    </td>
        
                                </tr>
                            )}
                        </tbody>
                </Table>
        {/* } */}
        {/* {curso.IdDimension==1&&
            <Table>
                <thead>
                    <tr>
                        <th>Docente</th>
                        <th>Fecha</th>
                        <th>Estado</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                    <tbody>
                        {eventos.map((evento,key)=>
                            <tr>
                                <td>
                                    {evento.docente}
                                </td>
                                <td>
                                    {evento.FechaInicio.split(" ")[0]}{', '}
                                    {evento.FechaInicio.split(" ")[1].slice(0,-3)}{'-'}
                                    {evento.FechaFin.split(" ")[1].slice(0,-3)}
                                </td>
                                <td>
                                    {evento.Asistencia==null&&
                                        <>Sin calificar</>
                                    }
                                    {evento.Asistencia==0&&
                                        <>No asistió</>
                                    }
                                </td>
                                <td>
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        <Dropdown.Menu>
                                        <Dropdown.Item href="#/action-1">Editar</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Eliminar</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown.Toggle>
                                </Dropdown>
                                </td>
                            </tr>
                        )}
                    </tbody>
            </Table>
        }    */}

        <Modal
            show={modal}
            onHide={()=>setModal()}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Calificar Asistencia
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form.Check 
                type="switch"
                id="custom-switch"
                label="El docente asistió"
                onChange={(e)=>setAsistio(e.target.checked)}
                value={asistio}
            />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant='secondary'
                    onClick={()=>setModal()}
                >
                    Cancelar
                </Button>
                <Button
                    onClick={()=>guardarAsistencia()}
                >
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal  
            show={show}
            onHide={()=>setShow()}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Crear Asistencia
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Docente</Form.Label>
                        <Form.Control as="select"
                            onChange={(e)=>setFormDocente({...formDocente,IdUsuario:e.target.value})}
                        >
                            <option>Seleccione un docente</option>
                            {docentes.map((docente,key)=>
                                <option value={docente.IdUsuario}>{docente.Nombre}</option>
                            )}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Fecha</Form.Label>
                        <Form.Control type="date"
                            onClick={(e)=>setFormDocente({...formDocente,fecha:e.target.value})}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Hora de inicio</Form.Label>
                        <Form.Control type="time"
                            onClick={(e)=>setFormDocente({...formDocente,horaInicio:e.target.value})}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Hora de fin</Form.Label>
                        <Form.Control type="time"
                            onClick={(e)=>setFormDocente({...formDocente,horaFin:e.target.value})}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            label="El docente asistió"
                            onChange={(e)=>setFormDocente({...formDocente,asistio:e.target.checked})}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant='secondary'
                    onClick={()=>setShow()}
                >
                    Cancelar
                </Button>
                <Button
                    onClick={()=>guardarAsistencia()}
                >
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
        {/* agregar estilos */}
        <style>
            {`
                .modal-body div{
                    margin-bottom:10px;
                }
            `}
        </style>


    </>
  )
}
