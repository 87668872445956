import { useNavigate } from 'react-router-dom'
import { DynamicFormContainer } from '../../../../components/DynamicForm/DynamicFormContainer'
import { DynamicTable } from '../../../../components/DynamicForm/dynamicTable/DynamicTable';
import { alerta } from '../../../../helpers/alertas'
import { BASE_URL } from '../../../../services/api/config';
import { LocalStorage } from '../../../../services';
import { useState, useEffect } from 'react';
import { DynamicSearch } from '../../../../components/DynamicForm/DynamicSearch/DynamicSearch';
import { FormService } from '../../../../services/api/formService';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Card } from 'react-bootstrap';
import { DynamicForm } from '../../../../components/DynamicForm/DynamicForm';
import { Input } from '../../../../components/input/input'; 
import { useDispatch } from 'react-redux';
import { setForm, setItemSelected } from '../../../../components/DynamicForm/FormSlice';
import Swal from 'sweetalert2';
import { Select } from '../../../../components/select/Select';

function OfertaAsignaturas() { 
    const dispatch = useDispatch()
    const urlEnpoint = `ofertas`
    const [endPoint, setEndPoint] = useState(urlEnpoint)
    const [campos, setCampos] = useState()
    const [modalCrear, setModalCrear] = useState()
    const [modalActualizar, setModalActualizar] = useState(false)
    const [cuposProyectados, setCuposProyectados] = useState()
    const [cuposTotalProyectados, setCuposTotalProyectados] = useState()
    const [matriculaTotalEsperada, setMatriculaTotalEsperada] = useState()
    const [fechaIniciaAdmision, setFechaIniciaAdmision] = useState()
    const [fechaFinalizaAdmision, setFechaFinalizaAdmision] = useState()
    const [fechaIniciaMatricula, setFechaIniciaMatricula] = useState()
    const [fechaFinalizaMatricula, setFechaFinalizaMatricula] = useState()
    const [fechaFinalizaActividad, setFechaFinalizaActividad] = useState()
    const [fechaIniciaSemestre, setFechaIniciaSemestre] = useState()
    const [fechaReporteAdmitidos, setFechaReporteAdmitidos] = useState()
    const [fechaReporteMatriculados, setFechaReporteMatriculados] = useState()
    const [calendario, setCalendario] = useState()
    const [observaciones, setObservaciones] = useState()
    const [item, setItem] = useState()
    const [fechaInicioCurso, setFechaInicioCurso] = useState()
    const [fechaFinCurso, setFechaFinCurso] = useState()
    const api = new FormService()
    const stoare=new LocalStorage();
    const dimension=stoare.getItem("dimension");
    const navigate=new useNavigate();
    const urlexport =dimension=='"true"'? 
                                        `${BASE_URL}/exportar/ofertas?IdDimension=1`
                                        :
                                        `${BASE_URL}/exportar/ofertas?IdDimension=2`

    const exportables=(e)=>{
        if(e.IdConvocatoria!=null){
            navigate("gestion_convocatoria",{state:{oferta:e}})
            
        }else{
            alerta("La oferta no tiene una convocatoria vinculada")
        }
    }
    
    const onRequisitos =(e)=>{
        navigate("requisitos",{state:{oferta:e}})
    }

    const onSearch = (e) => {
        setEndPoint(endPoint+e)
    }
    
    const fetchCamposFormFilter = async () => {
        const camp = await api.getAll(`xcampos?id_formulario=13`)
        .then((res)=>{
            setCampos(res)
        })
    }

    useEffect(() => {
      fetchCamposFormFilter()
    }, [])
    

    const renderizar = () => {
        setModalCrear(false)
        setModalActualizar(false)
        setEndPoint(`${urlEnpoint}?i=${Math.random()}`)
    }
    // eliminar oferta con confirmación 

    const eliminarOferta = async (e) => {
        console.log(e);
        
        // Mostrar la alerta de confirmación
        const result = await Swal.fire({
          title: '¿Está seguro de eliminar la oferta?',
          text: "¡No podrás revertir esto!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sí, eliminar',
          cancelButtonText: 'Cancelar'
        });
        
        if (result.isConfirmed) {
          try {
            const response = await api.delete('ofertas', { Estado: 0 }, e.IdOferta);
            
            if (response.respuesta == "exito") {
              Swal.fire(
                '¡Eliminado!',
                'La oferta ha sido eliminada.',
                'success'
              );
              
              setEndPoint(`${urlEnpoint}?i=${Math.random()}`);
            } else {
              Swal.fire(
                '¡Error!',
                'La oferta no ha sido eliminada.',
                'error'
              );
            }
          } catch (error) {
            console.log(error);
          }
        }
    }
      


    return (
        <>
            <Card>
                <h2>Ofertas</h2>
                <div style={{textAlign: 'right'}}>
                    <Button onClick={()=>{
                            setModalCrear(true)
                            dispatch(setItemSelected(null))
                        }}>
                        Crear
                    </Button>
                </div>
            </Card>
            
            {campos &&
                <DynamicSearch
                    onSearch={onSearch}
                    camposForm={campos}
                />
            }
            {dimension == '"true"' 
                ?
                <DynamicTable
                    endPoint={endPoint}
                    titleColumn={["N°", "Plan Estudio a Ofertar", "Cupos", "Fecha Inicial Período", "Fecha Final Período", "Código de Grupo", "Acción"]}
                    attributes={["Nombre", "Cupos", "FechaInicioCurso", "FechaFinCurso", "codigo_grupo"]}
                    onUpdate={false}
                    exportar={`${BASE_URL}/exportar/ofertas`}
   
                    labelOptionClick2={'Requisitos'}
                    handleOptionClick2={(e) => onRequisitos(e)}
                    labelOptionClick3={"Actualizar"}
                    handleOptionClick3={(e) => {
                        setModalActualizar(true)
                        dispatch(setItemSelected(e))
                        dispatch(setForm({llave_primaria:"IdOferta",form_data:"0"}))
                        setItem(e)
                    }}
                    elimimarExterno={(e)=>eliminarOferta(e)}
                />
                :
                <DynamicTable
                    endPoint={endPoint}
                    titleColumn={["N°", "Plan Estudio a Ofertar", "Cupos", "Fecha Inicial Período", "Fecha Final Período", "Código de Grupo", "Acción"]}
                    attributes={["Nombre", "Cupos", "FechaInicioCurso", "FechaFinCurso", "codigo_grupo"]}
                    onUpdate={false}
                    exportar={`${BASE_URL}/exportar/ofertas`}
                    labelOptionClick={dimension == "true" ? "" : "Gestión convo."}
                    handleOptionClick={dimension == "true" ? ()=>{} : (e)=>exportables(e)}
                    labelOptionClick2={'Requisitos'}
                    handleOptionClick2={(e) => onRequisitos(e)}
                    labelOptionClick3={"Actualizar"}
                    handleOptionClick3={(e) => {
                        setModalActualizar(true)
                        dispatch(setItemSelected(e))
                        dispatch(setForm({llave_primaria:"IdOferta",form_data:"0"}))
                        setItem(e)
                    }}
                    elimimarExterno={(e)=>eliminarOferta(e)}
                />
            }
            

            <>
                <Modal
                    show={modalCrear}
                    onHide={() => setModalCrear(false)}
                >
                    <DynamicForm
                        title={"Crear Oferta"}
                        justDynamicForm={{flag: true, idForm:dimension=='"true"'? '177':'13'}}
                        isCreate={true}
                        endPointSubmit='ofertas'
                        onClickCancelBtn={setModalCrear}
                        accionMysql={() => renderizar()}
                    />
                </Modal>
                <Modal
                    show={modalActualizar}
                    onHide={() => setModalActualizar(false)}
                >
                    <DynamicForm
                        title={"Actualizar Oferta"}
                        justDynamicForm={{flag: true, idForm:dimension=='"true"'? '177':'13'}}
                        isCreate={false}
                        endPointSubmit='ofertas'
                        onClickCancelBtn={setModalActualizar}
                        accionMysql={() => renderizar()}
                    />
                </Modal>
            </>

            {/* <Modal
                show={modalCrear}
                onHide={() => setModalCrear(false)}
            >
                <DynamicForm
                    title={"Crear Oferta"}
                    justDynamicForm={{flag: true, idForm: '13'}}
                    isCreate={true}
                    endPointSubmit='ofertas'
                    onClickCancelBtn={setModalCrear}
                    accionMysql={() => renderizar()}
                    extraData={
                        {
                            "FechaInicioCurso": fechaInicioCurso,
                            "FechaFinCurso": fechaFinCurso,
                            "IdCalendario": calendario,
                            "CuposNuevosProyectados": cuposProyectados,
                            "CuposTotalesProyectados": cuposTotalProyectados,
                            "MatriculaTotalEsperada": matriculaTotalEsperada,
                            "FechaInicioAdmision": fechaIniciaAdmision,
                            "FechaFinAdmision": fechaFinalizaAdmision,
                            "FechaInicioMatricula": fechaIniciaMatricula,
                            "FechaFinMatricula": fechaFinalizaMatricula,
                            "FechaInicioSemestre": fechaIniciaSemestre,
                            "FechaFinActividades": fechaFinalizaActividad,
                            "FechaReporteAdmitidos": fechaReporteAdmitidos,
                            "FechaReporteMatriculados": fechaReporteMatriculados,
                            "Observaciones": observaciones
                        }
                    }
                >
                    {dimension == '"true"' ?
                    <>
                    <Input
                        label={"Fecha Inicial del Semestre"}
                        type={"date"}
                        value={fechaInicioCurso}
                        onChange={(e) => setFechaInicioCurso(e)}
                    />
                    <Input
                        label={"Fecha Final del Semestre"}
                        type={"date"}
                        value={fechaFinCurso}
                        onChange={(e) => setFechaFinCurso(e)}
                    />
                    <Input
                        label={"Fecha Inicia Admisión"}
                        type={"date"}
                        value={fechaIniciaAdmision}
                        onChange={(e) => setFechaIniciaAdmision(e)}
                    />
                    <Input
                        label={"Cupos Nuevos Proyectados"}
                        type={"number"}
                        value={cuposProyectados}
                        onChange={(e) =>setCuposProyectados(e)}
                    />
                    <Input
                        label={"Cupo Totales Proyectados"}
                        type={"number"}
                        value={cuposTotalProyectados}
                        onChange={(e) => setCuposTotalProyectados(e)}
                    />
                    <Input
                        label={"Matrícula Total Esperada"}
                        type={"number"}
                        value={matriculaTotalEsperada}
                        onChange={(e) => setMatriculaTotalEsperada(e)}
                    />
                    <Input
                        label={"Fecha Inicia Admisión"}
                        type={"date"}
                        value={fechaIniciaAdmision}
                        onChange={(e) => setFechaIniciaAdmision(e)}
                    />
                    <Input
                        label={"Fecha Finaliza Admisión"}
                        type={"date"}
                        value={fechaFinalizaAdmision}
                        onChange={(e) => setFechaFinalizaAdmision(e)}
                    />
                    <Input
                        label={"Fecha Inicia Matrícula"}
                        type={"date"}
                        value={fechaIniciaMatricula}
                        onChange={(e) => setFechaIniciaMatricula(e)}
                    />
                    <Input
                        label={"Fecha Finaliza Matrícula"}
                        type={"date"}
                        value={fechaFinalizaMatricula}
                        onChange={(e) => setFechaFinalizaMatricula(e)}
                    />
                    <Input
                        label={"Fecha Finalizan Actividades"}
                        type={"date"}
                        value={fechaFinalizaActividad}
                        onChange={(e) => setFechaFinalizaActividad(e)}
                    />
                    <Input
                        label={"Fecha Inicio de Semestre"}
                        type={"date"}
                        value={fechaIniciaSemestre}
                        onChange={(e) => setFechaIniciaSemestre(e)}
                    />
                    <Input
                        label={"Fecha Reporte de Admitidos"}
                        type={"date"}
                        value={fechaReporteAdmitidos}
                        onChange={(e) => setFechaReporteAdmitidos(e)}
                    />
                    <Input
                        label={"Fecha Reporte de Matrículados"}
                        type={"date"}
                        value={fechaReporteMatriculados}
                        onChange={(e) => setFechaReporteMatriculados(e)}
                    />
                    <Input
                        label={"Observaciones"}
                        type={"date"}
                        value={observaciones}
                        onChange={(e) => setObservaciones(e)}
                    />
                    <Select
                        label={"Calendario"}
                        value={calendario}
                        onChange={(e)=> setCalendario(e)}
                        optionName={'Nombre'}
                        optionValue={'IdCalendario'}
                        // urlOptionsServiceoptions={`calendarios`}
                        urlOptionsService={'calendarios'}
                    />
                    </> 
                    :
                    <>
                    <Input
                        label={"Fecha Inicio"}
                        type={"date"}
                        value={fechaInicioCurso}
                        onChange={(e) => setFechaInicioCurso(e)}
                    />
                    <Input
                        label={"Fecha Fin"}
                        type={"date"}
                        value={fechaFinCurso}
                        onChange={(e) => setFechaFinCurso(e)}
                    />
                    
                    </>
                    }
                </DynamicForm>

            </Modal>

            <Modal
                show={modalActualizar}
                onHide={() => setModalActualizar(false)}
            >
                <DynamicForm
                    title={"Actualizar Oferta"}
                    justDynamicForm={{flag: true, idForm: '13'}}
                    isCreate={false}
                    endPointSubmit='ofertas'
                    onClickCancelBtn={setModalActualizar}
                    accionMysql={() => renderizar()}
                    extraData={
                        {
                            "FechaInicioCurso": fechaInicioCurso,
                            "FechaFinCurso": fechaFinCurso,
                            "IdCalendario": calendario,
                            "CuposNuevosProyectados": cuposProyectados,
                            "CuposTotalesProyectados": cuposTotalProyectados,
                            "MatriculaTotalEsperada": matriculaTotalEsperada,
                            "FechaInicioAdmision": fechaIniciaAdmision,
                            "FechaFinAdmision": fechaFinalizaAdmision,
                            "FechaInicioMatricula": fechaIniciaMatricula,
                            "FechaFinMatricula": fechaFinalizaMatricula,
                            "FechaInicioSemestre": fechaIniciaSemestre,
                            "FechaFinActividades": fechaFinalizaActividad,
                            "FechaReporteAdmitidos": fechaReporteAdmitidos,
                            "FechaReporteMatriculados": fechaReporteMatriculados,
                            "Observaciones": observaciones
                        }
                    }
                >
                    {dimension == '"true"' ?
                    <>
                    <Input
                        label={"Cupos Nuevos Proyectados"}
                        type={"number"}
                        value={cuposProyectados}
                        onChange={(e) =>setCuposProyectados(e)}
                    />
                    <Input
                        label={"Cupo Totales Proyectados"}
                        type={"number"}
                        value={cuposTotalProyectados}
                        onChange={(e) => setCuposTotalProyectados(e)}
                    />
                    <Input
                        label={"Matrícula Total Esperada"}
                        type={"number"}
                        value={matriculaTotalEsperada}
                        onChange={(e) => setMatriculaTotalEsperada(e)}
                    />
                    <Input
                        label={"Fecha Inicia Admisión"}
                        type={"date"}
                        value={fechaIniciaAdmision}
                        onChange={(e) => setFechaIniciaAdmision(e)}
                    />
                    <Input
                        label={"Fecha Finaliza Admisión"}
                        type={"date"}
                        value={fechaFinalizaAdmision}
                        onChange={(e) => setFechaFinalizaAdmision(e)}
                    />
                    <Input
                        label={"Fecha Inicia Matrícula"}
                        type={"date"}
                        value={fechaIniciaMatricula}
                        onChange={(e) => setFechaIniciaMatricula(e)}
                    />
                    <Input
                        label={"Fecha Finaliza Matrícula"}
                        type={"date"}
                        value={fechaFinalizaMatricula}
                        onChange={(e) => setFechaFinalizaMatricula(e)}
                    />
                    <Input
                        label={"Fecha Finalizan Actividades"}
                        type={"date"}
                        value={fechaFinalizaActividad}
                        onChange={(e) => setFechaFinalizaActividad(e)}
                    />
                    <Input
                        label={"Fecha Inicio de Semestre"}
                        type={"date"}
                        value={fechaIniciaSemestre}
                        onChange={(e) => setFechaIniciaSemestre(e)}
                    />
                    <Input
                        label={"Fecha Reporte de Admitidos"}
                        type={"date"}
                        value={fechaReporteAdmitidos}
                        onChange={(e) => setFechaReporteAdmitidos(e)}
                    />
                    <Input
                        label={"Fecha Reporte de Matrículados"}
                        type={"date"}
                        value={fechaReporteMatriculados}
                        onChange={(e) => setFechaReporteMatriculados(e)}
                    />
                    <Input
                        label={"Observaciones"}
                        type={"date"}
                        value={observaciones}
                        onChange={(e) => setObservaciones(e)}
                    />
                    <Select
                        label={"Calendario"}
                        value={calendario}
                        onChange={(e)=> setCalendario(e)}
                        optionName={'Nombre'}
                        optionValue={'IdCalendario'}
                        urlOptionsService={`calendarios`}
                    />
                    </> 
                    :
                    <>
                    <Input
                        label={"Fecha Inicio"}
                        type={"date"}
                        value={fechaInicioCurso}
                        onChange={(e) => setFechaInicioCurso(e)}
                    />
                    <Input
                        label={"Fecha Fin"}
                        type={"date"}
                        value={fechaFinCurso}
                        onChange={(e) => setFechaFinCurso(e)}
                    />
                    </>
                    }
                </DynamicForm>

            </Modal> */}
        </>
    )
}
export { OfertaAsignaturas }
