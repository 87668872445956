import { useState } from 'react'

import { FormService } from '../services/api/formService'

const useUpdate = (endPoint) => {

    const [error, setError] = useState(null)
    const formService = new FormService()

    const update = async (data, idData, deleteKey) => {
        try {
            const dataLocal = {
                ...data
            }
            const id = data[idData]
            delete dataLocal[idData]
            if (deleteKey) {
                deleteKey.map(attribute => {
                    delete dataLocal[attribute]
                })
            }
            const response = await formService.update(endPoint, dataLocal, id)
            console.log('response', response)
        } catch (err) {
            setError(err)
            // console.log('error', err)
        }
    }

    return [
        update,
        error
    ]
}

export { useUpdate }