import { Card } from "react-bootstrap";
import { DynamicFormContainer } from "../../../components/DynamicForm/DynamicFormContainer";
import { DynamicTable } from "../../../components/DynamicForm/dynamicTable/DynamicTable";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export function ParametrizacionPIC() {
    const [modalCreate, setModalCreate] = useState()
    const [modalUpdate, setModalUpdate] = useState()
    const navigate = useNavigate()

    const onNavigate = (e) => {
        navigate(`${e.IdParametrizacionPic}/funcionarios`)
    }

    return (
        <>
        <Card>
            <Row>
                    <h2> Plan Institucional de Capaciación - Parametrización</h2>
                    {/* <div style={{textAlign: 'right'}}>
                        <Button 
                            onClick={() => setModalCreate(true)}   
                        >
                            Crear
                        </Button>
                    </div> */}
            </Row>
        </Card>
        <Card>
            <DynamicFormContainer
                idForm="147"
                labelOptionClick={"Funcionarios"}
                handleOptionClick={(e) => onNavigate(e)}
            />
            {/* <DynamicTable
                titleColumn={["No", "Tipo de Conocimiento", "Nombre Eje Temático", "Temática Especifica", "Fecha Inicial", "Dirige", "Acción"]}
                attributes={["TipoConocimiento", "NombreEjeTematico", "TematicaEspecifica", "FechaInicial", "IdImparteCapacitacion"]}
                endPoint={"parametrizacionPic"}
                onActions={false}
                onDelete={true}
                onUpdate={false}
                labelOptionClick={"Actualizar"}
                handleOptionClick={() => setModalUpdate(true)}
                labelOptionClick2={"parametrizacionPic"}
                handleOptionClick2={onNavigate}

            /> */}
        </Card>
        {/* <Modal
            show={modalCreate}
            onHide={setModalCreate}
        >
            <DynamicFormContainer
                idForm="151"
                isCreate={true}
                title={"Crear Parametrización"}
                endPointSubmit="parametrizacionPic"
            />
        </Modal>
        <Modal
            show={modalUpdate}
            onHide={setModalUpdate}
        >
            <DynamicFormContainer
                idForm={"151"}
                isCreate={false}
                title={"Actualizar Parametrización"}
                endPointSubmit="parametrizacionPic"
            />
        </Modal> */}
        </>
    );

}