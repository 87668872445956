import { useNavigate } from "react-router-dom"
import { DynamicFormContainer } from "../../components/DynamicForm/DynamicFormContainer"
import { BASE_URL } from "../../services/api/config"


const Actividades=()=>{

    const Navigate=useNavigate()
    const urlExportar = `${BASE_URL}/exportarActividades`

    const irDocumnentos=(e)=>{
        Navigate("documentos",{state:{actividad:e}})
    }

    return(
        <>
            <DynamicFormContainer 
                labelOptionClick={'Gestionar Documentos'}
                handleOptionClick={(e)=>irDocumnentos(e)}
                idForm="92" 
                exportar={urlExportar}
            />
        </>
            
    )
}

export {Actividades}