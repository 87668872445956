import React, { useEffect, useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import SeleccionarAsignaturas from '../../../components/Student/SeleccionarAsignaturas/SeleccionarAsignaturas'
import { useLocation } from 'react-router-dom'
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner'
import { InputFile } from '../../../components/inputFile/inputFile'
import { Message } from '../../../components/Message/Message'
import { FormService } from '../../../services/api/formService'
import { useSubmitCreate } from '../../../components/DynamicForm/hooks/useSubmitCreate'
import { BASE_SERVIDOR, BASE_URL } from '../../../services/api/config'
import { useFetchGet } from '../../../hooks/useFetchGet'
import { CardPay } from '../../../components/cardPay/CardPay'

export default function Asignaturas() {
  // recibir parametros por useLocation
  const {oferta}=useLocation().state
  const aspirante=oferta.aspirante
  const [documents, setDocuments] = useState([])
  const URL = `requisitosAspirante?IdOferta=${oferta.IdOferta}&IdAspirante=`
  const [asignaturasAprobadas,setAsignaturasAprobadas]=useState([])
  const [loading, setLoading] = useState(false)
  const [loadingFull, setLoadingFull] = useState(false)
  const [error, setError] = useState(null)
  const [item, setItem] = useState(null)

  const ESTADO_DOCUMENTO = {
      NO_SE_HA_CARGADO_NADA:'0',
      APROBADO:'1',
      RECHAZADO:'2'
  }
  const [fetchDataCreate, statusCreate] = useSubmitCreate(
    'requisitosAspirante', 
    null, 
    null,
    '1'
  )
  const [registerPay, loadingRegister, errorRegister] = useFetchGet(`pagos/${oferta?.PagoMatricula}`)
  const [urlBill,_] = useState(
    `${BASE_URL}/estudiante/descargarRecibo?IdOFerta=${oferta.IdOferta}&IdAspirante=${oferta.IdAspirante}&Concepto=${'matricula'}`
)

const urlInstructivo=`${BASE_URL}/educacionIES/descargarReciboAsignaturas?IdAspirante=${aspirante.IdAspirante}`
  useEffect(() => {
    fetchDataDocuments()
  }, [])

  const handleChange = (e) => {}

  const onHandleSubmit = async (e, document) => {
      e.preventDefault()
      try {
          setLoading(true)
          const formData = new FormData(e.currentTarget)
          if (
              document?.requisito?.EstadoRequisito === ESTADO_DOCUMENTO.NO_SE_HA_CARGADO_NADA ||
              document?.requisito?.EstadoRequisito === ESTADO_DOCUMENTO.RECHAZADO
          ) await update(formData, document)
          else await created(formData, document)
          setLoading(false)
            fetchDataDocuments()
      } catch(err) {
          setError(err)
      }
  }
  const created = async (formData, document) => {
    formData.set('IdAspirante', aspirante?.IdAspirante)
    formData.set('IdRequisito', document?.IdRequisito)
    await fetchDataCreate(formData)
}

const update = async (formData, document) => {
    const formService = new FormService()
    formData.set('EstadoRequisito', "0")
    let dataRaw = Object.fromEntries(formData.entries());
    const id = document?.requisito?.IdRequisitoAspirante
    await formService.createWithFormData(
        `requisitosAspirante/${id}?_method=PUT`, 
        dataRaw
    )
}
  const fetchDataDocuments = async () => {
    setLoadingFull(true)
    try {
        const formService = new FormService()
        const response = await formService.getAll(`${URL}${aspirante?.IdAspirante}&IdTipoRequisito=2`)
        setLoadingFull(false)
        setDocuments(response)
    } catch (error) {
        setLoadingFull(false)
        setError(error)
    }
}

  return (
    <>
        <Card>
            <Card.Title>Seleccionar Asignaturas</Card.Title>
        </Card>
            <div style={{textAlign:"center"}}>
                <a target="blank" href={urlInstructivo}>Descargar instructivo de pago</a>    
            </div> 
            <Card>
                <Card.Title
                  style={{textAlign:'center'}}
                >
                  Adjunte el comprobante de pago
                </Card.Title>
                   <div>
                {
                    loadingFull
                    ? <LoadingSpinner /> 
                    : !!documents && documents.map((document, key) => (
                        <form 
                            onSubmit={ e => onHandleSubmit(e, document) } 
                            className='estudiante-documento'
                            key={ key }
                        >
                            <div className='estudiante-documento__document'>
                                <h6 className='estudiante-documento__document-title'>
                                    { document.Nombre }
                                </h6>
                                <p>Adjunta un documento PDF de máximo 10 Mb</p>
                                { document?.requisito?.EstadoRequisito === undefined  &&
                                        <section className='estudiante-documento__update-document'>
                                            <InputFile 
                                                label={ document.Descripcion }
                                                name='Archivo'
                                                accept='image/*,.pdf'
                                                onChange={ handleChange }
                                            />
                                            <Button 
                                                type='send'
                                                loading={ loading }
                                            >
                                                Subir
                                            </Button>
                                        </section>
                                    
                                }
                                {document?.requisito?.EstadoRequisito === ESTADO_DOCUMENTO.NO_SE_HA_CARGADO_NADA &&
                                        <>
                                            <div className="alert alert-warning" role="alert">
                                                Tu documento está en proceso de revisión.
                                                {/* mostrar el documento */}
                                                <a href={`${BASE_SERVIDOR}/pdf/${document?.requisito?.Archivo}`} target='_blank' rel='noreferrer'>
                                                    <i className='fas fa-file-pdf' /> ver documento
                                                </a>

                                            </div>
                                            <section 
                                                className='estudiante-documento__update-document'
                                            >
                                                <InputFile 
                                                    label={ document.Descripcion }
                                                    name='Archivo'
                                                    accept='.pdf'
                                                    onChange={ handleChange }
                                                />
                                                <Button 
                                                    type='send'
                                                    loading={ loading }
                                                >
                                                    Actualizar documento
                                                </Button>
                                            </section>
                                        </>
                                }
                                {
                                    document?.requisito?.EstadoRequisito === ESTADO_DOCUMENTO.APROBADO && (
                                        <>
                                            <Message type='ok'>
                                                Tu documento fue aprobado.
                                            </Message>
                                        </>
                                    )
                                }
                                {
                                    document?.requisito?.EstadoRequisito === ESTADO_DOCUMENTO.RECHAZADO && 
                                    oferta.EstadoEstudiante==="0" &&
                                        <>
                                            <Message type='error'>
                                                Tu documento fue rechazado.
                                            </Message>
                                            <h6>Sucedio por el siguiente motivo:</h6>
                                            <p>
                                                { document.requisito.Observacion }
                                            </p>
                                            <section className='estudiante-documento__update-document'>
                                                <InputFile 
                                                    label={ document.Descripcion }
                                                    name='Archivo'
                                                    accept='.pdf'
                                                    onChange={ handleChange }
                                                />
                                                <Button 
                                                    type='send'
                                                    loading={ loading }
                                                >
                                                    Actualizar documento
                                                </Button>
                                            </section>
                                        </>
                                }
                                
                            </div>
                        </form>
                    ))
                }
                </div>  
            </Card>
            {/* verificar si todos los requcisito fueron aprobados/ */}
            {!documents || documents.every(document => document?.requisito?.EstadoRequisito === ESTADO_DOCUMENTO.APROBADO) &&
                <SeleccionarAsignaturas 
                    aspirante={aspirante} 
                    IdOferta={oferta.IdOferta}
                    asignaturasAprobadas={asignaturasAprobadas}
                    setAsignaturasAprobadas={setAsignaturasAprobadas}
                />
            }
    </>
  )     
}
