import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ActualizarDatos from './ActualizarDatos'
import CambiarClave from './CambiarClave'
import FormSolicitud from './FormSolicitud'
import HistorialContinuada from './HistorialContinuada'
import HistorialIES from './HistorialIES'
import Inicio  from './Inicio'
import NuevaSolicitud from './NuevaSolicitud'
import { OfertasEmpleo } from './OfertasEmpleo'
import Reconocimientos from './Reconocimientos'
import Solicitudes from "./Solicitudes"
import ActividadesBienestar from "./ActividadesBienestar"
import ConvocatoriasGrado from '../../AutoservicioGrado/ConvocatoriasGrado'
import Incripcion from '../../AutoservicioGrado/Incripcion'
import Firmas from './Firmas'
export default function PerfilUsuario() {
  return (
    <Routes>
        <Route  path='/' element={<Inicio/>}/>
        <Route path='cambiar_clave' element={<CambiarClave/>}/>
        <Route path='actualizar_datos' element={<ActualizarDatos/>}/>
        <Route path='ofertas_de_empleo' element={<OfertasEmpleo/>}/>
        <Route path='solicitudes' element={<Solicitudes/>}/>
        <Route path='solicitudes/nueva_solicitud' element={<NuevaSolicitud/>}/>
        <Route path='solicitudes/nueva_solicitud/form_solicitud' element={<FormSolicitud/>}/>
        <Route path='reconocimientos' element={<Reconocimientos/>}/>
        <Route path='historial_continuada' element={<HistorialContinuada/>}/>
        <Route path='historial_IES' element={<HistorialIES/>}/>
        <Route path='actividades_bienestar' element={<ActividadesBienestar/>}/>
        <Route path='convocatorias_grado' element={<ConvocatoriasGrado/>}/>
        <Route path='convocatorias_grado/inscripcion' element={<Incripcion/>}/>
        <Route path='firmas' element={<Firmas/>}/>
    </Routes>
  )
}
