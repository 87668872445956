import { DynamicTable } from "../../../../components/DynamicForm/dynamicTable/DynamicTable"


function UsuarioEstudiantes (){
  return (
    <>
    <DynamicTable
      endPoint={'estudiante'}
      titleColumn={['N°','Nombre','Apellido', 'N° Documento' ,'Email', 'Teléfono', ]}
      attributes={['PrimerNombres', 'PrimerApellidos','NumeroDocumento','email', 'TelefonoContacto']}
      onDelete={false}
      onUpdate={false}
      onActions={false}
    ></DynamicTable>
    </>
  )
}

export { UsuarioEstudiantes }