import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { BsArrowLeft, BsExclamationCircle } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { FormService } from '../../services/api/formService';

export default function Solicitudes(){
    const api=new FormService();
    const navigate=new useNavigate();
    const [tiposSolicitudes,setTiposSolictudes]=useState([]);
    const [tipoSolicitud,setTipoSolicitud]=useState();

    useEffect(()=>{
        (async()=>{
            consultarTiposSolicitudes();
        })()
    },[])

    const consultarTiposSolicitudes=async()=>{
        const tiposSolicitudes=await api.getAll("tiposSolicitudes")
        setTiposSolictudes(tiposSolicitudes)
    }

    const goSolicitud=()=>{
        navigate("solicitud",{state:{tipoSolicitud:tipoSolicitud}})
    }

  return (
    <>
        <div>
            <div style={{padding:20}}>
                <Button
                    style={{float:"left"}}
                    variant='light'
                    onClick={()=>navigate("dashboard/inicio")}
                >
                    <BsArrowLeft/> Regresar dashboard                
                </Button>   

                <Button
                style={{float:"right"}}
                    variant='light'
                    onClick={()=>navigate("mis_solicitudes")}
                >
                 Mis solicitudes                 
                </Button>   
            </div>
        </div>
            <Card.Title style={{textAlign:"center",fontSize:30,marginTop:20}}>
                Solicitudes
            </Card.Title>
        <Row style={{padding:20}}>
            <Col xs="4">
                <Card>
                    <div style={{textAlign:"center",fontSize:25}}>
                        <BsExclamationCircle/>
                    </div>
                    
                    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Numquam magni, beatae at illum corporis nesciunt saepe modi maxime, corrupti aperiam optio voluptatibus repudiandae natus, accusamus eaque! Recusandae quaerat dolores sequi?</p>
                </Card>
            </Col>
            <Col>
                <Card>
                    <Card.Title>Tipos de solicitudes</Card.Title>
                    <p>Selecciona el tipo de solicitud que desea realizar</p>
                    {tiposSolicitudes.map((solicitud,key)=>
                        <Form.Check 
                            key={key}
                            type="radio"
                            id={`default${key}`}
                            name={`tipoSolicitud`}
                            onChange={()=>setTipoSolicitud(solicitud)}
                            label={`${solicitud.Nombre}`}
                        />
                    )}
                    {tipoSolicitud&&
                        <Button 
                            onClick={()=>goSolicitud()}
                        >
                            Continuar
                        </Button>
                    }
                    
                </Card>
            </Col>
        </Row>
    </>
  )
}
