import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { AddButton } from "../../../components/componentsForm/addButton/addButton"
import { useFetchGet } from "../../../hooks/useFetchGet"
import { Title } from "../../../shared/components/title/Title"
import { Card, Col, Row, Button } from "react-bootstrap"
import { ICON } from "../../../constants/icons"
import { SubTitle } from "../../../shared/components/subtitle/SubTitle"
import { BASE_URL } from "../../../services/api/config"
import { FormService } from "../../../services/api/formService"
import DowloadFile from '../../../components/DowloadFile/DowloadFile';

function PlanTrabajo () {

  const {state:{programa, oferta}} = useLocation()
  const [malla, setMalla] = useState()
  const urlSyllabus = `${BASE_URL}/exportar/formatosIES/syllabus?IdAsignatura=${malla?.IdAsignatura}&IdUsuario=${oferta.IdUsuario}&IdOferta=${programa?.IdOferta}`
  const urlMicrocurriculo = `${BASE_URL}/exportar/formatosIES/formatoMicrocurriculo?IdAsignatura=${malla?.IdAsignatura}&IdUsuario=${oferta.IdUsuario}&IdOferta=${programa?.IdOferta}`
  const api = new FormService ()

  useEffect(() => {
    consultaMalla()
  }, []);

  const consultaMalla = async () => {
    const fetchMalla = await api.getById("malla", oferta.IdMalla)
    setMalla(fetchMalla)
  }

  return(
    <>
    <Card>
      <Card.Title>{oferta.asignatura} </Card.Title>
      <p>Plan de Trabajo</p>
    </Card>
    <Card>
      <Row>
        <Col>
          <DowloadFile
              nameFile={"Formato Syllabus.pdf"}
              name={"Descargar Syllabus"}
              endPoint={urlSyllabus}
          />
        </Col>

        <Col>
          <DowloadFile
              nameFile={"Formato Microcurriculo.pdf"}
              name={"Descargar Microcurriculo"}
              endPoint={urlMicrocurriculo}
          />
        </Col>
      </Row>
    </Card>
    </>
  )
}

export { PlanTrabajo }