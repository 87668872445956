import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { DynamicSearch } from '../../../../components/DynamicForm/DynamicSearch/DynamicSearch';
import { DynamicTable } from '../../../../components/DynamicForm/dynamicTable/DynamicTable';
import { FormService } from '../../../../services/api/formService';

const CursosFinalizados=()=>{
    const urlCursos = 'instrumento/cursosFinalizados';
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const[campos,setCampos]=useState(null);
    const[endPoint,setEndPoint]=useState(urlCursos);
    const api = new FormService();
    useEffect(() => {
        const fecthData = async () => {
            setLoading(true);
            setLoading(false);
            fetchCamposFormForFilter();
        };
        fecthData();
    }, []);
    const onNavigateDetails = (programa)=>{
        console.log(programa)
        navigate(`${programa.IdCurso}`,{state:{programa}})
    }

    const fetchCamposFormForFilter = ()=>{   
        api.getAll(`xcampos?id_formulario=97`)
        .then((res)=>{
            setCampos(res)
        })
      }
      const buscarCursos=(e)=>{
        const nuevoEndPoint=`${urlCursos}${e}`
        setEndPoint(nuevoEndPoint)
      }
    return (
        <>
            <Card>
                <Card.Title>
                    Actividades Académicas Finalizadas
                </Card.Title>
                {/* <div className='d-flex justify-content-end'>
                    <div className="input-group mb-3 w-50">
                        <span className="input-group-text" id="basic-addon1">Buscar</span>
                        <input
                        onChange={onSearch}
                        type="text"
                        className="form-control" 
                        placeholder="Buscar..." 
                        aria-label="buscar" 
                        aria-describedby="buscar-item"
                        />
                    </div>
                </div> */}
            </Card>
            {campos&&
                <DynamicSearch  onSearch={buscarCursos} camposForm={campos} ></DynamicSearch>
            }
            <DynamicTable
                titleColumn={[ 'Id', 'Nombre', 'Código Grupo', 'Horas', 'Acciones', ]}
                endPoint={endPoint}
                attributes={['NombrePlanEstudio','codigo_grupo','horas']}
                handleOptionClick={onNavigateDetails}
                labelOptionClick='Ver Programa'
                onDelete={false}
                onUpdate={false}
            />
        </>
    );
}


export{CursosFinalizados};