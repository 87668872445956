import React, { useEffect, useState } from 'react'
import { Button, Card, Table } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { BASE_URL } from '../../../services/api/config'
import { FormService } from '../../../services/api/formService'
import Header from './Componentes/Header'

export default function Reconocimientos() {
    const {state:{usuario}}=useLocation()
    const api= new FormService();
    const urlReconocimiento=`${BASE_URL}/educacionIES/descargarReconocimiento`
    const [reconocimientos,setReconocimientos]=useState([]);

    useEffect(()=>{
        (async()=>{
            consultarReconocimientos()
        })()

    },[])

    const consultarReconocimientos=async()=>{
        const reconocimientos=await api.getAll("educacionIES/consultarReconocimientos");
        setReconocimientos(reconocimientos)
    }
  return (
    <>
    <div style={{maxWidth:1000,margin:"0 auto",marginTop:40}}>
        <Header usuario={usuario}/>
        <Card>
            <Card.Title>Reconocimientos</Card.Title>
        </Card>
        <Table>
            <thead>
                <tr>
                    <th>Nombre</th>
                    <th>Actividad Académica</th>
                    <th>Reconocimiento</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                {reconocimientos.map((reconocimiento,key)=>
                    <tr key={key}>
                        <td>{reconocimiento.Nombre}</td>
                        <td>{reconocimiento.Curso}</td>
                        <td>{reconocimiento.TipoReconocimiento}</td>
                        <td>
                            <Button
                                target='_blank'
                                href={`${urlReconocimiento}?IdReconocimiento=${reconocimiento.IdReconocimiento}`}
                            >
                                Descargar
                            </Button>                
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
    </div>
    </>
  )
}
