import { Col, Row, Card as Carb, Card, Button } from "react-bootstrap"

import { Bar } from 'react-chartjs-2';
import { useEffect, useState } from "react";
import { FormService } from "../../services/api/formService";
import { setError } from "../../components/DynamicForm/FormSlice";
import { alerta } from "../../helpers/alertas";
import { Input } from "../../components/input/input";
import { Select } from "../../components/select/Select";
import { Form } from "react-bootstrap";



const Otros=()=>{
    
    const [barData, setBarData] = useState([]);
    const [datos,setDatos]=useState()
    const [FechaInicio, setFechaInicio] = useState(null)
    const [FechaFin, setFechaFin] = useState(null)
    const [tipoIngreso, setTipoIngreso] = useState(null)
    const [actividadExterna, setActividadExterna] = useState(null)
    

    const consultarDatos = async () => {
        const formService = new FormService();
        if(FechaInicio === null || FechaFin === null){
            return alerta('Debe seleccionar una fecha inicial y una fecha final')
        }
        
        try {
            const response = await formService.getAll(`planeacion/otros?FechaInicio=${FechaInicio}&FechaFin=${FechaFin}&IdTipoIngreso=${tipoIngreso}&ActividadExternas=${actividadExterna}`)
            setDatos(response)
            setBarData(response.indicadores)
        } catch (err) {
            setError(err)
        }
    }
    const filterData = (data) => {
        const  dataForBar= []
        data?.tipos_evaluaciones.map(tipo => {
            dataForBar.push(tipo.contador)
        })
        return dataForBar;
    }
    // useEffect(() => {
    //     const fetchData = async () => {
    //         await consultarDatos();
    //     }
    //     fetchData();
    // }, [])

    const labels = [
        // '1-20', 
        // '20-35', 
        // '35-45', 
        // '45-60', 
        // '>60',
    ];

    const dataVerticalBar = {
        labels,
        datasets: [
            {
                label: 'Número Total de Indicador Por Periodo Seleccionado',
                data: barData,
                backgroundColor: '#009490',
            }
        ],
    }
    return(
        <>
        <Card>
        <Card.Title>Otros Reportes</Card.Title>
        </Card>
            <Row>
                <Col>
                    <Select
                        id="IdTipoIngreso"
                        name="IdTipoIngreso"
                        onChange={(e) => setTipoIngreso(e) }
                        value={ tipoIngreso || null }
                        label="Tipo de Ingreso"
                        required={true}
                        optionValue="Id"
                        optionName="nombre"
                        urlOptionsService={"selects/tipoOferta"}
                    /> 
                </Col>
                <Col>
                    <Input
                        type="date"
                        label="Fecha Inicio"
                        value={FechaInicio}
                        onChange={(e) => setFechaInicio(e)}
                    />
                </Col>
                <Col>
                    <Input
                        type="date"
                        label="Fecha Fin"
                        value={FechaFin}
                        onChange={(e) => setFechaFin(e)}
                    />
                </Col>
                {/* <Col>
                    <br />
                    <label htmlFor="">¿ Actividades Externas ?</label>
                    <Form.Check 
                        type="switch"
                        name="Externa"
                        id="Externa"
                        label="Sí"
                        checked = {actividadExterna}
                        value={actividadExterna}
                        onChange={ (e) => {
                            setActividadExterna(e.target.checked)
                        }}
                    />
                </Col> */}
                <Col>
                    <br />
                    <Button onClick={consultarDatos}>Consultar</Button>
                </Col>
            </Row>
        <Card>
        <Row>
                <Col>
                    <Carb border="primary" style={{ width: '18rem' }}>
                        <Carb.Header>
                            Aspirantes
                        </Carb.Header>
                        <Carb.Body>
                            <Carb.Title>
                            {datos?.aspirantes}
                            </Carb.Title>                           
                        </Carb.Body>
                    </Carb>
                </Col>
                <Col>
                <Carb>
                        <Carb.Header>
                            Admitidos
                        </Carb.Header>
                        <Carb.Body>
                            <Carb.Title>
                            {datos?.admitidos}
                            </Carb.Title>                           
                        </Carb.Body>
                    </Carb>
                </Col>
                <Col>
                <Carb>
                        <Carb.Header>
                            Matriculados
                        </Carb.Header>
                        <Carb.Body>
                            <Carb.Title>
                            {datos?.matriculados}
                            </Carb.Title>                           
                        </Carb.Body>
                    </Carb>
                </Col>
                <Col>
                    <Carb>
                        <Carb.Header>
                            Graduados
                        </Carb.Header>
                        <Carb.Body>
                            <Carb.Title>
                            {datos?.graduados}
                            </Carb.Title>                           
                        </Carb.Body>
                    </Carb>
                </Col>
                <Col>
                    <Carb>
                        <Carb.Header>
                            Regionales
                        </Carb.Header>
                        <Carb.Body>
                            <Carb.Title>
                            {datos?.regionales}
                            </Carb.Title>                           
                        </Carb.Body>
                    </Carb>
                </Col>
                <Col>
                    <Carb>
                        <Carb.Header>
                            Departamentos
                        </Carb.Header>
                        <Carb.Body>
                            <Carb.Title>
                            {datos?.departamentos}
                            </Carb.Title>                           
                        </Carb.Body>
                    </Carb>
                </Col>
            </Row>


            <Row>
                <Col>
                    <Carb border="primary" style={{ width: '18rem' }}>
                        <Carb.Header>
                            Áreas
                        </Carb.Header>
                        <Carb.Body>
                            <Carb.Title>
                            {datos?.areas}
                            </Carb.Title>                           
                        </Carb.Body>
                    </Carb>
                </Col>
                <Col>
                <Carb>
                        <Carb.Header>
                            Coordinadores
                        </Carb.Header>
                        <Carb.Body>
                            <Carb.Title>
                            {datos?.coordinadores}
                            </Carb.Title>                           
                        </Carb.Body>
                    </Carb>
                </Col>
                <Col>
                <Carb>
                        <Carb.Header>
                            Docentes
                        </Carb.Header>
                        <Carb.Body>
                            <Carb.Title>
                            {datos?.docentes}
                            </Carb.Title>                           
                        </Carb.Body>
                    </Carb>
                </Col>
            </Row>
            {/* <div style={{marginTop:20,marginBottom:20}}>
                <h3>Activiades y programas por dimensiones</h3>
            </div>*/}
            <Bar data={dataVerticalBar} /> 

        </Card>
        </>
    )
}   

export {Otros}