import React from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import Cursos from './Cursos'
import CursosRequisitos from './CursosRequisitos'
import { DynamicFormContainer } from '../../components/DynamicForm/DynamicFormContainer'

export default function CursosPieso() {
  const navigate = useNavigate()
  return (
    <Routes>
        <Route path="cursos" element={ <DynamicFormContainer idForm="139" /> } />
        {/* <Route path="cursos/:id" element={ <CursosRequisitos /> } /> */}
        <Route path="*" element={<DynamicFormContainer/> } />
  
    </Routes>
  )
}
