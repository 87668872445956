import React from 'react'
import { Route, Routes } from 'react-router-dom'
import TiposAlertas from './TiposAlertas'
import ListadoAlertas from './ListadoAlertas'

export default function Alertas() {
  return (
    <>
        <Routes>
            <Route path="tipos_de_alertas" element={<TiposAlertas />} />
            <Route path='listado_de_alertas' element={<ListadoAlertas />} />
        </Routes>
    </>
  )
}
