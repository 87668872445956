import { useNavigate } from "react-router-dom"
import { Card } from "../../../components/card/Card"
import { AddButton } from "../../../components/componentsForm/addButton/addButton"
import { DynamicTable } from "../../../components/DynamicForm/dynamicTable/DynamicTable"
import { LocalStorage } from "../../../services"

import {BASE_URL} from "../../../services/api/config"

function EstudianteCursosContainer () {
  const ls = new LocalStorage()
  const IdUsuario = ls.getAsJsonItem('usuario').usuario?.IdUsuario
  const navigate = useNavigate()

  const url_descarga=`${BASE_URL}/estudiante/historialAcademico?IdUsuario=${IdUsuario}`

  const handleOptionClick = (item) => {
    navigate(`${item.IdOferta}/detalle_programa`,{state:{programa:item}}) //go to Notas.jsx
  }

  return (<>
  <AddButton></AddButton>
  <Card>

  {/* <div className="d-flex mt-2 ml-1 align-items-baseline">
    <h2 style={{color:'gray'}}>Mis Programas Finalizados</h2>
  </div> */}
  <div style={{textAlign:'right'}}>
    <a className="button" href={url_descarga}>Descargar Historial</a>
  </div>
  </Card>
  <DynamicTable
  titleColumn={['N°','Nombre','Acción']}
  attributes = { ['Nombre']}
  onUpdate={false}
  onDelete={false}
  handleOptionClick= {handleOptionClick}
  labelOptionClick= {'Ver Programa'}
  classNameOptionIcon={'bi bi-book'}
  endPoint={`estudiante/cursosFinalizados?IdUsuario=${IdUsuario}`}

  
  ></DynamicTable>
  </>)
}

export { EstudianteCursosContainer }