
import React from 'react'
import { useState } from 'react'
import { Accordion, Button, Card, Col, Modal, Row } from 'react-bootstrap'
import { BsFillPlusCircleFill, BsTrash } from 'react-icons/bs'
import { Input } from '../../../components/input/input'
import { Select } from '../../../components/select/Select'
import { SelectDataList } from '../../../components/SelectDataList/SelectDataList'
import { alerta, alertaSinBoton } from '../../../helpers/alertas'
import { useFetchGet } from '../../../hooks/useFetchGet'
import { FormService } from '../../../services/api/formService'

export default function HorarioMasivo({
    oferta,
    setModalHorarioMasivo,
    setOriginalEvents,
    horarioMasivo,

}) {
    const [aulas, loadingOferta, error] = useFetchGet(`aulas/disponibles?IdOferta=${oferta.IdOferta}`)
    const [aula,setAula]=useState(null)
    const [malla,setMalla]=useState(null)
    const [idUnidadAsignatura,setIdUnidadAsignatura]=useState();
    const [docente,setDocente]=useState(null)
    const [newDate,setNewDate]=useState()
    const [listadoFechas, setListadoFechas] = useState([])
    const [modalHoras,setModalHoras]=useState();
    const [posicion,setPosicion]=useState();
    const [horaInicio,sethoraInicio]=useState();
    const [horaFin,sethoraFin]=useState();
    const [exitos, setExitos]=useState();
    const [fallos, setFallos]=useState();
    const [respuestas,setRespuestas]=useState([])
    const [isOpenModalRespuesta, setIsOpenModalRespuesta] = useState()

    const api= new FormService();

    const deleteNewDate = (indexSelected, fechaSelected) => {
        let copyListadoFechas = listadoFechas
        const filtered = copyListadoFechas.filter(
            (fecha, index) => index !== indexSelected
        )
        setListadoFechas([...filtered])
    }
    const onAddNewDate = () => {
        const dateAlreadyExist=listadoFechas.find((fecha)=>fecha===newDate)

        if (newDate!==null&&dateAlreadyExist===undefined&&newDate!== ''){
            let data={
                "dia":newDate,
                "horas":[]
            }
            console.log(data)
            setListadoFechas([...listadoFechas, data])

            console.log(listadoFechas)
        }
    }
    const consultarEventos=async()=>{
        const eventos=await api.getAll(`eventosAula/eventosCurso?IdOferta=${oferta.IdOferta}`)
        setOriginalEvents(eventos);
    }

    const agregarHora=(key)=>{
        setPosicion(key)
        setModalHoras(true)
    }

    const asginarFechas=()=>{
        console.log(posicion)
        let horario={
            "horaInicio":horaInicio,
            "horaFin":horaFin
        }

        let fechas=[...listadoFechas]
        let fechaActual=listadoFechas[posicion]
        fechaActual.horas.push(horario)

        // fechas=fechas.filter((item) => item !== listadoFechas[posicion]);

        console.log(fechas)
        setModalHoras();
        sethoraInicio()
        sethoraFin()        
    }

    const guardarHorarioMasivo=async()=>{

        if(aula==null||malla==null||docente==null||idUnidadAsignatura==null){
            alerta("Completa los campos requeridos")
            return
        }
        const data={
            "IdOferta":oferta.IdOferta,
            "IdAula":aula,
            "IdMalla":malla,
            "IdUnidadAsignatura":idUnidadAsignatura,
            "IdUsuario":docente,
            "fechas":listadoFechas
        }
        const respuestas=await api.create("eventosAula/horarioMasivo",data);
        let fallos=0;
        let exitos=0;
        
        for (let i = 0; i < respuestas.length; i++) {
            let element = respuestas[i];
            console.log(element)
            if (element.respuesta=='libre'){
                exitos++;
            }else{
                fallos++;
            }

        }
        setExitos(exitos)
        setFallos(fallos)
        setListadoFechas([])
        setAula(null)
        setMalla(null)
        setIdUnidadAsignatura(null)
        setDocente(null)
        console.log("actualizar")
        if (fallos>0) {
            setIsOpenModalRespuesta(true)
            setRespuestas(respuestas)
        }else{
            alertaSinBoton(`Se registraron ${exitos} eventos`, 'success', 'bottom-end', 1900)
        }

        setModalHorarioMasivo()
        consultarEventos()
        console.log(data)
    }

    const eliminarHoras=(key1,key2)=>{
        let fecha_selecionada=listadoFechas[key1]
        let horas=listadoFechas[key1].horas
        listadoFechas[key1].horas.splice(key2,1)

        console.log(listadoFechas)
        setListadoFechas([...listadoFechas])
    }

  return (
    <>
    <Modal
        show={horarioMasivo}
        onHide={()=>setModalHorarioMasivo()}
        fullscreen={true}
    >
    <Modal.Header closeButton>
        <Modal.Title>{oferta.Nombre}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <Card>
            <Row>
                <Col>
                    <Select
                        label={'Aulas'}
                        name={'IdAula'}
                        onChange={setAula}
                        value={aula}
                        optionName={'numero'}
                        optionValue={'IdAula'}
                        options={aulas}
                    ></Select>
                </Col>
                <Col>
                    <Select
                        label={'Asignatura'}
                        name={'IdMalla'}
                        onChange={setMalla}
                        value={malla}
                        optionName={'Asignatura'}
                        optionValue={'IdMalla'}
                        urlOptionsService={`malla?IdOferta=${oferta.IdOferta}`}
                    ></Select>
                </Col>
                <Col>
                    <Select
                        label={'Unidad'}
                        name={'IdUnidadAsignatura'}
                        onChange={setIdUnidadAsignatura}
                        value={idUnidadAsignatura}
                        optionName={'Nombre'}
                        optionValue={'IdUnidadAsignatura'}
                        urlOptionsService={`unidadesAsignaturas?IdMalla=${malla}`}
                    ></Select>
                </Col>
                <Col>
                    <SelectDataList
                        label={"Docente"}
                        optionValue={"IdUsuario"}
                        optionName={"Nombre"}
                        onChangeHandler={setDocente}
                        name={"IdUsuario"}
                        endPoinLastUrl={"docente/full"}
                        value={docente?.Nombre}
                    /> 
                </Col>
                <Col>
                <Row>
                    <Col>
                    <Input
                        label="Nuevo día"
                        required={false}
                        type={'date'}
                        name='newDate'
                        onChange={(value,name)=>setNewDate(value)}
                    ></Input>
                    </Col>
                    <Col>
                    <Button 
                        variant="success"
                        onClick={(e)=>onAddNewDate(e)}
                    >
                        Agregar día
                    </Button>
                    </Col>
                
                    </Row>
                </Col>
            </Row>
        </Card>

        <Accordion defaultActiveKey="0" flush>
        {listadoFechas.map((fecha,key)=>
            <>
            <Accordion.Item key={key} eventKey={key}>
                <Accordion.Header>
                <Button
                    variant="light"
                    onClick={()=>deleteNewDate(key)}
                >
                    <BsTrash/>
                </Button>                    
                    {fecha.dia}
                </Accordion.Header>
                <Accordion.Body>
                        <Row style={{padding:"30px"}}>
                            <Col xs={3 }>
                                <Button
                                    variant='light'
                                    onClick={()=>agregarHora(key)}
                                >
                                    <BsFillPlusCircleFill/> Agregar hora
                                </Button>
                            </Col>
                            <Col style={{display:"flex"}}>
                                {fecha.horas.map((horas,key2)=>
                                    <Card key={key2} style={{padding:"10px",marginLeft:"10px"}}>
                                        {horas.horaInicio} - {horas.horaFin}
                                        <Button
                                            onClick={()=>eliminarHoras(key,key2)}
                                            variant='light'
                                        >
                                            <BsTrash/>
                                        </Button>
                                    </Card>
                                )
                                }
                            </Col>
                        </Row>
                        
                </Accordion.Body>
            </Accordion.Item>
            </>
        )}
        </Accordion>
    </Modal.Body>
    <Modal.Footer>
        <Button
            variant='secondary'
            onClick={()=>setModalHorarioMasivo()}
        >Cancelar</Button>
        <Button
            onClick={guardarHorarioMasivo}
        >
            Guardar
        </Button>
    </Modal.Footer>
    </Modal>
    <Modal
        show={modalHoras}
        onHide={setModalHoras}
    >
        <Modal.Header closeButton>
            <Modal.Title>Horario del Evento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Input
                type="time" 
                label="Hora Inicial"
                value={horaInicio}
                onChange={sethoraInicio}
            />
            <Input
                type="time"  
                label="Hora Final"
                value={horaFin}
                onChange={sethoraFin}
            />
        </Modal.Body>

        <Modal.Footer>
            <Button
                variant='secondary'
                onClick={()=>setModalHoras()}
            >
                Cancelar
            </Button>
            <Button
                onClick={asginarFechas}
            >
                Asignar
            </Button>
        </Modal.Footer>
    </Modal>

    {/* Modal para mostrar respuesta al agregar evento */}
    <Modal
        show={isOpenModalRespuesta}
        onHide={()=>setIsOpenModalRespuesta(false)}
    >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <h3>Se registraron {exitos} eventos y fallaron {fallos} por los siguientes motivos</h3>
                {respuestas.map((respuesta, key)=>(
                    respuesta.respuesta=='ocupado'&&
                    <li className='lista-respuesta' key={key}> El aula se encuentra ocupada en la siguiente fecha {respuesta.fecha_inicio} a {respuesta.fecha_fin}</li>
                    ||
                    respuesta.respuesta=='rango'&&
                    <li className='lista-respuesta' key={key}> La asginación del siguente evento {respuesta.fecha_inicio} a {respuesta.fecha_fin} esta fuera del rango la actividad académica</li>
                    ||
                    respuesta.respuesta=='docente_ocupado'&&
                    <li className='lista-respuesta' key={key}>El docente o responsable se encuentra ocupado en la sigueinte fecha {respuesta.fecha_inicio} a {respuesta.fecha_fin} </li>                        
                    ||
                    respuesta.respuesta=="horas"&&
                    <li className='lista-respuesta'>La intensidad horaría de la asignatura se ha superado no se puede programar la siguiente fecha {respuesta.fecha_inicio} a {respuesta.fecha_fin} </li>
                    ))}          
        </Modal.Body>
    </Modal>
    </>
  )
}
