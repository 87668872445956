import React from 'react'
import { Card, Table } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { DynamicTable } from '../../../components/DynamicForm/dynamicTable/DynamicTable'
import { useFetchGet } from '../../../hooks/useFetchGet'
import Header from './Componentes/Header'


export default function HistorialContinuada({perfil=true}) {

    const usuario=JSON.parse(localStorage.getItem("usuario")).usuario


    const [data,loading,error]=new useFetchGet(`educacionIES/cursosContinuada?IdUsuario=${usuario.IdUsuario}`)
  return (
    <div style={{maxWidth:1000,margin:"0 auto",marginTop:40}}>
        {perfil&&
            <Header usuario={usuario}/>
        }
        <Card>
            <Card.Title>
                Historial Educación Continuada
            </Card.Title>
        </Card>
        <Table>
            <thead>
                <tr>
                    <th>Nombre</th>
                    <th>Fecha</th>
                    <th>Estado</th>
                    <th>Aprobó</th>
                </tr>
            </thead>
            <tbody>
                {data.map((curso,key)=>
                <tr key={key}>
                    <td>{curso.Nombre}</td>
                    <td>{curso.Fecha}</td>
                    <td>
                        {curso.IdEstadoOferta==2&&
                            <>Activo</>
                        }
                        {curso.IdEstadoOferta==3&&
                            <>Finalizado</>
                        }
                    </td>
                    <td>
                        {!curso.aprobado&&
                            <>Sin validar </>
                        }
                        {curso.aprobado==0&&
                            <>No</>
                        }
                        {curso.aprobado==1&&
                            <>Si</>
                        }
                    </td>
                </tr>
                )
                }
            </tbody>
        </Table>
    </div>
  )
}
