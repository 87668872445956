import { Button } from "react-bootstrap"
import { Card } from "../../../../../components/card/Card"
import { DynamicTable } from "../../../../../components/DynamicForm/dynamicTable/DynamicTable"
import {BASE_URL} from "../../../../../services/api/config";
import { useLocation } from "react-router-dom"
import DowloadFile from "../../../../../components/DowloadFile/DowloadFile";

const ListaCoordinadores=()=>{

    const{state:{ies,FechaInicio,FechaFin}}=useLocation();
    const enpoint=`instrumento/coordinadoresEvaluadores?FechaInicio=${FechaInicio}&FechaFin=${FechaFin}&ies=${ies}`

    const docente = `${BASE_URL}/exportar/CoordinadoresDocentes?FechaInicio=${FechaInicio}&FechaFin=${FechaFin}`;
    const docente_xlsx = `${BASE_URL}/exportar/excel/CoordinadoresDocentes?FechaInicio=${FechaInicio}&FechaFin=${FechaFin}`;
    const docente_ies = `${BASE_URL}/formatosIES/exportar/CoordinadoresDocentes?FechaInicio=${FechaInicio}&FechaFin=${FechaFin}`;
    const docente_ies_xlsx = `${BASE_URL}/ies/exportar/excel/CoordinadoresDocentes?FechaInicio=${FechaInicio}&FechaFin=${FechaFin}`;

    return (
        <>
            <main>
                <Card>
                    <h2>Coordinadores</h2>
                    <p>Perido: del {FechaInicio} al {FechaFin}</p>
                    <div style={{textAlign:"right"}}>
                        <DowloadFile
                            endPoint={
                                !ies?docente:docente_ies
                            }
                            name={`Exportar Calificaciones.pdf`}
                            nameFile={`Exportar calificaciones.pdf`}
                        />
                        <DowloadFile
                            endPoint={
                                !ies?docente_xlsx:docente_ies_xlsx
                            }
                            name={`Exportar Calificaciones.xlsx`}
                            nameFile={`Exportar calificaciones.xlsx`}
                        />
                    </div>
                </Card>
                
                
                <DynamicTable 
                    endPoint={enpoint}
                    titleColumn={["IdDocente","Nombre"]}
                    attributes={["Nombre"]}
                    onActions={false}
                    // titleColumn={'Id'}
                    labelOptionClick={"Exportar"}
                    handleOptionClick
                />
            </main>            
        </>
    )
}

export {ListaCoordinadores}