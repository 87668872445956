
export const ESTADO_DOCUMENTO = {
  NO_SE_HA_CARGADO_NADA:'0',
  APROBADO:'1',
  RECHAZADO:'2'
}

export const ETAPAS_INSCRIPCION = {

  DOCUMENTOS:{
      REQUISITO_TERMINADO:'1',
      REQUISITO_NO_TERMINADO:'0'
  },
  ADMISION:{
      ADMISION_TERMINADO:'1',
      ADMISION_NO_TERMINADO:'0'
  },
  MATRICULA:{
      ESTUDIANTE_TERMINADO:'1',
      ESTUDIANTE_NO_TERMINADO:'0'
  }
} 