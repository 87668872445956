import { Route, Routes } from 'react-router-dom'
import { UsuarioAspirantes } from './aspirantes/UsuariosAspirantes'
import { UsuarioGenerales } from './generales/UsuarioGenerales'
import { UsuarioFuncionarios } from './funcionarios/UsuarioFuncionarios'
import { UsuarioDocentes } from './docentes/UsuarioDocentes'
import { UsuarioEstudiantes } from './estudiantes/UsuarioEstudiantes'

import { Coordinadores} from './coordinadores/Coordinadores'
import Auditoria from './generales/Auditoria'

function Usuarios() {
    return (
        <>
            {/* USUARIOS-PERFILES PATH /perfiles  */}
            <Routes>
                <Route
                    path="funcionarios"
                    element={<UsuarioFuncionarios />}
                ></Route>
                <Route 
                  path="docentes" 
                  element={<UsuarioDocentes />}></Route>
                <Route path="generales" element={<UsuarioGenerales />}/>
                <Route path="generales/:id/auditoria" element={<Auditoria/>} />

                <Route              
                    path="estudiantes"
                    element={<UsuarioEstudiantes />}
                ></Route>
                <Route
                    path="aspirantes"
                    element={<UsuarioAspirantes></UsuarioAspirantes>}
                ></Route>
                <Route
                    path="coordinadores"
                    element={<Coordinadores />}
                ></Route>
            </Routes>
        </>
    )
}

export { Usuarios }
