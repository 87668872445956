import React from 'react'
import { useNavigate } from 'react-router-dom'

import { DynamicTable } from '../../components/DynamicForm/dynamicTable/DynamicTable' 
import { LocalStorage } from '../../services' 

const OfertasConvenios = () => {

    const navigate = useNavigate()
    const ls = new LocalStorage()
    const idUser = ls.getAsJsonItem('usuario').usuario.IdUsuario

    const titleColumnsTable = [
        'N°',
        'Programas y Actividades',
        'Fecha Incio',
        'Fecha Fin',
        'Cupos',
        'Acciones'
    ]

    const attributes = [
        'Nombre',
        'FechaInicioCurso',
        'FechaFinCurso',
        'Cupos',
    ]
    const onNavigateDetails = (item) => {
        navigate(
            `lista-aspirante/${item.IdOferta}`, 
            { state: { offer: item } }
        ) //to ApplicantList.jsx
    }
    
    return (
        <>
            <DynamicTable
                titleColumn={ titleColumnsTable }
                attributes={ attributes }
                endPoint={ `empresa/ofertasConvenios?IdUsuario=${idUser}` }
                onDelete={ false }
                onUpdate={ false }
                labelOptionClick={ 'Lista de aspirantes' }
                handleOptionClick={ onNavigateDetails }
                classNameOptionIcon='bi bi-view-list'
            />
        </>
    )
}

export { OfertasConvenios }
