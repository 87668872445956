import { Button, Col, ListGroup, Modal, Row } from "react-bootstrap"
import { Card } from "../../../../../components/card/Card"
import { DynamicTable } from "../../../../../components/DynamicForm/dynamicTable/DynamicTable"
import { useLocation,sue} from "react-router-dom"
import {BASE_URL} from "../../../../../services/api/config";
import { FormService } from "../../../../../services/api/formService";
import DowloadFile from "../../../../../components/DowloadFile/DowloadFile";
import { useState } from "react";
import { useFetchGet } from "../../../../../hooks/useFetchGet";

const ListaDocentes=()=>{

    const api= new FormService();
    const [modal,setModal]=useState();
    const [item,setItem]=useState();

    const {state:{ies,FechaInicio,FechaFin}}=useLocation();
    const enpoint=`instrumento/listaDocentes?FechaInicio=${FechaInicio}&FechaFin=${FechaFin}&ies=${ies}`

    const docentes_continua = `${BASE_URL}/exportar/calificacionesDocentes?FechaInicio=${FechaInicio}&FechaFin=${FechaFin}`;
    const docentes_continua_xlsx = `${BASE_URL}/exportar/excel/calificacionesDocentes?FechaInicio=${FechaInicio}&FechaFin=${FechaFin}`;
    const docentes_ies = `${BASE_URL}/formatosIES/exportar/calificacionesDocentes?FechaInicio=${FechaInicio}&FechaFin=${FechaFin}&ies=${ies}`;
    const docentes_ies_xlsx = `${BASE_URL}/ies/exportar/excel/calificacionesDocentes?FechaInicio=${FechaInicio}&FechaFin=${FechaFin}&ies=${ies}`;

    const reporte_docente = `${BASE_URL}/instrumento/reporteDocente?FechaInicio=${FechaInicio}&FechaFin=${FechaFin}&IdDocente=${item?.IdDocente}`;
    const cualitativas_docente = `${BASE_URL}/exportar/cualitativasDocente?FechaInicio=${FechaInicio}&FechaFin=${FechaFin}&IdDocente=${item?.IdDocente}`;

    const reporte_docente_ies = `${BASE_URL}/formatosIES/instrumento/reporteDocente?FechaInicio=${FechaInicio}&FechaFin=${FechaFin}&IdDocente=${item?.IdDocente}`;
    const cualitativas_docente_ies = `${BASE_URL}/formatosIES/exportar/cualitativaDocente?FechaInicio=${FechaInicio}&FechaFin=${FechaFin}&IdDocente=${item?.IdDocente}`;

    const [totalPromedioDocentes,error,loadin]=useFetchGet(`instrumento/totalPromedioDocentes?FechaInicio=${FechaInicio}&FechaFin=${FechaFin}&ies=${ies}`)

    return (
        <>
            <main>
                <Card>
                    <h2>Docentes</h2>
                    <p>Período: del {FechaInicio} al {FechaFin}</p>
                    {/* <div style={{textAlign:"right"}}> */}
                        <Row>
                            <Col>
                                <div>Indicador docente: {totalPromedioDocentes?.indicador_total}</div>
                            </Col>
                            <Col>
                                <DowloadFile 
                                    nameFile={"Docentes.pdf"}
                                    name={"Reporte Docentes.pdf"}
                                    endPoint={
                                        !ies?docentes_continua:docentes_ies
                                    }
                                />
                            </Col>
                            <Col>
                            <DowloadFile 
                                nameFile={"Docentes.xlsx"}
                                name={"Reporte Docentes.xlsx"}
                                endPoint={
                                    !ies?docentes_continua_xlsx:docentes_ies_xlsx
                                }
                            />
                            </Col>
                        </Row>
                        
                        
                    {/* </div> */}
                </Card>
                <DynamicTable 
                    endPoint={enpoint}
                    titleColumn={["IdDocente","Nombre","Acciones"]}
                    attributes={["Nombre"]}
                    labelOptionClick={"Exportables"}
                    handleOptionClick={(e)=>{
                        setItem(e)
                        setModal(true)
                    }}
                    onUpdate={false}
                    onDelete={false}
                />
            </main>
            <Modal 
                show={modal}
                onHide={()=>setModal()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Exportables Docentes
                    </Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                    <ListGroup>
                        <ListGroup.Item>
                            <DowloadFile 
                                endPoint={
                                    !ies?reporte_docente:reporte_docente_ies
                                }
                                nameFile={`Prodmedio Docente.pdf`}
                                name={"Promedio"}
                            />
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <DowloadFile
                                endPoint={
                                    !ies?cualitativas_docente:cualitativas_docente_ies
                                }
                                nameFile={`Cualitativas.pdf`}
                                name={"Cualitativas"}
                            />
                        </ListGroup.Item>
                    </ListGroup>
                </Modal.Body>
            </Modal>
        </>
    )
}

export {ListaDocentes}