import React, { useState } from 'react'
import { Button, Card, Col, Form, ListGroup, ListGroupItem, Modal, Row } from 'react-bootstrap'
import DowloadFile from '../../../components/DowloadFile/DowloadFile';
import { BASE_URL } from '../../../services/api/config'
import { Select } from '../../../components/select/Select';
import ModalFormatosAdmision from './ModalFormatosAdmision';


export default function FormatoCursoContinuada({IdOferta,oferta,fasesLista}) {
    const [modalDescargar,setModalDescargar]=useState();
    const [fechaInicio,setFechaInicio]=useState(oferta.FechaInicioCurso);
    const [fechaFin,setFechaFin]=useState(oferta.FechaFinCurso);
    const [modalHorario,setModalHorario]=useState();
    const [modalAsistencia, setModalAsistencia] = useState();
    const [malla, setMalla] = useState()


return (
    <>
        <Row>
            <Col>
                <Card>
                    <Button 
                        variant="outline-primary"
                        onClick={()=>setModalAsistencia(true)}
                    >
                        Control de Asistencia
                    </Button>
                </Card>
            </Col>
            <Col>
                <Card>
                    <DowloadFile
                        nameFile={"Calificaciones.pdf"}
                        name={"Calificaciones"}
                        endPoint={`${BASE_URL}/exportar/cursoFinalizado/calificaciones?IdOferta=${IdOferta}`}
                    />
                    {/* <a target="_blank" style={{textAlign:'center'}} href={`${BASE_URL}/exportar/cursoFinalizado/calificaciones?IdOferta=${IdOferta}`}>
                        <i className="bi bi-arrow-down"></i><br/>
                        Calificaciones                                    
                        </a> */}
                </Card>
            </Col>
            <Col>
                <Card>
                    <DowloadFile
                        nameFile={"Inscritos.xlsx"}
                        name={"Inscritos"}
                        endPoint={`${BASE_URL}/exportar/cursoFinalizado/inscritos?IdOferta=${IdOferta}`}
                    />
                    {/* <a target="_blank" style={{textAlign:'center'}} href={`${BASE_URL}/exportar/cursoFinalizado/inscritos?IdOferta=${IdOferta}`}>
                        <i className="bi bi-arrow-down"></i><br/>
                        Inscritos                                    
                        </a> */}
                    </Card>
            </Col>
        </Row>
        <Row>
            <Col>
            <Card>
                <Button 
                    variant="outline-primary"
                    onClick={()=>setModalHorario(true)}
                >
                    Horario del Curso
                </Button>
            </Card>
            </Col>
            <Col>
            <Card>
                <DowloadFile
                    nameFile={"Inscritos Eliminados.xlsx"}
                    name={"Inscritos Eliminados"}
                    endPoint={`${BASE_URL}/exportar/cursoFinalizado/inscritosEliminados?IdOferta=${IdOferta}`}
                />
            </Card>
            </Col>
            <Col>
                <Card>
                <DowloadFile
                    nameFile={"Estudiantes.xlsx"}
                    name={"Estudiantes"}
                    endPoint={`${BASE_URL}/exportar/cursoFinalizado/estudiantes?IdOferta=${IdOferta}`}
                />
                </Card>
            </Col>
        </Row>

        <Row>
            <Col>
            <Card>
                <DowloadFile
                    nameFile={"Informacion General del Curso.pdf"}
                    name={"Informacion General del Curso"}
                    endPoint={`${BASE_URL}/docente/sia?IdOferta=${IdOferta}`}
                />
            </Card>
            </Col>
            <Col>
                <Card>
                <DowloadFile
                    nameFile={"Promedios del Curso.pdf"}
                    name={"Promedios del Curso"}
                    endPoint={`${BASE_URL}/cursos/promedioCurso?IdOferta=${IdOferta}`}
                />
                </Card>
            </Col>
            <Col>
            <Card>
                <Button onClick={()=>setModalDescargar(true)}>
                    Otros Formatos
                </Button>
            </Card>
            </Col>
        </Row>
            <ModalFormatosAdmision
                modalDescargar={modalDescargar}
                setModalDescargar={setModalDescargar}
                IdOferta={IdOferta}
                fasesLista={fasesLista}
                oferta={oferta}
            />
            {/* filtra descarga del horario por fecha inicial y fecha fina en un modal    */}
            <Modal
                show={modalHorario}
                onHide={()=>setModalHorario(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Descarga Horario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Fecha Inicial</Form.Label>
                            <Form.Control type="date" value={fechaInicio} onChange={(e)=>setFechaInicio(e.target.value)}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Fecha Final</Form.Label>
                            <Form.Control type="date" value={fechaFin} onChange={(e)=>setFechaFin(e.target.value)}/>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="secondary" onClick={()=>setModalHorario(false)}>
                        Cerrar
                    </Button> */}
                    {/* <Button variant="primary" onClick={()=>descargarHorario()}>
                        Descargar
                    </Button> */}
                    {fechaInicio&&fechaFin&&
                        <DowloadFile
                            nameFile={"Horario del Curso.pdf"}
                            name={"Horario del Curso"}
                            endPoint={`${BASE_URL}/cursos/exportarHorario?IdOferta=${IdOferta}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`}
                        />
                    }
                    
                </Modal.Footer>
            </Modal>

            <Modal
                show={modalAsistencia}
                onHide={()=>setModalAsistencia(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Descarga Asistencia</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Select
                                id="IdMalla"
                                name="IdMalla"
                                value={malla}
                                onChange={ (e) => setMalla(e)}
                                label="Asignatura"
                                required={true}
                                optionValue="IdMalla"
                                optionName="Descripcion"
                                urlOptionsService={`asignaturasPrograma?IdOferta=${IdOferta}`}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="secondary" onClick={()=>setModalHorario(false)}>
                        Cerrar
                    </Button> */}
                    {/* <Button variant="primary" onClick={()=>descargarHorario()}>
                        Descargar
                    </Button> */}
                    {malla &&
                        <>
                        <DowloadFile 
                            nameFile={"Control de Asistencia Estudiante.pdf"}
                            name={"Control de Asistencia"}
                            endPoint={`${BASE_URL}/exportar/cursoFinalizado/asistencias?IdOferta=${IdOferta}&IdMalla=${malla}`}
                        />
                        </>
                    }
                </Modal.Footer>
            </Modal>
    </>
    )
}
