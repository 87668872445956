import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { Input } from '../../../components/input/input'
import { alerta } from '../../../helpers/alertas'
import { useUpdate } from '../../../hooks/useUpdate'
import { LocalStorage } from '../../../services'
import { FormService } from '../../../services/api/formService'
import Header from './Componentes/Header'
import { InputFile } from '../../../components/inputFile/inputFile'
import { BASE_SERVIDOR, BASE_URL } from '../../../services/api/config'

export default function ActualizarDatos() {
    const {state:{usuario}}=useLocation()
    const [userData, setUserData] = useState({})
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [ready, setReady] = useState(false)
    const [update, errorUpdate] = useUpdate('usuario')
    const localStorage = new LocalStorage()
    const [IdUsuario,setIdUsuario]=useState('');
    const api= new FormService()

    const {
        PrimerNombres,
        SegundoNombre,
        PrimerApellidos,
        SegundoApellidos,
        TelefonoContacto,
        email,
        Firma
    } = userData

    const fecthUserData = async () => {
        try {
            const formService = new FormService()
            const user = localStorage.getAsJsonItem('usuario')
            const ID = user?.usuario?.IdUsuario
            setIdUsuario(user?.usuario?.IdUsuario)
            const userDataFetched = await formService.getById('usuario', ID)
            setUserData(userDataFetched)
        } catch (err) {
            setError(err)
        }
    }

    useEffect(() => {
        const fecthData = async () => {
            await fecthUserData()
        }
        fecthData()
    }, [])

    const handleSudmit = async (e) => {
        e.preventDefault()
        try {
            setError(null)
            setReady(false)
            setLoading(true)
            const respuesta= await api.updateWithFormData("usuario",userData,userData.IdUsuario)
            if(respuesta.respuesta=="exito"){
                alerta(respuesta.mensaje)
                fecthUserData()
            }else{
                alerta("Fallo desconocido")
            }

            console.log('userData', userData)
            setReady(true)
            setLoading(false)
        } catch (err) {
            setError(err)
            setLoading(false)
        }
    }

    const handleChange = (value, name) => {
        setReady(false)
        setError(null)
        setUserData({
            ...userData,
            [name]: value
        })
    }
  return (
    <div style={{maxWidth:1000,margin:"0 auto",marginTop:40}}>
        <Header usuario={usuario}/>
        <form 
            onSubmit={ handleSudmit }
            style={{marginBottom:50}}
        >
            <Row>
                <Col>
                    <Input 
                        label='Primer Nombre'
                        type='text'
                        name='PrimerNombres'
                        value={ PrimerNombres || '' }
                        onChange={ handleChange }
                    />
                    <Input
                        required={false}
                        label='Segundo Nombre'
                        type='text'
                        name='SegundoNombre'
                        value={ SegundoNombre || '' }
                        onChange={ handleChange }
                    />
                    <Input 
                        label='Primer Apellido'
                        type='text'
                        name='PrimerApellidos'
                        value={ PrimerApellidos || '' }
                        onChange={ handleChange }
                    />
                    <div
                        style={{
                            padding: '10px',
                            textAlign: 'center',
                        }}
                    >
                        <img width={200} src={`${BASE_SERVIDOR}/firmas/${userData.Firma}`} alt="No hay firma" />
                    </div>
                </Col>
                <Col>
                    <Input
                        required={false} 
                        label='Segundo Apellido'
                        type='text'
                        name='SegundoApellidos'
                        value={ SegundoApellidos || '' }
                        onChange={ handleChange }
                    />
                    <Input 
                        label='Número Celular'
                        type='number'
                        name='TelefonoContacto'
                        value={ TelefonoContacto || '' }
                        onChange={ handleChange }
                    />
                    <Input 
                        label='Correo'
                        type='email'
                        name='email'
                        value={ email || '' }
                        onChange={ handleChange }
                    />
                    <InputFile 
                        name={'Firma'}
                        label={'Firma'}
                        required={false}
                        onChange={handleChange}
                        completo={true}
                    />
                    <p>Tenga en cuenta las siguientes medidas 332px por 152px para cargar su imagen</p>
                </Col>
            </Row>
            <div style={{textAlign:"right",paddingTop:15}}>
                <Button 
                    type='send'
                    loading={ loading }
                >
                    Guardar Cambios
                </Button>
            </div>
        </form>
</div>
  )
}
