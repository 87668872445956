import React from 'react'
import { AddButton } from '../../componentsForm/addButton/addButton'
import { DynamicTable } from '../../DynamicForm/dynamicTable/DynamicTable'
import { useNavigate } from 'react-router-dom'
import { LocalStorage } from '../../../services'

const Registration = () => {

    const navigate = useNavigate()
    const ls = new LocalStorage()
    const urlOffersApplied = `aspirantes/ofertas?IdUsuario=${ls.getAsJsonItem('usuario')?.usuario?.IdUsuario || 'localstorage_empty'}`
    const titleColumnsTable = [
        'N°',
        'Nombre de la oferta',
        'Acción'
    ]

    const attributes = [
        'Nombre',
    ]

    const onNavigateDetails = (item) => {
        navigate(
            'proceso-oferta/', 
            {
                state: {
                    offer: item,
                } 
            }
        ) //to EnrollmentProcess.jsx
    }

    return (
        <>
            <AddButton />
            <DynamicTable
                titleColumn={ titleColumnsTable }
                attributes={ attributes }
                endPoint={ urlOffersApplied }
                onDelete={ false }
                onUpdate={ false }
                labelOptionClick={ 'Ver detalles' }
                handleOptionClick={ onNavigateDetails }
                classNameOptionIcon='bi bi-view-list'
            />
        </>
    )
}

export { Registration }