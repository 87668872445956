import React from 'react'
// import './addButton.css'
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';

const AddButton = ({
    buttonTitle,
    createModal,
    formTitleValue
}) => {
  const menu = JSON.parse(localStorage.getItem("menu")) ?  JSON.parse(localStorage.getItem("menu")) : [];
  const dimensionActual = JSON.parse(localStorage.getItem("dimensionActual")) ? JSON.parse(localStorage.getItem("dimensionActual")) : [];

  const ruta=window.location.href;
  const paths=ruta.split("/")
  var modulo="";
  var ventana="";

  for (let i = 0; i < menu.length; i++){
    if(paths[4]===menu[i]["path_modulo"]){
      modulo=menu[i]["modulo"];
      let ventanas=menu[i]["ventanas"]
      for (let e = 0; e < ventanas.length; e++) { 
        if(paths[5]===ventanas[e]["path_ventana"]){
          ventana=ventanas[e]["descripcion"]
        }        
      }    
    }    
  }
  return (
    <>
    <Card>
      <Row>
        <Col>
          <Modal.Title>{ventana}</Modal.Title>
        </Col>
        <Col style={{textAlign:"right"}}>
          {buttonTitle && (
              <Button
                  onClick={ createModal }
              >
                    { buttonTitle }
              </Button>
            )}
        </Col>
      </Row>
    </Card>
    </>
  )
}

export {AddButton}