

export const ICON = {
  CHECKED:'✔️',
  UNCHECKED:'❌',
  CONGRA:'🎉',
  AIRPLANE:'✈️',
  PENCIL:'✏️',
  HAPPY:'',
  WAITING:''
}

