import { Route, Routes } from "react-router-dom"
import { DynamicFormContainer } from "../../../components/DynamicForm/DynamicFormContainer"
import { Admision } from "../../../components/outlet/admision/Admision"
import { Aspirante } from "../../../components/outlet/aspirantes/Aspirantes"
import { Estudiante } from "../../estudiante/Estudiante"



function AdmisionMatricula () {

  return (
    <Routes>
       <Route exact path="registro_estudiante" element={<Estudiante></Estudiante>} />
      <Route exact path="registro_aspirante" element={<Aspirante />} />
      <Route exact path="registro_admision" element={<Admision />} />
      <Route  path="admision_matricula" element = {<DynamicFormContainer idForm="37"/>}></Route>
      <Route  path="*" element = {<DynamicFormContainer />}></Route>
           

    </Routes>
  )
}
export { AdmisionMatricula }