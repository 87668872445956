import { useState } from "react"
import { Button, Col, Row } from "react-bootstrap"
import { Card } from "../../../components/card/Card"
import { Input } from "../../../components/input/input"
import { Title } from "../../../shared/components/title/Title"
import { FormService } from "../../../services/api/formService"
import { BASE_URL } from "../../../services/api/config"
import { alerta } from "../../../helpers/alertas"
import { useNavigate } from "react-router-dom"
import { DynamicTable } from "../../../components/DynamicForm/dynamicTable/DynamicTable"


const EstudianteHistorial=()=>{
    const api=new FormService();
    const Navigate= useNavigate();

    const[documento,setDocumento]=useState()
    const [Nombre, setNombre] = useState()
    const [endPoint, setEndPoint] = useState()

    const searchEstudiantes = () => {
        setEndPoint(`estudiante/consultar?NumeroDocumento=${documento}&Nombres=${Nombre}`)
    }

    const consultarEstudiante=async(estudiante)=>{
        Navigate("perfil_estudiante",{state:{estudiante:estudiante}})
    }

    return(
        <>
        <Card>
            <Title>
                Historial del Estudiante
            </Title>
            <p>Consultar estudiante por número de documento o Nombre</p>
        </Card>
        <Card>
            <Row>
                <Col>
                    <Input
                        label={'No de Documento:'}
                        onChange={(e)=>setDocumento(e)}
                        value={documento}
                    />
                </Col>
                <Col>
                    <Input
                        label={"Nombre: "}
                        type={'text'}
                        onChange={(e)=>setNombre(e)}
                        value={Nombre}
                    />
                </Col>
                <Col>
                    <br />
                    <br />
                    <div style={{textAlign: 'right'}}>
                        <Button
                            onClick={()=>searchEstudiantes()}
                        >
                            Buscar
                        </Button> 
                    </div>
                </Col>
            </Row>
        </Card>
        
        <DynamicTable
            titleColumn={["No", "Documento", "Primer Apellido", "Segundo Apellido", "Primer Nombre", "E-mail", "Acción"]}
            attributes={["NumeroDocumento", "PrimerApellidos", "SegundoApellidos", "PrimerNombres", "email"]}
            endPoint={endPoint}
            onUpdate={false}
            onDelete={false}
            labelOptionClick={"Ver más"}
            handleOptionClick={(e) => consultarEstudiante(e)}
        />
        </>
    )
}

export{EstudianteHistorial}