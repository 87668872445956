
import { Button, Card, Modal, Table } from "react-bootstrap"
import { Title } from "../../shared/components/title/Title"
import { FormService } from "../../services/api/formService"
import { useLocation } from "react-router-dom";
import { useEffect,useState } from "react";
import { BASE_SERVIDOR } from "../../services/api/config";
import { DynamicForm } from "../../components/DynamicForm/DynamicForm";
const Documentos=()=>{
    const api=new FormService();
    const Location=useLocation();
    const [documentos,setDocumentos]=useState([]);
    const [documentoActual,setDocumentoActual]=useState(null)
    const [modal,setModal]=useState(false)
    const [modalEliminar,setModalEliminar]=useState(false)
    const IdActividadBienestar=Location.state.actividad.IdActividadBienestar

    useEffect(()=>{
        const fetcData=async()=>{
            conusltarDocumentos()
        }
        fetcData()
        console.log(Location)
    },[])

    const conusltarDocumentos=async()=>{
        const documentos= await api.getAll(`bienestarRepositorio?IdActividadBienestar=${IdActividadBienestar}`)
        setDocumentos(documentos)
    }
    const reload=()=>{
        setTimeout(() => {
            conusltarDocumentos();
            setModal(false)
        }, 3000);
    }
    const eliminar=(e)=>{
        var data={"Estado":"0"}
        const respuesta=api.update("bienestarRepositorio",data,documentoActual.IdBienestarRepositorio)
        setModalEliminar(false)
        conusltarDocumentos()
    }

    return(
        <>
            <Card>
                <Title>Documentos de la actividad</Title>
                <div style={{textAlign:'right'}}>
                    <Button
                        onClick={()=>setModal(true)}
                    >Agregar documento</Button>
                </div>
            </Card>  
            <Card>
                <Table striped>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Documento</th>
                            <th>Enlace</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                        <tbody>
                            {documentos.map((documento,key)=>
                            <tr key={key}>
                                <td>{documento.Descripcion}</td>
                                <td>
                                    {documento.Archivo&&
                                    <a target="blank" href={`${BASE_SERVIDOR}/pdf/${documento.Archivo}`}>Ver documento</a>
                                    }
                                </td>
                                <td>
                                    {documento.Enlace?
                                    <a target="blank" href={documento.Enlace}>Ver enlace</a>
                                    :<></>
                                    }
                                </td>
                                <td>
                                    <Button 
                                        onClick={()=>{
                                            setModalEliminar(true)
                                            setDocumentoActual(documento)
                                        }}
                                    variant="danger">
                                        Eliminar
                                    </Button>
                                </td>

                            </tr>
                            )
                            }
                        </tbody>
                </Table>
            </Card>
            <Modal show={modal} onHide={()=>setModal(false)}>
                <Modal.Header closeButton>
                        <Modal.Title>
                        Agregar Documento
                        </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <DynamicForm
                            justDynamicForm = {{ flag: true, idForm: 93 }}
                            onClickCancelBtn={()=>setModal(false)}
                            isCreate={true}
                            endPointSubmit="bienestarRepositorio"
                            isContentTypeFormData="1"
                            extraData={{IdActividadBienestar:IdActividadBienestar}}
                            accionMysql={reload}
                        />
                </Modal.Body>
            </Modal>

            <Modal  show={modalEliminar} onHide={()=>setModalEliminar(false)}>
                <Modal.Body>
                    ¿Deseas eliminar este registro?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>setModalEliminar(false)}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={()=>eliminar()}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}


export {Documentos}