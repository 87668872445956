import React from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { DynamicTable } from '../../components/DynamicForm/dynamicTable/DynamicTable'
import { useNavigate, useParams } from 'react-router-dom';

export default function Funciones() {
    const {id}=useParams();
    const navigate=useNavigate()
  return (
    <>
        <Card>
            <Row>
                <Col>
                    <Card.Title> Funciones </Card.Title>
                </Col>
            </Row>
           
        </Card>
        <DynamicTable
            endPoint={`seguridadAerea/funcionesSaa?IdAreaSaa=${id}`}
            titleColumn={["Id","Codigo Área","Código","Nombre","Acciones"]}
            attributes={["IdFuncionSaa","CodigoArea","Codigo","Nombre"]}
            indice={false}
            onDelete={false}
            onUpdate={false}
            labelOptionClick={"Cursos Requeridos"}
            handleOptionClick={(e)=>{
                    navigate(`cursos_requeridos/${e.IdFuncionSaa}`);
            }}
        />
        {/* modal para agregar funcion */}
    </>
  )
}