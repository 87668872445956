import React, { useState } from 'react'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { DynamicTable } from '../../components/DynamicForm/dynamicTable/DynamicTable'
import { useFetchGet } from '../../hooks/useFetchGet'
import { FormService } from '../../services/api/formService'
import { alerta } from '../../helpers/alertas'

export default function Funcionario() {
    const location = useLocation()
    const api= new FormService()
    const funcionario = location.state.funcionario
    const url=`funcionarios/funciones?IdFuncionario=${funcionario.IdFuncionario}`
    const [endPoint, setEndPoint] = useState(url)
    const [show, setShow] = useState(false)
    const [idFuncion, setIdFuncion] = useState(null)
    const [funciones]=useFetchGet("funcionarios/listaFunciones")
    const [modalEliminar, setModalEliminar] = useState(false)
    const [modalActualizar, setModalActualizar] = useState(false)
    const [estadoFuncion, setEstadoFuncion] = useState(null)
    const [funcionActual, setFuncionActual] = useState(null)

    // agregar funcion
    const agregarFuncion = async() => {
        const data={
            IdFuncionario:funcionario.IdFuncionario,
            IdFuncion:idFuncion,
        }

        const res=await api.create("funcionarios/agregarFuncion", data)
        if(res.success){
            alerta(res.message)
            setShow(false)
            setEndPoint(`${url}&i=${Math.random()}`)
        }else{
            alerta(res.message,"error")
        }
    }
    const actualizarFuncion = async() => {
        const data={
            EstadoFuncion:estadoFuncion,
        }
        const res=await api.update("funcionarios/actualizarFuncion", data, funcionActual.IdUsuarioFuncion)
        if(res.success){
            alerta(res.message)
            setModalActualizar(false)
            setEndPoint(`${url}&i=${Math.random()}`)
        }else{
            alerta(res.message,"error")
        }
    }
    const eliminarFuncion = async() => {

        const res=await api.delete("funcionarios/eliminarFuncion", {}, funcionActual.IdUsuarioFuncion)
        if(res.success){
            alerta(res.message)
            setModalEliminar(false)
            setEndPoint(`${url}&i=${Math.random()}`)
        }else{
            alerta(res.message,"error")
        }
    }
  return (
    <>
        <Card>
            <Row>
                <Col>
                    <Card.Title as="h5">{funcionario.Nombre}</Card.Title>
                </Col>
                <Col
                    style={{
                        display: "flex",
                        justifyContent: "flex-end"
                    }}
                >
                    <Button 
                        onClick={()=>{setShow(true)}}
                        variant="primary"
                     >
                        Agregar Funcion
                    </Button>
                </Col>
            </Row>
        </Card>
        {/* mostrar en una tabla las funciones del funcionario */}
        <DynamicTable
            endPoint={endPoint}
            titleColumn={[ 'N°', 'Función', 'Estado', 'Acciones' ]}
            attributes={[ 'Nombre', 'EstadoFuncion' ]}
            elimimarExterno={(funcion)=>{
                setModalEliminar(true)
                setFuncionActual(funcion)
            }}
            actualizarExterno={(funcion)=>{
                setModalActualizar(true)
                setFuncionActual(funcion)
                setEstadoFuncion(funcion.EstadoFuncion)
            }}
        />
        {/* Modal para agregar funcion */}
        <Modal show={show} onHide={setShow}>
            <Modal.Header closeButton>
                <Modal.Title>Agregar Función</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Función</Form.Label>
                        <Form.Control 
                            as="select"
                            placeholder="Función" 
                            onChange={(e)=>setIdFuncion(e.target.value)}
                        >
                            <option>Seleccione una función</option>
      
                            {funciones.length!=0 && funciones.map((funcion, index)=>(
                                <option value={funcion.IdFuncionSaa} >{funcion?.Nombre}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={()=>setShow()}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={()=>agregarFuncion()}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
        {/* modal eliminar */}
        <Modal show={modalEliminar} onHide={setModalEliminar}>
            <Modal.Header closeButton>
                <Modal.Title>Eliminar Función</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                ¿Está seguro que desea eliminar la función?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={()=>setModalEliminar(false)}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={()=>eliminarFuncion()}>
                    Eliminar
                </Button>
            </Modal.Footer>
        </Modal>
        {/* modal actualizar */}
        <Modal show={modalActualizar} onHide={setModalActualizar}>
            <Modal.Header closeButton>
                <Modal.Title>Actualizar Función</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {/* solamente un campo para cambiar el estado */}
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Estado</Form.Label>
                        <Form.Control
                            as="select"
                            placeholder="Estado"
                            value={estadoFuncion}
                            onChange={(e)=>setEstadoFuncion(e.target.value)}
                        >
                            <option value="SIN ESTADO">SIN ESTADO</option>
                            <option value="ACTIVA">ACTIVA</option>
                            <option value="INACTIVA">INACTIVA</option>
                        </Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={()=>setModalActualizar(false)}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={()=>actualizarFuncion()}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    </>
  )
}
