import React, { useState, useEffect } from 'react';
import { Button, Card, Modal, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import TablePaginate from '../../../../components/TablePaginate/TablePaginate';
import { useLocation } from 'react-router-dom';

export default function Auditoria() {
  const [modalShow, setModalShow] = useState(false);
  const [auditoria, setAuditoria] = useState({});
  const [atributosAnteriores, setAtributosAnteriores] = useState([]);
  const [atributosActuales, setAtributosActuales] = useState([]);
    const [estadoActual, setEstadoAnterior] = useState([]);
  const [estadoAnterior, setEstadoActual] = useState([]);
  const location = useLocation();
  const usuario = location.state.usuario;

  const fetchAuditoriaData = async () => {
      const response = await fetch(`usuario/auditoria?IdUsuario=${usuario.IdUsuario}`);
      setAuditoria(response);
  };


  const handleVerClick = (rowData) => {
    try {
      setModalShow(true);
      setAuditoria(rowData);
  
      // Parsear estado_anterior y estado_actual de JSON a objetos JavaScript
      const estadoAnteriorObj = JSON.parse(rowData.estado_anterior);
      const estadoActualObj = JSON.parse(rowData.estado_despues);
  
      // Obtener un conjunto de todas las claves únicas de ambos objetos
      const todasLasClaves = new Set([...Object.keys(estadoAnteriorObj), ...Object.keys(estadoActualObj)]);
  
      // Mapear sobre el conjunto de claves
      const atributosAnterioresArray = Array.from(todasLasClaves).map((key) => ({
        atributo: key,
        antes: estadoAnteriorObj[key] !== undefined ? estadoAnteriorObj[key] : 'N/A',
        despues: estadoActualObj[key] !== undefined ? estadoActualObj[key] : 'N/A',
      }));
  
      setAtributosAnteriores(atributosAnterioresArray);
      setAtributosActuales([]);
    } catch (error) {
      console.error('Error parsing JSON data:', error);
      // Puedes manejar el error según tus necesidades
    }
  };
  
  

  useEffect(() => {
    fetchAuditoriaData();
  }, [usuario.IdUsuario]);

  return (
    <>
      <Card>
        <Card.Title>Auditoria</Card.Title>
        <p>{usuario.PrimerNombres} {usuario.PrimerApellidos}</p>
      </Card>

       <TablePaginate 
        apiUrl={`usuario/auditoria?IdUsuario=${usuario.IdUsuario}`}
        tableHeaders={[
          {"title": "Fecha", "attribute": "created_at"},
          {"title": "Acción", "attribute": "accion"},
        ]}
        rowActions={[
          ({ rowData }) => {
            return (
              <Button
                variant="primary"
                onClick={() => {
                  setModalShow(true);
                  setAuditoria(rowData);
                  handleVerClick(rowData);
                }}
              >
                Ver
              </Button>
            );
          },
        ]}
      />

      {/* modal para mostrar los cambios */}
      <Modal 
        size='xl'
        show={modalShow} 
        onHide={() => setModalShow(false)}
       >
        <Modal.Header closeButton>
          <Modal.Title>Auditoria </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Table>
          <thead>
            <tr>
              <th>Atributo</th>
              <th>Valor Anterior</th>
              <th>Valor Nuevo</th>
            </tr>
          </thead>
          <tbody>
          {atributosAnteriores.map((item, index) => (
            <tr key={index}>
              <td>{item.atributo}</td>
              <td style={{ backgroundColor: item.antes !== item.despues ? 'yellow' : 'transparent' }}>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id={`tooltip-antes-${index}`}>{item.antes}</Tooltip>}
                >
                  <span>{typeof item.antes === 'string' ? item.antes.slice(0, 30) : item.antes}</span>
                </OverlayTrigger>
              </td>
              <td style={{ backgroundColor: item.antes !== item.despues ? 'yellow' : 'transparent' }}>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id={`tooltip-despues-${index}`}>{item.despues}</Tooltip>}
                >
                  <span>{typeof item.despues === 'string' ? item.despues.slice(0, 30) : item.despues}</span>
                </OverlayTrigger>
              </td>
            </tr>
          ))}

          </tbody>
        </Table>
        {/* Puedes agregar un mensaje si atributosAnteriores está vacío */}
        {atributosAnteriores.length === 0 && <p>No hay datos disponibles.</p>}
      </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

