import React, { useState } from 'react'
import { alertaTimer } from '../../helpers/alertas'
import { Message } from '../Message/Message'

const ErrorHandling = ({ bugs }) => {

    return (
        <>
            {
                Array.isArray(bugs) && bugs.slice(0, 4).map((bug, key) => (
                    <Message 
                        key={ key }
                        type='error'
                    >
                        { `Error: ${bug.message}` }
                    </Message>
                ))           
            }
        </>
    )
    // status?.status === 'Token is Expired' && (
    //     alertaTimer('La sesion ha expirado', 'info', '4000')
    // )
}

export { ErrorHandling }