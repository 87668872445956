import React, { useRef } from 'react'
import FullCalendar from '@fullcalendar/react' 
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

import esLocale from '@fullcalendar/core/locales/es'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useEffect } from 'react'
import './Horario.css'
import { FormService } from '../../../services/api/formService'
import { alerta, alertaSinBoton, alertaTimer } from '../../../helpers/alertas'
import { Accordion, Button, Card, Col, Form, Modal, ProgressBar, Row, Table } from 'react-bootstrap' 
import { FormEvento } from './FormEvento'
import { SelectDataList } from '../../../components/SelectDataList/SelectDataList'
import { optionsDate } from '../../../shared/util/date'
import HorarioMasivo from './HorarioMasivo'
import { useFetchGet } from '../../../hooks/useFetchGet'
import { Input } from '../../../components/input/input'
import { BASE_URL } from '../../../services/api/config'
import DowloadFileText from '../../../components/DowloadFileText/DowloadFileText'
import { BsFileEarmarkPdfFill,BsFileEarmarkExcelFill } from 'react-icons/bs';

export default function Horario({
    editable=null,
    cordinador=null
}) {
    const { state } = useLocation()
    const api = new FormService()
    const navigate=useNavigate()
    const [isOpenModalAsignacion, setIsOpenModalAsignacion] = useState()
    const [isOpenModalDelete, setIsOpenModalDelete] = useState()
    const [itemEventToDelete, setItemEventToDelete] = useState()
    const [itemSelected, setItemSelected] = useState()
    const [dateStrSelected, setDateStrSelected] = useState()
    const [originalEvents, setOriginalEvents] = useState([])
    const [loading, setLoading] = useState(false)
    const [eventos, setEventos]=useState([]);
    const [exitos, setExitos]=useState();
    const [fallos, setFallos]=useState();
    const [evento,setEvento]=useState();
    const [IdDocente,setIdDocente]=useState(null)
    const [eventoActual,setEventoActual]=useState();
    const [modalEditar,setModalEditar]=useState();
    const [isOpenModalRespuesta, setIsOpenModalRespuesta] = useState()
    const [html,setHtml]=useState();
    const [isUpdate,setsUIpdate]=useState(null)
    const[horarioMasivo,setModalHorarioMasivo]=useState();
    const [visibleProgreso,setVisibleProgreso]=useState(false)
    const calendarComponentRef = useRef();
    const [horarioProgreso]=useFetchGet(`eventosAula/progresoHorario?IdOferta=${state.programa.IdOferta}`);

    const [fechaInicio,setFechaInicio]=useState(state.programa.FechaInicioHorario);
    const [fechaFin,setFechaFin]=useState(state.programa.FechaFinHorario);
    const [modalFijarHorario,setModalFijarHorario]=useState();
    const [modalExportarHorario,setModalExportarHorario]=useState();
    const location=useLocation();
    // const curso=location.state.programa

    const [formData, setFormData] = useState({
        horaInicial:null,
        horaFinal:null,
        IdPlandeEstudio: '',
        IdUsuario: '',
        IdOferta:0,
        fechaInicial: dateStrSelected,
        fechaFinal: dateStrSelected,
        Observaciones: '',
        eventoPersonalizado:'',
        fechas: [],
        IdMalla: '',
    })

    useEffect(() => {
        (async () => {
            gotoPast();
            console.log(state)
            const originalEv = await api.getAll( `eventosAula/eventosCurso?IdOferta=${state.programa.IdOferta}`)
            if (originalEv?.data) {
                setOriginalEvents(originalEv.data.eventos)
            } else {
                setOriginalEvents(originalEv)
                console.log({ originalEv })
            }
        })()
    }, [isOpenModalDelete, isOpenModalAsignacion])

    const eventDataTransform = (event) => {
        return {
            id: event.IdEventoAula,
            title: event.Asignatura + ', ' + event.Docente,
            start: event.FechaInicio.replace(' ', 'T'),
            end: event.FechaFin.replace(' ', 'T'),
            allDay: false,
            classNames: ['calendar__event'],
        }
    }

    const handleDateClick = (arg) => {
        console.log(arg.dateStr)
        const fechaSelecionada= new Date(arg.dateStr)
        const fechaInicio= new Date(state.programa.FechaInicioCurso)
        const fechaFin= new Date(state.programa.FechaFinCurso)
        if(fechaSelecionada<fechaInicio||fechaSelecionada>fechaFin){
            alerta("Fecha seleccionada fuera de rango")
            return;
        }

        setsUIpdate(false)
        setFormData([])
        setItemSelected({
            date: arg.date,
            title: state?.item?.Edificio + ', aula ' + state?.item?.numero,
            item: state.item,
        })
        setDateStrSelected(arg.dateStr)
        setIsOpenModalAsignacion(true)

    }

    const onAddEvent = async (event, formData) => {
        setExitos(0)
        setFallos(0)
        setEventos([])
        try {
            setLoading(true)
            let getEventos=[]
            if(!isUpdate){
                getEventos = await api.create('eventosAula', event)
                console.log("creando¡¡¡¡")
            }else{
                getEventos = await api.update('eventosAula', event,eventoActual.IdEventoAula)
            }
            setEventos(getEventos);
            let fallos=0;
            let exitos=0;
            let html="";
            console.log(getEventos)
            for (let i = 0; i < getEventos.length; i++) {
                let element = getEventos[i];
                console.log(element)
                if (element.respuesta=='libre'){
                    exitos++;
                }else{
                    fallos++;
                }

            }
            setHtml(html)
            setExitos(exitos)
            setFallos(fallos)

            if (fallos>0) {
                setIsOpenModalRespuesta(true)
                setLoading(false)
                return false
            }else{
                setIsOpenModalAsignacion(false)
                alertaSinBoton(`Se registraron ${exitos} eventos`, 'success', 'bottom-end', 1900)
                setLoading(false)
                return true
            }
        } catch (error) {
            console.log('error addevento aula:', error)
            setLoading(false)
        }
    }

    const onEventClick = async (info) => {
        console.log(info)
        setEvento(false)
        setItemEventToDelete(info.event)
        const evento= await api.getAll(`eventosAula/evento?IdEventoAula=${info.event.id}`)
        setEventoActual(evento)
        setModalEditar(true)
        consultarDocentesEvento(info.event.id)
    }

    const onDeleteEvent = async () => {
        try {
            const respuesta= await api.delete('eventosAula', { Estado: '0' }, itemEventToDelete.id)
            if(respuesta.respuesta=="exito"){
                alerta(respuesta.mensaje)
            }else{
                alerta(respuesta.mensaje)
            }

            setIsOpenModalDelete(false)
            setModalEditar(false)
        } catch (error) {
            console.log('Error to delete', itemEventToDelete.id)
        }
    }

    const onCloseModal = async () => {
        setIsOpenModalDelete(false)
        setIsOpenModalAsignacion(false)
        setIsOpenModalRespuesta(false)
    }
    const consultarDocentesEvento=async(IdEventoAula)=>{

        const evento= await api.getAll(`eventosAula/evento?IdEventoAula=${IdEventoAula}`)
        setEvento(evento)
    }
    const agregarDocente=async()=>{
        if(IdDocente){
            const data ={
                "IdEventoAula":eventoActual.IdEventoAula,
                "IdDocente":IdDocente
            }
            const respuesta= await api.create("eventosAula/agregarDocenteEvento",data)
            if(respuesta.respuesta=="exito"){
                consultarDocentesEvento(eventoActual.IdEventoAula)
            }else if(respuesta.respuesta=="error"){
                alerta(respuesta.mensaje,"error")
            }else{
                alerta("Fallo desconocido","error")
            }
        }else{
            alerta("Busca y selecciona un docente")
        }
    }
    
    const eliminarDocenteEvento=async(IdEventoAulaDocente,IdEventoAula)=>{
        const data={"IdEventoAulaDocente":IdEventoAulaDocente}
        const respuesta=await api.create("eventosAula/eliminarDocenteEvento",data)

        if(respuesta.respuesta=="exito"){
            const evento= await api.getAll(`eventosAula/evento?IdEventoAula=${IdEventoAula}`)
            setEvento(evento)
        }
    }

    const onEditar=()=>{
        setIsOpenModalAsignacion(true)
        setsUIpdate(true)
        const fechaInicial= new Date(eventoActual.FechaInicio)
        const horaInicial=fechaInicial.toLocaleTimeString('en-GB').toString()

        const FechaFin= new Date(eventoActual.FechaFin)
        const horaFin=FechaFin.toLocaleTimeString('en-GB').toString()
        setFormData({...eventoActual, "horaFinal":horaFin.slice(0, -3),"horaInicial": horaInicial.toString().slice(0, -3)})
        setDateStrSelected(eventoActual.FechaInicio.toString().slice(0, -9))


    }

    // guardar evento masivo
    const gotoPast = () => {
        let calendarApi = calendarComponentRef.current.getApi();
        calendarApi.gotoDate(state.programa.FechaInicioCurso||null);
    };

    const porcentajeProgreso=(horas,asignadas)=>{
        let  porcentaje=asignadas*100/horas
        return porcentaje
    }

    // fijar horario
    const fijarHorario = async () => {
        const data={
            FechaFinHorario:fechaFin,
            FechaInicioHorario:fechaInicio
        }
        const respuesta= await api.update("ofertas",data,state.programa.IdOferta)
        if(respuesta.respuesta=="exito"){
            alerta("Se ha fijado el horario","success")
            setModalFijarHorario(false)
            navigate(-1)
        }else{
            alerta("Fallo al fijar horario","error")
        }
    }

    return (
        <>
            <Card>
                <Row>
                    <Col sm="8">
                        <Card.Title>
                            {state.programa.Nombre}
                        </Card.Title>
                        <div>Inicia: {state.programa.FechaInicioCurso} Finaliza: {state.programa.FechaFinCurso}</div> 
                    </Col>
                    <Col style={{textAlign:"right"}}>
                    {console.log (state.programa)}
                    <Button
                            variant='primary'
                            style={{ margin: '3px' }}
                            onClick={()=>setModalExportarHorario(true)}
                        >
                            Exportar
                        </Button>
                    {/* {state.programa.IdDimension == 1?
                        <Button
                            variant='secondary'
                            style={{ margin: '3px' }}
                            onClick={() => document.querySelector('.Asistenciaspdf').click()}
                        >
                            <DowloadFileText
                                nameFile={"Horario.pdf"}
                                name={"Descargar"}
                                colortext={"#ffffff"}
                                icon={<BsFillFilePdfFill />}
                                classname="Asistenciaspdf"
                                endPoint={`${BASE_URL}/cursos/exportarHorario?IdOferta=${state.programa.IdOferta}&fechaInicio=${state.programa.FechaInicioCurso}&fechaFin=${state.programa.FechaFinCurso}`}
                            />
                        </Button>
                        :
                        <Button
                            variant='secondary'
                            style={{ margin: '3px' }}
                            onClick={() => document.querySelector('.Asistenciaspdf').click()}
                        >
                            <DowloadFileText
                                nameFile={"Horario.pdf"}
                                name={"Descargar"}
                                colortext={"#ffffff"}
                                icon={<BsFillFilePdfFill />}
                                classname="Asistenciaspdf"
                                endPoint={`${BASE_URL}/cursos/exportarHorario?IdOferta=${state.programa.IdOferta}&fechaInicio=${state.programa.FechaInicioCurso}&fechaFin=${state.programa.FechaFinCurso}`}
                            />
                        </Button>
                    } */}
                        <Button
                            onClick={()=>setModalHorarioMasivo(true)}
                            style={{ margin: '3px' }}
                        >
                            Horario masivo
                        </Button>
                        {/* boton para abrir el modal de fijar horario */}
                        <Button
                            onClick={()=>setModalFijarHorario(true)}
                            style={{ margin: '3px' }}
                        >
                            Fijar horario
                        </Button>

                    </Col>
                </Row>        
            </Card>
            <Row>
                <Col sm="4">
                    <div
                        style={{float:"left",paddingLeft:10,paddingRight:10}}
                    >
                        {' '}
                        Asignadas: {horarioProgreso?.curso?.asignadas}{' h '}
                        Intensidad: {horarioProgreso?.curso?.horas}{' h '}
                        
                    </div>                    
                    <Form.Check 
                        style={{float:"left"}}
                        type="switch"
                        id="custom-switch"
                        label=""
                        onClick={(e)=>{setVisibleProgreso(e.target.checked)}}
                    />
                </Col>
                <Col>
                <ProgressBar 
                    now={porcentajeProgreso(horarioProgreso?.curso?.horas,horarioProgreso?.curso?.asignadas)}  
                    label={`${porcentajeProgreso(horarioProgreso?.curso?.horas,horarioProgreso?.curso?.asignadas)}% `} 
                />
                </Col>
            </Row>
            {visibleProgreso&&
                <div>
                    {horarioProgreso.asignaturas&&horarioProgreso?.asignaturas.map((asignatura,key)=>
                        <Row key={key}
                            style={{borderBottom:'1px solid #ccc',padding:2}}
                        >
                            <Col>
                                <span style={{marginLeft:10,fontSize:14,color:'#bbb'}}>
                                {asignatura?.Asignatura}
                                </span>
                            </Col>
                            <Col>
                            <span
                                style={{float:'left',fontSize:14,minWidth:60}}
                            >
                                {asignatura.asignadas}/{asignatura.horas} 
                            </span>
                            <ProgressBar
                                // style={{float:'left'}}
                                variant="secondary" 
                                now={porcentajeProgreso(asignatura?.horas,asignatura?.asignadas)}  
                                label={` ${porcentajeProgreso(asignatura?.horas,asignatura?.asignadas)}% `} 
                            />
                            </Col>
                        </Row>
                    )}
                </div>
            }
            
            <Card>
                    <FullCalendar
                        ref={calendarComponentRef}
                        plugins={[
                            dayGridPlugin,
                            timeGridPlugin,
                            interactionPlugin,
                        ]}
                        dateClick={handleDateClick}
                        initialView="dayGridMonth"
                        locale={esLocale}
                        eventClick={onEventClick}
                        displayEventEnd={true}
                        eventTimeFormat={{
                            hour: '2-digit',
                            minute: '2-digit',
                            meridiem: true,
                            hour12: true,
                        }}
                        themeSystem={'bootstrap5'}
                        headerToolbar={{
                            right: 'prev,next today',
                            left: 'title',
                            center: 'dayGridMonth,timeGridWeek,timeGridDay',
                        }}
                        events={originalEvents}
                        eventDataTransform={eventDataTransform}
                    />
            </Card>

            {/* Modal para gestionar evento */}
            <Modal 
                show={modalEditar}
                onHide={()=>setModalEditar(false)}
                size="lg"
            >
                <Modal.Header closeButton>                
                        <Modal.Title>
                            Gestionar Evento
                        </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>Programa: {eventoActual?.NombrePlanEstudio}</div>
                    <div>Asignatura: {eventoActual?.Asignatura}</div>
                    <div>Docente: {eventoActual?.Nombre}</div>
                    <div>
                        <Button 
                            variant='danger'
                            onClick={()=>setIsOpenModalDelete(true)}
                        >
                        Eliminar
                        </Button>
                        {' '}
                        <Button                            
                            onClick={()=>onEditar()}
                        >
                        Editar
                        </Button>
                    </div>
                    <div style={{marginTop:20}}>
                        <h5>Otros Docentes</h5>
                        <Row>
                            <Col>
                                <SelectDataList
                                    optionValue={"IdDocente"}
                                    optionName={"Nombre"}
                                    onChangeHandler={(e)=>setIdDocente(e)}
                                    name={"IdDocente"}
                                    endPoinLastUrl={"docente/full"}
                                    value={""}
                                    placeholder="Docente"
                                /> 
                            </Col>
                            <Col><Button onClick={()=>agregarDocente(evento?.IdEventoAula)}>Agregar</Button> </Col>
                        </Row> 
                        <div style={{marginTop:20}}>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Docente</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {evento && evento?.docentes.map((docente)=>
                                    <tr>
                                        <td>{docente.Nombre}</td>
                                        <td><Button 
                                            onClick={()=>eliminarDocenteEvento(docente.IdEventoAulaDocente,evento.IdEventoAula)}
                                        variant='danger'>Eliminar</Button></td>
                                    </tr>
                                    )
                                    }
                                </tbody>
                            </Table>

                        </div>
                         
                    </div>                        
                </Modal.Body>
            </Modal>

            {/* Modal para eliminar evento */}
            <Modal
                show={isOpenModalDelete}
                onHide={onCloseModal}
            >
                <Modal.Header>
                    <Modal.Title>
                        ¿ Deseas eliminar el evento asignado a "{' '}
                        {itemEventToDelete?.title} " ?     
                    </Modal.Title>                                                                           
                </Modal.Header>
                <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={()=>onCloseModal()}>
                            Cancelar
                        </Button>
                        <Button
                        variant="danger"
                        onClick={onDeleteEvent}>
                            Eliminar
                        </Button>
                    </Modal.Footer>
            </Modal>


            {/* Modal para asignar evento */}
            <Modal
                show={isOpenModalAsignacion}
                onHide={()=>setIsOpenModalAsignacion(false)}    
            >
            <Modal.Header closeButton>
                <Modal.Title>
                {itemSelected?.date.toLocaleDateString(
                    'es-co',
                    optionsDate
                    )
                }
                </Modal.Title>
            </Modal.Header>
                    <FormEvento
                        oferta={state.programa}
                        isUpdate={isUpdate}
                        formData={{...formData}}
                        fecha_actual={eventoActual?.FechaInicio.slice(0,-9)}
                        setFormData={setFormData}
                        itemSelected={itemSelected}
                        dateStrSelected={dateStrSelected}
                        loading={loading}
                        onCloseModal={onCloseModal}
                        onAddEvent={onAddEvent}
                        cordinador={cordinador}
                    />
            </Modal>

            {/* Modal para mostrar respuesta al agregar evento */}
            <Modal
                show={isOpenModalRespuesta}
                onHide={()=>setIsOpenModalRespuesta(false)}
            >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <h3>Se registraron {exitos} eventos y fallaron {fallos} por los siguientes motivos</h3>
                    {eventos.map((evento, key)=>(
                        evento.respuesta=='ocupado'&&
                        <li className='lista-respuesta' key={key}> El aula se encuentra ocupada en la siguiente fecha {evento.fecha_inicio} a {evento.fecha_fin}</li>
                        ||
                        evento.respuesta=='rango'&&
                        <li className='lista-respuesta' key={key}> La asignación del siguiente evento {evento.fecha_inicio} a {evento.fecha_fin} esta fuera del rango de la actividad académica</li>
                        ||
                        evento.respuesta=='docente_ocupado'&&
                        <li className='lista-respuesta' key={key}>El docente o responsable se encuentra ocupado en la sigueinte fecha {evento.fecha_inicio} a {evento.fecha_fin} </li>                        
                        ||
                        evento.respuesta=="horas"&&
                        <li className='lista-respuesta'>La intensidad horaria de la asignatura se ha superado no se puede programar la siguiente fecha {evento.fecha_inicio} a {evento.fecha_fin} </li>
                        ||
                        evento.respuesta=="usuario"&&
                        <li className='lista-respuesta'>No ha seleccionado un docente para este evento {evento.fecha_inicio} a {evento.fecha_fin} </li>
                        ))}          
            </Modal.Body>
            </Modal>

            {/* <Modal
                show={horarioMasivo}
                onHide={()=>setModalHorarioMasivo()}
                fullscreen={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{state.programa.Nombre}</Modal.Title>
                </Modal.Header> */}
                <HorarioMasivo
                    horarioMasivo={horarioMasivo}
                    oferta={state.programa}
                    setModalHorarioMasivo={setModalHorarioMasivo}
                    setOriginalEvents={setOriginalEvents}
                />
            {/* </Modal> */}

            {/* modal para fijar horario */}
            <Modal
                show={modalFijarHorario}
                onHide={()=>setModalFijarHorario(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Fijar Horario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        type="date"
                        name="FechaInicio"
                        label="Fecha Inicio"
                        value={fechaInicio}
                        onChange={(e)=>setFechaInicio(e)}
                    />
                    <Input
                        type="date"
                        name="FechaFin"
                        label="Fecha Fin"
                        value={fechaFin}
                        onChange={(e)=>setFechaFin(e)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={()=>setModalFijarHorario(false)}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="primary"
                        onClick={()=>fijarHorario()}
                    >
                        Fijar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* modal para Exportar Horarios */}
            <Modal
                show={modalExportarHorario}
                onHide={()=>setModalExportarHorario(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Exportar Horario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <>
                    {console.log (state.programa)}
                    {state.programa.IdDimension == 1?
                    <>
                        <Button
                            variant='primary'
                            style={{ margin: '3px'  }}
                            onClick={() => document.querySelector('.horarioIESpdf').click()}
                            className="w-100"
                        >
                            <DowloadFileText
                                nameFile={"Horario.pdf"}
                                name={"Descargar.pdf"}
                                colortext={"#ffffff"}
                                icon={<BsFileEarmarkPdfFill />}
                                classname="horarioIESpdf"
                                endPoint={`${BASE_URL}/cursos/exportarHorarioDetalladoIES?IdOferta=${state.programa.IdOferta}&fechaInicio=${state.programa.FechaInicioCurso}&fechaFin=${state.programa.FechaFinCurso}`}
                            />
                        </Button>
                        <Button
                            variant='primary'
                            style={{ margin: '3px'  }}
                            onClick={() => document.querySelector('.horarioIESxlsx').click()}
                            className="w-100"
                            >
                            <DowloadFileText
                                nameFile={"Horario.xlsx"}
                                name={"Descargar.xlsx"}
                                colortext={"#ffffff"}
                                icon={<BsFileEarmarkExcelFill />}
                                classname="horarioIESxlsx"
                                endPoint={`${BASE_URL}/excel/exportarHorarioDetalladoExcel?IdOferta=${state.programa.IdOferta}&fechaInicio=${state.programa.FechaInicioCurso}&fechaFin=${state.programa.FechaFinCurso}`}
                            />
                        </Button>
                    </>
                        :
                    <>
                        <Button
                            variant='primary'
                            style={{ margin: '3px'}}
                            onClick={() => document.querySelector('.horarioContinuadapdf').click()}
                            className="w-100"
                        >
                            <DowloadFileText
                                nameFile={"Horario.pdf"}
                                name={"Descargar.pdf"}
                                colortext={"#ffffff"}
                                icon={<BsFileEarmarkPdfFill />}
                                classname="horarioContinuadapdf"
                                endPoint={`${BASE_URL}/cursos/exportarHorario?IdOferta=${state.programa.IdOferta}&fechaInicio=${state.programa.FechaInicioCurso}&fechaFin=${state.programa.FechaFinCurso}`}
                            />
                        </Button>
                        <Button
                            variant='primary'
                            style={{ margin: '3px'  }}
                            onClick={() => document.querySelector('.horarioContinuadaxlsx').click()}
                            className="w-100"
                            >
                            <DowloadFileText
                                nameFile={"Horario.xlsx"}
                                name={"Descargar.xlsx"}
                                colortext={"#ffffff"}
                                icon={<BsFileEarmarkExcelFill />}
                                classname="horarioContinuadaxlsx"
                                endPoint={`${BASE_URL}/excel/exportarHorarioDetalladoExcel?IdOferta=${state.programa.IdOferta}&fechaInicio=${state.programa.FechaInicioCurso}&fechaFin=${state.programa.FechaFinCurso}`}
                            />
                        </Button>
                    </>
                    }
                    </>
                </Modal.Body>
            </Modal>
            

        
        </>
    )
}


