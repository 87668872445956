import { useNavigate } from "react-router-dom"
import { AddButton } from "../../../components/componentsForm/addButton/addButton"
import { DynamicTable } from "../../../components/DynamicForm/dynamicTable/DynamicTable"
import { LocalStorage } from "../../../services"

import {BASE_URL} from "../../../services/api/config"
import { Card } from "react-bootstrap"

function PeriodosFinalizados () {
  const ls = new LocalStorage()
  const IdUsuario = ls.getAsJsonItem('usuario').usuario?.IdUsuario
  const navigate = useNavigate()

  const url_descarga=`${BASE_URL}/estudiante/historialAcademico?IdUsuario=${IdUsuario}`

  const handleOptionClick = (item) => {
    navigate(`${item.IdOferta}/detalle_programa`,{state:{programa:item}}) //go to Notas.jsx
  }

  return (
  <>
  <Card>
    <Card.Title>
        Periodos Finalizados
    </Card.Title>
  </Card>
  <DynamicTable
  titleColumn={['N°','Nombre','Acción']}
  attributes = { ['Nombre']}
  onUpdate={false}
  onDelete={false}
  handleOptionClick= {handleOptionClick}
  labelOptionClick= {'Ver Programa'}
  classNameOptionIcon={'bi bi-book'}
  endPoint={`estudiante/cursosFinalizados?IdUsuario=${IdUsuario}`}

  
  ></DynamicTable>
  </>)
}

export { PeriodosFinalizados }