import React from 'react'

import { DeleteDialog } from '../../componentsForm/deleteDialog/DeleteDialog'
import { Table } from '../../componentsForm/table/Table'
import { Modal } from '../../modal/Modal'
import { Form } from './Form'
import { AddButton } from '../../componentsForm/addButton/addButton'
import { FormContainer } from '../../componentsForm/formContainer/FormContainer'

const AsignaturaProfesor = props => {

    const {
        onCreate,
        title,
        asignaturasProfesor,
        asignaturaProfesor,
        attributes,
        onDelete,
        onUpdate,
        isOpenForm,
        handleSubmit,
        handleChange,
        loading,
        handleClickAdd,
        isCreate,
        isOpenDelete,
        isOpenDetails,
        handleClickDelete,
        handleClickDetails,
        deleteTuple,
        selectList
    } = props

    return (
            <FormContainer >
                <AddButton
                    buttonTitle='Asignar docente'
                    createModal={onCreate}
                />
                <Table 
                    titleColumn={ title }
                    data={ asignaturasProfesor }
                    attributes={ attributes }
                    onDelete={ onDelete }
                    onUpdate={ onUpdate }
                    isLoading={ loading }
                />
                <Modal isOpen={ isOpenForm }>
                    <Form 
                        handleSubmit={ handleSubmit }
                        handleChange={ handleChange }
                        loading={ loading }
                        formData={ asignaturaProfesor }
                        close={ handleClickAdd }
                        isCreate={ isCreate }
                        selectList={ selectList }
                    />
                </Modal>
                <Modal isOpen={ isOpenDelete } close={ handleClickDelete }>
                    <DeleteDialog 
                        deleteTuple={ deleteTuple }
                        handleClickDelete={ handleClickDelete }
                        element={ asignaturaProfesor.Asginatura }
                        loading={ loading }
                    />
                </Modal>
                <Modal isOpen={ isOpenDetails } close={ handleClickDetails }>
                </Modal>
            </FormContainer>
    )
}

export { AsignaturaProfesor }