import React, { useState } from 'react';
import { Form, Button, Card, Modal, Col, Row } from 'react-bootstrap';
import TablePaginate from '../../components/TablePaginate/TablePaginate';
import { FaFileExport } from 'react-icons/fa';
import { SelectDataList } from '../../components/SelectDataList/SelectDataList';
import { FormService } from '../../services/api/formService';
import { alerta } from '../../helpers/alertas';
import { BASE_URL } from '../../services/api/config';

const Actividades = () => {
    const api=new FormService()
    const [show, setShow] = useState(false);
    const [accion, setAccion] = useState('Crear');
    const [formData, setFormData] = useState({
        IdCurso: '',
        tipoConocimiento: '',
        tipologiaActividad: '',
        docentesRequeridos: '',
        requisitosActividadAcademica: '',
        intensidadActividad: '',
        fechaInicio: '',
        fechaFinalizacion: '',
        modalidadImparticion: '',
        mecanismosAccesoCapacitacionInterno: '',
        modalidadEjecucion: '',
        espacioAcademicoAsignado: '',
        gastosDesplazamiento: '',
        comiteCurricularActaAprobada: '',
        planEstudio: '',
        oferta: '',
        estadoActividad: '',
        observacion: '',
        costo: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevValores) => ({
        ...prevValores,
        [name]: value,
        }));
    };

    const handleSubmit = async(e) => {
        e.preventDefault();
        if(accion==='Crear'){
            const response = await api.create('actividadesCronograma', formData);
            if (response) {
                alerta('Actividad creada correctamente');
                setShow(false);
            }else{
                alerta('Error al crear la actividad','error', );
            }
        }else{
            const response = await api.update('actividadesCronograma',formData,formData.idActividadCronograma);
            if (response) {
                alerta('Actividad actualizada correctamente');
                setShow(false);
            }else{
                alerta('Error al actualizar la actividad','error', );
            }
        }

    };

  return (
    <>
    <Card>
        <Row>
            <Col>
                <Card.Title>Cronograma de actividades académicas</Card.Title>
            </Col>
            <Col className="d-flex justify-content-end gap-2">
                <Button variant="outline-primary" onClick={() => {
                    window.open(`${BASE_URL}/actividadesCronograma/descargar`, '_blank')
                }}>
                    <FaFileExport />
                    Exportar
                </Button>
                <Button variant="primary" onClick={() =>{
                    setShow(true);
                    setAccion('Crear');
                    setFormData({
                        IdCurso: '',
                        tipoConocimiento: '',
                        tipologiaActividad: '',
                        dimensionCEA: '',
                        poblacionObjetivo: '',
                        docentesRequeridos: '',
                        requisitosActividadAcademica: '',
                        intensidadActividad: '',
                        fechaInicio: '',
                        fechaFinalizacion: '',
                        modalidadImparticion: '',
                        mecanismosAccesoCapacitacionInterno: '',
                        modalidadEjecucion: '',
                        espacioAcademicoAsignado: '',
                        gastosDesplazamiento: '',
                        comiteCurricularActaAprobada: '',
                        planEstudio: '',
                        oferta: '',
                        estadoActividad: '',
                        observacion: '',
                        costo: '',
                    })
                }}>
                    Crear
                </Button>

            </Col>
        </Row>
    </Card>
    <TablePaginate 
        apiUrl={'actividadesCronograma'}
        tableHeaders={[
            {title: 'Nombre de la actividad', attribute: 'NombreCurso'},
            {title: 'Fecha de inicio', attribute: 'fechaInicio'},
            {title: 'Fecha de finalizacion', attribute: 'fechaFinalizacion'},
        ]}
        rowActions={[
            ({rowData})=>(
                <Button variant="outline-primary" onClick={() => {
                    setShow(true);
                    setAccion('Editar');
                    setFormData({...rowData})
                }}>
                    Editar
                </Button>
            ),
        ]}
    />
    <Modal
        show={show}
        onHide={setShow}
    >
        <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
            <Modal.Title>
                {accion==='Crear'?'Crear':'Editar'} actividad
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>


        <Form.Group className="mb-3">
            <Form.Label>Nombre de la actividad:</Form.Label>
            <SelectDataList 
                endPoinLastUrl={'curso'}
                optionName='Nombre'
                optionValue={'IdCurso'}
                onChangeHandler={(e) => {handleChange({target: {name: 'IdCurso', value: e}})}}
                value={formData?.NombreCurso}
            />
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>Tipo de conocimiento:</Form.Label>
            <Form.Select aria-label="Default select example"
                name="tipoConocimiento"
                value={formData.tipoConocimiento}
                onChange={handleChange}
            >
                <option>Esencial</option>
                <option>Especifico</option>
                <option>Especializado</option>
            </Form.Select>

        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>Tipología de la actividad:</Form.Label>
            <Form.Control
            type="text"
            name="tipologiaActividad"
            value={formData.tipologiaActividad}
            onChange={handleChange}
            />
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>Docentes requeridos:</Form.Label>
            <Form.Control
            type="number"
            name="docentesRequeridos"
            value={formData.docentesRequeridos}
            onChange={handleChange}
            />
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>Requisitos actividad académica:</Form.Label>
            <Form.Control
            as="textarea"
            name="requisitosActividadAcademica"
            value={formData.requisitosActividadAcademica}
            onChange={handleChange}
            />
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>Intensidad de la Actividad:</Form.Label>
            <Form.Control
            type="number"
            name="intensidadActividad"
            value={formData.intensidadActividad}
            onChange={handleChange}
            />
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>Fecha de inicio:</Form.Label>
            <Form.Control
            type="date"
            name="fechaInicio"
            value={formData.fechaInicio}
            onChange={handleChange}
            />
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>Fecha de finalización:</Form.Label>
            <Form.Control
            type="date"
            name="fechaFinalizacion"
            value={formData.fechaFinalizacion}
            onChange={handleChange}
            />
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>Modalidad de impartición:</Form.Label>
            <Form.Control
            type="text"
            name="modalidadImparticion"
            value={formData.modalidadImparticion}
            onChange={handleChange}
            />
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>Mecanismos de acceso a la capacitación interno:</Form.Label>
            <Form.Control
            type="text"
            name="mecanismosAccesoCapacitacionInterno"
            value={formData.mecanismosAccesoCapacitacionInterno}
            onChange={handleChange}
            />
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>Modalidad de ejecución:</Form.Label>
            <Form.Select aria-label="Default select example"
                name="modalidadEjecucion"
                value={formData.modalidadEjecucion}
                onChange={handleChange}
            >
                <option>Inhouse</option>
                <option>Alianza estratégica</option>
                <option>Convenio</option>
                <option>Contratación</option>
            </Form.Select>

        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>Espacio académico asignado:</Form.Label>
            <Form.Control
            type="text"
            name="espacioAcademicoAsignado"
            value={formData.espacioAcademicoAsignado}
            onChange={handleChange}
            />
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>Gastos desplazamiento (docentes/estudiantes):</Form.Label>
            <Form.Control
                type="number"
                name="gastosDesplazamiento"
                value={formData.gastosDesplazamiento}
                onChange={handleChange}
            />
        </Form.Group>

        <Form.Group className="mb-3">
            <Form.Label>Comité curricular acta aprobada:</Form.Label>
            <Form.Control
                type="text"
                name="comiteCurricularActaAprobada"
                value={formData.comiteCurricularActaAprobada}
                onChange={handleChange}
            />
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>Plan de estudio:</Form.Label>
            <Form.Select aria-label="Default select example"
                name="planEstudio"
                value={formData.planEstudio}
                onChange={handleChange}
            >
                <option>Si</option>
                <option>No</option>
            </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>Oferta:</Form.Label>
            <Form.Select aria-label="Default select example"
                name="oferta"
                value={formData.oferta}
                onChange={handleChange}
            >
                <option>PIC</option>
                <option>OFA</option>
                <option>PICOFA</option>
            </Form.Select>
            
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>Estado de la actividad:</Form.Label>    
            <Form.Select aria-label="Default select example"
                name="estadoActividad"
                value={formData.estadoActividad}
                onChange={handleChange}
            >
                <option>Programada</option>
                <option>En proceso</option>
                <option>Finalizado</option>
            </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>Observación:</Form.Label>
            <Form.Control
                as="textarea"
                name="observacion"
                value={formData.observacion}
                onChange={handleChange}
            />
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>Costo:</Form.Label>
            <Form.Control
                type="number"
                name="costo"
                value={formData.costo}
                onChange={handleChange}
            />
        </Form.Group>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={()=>setShow()}>
                Cerrar
            </Button>
            <Button variant="primary" type="submit">
                {accion=='Crear' ? 'Crear' : 'Actualizar'}
            </Button>
        </Modal.Footer>
        </Form>
    </Modal>
    </>
    )
}

export default Actividades;
        