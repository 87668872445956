import React from 'react'
import { Button, Modal } from 'react-bootstrap'
const DeleteDialog = props => {

    const {
        deleteTuple, 
        handleClickDelete, 
        element,
        loading
    } = props

    return (
        <>
        <Modal.Body>
            <h3>
                ¿Estas seguro que deseas eliminar { element || ''}?
            </h3>
        </Modal.Body>
        <Modal.Footer>
        <Button 
                onClick={ handleClickDelete }
                variant="secondary"    
            >
                    cancelar
            </Button>
            <Button 
                onClick={ deleteTuple }
                loading={ loading }
                variant='danger'>
                    Eliminar
            </Button>
        </Modal.Footer>
        </>
    )
}

export { DeleteDialog }