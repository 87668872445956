import React, { useState } from 'react'
import { useEffect } from 'react';
import { Tab } from 'react-bootstrap';
import { OfferCard } from '../../components/offerCard/OfferCard';

export default function CursosContinuada({
    cursos,
    eventKey,
    onClickInscripcion
}) {


    const [cursosPIC, setCursosPIC] = useState([]);
    const [cursosOFA, setCursosOFA] = useState([]);

    useEffect(()=>{
        setCursosOFA(cursos.filter((curso) => curso.IdTipoIngreso == '3'||curso.IdTipoIngreso == '2'));
        setCursosPIC(cursos.filter((curso) => curso.IdTipoIngreso == '1'|| curso.IdTipoIngreso == '2'));
    
    },[])
    
  return (
    
        <Tab.Pane eventKey={eventKey}>
        <div className='text-center text-primary'>
            <p>
            Inscríbase por PIC si es funcionario o por OFA si es externo.
            </p>
            <ul
                className="nav nav-pills tabs mb-3 d-flex justify-content-center"
                id="pills-tab"
                role="tablist"
            >
                <li
                    className="nav-item"
                    role="presentation"
                >
                    <button
                        className="nav-link active"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                        style={{
                            fontSize: '1.3rem',
                        }}
                    >
                        PIC
                    </button>
                </li>
                <li
                    className="nav-item"
                    role="presentation"
                >
                    <button
                        className="nav-link"
                        id="pills-contact-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-contact"
                        type="button"
                        role="tab"
                        aria-controls="pills-contact"
                        aria-selected="false"
                        style={{
                            fontSize: '1.3rem',
                        }}
                    >
                        OFA
                    </button>
                </li>
                <li
                    className="nav-item"
                    role="presentation"
                >
                </li>
            </ul>
            <div className="row">
    <div className="col-12">
    <div className="d-flex flex-column align-items-center">
        <>
            <div
                className="tab-content w-100"
                id="pills-tabContent"
            >
                <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                >              
                    <div className="d-flex flex-wrap justify-content-evenly">                                      
                        {!!cursosPIC && cursosPIC.map((item, key) => {
                                return (
                                    <>                                                                                                                
                                    <OfferCard
                                        key={key}
                                        program={item}
                                        onClickInscripcion={onClickInscripcion}
                                        // programLink={() => onClickInscripcion( item ) }
                                    />
                                    </>
                                );
                            })}
                        {cursosPIC.length == 0 ? (
                            <div className='row'>No existen resultados</div>
                        ) : null}
                    </div>
                </div>
                <div
                    className="tab-pane fade"
                    id="pills-contact"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab"
                >
                    <div className="d-flex flex-wrap justify-content-evenly">
                    
                        {!!cursosOFA && cursosOFA.map((item, key) => {
                                return (
                                    <OfferCard
                                        key={key}
                                        program={item}
                                        onClickInscripcion={onClickInscripcion}
                                        // programLink={() => onClickInscripcion( item ) }
                                    />
                                );
                            })}
                        {cursosOFA.length == 0 ? (
                            <p>No existen resultados</p>
                        ) : null}
                    </div>
                </div>
            </div>
        </>
    </div>
    </div>
    </div>
    </div> 
    </Tab.Pane>
  )
}
