import { useState, useEffect } from "react";
import { DynamicSearch } from "../../components/DynamicForm/DynamicSearch/DynamicSearch";
import { DynamicTable } from "../../components/DynamicForm/dynamicTable/DynamicTable";
import { FormService } from "../../services/api/formService";
import { Card, Row, Col, Button } from "react-bootstrap";

const Indicadores=()=>{

    const [camposForm, setCamposForm] = useState(null);
    const [endPoint,setEndPoint]=useState("bodegaIndicadores");
    const [pagination, setPagination] = useState(
        {
            current_page:1,
            prev_page_url:null,
            next_page_url:null,
            per_page:0
        }
    )

    const fetchCamposFormForFilter = ()=>{
        
        const api = new FormService()
        api.getAll(`xcampos?id_formulario=110`)
        .then((res)=>{
            setCamposForm(res)
        })
      
    }

    useEffect(() => {
        
        const fecthData = async () => {
            fetchCamposFormForFilter(endPoint);
        };
        fecthData();
        
    }, []);

    return (
    <>
        <Card>
            <Card.Title>
                <Row>
                    <hr />
                </Row>
                <Row>
                    <Col>Bodega Indicadores</Col>
                    <Col>
                        <div style={{textAlign: 'right'}}>
                            <Button href="https://halconerp.com/v2/public/api/bodega" target="_blank">Exportar</Button>
                        </div>
                    </Col>
                </Row>
            </Card.Title>
        </Card>
        <DynamicTable 
            titleColumn={['No', 'Indicador', 'Periodo Actual', 'Periodo Anterior']}
            attributes={['BINombre', 'Periodo_Actual', 'Periodo_Anterior']}
            endPoint={endPoint}
            onActions={false}
            pagination={pagination}
            //actualizarExterno={(e)=>onUpdate(e)}
            //elimimarExterno={(e)=>onDelete(e)}
        />
    </>);
}

export {Indicadores}