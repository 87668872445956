import { Col, Row ,Card as Cardb, Accordion, ListGroup, Tabs, Tab} from "react-bootstrap"
import { Card } from "../../../../components/card/Card"
import { Select } from "../../../../components/select/Select"
import { Title } from "../../../../shared/components/title/Title"

import { FormService } from "../../../../services/api/formService"
import { useState } from "react"
import { useEffect } from "react"
import { BASE_SERVIDOR, BASE_URL } from "../../../../services/api/config"

import { BsFillFileEarmarkExcelFill, BsFillFileEarmarkPdfFill, BsFillFileXFill } from 'react-icons/bs';

import a2019_1 from "../../../../Luisa/2019/a2019_1.xls"
import a2019_2 from "../../../../Luisa/2019/a2019_2.xls"
import a2019_3 from "../../../../Luisa/2019/a2019_3.xls"
import a2019_4 from "../../../../Luisa/2019/a2019_4.xls"
import a2019_5 from "../../../../Luisa/2019/a2019_5.xlsx"
import a2019_6 from "../../../../Luisa/2019/a2019_6.xls"
import a2019_7 from "../../../../Luisa/2019/a2019_7.xlsx"
import a2019_8 from "../../../../Luisa/2019/a2019_8.xlsx"
import a2019_9 from "../../../../Luisa/2019/a2019_9.xls"
import a2019_10 from "../../../../Luisa/2019/a2019_10.xls"
import a2019_11 from "../../../../Luisa/2019/a2019_11.pdf"
import a2019_12 from "../../../../Luisa/2019/a2019_12.pdf"
import a2019_13 from "../../../../Luisa/2019/a2019_13.pdf"
import a2019_14 from "../../../../Luisa/2019/a2019_14.pdf"
// 2020
import a2020_1 from "../../../../Luisa/2020/a2020_1.xls"
import a2020_2 from "../../../../Luisa/2020/a2020_2.xls"
import a2020_3 from "../../../../Luisa/2020/a2020_3.xls"
import a2020_4 from "../../../../Luisa/2020/a2020_4.xls"
import a2020_5 from "../../../../Luisa/2020/a2020_5.xls"
import a2020_6 from "../../../../Luisa/2020/a2020_6.xls"
import a2020_7 from "../../../../Luisa/2020/a2020_7.xls"
import a2020_8 from "../../../../Luisa/2020/a2020_8.xls"
import a2020_9 from "../../../../Luisa/2020/a2020_9.pdf"
import a2020_10 from "../../../../Luisa/2020/a2020_10.pdf"
import a2020_11 from "../../../../Luisa/2020/a2020_11.pdf"
import a2020_12 from "../../../../Luisa/2020/a2020_12.pdf"
// 2021
import a2021_1 from "../../../../Luisa/2021/a2021_1.xls"
import a2021_2 from "../../../../Luisa/2021/a2021_2.xls"
import a2021_3 from "../../../../Luisa/2021/a2021_3.xls"
import a2021_4 from "../../../../Luisa/2021/a2021_4.xls"
import a2021_5 from "../../../../Luisa/2021/a2021_5.xls"
import a2021_6 from "../../../../Luisa/2021/a2021_6.xls"
import a2021_7 from "../../../../Luisa/2021/a2021_7.pdf"
import a2021_8 from "../../../../Luisa/2021/a2021_8.xls"
import a2021_9 from "../../../../Luisa/2021/a2021_9.pdf"
import a2021_10 from "../../../../Luisa/2021/a2021_10.xls"
import a2021_11 from "../../../../Luisa/2021/a2021_11.pdf"
import a2021_12 from "../../../../Luisa/2021/a2021_12.xls"
import a2021_13 from "../../../../Luisa/2021/a2021_13.pdf"

const ConsultaHistorico=()=>{
    const api= new FormService();
    const[IdPeriodo,setIdPeriodo]=useState();

    const actividadesEvaluadas=`${BASE_URL}/luisa/actividadesEvaluadas?IdPeriodo=${IdPeriodo}`
    const noContestadas=`${BASE_URL}/luisa/evaluacionesNoContestadas?IdPeriodo=${IdPeriodo}`
    const apreciacionesDocentes=`${BASE_URL}/luisa/apreciacionesDocentes?IdPeriodo=${IdPeriodo}`
    const cualitativasAutoevaluacionDocente=`${BASE_URL}/luisa/cualitativasAutoevaluacionDocente?IdPeriodo=${IdPeriodo}`
    const ApreciacionesActividades=`${BASE_URL}/luisa/ApreciacionesActividades?IdPeriodo=${IdPeriodo}`
    const docentesEvaluados=`${BASE_URL}/luisa/docentesEvaluados?IdPeriodo=${IdPeriodo}`
    const cualitativasHeteroevaluacion=`${BASE_URL}/luisa/cualitativasHeteroevaluacion?IdPeriodo=${IdPeriodo}`
    const rutaRepositorio = `repositorio/archivos`
    const [archivo,setArchivo] = useState([]);
    
    const carpeta =async()=>{
        const respuesta =await api.getAll(rutaRepositorio)
        setArchivo(respuesta)
        // respuesta.map((file)=>{console.log(file.Nombre)})
    }

     useEffect(()=>{

        carpeta()

    },[])

    const consultarPeriodos=async()=>{
        const periodos=await api.getAll("periodo")
        console.log(periodos)
    }
    const peridoActual=(e)=>{
        setIdPeriodo(e)
    }

    return(
        <>
        <Card>
            <Title>Histórico de Alejandría</Title>
        </Card>
        <Tabs
            defaultActiveKey={"reportes"}
        >
            <Tab 
                title="Reportes"
                eventKey={"reportes"}
            >
            <Accordion >
            <Accordion.Item eventKey="0">
                <Accordion.Header>2019</Accordion.Header>
                <Accordion.Body>
                    <ListGroup>
                        <ListGroup.Item>
                            <a href={a2019_1}>ACTIVIDADES ACADÉMICAS EVALUADAS EDUCACION CONTINUADA EN EL PERIODO DE 20190101 HASTA 20191231</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={a2019_2}>APRECIACIÓN CUALITATIVA DE LOS DOCENTES DESDE 20190101 HASTA 20191231</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={a2019_3}>APRECIACIÓN DE LOS ESTUDIANTES A LAS ACTIVIDADES ACADÉMICAS DESDE 20190101 HASTA 20191231</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={a2019_4}>APRECIACIÓN DE LOS ESTUDIANTES A LOS DOCENTES DESDE 20190101 HASTA 20191231</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={a2019_5}>EDUCACION CONTINUADA EN EL PERIODO DE 20190101 HASTA 20191231</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={a2019_6}>FORMULARIOS DE EVALUACIÓN SIN DILIGENCIAR EDUCACION CONTINUADA EN EL PERIODO DE 20190101 HASTA 20191231</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={a2019_7}>INFORME CONSOLIDADO DE LAS ACTIVIDADES ACADÉMICAS Y DOCENTES DE 20190101 HASTA 20190331</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={a2019_8}>INFORME CONSOLIDADO DE LAS ACTIVIDADES ACADÉMICAS Y DOCENTES DE 20190401 HASTA 20190630</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={a2019_9}>INFORME CONSOLIDADO DE LAS ACTIVIDADES ACADÉMICAS Y DOCENTES DE 20190701 HASTA 20190930</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={a2019_10}>INFORME CONSOLIDADO DE LAS ACTIVIDADES ACADÉMICAS Y DOCENTES DE 20191001 HASTA 20191231</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={a2019_11}>RESUMEN ACTIVIDADES ACADÉMICAS EVALUADAS I TRIMESTRE 2019</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={a2019_12}>ARESUMEN ACTIVIDADES ACADÉMICAS EVALUADAS II TRIMESTRE 2019</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={a2019_13}>RESUMEN ACTIVIDADES ACADÉMICAS EVALUADAS III TRIMESTRE 2019</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={a2019_14}>RESUMEN ACTIVIDADES ACADÉMICAS EVALUADAS IV TRIMESTRE 2019</a>
                        </ListGroup.Item>
                    </ListGroup>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>2020</Accordion.Header>
                <Accordion.Body>
                <ListGroup>
                        <ListGroup.Item>
                            <a href={a2020_1}>APRECIACIÓN CUALITATIVA DE LOS DOCENTES DESDE 20200101 HASTA 20201231</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={a2020_2}>APRECIACIÓN DE LOS ESTUDIANTES A LAS ACTIVIDADES ACADÉMICAS DESDE 20200101 HASTA 20201231</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={a2020_3}>APRECIACIÓN DE LOS ESTUDIANTES A LOS DOCENTES DESDE 20200101 HASTA 20201231</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={a2020_4}>FORMULARIOS DE EVALUACIÓN SIN DILIGENCIAR EDUCACION CONTINUADA EN EL PERIODO DE 20200101 HASTA 20201231</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={a2020_5}>INFORME CONSOLIDADO DE LAS ACTIVIDADES ACADÉMICAS Y DOCENTES  DE 20200101 HASTA 20200331</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={a2020_6}>INFORME CONSOLIDADO DE LAS ACTIVIDADES ACADÉMICAS Y DOCENTES  DE 20200401 HASTA 20200630</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={a2020_7}>INFORME CONSOLIDADO DE LAS ACTIVIDADES ACADÉMICAS Y DOCENTES DE 20200701 HASTA 20200930</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={a2020_8}>INFORME CONSOLIDADO DE LAS ACTIVIDADES ACADÉMICAS Y DOCENTES DE 20201001 HASTA 20201231</a>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <a href={a2020_9}>INFORME CONSOLIDADO DE LAS ACTIVIDADES ACADÉMICAS Y DOCENTES I TRIMETRE 2020</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={a2020_10}>INFORME CONSOLIDADO DE LAS ACTIVIDADES ACADÉMICAS Y DOCENTES II TRIMETRE 2020</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={a2020_11}>INFORME CONSOLIDADO DE LAS ACTIVIDADES ACADÉMICAS Y DOCENTES III  TRIMETRE 2020</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={a2020_12}>INFORME CONSOLIDADO DE LAS ACTIVIDADES ACADÉMICAS Y DOCENTES IV  TRIMETRE 2020</a>
                        </ListGroup.Item>
                </ListGroup>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header>2021</Accordion.Header>
                <Accordion.Body>
                <ListGroup>
                        <ListGroup.Item>
                            <a href={a2021_1}>APRECIACIÓN CUALITATIVA DE LOS AUTO DOCENTES 2021</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={a2021_2}>APRECIACIÓN CUALITATIVA DE LOS DOCENTES 2021</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={a2021_3}>APRECIACIÓN DE LOS ESTUDIANTES A LAS ACTIVIDADES ACADÉMICAS 2021</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={a2021_4}>APRECIACIÓN DE LOS ESTUDIANTES A LOS DOCENTES  2021</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={a2021_5}>FORMULARIOS DE EVALUACIÓN SIN DILIGENCIAR EDUCACION CONTINUADA 2021</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={a2021_6}>INFORME CONSOLIDADO DE LAS ACTIVIDADES ACADÉMICAS Y DOCENTES I TRIMESTRE 2021</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={a2021_7}>INFORME CONSOLIDADO DE LAS ACTIVIDADES ACADÉMICAS Y DOCENTES I TRIMETRE 2021</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={a2021_8}>INFORME CONSOLIDADO DE LAS ACTIVIDADES ACADÉMICAS Y DOCENTES II TRIMESTRE 2021</a>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <a href={a2021_9}>INFORME CONSOLIDADO DE LAS ACTIVIDADES ACADÉMICAS Y DOCENTES II TRIMETRE 2021</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={a2021_10}>INFORME CONSOLIDADO DE LAS ACTIVIDADES ACADÉMICAS Y DOCENTES III TRIMESTRE 2021</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={a2021_11}>INFORME CONSOLIDADO DE LAS ACTIVIDADES ACADÉMICAS Y DOCENTES III TRIMETRE 2021</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={a2021_12}>INFORME CONSOLIDADO DE LAS ACTIVIDADES ACADÉMICAS Y DOCENTES IV TRIMESTRE 2021</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href={a2021_13}>INFORME CONSOLIDADO DE LAS ACTIVIDADES ACADÉMICAS Y DOCENTES IV TRIMETRE 2021</a>
                        </ListGroup.Item>
                </ListGroup>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
                
            </Tab>
            <Tab 
                title="Base de datos"
                eventKey={"DB"}
            >
                <Card>
                    <Row>
                        <Col>
                            <div style={{paddingTop:15}}>Selecciona el período de la consulta</div>
                        </Col>
                        <Col>
                            <Select
                                urlOptionsService="periodo"
                                optionValue={"IdPeriodo"}
                                optionName={"Descripcion"}
                                onChange={(e)=>peridoActual(e)}
                                value={IdPeriodo}
                            />
                        </Col>
                    </Row>
                </Card>
                <Card>
            <Row>
                <Col>
                    <a href={actividadesEvaluadas} target="_blank">                    
                        <Cardb>
                            Actividades Evaluadas
                        </Cardb>
                    </a>
                </Col>
                <Col>
                    <a href={noContestadas}  target="_blank">
                        <Cardb>
                            Evaluaciones no Contestadas
                        </Cardb>
                    </a>
                </Col>
                <Col>
                    <a href={apreciacionesDocentes} target="_blank">
                        <Cardb>
                            Apreciaciones Cualitativas de Estudiantes a Docentes
                        </Cardb>
                    </a>
                </Col>
            </Row>

            <Row>
                {/* <Col>
                    <a href={actividadesEvaluadas} target="_blank">                    
                        <Cardb>
                             {'Apreciaciones cualitativas de actividades hasta el 2020'}
                        </Cardb>
                    </a>
                </Col> */}
                <Col>
                    <a href={ApreciacionesActividades}  target="_blank">
                        <Cardb>
                            {'Apreciaciones Cualitativas de Actividades'} 
                        </Cardb>
                    </a>
                </Col>
                <Col>
                    <a href={cualitativasAutoevaluacionDocente} target="_blank">
                        <Cardb>
                            Apreciaciones Cualitativas de Autoevaluación Docente
                        </Cardb>
                    </a>
                </Col>
                <Col>
                    <a href={cualitativasHeteroevaluacion} target="_blank">
                        <Cardb>
                            Apreciaciones Cualitativas de Heteroevaluacion
                        </Cardb>
                    </a>
                </Col>
            </Row>
            {/* nueva fila */}
            <Row>
                {/* <Col>
                    <a href={actividadesEvaluadas} target="_blank">                    
                        <Cardb>
                             {'Apreciaciones cualitativas de actividades hasta el 2020'}
                        </Cardb>
                    </a>
                </Col> */}
                <Col>
                    <a href={docentesEvaluados}  target="_blank">
                        <Cardb>
                            {'Actividades y Docentes'} 
                        </Cardb>
                    </a>
                </Col>
                <Col>
                    {/* <a href={cualitativasAutoevaluacionDocente} target="_blank">
                        <Cardb>
                            Apreciaciones Cualitativas de Autoevaluación Docente
                        </Cardb>
                    </a> */}
                </Col>
                <Col>
                    {/* <a href={cualitativasHeteroevaluacion} target="_blank">
                        <Cardb>
                            Apreciaciones Cualitativas de Heteroevaluacion
                        </Cardb>
                    </a> */}
                </Col>
            </Row>

            </Card>
            </Tab>
            <Tab 
                title="Repositorio"
                eventKey={"Repositorio"}
            >
            <Accordion defaultActiveKey={['0']} alwaysOpen>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Repositorio de informacion Jul_Dic 2022 - Transicion Alejandria_Halcon</Accordion.Header>
                        <Accordion.Body>
                            <ListGroup>
                                <ListGroup.Item>
                                <Accordion.Item eventKey="1">
                                        <Accordion.Header>1-OFA-GESTIÓN DE SERIVICIO DE TRÁNSITO AÉREO - ATS - </Accordion.Header>
                                            <Accordion.Body>
                                                <ListGroup>
                                                    <ListGroup.Item>
                                                        <a><BsFillFileXFill/> Sin Documentos</a>
                                                    </ListGroup.Item>
                                            </ListGroup>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                <Accordion.Item eventKey="2">
                                        <Accordion.Header>2-OFA-SERVICIO DE EXTINCIÓN DE INCENDIOS - SEI - </Accordion.Header>
                                            <Accordion.Body>
                                                <ListGroup>
                                                    {
                                                        archivo.map((file)=>
                                                            <>
                                                            {
                                                                file.IdNombreCarpeta == 2 &&
                                                                <ListGroup.Item>
                                                                    <a target="_blank" href={`${BASE_SERVIDOR}/Repositorio de informacion Jul_Dic 2022 - Transicion Alejandria_Halcon/2-OFA-SERVICIO DE EXTINCIÓN DE INCENDIOS - SEI -/${file.Nombre}`}> {file.extension==1?<BsFillFileEarmarkExcelFill/> : <BsFillFileEarmarkPdfFill/>} {file.Nombre} </a>
                                                                </ListGroup.Item>
                                                            }
                                                            </>
                                                        )
                                                    }
                                            </ListGroup>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                <Accordion.Item eventKey="3">
                                        <Accordion.Header> 3-OFA-SEGURIDAD DE LA AVIACION CIVIL-AVSEC- </Accordion.Header>
                                            <Accordion.Body>
                                                <ListGroup>
                                                {
                                                        archivo.map((file)=>
                                                            <>
                                                            {
                                                                file.IdNombreCarpeta == 3 &&
                                                                <ListGroup.Item>
                                                                    <a target="_blank" href={`${BASE_SERVIDOR}/Repositorio de informacion Jul_Dic 2022 - Transicion Alejandria_Halcon/3-OFA-SEGURIDAD DE LA AVIACION CIVIL-AVSEC-/${file.Nombre}`}> {file.extension==1?<BsFillFileEarmarkExcelFill/> : <BsFillFileEarmarkPdfFill/>} {file.Nombre} </a>
                                                                </ListGroup.Item>
                                                            }
                                                            </>
                                                        )
                                                    }
                                            </ListGroup>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                <Accordion.Item eventKey="4">
                                        <Accordion.Header> 4-OFA-FACTORES HUMANOS Y MEDICINA AERONÁUTICA - MED - </Accordion.Header>
                                            <Accordion.Body>
                                                <ListGroup>
                                                {
                                                        archivo.map((file)=>
                                                            <>
                                                            {
                                                                file.IdNombreCarpeta == 4 &&
                                                                <ListGroup.Item>
                                                                    <a target="_blank" href={`${BASE_SERVIDOR}/Repositorio de informacion Jul_Dic 2022 - Transicion Alejandria_Halcon/4-OFA-FACTORES HUMANOS Y MEDICINA AERONÁUTICA - MED -/${file.Nombre}`}> {file.extension==1?<BsFillFileEarmarkExcelFill/> : <BsFillFileEarmarkPdfFill/>} {file.Nombre} </a>
                                                                </ListGroup.Item>
                                                            }
                                                            </>
                                                        )
                                                    }
                                            </ListGroup>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                <Accordion.Item eventKey="5">
                                        <Accordion.Header> 5-OFA-GESTIÓN DE LA SEGURIDAD OPERACIONAL- SMS - </Accordion.Header>
                                            <Accordion.Body>
                                                <ListGroup>
                                                {
                                                        archivo.map((file)=>
                                                            <>
                                                            {
                                                                file.IdNombreCarpeta == 5 &&
                                                                <ListGroup.Item>
                                                                    <a target="_blank" href={`${BASE_SERVIDOR}/Repositorio de informacion Jul_Dic 2022 - Transicion Alejandria_Halcon/5-OFA-GESTIÓN DE LA SEGURIDAD OPERACIONAL- SMS -/${file.Nombre}`}> {file.extension==1?<BsFillFileEarmarkExcelFill/> : <BsFillFileEarmarkPdfFill/>}  {file.Nombre} </a>
                                                                </ListGroup.Item>
                                                            }
                                                            </>
                                                        )
                                                    }
                                            </ListGroup>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                <Accordion.Item eventKey="6">
                                        <Accordion.Header> 6-OFA-OPERACIONES AEROPORTUARIAS </Accordion.Header>
                                            <Accordion.Body>
                                                <ListGroup>
                                                {
                                                        archivo.map((file)=>
                                                            <>
                                                            {
                                                                file.IdNombreCarpeta == 6 &&
                                                                <ListGroup.Item>
                                                                    <a target="_blank" href={`${BASE_SERVIDOR}/Repositorio de informacion Jul_Dic 2022 - Transicion Alejandria_Halcon/6-OFA-OPERACIONES AEROPORTUARIAS/${file.Nombre}`}> {file.extension==1?<BsFillFileEarmarkExcelFill/> : <BsFillFileEarmarkPdfFill/>} {file.Nombre} </a>
                                                                </ListGroup.Item>
                                                            }
                                                            </>
                                                        )
                                                    }
                                            </ListGroup>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                <Accordion.Item eventKey="7">
                                        <Accordion.Header> 7-OFA-SECRETARIA DE AUTORIDAD AERONÁUTICA </Accordion.Header>
                                            <Accordion.Body>
                                                <ListGroup>
                                                {
                                                        archivo.map((file)=>
                                                            <>
                                                            {
                                                                file.IdNombreCarpeta == 7 &&
                                                                <ListGroup.Item>
                                                                    <a target="_blank" href={`${BASE_SERVIDOR}/Repositorio de informacion Jul_Dic 2022 - Transicion Alejandria_Halcon/7-OFA-SECRETARIA DE AUTORIDAD AERONÁUTICA/${file.Nombre}`}> {file.extension==1?<BsFillFileEarmarkExcelFill/> : <BsFillFileEarmarkPdfFill/>} {file.Nombre} </a>
                                                                </ListGroup.Item>
                                                            }
                                                            </>
                                                        )
                                                    }
                                            </ListGroup>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                <Accordion.Item eventKey="8">
                                        <Accordion.Header> 8-OFA-PROYECCION SOCIAL </Accordion.Header>
                                            <Accordion.Body>
                                                <ListGroup>
                                                    {
                                                        archivo.map((file)=>
                                                            <>
                                                            {
                                                                file.IdNombreCarpeta == 8 &&
                                                                <ListGroup.Item>
                                                                    <a target="_blank" href={`${BASE_SERVIDOR}/Repositorio de informacion Jul_Dic 2022 - Transicion Alejandria_Halcon/8-OFA-PROYECCION SOCIAL/${file.Nombre}`}> {file.extension==1?<BsFillFileEarmarkExcelFill/> : <BsFillFileEarmarkPdfFill/>} {file.Nombre} </a>
                                                                </ListGroup.Item>
                                                            }
                                                            </>
                                                        )
                                                    }
                                            </ListGroup>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                <Accordion.Item eventKey="9">
                                        <Accordion.Header> 9-PIC-SSNA-GRUPO INSPECCIÓN DE AERONAVEGABILIDAD - AIR - </Accordion.Header>
                                            <Accordion.Body>
                                                <ListGroup>
                                                    {
                                                        archivo.map((file)=>
                                                            <>
                                                            {
                                                                file.IdNombreCarpeta == 9 &&
                                                                <ListGroup.Item>
                                                                    <a target="_blank" href={`${BASE_SERVIDOR}/Repositorio de informacion Jul_Dic 2022 - Transicion Alejandria_Halcon/9-PIC-SSNA-GRUPO INSPECCIÓN DE AERONAVEGABILIDAD - AIR -/${file.Nombre}`}> {file.extension==1?<BsFillFileEarmarkExcelFill/> : <BsFillFileEarmarkPdfFill/>} {file.Nombre} </a>
                                                                </ListGroup.Item>
                                                            }
                                                            </>
                                                        )
                                                    }
                                            </ListGroup>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                <Accordion.Item eventKey="10">
                                        <Accordion.Header> 10-PIC-SSNA-GRUPO INSPECCIÓN DE OPERACIONES-OPS- </Accordion.Header>
                                            <Accordion.Body>
                                                <ListGroup>
                                                    {
                                                        archivo.map((file)=>
                                                            <>
                                                            {
                                                                file.IdNombreCarpeta == 10 &&
                                                                <ListGroup.Item>
                                                                    <a target="_blank" href={`${BASE_SERVIDOR}/Repositorio de informacion Jul_Dic 2022 - Transicion Alejandria_Halcon/10-PIC-SSNA-GRUPO INSPECCIÓN DE OPERACIONES-OPS-/${file.Nombre}`}> {file.extension==1?<BsFillFileEarmarkExcelFill/> : <BsFillFileEarmarkPdfFill/>} {file.Nombre} </a>
                                                                </ListGroup.Item>
                                                            }
                                                            </>
                                                        )
                                                    }
                                            </ListGroup>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                <Accordion.Item eventKey="11">
                                        <Accordion.Header> 11-PIC-SSNA - GESTIÓN DE INFORMACIÓN AERONÁUTICA - AIM </Accordion.Header>
                                            <Accordion.Body>
                                                <ListGroup>
                                                    {
                                                        archivo.map((file)=>
                                                            <>
                                                            {
                                                                file.IdNombreCarpeta == 11 &&
                                                                <ListGroup.Item>
                                                                    <a target="_blank" href={`${BASE_SERVIDOR}/Repositorio de informacion Jul_Dic 2022 - Transicion Alejandria_Halcon/11-PIC-SSNA - GESTIÓN DE INFORMACIÓN AERONÁUTICA - AIM/${file.Nombre}`}> {file.extension==1?<BsFillFileEarmarkExcelFill/> : <BsFillFileEarmarkPdfFill/>} {file.Nombre} </a>
                                                                </ListGroup.Item>
                                                            }
                                                            </>
                                                        )
                                                    }
                                            </ListGroup>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                <Accordion.Item eventKey="12">
                                        <Accordion.Header> 12-PIC-SSNA - SERVICIOS DE BÚSQUEDA Y SALVAMENTO - SAR - </Accordion.Header>
                                            <Accordion.Body>
                                                <ListGroup>
                                                    {
                                                        archivo.map((file)=>
                                                            <>
                                                            {
                                                                file.IdNombreCarpeta == 12 &&
                                                                <ListGroup.Item>
                                                                    <a target="_blank" href={`${BASE_SERVIDOR}/Repositorio de informacion Jul_Dic 2022 - Transicion Alejandria_Halcon/12-PIC-SSNA - SERVICIOS DE BÚSQUEDA Y SALVAMENTO - SAR -/${file.Nombre}`}> {file.extension==1?<BsFillFileEarmarkExcelFill/> : <BsFillFileEarmarkPdfFill/>} {file.Nombre} </a>
                                                                </ListGroup.Item>
                                                            }
                                                            </>
                                                        )
                                                    }
                                            </ListGroup>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                <Accordion.Item eventKey="13">
                                        <Accordion.Header> 13-PIC-SSNA - GESTIÓN DE LOS SERVICIOS DE TRÁNSITO AÉREO - ATS - </Accordion.Header>
                                            <Accordion.Body>
                                                <ListGroup>
                                                    {
                                                        archivo.map((file)=>
                                                            <>
                                                            {
                                                                file.IdNombreCarpeta == 13 &&
                                                                <ListGroup.Item>
                                                                    <a target="_blank" href={`${BASE_SERVIDOR}/Repositorio de informacion Jul_Dic 2022 - Transicion Alejandria_Halcon/13-PIC-SSNA - GESTIÓN DE LOS SERVICIOS DE TRÁNSITO AÉREO - ATS -/${file.Nombre}`}> {file.extension==1?<BsFillFileEarmarkExcelFill/> : <BsFillFileEarmarkPdfFill/>} {file.Nombre} </a>
                                                                </ListGroup.Item>
                                                            }
                                                            </>
                                                        )
                                                    }
                                            </ListGroup>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                <Accordion.Item eventKey="14">
                                        <Accordion.Header> 14-PIC-SSNA - DIR TELECO Y AYUDAS A LA NAVEGACIÓN AÉREA </Accordion.Header>
                                            <Accordion.Body>
                                                <ListGroup>
                                                    {
                                                        archivo.map((file)=>
                                                            <>
                                                            {
                                                                file.IdNombreCarpeta == 14 &&
                                                                <ListGroup.Item>
                                                                    <a target="_blank" href={`${BASE_SERVIDOR}/Repositorio de informacion Jul_Dic 2022 - Transicion Alejandria_Halcon/14-PIC-SSNA - DIR TELECO Y AYUDAS A LA NAVEGACIÓN AÉREA/${file.Nombre}`}> {file.extension==1?<BsFillFileEarmarkExcelFill/> : <BsFillFileEarmarkPdfFill/>} {file.Nombre} </a>
                                                                </ListGroup.Item>
                                                            }
                                                            </>
                                                        )
                                                    }
                                            </ListGroup>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                <Accordion.Item eventKey="15">
                                        <Accordion.Header> 15-PIC-SSNA - SERVICIO DE EXTINCIÓN DE INCENDIOS - SEI - </Accordion.Header>
                                            <Accordion.Body>
                                                <ListGroup>
                                                    {
                                                        archivo.map((file)=>
                                                            <>
                                                            {
                                                                file.IdNombreCarpeta == 15 &&
                                                                <ListGroup.Item>
                                                                    <a target="_blank" href={`${BASE_SERVIDOR}/Repositorio de informacion Jul_Dic 2022 - Transicion Alejandria_Halcon/15-PIC-SSNA - SERVICIO DE EXTINCIÓN DE INCENDIOS - SEI -/${file.Nombre}`}> {file.extension==1?<BsFillFileEarmarkExcelFill/> : <BsFillFileEarmarkPdfFill/>} {file.Nombre} </a>
                                                                </ListGroup.Item>
                                                            }
                                                            </>
                                                        )
                                                    }
                                            </ListGroup>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                <Accordion.Item eventKey="16">
                                        <Accordion.Header> 16-PIC-OPERACIONES AEROPORTUARIAS - PNIOA </Accordion.Header>
                                            <Accordion.Body>
                                                <ListGroup>
                                                    {
                                                        archivo.map((file)=>
                                                            <>
                                                            {
                                                                file.IdNombreCarpeta == 16 &&
                                                                <ListGroup.Item>
                                                                    <a target="_blank" href={`${BASE_SERVIDOR}/Repositorio de informacion Jul_Dic 2022 - Transicion Alejandria_Halcon/16-PIC-OPERACIONES AEROPORTUARIAS - PNIOA/${file.Nombre}`}> {file.extension==1?<BsFillFileEarmarkExcelFill/> : <BsFillFileEarmarkPdfFill/>} {file.Nombre} </a>
                                                                </ListGroup.Item>
                                                            }
                                                            </>
                                                        )
                                                    }
                                            </ListGroup>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                <Accordion.Item eventKey="17">
                                        <Accordion.Header> 17-AUTO_HETERO_COEVALUACION_III Y IV TRIMESTRES-Ed_Continua </Accordion.Header>
                                            <Accordion.Body>
                                                <ListGroup>
                                                    {
                                                        archivo.map((file)=>
                                                            <>
                                                            {
                                                                file.IdNombreCarpeta == 17 &&
                                                                <ListGroup.Item>
                                                                    <a target="_blank" href={`${BASE_SERVIDOR}/Repositorio de informacion Jul_Dic 2022 - Transicion Alejandria_Halcon/17-AUTO_HETERO_COEVALUACION_III Y IV TRIMESTRES-Ed_Continua/${file.Nombre}`}> {file.extension==1?<BsFillFileEarmarkExcelFill/> : <BsFillFileEarmarkPdfFill/>} {file.Nombre} </a>
                                                                </ListGroup.Item>
                                                            }
                                                            </>
                                                        )
                                                    }
                                            </ListGroup>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                <Accordion.Item eventKey="18">
                                        <Accordion.Header> 18-Informes_cuantitativos_cualitativos_III_IV_Trimes_2022 </Accordion.Header>
                                            <Accordion.Body>
                                                <ListGroup>
                                                    {
                                                        archivo.map((file)=>
                                                            <>
                                                            {
                                                                file.IdNombreCarpeta == 18 &&
                                                                <ListGroup.Item>
                                                                    <a target="_blank" href={`${BASE_SERVIDOR}/Repositorio de informacion Jul_Dic 2022 - Transicion Alejandria_Halcon/18-Informes_cuantitativos_cualitativos_III_IV_Trimes_2022/${file.Nombre}`}> {file.extension==1?<BsFillFileEarmarkExcelFill/> : <BsFillFileEarmarkPdfFill/>} {file.Nombre} </a>
                                                                </ListGroup.Item>
                                                            }
                                                            </>
                                                        )
                                                    }
                                            </ListGroup>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </ListGroup.Item>
                        </ListGroup>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            </Tab>
        </Tabs>
        </>
    )
}
export {ConsultaHistorico}