import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Alert, Button, Card, Table } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { alerta } from '../../../helpers/alertas'
import { FormService } from '../../../services/api/formService'

export const AsignarSemestre = ({
  usuario,
  IdOferta,
  miPrimerPerido,
  consultarAsignaturas,
  consultarMiPrimerPerido
}) => {
    const IdUsuaio=usuario.IdUsuario
    const api= new FormService();


  const asignatuarasPrimerSemestre=async()=>{
    var data={
      "IdUsuario":IdUsuaio,
      "IdOferta":IdOferta,
      "IdAspirante":usuario.IdAspirante
    }
  
    const respuesta=await api.create("educacionIES/asignatuarasPrimerSemestre",data);
    if(respuesta.respuesta=="exito"){
      alerta(respuesta.mensaje)
      consultarMiPrimerPerido()
      consultarAsignaturas()

    }else{
      alerta("Fallo al asignar asiganuras")
    }
  }

  return (
        <>
        <Card>
          {miPrimerPerido&&
            <Button 
              onClick={asignatuarasPrimerSemestre}
            >
              Asignar asignaturas del primer semestre
            </Button>
  
          }
        </Card>
      </>
  )
}
