import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { FormService } from '../../services/api/formService'
import { useSubmitCreate } from './hooks/useSubmitCreate'
import { useSubmitUpdate } from './hooks/useSubmitUpdate'
import { onCloseModals, setDataForm, setFieldsForm } from './FormSlice'
import { RenderTags } from './RenderTags'
import { Button, Modal, ModalBody } from 'react-bootstrap'

const DynamicForm = ({
    isContentTypeFormData = '0',
    title,
    endPointSubmit,
    extraData,
    keySubmit,
    isCreate=false,
    justDynamicForm = { flag: false, idForm: null },
    onClickCancelBtn,
    accionMysql,
    children
}) => {
    const dispatch = useDispatch()
    const camposForm = useSelector((state) => state.form.fields)
    const itemSelected = useSelector((state) => state.form.itemSelected)
    const dataForm=useSelector((state)=>state.form.dataForm)
    

    const [fields, setFields] = useState(itemSelected ||{})
    const [fetchDataCreate, statusCreate] = useSubmitCreate(
        endPointSubmit,
        null,
        extraData,
        isContentTypeFormData
    )
    const [fetchDataUpdate, statusUpdate] = useSubmitUpdate(
        endPointSubmit,
        extraData,
        keySubmit,
        isContentTypeFormData
    )
    useEffect(() => {
        dispatch(setDataForm(itemSelected))

        if (justDynamicForm.flag) {
            const api = new FormService()
            api.getAll(`xcampos?id_formulario=${justDynamicForm.idForm}`).then(
                (res) => {
                    dispatch(setFieldsForm(res))
                }
            )
        }
    }, [])

    const onChangeHandler = (value, name) => {
        setFields({
            ...fields,
            [name]: value,
        })

       dispatch(setDataForm({...dataForm,[name]:value}))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let formdata = new FormData(e.currentTarget)

        if (isCreate) {
            fetchDataCreate(formdata)
        } else {
            fetchDataUpdate(formdata)
        }
        if(accionMysql){
            accionMysql()
        }
    }

    return (
        <>
        {title&&
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
        }
        


        <form onSubmit={handleSubmit}>
        <ModalBody>
            {!!camposForm && camposForm.map((input, index) => 
                    <RenderTags
                        key={index}
                        input={input}
                        index={index}
                        fields={fields}
                        onChangeHandler={onChangeHandler}
                    />
                )}
            {
                children
            }
            {isCreate&&
                <div>
                    <small>{statusCreate}</small>
                </div>
            }
            {!isCreate&& 
                <div>
                    <small>{statusUpdate}</small>
                </div>
            }
        </ModalBody>
        <Modal.Footer>
                {justDynamicForm.flag?
                    <Button
                        onClick={()=>onClickCancelBtn(false)}
                        variant="secondary"
                    >
                        Cancelar
                    </Button>
                :
                    <Button
                        onClick={() => dispatch(onCloseModals())}
                        variant="secondary"
                    >
                        Cancelar
                    </Button>
                }
                <Button variant='primary' type='submit' >
                    {isCreate ? 'Guardar' : 'Actualizar'}
                </Button>
        </Modal.Footer>
        </form>
        </>
    )
}

DynamicForm.propTypes = {
    isContentTypeFormData: PropTypes.oneOf(['0', '1']),
    ventanaFormName: PropTypes.string,
    title: PropTypes.string,
    endPointSubmit: PropTypes.string.isRequired,
    extraData: PropTypes.object,
    extraParams: PropTypes.string,
    keySubmit: PropTypes.string,
    isCreate: PropTypes.bool.isRequired,
    justDynamicForm: PropTypes.exact({
        idForm: PropTypes.string,
        flag: PropTypes.bool,
    }),
    onClickCancelBtn: PropTypes.func,
    accionMysql: PropTypes.func,
}

export { DynamicForm }
