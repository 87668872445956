import { Card } from "react-bootstrap";
import { DynamicFormContainer } from "../../../components/DynamicForm/DynamicFormContainer"; 
import { DynamicTable } from "../../../components/DynamicForm/dynamicTable/DynamicTable";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFetchGet } from "../../../hooks/useFetchGet";
import { FormService } from "../../../services/api/formService";

export function FuncionariosPIC() {
    const [modalUpdate, setModalUpdate] = useState()
    const {IdParametrizacionPic} = useParams()
    const api = new FormService()
    const [pic, setPic] = useState()

    

    useEffect (() => {
        getParametrizacion()
    }, [])
    
    const getParametrizacion = () => {
        const fetch = api.getAll(`parametrizacionPIC/consulta?IdParametrizacionPic=${IdParametrizacionPic}`)
        /* console.log(fetch.DirigeCapacitacion) */
        setPic(fetch)
    }

    return (
        <>
        <Card>
            <Row>
                    <h2> Plan Institucional de Capaciación - Funcionarios</h2>
            </Row>
        </Card>
        <Card>
            <DynamicFormContainer
                idForm={149}
                isCreate={true}
                title={"Crear Funcionario"}
                endPointSubmit="funcionariosPIC"
                showTable={false}
            />
            <DynamicTable
                titleColumn={["No", "Usuario", "Documento", "Acción"]}
                attributes={["Usuario", "Documento"]}
                endPoint={`funcionariosPIC?IdParametrizacionPic=${IdParametrizacionPic}`}
                onActions={true}
                onUpdate={false}
                onDelete={true}
            />
        </Card>
        <Modal
            show={modalUpdate}
            onHide={setModalUpdate}
        >
            <DynamicFormContainer
                idForm={149}
                isCreate={false}
                title={"Actualizar Parametrización"}
                endPointSubmit="funcionariosPIC "
                showTable={false}
            />
        </Modal>
        </>
    );

}