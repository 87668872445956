import React from 'react'
import ReactDOM from 'react-dom'
import { Button } from '../button/button'
import './Sidebar.css'

const Sidebar = ({
    isOpen,
    close,
    children,
}) => {

    if (!isOpen) return null

    return ReactDOM.createPortal(
        <div className='sidebar'>
            <div className='sidebar__window'>
                <section className='sidebar__container'>
                    { children }
                    <div className="sidebar__button">
                        <Button onClick={ close }>
                            <i className="sidebar__icon bi bi-x"></i>
                        </Button>
                    </div>
                </section>
            </div>
        </div>,
        document.getElementById('sidebar')
    )
}

export { Sidebar }