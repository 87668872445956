import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { DocumentosRequisitos } from '../componentesC/DocumentosRequisitos/DocumentosRequisitos'

export default function ProcesoInscripcion() {
    const {inscrito}=useLocation().state
  return (
    <>
        <Card>
            <Row>
                <Col>
                    <Card.Title> Proceso de inscripción </Card.Title>
                    <p>{inscrito.Nombre}</p>
                </Col>
                <Col
                    style={{textAlign:'right'}}
                >
                    Estado:
                    {inscrito.EstadoSolicitud==0&& <span style={{color:'grey'}}> Pendiente </span>}
                    {inscrito.EstadoSolicitud==1&& <span style={{color:'green'}}> Aprobado </span>}
                    {inscrito.EstadoSolicitud==2&& <span style={{color:'red'}}> Rechazado </span>}
                </Col>
            </Row>
            
        </Card>
        { inscrito.EstadoSolicitud==0&&
            <DocumentosRequisitos
                oferta={inscrito}
            />
        }
        

    </>
  )
}
