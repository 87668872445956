import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Form, Modal, NavDropdown, Table,Card , Col, Row } from "react-bootstrap";
import { TextArea } from '../../../components/componentsForm/textarea/textarea';
import { alerta } from '../../../helpers/alertas';
import { BASE_SERVIDOR, BASE_URL } from '../../../services/api/config';
import { FormService } from '../../../services/api/formService'
import DowloadFileText from '../../../components/DowloadFileText/DowloadFileText';

export default function PagoAsignaturas() {
    const api=new FormService();
    const [pagos,setPagos]=useState([]);
    const [modalAceptar,setModalAceptar]=useState();
    const [modalRechazar,setModalRechazar]=useState();
    const [motivoPagoRechazo,setMotivoPagoRechazo]=useState(null)
    const [malla,setMalla]=useState();
    const linkPdf=`${BASE_SERVIDOR}/pdf/`
    const exportar=()=>{
        window.open(`${BASE_URL}/educacionIES/pogoAsginaturas/PDF`,"_blank",)
    }

    useEffect(()=>{
        (async()=>{
            consultarPagos()
        })()

    },[])
    
    const consultarPagos=async()=>{
        const pagos=await api.getAll("educacionIES/pogoAsginaturas");
        setPagos(pagos)
    }

    const aceptarPago=async()=>{
        const data={
            "IdMallaAspirante":malla.IdMallaAspirante
        }
        const respuesta=await api.create("educacionIES/aceptarPagoAsignatura",data)
        if(respuesta.respuesta=="exito"){
            alerta(respuesta.mensaje)
            consultarPagos();
            setModalAceptar();
        }else{
            alerta("Fallo desconocido")
        }
    }

    const rechazarPago=async()=>{
        const data={
            "IdMallaAspirante":malla.IdMallaAspirante,
            "MotivoPagoRechazo":motivoPagoRechazo
        }
        const respuesta=await api.create("educacionIES/rechazarPagoAsignatura",data)
        if(respuesta.respuesta=="exito"){
            alerta(respuesta.mensaje)
            consultarPagos();
            setModalRechazar()
        }else{
            alerta("Fallo desconocido")
        }
    }

  return (
    <>
        <Card>
            <Row>
                <Col>
                    <Card.Title>Pago de Asignaturas</Card.Title>
                </Col>
                <Col style={{textAlign:"right"}}>
                    <Button
                        variant='primary'
                        // href={`${BASE_URL}/formatosIES/exportar/horarioResumido?IdOferta=${oferta?.IdOferta}&IdHorario=${horario.IdHorario}`}
                    >
                        <DowloadFileText
                            nameFile={"Pago de Asignaturas.xlsx"}
                            name={"Exportar"}
                            colortext={"#ffffff"}
                            endPoint={`${BASE_URL}/educacionIES/pogoAsginaturas/Excel`}
                        />
                    </Button>
                    {/*<Button
                        onClick={()=>exportar()}
                    >
                        Exportar
                    </Button>*/}
                </Col>
            </Row>
        </Card>
        <Card>
            <Table>
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Asignatura</th>
                        <th>PDF</th>
                        <th>Fecha</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {pagos.map((pago,key)=>
                        <tr key={key}>
                            <td>{pago.Nombre}</td>
                            <td>{pago.Asignatura}</td>                            
                            <td>
                                <a target="_blank" href={`${linkPdf}${pago.ComprobantePago}`}>
                                    Ver PDF
                                </a>
                            </td>
                            <td>{pago.FechaComprobante}</td>
                            <td>
                                <Button
                                    variant='danger'
                                    onClick={()=>{
                                        setModalRechazar(true)
                                        setMalla(pago)
                                    }}
                                >
                                    Rechazar
                                </Button>
                                <Button
                                    onClick={()=>{
                                        setModalAceptar(true)
                                        setMalla(pago)
                                    }}
                                >
                                    Aceptar
                                </Button>
                            </td>
                        </tr>
                    )}

                </tbody>
            </Table>
        </Card>
        <Modal
            show={modalRechazar}
            onHide={()=>setModalRechazar()}
        >
            <Modal.Body>
                ¿ Desea rechazar el pago?
                <TextArea
                    label="Describe el motivo del rechazo"
                    value={motivoPagoRechazo}
                    onChange={setMotivoPagoRechazo}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={()=>setModalRechazar()}
                >
                    Cancelar
                </Button>
                <Button
                    onClick={()=>rechazarPago()}
                >
                    Aceptar
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal
            show={modalAceptar}
            onHide={()=>setModalAceptar()}
        >
            <Modal.Body>
                ¿ Desea aceptar el pago?
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={()=>setModalAceptar()}
                >
                    Cancelar
                </Button>
                <Button
                    onClick={()=>aceptarPago()}
                >
                    Aceptar
                </Button>
            </Modal.Footer>
        </Modal>
    </>
  )
}
