import { useState } from "react";
import { Input } from "../../../../../components/input/input"
import { optionsDate } from "../../../../../shared/util/date"
import './AddNewDates.css'
function AddNewDates ({itemSelected,setNewDate,dateStrSelected,listadoFechas,deleteNewDate,onAddNewDate}){
  return (
    <>
    <div>
       <div className="d-flex">
        <Input
        required={false}
        type={'date'}
        name='newDate'
        onChange={(value,name)=>setNewDate(value)}
        ></Input>
        <button
        onClick={()=>onAddNewDate()}
        type='button'
        className="btn btn-info btn-sm"
        >Añadir Fecha</button>
        
       </div>
       <div className="d-flex newdates-list evento-container">
       <div className="evento-date-card" >
              
              <div className="evento-date-card__evento-header" >x</div>
              <div style={{backgroundColor:'#E9ECEF',color:'gray'}} className="event-content">{(itemSelected.date).toLocaleDateString('es-co',optionsDate)}</div>
            </div>
       {!!listadoFechas && listadoFechas.map((fecha,index)=>{
          return (
            <div className="evento-date-card">
              <div
              className="evento-date-card__evento-header">
                <span onClick={()=>deleteNewDate(index,fecha)}>x</span>
              </div>
              
              <div className="event-content">{!!fecha && new Date(fecha+'T00:00').toLocaleDateString('es-co',optionsDate) } </div>
            </div>
          )
        })}
       </div>
      </div>
    </>
  )
}

export { AddNewDates }