import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Modal, Row } from 'react-bootstrap'
import { FormService } from '../../services/api/formService'
import { useLocation, useParams } from 'react-router-dom'
import TablePaginate from '../../components/TablePaginate/TablePaginate'
import { SelectDataList } from '../../components/SelectDataList/SelectDataList'
import Consentimiento1 from '../../pages/Consentimientos/Consentimiento1'
import { alerta } from '../../helpers/alertas'
import { useDispatch } from 'react-redux'
import { setSearchFields } from '../../shared/store/table/tableSlice'

export default function Funcion() {

    const {funcion} =   useLocation().state
    const api=new FormService();
    const[modal,setModal]=useState(false)
    const[IdCursoPieso,setIdCursoPieso]=useState(null)
    const dispatch=useDispatch()



    useEffect(()=>{

    },[])

    const agregarCurso=async()=>{
        const data={
            IdFuncionSaa:funcion.IdFuncionSaa,
            IdCursoPieso:IdCursoPieso
        }
        const response=await api.create("seguridadAerea/agregarCursoFuncion",data)
        if(response.success){
            setModal(false)
            dispatch(setSearchFields({i:Math.random()}))
        }else{
            alerta("Ocurrio un error","error")
        }
    }

  return (
    <>
        <Card>
            <Row>
                <Col>
                    <Card.Title>Cursos requeridos</Card.Title>
                </Col>
                <Col 
                    style={{textAlign:'right'}}
                >
                    {/* boton para agregar */}
                    <Button
                        variant="primary"
                        onClick={()=>{
                            setModal(true)
                        }}
                    >Agregar</Button>
                </Col>
            </Row>
            
        </Card>
        <TablePaginate
            apiUrl={`cursosPieso/porFuncion?IdFuncion=${funcion.IdFuncionSaa}`}
            tableHeaders={[
                // {title: 'Id', attribute: 'Id'},
                {title: 'Código', attribute: 'CodigoPrograma'},
                {title: 'Nombre', attribute: 'Descripcion'},
            ]}
        />
        {/* modal para agregar */}
        <Modal
            show={modal}
            onHide={setModal}
        >
            <Modal.Header>
                <Modal.Title>Agregar curso</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <SelectDataList
                endPoinLastUrl={`cursosPieso/full`}
                optionName='Descripcion'
                optionValue={'IdCursoPieso'}
                onChangeHandler={setIdCursoPieso}
            />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={()=>setModal()}>Cancelar</Button>
                <Button onClick={()=>{agregarCurso()}}>Guardar</Button>
            </Modal.Footer>
        </Modal>

    </>
  )
}
