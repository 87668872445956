import React from 'react'
import { Card } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { DynamicTable } from '../../../components/DynamicForm/dynamicTable/DynamicTable'

export default function CohortesMatriculados(){
    const {state:{oferta}}=useLocation()
    
  return (
    <>
        <Card>
            <Card.Title>Matriculados</Card.Title>
            <div>Cohorte: {oferta.codigo_grupo}</div>
        </Card>
        <DynamicTable
            endPoint={`educacionIES/matriculadosCohorte?IdOferta=${oferta.IdOferta}`}
            titleColumn={["N°","Número Documento","Nombre"]}
            attributes={["NumeroDocumento","NombreUsuario"]}
            // handleOptionClick={()=>{}}
            // labelOptionClick={`Ver estudiante`}
            onDelete={false}
            onUpdate={false}
            onActions={false}
        />
    </>
  )
}
