import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { AlertasInasistencias } from '../bienestar/AlertasInasistencia'
import { AlertasAsignaturas } from '../bienestar/AlertasAsignaturas'
import { EstudianteHistorial } from '../componentesC/estudianteHistorial/EstudianteHistorial'
import { PerfilEstudiante } from '../componentesC/estudianteHistorial/PerfilEstudiante'
import { AlertasSocioeconomicas } from '../bienestar/AlertasSocioeconomicas'
import { SeguiminetoAlerta } from '../bienestar/SeguiminetoAlerta'
import { EstudianteNotas } from '../componentesC/EstudianteNotas/EstudianteNotas'


export default function PlaneacionEducacion() {
  return (
    <Routes>
        <Route path="historial_estudiante" element = {<EstudianteHistorial/>}></Route>
        <Route path="historial_estudiante/perfil_estudiante" element = {<PerfilEstudiante/>}></Route>
        <Route path='historial_estudiante/perfil_estudiante/asignaturas' element={<EstudianteNotas/>}></Route>

        <Route path="alertas_inasistencia" element ={<AlertasInasistencias full={1} />}></Route>
        <Route path="alertas_inasistencia/seguimiento_alerta" element ={<SeguiminetoAlerta />}></Route>
        <Route path="alertas_promedio_academico" element ={<AlertasAsignaturas full={1} />}></Route>
        <Route path="alertas_promedio_academico/seguimiento_alerta" element ={<SeguiminetoAlerta />}></Route>
        <Route path="alertas_socioeconomica" element={<AlertasSocioeconomicas full={1} />}></Route>   
        <Route path="alertas_socioeconomica/seguimiento_alerta" element={<SeguiminetoAlerta />}></Route>

    </Routes>
  ) 
}
