import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Button, Card, Table } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { DynamicFormContainer } from '../../components/DynamicForm/DynamicFormContainer'
import { LocalStorage } from '../../services'
import { FormService } from '../../services/api/formService'
import Header from '../componentesC/PerfilUsuario/Componentes/Header'

export default function ConvocatoriasGrado() {
    const {state:{usuario}}=useLocation()
    const [cursos,setCursos]=useState([]);
    const api=new FormService();
    const Store= new LocalStorage();

    const Navigate=useNavigate();

    useEffect(()=>{
      const fetchData=async()=>{
        consultarCursos();
      }
      fetchData();
    },[])

    const consultarCursos=async()=>{
      const cursos=await api.getAll(`convocatoriaGrados/consultarOfertaGrados?IdUsuario=${usuario.IdUsuario}`)
      setCursos(cursos)
    }
    const solicitudGrado=(curso)=>{
      Navigate("inscripcion",{state:{curso:curso,usuario:usuario}})
    }

  return (
    <>
     <div style={{maxWidth:1000,margin:"0 auto",marginTop:40}}>
        <Header usuario={usuario}/>
        <Card>
            <Card.Title>Convocatoria a Grados</Card.Title>
        </Card>
        <Card>
          <Table>
            <thead>
              <tr>
                <th>Curso</th>
                <th>Fecha ceremonia</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {cursos.map((curso,key)=>
                <tr key={key}>
                  <td>{curso.Curso}</td>
                  <td>{curso.Fecha}</td>
                  <td><Button
                    onClick={()=>solicitudGrado(curso)}
                  >Ver más</Button></td>
                </tr>
              )
              }
              
            </tbody>
          </Table>
        </Card>
      </div>
    </>
  )
}
