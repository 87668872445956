import { useEffect } from "react";
import { useState } from "react";
import { FormService } from "../services/api/formService";


function useFetchGet (url) {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const formService = new FormService()

  useEffect(()=>{
    (async () => {
      try {
        const res = await formService.getAll(url);
        if(res?.data){
          setData(res);
        }else{
          setData(res);
        }
        setLoading(false);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();

  },[url])

  return [data,loading,error]
}
export { useFetchGet}