export const insertExtraDataViaHistoryForm = (dataRaw,historyForm,currentPathName)=>{
  // this function insert new object based in the pathname,the objects is based of history navigation of the dynamictable actions
  let obj = {};
  if(currentPathName.includes('notasAsignatura')){
    console.log('viahistoryform')
    for (const key of historyForm) {
      if(key.IdMalla) obj['IdMalla'] = key.IdMalla;
      if(key.IdOferta) obj['IdOferta'] = key.IdOferta;
  }
  // for (const key of historyForm) {
  //       obj[key.llave_primaria] = key.id;
  // }
  
  return obj
  }else{
    return dataRaw
  }
  
  
}

export const insertParamsViaHistoryForm = (endpoint,historyForm,currentPathName)=>{
  
  let extraParams = '&'
  if(currentPathName.includes('notasAsignatura')){
    for (const key of historyForm) {
      // if(key.IdMalla) params =  params+'IdMalla='+ key.IdMalla;
      if(key.IdOferta)extraParams = extraParams+'IdOferta='+ key.IdOferta;
  }
  
  return endpoint+extraParams
  }else{
    return endpoint
  }
  
  
}

export const openURLNewTab=(url)=>{

  const newTab = window.open(
    url, 
    '_blank', 
    'noopener,noreferrer'
  )
  if (newTab) newTab.opener = null

}