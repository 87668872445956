import { DynamicForm } from "../../../components/DynamicForm/DynamicForm"


function EvaluacionDocente (props) {

  // const {state:{itemParent,item}} = useLocation()
  // const [asistencias, loading, error] = useFetchGet(`estudiante/asistencia?IdOferta=${item.IdOferta}&IdEstudiante=${item.IdEstudiante}`)
  // const [asistencias, loading, error] = useFetchGet(`estudiante/asistencia?IdOferta=${itemParent.IdOferta}&IdEstudiante=${item.IdEstudiante}`)
  return (
    <>
   <DynamicForm
    parentField = { null}
    ventanaFormName={'Evaluar docente' }
    title = {''}
    endPointSubmit={'keysubmit?idaspiranteetc=x'}
    keySubmit={'k'}
    isContentTypeFormData={'0'}
    isCreate={true}
    justDynamicForm={{flag:true,idForm:'51'}}
   ></DynamicForm>

    </>
  )
}

export { EvaluacionDocente }