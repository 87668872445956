import React, { useState } from 'react'

import { Select } from '../../select/Select'
import { Input } from '../../input/input'
import { FormService } from '../../../services/api/formService'
import { alertaSinBoton } from '../../../helpers/alertas'
import { SelectDataList } from '../../SelectDataList/SelectDataList'
import { Button, Modal } from 'react-bootstrap'
import { useEffect } from 'react'
import { Checkbox } from '../../componentsForm/checkbox/checkbox'
import { InputFile } from '../../inputFile/inputFile'
import { LocalStorage } from '../../../services'

const AperturaForm = props => {

    const {
        setData,
        loading,
        close,
        isCreate,
        selectList,
        loadDataTable,
        handleClickAdd,
        data=[],
    } = props


    const {
        ofertas,
        usuarios,
        convenios,
        convocatorias,
    } = selectList

    const [renderConvenio,setRenderConvenio] = useState(false)
    const [renderConvocatoria,setRenderConvocatoria] = useState(false)
    const [formData,setFormData] = useState({...data});
    const [itemOferta,setItemOferta]=useState();
    const [busqueda, setBusqueda] = useState()
    const storage=new LocalStorage()
    const dimension=storage.getItem('dimension')


    const handleSubmit = async e => {
        e.preventDefault()
        const api = new FormService()
        try {
            delete formData["Nombre"]
            delete formData["Docente"]
            delete formData["Mecanismo"]
            delete formData["IdDimension"]
            const x = await api.updateWithFormData('ofertas',formData,formData?.IdOferta)
            if(isCreate){
                handleClickAdd()
            }else{
                close();
            }
            alertaSinBoton('Completado','success','bottom-end','1500')
            loadDataTable()
        } catch (error) {
            
        }
    }
    const handleChange =(value,name)=>{
        setFormData({...formData,[name]:value}) 
    }
    useEffect(()=>{
        if(!isCreate){
            onLocalHandleChange(data?.IdOferta,"IdOferta")
        }
    },[])

    const onLocalHandleChange=(value,name)=>{
       
        let itemOferta;
        if(isCreate){
            itemOferta =ofertas.find(item=>item.IdOferta ==value) 
        }else{
            itemOferta=data 
        }
        setItemOferta(itemOferta)

        if(name=='IdOferta' && itemOferta.IdTipoMecanismo=='4'){
            setRenderConvocatoria(true)
            setRenderConvenio(false)
            handleChange(null,'IdConvenio')
        }
        if(name=='IdOferta' && itemOferta.IdTipoMecanismo=='9'){
            setRenderConvocatoria(true)
            setRenderConvenio(false)
            handleChange(null,'IdConvenio')
        }
        if(name=='IdOferta' && itemOferta.IdTipoMecanismo=='7'){
            setRenderConvocatoria(true)
            setRenderConvenio(false)
            handleChange(null,'IdConvenio')
        }
        if(name=='IdOferta' && itemOferta.IdTipoMecanismo=='3'){
            setRenderConvenio(true)
            setRenderConvocatoria(false)
            handleChange(null,'IdConvocatoria')
        }
        if(name=='IdOferta' && itemOferta.IdTipoMecanismo=='1'){
            setRenderConvenio(false)
            setRenderConvocatoria(false)
            handleChange(null,'IdConvocatoria')
            handleChange(null,'IdConvenio')
        }
        if(name=='IdOferta' && itemOferta.IdTipoMecanismo=='2'){
            setRenderConvenio(false)
            setRenderConvocatoria(false)
            handleChange(null,'IdConvocatoria')
            handleChange(null,'IdConvenio')
        }
        if(name=='IdEstadoOferta'){
            handleChange('1',name) //activo
        }


        handleChange(value,name)
    }

     console.log(usuarios)

    return (
       
       <form onSubmit={ handleSubmit } >
            <Modal.Body>
                {isCreate&&
                <Select
                    name="IdOferta"
                    onChange={ onLocalHandleChange  }
                    value={ formData?.IdOferta || '' }
                    label={dimension=='"true"'?'Programa'
                        :'Actividad Académica'
                    }
                    optionValue='IdOferta'
                    optionName='Nombre'
                    urlOptionsService={'ofertas/disponibles'}
                />
                }
                
                { renderConvocatoria &&
                    <Select
                    name="IdConvocatoria"
                    onChange={ handleChange }
                    value={ formData?.IdConvocatoria || '' }
                    label={dimension=='"true"'?'Etapas':'Convocatoria'}
                    optionValue='IdConvocatoria'
                    optionName='Nombre'
                    options={ convocatorias }
                    />
                }

                {renderConvenio && <Select
                    name="IdConvenio"
                    required={false}
                    onChange={ handleChange }
                    value={ formData?.IdConvenio || ''}
                    label="Convenio"
                    optionValue='IdEntidad'
                    optionName='NOMBRE_ENTIDAD'
                    urlOptionsService={'empresa/consulta'}

                />}
                    <SelectDataList
                        // input={input}
                        label={
                            dimension=='"true"'?'Coordinador del Programa'
                            :'Coordinador de la Actividad'
                        }
                        optionValue={"IdUsuario"}
                        optionName={"Nombre"}
                        onChangeHandler={handleChange}
                        name={"IdUsuario"}
                        endPoinLastUrl={"docente/full"}
                        value={formData.Docente}
                        valorActual={formData.IdUsuario}
                    /> 
                
                <Select
                    name="IdEstadoOferta"
                    onChange={ handleChange }
                    value={ formData?.IdEstadoOferta || '' }
                    label="Estado Oferta"
                    optionValue='IdEstadoOferta'
                    optionName='nombre'
                    secondOptionName='PrimerApellidos'
                    options={ [
                    {IdEstadoOferta:'1',nombre:'Activo'},
                    {IdEstadoOferta:'2',nombre:'En Curso'},
                    {IdEstadoOferta:'3',nombre:'Notas Digitadas'},
                    {IdEstadoOferta:'4',nombre:'Cerrado'},
                    {IdEstadoOferta:'5',nombre:'Cancelado'},
                ] }
                    />
                <Input
                    label="Fecha Apertura Inscripciones"
                    type="date"
                    onChange={ handleChange }
                    name='FechaInicio'
                    value={ formData?.FechaInicio || '' }
                />
                <Input
                    label="Fecha Cierre Inscripciones"
                    type="date"
                    onChange={ handleChange }
                    name='FechaFin'
                    value={ formData?.FechaFin || '' }
                />
                <Input
                    label="Código / Grupo  "
                    type="text"
                    onChange={ handleChange }
                    name='codigo_grupo'
                    value={ formData?.codigo_grupo || '' }
                />

                {itemOferta?.IdDimension==1&&
                    <Checkbox
                        onChange={ handleChange }
                        checked={formData.GestionEmpresas==1&&true}
                        name='GestionEmpresas'
                        value={formData.GestionEmpresas}
                    >Gestionable por Empresas</Checkbox>
                }
                
                <Checkbox
                    onChange={ handleChange }
                    checked={formData.VisiblePortafolio=="true"||formData.VisiblePortafolio==1&&true}
                    value={formData.VisiblePortafolio}
                    name='VisiblePortafolio'
                >
                    Visible en el Portafolio
                </Checkbox>
                <Input
                    label="Intensidad Horaria"
                    type="text"
                    onChange={ handleChange }
                    name='HORAS'
                    value={ formData?.HORAS || '' }
                />
                {/* formulario adicional */}
                <Checkbox
                    onChange={ handleChange }
                    checked={formData.MasInformacion=="true"||formData.MasInformacion==1&&true}
                    value={formData.MasInformacion}
                    name='MasInformacion'
                >
                    Requiere recolectar más infomación
                </Checkbox>

                {(formData.MasInformacion=="true" || formData.MasInformacion==1 )&&
                    <Select
                        name="IdFormulario"
                        onChange={ handleChange }
                        value={ formData?.IdFormulario || '' }
                        label="Formulario"
                        optionValue='id'
                        optionName='nombre'
                        urlOptionsService={'xformularios?Permisos=3'}
                    />
                }
                
            </Modal.Body>
            <Modal.Footer>
                    <Button
                    variant="secondary"
                    onClick={()=>{
                        isCreate?handleClickAdd():close()
                    }}>
                        Cancelar
                    </Button>
                    <Button 
                        type='send'
                        loading={ loading }
                    >
                        { isCreate ? 'Aperturar' : 'Actualizar Apertura' }
                    </Button>
            </Modal.Footer>
        </form>

    )
}

export { AperturaForm }