import React from 'react'

import { Select } from '../../select/Select'
import { Input } from '../../input/input'
import { Button } from '../../button/button'
import { ButtonsContainer } from '../../componentsForm/buttonsContainer/buttonsContainer'
import { LeftButtonContainer } from '../../componentsForm/leftButtonContainer/leftButtonContainer'
import { RightButtonContainer } from '../../componentsForm/rightButtonContainer/rightButtonContainer'
import { FormContainerModal } from '../../componentsForm/formContainer/FormContainerModal'


const Form = props => {

    const {
        handleSubmit,
        handleChange,
        formData,
        loading,
        close,
        isCreate,
        selectList
    } = props

    const {
        IdMalla,
        IdPersona,
        TipoCurso,
        Horas,
        IdAula,
        IdCalendarioDetalle,
    } = formData

    const {
        mesh,
        profile,
        classroom,
        calendar,
        type,
    } = selectList

    return (
        <FormContainerModal title={ 'Asignatura profesor' }>
            <form onSubmit={ handleSubmit }>
            <Select
                name='IdMalla'
                value={ IdMalla }
                onChange={ handleChange || ''}
                label='Plan de estudio'
                optionValue='idmalla'
                optionName='Nombre'
                options={ mesh }
            />
            <Select
                name='IdPersona'
                value={ IdPersona }
                onChange={ handleChange || ''}
                label='Asignatura Profesor'
                optionValue='IdPersona'
                optionName='PrimerNombres'
                secondOptionName='SegundoApellidos'
                options={ profile }
            />
            <Select
                name='IdAula'
                value={ IdAula }
                onChange={ handleChange || '' }
                label='Aula'
                optionValue='IdAula'
                optionName='Aula'
                options={ classroom }
            />
            <Select
                name='IdCalendarioDetalle'
                value={ IdCalendarioDetalle }
                onChange={ handleChange || ''}
                label='Detalles del calendario'
                optionValue='IdCalendarioDetalle'
                optionName='Descripcion'
                options={ calendar }
            />
            <Select
                name='TipoCurso'
                value={ TipoCurso }
                onChange={ handleChange || ''}
                label='Tipo curso'
                optionValue='IdTipoCurso'
                optionName='Descripcion'
                options={ type }
            />
            <Input
                label="Horas"
                type="number"
                value={ Horas || ''}
                name='Horas'
                onChange={ handleChange }
            />
            <ButtonsContainer>
                <LeftButtonContainer>
                    <Button type='back' onClick={ close }>
                        Cancelar
                    </Button>
                </LeftButtonContainer>
                <RightButtonContainer>
                    <Button 
                        type='send'
                        loading={ loading }
                    >
                        { isCreate ? 'Crear' : 'Actualizar' }
                    </Button>
                </RightButtonContainer>
            </ButtonsContainer>
            </form>
        </FormContainerModal>
    )
}

export { Form }