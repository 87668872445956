import React from 'react'
import { HeaderHome } from '../../components/headerHome/HeaderHome'
import { FooterHome } from '../../components/Inicio/FooterHome/FooterHome'
import NoticiasContainer from './NoticiasContainer/NoticiasContainer'

const Noticias = () => {
  return (
    <>
        <header className="inicio__header">
            <HeaderHome/>
        </header>
        <main className='mb-5'>
            <NoticiasContainer />
        </main>
        <footer className="inicio__footer">
          <FooterHome />
        </footer>

    </>
  )
}

export default Noticias
