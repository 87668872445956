import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Areas from './Areas'
import Funciones from './Funciones'
import CursosRequeridos from './CursosRequeridos'
import Funcionarios from './Funcionarios'
import Funcionario from './Funcionario'

export default function ActividadesInspeccion() {
  return (
    <Routes>
        <Route path="funciones_y_cursos" element={<Funciones/>} />
        <Route path="areas/funciones/:id/cursos_requeridos/:id" element={<CursosRequeridos/>} />
        <Route path="funcionarios" element={<Funcionarios/>} />
        <Route path="funcionarios/:id" element={<Funcionario/>} />

    </Routes>
  )
}
