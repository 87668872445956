import { Card } from "react-bootstrap";
import { DynamicTable } from "../../../components/DynamicForm/dynamicTable/DynamicTable";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row } from "react-bootstrap";
import { useState } from "react";
import { FormService } from "../../../services/api/formService";
import { Input } from "../../../components/input/input";
import { Form } from "react-bootstrap";
import { alerta } from "../../../helpers/alertas";

export function DirectivosPIC() {
    const [modalUpdate, setModalUpdate] = useState()
    const api = new FormService()
    const [parametrizacion, setParametrizacion] = useState({})
    const [tipoConocimientos, setTipoConocimientos] = useState({})
    const [formData, setFormData] = useState()
    const [formDataTipoConocimiento, setFormDataTipoConocimiento] = useState()
    
    const handleChange =(value,name)=>{
        setParametrizacion({...parametrizacion,[name]:value}) 
    }
    
    const handleChangeTConocimiento =(value,name)=>{
        setTipoConocimientos({...tipoConocimientos,[name]:value}) 
    }

    const guardarSeguimiento = async () => {
        const tipoCono = await api.update("tiposConocimientosPic", tipoConocimientos, tipoConocimientos.IdTipoConocimientoPic)
        const respuesta = await api.update("parametrizacionPic", parametrizacion, parametrizacion.IdParametrizacionPic)
        if(respuesta.respuesta == "exito") {
            alerta("Éxito", "success")
        } else {
            alerta("Algo salio mal", "warning")
        }
        setModalUpdate(false)
    }
    
    const getTipoConocimiento = async (e) => {
        const fetch = await api.getAll(`tipoConocimientoPIC/consulta?IdTipoConocimientoPic=${e.IdTipoConocimientoPic}`)
        setTipoConocimientos(fetch)
    }

    return (
        <>
        <Card>
            <Row>
                <h2> Plan Institucional de Capaciación - Seguimiento</h2>
            </Row>
        </Card>
        <Card>
            <DynamicTable
                titleColumn={["No", "Tipo de Conocimiento", "Nombre Eje Temático", "Temática Especifica", "Fecha Inicial", "Fecha Final", "Dirige", "Acción"]}
                attributes={["TipoConocimiento", "NombreEjeTematico", "TematicaEspecifica", "FechaInicial", "FechaFin", "DirigeCapacitacion"]}
                endPoint={"parametrizacionPic"}
                onDelete={false}
                onUpdate={false}
                labelOptionClick={"Ver más"}
                handleOptionClick={(e) => {
                    setModalUpdate(true)
                    setParametrizacion(e)
                    getTipoConocimiento(e)
                }}
            />
        </Card>
        <Modal
            show={modalUpdate}
            onHide={setModalUpdate}
        >
            <ModalHeader>
                <h2>
                    Seguimiento - PIC
                </h2>
            </ModalHeader>
            <ModalBody>
                <Form.Group className="mb-3">
                    <Form.Label><b> Tipo de Conocimiento </b></Form.Label>
                    <Form.Control
                        value={parametrizacion?.TipoConocimiento} 
                        disabled 
                    />
                </Form.Group>
                <Input
                    label={"Porcentaje de Ejecución"}
                    name={"PorcentajeEjecucion"}
                    id={"PorcentajeEjecucion"}
                    value={tipoConocimientos?.PorcentajeEjecucion}
                    type={"number"}
                    onChange={handleChangeTConocimiento}
                />
                <Input
                    label={"Porcentaje de Incumplimiento"}
                    name={"PorcentajeIncumplimiento"}
                    id={"PorcentajeIncumplimiento"}
                    value={tipoConocimientos?.PorcentajeIncumplimiento}
                    type={"number"}
                    onChange={handleChangeTConocimiento}
                />
                <hr />
                <Form.Group className="mb-3">
                    <Form.Label><b> Nombre del Eje Temático </b></Form.Label>
                    <Form.Control
                        value={parametrizacion?.NombreEjeTematico} 
                        disabled 
                    />
                </Form.Group>
                <Input
                    label={"Porcentaje de Ejecución"}
                    name={"EjecucionEjeTematico"}
                    id={"EjecucionEjeTematico"}
                    value={parametrizacion?.EjecucionEjeTematico}
                    type={"number"}
                    onChange={handleChange}
                />
                <Input
                    label={"Porcentaje de Incumplimiento"}
                    name={"IncumplimientoEjeTematico"}
                    id={"IncumplimientoEjeTematico"}
                    value={parametrizacion?.IncumplimientoEjeTematico}
                    type={"number"}
                    onChange={handleChange}
                />
                <hr />
                <Form.Group className="mb-3">
                    <Form.Label><b> Temática Específica de la Capacitación </b></Form.Label>
                    <Form.Control
                        value={parametrizacion?.TematicaEspecifica} 
                        disabled 
                    />
                </Form.Group>
                <Input
                    label={"Porcentaje de Ejecución"}
                    name={"EjecucionTematicaCapacitacion"}
                    id={"EjecucionTematicaCapacitacion"}
                    value={parametrizacion?.EjecucionTematicaCapacitacion}
                    type={"number"}
                    onChange={handleChange}
                />
                <Input
                    label={"Porcentaje de Incumplimiento"}
                    name={"IncumplimientoTematicaCapacitacion"}
                    id={"IncumplimientoTematicaCapacitacion"}
                    value={parametrizacion?.IncumplimientoTematicaCapacitacion}
                    type={"number"}
                    onChange={handleChange}
                />
                <hr />
                <Input
                    label={"Fecha Inicio"}
                    name={"FechaInicial"}
                    id={"FechaInicial"}
                    value={parametrizacion?.FechaInicial}
                    type={"date"}
                    onChange={handleChange}
                />
                <Input
                    label={"Fecha Final"}
                    name={"FechaFin"}
                    id={"FechaFin"}
                    value={parametrizacion?.FechaFin}
                    type={"date"}
                    onChange={handleChange}
                />
                <Form.Group className="mb-3">
                    <Form.Label><b> Quien Imparte Capacitación </b></Form.Label>
                    <Form.Control
                        value={parametrizacion?.DirigeCapacitacion} 
                        disabled 
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label><b> Dependecia </b></Form.Label>
                    <Form.Control
                        value={parametrizacion?.CualDependencia} 
                        disabled 
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label><b> Tipo de Área </b></Form.Label>
                    <Form.Control
                        value={parametrizacion?.TipoArea} 
                        disabled 
                    />
                </Form.Group>

            </ModalBody>
            <ModalFooter>
                <Button onClick={guardarSeguimiento}> Guardar </Button>
            </ModalFooter>
        </Modal>
        </>
    );

}