import React from 'react'
import { Card } from 'react-bootstrap'
import { DynamicTable } from '../../components/DynamicForm/dynamicTable/DynamicTable'
import { useNavigate, useParams } from 'react-router-dom'

export default function InscritosGrados() {
    const naviage = useNavigate()
    const {id} = useParams()
  return (
    <>
        <Card>
            <Card.Title> Inscritos a grados </Card.Title>
        </Card>
        {/* listar los incritos a la convocatoria */}
        <DynamicTable 
            endPoint={`convocatoriaGrados/inscritos?IdConvocatoriaGrado=${id}`}
            titleColumn={['N°','Nombre','Programa','Acciones']}
            attributes={['Nombre','Curso']}
            handleOptionClick={(e)=>naviage(`${e.IdAspirante}`,{state:{inscrito:e}})}
            labelOptionClick={'Ver proceso'}
            onDelete={false}
            onUpdate={false}
        />
    </>
  )
}
