
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { DynamicFormContainer } from '../../components/DynamicForm/DynamicFormContainer'

export default function ConvocatoriasGrados() {

    const Navigate=useNavigate();

    const navegarSolicitudes=(e)=>{
        Navigate("solicitudes",{state:{convocatoria:e}})
    }

  return (
    <>
        <DynamicFormContainer
            // labelOptionClick={"Solicitudes de gado"}    
            // handleOptionClick={(e)=>navegarSolicitudes(e)}
            idForm="64"
        />
    </>
  )
}
