import React, { useEffect, useState } from 'react';
import { Button } from '../button/button';
import { useNavigate } from 'react-router-dom';
import ApiDashboard from '../../services/api/dashboard';
import { alertaTimer, alertaSinBoton } from '../../helpers/alertas';
import { Checkbox } from '../componentsForm/checkbox/checkbox';
import { Modal } from '../modal/Modal';
import sinImagen from '../../assets/img/img_curso.png'
import './OfferCard.css';
import { BASE_URL_REPOSITORIO } from '../../services/api/config';
import FormPreinscripcion from '../../modules/componentesC/FormPreinscripcion/FormPreinscripcion';
import FormInscripcion from '../../modules/componentesC/FormInscripcion/FormInscripcion';

const OfferCard = ({ 
    programLink, 
    program,
    onClickInscripcion
}) => {

    const { IdOferta, Nombre, Repositorio, Observaciones, horas, asignaturas } =
        program;

    const img = Repositorio? `${BASE_URL_REPOSITORIO}${Repositorio}`:sinImagen;
    const [usuarioLS,setUsuarioLS]=useState();

    useEffect(() => {
        if (localStorage.getItem('usuario') === null) {
            setUsuarioLS(false);
        } else {
            setUsuarioLS(true);
        }
    }, []);


    return (
        <>
            <div className="offer-card" key={IdOferta}>
                <figure className="offer-card__img-container">
                    <img
                        className="offer-card__img"
                        src={img}
                        alt="Imagen representativa de la oferta"
                    />
                </figure>
                <section className="offer-card__snap">
                    {program.IdDimension!=1&&
                        <h5 className="offer-card__program-name">{Nombre} / {program.codigo_grupo}</h5>
                    }
                    {program.IdDimension==1&&
                        <>
                            <h5 className="offer-card__program-name">{Nombre}</h5>
                            <p>SNIES: {program.SNIES}</p>
                        </> 
                    }
                    <section className="offer-card__content">
                        <p className="offer-card__description">
                            {Observaciones}
                        </p>
                        <section className="offer-card__attributes">
                            {program.IdDimension==1&&
                                <>
                                <br></br>
                                    <p><span>Cohorte: </span>{program.codigo_grupo}</p>
                                    <p><span>Jornada: </span>Única</p>
                                    <p><span>Nivel de formación: </span>{program.NivelFormacion}</p>
                                    <p><span>Modalidad: </span>{program.ModoEstudio}</p>
                                    <p><span>Créditos: </span>{program.Creditos}</p>
                                    <p><span>Duración: </span>6 semestres </p>
                                    <p><span>Periocidad de la admisión: </span>{program.Periocidad}</p>
                                </>                                
                            }
                            {program.IdDimension!=1&&
                                <>
                                    {program.IdTipoIngreso==4?
                                    <p className="offer-card__attribute-text">Creditos :</p>
                                    :
                                    <p className="offer-card__attribute-text">Intensidad</p>
                                    }
                                    
                                    <h5>{horas}</h5>
                                    <p className="offer-card__attribute-text">
                                        Asignaturas
                                    </p>
                                    {/* contar las asignaturas */}
                                    <h5>{asignaturas.length}</h5>
                                </>
                            }                            
                        </section>
                    </section>
                    <section className="offer-card__button">
                        <Button
                            type="link"
                            isLink={true}
                            to={`oferta-detalle/${IdOferta}`}
                            state={program}
                        >
                            Ver más
                        </Button>
                       
                        { usuarioLS?
                            <FormInscripcion
                                itemSelected={program}
                            />
                        :
                        <></>
                            // <FormPreinscripcion
                            //     itemSelected={program}
                            // />
                        }
                    </section>
                </section>
            </div>
        </>
    );
};

export { OfferCard };
