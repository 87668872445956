import React from 'react'
import NoticiaCard from './NoticiaCard/NoticiaCard'
import './noticiasContainer.css'

const NoticiasContainer = () => {
  return (
    <div>
      <div className='heading_noticias'>
        <h2 className='text-center'>Noticias</h2>
      </div>
      
    <NoticiaCard />
    </div>
  )
}

export default NoticiasContainer
