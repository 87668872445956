import React, { useState } from 'react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { FormService } from '../../../services/api/formService'
import { Card } from '../../card/Card'

function Totales({
    IdPlanEstudio
}) {
    const [total, setTotal] = useState({
        horas: null,
        peso: null,
        asignaturas: null,
    })
    const api = new FormService()
    useEffect(() => {
        const fecthData=async()=>{
            consutltarTotales();
        }
        fecthData();

    }, [])

    const consutltarTotales=async()=>{
        const respuesta=await api.getAll(`malla/totalizar?IdPlanEstudio=${IdPlanEstudio}`)
        setTotal(respuesta?.datos)
    }

    return (
        <>
            {total?.horas !== null ? (
                <Card>
                    <div className="d-flex">
                        <div className="d-flex flex-direction-column total-card">
                            <div>Total horas: {total?.horas}</div>
                        </div>
                        <div className="d-flex flex-direction-column total-card">
                            <div>Total pesos: {total?.peso}</div>
                        </div>
                        <div className="d-flex flex-direction-column total-card">
                            <div>Total asignaturas: {total?.asignaturas}</div>
                        </div>
                    </div>
                </Card>
            ) : null}
        </>
    )
}

export { Totales }
