import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner'

import './ListContainer.css'

export const ListContainer = ({ loading, children }) => {
    return (
        <div className='list-container'>
            {
                loading ? <LoadingSpinner /> : (
                    children
                )
            }
        </div>
    )
}