import React from 'react'

const Tag = ({
    label,
    value,
}) => (
    <section>
        <h6 className='fw-bold text-secondary'>
            { label }
        </h6>
        <h5>
            { value }
        </h5>
    </section>
)

export { Tag }