import React, { useState, useEffect } from 'react';
import { Card, Button, Form } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { FormService } from '../../services/api/formService';
import { BASE_SERVIDOR } from '../../services/api/config';

export default function Item({administrador=null}) {
    const [item, setItem] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [Archivo, setArchivo] = useState();
    const[isLoaded,setIsLoaded]=useState(false)
    const api= new FormService();
    // obtener el usuario del local storage
    const user = JSON.parse(localStorage.getItem('usuario')).usuario;

  
    // Recibir parámetros por location
    const { state: { Item } } = useLocation();
  
    useEffect(() => {
        fetchItemMessages(Item.IdItemEstabilizacion);
    }, [Item.IdItemEstabilizacion]);
  
    const fetchItemMessages = async (itemId) => {
        try {
            const response = await api.getAll(`mensajesEstabilizacion?IdItemEstabilizacion=${itemId}`);
            setItem(Item);
            setMessages(response);
            setIsLoaded(true)
        } catch (error) {
            console.error(error);
        }
    };

    const handleNewMessage = async (e) => {
        e.preventDefault();

        // Verificar si hay mensajes existentes o si el último mensaje tiene el campo "Rol" igual a "administrador"
        const hasExistingMessages = messages.length > 0;
        const lastMessage = messages[messages.length - 1];
        const lastMessageIsAdmin = lastMessage && lastMessage.Rol === 'administrador';

        if (!hasExistingMessages || lastMessageIsAdmin || administrador) {
            const data = {
                IdItemEstabilizacion: Item.IdItemEstabilizacion,
                Mensaje: newMessage,
                IdUsuario: user.IdUsuario,
                Archivo: Archivo,
                Rol: administrador ? 'administrador' : 'usuario',
            };

            const respuesta = await api.createWithFormData('mensajesEstabilizacion', data);
            // if (respuesta.status === 201) {
                setNewMessage('');
                fetchItemMessages(Item.IdItemEstabilizacion);
            // }
        } else {
            console.log('No se puede agregar un nuevo mensaje');
        }
    };

  
    return (
        <>
            <Card>
                <Card.Title>Item</Card.Title>
                <p>{Item.Item}</p>
            </Card>
           {isLoaded ?
            <>
            {messages?.map((message,key) => (
                <Card key={key}>
                    <Card.Title>{message.Usuario}</Card.Title>
                    <p>{message.Mensaje}</p>
                    {message.Archivo ? (
                        <a href={`${BASE_SERVIDOR}/estabilizacion/${message.Archivo}`} target="_blank" rel="noreferrer">Descargar archivo</a>
                    ) : null}
                </Card>
            ))}
  
            <Card>
                <Card.Title>Agregar Mensaje</Card.Title>
                {(!messages.length || administrador || messages[messages.length - 1].Rol === 'administrador') ? (
                    <Form onSubmit={handleNewMessage}>
                        <Form.Group controlId="newMessage">
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Ingrese su mensaje"
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                            />
                            <Form.Label>Adjunta un archivo si lo requiere</Form.Label>
                            <Form.Control
                                type="file"
                                id="custom-file"
                                label="Archivo"
                                onChange={(e) => setArchivo(e.target.files[0])}
                            />
                            
                        </Form.Group>
                        <Button type="submit">Enviar</Button>
                    </Form>
                ) : (
                    <p>Esperando respuesta del administrador, para poder agregar un nuevo mensaje</p>
                )}
            </Card>
            </>
            :
            <Card>
            <p>Verificando si hay mensajes...</p>
            </Card>
            }

        </>
    );
}
